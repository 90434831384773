import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const kuchikomyuSettingEditBySalesmanSchema = yup.object().shape({
  branch_score: yup.number().required('ブランチスコアを入力してください'),
  g_url: yup.string().required('Urlを入力してください'),
  en_url: yup.string(),
});
type KuchikomyuSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  en_url: string;
}
export const useEditKuchikomyuSettingFormForAdmin = (kuchikomyuSetting?: KuchikomyuSetting) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(kuchikomyuSettingEditBySalesmanSchema),});
  useEffect(() => {
    if (kuchikomyuSetting) {
      setValue('branch_score', kuchikomyuSetting.branch_score);
      setValue('g_url', kuchikomyuSetting.g_url);
      setValue('en_url', kuchikomyuSetting.en_url);
    }
  }, [kuchikomyuSetting]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};