import React, { memo } from 'react';
import { RegisterMenuForm } from '../../organisms/menu/RegisterMenuForm';
import { useRegisterMenuImages } from '../../../hooks/menu/useRegisterMenuImages';
import { useParams } from 'react-router-dom';


export const FoodMenuRegister: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const onSubmit = useRegisterMenuImages('food', restaurantId)
  return <RegisterMenuForm menuName='フード' onSubmit={onSubmit} />;
});