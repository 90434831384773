import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";


export const useRegisterManLaboSetting = (restaurantId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();

  const onSubmit = async (data: any) => {
    data.restaurant = restaurantId;
    try {
      await customAuthAxios.post(`manzokudo_labo/settings/`, data)
      showToast('登録が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  };

  return onSubmit;
};