import { useEffect, useState } from 'react';
import { extractRestaurantIdAndTableIdFromUrl } from '../../../utils/extractRestaurantIdFromUrl';

export const useExtractRestaurantIdAndTableIdFromUrl = () => {
  const [restaurantId, setRestaurantId] = useState<string|null>(null)
  const [tableId, setTableId] = useState<string|null>(null)

  useEffect(() => {
    const fetchRestaurantIdAndTableId = async () => {
      const { restaurantId, tableId } = await extractRestaurantIdAndTableIdFromUrl();
      setRestaurantId(restaurantId);
      setTableId(tableId);
    };
    fetchRestaurantIdAndTableId();
  }, []);

  return { restaurantId, tableId };
};
