import React, { memo } from "react";
import { IconButton } from '@chakra-ui/react'
import { DeleteIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from '@chakra-ui/react'

type MenuDeleteModalLayoutProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSubmit: (data: any) => Promise<void>;
}
export const MenuDeleteModalLayout: React.FC<MenuDeleteModalLayoutProps> = memo(({isOpen, onOpen, onClose, onSubmit }) => {
  const { handleSubmit, formState: { isSubmitting }  } = useForm();
  return (
    <>
      <IconButton
        colorScheme='red'
        aria-label='Delete Menu'
        icon={<DeleteIcon />}
        size='sm'
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} size='xs' >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>注意！</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box fontWeight='bold' fontSize='16px'>削除してもよろしいですか？<br/>この操作は元に戻せません。</Box>
          </ModalBody>
          <ModalFooter>
            <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
              <Button colorScheme='red' size='sm' mr={3} type="submit" isLoading={isSubmitting} >削除</Button>
              <Button colorScheme='gray' size='sm' onClick={onClose} isDisabled={isSubmitting}>キャンセル</Button>
            </form>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});