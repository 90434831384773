import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const registerManLaboSettingSchema = yup.object().shape({
  branch_score: yup.number().required('ブランチスコアを入力してください'),
  g_url: yup.string().required('Urlを入力してください'),
  is_navigate_g_url: yup.boolean(),
});
export const useRegisterManLaboSettingForm = () => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(registerManLaboSettingSchema),});
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};