import React, { memo } from "react";
import { Grid, Text } from "@chakra-ui/react";
import { VisitCountCheckbox } from "./VisitCountCheckbox";


type VisitCountCheckboxFieldProps = {
  visitCounts: number[];
  control: any;
}
export const VisitCountCheckboxField: React.FC<VisitCountCheckboxFieldProps> = memo(({visitCounts, control}) => {
  if (visitCounts.length === 0) return <Text fontWeight='bold'>友だち登録者数が0人です</Text>
  return (
    <Grid templateColumns={{base: 'repeat(4, 1fr)', sm: 'repeat(8, 1fr)', md: 'repeat(10, 1fr)'}} gap={3}>
      {visitCounts.map((visitCount) => (
        <VisitCountCheckbox key={visitCount} visitCount={visitCount} control={control} />
      ))}
    </Grid>
  );
});