import React, { memo, useMemo } from "react";
import { Roulette } from 'react-hook-roulette';
import { Box, Button, Center, Flex, Image, Modal, ModalBody, ModalContent,  ModalOverlay, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { useFetchSegmentCount } from "../../../../hooks/games/roulette/useFetchSegmentCount";
import { useParams, useSearchParams } from "react-router-dom";
import { useFetchRouletteCouponSegmentsCustomerByRestaurantId } from "../../../../hooks/games/roulette/useFetchRouletteCouponSegmentsCustomerByRestaurantId";
import { CouponLayout } from "../../../molecules/CouponLayout";
import { useRecoilValue } from "recoil";
import { lineAccountIdState } from "../../../../store/fetchedData/lineAccountIdState";
import { useGiveCoupon } from "../../../../hooks/games/roulette/useGiveCoupon";
import { useNavigateNextPage } from "../../../../hooks/games/roulette/useNavigateNextPage";
import { useCoupons } from "../../../../hooks/games/roulette/useCoupons";
import { useSetupCouponRoulette } from "../../../../hooks/games/roulette/useSetupCouponRoulette";
import { useConfetti } from "../../../../hooks/games/roulette/useConfetti";
import { useFetchHasTwelveHoursPassedSinceLastRoulette } from "../../../../hooks/games/roulette/useFetchHasTwelveHoursPassedSinceLastRoulette";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { NavigateToMembershipCoupons } from "../../../organisms/games/roulette/NavigateToMembershipCoupons";
import { useResult } from "../../../../hooks/games/roulette/useResult";


export const CouponRoulette = () => {
  const { restaurantId } = useParams();
  const [searchParams] = useSearchParams();
  const survey = searchParams.get('survey');
  const lineAccountId = useRecoilValue(lineAccountIdState)
  const { has12HoursPassed, isLoading, isValidating } = useFetchHasTwelveHoursPassedSinceLastRoulette(lineAccountId)
  if (isLoading || isValidating || !restaurantId) return <CircularLoading />
  // 会員ページからブラウザバックするとlineAccountIdはnullになるので、その場合はメンバーシップクーポンに遷移する
  if ((survey === 'false' && !has12HoursPassed) || !lineAccountId) return <NavigateToMembershipCoupons restaurantId={restaurantId} />;
  return <RouletteContents restaurantId={restaurantId} />
};


const RouletteContents = ({restaurantId}: {restaurantId: string}) => {
  const { segmentCount } = useFetchSegmentCount(restaurantId)
  const { couponSegments } = useFetchRouletteCouponSegmentsCustomerByRestaurantId(restaurantId)
  const coupons = useCoupons(couponSegments);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { roulette, onStart, result } = useSetupCouponRoulette(onOpen, couponSegments, segmentCount)
  return (
    <Box h='100vh'>
      <Box p={4} bgImg="/images/roulette/roulette-bg.png" bgPos="center" h='100%' bgRepeat='no-repeat'>
        <Center h='100%'>
          <VStack justify='center' spacing={20}>
            <Flex align='center' h='40px'>
            <Text m={0} fontWeight='bold' fontSize='xl'>クーポンルーレット！</Text>
            </Flex>
            <Roulette roulette={roulette} />
            <Button w='80%' colorScheme="orange" onClick={onStart}>スタート</Button>
          </VStack>
        </Center>
      </Box>
      <ResultModal isOpen={isOpen} onClose={onClose} result={result} coupons={coupons} restaurantId={restaurantId} />
    </Box>
  );
};


type Coupon = {
  id: string;
  title: string;
  explanation: string;
  notes: string;
  expiration_date: string;
  img: string;
}
const ResultModal: React.FC<{isOpen: boolean, onClose: () => void, result: string, coupons: Coupon[], restaurantId?: string}> = memo(({isOpen, onClose, result, coupons, restaurantId}) => {
  const randomIndex = useMemo(() => Math.floor(Math.random() * coupons.length), [coupons]);
  const coupon = coupons[randomIndex];
  useNavigateNextPage(isOpen, restaurantId)
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size='xs' isCentered>
      <ModalOverlay zIndex={2} />
      <ModalContent>
        <ModalBody p={4}>
          {result === '当たり' ? <Win coupon={coupon} /> : <Lose />}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
})

const Win: React.FC<{coupon: Coupon}> = memo(({coupon}) => {
  const lineAccountId = useRecoilValue(lineAccountIdState)
  useConfetti();
  useGiveCoupon(coupon.id, lineAccountId)
  useResult(lineAccountId, true)
  return <CouponLayout {...coupon} />
})

const Lose = () => {
  const lineAccountId = useRecoilValue(lineAccountIdState)
  useResult(lineAccountId, false)
  return <Image src='/images/roulette/lose.png' alt='lose' />
}