import { FormControl, FormErrorMessage, FormLabel, Textarea } from "@chakra-ui/react"
import { memo } from "react"


type ExplanationTextareaProps = {
  register: any
  errors: any
}
export const ExplanationTextarea = memo(({register, errors}: ExplanationTextareaProps) => {
  return (
    <FormControl isRequired>
      <FormLabel>概要</FormLabel>
      <Textarea {...register('explanation')} />
      <FormErrorMessage>{errors.explanation?.message}</FormErrorMessage>
    </FormControl>
  )
})