import React from 'react';
import { Text, TextProps } from "@chakra-ui/react";

type TruncatedTextProps = TextProps & {
  text: string;
  maxLength: number;
}

export const TruncatedText: React.FC<TruncatedTextProps> = ({ text, maxLength, ...props }) => (
  <Text {...props}>
    {text.length > maxLength ? `${text.substring(0, maxLength)}...` : text}
  </Text>
);