import { Button, Center, Text, VStack, } from "@chakra-ui/react";
import { AuthPasswordInput } from '../../molecules/form/AuthPasswordInput';
import { LoginUsernameInput } from '../../molecules/form/LoginUsernameInput';


type LoginFormProps = {
  onSubmit: () => void;
  register: any
  errors: any;
  isSubmitting: boolean;
  title: string;
  btnColor: string;
}
export const LoginForm = ({onSubmit, register, errors, isSubmitting, title, btnColor}: LoginFormProps) => {
  return (
    <Center mt={{base: 0, sm: '60px', lg: '60px'}}>
      <VStack as='form' align='start' spacing={6} bg="white" p={{ base: 6, sm: 10 }} rounded={6} w={{ base: '100%', sm: '450px' }} onSubmit={onSubmit}>
        <Text fontSize='2xl' fontWeight='bold' mb={0}>{title}</Text>
        <VStack align='start' w='100%' spacing={4}>
          <LoginUsernameInput register={register} errors={errors} />
          <AuthPasswordInput register={register} errors={errors} />
        </VStack>
        <Button colorScheme={btnColor} w='100%' isLoading={isSubmitting} type='submit'>ログイン</Button>
      </VStack>
    </Center>
  );
};