import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";

export const useSubmitRankSettings = (restaurantId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();

  const onSubmit = async (data: any) => {
    try {
      await customAuthAxios.post(
        `membership/restaurants/${restaurantId}/rank-settings/`,
        data
      );
      showToast('変更が完了しました');
      navigate(-1);
    } catch (error: any) {
    } finally {
    }
  };

  return onSubmit;
};