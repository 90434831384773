import React, { memo } from "react";
import styled from 'styled-components';

const SContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
`;
const SLogoImg = styled.img`
  width: 40vw;
  border-radius: 15px;
`;



export const Logo: React.FC = memo(() => {
  return (
    <>
      <main>
        <SContainer>
          <SLogoImg src="/images/logo.png" alt="logo" />
        </SContainer>
      </main>
      <footer className="py-2 bg-dark">
        <p className="m-0 text-center text-white">
          Copyright &copy; BLOCKs
        </p>
      </footer>
    </>
  );
});

