import useSWR from "swr";

type SoCoupons = {
  id: string;
  title: string;
  explanation: string;
  notes: string;
  expiration_date: string;
  img: string;
}[]

export const useFetchSoCouponsByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `socoupon/restaurants/${restaurantId}/socoupons/` : null;
  const { data: soCoupons, error, isLoading } = useSWR<SoCoupons>(fetcherKey)
  return { soCoupons, isLoading, error };
};