import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { restaurantGroupId } from '../../groupNumber';
import { customNoAuthAxios } from '../../utils/customNoAuthAxios';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';
import { useErrorToast } from '../toast/useErrorToast';


export const useRegisterRestaurant = () => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();

  const registerUser = async (userData: any) => {
    userData.groups = [restaurantGroupId];
    const userRes = await customNoAuthAxios.post('authentication/signup/', userData);
    return userRes.data.id;
  };

  const registerRestaurant = async (restaurantData: any, userId: string) => {
    const uploadData = new FormData();
    uploadData.append("user", userId);
    uploadData.append("name", restaurantData.name);
    uploadData.append("address", restaurantData.address);
    uploadData.append("manager_last_name", restaurantData.manager_last_name);
    uploadData.append("manager_first_name", restaurantData.manager_first_name);
    uploadData.append("manager_phone_number", restaurantData.manager_phone_number);
    uploadData.append("corporation", restaurantData.corporation);
    uploadData.append("plan", restaurantData.plan);

    restaurantData.add_ons?.forEach((addOnId: string) => uploadData.append("add_ons", addOnId));
    restaurantData.logo[0] && uploadData.append("logo", restaurantData.logo[0], restaurantData.logo[0].name);
    const restaurantRes = await customAuthAxios.post('accounts/restaurants/', uploadData);
    return restaurantRes.data.id;
  };

  const setupMenuSettings = async (restaurantId: string) => {
    await customAuthAxios.post('menu/settings/', { restaurant: restaurantId });
  };

  const onSubmit = useCallback(async (data: any) => {
    try {
      const userId = await registerUser(data.user);
      const restaurantId = await registerRestaurant(data.restaurant, userId);
      await setupMenuSettings(restaurantId);
      showToast('登録が完了しました');
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.username) {
        showErrorToast(error.response.data.username);
      }
    }
  }, [navigate]);

  return onSubmit;
};