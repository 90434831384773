import { FormControl, FormLabel, Input } from "@chakra-ui/react";


export const TabelogUrlInput = ({register, errors}: {register: any, errors: any}) => {
  return (
    <FormControl isInvalid={errors.account?.tabelog} w='100%'>
      <FormLabel>食べログ</FormLabel>
      <Input type="url" {...register('account.tabelog')} />
    </FormControl>
  );
};

export const InstagramUrlInput = ({register, errors}: {register: any, errors: any}) => {
  return (
    <FormControl isInvalid={errors.account?.instagram} w='100%'>
      <FormLabel>Instagram</FormLabel>
      <Input type="url" {...register('account.instagram')} />
    </FormControl>
  );
};

export const TiktokUrlInput = ({register, errors}: {register: any, errors: any}) => {
  return (
    <FormControl isInvalid={errors.account?.tiktok} w='100%'>
      <FormLabel>TikTok</FormLabel>
      <Input type="url" {...register('account.tiktok')} />
    </FormControl>
  );
};

export const XUrlInput = ({register, errors}: {register: any, errors: any}) => {
  return (
    <FormControl isInvalid={errors.account?.x} w='100%'>
      <FormLabel>X</FormLabel>
      <Input type="url" {...register('account.x')} />
    </FormControl>
  );
};