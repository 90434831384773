import useSWR from "swr";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { AxiosError } from "axios";


export const useSwrWithQueryParams = <T = any>(url: string | null, params?: object) => {
  const fetcher = async ([url, params]: [string, object | undefined]): Promise<T> => (await customAuthAxios.get(url, { params })).data;
  const { data, error, isLoading, isValidating, mutate } = useSWR<T, AxiosError<{detail: string, message: string}>, [string, object | undefined] | null>(
    url ? [url, params] : null,
    fetcher
  );
  return { data, error, isLoading, isValidating, mutate };
};