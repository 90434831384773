import React, { memo } from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";


export const GoogleAccordionMenu: React.FC = memo(() => {
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          GBPマネジャー
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItemLink to={`google/business-performances`} text="ビジネスパフォーマンス" />
          <AccordionItemLink to={`google/search-keywords`} text="検索キーワード" />
          <AccordionItemLink to={`google/reviews`} text="口コミ" />
          <AccordionItemLink to={`google/posts/latest-info/new`} text="投稿" />
          <AccordionItemLink to={`google/settings`} text="設定" />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
});