import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const liffSchema = yup.object().shape({
  liff_type: yup.string().required('入力必須項目です'),
  liff_id: yup.string().required('入力必須項目です'),
  liff_url: yup.string().required('入力必須項目です'),
});
type Liff = {
  id: string;
  liff_type: string;
  liff_id: string;
  liff_url: string;
}
export const useEditLiffForm = (liff?: Liff) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(liffSchema),});
  useEffect(() => {
    if (liff) {
      setValue('liff_type', liff.liff_type);
      setValue('liff_id', liff.liff_id);
      setValue('liff_url', liff.liff_url);
    }
  }, [liff]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};