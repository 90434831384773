import useSWR from "swr";

type LineDev = {
  id: string;
  access_token: string;
  channel_secret: string;
}

export const useFetchLineDevByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `/external_api/restaurants/${restaurantId}/line-dev/` : null;
  const { data: lineDev, error, isLoading } = useSWR<LineDev>(fetcherKey)
  return { lineDev, isLoading, error };
};