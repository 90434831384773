import { memo } from "react"
import { useBenefitRegisterForm } from "../../../hooks/membership/useBenefitRegisterForm";
import { useSubmitBenefit } from "../../../hooks/membership/useSubmitBenefit";
import { BenefitForm } from "../../organisms/membership/BenefitForm";
import { useParams } from "react-router-dom";


export const BenefitRegister: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { handleSubmit, register, control, isSubmitting, errors, setValue } = useBenefitRegisterForm()
  const onSubmit = useSubmitBenefit(restaurantId!);
  return (
    <BenefitForm
      handleSubmit={handleSubmit(onSubmit)}
      register={register}
      control={control}
      isSubmitting={isSubmitting}
      errors={errors}
      setValue={setValue}
    />
  );
});