import useSWR from "swr";

type GoogleSetting = {
  id: string;
  location_id: string;
  place_id: string;
  reply_mode: string;
  notification_recipients: string[];
}

export const useFetchGoogleSetting = (settingsId?: string) => {
  const fetcherKey = settingsId ? `external_api/google-settings/${settingsId}` : null;
  const { data: googleSetting, error, isLoading } = useSWR<GoogleSetting>(fetcherKey)
  return { googleSetting, isLoading, error };
};