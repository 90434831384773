import React, { memo } from 'react';
import { formatDate } from "../../../utils/formatDate";
import { useFetchKuchikomyuLatestSurveyTexts } from '../../../hooks/kuchikomyu/useFetchKuchikomyuLatestSurveyTexts';
import { Box, CardBody, CardHeader, Divider, Flex, HStack, Icon, Link, Skeleton, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { LightShadowCard } from '../../molecules/card/LightShadowCard';


type KuchikomyuLatestSurveyAnswerTextsProps = {
  restaurantId: string;
};
export const KuchikomyuLatestSurveyAnswerTexts: React.FC<KuchikomyuLatestSurveyAnswerTextsProps> = memo(({restaurantId}) => {
  const { kuchikomyuLatestSurveyTexts, isLoading } = useFetchKuchikomyuLatestSurveyTexts(restaurantId)
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <LightShadowCard >
        <CardHeader>
          <Flex align='center' justify='space-between'>
            <Text m={0} fontSize='lg' fontWeight='bold'>最新レビュー</Text>
            <Link as={RouterLink} to='../answer-texts' fontSize='sm' color='gray.500' fontWeight='bold' _hover={{ color: 'gray.400' }}>
              <HStack align='center' spacing={1}>
                <Text m='0'>詳細</Text>
                <Icon as={IoIosArrowForward} />
              </HStack>
            </Link>
          </Flex>
        </CardHeader>
        <CardBody pt={0}>
          <LatestSurveyAnswerTexts kuchikomyuLatestSurveyTexts={kuchikomyuLatestSurveyTexts} />
        </CardBody>
      </LightShadowCard>
    </Skeleton>
  );
});

const LatestSurveyAnswerTexts: React.FC<{kuchikomyuLatestSurveyTexts?: any[]}> = ({kuchikomyuLatestSurveyTexts}) => {
  if (kuchikomyuLatestSurveyTexts?.length === 0) return <>データは存在しません</>
  return (
    <>
      <Divider m={0} />
      {kuchikomyuLatestSurveyTexts?.map((kuchikomyuLatestSurveyText) => (
        <React.Fragment key={kuchikomyuLatestSurveyText.id}>
          <HStack justifyContent='space-between' fontSize='14px' p={2}>
            <Text noOfLines={1} m='0'>{kuchikomyuLatestSurveyText.answer_text}</Text>
            <Box color='gray.500'>{formatDate(kuchikomyuLatestSurveyText.answered_at)}</Box>
          </HStack>
          <Divider m={0} />
        </React.Fragment>
      ))}
    </>
  )
}