import React from "react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { UserUsernameInput } from "../../molecules/form/UserUsernameInput";
import { UserEmailInput } from "../../molecules/form/UserEmailInput";
import { UserPhoneNumberInput } from "../../molecules/form/UserPhoneNumberInput";
import { useParams } from "react-router-dom";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { UserFullNameInput } from "../../molecules/form/UserFullNameInput";
import { InstagramUrlInput, TabelogUrlInput, TiktokUrlInput, XUrlInput } from "../../organisms/kuitan/SnsUrlInputs";
import { IoMdPerson } from "react-icons/io";
import { useFetchKuitanAgent } from "../../../hooks/kuitan/useFetchKuitanAgent";
import { useEditKuitanAgentForm } from "../../../hooks/kuitan/useEditKuitanAgentForm";
import { useEditKuitanAgent } from "../../../hooks/kuitan/useEditKuitanAgent";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const EditKuitanAgentBaseInfo = () => {
  const { kuitanAgentId } = useParams()
  const { kuitanAgent } = useFetchKuitanAgent(kuitanAgentId)
  const { handleSubmit, register, isSubmitting, errors } = useEditKuitanAgentForm(kuitanAgent);
  const onSubmit = useEditKuitanAgent(kuitanAgent?.user, kuitanAgentId);
  if (!kuitanAgent) return <CircularLoading />
  const inputFields: InputField[] = [
    { component: UserUsernameInput, props: { register, errors } },
    { component: UserPhoneNumberInput, props: { register, errors } },
    { component: UserFullNameInput, props: { register, errors } },
    { component: UserEmailInput, props: { register, errors, isRequired: false } },
    { component: TabelogUrlInput, props: { register, errors } },
    { component: InstagramUrlInput, props: { register, errors } },
    { component: TiktokUrlInput, props: { register, errors } },
    { component: XUrlInput, props: { register, errors } },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<IoMdPerson />} heading='エージェント編集'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={4}>
              {inputFields.map(({ component: C, props }, i) => <C key={i} {...props} />)}
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};