import React, { memo } from "react";
import { useChangeSoCouponStateByLineId } from "../../../hooks/socoupon/useChangeSoCouponStateByLineId";
import { useRecoilValue } from "recoil";
import { lineProfileState } from "../../../store/lineProfileState";
import { Box, Button, useDisclosure } from '@chakra-ui/react'
import { ConfirmCouponUseDialog } from "./ConfirmCouponUseDialog";
import { useFetchSoCouponUsageStatusByLineId } from "../../../hooks/socoupon/useFetchSoCouponUsageStatusByLineId";
import { customerRestaurantId } from "../../../store/customerRestaurantId";


type SoCouponUsageStatusBtnProps = {
  couponId: string;
}
export const SoCouponUsageStatusBtn: React.FC<SoCouponUsageStatusBtnProps> = memo(({couponId}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const lineProfile = useRecoilValue(lineProfileState)
  const restaurantId = useRecoilValue(customerRestaurantId)
  const lineId = lineProfile!.userId
  const { isUsed, isSubmitting, handleOnClick } = useChangeSoCouponStateByLineId(couponId, lineId, onClose)
  const { soCouponUsageStatus, isLoading } = useFetchSoCouponUsageStatusByLineId(restaurantId, lineId)
  if (isLoading || !soCouponUsageStatus) return null
  return (
    <Box textAlign='center'>
      {(soCouponUsageStatus === 'used' || isUsed) ? (
        <Button colorScheme='red' size='lg' isDisabled={true}>利用済み</Button>
      ) : soCouponUsageStatus === 'unused' ? (
        <Button colorScheme='messenger' size='lg' onClick={onOpen}>クーポンを利用する</Button>
      ) : soCouponUsageStatus === 'next' ? (
        <Button colorScheme='orange' size='lg'>次回来店時に利用可能</Button>
      ) : null}
      <ConfirmCouponUseDialog isOpen={isOpen} onClose={onClose} handleOnClick={handleOnClick} isLoading={isSubmitting}/>
    </Box>
  );
});