import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { MembershipHeaderRight } from './MembershipHeaderRight';
import { MembershipHeaderCenter } from './MembershipHeaderCenter';
import { useRecoilValue } from 'recoil';
import { lineProfileState } from '../../../store/lineProfileState';
import { ProfileIcon } from '../../molecules/ProfileIcon';
import { customerRestaurantId } from '../../../store/customerRestaurantId';

export const MembershipHeader: React.FC = () => {
  const lineProfile = useRecoilValue(lineProfileState)
  const restaurantId = useRecoilValue(customerRestaurantId)

  return (
    <Box as="header" bgColor='#5371FE' color='white' position="fixed" top='0' w='100%' zIndex={3}>
      <Flex
        alignItems='center'
        h='60px'
        p='0 8px'
        justify="space-between"
      >
        <ProfileIcon pictureUrl={lineProfile!.pictureUrl} />
        <MembershipHeaderCenter restaurantId={restaurantId!} />
        <MembershipHeaderRight />
      </Flex>
    </Box>
  );
};
