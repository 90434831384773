import React, { memo } from "react";
import { useFetchManlaboSurveySelectedQuestionsByRestaurantId } from "../../../hooks/manzokudoLabo/useFetchManlaboSurveySelectedQuestionsByRestaurantId";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { Box, Card, CardBody, CardFooter, CardHeader, HStack, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { RiSurveyLine } from "react-icons/ri";


export const ManLaboSurveySelectedQuestions: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { selectedQuestions, isLoading } = useFetchManlaboSurveySelectedQuestionsByRestaurantId(restaurantId)
  if (isLoading) return <CircularLoading />
  return (
    <Box m='auto' w={{ base: '100%', md: '80%', lg: '600px' }}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<RiSurveyLine />} heading='使用中アンケート質問'/>
        </CardHeader>
        <CardBody py={2}>
          <VStack spacing={2}>
            {selectedQuestions?.map((selectedQuestion) => (
              <Card key={selectedQuestion.id} colorScheme='blue' rounded='15px'  w='100%' >
                <CardBody p={3}>
                  <HStack fontSize='sm' fontWeight='bold' spacing={3}>
                    <Text m={0}>Q{selectedQuestion.order}</Text>
                    <Text m={0}>{selectedQuestion.question_content}</Text>
                  </HStack>
                </CardBody>
              </Card>
            ))}
          </VStack>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path={'edit'} text="編集" />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});