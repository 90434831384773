import { useRecoilValue } from "recoil";
import { totalState } from "../../store/totalState";
import { useCustomPagination } from "../useCustomPagenation";
import { useUpdateTotal } from "../useUpdateTotal";
import { useFetchCorporateRestaurantsPerPage } from "./useFetchCorporateRestaurantsPerPage";

export const useFetchPaginateCorporateRestaurants = (corporationId?: string) => {
  const total = useRecoilValue(totalState);
  const { pages, pagesCount, currentPage, setCurrentPage, pageSize } = useCustomPagination(total, 10);
  const { restaurantsPerPage, isLoading } = useFetchCorporateRestaurantsPerPage(pageSize, currentPage, corporationId)
  useUpdateTotal(restaurantsPerPage);

  return { restaurantsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage };
};