import React, { memo } from "react";
import { HStack, Avatar, Text } from '@chakra-ui/react'
import { formatDate } from "../../../../utils/formatDate";
import { LineAccountsTableHoc } from "../../../template/LineAccountsTableHoc";
import { LoadMoreButton } from "../../../atoms/button/LoadMoreBtn";
import { TableRowWithNavigationLayout } from "../../../template/table/TableRowWithNavigationLayout";
import { TableLayout } from "../../../template/table/TableLayout";


type LineAccounts = {
  id: string;
  customer: string;
  restaurant: string;
  line_id: string;
  display_name: string;
  picture_url: string;
  visit_count: string;
  last_visit: string;
  registered_date: string;
  customer_sex : string;
  customer_birth_year : string;
  customer_birth_month : string;
}[]
type UsersTableProps = {
  lineAccountsList: LineAccounts,
  isValidating: boolean,
  isLoading: boolean,
  setSize: (size: number | ((_size: number) => number)) => Promise<LineAccounts | undefined>,
  isReachingEnd: boolean
}
export const UsersTable: React.FC<UsersTableProps> = memo(({lineAccountsList, isValidating, isLoading, setSize, isReachingEnd}) => {
  return (
    <LineAccountsTableHoc isLoading={isLoading} data={lineAccountsList}>
      {(lineAccounts) => (
        <>
          <LineAccountsTable lineAccounts={lineAccounts} />
          <LoadMoreButton isValidating={isValidating} setSize={setSize} isReachingEnd={isReachingEnd} />
        </>
      )}
    </LineAccountsTableHoc>
  );
});


const LineAccountsTable: React.FC<{lineAccounts: LineAccounts}> = memo(({lineAccounts}) => {
  const headers = ['アカウント', '初回来店日', '最終来店日', '来店回数'];
  const rows = lineAccounts?.map((lineAccount) => (
    <TableRowWithNavigationLayout
      key={lineAccount.id}
      path={`../../line/accounts/${lineAccount.id}`}
      size='sm'
      childrenList={[
        <HStack>
          <Avatar src={lineAccount.picture_url} boxSize={{base: '30px', sm: '34px'}} />
          <Text overflowWrap="break-word" whiteSpace="normal" noOfLines={1} m={0}>{lineAccount.display_name}</Text>
        </HStack>,
        formatDate(lineAccount.registered_date),
        formatDate(lineAccount.last_visit),
        lineAccount.visit_count + '回'
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />
})