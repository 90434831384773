import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type nextRank = {
  name: string;
  visitCountsNeededToRankUp: number;
}

export const useFetchNextRank = (restaurantId: string, lineId: string) => {
  const { data: nextRank, error, isLoading: isLoadingNextRank } = useSwrNoAuth<nextRank>(`membership/restaurants/${restaurantId}/line-accounts/${lineId}/next-rank/`)
  return { nextRank, isLoadingNextRank, error };
};