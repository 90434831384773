import { Text, VStack } from "@chakra-ui/react";
import { useFetchCustomerRestaurant } from "../../../hooks/membership/useFetchCustomerRestaurant";

type MembershipHeaderCenterProps = {
  restaurantId: string;
}

export const MembershipHeaderCenter: React.FC<MembershipHeaderCenterProps> = ({restaurantId}) => {
  const { restaurant, error, isLoading } = useFetchCustomerRestaurant(restaurantId)
  if (isLoading || !restaurant) return null

  return (
    <VStack fontWeight='bold' spacing={0}>
      <Text m='0'>{restaurant.name}</Text>
      <Text m='0' fontSize='xs'>BLOCKs</Text>
    </VStack>
  );
}