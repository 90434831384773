import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const editMyCorporationSchema = yup.object().shape({
  user: yup.object().shape({
    username: yup.string().required('ユーザーネームを入力してください'),
    email: yup.string().required('メールアドレスを入力してください').email("メールアドレスを正しい形式で入力してください"),
    phone_number: yup.string().required('電話番号を入力してください'),
  }),
  corporation: yup.object().shape({
    name: yup.string().required('店舗名を入力してください'),
    address: yup.string().required('住所を入力してください').test('password-length', 'パスワードは4文字以上で入力してください', value => value.length >= 4),
    president_last_name: yup.string().required('代表者名を入力してください'),
    president_first_name: yup.string().required('代表者名を入力してください'),
    president_phone_number: yup.string().required('電話番号を入力してください'),
  }),
});


export const useEditMyCorporationForm = (user: any, corporation: any) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({
    resolver: yupResolver(editMyCorporationSchema),
  });
  useEffect(() => {
    if (user && corporation) {
      setValue('user.username', user.username);
      setValue('user.email', user.email);
      setValue('user.phone_number', user.phone_number);
      setValue('corporation.name', corporation.name);
      setValue('corporation.address', corporation.address);
      setValue('corporation.president_last_name', corporation.president_last_name);
      setValue('corporation.president_first_name', corporation.president_first_name);
      setValue('corporation.president_phone_number', corporation.president_phone_number);
    }
  }, [user, corporation]);

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};