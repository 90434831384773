import React, { memo } from "react";
import { Badge, Flex, Text, VStack } from "@chakra-ui/react";

type BenefitWithoutImgProps = {
  explanation: string;
  title: string;
}

export const BenefitWithoutImg: React.FC<BenefitWithoutImgProps> = memo(({explanation, title}) => {
  return (
    <VStack
      align="start"
      boxSize={{ base: '70vw', sm: '250px' }}
      bgColor="#f99e15"
      outline="2px solid rgb(255, 255, 255)"
      outlineOffset="-7px"
      p={{ base: '4.65vw', sm: 5 }}
      spacing={3}
      m='auto'
      fontWeight="bold"
    >
      <Flex w='100%' justifyContent='center'>
        <Badge bgColor='white' borderRadius='10px' fontSize={{ base: '3.2vw', sm: '14px' }} px={2} py='2px'>会員限定メニュー</Badge>
      </Flex>
      <Text color="#1E2336" fontSize={{ base: '5.1vw', sm: '20px' }} m='0'>{title}</Text>
      <Text color='#1E2336' fontSize={{ base: '2.8vw', sm: '12px' }} m='0'>{explanation}</Text>
    </VStack>
  );
});