import React from "react";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Textarea } from "@chakra-ui/react";


type TextTextareaProps = {
  register: any,
  errors: any,
}
export const TextTextarea: React.FC<TextTextareaProps> = ({register, errors}) => (
  <FormControl isRequired isInvalid={errors.text}>
    <FormLabel>本文</FormLabel>
    <Textarea
      {...register('text')}
      maxLength={60}
      placeholder='17時以降のご来店で特別なディナーセットが15%オフ。夜景と共にお楽しみください。有効期限: 2025年01月31日'
    />
    <FormHelperText>最大60文字</FormHelperText>
    <FormErrorMessage>{errors['text']?.message}</FormErrorMessage>
  </FormControl>
);