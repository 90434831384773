// src/components/ThankYouPage.js
import { Box, Center, Heading, Image, keyframes } from '@chakra-ui/react';
import React from 'react';

const shakeAnimation = keyframes`
0% { transform: translateX(0); }
25% { transform: translateX(-5px) rotate(0.5deg); }
50% { transform: translateX(5px) rotate(-0.5deg); }
75% { transform: translateX(-5px) rotate(0.5deg); }
100% { transform: translateX(0); }
`;
export const ThanksEn: React.FC = () => {
  return (
    <Center
      h="100vh"
      backgroundColor="#f8f8f8"
    >
      <Box
        textAlign="center"
        p="20px"
        backgroundColor="#fff"
        borderRadius="8px"
        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      >
      <Heading as='h1'size='3xl' letterSpacing='3px' >
        THANKS!
      </Heading>
        <p>Thank you for always using our service.</p>
        <Image
          src='/images/logo.png'
          animation={`${shakeAnimation} 0.5s ease-in-out infinite`}
          width="200px"
          margin="auto"
        />
      </Box>
    </Center>
  );
};