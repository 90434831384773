import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";


export const StaffAccordionMenu = () => {
  const links = [
    { to: 'restaurant-staff/dashboard', text: 'ダッシュボード' },
    { to: 'restaurant-staff', text: '一覧' },
  ];
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          従業員
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          {links.map(link => <AccordionItemLink key={link.to} to={link.to} text={link.text} />)}
          <AccordionItem border='none'>
            <AccordionButton px='10px' py={1} >
              <Box flex='1' textAlign='left' fontWeight='bold'>
                アンケート
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <AccordionList>
                <AccordionItem border='none'>
                  <AccordionButton px='10px' py={1} >
                    <Box flex='1' textAlign='left' fontWeight='bold'>
                      従業員
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <AccordionList>
                      <AccordionItemLink to={`restaurant-staff/survey/questions`} text="アンケート設定" />
                      <AccordionItemLink to={`restaurant-staff/survey/questions/selected`} text="アンケートセット" />
                    </AccordionList>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border='none'>
                  <AccordionButton px='10px' py={1} >
                    <Box flex='1' textAlign='left' fontWeight='bold'>
                      店舗
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <AccordionList>
                      <AccordionItemLink to={`restaurant-staff/restaurant/survey/questions`} text="アンケート設定" />
                      <AccordionItemLink to={`restaurant-staff/restaurant/survey/questions/selected`} text="アンケートセット" />
                    </AccordionList>
                  </AccordionPanel>
                </AccordionItem>
              </AccordionList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItemLink to='restaurant-staff/setting' text='設定' />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
};