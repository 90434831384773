import React, { memo } from "react";
import { Skeleton } from "@chakra-ui/react";
import { useFetchTodaysKuchikomyuOverallScoresCount } from "../../../hooks/kuchikomyu/useFetchTodaysKuchikomyuOverallScoresCount";
import { SmallCardWithIcon } from "../../molecules/card/SmallCardWithIcon";
import { FaUserPlus } from "react-icons/fa";


type TodaysKuchikomyuOverallScoreAnswerCountCardProps = {
  restaurantId: string;
}
export const TodaysKuchikomyuOverallScoreAnswerCountCard: React.FC<TodaysKuchikomyuOverallScoreAnswerCountCardProps> = memo(({restaurantId}) => {
  const { todaysKuchikomyuOverallScoresCount, isLoading } = useFetchTodaysKuchikomyuOverallScoresCount(restaurantId);
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <SmallCardWithIcon data={`${todaysKuchikomyuOverallScoresCount}人`} title='今日の口コミュ評価回答数'>
        <FaUserPlus size='30px'  />
      </SmallCardWithIcon>
    </Skeleton>
  );
});