import React, { memo } from "react"
import QRCode from "qrcode.react";
import { Button, Box, Text, VStack, Flex } from '@chakra-ui/react'
import { useParams } from "react-router-dom";
import { useChangeTableStatusOfUse } from "../../../../hooks/tableStock/useChangeTableStatusOfUse";

const frontUrl = process.env.REACT_APP_FRONT_URL

type TableQrCardProps = {
  tableId: string;
  tableName: string;
  inUse: boolean;
  authCode: number;
  index: number;
}
export const TableQrCard: React.FC<TableQrCardProps> = memo(({tableId, tableName, inUse, authCode, index}) => {
  const { restaurantId } = useParams()
  const { inUseState, authCodeState, isSubmitting, handleClickVisitBtn, handleClickLeaveBtn } = useChangeTableStatusOfUse(tableId, inUse, authCode);
  return (
    <VStack spacing={0} outline={`4px solid ${inUseState ? '#E53E3E' : '#0078FF'}`} outlineOffset='-3px' fontWeight='bold'>
      <Flex align='start' w='100%'>
        <Box bg={inUseState ? '#E53E3E' : '#0078FF'} color='white' width='30px' textAlign='center' fontSize='12px'>{index}</Box>
      </Flex>
      <VStack spacing={{base: 1, sm: 2}} justify='space-between' p='0 10px 10px 10px' fontSize={{base: 'xs', sm: 'md'}} w='100%' h='100%'>
        <Text textAlign='center' m='0'>{tableName}</Text>
        <Box w='100%'>
          {inUseState ? (
            <>
              <Box bg='red.500' color='white' borderRadius='10px' textAlign='center'>利用中</Box>
              <Box color='red.500' textAlign='center'>認証コード：{authCodeState}</Box>
            </>
          ) : (
            <Box bg='messenger.500' color='white' borderRadius='10px' textAlign='center'>利用できます</Box>
          )}
        </Box>
        <Box as={QRCode} value={`${frontUrl}check-table-security/${restaurantId}/${tableId}/`} boxSize={{base: '26vw!important', sm: '128px!important'}} />
        <Box w='100%' >
          {inUseState ? (
            <Button colorScheme="red" size={{base: 'sm', sm: 'md'}} w='100%' onClick={handleClickLeaveBtn} isLoading={isSubmitting}>退店</Button>
          ) : (
            <Button colorScheme="messenger" size={{base: 'sm', sm: 'md'}} w='100%' onClick={handleClickVisitBtn} isLoading={isSubmitting}>来店</Button>
          )}
        </Box>
      </VStack>
    </VStack>
  );
});