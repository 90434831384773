import React from 'react';
import { Box, Divider } from '@chakra-ui/react';
import { SidebarNavWrapper } from '../../template/SidebarNavWrapper';
import { ManagerSidebarMenu } from './ManagerSidebarMenu';
import { ManagerName } from '../../atoms/ManagerName';


export const ManagerSidebar: React.FC = () => {
  return (
    <Box>
      <Box px={4} py={3} fontSize='24px'>BLOCKs</Box>
      <Divider m='0' />
      <Box px={4} py={3}>
        <ManagerName/>
      </Box>
      <Box px={2}>
        <Divider m='0' />
      </Box>
      <SidebarNavWrapper>
        <ManagerSidebarMenu/>
      </SidebarNavWrapper>
    </Box>
  );
};