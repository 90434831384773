import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { memo } from "react"


type ExpirationDateInputProps = {
  register: any
  errors: any
}
export const ExpirationDateInput = memo(({register, errors}: ExpirationDateInputProps) => {
  return (
    <FormControl isRequired>
      <FormLabel>有効期限</FormLabel>
      <Input type="date" {...register('expiration_date')} />
      <FormErrorMessage>{errors.expiration_date?.message}</FormErrorMessage>
    </FormControl>
  )
})