import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const schema = yup.object().shape({
  managers: yup.array().of(yup.string().required('必須項目です')),
  name: yup.string().required('必須項目です'),
  email: yup.string().required('必須項目です'),
  cc_emails: yup.array().of(yup.string()),
});
export const useCreateTeamForm = () => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm({ resolver: yupResolver(schema) });
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};