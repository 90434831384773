import { Badge, Box, CardBody, CardFooter, CardHeader, IconButton, useDisclosure } from '@chakra-ui/react'
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { TableLayout } from "../../template/table/TableLayout";
import { TableRowLayout } from "../../template/table/TableRowLayout";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { RiSurveyLine } from "react-icons/ri";
import { memo } from 'react';
import { useDeleteWithMutate } from '../../../hooks/useDeleteWithMutate';
import { DeleteIcon } from '@chakra-ui/icons';
import { DeleteModal } from '../../molecules/DeleteModal';
import { KeyedMutator } from 'swr';
import { useFetchRestaurantSurveyQuestions } from '../../../hooks/restaurantStaff/useFetchRestaurantSurveyQuestions';


export const RestaurantSurveyQuestions = () => {
  const { restaurantId } = useParams()
  const { surveyQuestions, isLoading, mutate } = useFetchRestaurantSurveyQuestions(restaurantId)
  if (isLoading || !surveyQuestions) return <CircularLoading />
  const headers = ['質問', '回答数', ''];
  const rows = surveyQuestions.map((surveyQuestion) => (
    <TableRowLayout
      key={surveyQuestion.id}
      childrenList={[
        surveyQuestion.question,
        surveyQuestion.answer_count,
        surveyQuestion.is_selected ? (
          <Badge colorScheme='green'>使用中</Badge>
        ) : (
          <RestaurantSurveyQuestionDeleteModal id={surveyQuestion.id} mutate={mutate} question={surveyQuestion.question} />
        )
      ]}
    />
  ));
  return (
    <Box m='auto' w={{ base: '100%', md: '80%' }}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<RiSurveyLine />} heading='店舗アンケート一覧'/>
        </CardHeader>
        <CardBody py={2}>
          <TableLayout headers={headers} rows={rows || []} size='sm' />
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path={'register'} text="新規登録" />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
};


const RestaurantSurveyQuestionDeleteModal = memo(({id, mutate, question}: {id: string, mutate: KeyedMutator<any>, question: string}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDeleteWithMutate(`restaurant_staff/restaurant-survey/questions/${id}/`, onClose, mutate)
  return (
    <>
      <IconButton
        variant='ghost'
        colorScheme='red'
        aria-label='Delete'
        icon={<DeleteIcon />}
        size='xs'
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={question}
        onSubmit={onSubmit}
      />
    </>
  );
});
