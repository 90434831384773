import { useState } from 'react';
import { customAuthAxios } from '../../utils/customAuthAxios';

export const useChangeTableStatusOfUse = (tableId: string, inUse: boolean, authCode: number) => {
  const [inUseState, setInUse] = useState<boolean>(inUse);
  const [authCodeState, setAuthCode] = useState<number>(authCode);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const generateRandomNumber = () => {
    return Math.floor(10000 + Math.random() * 90000);
  };
  const handleClickVisitBtn = async () => {
    const authCode = generateRandomNumber()
    try {
      setIsSubmitting(true)
      const tableInUseRes = await customAuthAxios.put(
        `table_stock/tables/${tableId}/`,
        {in_use: true, auth_code: authCode}
      );
      setAuthCode(tableInUseRes.data.auth_code)
      setInUse(tableInUseRes.data.in_use)
    } catch (error:any) {
    } finally {
      setIsSubmitting(false)
    }
  };

  const handleClickLeaveBtn = async () => {
    try {
      setIsSubmitting(true)
      const tableInUseRes = await customAuthAxios.put(
        `table_stock/tables/${tableId}/`,
        {in_use: false}
      );
      setInUse(tableInUseRes.data.in_use)
    } catch (error:any) {
    } finally {
      setIsSubmitting(false)
    }
  }

  return { inUseState, authCodeState, isSubmitting, handleClickVisitBtn, handleClickLeaveBtn };
};