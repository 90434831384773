import useSWR from "swr";


type MultiMonthDispatchRequest = {
  id: string;
  corporation_name: string;
  restaurant_names: string[];
  team_name: string;
  period: string;
  number: number;
  message: string;
  unit_price: number;
  menu_specification_label: string;
  budget_limit: number;
  max_companions_allowed: number;
  is_transportation_cost_provided: boolean;
  dispatch_ng_days: string[];
  dispatch_ng_time_periods: string[];
  requested_at: string;
}
export const useFetchMultiMonthDispatchRequest = (requestId?: string,) => {
  const fetcherKey = requestId ? `kuitan/dispatch/requests/multi-month/${requestId}/` : null
  const { data: multiMonthDispatchRequest, error, isLoading } = useSWR<MultiMonthDispatchRequest>(fetcherKey)
  return { multiMonthDispatchRequest, isLoading, error };
};