import { memo } from "react";
import { VStack, Skeleton, Grid, GridItem, Text } from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import { useFetchBenchmarkRestaurantTabelogScores } from "../../../hooks/ifreaAnalytics/useFetchBenchmarkRestaurantTabelogScores";
import { useFetchBenchmarkRestaurantTabelogReviewCounts } from "../../../hooks/ifreaAnalytics/useFetchBenchmarkRestaurantTabelogReviewCounts";
import { useFetchBenchmarkRestaurantTabelogSaveCounts } from "../../../hooks/ifreaAnalytics/useFetchBenchmarkRestaurantTabelogSaveCounts";
import { useFetchBenchmarkRestaurant } from "../../../hooks/ifreaAnalytics/useFetchBenchmarkRestaurant";
import { LineGraphCard } from "../../molecules/card/LineGraphCard";
import { DateRangeFilter } from "../../molecules/DateRangeFilter";


export const TabelogScore = () => {
  const { benchmarkRestaurantId } = useParams();
  const [searchParams] = useSearchParams();
  const since = searchParams.get('since');
  const until = searchParams.get('until');
  const queryParams = buildQueryParams(since, until);
  return (
    <VStack align='start' spacing={4}>
      <BenchmarkRestaurantName benchmarkRestaurantId={benchmarkRestaurantId}/>
      <DateRangeFilter/>
      <Grid templateColumns='repeat(2, 1fr)' gap={4} w='100%'>
        <GridItem colSpan={2}>
          <ScoresGraphCard benchmarkRestaurantId={benchmarkRestaurantId} queryParams={queryParams}/>
        </GridItem>
        <GridItem colSpan={1}>
          <ReviewCountsGraphCard benchmarkRestaurantId={benchmarkRestaurantId} queryParams={queryParams}/>
        </GridItem>
        <GridItem colSpan={1}>
          <SaveCountsGraphCard benchmarkRestaurantId={benchmarkRestaurantId} queryParams={queryParams}/>
        </GridItem>
      </Grid>
    </VStack>
  );
};


const BenchmarkRestaurantName = ({ benchmarkRestaurantId }: { benchmarkRestaurantId?: string }) => {
  const { benchmarkRestaurant, isLoading } = useFetchBenchmarkRestaurant(benchmarkRestaurantId)
  if (isLoading || !benchmarkRestaurant) return null
  return <Text m={0} color='gray.500' fontSize='sm' borderBottom='1px' borderColor='gray.300'>{benchmarkRestaurant.name}</Text>
};

const buildQueryParams = (since: string|null, until: string|null) => {
  const queryParams: { since?: string; until?: string } = {};
  since && (queryParams.since = since);
  until && (queryParams.until = until);
  return queryParams;
};

const ScoresGraphCard = memo(({benchmarkRestaurantId, queryParams}: {benchmarkRestaurantId?: string, queryParams?: object}) => {
  const { tabelogScores, isLoading } = useFetchBenchmarkRestaurantTabelogScores(benchmarkRestaurantId, queryParams)
  return (
    <Skeleton isLoaded={!isLoading} rounded='md'>
      <LineGraphCard
        title='点数'
        labels={tabelogScores ? tabelogScores.map((data: any) => data.date) : []}
        values={tabelogScores ? tabelogScores.map((data: any) => data.score) : []}
        graphColorRgb='49, 130, 206'
        height='260px'
      />
    </Skeleton>
  )
})

const ReviewCountsGraphCard = memo(({benchmarkRestaurantId, queryParams}: {benchmarkRestaurantId?: string, queryParams?: object}) => {
  const { reviewCounts, isLoading } = useFetchBenchmarkRestaurantTabelogReviewCounts(benchmarkRestaurantId, queryParams)
  return (
    <Skeleton isLoaded={!isLoading} rounded='md' w='100%'>
      <LineGraphCard
        title='レビュー数'
        labels={reviewCounts ? reviewCounts.map((data: any) => data.date) : []}
        values={reviewCounts ? reviewCounts.map((data: any) => data.review_count) : []}
        graphColorRgb='49, 130, 206'
        height='250px'
      />
    </Skeleton>
  )
})

const SaveCountsGraphCard = memo(({benchmarkRestaurantId, queryParams}: {benchmarkRestaurantId?: string, queryParams?: object}) => {
  const { saveCounts, isLoading } = useFetchBenchmarkRestaurantTabelogSaveCounts(benchmarkRestaurantId, queryParams)
  return (
    <Skeleton isLoaded={!isLoading} rounded='md'>
      <LineGraphCard
        title='保存数'
        labels={saveCounts ? saveCounts.map((data: any) => data.date) : []}
        values={saveCounts ? saveCounts.map((data: any) => data.save_count) : []}
        graphColorRgb='49, 130, 206'
        height='250px'
      />
    </Skeleton>
  )
})