import { Box } from "@chakra-ui/react";
import { memo } from "react"
import { CustomerForm } from "../../../organisms/externalApi/line/CustomerForm";
import { useEditCustomer } from "../../../../hooks/externalApi/useEditCustomer";


type EditCustomerProps = {
  mutateKey: string;
  lineAccountId: string;
}
export const EditCustomer: React.FC<EditCustomerProps> = memo(({mutateKey, lineAccountId}) => {
  const onSubmit = useEditCustomer(lineAccountId, mutateKey)
  return (
    <Box p={4}>
      <CustomerForm {...{onSubmit}} />
    </Box>
  );
});