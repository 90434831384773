import { atom } from 'recoil';

type DateRange = {
  startDate: string | null;
  endDate: string | null;
};

export const reservationNumPeopleGraphDateRangeState = atom<DateRange>({
  key: 'reservationNumPeopleGraphDateRangeState',
  default: { startDate: null, endDate: null }
});