import { useNavigate } from "react-router-dom";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";
import { SelectedQuestions } from "./useFetchManlaboSurveySelectedQuestionsCustomerByRestaurantId";
import { ManLaboSetting } from "./useFetchManlaboSettingCustomerByRestaurantId";
import liff from '@line/liff';


export const useAnswerManLaboSurvey = (restaurantId: string, selectedQuestions: SelectedQuestions, lineAccountId: string, setting: ManLaboSetting) => {
  const navigate = useNavigate();
  const onSubmit = async (data:any) => {
    const questions = selectedQuestions.map(selectedQuestion => selectedQuestion.question);
    data['restaurant_id'] = restaurantId
    data['line_account_id'] = lineAccountId
    data['questions'] = questions
    try {
      await customNoAuthAxios.post('manzokudo_labo/survey/answers/customer/', data);
      const branchQuestionIndex = questions.findIndex(question => question === setting.branch_question);
      const branchQuestionScore = data.survey[branchQuestionIndex!].answer_score
      if (branchQuestionScore > setting.branch_score && setting.is_navigate_g_url) {
        await liff.openWindow({url: setting.g_url, external: true});
        liff.closeWindow();
      } else {
        navigate('/thanks', {replace: true});
      }
    } catch (error:any) {
    }
  };
  return onSubmit;
};