import { useParams } from "react-router-dom";
import { useRegisterKuitanSettingForm } from "../../../hooks/kuitan/useRegisterKuitanSettingForm";
import { useRegisterKuitanSetting } from "../../../hooks/kuitan/useRegisterKuitanSetting";
import { KuitanSettingForm } from "../../organisms/kuitan/KuitanSettingForm";


export const RegisterKuitanSetting = () => {
  const { restaurantId } = useParams();
  const { handleSubmit, register, isSubmitting, errors } = useRegisterKuitanSettingForm();
  const onSubmit = useRegisterKuitanSetting(restaurantId);
  return <KuitanSettingForm onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} errors={errors} register={register} />
};