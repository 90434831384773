import React, { memo } from "react";
import { Text, VStack, Divider } from '@chakra-ui/react'


type nextRank = {
  name: string;
  visitCountsNeededToRankUp: number;
}
type StampCardBottomProps = {
  nextRank?: nextRank;
}
export const StampCardBottom: React.FC<StampCardBottomProps> = memo(({nextRank}) => {
  return (
    <VStack spacing={2} w='100%'>
      <Divider m='0' borderColor='#032963' variant='dashed' />
      <Text fontSize={{ base: '3.26vw', sm: '14px' }} fontWeight='bold' m='0'>
        {nextRank ? `あと${nextRank.visitCountsNeededToRankUp}回来店で${nextRank.name}にランクアップ` : 'ご利用ありがとうございます'}
      </Text>
    </VStack>
  );
});