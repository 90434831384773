import React, { memo } from "react";
import { useFetchLineSegmentMsgHistoryPerPage } from "../../../../hooks/externalApi/line/useFetchLineSegmentMsgHistoryPerPage";
import { SegmentTableHoc } from "../../../template/SegmentTableHoc";
import { LoadMoreButton } from "../../../atoms/button/LoadMoreBtn";
import { useParams } from "react-router-dom";
import { TableLayout } from "../../../template/table/TableLayout";
import { TableRowWithNavigationLayout } from "../../../template/table/TableRowWithNavigationLayout";
import { formatDate } from "../../../../utils/formatDate";


export const SegmentMsgTable: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const {
    lineSegmentMsgHistory,
    isValidating,
    isLoading,
    setSize,
    isReachingEnd
  } = useFetchLineSegmentMsgHistoryPerPage(restaurantId);
  const headers = ['配信日時', '配信アカウント数', '対象来店回数', 'タイトル'];
  const rows = lineSegmentMsgHistory?.map((lineSegmentMsg) => (
    <TableRowWithNavigationLayout
      key={lineSegmentMsg.id}
      path={`msgs/${lineSegmentMsg.id}`}
      childrenList={[
        formatDate(lineSegmentMsg.send_at),
        lineSegmentMsg.line_accounts.length,
        lineSegmentMsg.visit_count,
        lineSegmentMsg.title
      ]}
    />
  ));
  return (
    <SegmentTableHoc isLoading={isLoading} data={lineSegmentMsgHistory}>
      <TableLayout headers={headers} rows={rows || []} />
      <LoadMoreButton isValidating={isValidating} setSize={setSize} isReachingEnd={isReachingEnd} />
    </SegmentTableHoc>
  );
});