import useSWR from "swr";


type HygieneRating = {
  [key: string]: number;
}
export const useFetchHygieneRating = (restaurantId?: string,) => {
  const fetcherKey = restaurantId ? `kuitan/restaurants/${restaurantId}/ratings/hygiene/` : null
  const { data: hygieneRating, error, isLoading } = useSWR<HygieneRating>(fetcherKey)
  return { hygieneRating, isLoading, error };
};