import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useErrorToast } from '../toast/useErrorToast';
import { customAuthAxios } from '../../utils/customAuthAxios';

const apiUrlLogin = `${process.env.REACT_APP_API_URL}authen/jwt/create/`;


export const useLoginAdmin = () => {
  const navigate = useNavigate();
  const showErrorToast = useErrorToast();

  const onSubmit = async (data:any) => {
    try {
      const authRes = await axios.post(apiUrlLogin, data, { headers: { 'Content-Type': 'application/json' } });
      const token = authRes.data.access;
      localStorage.clear();
      localStorage.setItem('localJWT', token);
      const userRes = await customAuthAxios.get(`accounts/users/me/`);
      const user = userRes.data;
      user.is_superuser ? navigate('/admin/teams') : showErrorToast("ユーザーネームまたはパスワードが誤っています。");
    } catch (error:any) {
      const errorMessage = (error.response?.status === 401 || error.response?.status === 404)
        ? "ユーザーネームまたはパスワードが誤っています。"
        : "予期せぬエラーが発生しました。";
      showErrorToast(errorMessage);
    }
  };
  return onSubmit;
};