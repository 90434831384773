import { Skeleton } from "@chakra-ui/react"
import { memo } from "react"


export const MenuImagesSkelton: React.FC = memo(() => {
  return (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <Skeleton key={index} w='100%' h='100%' aspectRatio={1 / Math.sqrt(2)} rounded='md' />
      ))}
    </>
  )
})