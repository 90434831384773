import useSWR from "swr";


type Notice = {
  id: string;
  title: string;
  content: string;
  img: string|null;
  url: string|null;
  read_by: string[];
  created_at: string;
}
export const useFetchNotice = (noticeId?: string) => {
  const fetcherKey = noticeId ? `general_setting/notices/${noticeId}/` : null;
  const { data: notice, error, isLoading } = useSWR<Notice>(fetcherKey)
  return { notice, isLoading, error };
};