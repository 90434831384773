import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const surveyQuestionSchema = yup.object().shape({
  question: yup.string().required('入力してください'),
});
export const useRegisterStaffSurveyQuestionForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(surveyQuestionSchema)});
  return {
    handleSubmit,
    register,
    errors,
    isSubmitting,
  };
};