import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Textarea, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { useRegisterKuchikomyuSettingForm } from "../../../hooks/kuchikomyu/useRegisterKuchikomyuSettingForm";
import { useRegisterKuchikomyuSetting } from "../../../hooks/kuchikomyu/useRegisterKuchikomyuSetting";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const RegisterKuchikomyuSetting = () => {
  const { restaurantId } = useParams();
  const { handleSubmit, register, control, isSubmitting, errors } = useRegisterKuchikomyuSettingForm()
  const onSubmit = useRegisterKuchikomyuSetting(restaurantId)
  const options = Array.from({ length: 5 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));
  const branchScoreError = errors.branch_score?.message;
  const gUrlError = errors.g_url?.message;
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='口コミュ設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.branch_score} w='100%'>
                <FormLabel>ブランチスコア</FormLabel>
                <Controller
                  name='branch_score'
                  control={control}
                  render={({ field }) => (
                    <ReactSelectWithChakraStyle
                      placeholder="選択"
                      options={options}
                      value={options.find(option => option.value === field.value)}
                      onChange={(option) => field.onChange(option?.value)}
                    />
                  )}
                />
                <FormErrorMessage>{branchScoreError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.g_url} w='100%'>
                <FormLabel>GoogleUrl</FormLabel>
                <Textarea {...register('g_url')} />
                <FormErrorMessage>{gUrlError}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.en_url} w='100%'>
                <FormLabel>英語版Url</FormLabel>
                <Textarea {...register('en_url')} />
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};