import { useRecoilValue } from "recoil";
import { totalState } from "../../store/totalState";
import { useCustomPagination } from "../useCustomPagenation";
import { useUpdateTotal } from "../useUpdateTotal";
import { useFetchManagersPerPage } from "./useFetchManagersPerPage";


export const useFetchPaginateManagers = (corporationId?: string) => {
  const total = useRecoilValue(totalState);
  const { pages, pagesCount, currentPage, setCurrentPage, pageSize } = useCustomPagination(total, 10);
  const { managersPerPage, isLoading } = useFetchManagersPerPage(pageSize, currentPage, corporationId)
  useUpdateTotal(managersPerPage);
  return { managersPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage };
};