import React from 'react';
import { Box, Divider } from '@chakra-ui/react';
import { SalesmanName } from '../../atoms/SalesmanName';
import { SalesmanSidebarMenu } from './SalesmanSidebarMenu';
import { SidebarNavWrapper } from '../../template/SidebarNavWrapper';


export const SalesmanSidebar: React.FC = () => {
  return (
    <Box>
      <Box px={4} py={3} fontWeight='bold' fontSize='24px' color='white'>BLOCKs</Box>
      <Divider m='0' />
      <Box px={4} py={3}>
        <SalesmanName/>
      </Box>
      <Box px={2}>
        <Divider m='0' />
      </Box>
      <SidebarNavWrapper>
        <SalesmanSidebarMenu/>
      </SidebarNavWrapper>
    </Box>
  );
};