import React, { memo } from "react";
import { Box, Stack } from "@chakra-ui/react";
import { RegisteredLineAccountsCard } from "./RegisteredLineAccountsCard";
import { LineRegistrationTrendsGraphCard } from "./LineRegistrationTrendsGraphCard";


type BottomDataSectionProps = {
  restaurantId: string;
}
export const BottomDataSection: React.FC<BottomDataSectionProps> = memo(({restaurantId}) => {
  return (
    <Stack direction={{base: 'column', sm: 'column', lg: 'row'}} gap={{base: 4, sm: 5, lg: 5}} >
      <Box w='100%'>
        <RegisteredLineAccountsCard restaurantId={restaurantId} />
      </Box>
      <Box w='100%'>
        <LineRegistrationTrendsGraphCard restaurantId={restaurantId} />
      </Box>
    </Stack>
  );
});