import useSWR from "swr";

type LineDev = {
  id: string;
  access_token: string;
  channel_secret: string;
}

export const useFetchLineDev = (lineDevId?: string) => {
  const fetcherKey = lineDevId ? `external_api/line-devs/${lineDevId}` : null;
  const { data: lineDev, error, isLoading } = useSWR<LineDev>(fetcherKey)
  return { lineDev, isLoading, error };
};