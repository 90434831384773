import { useSwrNoAuth } from "../../swr/useSwrNoAuth";


type CouponSegments = {
  id: string;
  coupon: {
    id: string;
    title: string;
    explanation: string;
    notes: string;
    expiration_date: string;
    img: string;
  }
  segment_count: number;
}[]
export const useFetchRouletteCouponSegmentsCustomerByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `games/roulette/restaurants/${restaurantId}/coupon/segments/customer/` : null;
  const { data: couponSegments, error, isLoading } = useSwrNoAuth<CouponSegments>(fetcherKey)
  return { couponSegments, isLoading, error };
};