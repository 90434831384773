import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { KuitanAgent } from "./useFetchKuitanAgent";


const schema = yup.object().shape({
  user: yup.object().shape({
    username: yup.string().required('ユーザーネームを入力してください'),
    email: yup.string().email("メールアドレスを正しい形式で入力してください"),
    phone_number: yup.string().required('電話番号を入力してください'),
    last_name: yup.string().required('姓を入力してください'),
    first_name: yup.string().required('名を入力してください'),
  }),
  account: yup.object().shape({
    tabelog: yup.string(),
    instagram: yup.string(),
    tiktok: yup.string(),
    x: yup.string(),
  }),
});
export const useEditKuitanAgentForm = (kuitanAgent?: KuitanAgent) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(schema)});
  useEffect(() => {
    if (kuitanAgent) {
      const { tabelog, instagram, tiktok, x } = kuitanAgent;
      const { username, email, phone_number, last_name, first_name } = kuitanAgent.user_data;
      setValue('user.username', username);
      setValue('user.email', email);
      setValue('user.phone_number', phone_number);
      setValue('user.last_name', last_name);
      setValue('user.first_name', first_name);
      setValue('account.tabelog', tabelog);
      setValue('account.instagram', instagram);
      setValue('account.tiktok', tiktok);
      setValue('account.x', x);
    }
  }, [kuitanAgent]);

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};