import React, { memo } from "react";
import { Box, FormControl, Input } from "@chakra-ui/react";

type LogoInputFieldProps = {
  register: any;
}

export const LogoInputField: React.FC<LogoInputFieldProps> = memo(({register}) => {

  return (
    <FormControl w='100%'>
      <Box as='label' fontWeight='bold'>店舗ロゴ画像</Box>
      <Input type="file" variant='unstyled' borderRadius='none' accept="image/*" {...register('restaurant.logo')} />
    </FormControl>
  );
});
