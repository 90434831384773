import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { kuchikomyuDateRangeState } from "../../store/kuchikomyuDateRangeState";

type KuchikomyuOverallScore = {
  overall_scores_1_count: number;
  overall_scores_1_percentage: number;
  overall_scores_2_count: number;
  overall_scores_2_percentage: number;
  overall_scores_3_count: number;
  overall_scores_3_percentage: number;
  overall_scores_4_count: number;
  overall_scores_4_percentage: number;
  overall_scores_5_count: number;
  overall_scores_5_percentage: number;
  overall_scores_average: number;
  overall_scores_count: number;
};

export const useFetchKuchikomyuOverallScore = (restaurantId: string) => {
  const dateRange = useRecoilValue(kuchikomyuDateRangeState)
  const { data: KuchikomyuOverallScore, error, isLoading } = useSWR<KuchikomyuOverallScore>(`kuchikomyu/overall-score/?restaurantId=${restaurantId}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`)
  return { KuchikomyuOverallScore, isLoading, error };
};