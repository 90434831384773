import React, { memo } from "react";
import { Text, Image, HStack, VStack, Spacer } from '@chakra-ui/react'

type DefaultRankCardCenterProps = {
  rank: string;
}

export const DefaultRankCardCenter: React.FC<DefaultRankCardCenterProps> = memo(({rank}) => {
  return (
    <HStack justifyContent='space-between' spacing={10} w='100%'>
      <Image
        src='https://storage.googleapis.com/blocks-api/images/membership-decoration2.png'
        alt=''
        boxSize={{ base: '14vw', sm: '60px' }}
      />
      <VStack align='center' spacing={0}>
        <Text color='#F6D474' fontSize={{ base: '5.6vw', sm: '30px' }} fontWeight='bold' m='0' textShadow='2px 2px 3px #a0a0a0'>会員ランク</Text>
        <Text fontSize={{ base: '7vw', sm: '24px' }} fontWeight='bold' m='0' textShadow='2px 2px 3px #a0a0a0'>{rank}</Text>
      </VStack>
      <Spacer />
    </HStack>
  );
});

