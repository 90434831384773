import { Route } from 'react-router-dom';
import KuitanAgentBase from '../components/template/base/KuitanAgentBase';
import { KuitanAgentBaseInfo } from '../components/pages/kuitan/KuitanAgentBaseInfo';
import { EditKuitanAgentBaseInfo } from '../components/pages/kuitan/EditKuitanAgentBaseInfo';
import { RatingsForKuitanAgent } from '../components/pages/kuitan/RatingsForKuitanAgent';
import { Rate } from '../components/pages/kuitan/Rate';


export const KuitanAgentRoutes = (
  <>
    <Route path="kuitan-agents/:kuitanAgentId/:restaurantId/rate" element={<Rate />} />
    <Route path="kuitan-agents/:kuitanAgentId" element={<KuitanAgentBase />}>
      <Route path="" element={<KuitanAgentBaseInfo />} />
      <Route path="edit" element={<EditKuitanAgentBaseInfo />} />
      <Route path="ratings" element={<RatingsForKuitanAgent />} />
    </Route>
  </>
);