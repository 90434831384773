import React, { memo } from "react";
import { Box, VStack } from '@chakra-ui/react'
import { CouponImg } from "./CouponImg";
import { DefaultCouponDesignLg } from "./DefaultCouponDesignLg";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";

type ValidCouponProps = {
  id: string;
  title: string;
  img: string | null;
}

export const ValidCoupon: React.FC<ValidCouponProps> = memo(({id, title, img}) => {

  return (
    <VStack spacing={1}>
      <Box h='45.8vw' w='100%'>
        {img ? <CouponImg img={img} /> : <DefaultCouponDesignLg title={title} />}
      </Box>
      <PageTransitionBtn path={`/membership/coupons/${id}`} text='クーポンを確認' />
    </VStack>
    );
});
