import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";
import { SelectedQuestions } from "./useFetchStaffSurveySelectedQuestions";


const schema = yup.object().shape({
  questions: yup.array().of(yup.string()).required('必須項目です'),
});
export const useEditStaffSurveySelectedQuestionsForm = (selectedQuestions?: SelectedQuestions) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({ resolver: yupResolver(schema) });
  useEffect(() => {
    if (selectedQuestions) {
      setValue('questions', selectedQuestions.map(question => question.question));
    }
  }, [selectedQuestions]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};