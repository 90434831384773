import { Route } from 'react-router-dom';
import { LineAccounts } from '../components/pages/dashboard/LineAccounts';
import { LineAccount } from '../components/pages/dashboard/LineAccount';


export const LineRoutes = (
  <Route path="line">
    <Route path="accounts" element={<LineAccounts />} />
    <Route path="accounts/:lineAccountId" element={<LineAccount />} />
  </Route>
);