import { useSetRecoilState } from "recoil";
import { googleReviewsDateRangeState } from "../../../store/googleReviewsDateRangeState";
import { googleReviewRatingState } from "../../../store/googleReviewRatingState";
import { googleReviewReplyState } from "../../../store/googleReviewReplyState";
import { useEffect } from "react";

export const useInitializeFilter = () => {
  const setDateRange = useSetRecoilState(googleReviewsDateRangeState);
  const setReviewRating = useSetRecoilState(googleReviewRatingState);
  const setReviewReplyState = useSetRecoilState(googleReviewReplyState);
  useEffect(() => {
    setDateRange({ since: null, until: null })
    setReviewRating(null)
    setReviewReplyState(null)
  }, []);
};