import React, { memo } from "react";
import { Center, Text, VStack } from "@chakra-ui/react";
import { BlocksLogoForCustomerPostScoreForm } from "../../atoms/BlocksLogoForCustomerPostScoreForm";
import { OverallScoreRating } from "./OverallScoreRating";
import { CustomerPostScoreBtn } from "../../atoms/button/CustomerPostScoreBtn";


type PostOverallScoreFormProps = {
  onSubmit: any;
  control: any;
  isSubmitting: boolean;
  errors: any;
}
export const PostOverallScoreForm: React.FC<PostOverallScoreFormProps> = memo(({onSubmit, control, isSubmitting, errors}) => {
  return (
    <VStack as="form" onSubmit={onSubmit} spacing={4}>
      <BlocksLogoForCustomerPostScoreForm />
      <Text fontWeight='bold' align='center' m='0'>
        本日はご来店いただき誠にありがとうございます。ご利用いただきまして感じた当店の総合評価を星5段階でお聞かせください！
      </Text>
      <Text fontWeight='bold' fontSize='2xl' m='0'>お店の総合評価</Text>
      <Center>
        <OverallScoreRating control={control} errors={errors} />
      </Center>
      <CustomerPostScoreBtn isSubmitting={isSubmitting} text='次へ'/>
    </VStack>
  );
});