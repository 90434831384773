import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { memo } from "react"
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from "recoil";
import { isLoadingState } from "../../../store/isLoadingState";
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from "../../molecules/form/ErrorMessage";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Rating } from "@mui/material";
import { lineAccountIdState } from "../../../store/fetchedData/lineAccountIdState";
import { SELECTED_QUESTION } from "../../../types/suervyTypes";
import { apiUrlSoCouponSurveyAnswer, apiUrlSoCouponSurveySelectedQuestion } from "../../../apiUrls/soCouponApiUrls";
import { surveySchema } from "../../../yupSchema/surveySchema";
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";
import { useSwrNoAuth } from "../../../hooks/swr/useSwrNoAuth";

const SRating = styled(Rating)`
  font-size: 3rem!important;
  margin-top: 8px;
`;

export const SoCouponSurvey: React.FC = memo(() => {
  const { restaurantId } = useParams();
  const [loading, setIsLoading] = useRecoilState(isLoadingState)
  const theme = createTheme();
  const lineAccountId = useRecoilValue(lineAccountIdState)
  const navigate = useNavigate();

  const { handleSubmit, register, formState: { errors }, control } = useForm({
    resolver: yupResolver(surveySchema),
  });


  const { data: surveys, isLoading } = useSwrNoAuth<SELECTED_QUESTION[]>(`${apiUrlSoCouponSurveySelectedQuestion}?restaurant_id=${restaurantId}`);
  if (!surveys || isLoading) return <CircularLoading />

  const onSubmit = async (data:any) => {
    setIsLoading(true)
    const questions = surveys?.map(survey => survey.question);
    data['restaurant_id'] = restaurantId
    data['line_account_id'] = lineAccountId
    data['questions'] = questions
    try {
      const res = await customNoAuthAxios.post(
        `${apiUrlSoCouponSurveyAnswer}`,
        data
      );
      navigate(`/thanks`);
    } catch (error:any) {
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <Box py='50px'>
      <Flex justifyContent='center'>
        <Image w='200px' borderRadius='10px' src="/images/logo.png" />
      </Flex>
      <Box px="3">
        <br />
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          {surveys.map((survey, index) => (
            <Box key={survey.id} mb='5'>
              <Text as='b' fontSize='sm'>{survey.question_content}</Text>
              <Box textAlign="center">
                <Controller
                  name={`survey.${index}.answer_score`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <ThemeProvider theme={theme}>
                      <SRating
                        name="score"
                        size="large"
                        onChange={(event, newValue) => {
                          field.onChange(newValue);
                        }}
                      />
                    </ThemeProvider>
                  )}
                />
              </Box>
              {<Box textAlign="center">
                <ErrorMessage errorMessage={errors.survey?.[index]?.answer_score?.message ?? ''} />
              </Box>}
            </Box>
          ))}
          <div>
            <Text as='b' fontSize='sm' >自由解答欄</Text>
            <textarea rows={5} className='form-control' {...register('answer_text')} />
            {errors.answer_text?.message && <ErrorMessage errorMessage={errors.answer_text.message} />}
          </div>
          <br />
          <Box textAlign="center">
            <Button colorScheme='messenger' w='75%' size='lg' type='submit' isDisabled={loading}>回答する</Button>
          </Box>
        </form>
      </Box>
    </Box>

  );
});


