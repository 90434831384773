import useSWR from "swr";


type MonthlyDispatchRequest = {
  id: string;
  corporation_name: string;
  restaurant_names: string[];
  team_name: string;
  month: string;
  number: number;
  message: string;
  unit_price: number;
  menu_specification_label: string;
  budget_limit: number;
  max_companions_allowed: number;
  is_transportation_cost_provided: boolean;
  dispatch_ng_days: string[];
  dispatch_ng_time_periods: string[];
  requested_at: string;
}
export const useFetchMonthlyDispatchRequest = (requestId?: string,) => {
  const fetcherKey = requestId ? `kuitan/dispatch/requests/monthly/${requestId}/` : null
  const { data: monthlyDispatchRequest, error, isLoading } = useSWR<MonthlyDispatchRequest>(fetcherKey)
  return { monthlyDispatchRequest, isLoading, error };
};