import React, { memo } from "react";
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Select, VStack, useDisclosure } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { RestaurantSidebarMenu } from "../../organisms/sidebar/RestaurantSidebarMenu";
import { SlMenu } from "react-icons/sl";
import { useFetchCorporateRestaurants } from "../../../hooks/restaurants/useFetchCorporateRestaurants";


export const CorporateRestaurantBase = () => {
  const { restaurantId } = useParams()
  if(!restaurantId) return null
  return (
    <VStack align='start' spacing={4}>
      <Box position="sticky" top='80px' zIndex={10}>
        <RestaurantName restaurantId={restaurantId}/>
      </Box>
      <Box position="fixed" bottom={4} right={4} zIndex={5}>
        <NavigationDrawer restaurantId={restaurantId}/>
      </Box>
      <Box w='100%'>
        <Outlet />
      </Box>
    </VStack>
  );
};


const NavigationDrawer: React.FC<{ restaurantId: string }> = memo(({ restaurantId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef(null)
  return (
    <>
      <Flex align='center' justify='center' cursor='pointer' ref={btnRef} rounded={30} backdropFilter='blur(7.5px)' bgColor='#ffffffe6' boxSize='60px' onClick={onOpen} boxShadow='0 0 14px #0000001a'>
        <SlMenu color='#718096' size='22px'/>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        blockScrollOnMount={false}
      >
        <DrawerOverlay bgColor='rgb(14, 15, 14, .2)' />
        <DrawerContent maxW="250px">
          <DrawerCloseButton />
          <DrawerHeader/>
          <DrawerBody fontWeight='bold' px={0}>
            <RestaurantSidebarMenu restaurantId={restaurantId}/>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
});

const RestaurantName: React.FC<{ restaurantId: string }> = memo(({ restaurantId }) => {
  const { corporationId } = useParams()
  const navigate = useNavigate()
  const location = useLocation();
  const { restaurants } = useFetchCorporateRestaurants(corporationId)
  if(!restaurants) return null
  const options = restaurants.map((restaurant) => ({
    label: restaurant.name,
    value: restaurant.id,
  }))
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const restaurantId = e.target.value;
    const newPath = location.pathname.replace(/restaurants\/[^/]+/, `restaurants/${restaurantId}`);
    navigate(newPath);
  };
  return (
    <Select size='xs' defaultValue={restaurantId} bg='rgba(255, 255, 255, 0.9)' rounded='md' onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </Select>
  )
})