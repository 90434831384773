import React, { memo } from "react";
import { useRecoilValue } from "recoil";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { lineProfileState } from "../../../store/lineProfileState";
import { useFetchLineSegmentMessagesPerPage } from "../../../hooks/membership/useFetchLineSegmentMessagesPerPage";
import { LoadMoreButton } from "../../atoms/button/LoadMoreBtn";
import { Box } from "@chakra-ui/react";
import { SegmentMsgField } from "../../organisms/membership/SegmentMsgField";
import { customerRestaurantId } from "../../../store/customerRestaurantId";

export const SegmentMessages: React.FC = memo(() => {
  const lineProfile = useRecoilValue(lineProfileState)
  const restaurantId = useRecoilValue(customerRestaurantId)

  const { lineSegmentMessages, isValidating, isLoading, setSize, isReachingEnd } = useFetchLineSegmentMessagesPerPage(restaurantId!, lineProfile!.userId)
  if (isLoading) return <CircularLoading />
  if (!lineSegmentMessages) return null

  return (
    <>
      <Box>
        {lineSegmentMessages.map((lineSegmentMessage) => (
          <SegmentMsgField key={lineSegmentMessage.id} {...lineSegmentMessage} />
        ))}
      </Box>
      <LoadMoreButton isValidating={isValidating} setSize={setSize} isReachingEnd={isReachingEnd} />
    </>
  );
});
