import { Box, Button, CardBody, CardFooter, CardHeader, IconButton, VStack } from "@chakra-ui/react";
import { memo } from "react"
import { useFetchRankSettings } from "../../../hooks/membership/useFetchRankSettings";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useRankSettingsForm } from "../../../hooks/membership/useRankSettingsForm";
import { useResetRankSettings } from "../../../hooks/membership/useResetRankSettings";
import { useSubmitRankSettings } from "../../../hooks/membership/useSubmitRankSettings";
import { useSyncMinVisits } from "../../../hooks/membership/useSyncMinVisits";
import { RankSettingsEditInputsField } from "../../organisms/membership/RankSettingsEditInputsField";
import { useSyncMaxVisits } from "../../../hooks/membership/useSyncMaxVisits";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { AddIcon, SettingsIcon } from "@chakra-ui/icons";


export const RankSettingsEdit: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { handleSubmit, isSubmitting, register, reset, watch, fields, append, remove, setValue } = useRankSettingsForm();
  useSyncMinVisits({ fields, setValue, watch });
  useSyncMaxVisits({ fields, setValue });
  const onSubmit = useSubmitRankSettings(restaurantId);
  const { rankSettings, isLoading } = useFetchRankSettings(restaurantId)
  useResetRankSettings(rankSettings, reset);
  if (isLoading) return <CircularLoading />
  return (
    <Box as="form" m='auto' w={{base: '100%', md: '700px'}} onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='ランク設定'/>
        </CardHeader>
        <CardBody py={2}>
          <VStack spacing={2}>
            <VStack align='start' spacing={3} w='100%'>
              {fields.map((item, index) => {
                const prevMaxVisits = parseInt(watch(`rankSettings.${index-1}.max_visits`, 1));
                return (
                  <RankSettingsEditInputsField
                    key={item.id}
                    index={index}
                    prevMaxVisits={prevMaxVisits}
                    register={register}
                    remove={remove}
                    fieldsLength={fields.length}
                  />
                );
              })}
            </VStack>
            <Box textAlign="right" w='100%'>
              <IconButton aria-label='add' icon={<AddIcon/>} colorScheme='green' size='sm' variant='outline' onClick={() => append({})}/>
            </Box>
          </VStack>
        </CardBody>
        <CardFooter>
          <Button colorScheme='messenger' size='sm' type='submit' isDisabled={fields.length === 0} isLoading={isSubmitting}>登録</Button>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});