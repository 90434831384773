import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const schema = yup.object().shape({
  dispatch: yup.object().shape({
    team: yup.string().required('必須項目です'),
    month: yup.string().required('必須項目です'),
    number: yup.string().required('必須項目です'),
    message: yup.string(),
    dispatch_ng_days: yup.array(),
    unit_price: yup.number().required('必須項目です'),
    menu_specification: yup.string().required('必須項目です'),
    budget_limit: yup.number().required('必須項目です'),
    max_companions_allowed: yup.number().required('必須項目です'),
    is_transportation_cost_provided: yup.boolean().required('必須項目です'),
  }),
  ng_time_period: yup.object().shape({
    since: yup.string(),
    until: yup.string(),
  }),
});
export const useRequestMonthlyDispatchFormForRestaurant = () => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, watch } = useForm({ resolver: yupResolver(schema) });
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
    watch,
  };
};