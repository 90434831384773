import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const registerRestaurantSchema = yup.object().shape({
  user: yup.object().shape({
    username: yup.string().required('ユーザーネームを入力してください'),
    password: yup.string().required('パスワードを入力してください').test('password-length', 'パスワードは4文字以上で入力してください', value => value.length >= 4),
    email: yup.string().required('メールアドレスを入力してください').email("メールアドレスを正しい形式で入力してください"),
    phone_number: yup.string().required('電話番号を入力してください')
  }),
  restaurant: yup.object().shape({
    name: yup.string().required('店名を入力してください'),
    address: yup.string().required('住所を入力してください'),
    manager_last_name: yup.string().required('代表者名を入力してください'),
    manager_first_name: yup.string().required('代表者名を入力してください'),
    manager_phone_number: yup.string().required('電話番号を入力してください'),
    corporation: yup.string().required('法人を選択してください'),
    plan: yup.string().required('プランを選択してください'),
    add_ons: yup.array().of(yup.string()),
    logo: yup.mixed()
  }),
});
export const useRegisterRestaurantForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, control } = useForm({
    resolver: yupResolver(registerRestaurantSchema),
  });
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
    control,
  };
};