import React, { memo } from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";


export const MembershipAccordionMenu: React.FC = memo(() => {
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          会員証
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItemLink to='membership/benefits' text="特典一覧" />
          <AccordionItemLink to='visit/qr-code' text="来店QR" />
          <AccordionItemLink to='membership/settings' text="設定" />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
});