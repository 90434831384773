
import { useParams } from 'react-router-dom';
import { useFetchCorporation } from '../../hooks/corporations/useFetchCorporation';


export const CorporationName: React.FC = () => {
  const { corporationId } = useParams();
  const { corporation } = useFetchCorporation(corporationId);
  if (!corporation) return null;
  return (
    <>
      {corporation.name}
    </>
  );
};