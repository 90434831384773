import React, { memo } from "react"
import { Grid, Center } from '@chakra-ui/react'
import { useDownloadQrCords } from "../../hooks/useDownloadQrCard";
import { QrCardsDownloadModal } from "./QrCardsDownloadModal";

type QrCardsDownloaderProps = {
  children: React.ReactNode
}
export const QrCardsDownloader: React.FC<QrCardsDownloaderProps> = memo(({children}) => {
  const { modalRef, handleDownload } = useDownloadQrCords();
  return (
    <QrCardsDownloadModal handleDownload={handleDownload}>
      <Center h='1200px'>
        <Grid ref={modalRef} gap={0} templateColumns='repeat(4, 1fr)' transform='rotate(90deg)'>
          {[...Array(8)].map((_, i) => (
            <React.Fragment key={i}>
              {children}
            </React.Fragment>
          ))}
        </Grid>
      </Center>
    </QrCardsDownloadModal>
  );
});