import React, { memo } from "react";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { SoCouponOverallScoreAnswerCountCard } from "../soCoupon/SoCouponOverallScoreAnswerCountCard";
import { getToday } from "../../../utils/getToday";

type SoCouponDataSectionProps = {
  restaurantId: string;
  startDate: string|null;
  endDate: string|null;
}

export const SoCouponDataSection: React.FC<SoCouponDataSectionProps> = memo(({restaurantId, startDate, endDate}) => {
  const today = getToday();
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(`../socoupon/scores`)}
      cursor='pointer'
      w='100%'
      position="relative"
      transition='0.2s' _hover={{transform: 'translateY(-5px)'}}
    >
      <SoCouponOverallScoreAnswerCountCard restaurantId={restaurantId} startDate={startDate||today} endDate={endDate||today} />
    </Box>
  );
});