import { useParams } from 'react-router-dom';
import { useFetchManager } from '../../hooks/manager/useFetchManager';
import { Skeleton } from '@chakra-ui/react';


export const ManagerName: React.FC = () => {
  const { managerId } = useParams();
  const { manager } = useFetchManager(managerId);
  if (!manager) return <Skeleton h='24px' w='100%' />;
  return <>{manager.name}</>
};