import { useMemo } from "react";
import { useRoulette } from 'react-hook-roulette';


type CouponSegments = {
  id: string;
  coupon: {
    id: string;
    title: string;
    explanation: string;
    notes: string;
    expiration_date: string;
    img: string;
  }
  segment_count: number;
}[]
export const useSetupCouponRoulette = (onOpen: () => void, couponSegments?: CouponSegments, segmentCount?: number) => {
  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  const items = useMemo(() => {
    if (!couponSegments || !segmentCount) return [];
    const allItems = couponSegments.flatMap(segment =>
      Array(segment.segment_count).fill({ name: '当たり' })
    );
    const filledItems = allItems.slice(0, segmentCount);
    while (filledItems.length < segmentCount) {
      filledItems.push({ name: 'はずれ' });
    }
    return shuffleArray(filledItems);
  }, [couponSegments, segmentCount]);


  const shuffledTheme = useMemo(() => shuffleArray([
    { bg: "#7F5BD4", color: "#fff" },
    { bg: "#E43E3F", color: "#fff" },
    { bg: "#0078FF", color: "#fff" },
    { bg: "#D53F8C", color: "#fff" },
    { bg: "#309794", color: "#fff" },
    { bg: "#EBC84B", color: "#fff" },
    { bg: "#DC6B1F", color: "#fff" },
    { bg: "#0BC5EA", color: "#fff" },
    { bg: "#36A168", color: "#fff" },
  ]), []);

  const options = {
    size: 316,
    style: {
      canvas: { bg: "transparent" },
      arrow: { bg: "#000", size: 10 },
      label: { font: "14px Arial", defaultColor: "#000" },
      pie: { border: true, borderColor: '#660000', borderWidth: .5, theme: shuffledTheme },
    },
  };

  const onSpinUp = () => {
    setTimeout(() => {
      onStop();
    }, 2000);
  };

  const onSpinEnd = () => {
    setTimeout(() => {
      onOpen();
    }, 500);
  };

  const { roulette, onStart, onStop, result } = useRoulette({ items, options, onSpinUp, onSpinEnd });

  return { roulette, onStart, result };
};