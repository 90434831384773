import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useExtractRestaurantIdFromUrl } from "../../../hooks/externalApi/line/useExtractRestaurantIdFromUrl";
import { LiffLoginProcess } from "../../organisms/LiffLoginProcess";
import { AnswerRestaurantSurvey } from "./AnswerRestaurantSurvey";


export const RestaurantSurveyLiffApp = () => {
  const restaurantId = useExtractRestaurantIdFromUrl()
  if (restaurantId === null) return <CircularLoading />
  return (
    <LiffLoginProcess restaurantId={restaurantId} liffType="restaurant-survey">
      <AnswerRestaurantSurvey restaurantId={restaurantId} />
    </LiffLoginProcess>
  );
};