import { kuitanAgentGroupId } from '../../groupNumber';
import { useNavigate } from 'react-router-dom';
import { customNoAuthAxios } from '../../utils/customNoAuthAxios';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useErrorToast } from '../toast/useErrorToast';


export const useSignUpKuitanAgent = () => {
  const showErrorToast = useErrorToast();
  const navigate = useNavigate();

  const onSubmit = async (data:any) => {
    const userData = data.user;
    userData.groups = [kuitanAgentGroupId]
    const loginValues: { username: string; password: string } = {
      username: userData.username,
      password: userData.password
    };
    try {
      const userRes = await customNoAuthAxios.post('authentication/signup/', userData);
      const authRes = await customNoAuthAxios.post('authen/jwt/create/', loginValues);

      const token = authRes.data.access;
      localStorage.clear();
      localStorage.setItem('localJWT', token);

      const accountData = data.account
      const userId = userRes.data.id;
      accountData.user = userId
      const agentRes = await customAuthAxios.post('accounts/kuitan-agents/', accountData);
      navigate(`/kuitan-agents/${agentRes.data.id}`)
    } catch (error:any) {
      if (error.response.data.username) {
        showErrorToast(error.response.data.username);
      } else {
        showErrorToast("予期せぬエラーが発生しました。");
      }
    }
  };
  return onSubmit;
};