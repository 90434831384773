import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customNoAuthAxios } from '../../utils/customNoAuthAxios';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';
import { useErrorToast } from '../toast/useErrorToast';
import { corporationGroupId } from '../../groupNumber';


export const useRegisterCorporation = () => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();
  const onSubmit = useCallback(async (data: any) => {
    data.user.groups = [corporationGroupId]
    try {
      const userRes = await customNoAuthAxios.post(
        'authentication/signup/',
        data.user
      );
      const userId = userRes.data.id
      data.corporation.user = userId
      await customAuthAxios.post(
        'accounts/corporations/',
        data.corporation
      );
      showToast('登録が完了しました')
      navigate(-1);
    } catch (error:any) {
      showErrorToast(error.response.data.username)
    }
  }, [navigate]);
  return onSubmit;
};