import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { UsersTable } from "../../../organisms/externalApi/line/UsersTable";
import { CardBody, CardHeader } from "@chakra-ui/react";
import { RiUserReceived2Line } from "react-icons/ri";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { useFetchLineSegmentDeliveryReceivedAccountPerPage } from "../../../../hooks/externalApi/line/useFetchLineSegmentDeliveryReceivedAccountPerPage";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";


export const LineSegmentCouponReceivedUser: React.FC = memo(() => {
  const { couponId } = useParams();
  const {
    lineAccountsList,
    isValidating,
    isLoading,
    setSize,
    isReachingEnd
  } = useFetchLineSegmentDeliveryReceivedAccountPerPage(`membership/line/segment-coupons/${couponId}/received-users/`)
  if (isLoading || !lineAccountsList) return <CircularLoading/>
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<RiUserReceived2Line />} heading='クーポン受信ユーザー'/>
      </CardHeader>
      <CardBody pt={2}>
        <UsersTable lineAccountsList={lineAccountsList} isValidating={isValidating} isLoading={isLoading} setSize={setSize} isReachingEnd={isReachingEnd} />
      </CardBody>
    </DarkShadowCard>
  );
});