import useSWR from "swr";


type Restaurants = {
    id: string;
    name: string;
}[];
export const useFetchCorporateRestaurants = (corporationId?: string) => {
  const fetcKey = `accounts/corporations/${corporationId}/restaurants/`
  const { data: restaurants, error, isLoading } = useSWR<Restaurants>(fetcKey)
  return { restaurants, isLoading, error };
};