import { useParams } from "react-router-dom";
import { RouletteCouponForm } from "../../../organisms/games/roulette/RouletteCouponForm";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { useEditRouletteCouponForm } from "../../../../hooks/games/roulette/useEditRouletteCouponForm";
import { useEditRouletteCouponAndSegment } from "../../../../hooks/games/roulette/useEditRouletteCouponAndSegment";
import { useFetchRouletteCouponSegment } from "../../../../hooks/games/roulette/useFetchRouletteCouponSegment";


export const EditRouletteCoupon = () => {
  const {segmentId } = useParams();
  const { couponSegment, isLoading } = useFetchRouletteCouponSegment(segmentId)
  const { handleSubmit, register, isSubmitting, errors } = useEditRouletteCouponForm(couponSegment)
  const onSubmit = useEditRouletteCouponAndSegment(couponSegment?.coupon.id, segmentId)
  if (isLoading) return <CircularLoading />
  return (
    <RouletteCouponForm
      register={register}
      errors={errors}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};