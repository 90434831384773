import { memo } from "react"
import { useParams } from "react-router-dom";
import { useFetchLineAccountsForReviewCheck } from "../../../../hooks/externalApi/google/useFetchLineAccountsForReviewCheck";
import { Controller } from "react-hook-form";
import { FormControl, FormLabel, Grid, Radio, RadioGroup } from "@chakra-ui/react";


export const NotificationRecipientsSelect: React.FC<{control: any}> = memo(({ control }) => {
  const { restaurantId } = useParams()
  const { lineAccounts, isLoading } = useFetchLineAccountsForReviewCheck(restaurantId)
  if (isLoading || !lineAccounts) return null
  return (
    <Controller
      name="notification_recipients"
      control={control}
      render={({ field }) => (
        <FormControl w='100%'>
          <FormLabel>通知先</FormLabel>
          {!lineAccounts.length && <>通知先アカウントが登録されてません</>}
          <RadioGroup {...field}>
            <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)'}} gap={2}>
              {lineAccounts.map((account) => (
                <Radio key={account.id} value={account.id} spacing={1} m='0'>{account.display_name}</Radio>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      )}
    />
  )
});