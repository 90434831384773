import React, { memo } from "react";
import { CardHeader, CardBody, VStack, Box, Divider, CardFooter, HStack, IconButton, useDisclosure } from '@chakra-ui/react'
import { CardHeading } from "../../molecules/card/CardHeading";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { SettingItem } from "../../organisms/SettingItem";
import { RiTeamFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useDelete } from "../../../hooks/useDelete";
import { DeleteModal } from "../../molecules/DeleteModal";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchKuitanTeam } from "../../../hooks/kuitan/useFetchKuitanTeam";


export const Team = () => {
  const { teamId } = useParams();
  const { team, isLoading } = useFetchKuitanTeam(teamId);
  if (isLoading || !team) return <CircularLoading />
  const { id, name, email, cc_emails, dispatch_count } = team;
  const historyDetails = [
    { label: '責任者', value: team.manager_names.join('\n') },
    { label: 'メールアドレス To', value: email },
    { label: 'メールアドレス Cc', value: cc_emails.join('\n') },
    { label: '派遣回数', value: dispatch_count + '回' },
  ];
  return (
    <DarkShadowCard w='100%'>
      <CardHeader>
        <CardHeading icon={<RiTeamFill />} heading={name}/>
      </CardHeader>
      <CardBody py={2}>
        <VStack align='start' fontSize='sm' fontWeight='bold' spacing={4} w='100%'>
          <Divider my={0} />
          {historyDetails.map((detail) => (
            <React.Fragment key={detail.label}>
              <Box w='100%'>
                <SettingItem label={detail.label} content={detail.value}/>
              </Box>
              <Divider my={0} />
            </React.Fragment>
          ))}
        </VStack>
      </CardBody>
      <CardFooter>
        <HStack>
          <EditAndDeleteIcons id={id} title={name} />
        </HStack>
      </CardFooter>
    </DarkShadowCard>
  );
};


const EditAndDeleteIcons: React.FC<{ id: string, title: string }> = memo(({ id, title }) => {
  const navigate = useNavigate()
  return (
    <HStack align='start' w='100%'>
      <IconButton
        colorScheme='messenger'
        aria-label='Edit Team'
        icon={<EditIcon />}
        size='sm'
        variant='ghost'
        onClick={() => navigate('edit')}
      />
      <DeleteTeamModal id={id} title={title} />
    </HStack>
  )
})

const DeleteTeamModal: React.FC<{ id: string, title: string }> = memo(({id, title}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDelete(`kuitan/teams/${id}/`, onClose)
  return (
    <>
      <IconButton
        colorScheme='red'
        aria-label='Delete Team'
        icon={<DeleteIcon />}
        size='sm'
        variant='ghost'
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={title}
        onSubmit={onSubmit}
      />
    </>
  );
});