import React, { memo } from "react";
import { Skeleton } from "@chakra-ui/react";
import { useFetchNotActiveLineAccountsCount } from "../../../hooks/externalApi/line/useFetchNotActiveLineAccountsCount";
import { SmallCardWithIcon } from "../../molecules/card/SmallCardWithIcon";
import { ImUserMinus } from "react-icons/im";


type BlockCountCardProps = {
  restaurantId: string;
  startDate: string|null;
  endDate: string|null;
}
export const BlockCountCard: React.FC<BlockCountCardProps> = memo(({restaurantId, startDate, endDate}) => {
  const { notActiveLineAccountsCount, isLoading } = useFetchNotActiveLineAccountsCount(restaurantId, startDate, endDate);
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl' w='100%'>
      <SmallCardWithIcon data={`${notActiveLineAccountsCount}人`} title="ブロック数">
        <ImUserMinus size='30px'/>
      </SmallCardWithIcon>
    </Skeleton>
  );
});