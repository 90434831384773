import React, {memo} from 'react';
import { CustomerMenu } from "./CustomerMenu";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useExtractRestaurantIdAndTableIdFromUrl } from '../../../hooks/externalApi/line/useExtractRestaurantIdAndTableIdFromUrl';
import { LiffLoginProcess } from '../../organisms/LiffLoginProcess';


export const MenuLiffApp: React.FC = memo(() => {
  const { restaurantId, tableId } = useExtractRestaurantIdAndTableIdFromUrl()
  if (restaurantId===null || tableId===null) return <CircularLoading />
  return (
    <LiffLoginProcess restaurantId={restaurantId} liffType="menu">
      <CustomerMenu restaurantId={restaurantId} tableId={tableId} />
    </LiffLoginProcess>
  );
});