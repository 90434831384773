import React, { memo } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  useDisclosure,
} from '@chakra-ui/react'
import { useSubmitLiffDelete } from "../../../../hooks/externalApi/line/useSubmitLiffDelete";

type DeleteModalProps = {
  liffId: string;
  liffName: string;
  mutateKey: string;
}
export const LiffDeleteModal: React.FC<DeleteModalProps> = memo(({liffId, mutateKey, liffName}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { handleSubmit, formState: { isSubmitting }  } = useForm();
  const onSubmit = useSubmitLiffDelete(liffId, onClose, mutateKey)
  return (
    <>
      <Box fontWeight='bold' width='100%' onClick={onOpen} color='red'>
        削除
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size='xs'  >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>注意！</ModalHeader>
          <ModalCloseButton />
            <ModalBody>
              <Box fontWeight='bold' fontSize='16px'>「{liffName}」を削除してもよろしいですか？</Box>
              <Box fontWeight='bold' fontSize='16px'>この操作は元に戻せません。</Box>
            </ModalBody>
            <ModalFooter>
              <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
                <Button colorScheme='red' size='sm' mr={3} type="submit" isLoading={isSubmitting} >削除</Button>
                <Button colorScheme='gray' size='sm' onClick={onClose} isDisabled={isSubmitting}>キャンセル</Button>
              </form>
            </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
