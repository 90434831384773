import React, { memo } from "react";
import { Box, Card, CardBody, Flex, Text } from "@chakra-ui/react";
import { MdCardMembership } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const RankServiceSection: React.FC = memo(() => {
  const navigate = useNavigate()

  return (
    <Box onClick={() => navigate('/membership/customer-benefits')} px={3} w='100%'>
      <Card bgColor='#5371FE' borderRadius='20px' variant='filed'>
        <CardBody p={3}>
          <Flex align='center' color='white' justify='space-between'  w='100%'>
            <Text fontSize='28px' fontWeight='bold' m='0'>会員ランク<br/>サービスを確認</Text>
            <MdCardMembership size='120px' />
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
});