import React, { memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import './styles.css';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards } from 'swiper/modules';
import { RankCard } from "./RankCard";
import { Box } from "@chakra-ui/react";
import { StampCard } from "./StampCard";

type MembershipCardSectionProps = {
  restaurantId: string;
  lineId: string;
  displayName: string;
}

export const MembershipCardSection: React.FC<MembershipCardSectionProps> = memo(({restaurantId, lineId, displayName}) => {

  return (
    <Box bgColor='#5371FE' px={3} py={5} w='100%'>
      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
      >
        <SwiperSlide><RankCard restaurantId={restaurantId} lineId={lineId} displayName={displayName} /></SwiperSlide>
        <SwiperSlide><StampCard restaurantId={restaurantId} lineId={lineId} /></SwiperSlide>
      </Swiper>
    </Box>
  );
});