import { Box, CardBody, CardFooter, CardHeader, Checkbox, FormControl, FormErrorMessage, FormLabel, Input, VStack } from "@chakra-ui/react";
import { memo, useEffect, useState } from "react"
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { RiCoupon2Line } from "react-icons/ri";
import { TitleInput } from "../../molecules/form/TitleInput";
import { ExplanationTextarea } from "../../molecules/form/ExplanationTextarea";
import { NotesTextarea } from "../externalApi/line/NotesTextarea";


type SoCouponFormProps = {
  img?: string;
  handleSubmit: any;
  register: any;
  isSubmitting: boolean;
  errors: any;
  setValue: any;
}
export const SoCouponForm: React.FC<SoCouponFormProps> = memo(({img, handleSubmit, register, isSubmitting, errors, setValue}) => {
  return (
    <Box m='auto' w={{ base: '100%', sm: '90%', md: '580px' }}>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<RiCoupon2Line/>} heading='即ーポン'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={4}>
              <ImgInput img={img} register={register} setValue={setValue} />
              <TitleInput register={register} errors={errors} />
              <ExplanationTextarea register={register} errors={errors} />
              <NotesTextarea register={register} errors={errors} />
              <ExpirationDateInput register={register} errors={errors} />
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});


type ImgInputProps = {
  img?: string;
  register: any;
  setValue: any;
}
const ImgInput: React.FC<ImgInputProps> = memo(({img, register, setValue}) => {
  const [useDefaultImg, setUseDefaultImg] = useState<boolean>(img ? false : true);
  useEffect(() => {
    if (useDefaultImg) setValue('img', 'default');
  }, [useDefaultImg]);
  useEffect(() => {
    setUseDefaultImg(img ? false : true)
  }, [img]);
  return (
    <FormControl>
      <FormLabel>クーポン画像</FormLabel>
      <VStack align='start' spacing={1}>
        <input type="file" accept="image/*" {...register('img')} disabled={useDefaultImg}  />
        <Checkbox isChecked={useDefaultImg} onChange={() => setUseDefaultImg(!useDefaultImg)} size='sm' m='0'>デフォルト画像を使用</Checkbox>
      </VStack>
    </FormControl>
  )
})


type ExpirationDateInputProps = {
  register: any,
  errors: any,
}
const ExpirationDateInput: React.FC<ExpirationDateInputProps> = ({register, errors}) => (
  <FormControl isRequired isInvalid={errors.expiration_date}>
    <FormLabel>有効期限</FormLabel>
    <Input
      {...register('expiration_date')}
      type="date"
    />
    <FormErrorMessage>{errors['expiration_date']?.message}</FormErrorMessage>
  </FormControl>
);