import { useFetchPaginateAssignedRestaurants } from "../../../hooks/restaurants/useFetchPaginateAssignedRestaurants";
import { TableLayout } from "../../template/table/TableLayout";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { CiShop } from "react-icons/ci";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { Pagination } from "../../molecules/pagenation/Pagination";


export const AssignedRestaurants = () => {
  const { salesmanId } = useParams()
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const pageSize = 10;
  const { paginatedRestaurants, isLoading } = useFetchPaginateAssignedRestaurants(pageSize, page, salesmanId)
  if (isLoading || !paginatedRestaurants) return <CircularLoading />
  const headers = ['店舗名', 'ユーザーネーム', '代表者名', '代表者電話番号', '法人'];
  const rows = paginatedRestaurants.restaurants.map((restaurant) => (
    <TableRowWithNavigationLayout
      key={restaurant.id}
      path={`${restaurant.id}`}
      childrenList={[
        restaurant.name,
        restaurant.username,
        restaurant.manager_last_name + restaurant.manager_first_name,
        restaurant.manager_phone_number,
        restaurant.corporation_name
      ]}
    />
  ));
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<CiShop />} heading='店舗一覧'/>
      </CardHeader>
      <CardBody py={2}>
        <TableLayout headers={headers} rows={rows || []} size='sm' />
      </CardBody>
      <CardFooter>
        <Pagination totalItems={paginatedRestaurants.count} pageSize={pageSize}/>
      </CardFooter>
    </DarkShadowCard>
  );
};