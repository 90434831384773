
const shuffleString = (inputString:string) => {
  const array = inputString.split('');
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array.join('');
};

export const getRandomStringWithDate = () => {
  const randomString = Math.random().toString(32).substring(2);
  const currentDate = new Date();
  const dateString = currentDate.toISOString().split('T')[0];
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');
  return shuffleString(randomString + dateString + seconds);
};
