import React, { memo } from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import { BenefitWithImg } from "./BenefitWithImg";
import { BenefitWithoutImg } from "./BenefitWithoutImg";

type BenefitContentsProps = {
  title: string;
  explanation: string;
  notes: string;
  img: string | null;
}
export const BenefitContents: React.FC<BenefitContentsProps> = memo(({title, explanation, notes, img}) => {
  return (
    <VStack spacing='3'>
      <Box textAlign='left' w='100%'>
        <Text fontWeight='bold' m='0'>{title}</Text>
      </Box>
      <Box w='100%'>
        {img ? (
          <BenefitWithImg img={img} explanation={explanation} />
        ) : (
          <BenefitWithoutImg explanation={explanation} title={title} />
        )}
      </Box>
      <Box fontSize='sm' w='100%' >
        <Text color='red.500' m='0'>■注意事項</Text>
        <Text m='0'>{notes}</Text>
      </Box>
    </VStack>
  );
});