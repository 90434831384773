import { Router } from "./router/Router"
import 'react-toastify/dist/ReactToastify.css';
import { Providers } from './Providers';
import 'swiper/swiper-bundle.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Providers>
        <Router />
      </Providers>
    </div>
  );
}

export default App;
