import { useNavigate } from "react-router-dom";
import { useSuccessToast } from "../../toast/useSuccessToast";
import { customAuthAxios } from "../../../utils/customAuthAxios";


export const useRegisterRouletteCouponAndSegments = (restaurantId: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      const fields = ["title", "explanation", "notes", "expiration_date"];
      uploadData.append("restaurant", restaurantId);
      fields.forEach(field => uploadData.append(field, data[field]));
      if (data.img && data.img[0]) {
        uploadData.append("img", data.img[0]);
      }
      const couponRes = await customAuthAxios.post('games/roulette/coupons/', uploadData)
      await customAuthAxios.post('games/roulette/coupon/segments/', {roulette_coupon: couponRes.data.id, segment_count: data.segment_count})
      showToast('クーポンを登録しました');
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};