import React from 'react';
import Select, { Props as SelectProps } from 'react-select';
import { useTheme } from '@chakra-ui/react';

interface ReactSelectWithChakraStyleProps extends SelectProps<any, any> {}

export const ReactSelectWithChakraStyle: React.FC<ReactSelectWithChakraStyleProps> = (props) => {
  const theme = useTheme();
  const customStyles = {
    control: (provided:any) => ({
      ...provided,
      borderRadius: theme.radii.md,
      borderColor: theme.colors.gray[200],
      '&:hover': {
        borderColor: theme.colors.gray[300],
      },
    }),
    placeholder: (provided:any) => ({
      ...provided,
      color: theme.colors.gray[500],
    }),
  };

  return <Select styles={customStyles} {...props} />;
};
