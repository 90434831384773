import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Skeleton, Switch, Textarea, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { useEditStaffSettingFormForAdmin } from "../../../hooks/restaurantStaff/useEditStaffSettingFormForAdmin";
import { useEditStaffSettingByAdmin } from "../../../hooks/restaurantStaff/useEditStaffSettingByAdmin";
import { useFetchStaffSetting } from "../../../hooks/restaurantStaff/useFetchStaffSetting";


export const EditStaffSettingByAdmin = () => {
  const { settingId } = useParams();
  const { staffSetting, isLoading } = useFetchStaffSetting(settingId);
  const { handleSubmit, register, control, isSubmitting, errors } = useEditStaffSettingFormForAdmin(staffSetting)
  const onSubmit = useEditStaffSettingByAdmin(settingId)
  const options = Array.from({ length: 5 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));
  const restaurantBranchScoreError = errors.restaurant_branch_score?.message;
  const staffBranchScoreError = errors.staff_branch_score?.message;
  const gUrlError = errors.g_url?.message;
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='スタッフ設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.restaurant_branch_score} w='100%'>
                <FormLabel>店舗ブランチスコア</FormLabel>
                <Skeleton isLoaded={!isLoading} w='100%' borderRadius={5}>
                  <Controller
                    name='restaurant_branch_score'
                    control={control}
                    render={({ field }) => (
                      <ReactSelectWithChakraStyle
                        placeholder="選択"
                        options={options}
                        value={options.find(option => option.value === field.value)}
                        onChange={(option) => field.onChange(option?.value)}
                      />
                    )}
                  />
                </Skeleton>
                <FormErrorMessage>{restaurantBranchScoreError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.staff_branch_score} w='100%'>
                <FormLabel>従業員ブランチスコア</FormLabel>
                <Controller
                  name='staff_branch_score'
                  control={control}
                  render={({ field }) => (
                    <ReactSelectWithChakraStyle
                      placeholder="選択"
                      options={options}
                      value={options.find(option => option.value === field.value)}
                      onChange={(option) => field.onChange(option?.value)}
                    />
                  )}
                />
                <FormErrorMessage>{staffBranchScoreError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.g_url} w='100%'>
                <FormLabel>GoogleUrl</FormLabel>
                <Skeleton isLoaded={!isLoading} w='100%' borderRadius={5}>
                  <Textarea {...register('g_url')} />
                </Skeleton>
                <FormErrorMessage>{gUrlError}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>GoogleUrlへの遷移</FormLabel>
                <Controller
                  name='is_navigate_g_url'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      size='md'
                      mb='0'
                      isChecked={value}
                      onChange={onChange}
                      colorScheme="messenger"
                    />
                  )}
                />
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};