import React, { memo } from "react";
import { Box, HStack, Input } from "@chakra-ui/react"

type DateFilterProps = {
  dateRange: {
    since: string | null;
    until: string | null;
  };
  setDateRange: (dateRange: { since: string | null; until: string | null }) => void;
}
export const DateFilter: React.FC<DateFilterProps> = memo(({ dateRange, setDateRange }) => {
  const inputStyle = {
    size: "sm",
    type: "date",
    bgColor: 'white',
    rounded: 'md',
    w: '130px'
  };
  return (
    <HStack spacing={{ base: 2, md: 3 }} >
      <Input
        {...inputStyle}
        defaultValue={dateRange.since ?? ''}
        onChange={(e) => setDateRange({ ...dateRange, since: e.target.value })}
      />
      <Box>~</Box>
      <Input
        {...inputStyle}
        defaultValue={dateRange.until ?? ''}
        onChange={(e) => setDateRange({ ...dateRange, until: e.target.value })}
      />
    </HStack>
  );
});