import React, { memo } from "react";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { useFetchSoCouponUsageStatus } from "../../../hooks/socoupon/useFetchSoCouponUsageStatus";
import { ConfirmCouponUseDialog } from "../membership/ConfirmCouponUseDialog";
import { useChangeSoCouponStateByLineAccountId } from "../../../hooks/socoupon/useChangeSoCouponStateByLineAccountId";

type SoCouponUsageStatusBtnProps = {
  soCouponId: string;
  lineAccountId: string;
}

export const SoCouponUsageStatusBtn: React.FC<SoCouponUsageStatusBtnProps> = memo(({soCouponId, lineAccountId}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isUsed, isSubmitting, handleOnClick } = useChangeSoCouponStateByLineAccountId(soCouponId, lineAccountId, onClose)
  const { soCouponUsageStatus, isLoading } = useFetchSoCouponUsageStatus(lineAccountId!)
  if (isLoading || !soCouponUsageStatus) return null

  return (
    <Box textAlign="center">
      {(soCouponUsageStatus === 'used' || isUsed) ? (
        <Button colorScheme='red' size='lg' isDisabled={true}>利用済み</Button>
      ) : soCouponUsageStatus === 'unused' ? (
        <Button colorScheme='messenger' size='lg' onClick={onOpen}>クーポンを利用する</Button>
      ) : soCouponUsageStatus === 'next' ? (
        <Button colorScheme='orange' size='lg'>次回来店時に利用可能</Button>
      ) : null}
      <ConfirmCouponUseDialog isOpen={isOpen} onClose={onClose} handleOnClick={handleOnClick} isLoading={isSubmitting}/>
    </Box>
  );
});