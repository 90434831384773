
import React, { memo } from 'react';
import { StarRateScoreLayout } from './StarRateScoreLayout';

type StarRateSurveyScoreLayoutProps = {
  'survey_answer_scores_count': number,
  'survey_answer_scores_average': number,
  'survey_answer_scores_5_count': number,
  'survey_answer_scores_5_percentage': number,
  'survey_answer_scores_4_count': number,
  'survey_answer_scores_4_percentage': number,
  'survey_answer_scores_3_count': number,
  'survey_answer_scores_3_percentage': number,
  'survey_answer_scores_2_count': number,
  'survey_answer_scores_2_percentage': number,
  'survey_answer_scores_1_count': number,
  'survey_answer_scores_1_percentage': number,
}

export const StarRateSurveyScoreLayout: React.FC<StarRateSurveyScoreLayoutProps> = memo((props) => {

  return (
    <StarRateScoreLayout
      prefix="survey_answer_scores"
      props={props}
    />
  );
});
