import useSWR from "swr";


type RouletteSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  is_survey: boolean;
  segment_count: number;
}
export const useFetchRouletteSettingByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `games/roulette/restaurants/${restaurantId}/setting/` : null;
  const { data: rouletteSetting, error, isLoading } = useSWR<RouletteSetting>(fetcherKey)
  return { rouletteSetting, isLoading, error };
}