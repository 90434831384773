import { memo } from "react";
import { CardHeader, CardBody, VStack, Box, Text, HStack } from '@chakra-ui/react'
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { useParams, useSearchParams } from "react-router-dom";
import { BarGraph } from "../../molecules/graph/BarGraph";
import { QrCordDownloadModal } from "../../molecules/QrCordDownloadModal";
import { useFetchLiffUrlByRestaurantId } from "../../../hooks/externalApi/line/useFetchLiffUrlByRestaurantId";
import { AlertMsg } from "../../molecules/AlertMsg";
import { DateRangeFilter } from "../../molecules/DateRangeFilter";
import { SurveyScoreCards } from "../SurveyScoreCards";
import { useFetchStaffScores } from "../../../hooks/restaurantStaff/useFetchStaffScores";
import { useFetchStaffSurveyAnswerTrend } from "../../../hooks/restaurantStaff/useFetchStaffSurveyAnswerTrend";
import { NameTagDownloadModal } from "../../molecules/NameTagDownloadModal";


export const StaffTab = ({staffId}: {staffId?: string}) => {
  const [searchParams] = useSearchParams();
  const since = searchParams.get('since');
  const until = searchParams.get('until');
  const queryParams = buildQueryParams(since, until);
  return (
    <VStack align='start' spacing={3}>
      <StaffSurveyQrDownLoaders staffId={staffId} />
      <DateRangeFilter/>
      <StatusCard staffId={staffId} queryParams={queryParams} />
      <SurveyAnswerTrendCard staffId={staffId} queryParams={queryParams} />
    </VStack>
  )
};

const buildQueryParams = (since: string|null, until: string|null) => {
  const queryParams = new URLSearchParams();
  since && queryParams.set('since', since);
  until && queryParams.set('until', until);
  return queryParams;
};


const StaffSurveyQrDownLoaders = ({staffId}: {staffId?: string}) => {
  const { restaurantId } = useParams()
  const {liffUrl, isLoading, error} = useFetchLiffUrlByRestaurantId(restaurantId, 'staff-survey')
  if (error) return <AlertMsg msg={error.response.data.detail}/>
  if (isLoading || !restaurantId || !staffId) return null
  const liffUrlWithParams = `${liffUrl}/${restaurantId}?staff=${staffId}`
  return (
    <HStack>
      <QrCordDownloadModal url={liffUrlWithParams} btnText='QRコード' />
      <NameTagDownloadModal restaurantId={restaurantId} staffId={staffId} url={liffUrlWithParams} />
    </HStack>
  )
};


const StatusCard = memo(({staffId, queryParams}: {staffId?: string, queryParams: URLSearchParams}) => {
  const { staffScores, isLoading } = useFetchStaffScores(staffId, queryParams);
  return (
    <DarkShadowCard w='100%'>
      <CardHeader pb={2}>
        <Text m={0} fontSize='lg' fontWeight='bold'>ステータス</Text>
      </CardHeader>
      <CardBody>
        <SurveyScoreCards isLoading={isLoading} surveyScores={staffScores} to='questions' />
      </CardBody>
    </DarkShadowCard>
  );
});


const SurveyAnswerTrendCard = memo(({staffId, queryParams}: {staffId?: string, queryParams: URLSearchParams}) => {
  const { staffSurveyAnswerTrend } = useFetchStaffSurveyAnswerTrend(staffId, queryParams);
  const labels = staffSurveyAnswerTrend?.map((response) => response.date);
  const values = staffSurveyAnswerTrend?.map((response) => response.count);
  return (
    <DarkShadowCard w='100%'>
      <CardHeader>
        <Text m={0} fontSize='lg' fontWeight='bold'>アンケート取得数</Text>
      </CardHeader>
      <CardBody py={2}>
        <SurveyResponsesGraph labels={labels??[]} values={values??[]} />
      </CardBody>
    </DarkShadowCard>
  );
});


const SurveyResponsesGraph = memo(({labels, values}: {labels: string[], values: number[]}) => {
  const totalValues = values?.reduce((acc, value) => acc + value, 0);
  return (
    <VStack align='start' spacing={3}>
      <Text fontSize='md' fontWeight='bold' color='green.400' borderBottom='1.5px solid' borderColor='green.300' m={0}>合計 {totalValues}回</Text>
      <Box w='100%'>
        <BarGraph labels={labels??[]} values={values??[]} seriesName='回収数' />
      </Box>
    </VStack>
  );
});