import { CardHeader, CardBody, CardFooter, Flex, IconButton, Box, InputGroup, Input, InputRightElement, Button, VStack } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableLayout } from "../../template/table/TableLayout";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { DarkShadowCard } from '../../molecules/card/DarkShadowCard';
import { CardHeading } from '../../molecules/card/CardHeading';
import { RiTeamFill } from "react-icons/ri";
import { useFetchPaginatedKuitanTeams } from '../../../hooks/kuitan/useFetchPaginatedKuitanTeams';
import { Link } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { TableRowWithNavigationLayout } from '../../template/table/TableRowWithNavigationLayout';
import { PartialCircularLoading } from '../../molecules/loading/PartialCircularLoading';
import { useState } from 'react';


export const Teams = () => {
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const teamName = searchParams.get('name');
  const pageSize = 10;
  const queryParams = buildQueryParams(pageSize, page, teamName);
  const { paginatedTeams, isLoading } = useFetchPaginatedKuitanTeams(queryParams)
  return (
    <DarkShadowCard>
      <CardHeader>
        <TeamsCardHeader />
      </CardHeader>
      <CardBody py={2}>
        <VStack align='start'>
          <NameSearch />
          <Box w='100%'>
            <TeamsTable isLoading={isLoading} teams={paginatedTeams?.teams} />
          </Box>
        </VStack>
      </CardBody>
      <CardFooter>
        <Pagination totalItems={paginatedTeams?.count} pageSize={pageSize}/>
      </CardFooter>
    </DarkShadowCard>
  );
};


const TeamsCardHeader = () => {
  return (
    <Flex align='center' justify='space-between'>
      <CardHeading icon={<RiTeamFill />} heading='チーム'/>
      <IconButton
        as={Link}
        variant='outline'
        colorScheme='messenger'
        aria-label='register'
        icon={<AddIcon />}
        size='xs'
        to='create'
      />
    </Flex>
  );
};

const buildQueryParams = (pageSize: number, page: number, teamName: string|null) => {
  const queryParams = new URLSearchParams();
  queryParams.set('pageSize', pageSize.toString());
  queryParams.set('currentPage', page.toString());
  teamName && queryParams.set('name', teamName);
  return queryParams;
};

const NameSearch = () => {
  const [teamName, setTeamName] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onClickSearch = () => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('name', teamName);
    navigate(`?${newSearchParams.toString()}`);
  }
  const onClickClear = () => {
    setTeamName('');
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('name');
    navigate(`?${newSearchParams.toString()}`);
  }
  return (
    <Box>
      <InputGroup size='sm'>
        <Input
          placeholder="チーム名で検索"
          onChange={(e) => {setTeamName(e.target.value);}}
          rounded='md'
          onKeyDown={(e) => {if (e.key === 'Enter' && teamName) onClickSearch();}}
          value={teamName}
        />
        <InputRightElement>
          <Button size='xs' rounded='full' variant='ghost' onClick={onClickClear}>
            ✗
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}

const TeamsTable = ({isLoading, teams}: {isLoading: boolean, teams?: {id: string, name: string, email: string, dispatch_count: number}[]}) => {
  if (isLoading || !teams) return <PartialCircularLoading />
  const headers = ['チーム名', 'メールアドレス', '派遣回数'];
  const rows = teams.map((team) => (
    <TableRowWithNavigationLayout
      key={team.id}
      path={team.id}
      size='sm'
      childrenList={[
        team.name,
        team.email,
        team.dispatch_count + '回',
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />
}