import { memo } from "react"
import { Box, Divider, Grid } from "@chakra-ui/react";


type MenuImagesWrapperProps = {
  menuName: string;
  children: React.ReactNode;
}
export const MenuImagesWrapper: React.FC<MenuImagesWrapperProps> = memo(({menuName, children}) => {
  return (
    <Box w='100%'>
      <Box fontWeight='bold' fontSize='md'>{menuName}</Box>
      <Divider my={2} />
      <Grid templateColumns={{base: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)'}} gap={2}>
        {children}
      </Grid>
    </Box>
  )
})