import React, { memo } from "react";
import { Box, Text, VStack } from '@chakra-ui/react'


type ImageRankCardProps = {
  imgUrl: string;
  displayName: string;
  rank: string;
  lastVisitDate: string;
}
export const ImageRankCard: React.FC<ImageRankCardProps> = memo(({imgUrl, displayName, rank, lastVisitDate}) => {
  return (
    <Box
      bgImage={`url('${imgUrl}')`}
      w='100%'
      h='100%'
      bgSize='cover'
      borderRadius='md'
      p={3}
    >
      <VStack align='start' justifyContent='space-between' h='100%'>
        <Text fontSize={{ base: '3.26vw', sm: '14px' }} fontWeight='bold' m='0'>{displayName}さん</Text>
        <VStack align='center' spacing={0} w='100%'>
          <Text fontSize={{ base: '5.6vw', md: '30px' }} fontWeight='bold' m='0'>会員ランク</Text>
          <Text fontSize={{ base: '7vw', md: '24px' }} fontWeight='bold' m='0'>{rank}</Text>
        </VStack>
        <Box fontWeight='bold'>
          <Text fontSize={{ base: '2.8vw', md: '12px' }} m='0'>最終来店日</Text>
          <Text fontSize={{ base: '3.26vw', md: '14px' }} m='0'>{lastVisitDate}</Text>
        </Box>
      </VStack>
    </Box>
  );
});