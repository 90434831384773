import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const schema = yup.object().shape({
  title: yup.string().required('必須項目です'),
  content: yup.string().required('必須項目です'),
  url: yup.string(),
  img: yup.mixed()
});
type Notice = {
  id: string;
  title: string;
  content: string;
  url: string|null;
}
export const useEditNoticeForm = (notice?: Notice) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({ resolver: yupResolver(schema) });
  useEffect(() => {
    if (notice) {
      setValue('title', notice.title);
      setValue('content', notice.content);
      setValue('url', notice.url || '');
    }
  }, [notice]);
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};