import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";

type AddressInputFieldProps = {
  register: any;
  errors: any;
}
export const CorporationAddressInputField: React.FC<AddressInputFieldProps> = memo(({register, errors}) => {
  const addressError = errors.corporation?.address?.message;
  return (
    <FormControl isRequired isInvalid={errors.corporation?.address} w='100%'>
      <FormLabel>住所</FormLabel>
      <Input type="text" {...register('corporation.address')} />
      <FormErrorMessage>{addressError}</FormErrorMessage>
    </FormControl>
  );
});
