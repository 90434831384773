import { memo } from "react"
import { Center, Spinner } from "@chakra-ui/react";


export const PartialCircularLoading: React.FC = memo(() => {
  return (
    <Center w='100%'>
      <Spinner color='gray.300' size='lg' thickness='3px' speed='0.5s' />
    </Center>
  );
});