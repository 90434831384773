import useSWR from "swr";


type Restaurants = {
  id: string;
  name: string;
  username: string;
  manager_last_name: string;
  manager_first_name: string;
  manager_phone_number: string;
  corporation_name: string;
}[];
type PaginatedRestaurants = {
count: number;
restaurants: Restaurants;
};
export const useFetchPaginatedRestaurants = (queryParams: URLSearchParams) => {
  const queryString = queryParams.toString();
  const { data: paginatedRestaurants, error, isLoading } = useSWR<PaginatedRestaurants>(`accounts/restaurants/paginated/?${queryString}`)
  return { paginatedRestaurants, isLoading, error };
};