import React, { ReactNode, memo } from "react"
import { Card, CardProps } from "@chakra-ui/react";

type LightShadowCardProps = {
  children: ReactNode
}
type CombineProps = LightShadowCardProps & CardProps
export const LightShadowCard: React.FC<CombineProps> = memo(({children, ...props}) => {
  return (
    <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.04) 0px 3.5px 5.5px' {...props}>
      {children}
    </Card>
  );
});