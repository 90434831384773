import React, { memo } from "react";
import { Skeleton } from "@chakra-ui/react";
import { useFetchSoCouponUsedCouponUsersCount } from "../../../hooks/socoupon/useFetchSoCouponUsedCouponUsersCount";
import { FaUserPlus } from "react-icons/fa";
import { SmallCardWithIcon } from "../../molecules/card/SmallCardWithIcon";


type SoCouponUsedCouponUsersCountCardProps = {
  restaurantId: string;
  startDate: string | null;
  endDate: string | null;
};
export const SoCouponUsedCouponUsersCountCard: React.FC<SoCouponUsedCouponUsersCountCardProps> = memo(({restaurantId, startDate, endDate}) => {
  const { soCouponUsedCouponUsersCount, isLoadingSoCouponUsedCouponUsersCount } = useFetchSoCouponUsedCouponUsersCount(restaurantId, startDate, endDate);
  return (
    <Skeleton isLoaded={!isLoadingSoCouponUsedCouponUsersCount} rounded='2xl'>
      <SmallCardWithIcon data={`${soCouponUsedCouponUsersCount ?? '-'}人`} title='即ーポン利用者数'>
        <FaUserPlus size='30px'  />
      </SmallCardWithIcon>
    </Skeleton>
  );
});