import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from "@chakra-ui/react";

type PresidentPhoneNumberInputFieldProps = {
  register: any;
  errors: any;
}
export const PresidentPhoneNumberInputField: React.FC<PresidentPhoneNumberInputFieldProps> = memo(({register, errors}) => {
  const presidentPhoneNumberError = errors.corporation?.president_phone_number?.message;
  return (
    <FormControl isRequired isInvalid={errors.corporation?.president_phone_number} w='100%'>
      <FormLabel>代表者電話番号</FormLabel>
      <Input type="tel" minLength={10} maxLength={11} pattern="[\d]*" {...register('corporation.president_phone_number')} />
      <FormHelperText>ハイフンなし</FormHelperText>
      <FormErrorMessage>{presidentPhoneNumberError}</FormErrorMessage>
    </FormControl>
  );
});
