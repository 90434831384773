import React, { memo } from "react"
import {
  Button,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Center,
  Grid,
} from '@chakra-ui/react'
import QRCode from "qrcode.react";
import { useDownloadQrCords } from "../../hooks/useDownloadQrCard";


type QrCordDownloadModalProps = {
  url: string
  btnText?: string
}
export const QrCordDownloadModal: React.FC<QrCordDownloadModalProps> = memo(({url, btnText='QRコードダウンロード'}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {modalRef, handleDownload} = useDownloadQrCords()
  return (
    <>
      <Button onClick={onOpen} colorScheme="orange" variant='outline' size='sm'>{btnText}</Button>
      <Modal isOpen={isOpen} onClose={onClose} size={{base: 'full', md: '4xl'}} scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>QRコード</ModalHeader>
          <Divider m='0' />
          <ModalCloseButton />
          <ModalBody css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}>
            <Center h='1200px'>
              <Grid ref={modalRef} gap={1} templateColumns='repeat(3, 1fr)' transform='rotate(90deg)'>
                {[...Array(6)].map((_, i) => (
                  <Box
                    key={i}
                    as={QRCode}
                    value={url}
                    sx={{
                      width: '390px!important',
                      height: '390px!important',
                      objectFit: 'contain',
                    }}
                  />
                ))}
              </Grid>
            </Center>
          </ModalBody>
          <Divider m='0' />
          <ModalFooter>
            <Button colorScheme='messenger' size='sm' onClick={handleDownload}>ダウンロード</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

