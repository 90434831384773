import React, { memo } from 'react';
import { useFetchPaginateSoCouponSurveyAnswerTextsWithLineAccounts } from '../../../hooks/socoupon/useFetchPaginateSoCouponSurveyAnswerTextsWithLineAccounts';
import { Paginator } from '../../molecules/pagenation/Paginator';
import { useParams } from 'react-router-dom';
import { LightShadowCard } from '../../molecules/card/LightShadowCard';
import { CardBody, CardFooter, CardHeader } from '@chakra-ui/react';
import { SurveyAnswerTextsLayout } from '../../template/SurveyAnswerTextsLayout';
import { MdOutlineRateReview } from "react-icons/md";
import { CardHeading } from '../../molecules/card/CardHeading';
import { CircularLoading } from '../../molecules/loading/CircularLoading';


export const SoCouponSurveyAnswerTexts: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { soCouponSurveyAnswerTextsWithLineAccountsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage } = useFetchPaginateSoCouponSurveyAnswerTextsWithLineAccounts(restaurantId!)
  if (!restaurantId || !soCouponSurveyAnswerTextsWithLineAccountsPerPage || isLoading) return <CircularLoading />
  return (
    <LightShadowCard >
      <CardHeader>
        <CardHeading icon={<MdOutlineRateReview />} heading='レビュー'/>
      </CardHeader>
      <CardBody py={0}>
        <SurveyAnswerTextsLayout surveyAnswerTexts={soCouponSurveyAnswerTextsWithLineAccountsPerPage.socoupon_survey_answer_texts_with_line_accounts} />
      </CardBody>
      <CardFooter>
        <Paginator pagesCount={pagesCount} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
      </CardFooter>
    </LightShadowCard>
  );
});