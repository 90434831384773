import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";


type ManagerNameInputProps = {
  register: any;
  errors: any;
}
export const ManagerNameInput: React.FC<ManagerNameInputProps> = memo(({ register, errors }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.name}>
      <FormLabel>名前</FormLabel>
      <Input {...register("manager.name")} />
      <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
    </FormControl>
  )
});