import React, { memo } from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";


export const SegmentDeliveryAccordionMenu: React.FC = memo(() => {
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          セグメント配信
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItemLink to={`segment-delivery/msg`} text="メッセージ配信" />
          <AccordionItemLink to={`segment-delivery/coupon`} text="クーポン配信" />
          <AccordionItemLink to={`segment-delivery/history`} text="履歴" />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
});