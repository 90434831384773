import { RestaurantStaffForm } from "../../organisms/restaurantStaff/RestaurantStaffForm";
import { useRegisterStaffForm } from "../../../hooks/restaurantStaff/useRegisterStaffForm";
import { useRegisterStaff } from "../../../hooks/restaurantStaff/useRegisterStaff";
import { useParams } from "react-router-dom";


export const RegisterStaff = () => {
  const { restaurantId = '' } = useParams();
  const { handleSubmit, register, isSubmitting, errors } = useRegisterStaffForm();
  const onSubmit = useRegisterStaff(restaurantId);
  return <RestaurantStaffForm onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} register={register} errors={errors} cardHeading='スタッフ登録' btnLabel='登録'/>;
}