import { useEffect } from "react";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";

type UseSyncMinVisitsParams = {
  fields: Array<{ id: string }>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
};

export const useSyncMinVisits = ({ fields, setValue, watch }: UseSyncMinVisitsParams) => {
  const maxVisitsValues = watch(fields.map((_, index) => `rankSettings.${index}.max_visits`));
  useEffect(() => {
    fields.forEach((field, index) => {
      if (index === 0) {
        setValue(`rankSettings.${index}.min_visits`, 1);
      } else {
        const prevMaxVisits = parseInt(watch(`rankSettings.${index - 1}.max_visits`, null));
        setValue(`rankSettings.${index}.min_visits`, prevMaxVisits);
      }
    });
  }, [fields, setValue, watch, maxVisitsValues]);
};