import { customAuthAxios } from "../../../utils/customAuthAxios";
import { useSWRConfig } from "swr";
import { useSuccessToast } from "../../toast/useSuccessToast";

export const useSubmitLiffDelete = (liffId: string, onClose: () => void, mutateKey: string) => {
  const { mutate } = useSWRConfig()
  const showToast = useSuccessToast();

  const onSubmit = async () => {
    try {
      await customAuthAxios.delete(`external_api/liff-restaurants/${liffId}/`)
      mutate(mutateKey)
      onClose()
      showToast('削除が完了しました');
    } catch (error: any) {
    }

  };

  return onSubmit;
};