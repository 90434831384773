import React from "react";
import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";


type ExpirationDateInputProps = {
  register: any,
  errors: any,
}
export const ExpirationDateInput: React.FC<ExpirationDateInputProps> = ({register, errors}) => (
  <FormControl isRequired isInvalid={errors.expirationDate}>
    <FormLabel>有効期限</FormLabel>
    <Input
      {...register('expirationDate')}
      type="date"
    />
    <FormErrorMessage>{errors['expirationDate']?.message}</FormErrorMessage>
  </FormControl>
);