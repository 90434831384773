import React, { memo } from "react";
import { Grid, Skeleton, VStack } from "@chakra-ui/react";
import { usedCouponUsersDateRangeState } from "../../store/usedCouponUsersDateRangeState";
import { useRecoilState } from "recoil";
import { DateFilterSection } from "../organisms/DateFilterSection";
import { SoCouponUsedCouponUsersCountCard } from "../organisms/soCoupon/SoCouponUsedCouponUsersCountCard";
import { useFetchSegmentCouponUsedUsersCount } from "../../hooks/membership/useFetchSegmentCouponUsedUsersCount";
import { useParams } from "react-router-dom";
import { SmallCardWithIcon } from "../molecules/card/SmallCardWithIcon";
import { FaUserPlus } from "react-icons/fa";


export const UsedCouponUsers: React.FC = memo(() => {
  const [dateRange, setDateRange] = useRecoilState(usedCouponUsersDateRangeState)
  const { restaurantId } = useParams()
  if (!restaurantId) return null;
  return (
    <VStack spacing={5}>
      <DateFilterSection setDateRange={setDateRange} />
      <Grid templateColumns={{base: '1fr', sm: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}} gap={4} w='100%'>
        <SoCouponUsedCouponUsersCountCard restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />
        <SegmentCouponUsedCouponUsersCountCard restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />
      </Grid>
    </VStack>
  );
});

type SegmentCouponUsedCouponUsersCountCardProps = {
  restaurantId: string;
  startDate: string | null;
  endDate: string | null;
};
const SegmentCouponUsedCouponUsersCountCard: React.FC<SegmentCouponUsedCouponUsersCountCardProps> = memo(({restaurantId, startDate, endDate}) => {
  const { segmentCouponUsedUsersCount, isLoadingSegmentCouponUsedUsersCount } = useFetchSegmentCouponUsedUsersCount(restaurantId, startDate, endDate);
  return (
    <Skeleton isLoaded={!isLoadingSegmentCouponUsedUsersCount} rounded='2xl'>
      <SmallCardWithIcon data={`${segmentCouponUsedUsersCount ?? '-'}人`} title='セグメントクーポン利用者数'>
        <FaUserPlus size='30px'  />
      </SmallCardWithIcon>
    </Skeleton>
  )
})