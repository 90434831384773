
import { useSuccessToast } from "./toast/useSuccessToast";
import { customAuthAxios } from "../utils/customAuthAxios";
import { useNavigate } from "react-router-dom";


export const useDelete = (path: string, onClose: () => void, navigatePath?: string) => {
  const navigate = useNavigate()
  const showToast = useSuccessToast();

  const onSubmit = async () => {
    try {
      await customAuthAxios.delete(path)
      onClose()
      showToast('削除が完了しました')
      if (typeof navigatePath === 'string') {
        navigate(navigatePath);
      } else {
        navigate(-1);
      }
    } catch (error: any) {
    }
  };

  return onSubmit;
};