import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL

export const customNoAuthAxios = axios.create({
    baseURL: apiUrl,
    headers: {
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'true',
    }
});

customNoAuthAxios.interceptors.response.use(
    response => response,
    error => {
        console.log(error);
        switch (error.response.status) {
            case 400:
                throw error;
            case 401: // Unauthorized
                throw error;
            case 403: // Forbidden
                alert("権限がありません。");
                break;
            case 404: // Not Found
                throw error;
            case 500: // Internal Server Error
                window.location.href = '/server-error';
                break;
            default:
                console.log('予期しないエラーが発生しました');
                alert('予期しないエラーが発生しました');
                break;
        }
    }
);