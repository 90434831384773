import { Route } from 'react-router-dom';
import { SelectDispatchRequestRecipientCorporation } from '../components/pages/kuitan/SelectDispatchRequestRecipientCorporation';
import { SelectRequestMethod } from '../components/pages/kuitan/SelectRequestMethod';
import { RequestMonthlyDispatchBySalesman } from '../components/pages/kuitan/RequestMonthlyDispatchBySalesman';
import { RequestMultiMonthDispatchForSalesman } from '../components/pages/kuitan/RequestMultiMonthDispatchForSalesman';
import { DispatchRequestsForSalesman } from '../components/pages/kuitan/DispatchRequestsForSalesman';
import { MonthlyDispatchRequest } from '../components/pages/kuitan/MonthlyDispatchRequest';
import { MultiMonthDispatchRequest } from '../components/pages/kuitan/MultiMonthDispatchRequest';
import { SelectRestaurantForRatings } from '../components/pages/kuitan/SelectRestaurantForRatings';
import { RatingsForSalesman } from '../components/pages/kuitan/RatingsForSalesman';
import { SignUpAuthCode } from '../components/pages/kuitan/SignUpAuthCode';
import { SelectCorporationForRatings } from '../components/pages/kuitan/SelectCorporationForRatings';
import { TeamsAssignedRestaurants } from '../components/pages/kuitan/TeamsAssignedRestaurants';
import { DispatchRequestsForManager } from '../components/pages/kuitan/DispatchRequestsForManager';


const routes = [
  { path: 'dispatch/request', element: <SelectDispatchRequestRecipientCorporation /> },
  { path: 'dispatch/request/:corporationId', element: <SelectRequestMethod /> },
  { path: 'dispatch/request/:corporationId/monthly', element: <RequestMonthlyDispatchBySalesman /> },
  { path: 'dispatch/request/:corporationId/multi-month', element: <RequestMultiMonthDispatchForSalesman /> },
  { path: 'dispatch/requests', element: <DispatchRequestsForSalesman /> },
  { path: 'dispatch/requests/monthly/:requestId', element: <MonthlyDispatchRequest /> },
  { path: 'dispatch/requests/multi-month/:requestId', element: <MultiMonthDispatchRequest /> },
  { path: 'dispatch/requests/restaurants', element: <TeamsAssignedRestaurants /> },
  { path: 'dispatch/corporations', element: <SelectCorporationForRatings /> },
  { path: 'dispatch/corporations/:corporationId/restaurants', element: <SelectRestaurantForRatings /> },
  { path: 'dispatch/corporations/:corporationId/restaurants/:restaurantId/ratings', element: <RatingsForSalesman /> },
  { path: 'teams/dispatch/requests', element: <DispatchRequestsForManager /> },
  { path: 'signup/auth-code', element: <SignUpAuthCode /> },
];
export const KuitanRoutesForSalesman = (
  <Route path='kuitan'>
    {routes.map((route) => <Route key={route.path} path={route.path} element={route.element} />)}
  </Route>
);