import { useEffect } from "react";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSWRConfig } from "swr";


export const useMarkNoticeAsRead = (noticeId?: string, restaurantId?: string, readBy?: string[]) => {
  const { mutate } = useSWRConfig()
  useEffect(() => {
    const markAsRead = async () => {
      if (noticeId && restaurantId && (readBy && !readBy.includes(restaurantId))) {
        await customAuthAxios.post(
          `general_setting/notices/${noticeId}/read/`,
          { restaurant_id: restaurantId }
        );
        mutate(`general_setting/restaurants/${restaurantId}/notices/unread/`);
      }
    };
    markAsRead();
  }, [noticeId, restaurantId, readBy]);
};