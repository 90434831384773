import { Route } from 'react-router-dom';
import { MembershipSettings } from '../components/pages/membership/MembershipSettings';
import { RankSettings } from '../components/pages/membership/RankSettings';
import { RankSettingsEdit } from '../components/pages/membership/RankSettingsEdit';
import { RankCardSettings } from '../components/pages/membership/RankCardSettings';
import { RankCardSettingsEdit } from '../components/pages/membership/RankCardSettingsEdit';
import { StampCardSettings } from '../components/pages/membership/StampCardSettings';
import { StampCardSettingsEdit } from '../components/pages/membership/StampCardSettingsEdit';
import { Benefits } from '../components/pages/membership/Benefits';
import { Benefit } from '../components/pages/membership/Benefit';
import { BenefitRegister } from '../components/pages/membership/BenefitRegister';
import { BenefitEdit } from '../components/pages/membership/BenefitEdit';


export const MembershipRoutes = (
  <Route path='membership'>
    <Route path="settings" element={<MembershipSettings />} />
    <Route path="settings/rank" element={<RankSettings />} />
    <Route path="settings/rank/edit" element={<RankSettingsEdit />} />
    <Route path="settings/rank-card" element={<RankCardSettings />} />
    <Route path="settings/rank-card/edit" element={<RankCardSettingsEdit />} />
    <Route path="settings/stamp-card" element={<StampCardSettings />} />
    <Route path="settings/stamp-card/edit" element={<StampCardSettingsEdit />} />
    <Route path="benefits" element={<Benefits />} />
    <Route path="benefits/:benefitId" element={<Benefit />} />
    <Route path="benefits/register" element={<BenefitRegister />} />
    <Route path="benefits/:benefitId/edit" element={<BenefitEdit />} />
  </Route>
);