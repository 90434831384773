import React, { FC, memo } from "react";
import { VStack } from "@chakra-ui/react";
import { SoCouponDataSection } from "./SoCouponDataSection";
import { KuchikomyuDataSection } from "./KuchikomyuDataSection";
import { ManLaboDataSection } from "./ManLaboDataSection";
import { useRecoilValue } from "recoil";
import { dashboardDateRangeState } from "../../../store/dashboardDateRangeState";


type CenterRightDataSectionProps = {
  restaurantId: string;
  addOns: string[]
}
type AddOnComponent = FC<{ restaurantId: string, startDate: string|null, endDate: string|null }>;

const addOnComponents: Record<string, AddOnComponent | undefined> = {
  '即ーポン': SoCouponDataSection,
  '口コミュ': KuchikomyuDataSection,
  '満足度ラボ': ManLaboDataSection,
};
const orderedAddOns = ['即ーポン', '口コミュ', '満足度ラボ'];

export const CenterRightDataSection: React.FC<CenterRightDataSectionProps> = memo(({restaurantId, addOns}) => {
  const dateRange = useRecoilValue(dashboardDateRangeState)
  return (
    <VStack spacing={4} w='100%'>
      {orderedAddOns.map((addOnKey) => {
        if (addOns.includes(addOnKey)) {
          const Component = addOnComponents[addOnKey];
          return Component && <Component key={addOnKey} restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />;
        }
        return null;
      })}
    </VStack>
  );
});