import React, { memo } from "react";
import { Image } from '@chakra-ui/react'

type CouponImgProps = {
  img: string;
}

export const CouponImg: React.FC<CouponImgProps> = memo(({img}) => {

  return (
    <Image
      src={img}
      alt='coupon'
      borderRadius='20px'
      boxSize='100%'
      objectFit='cover'
    />
    );
});
