import React, { memo } from "react";
import { useDisclosure } from '@chakra-ui/react'
import { MenuEditModalLayout } from "./MenuEditModalLayout";
import { useEditChineseMenuImage } from "../../../hooks/menu/useEditChineseMenuImage";

type MenuEditModalProps = {
  id: string;
  mutateKey: string;
}
export const ChineseMenuEditModal: React.FC<MenuEditModalProps> = memo(({id, mutateKey}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useEditChineseMenuImage(id, mutateKey, onClose)
  return (
    <MenuEditModalLayout
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
});