import { customAuthAxios } from "../../../utils/customAuthAxios";
import { apiUrlLineSegmentMsg } from "../../../apiUrls/lineApiUrls";
import { useSuccessToast } from "../../toast/useSuccessToast";

export const useLineSegmentMsgSubmit = (restaurantId: string, accessToken: string, reset: () => void) => {
  const showToast = useSuccessToast();
  const onSubmit = async (data:any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("restaurantId", restaurantId);
      uploadData.append("accessToken", accessToken);
      uploadData.append("visitCounts", JSON.stringify(data.visitCounts));
      uploadData.append("textMsg", data.textMsg);
      uploadData.append("altText", data.altText);
      uploadData.append("title", data.title);
      uploadData.append("text", data.text);
      uploadData.append("label", data.label);
      uploadData.append("url", data.url);
      data.img[0] && uploadData.append("img", data.img[0]);

      await customAuthAxios.post(apiUrlLineSegmentMsg, uploadData);
      showToast('配信が完了しました。');
      reset();
    } catch (error:any) {
    } finally {
    }
  };

  return {onSubmit};
};