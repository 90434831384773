import React, { memo } from "react";
import { useFetchLineAccountCounts } from "../../../../hooks/externalApi/line/useFetchLineAccountCounts";
import { TargetAccountCount } from "./TargetAccountCount";
import { VisitCountCheckboxField } from "./VisitCountCheckboxField";
import { Box, FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";


type SelectVisitCountProps = {
  control: any;
  errors: any;
  restaurantId: string;
}

// メッセージ配信、クーポン配信で来店回数を選択するためのコンポーネント

export const SelectVisitCount: React.FC<SelectVisitCountProps> = memo(({control, errors, restaurantId}) => {
  const { lineAccountCounts, isLoading } = useFetchLineAccountCounts(restaurantId);

  if (isLoading || !lineAccountCounts) return null
  return (
    <FormControl isInvalid={errors.visitCounts}>
      <FormLabel>来店回数 <Box as='span' color='red.500'>*</Box></FormLabel>
      <VisitCountCheckboxField visitCounts={lineAccountCounts.map(lineAccountCount => lineAccountCount.visit_count)} control={control} />
      <TargetAccountCount control={control} lineAccountCounts={lineAccountCounts} />
      <FormErrorMessage>{errors['visitCounts']?.message}</FormErrorMessage>
    </FormControl>
  );
});