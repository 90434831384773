import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useErrorToast } from '../toast/useErrorToast';
import { customNoAuthAxios } from '../../utils/customNoAuthAxios';


export const useLoginKuitanAgent = () => {
  const navigate = useNavigate();
  const showErrorToast = useErrorToast();
  const onSubmit = async (data:any) => {
    try {
      const authRes = await customNoAuthAxios.post('authen/jwt/create/', data);
      const token = authRes.data.access;
      localStorage.clear();
      localStorage.setItem('localJWT', token);
      const agentRes = await customAuthAxios.get(`accounts/kuitan-agents/me/`);
      navigate(`/kuitan-agents/${agentRes.data.id}`)
    } catch (error:any) {
      if (error.response?.status === 401 || error.response?.status === 404) {
        showErrorToast("ユーザーネームまたはパスワードが誤っています。");
      } else {
        showErrorToast("予期せぬエラーが発生しました。");
      }
    }
  };
  return onSubmit;
};