import React, { memo } from "react";
import { Grid, Text, VStack } from "@chakra-ui/react";
import { SkeltonCoupon } from "./SkeltonCoupon";

export const NoValidCouponSection: React.FC = memo(() => {

  return (
    <VStack spacing={2}>
      <Grid templateColumns='repeat(3, 1fr)' gap={2} w='100%'>
        <SkeltonCoupon />
        <SkeltonCoupon />
        <SkeltonCoupon />
      </Grid>
      <Text fontSize='14px' fontWeight='bold' m='0' textAlign='center'>有効なクーポンがありません。<br/>新しいクーポンが配信されるまでお持ちください。</Text>
    </VStack>
  );
});
