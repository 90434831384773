import useSWR from "swr";


type Notices = {
  id: string;
  title: string;
  content: string;
  created_at: string;
}[];
type NoticesPerPage = {
  count: number;
  notices: Notices;
};
export const useFetchNoticesPerPage = (pageSize: number, currentPage: number) => {
  const { data: noticesPerPage, error, isLoading } = useSWR<NoticesPerPage>(`general_setting/notices/per-page/?pageSize=${pageSize}&currentPage=${currentPage}`)
  return { noticesPerPage, isLoading, error };
};