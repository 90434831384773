import useSWR from "swr";

type GoogleSetting = {
  id: string;
  location_id: string;
  place_id: string;
  reply_mode: string;
  reply_mode_label: string;
  notification_recipient_names: string[];
}

export const useFetchGoogleSettingByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `/external_api/restaurants/${restaurantId}/google/setting/` : null;
  const { data: gSetting, error, isLoading } = useSWR<GoogleSetting>(fetcherKey)
  return { gSetting, isLoading, error };
};