import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const schema = yup.object().shape({
  title: yup.string().required('必須項目です'),
  content: yup.string().required('必須項目です'),
  url: yup.string(),
  img: yup.mixed()
});
export const useCreateNoticeForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting }} = useForm({ resolver: yupResolver(schema) });
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};