import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Input, Skeleton, Textarea, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useFetchLineDev } from "../../../../hooks/externalApi/line/useFetchLineDev";
import { useEditLineDevForm } from "../../../../hooks/externalApi/line/useEditLineDevForm";
import { SubmitBtn } from "../../../atoms/button/SubmitBtn";
import { useEditLineDev } from "../../../../hooks/externalApi/line/useEditLineDev";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const EditLineDev = () => {
  const { lineDevId } = useParams();
  const { lineDev, isLoading } = useFetchLineDev(lineDevId);
  const { handleSubmit, register, isSubmitting, errors } = useEditLineDevForm(lineDev)
  const onSubmit = useEditLineDev(lineDevId!)
  const accessTokenError = errors.access_token?.message;
  const channelSecretError = errors.channel_secret?.message;
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='LineDev設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.access_token} w='100%'>
                <FormLabel>アクセストークン</FormLabel>
                <Skeleton isLoaded={!isLoading} w='100%' borderRadius={5}>
                  <Textarea {...register('access_token')} />
                </Skeleton>
                <FormErrorMessage>{accessTokenError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.channel_secret} w='100%'>
                <FormLabel>チャンネルシークレット</FormLabel>
                <Skeleton isLoaded={!isLoading} w='100%' borderRadius={5}>
                  <Input {...register('channel_secret')} />
                </Skeleton>
                <FormErrorMessage>{channelSecretError}</FormErrorMessage>
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};