import React, { memo } from "react";
import { DisplayMenu } from "../menu/DisplayMenu";
import { useRecoilValue } from "recoil";
import { customerRestaurantId } from "../../../store/customerRestaurantId";


export const MembershipMenu: React.FC = memo(() => {
  const restaurantId = useRecoilValue(customerRestaurantId)

  return (
    <DisplayMenu restaurantId={restaurantId!} />
  );
});