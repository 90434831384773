import useSWR from "swr";


type Manager = {
    id: string;
    name: string;
    user: string;
    username: string;
    restaurant_names: string[];
    restaurants: string[];
    corporation: string;
}
export const useFetchManager = (managerId?: string) => {
  const fetcKey = `accounts/managers/${managerId}/`
  const { data: manager, error, isLoading } = useSWR<Manager>(fetcKey)
  return { manager, isLoading, error };
};