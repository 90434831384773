import React from 'react';
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import { FieldValues, UseFormRegister } from 'react-hook-form';


type MaxVisitsInputFieldProps = {
  index: number;
  prevMaxVisits: number;
  register: UseFormRegister<FieldValues>;
}
export const MaxVisitsInputField: React.FC<MaxVisitsInputFieldProps> = ({ index, prevMaxVisits, register }) => {
  return (
    <InputGroup>
      <Input {...register(`rankSettings.${index}.max_visits`)} required type="number" min={prevMaxVisits + 1}/>
      <InputRightAddon fontSize='12px' px={2} fontWeight='bold'>
        未満
      </InputRightAddon>
    </InputGroup>
  );
};

