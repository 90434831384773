import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { Controller } from "react-hook-form";


export const SwitchSurveyStatus = ({control}: {control: any}) => {
  return (
    <Controller
      name="is_survey"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl>
          <FormLabel>アンケート</FormLabel>
          <Switch size='md' mb='0' isChecked={value} onChange={(e: any) => onChange(e.target.checked)} />
        </FormControl>
      )}
    />
  );
}