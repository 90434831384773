import useSWR from "swr";


type StaffSetting = {
  id: string;
  restaurant: string;
  restaurant_branch_score: number;
  staff_branch_score: number;
  g_url: string;
  is_navigate_g_url: boolean;
  staff_branch_question: string;
  staff_branch_question_name: string;
  restaurant_branch_question: string;
  restaurant_branch_question_name: string;
  name_tag_img?: string;
}
export const useFetchStaffSetting = (settingId?: string) => {
  const fetcherKey = settingId ? `restaurant_staff/settings/${settingId}/` : null;
  const { data: staffSetting, error, isLoading } = useSWR<StaffSetting>(fetcherKey)
  return { staffSetting, isLoading, error };
};