import { Route } from 'react-router-dom';
import { Corporation } from '../components/pages/corporations/Corporation';
import { EditCorporation } from '../components/pages/corporations/EditCorporation';
import { RegisterCorporation } from '../components/pages/corporations/RegisterCorporation';
import { CorporateRestaurants } from '../components/pages/corporations/CorporateRestaurants';
import { Corporations } from '../components/pages/corporations/Corporations';
import { EditCorporationPassword } from '../components/pages/corporations/EditCorporationPassword';


const routes = [
  { path: '', element: <Corporations /> },
  { path: ':corporationId', element: <Corporation /> },
  { path: ':corporationId/edit', element: <EditCorporation /> },
  { path: 'register', element: <RegisterCorporation /> },
  { path: ':corporationId/restaurants', element: <CorporateRestaurants /> },
  { path: ':corporationId/pass/edit', element: <EditCorporationPassword /> },
];
export const CorporationsRoutesForAdmin = (
  <Route path='corporations'>
    {routes.map((route, index) => <Route key={index} path={route.path} element={route.element} />)}
  </Route>
);