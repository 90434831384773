import { useLocation } from "react-router-dom";

export const useExtractGoogleReviewQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const since = queryParams.get('since');
  const until = queryParams.get('until');
  const rating = queryParams.get('rating');
  const reply = queryParams.get('reply');

  const queryParamItems = [];
  if (since) queryParamItems.push(`since=${since}`);
  if (until) queryParamItems.push(`until=${until}`);
  if (rating) queryParamItems.push(`rating=${rating}`);
  if (reply) queryParamItems.push(`reply=${reply}`);

  const requestQueryParams = queryParamItems.length !== 0 ? `?${queryParamItems.join('&')}` : '';

  return requestQueryParams;
};