import { Box, CardBody, CardFooter, CardHeader, FormControl, FormHelperText, FormLabel, Input, VStack } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { useFetchSignUpAuthCode } from "../../../hooks/kuitan/useFetchSignUpAuthCode";
import { useEditSignUpAuthCodeForm } from "../../../hooks/kuitan/useEditSignUpAuthCodeForm";
import { useEditSignUpAuthCode } from "../../../hooks/kuitan/useEditSignUpAuthCode";
import { CircularLoading } from "../../molecules/loading/CircularLoading";


export const EditSignUpAuthCode = () => {
  const {signUpAuthCode, isLoading} = useFetchSignUpAuthCode();
  const { handleSubmit, register, isSubmitting, errors } = useEditSignUpAuthCodeForm(signUpAuthCode)
  const onSubmit = useEditSignUpAuthCode()
  if (isLoading || !signUpAuthCode) return <CircularLoading/>
  return (
    <Box m='auto' w={{base: '100%', md: '580px'}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='認証コード変更'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.auth_code}>
                <FormLabel>認証コード</FormLabel>
                <Input type='text' maxLength={100} {...register('auth_code')} />
                <FormHelperText>100文字以内</FormHelperText>
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>変更</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};