import React, { memo } from "react";
import { Skeleton } from "@chakra-ui/react";
import { useFetchKuchikomyuOverallScoreAnswerCount } from "../../../hooks/kuchikomyu/useFetchKuchikomyuOverallScoreAnswerCount";
import { SmallCardWithIcon } from "../../molecules/card/SmallCardWithIcon";
import { RiSurveyLine } from "react-icons/ri";


type KuchikomyuOverallScoreAnswerCountCardProps = {
  restaurantId: string;
  startDate: string|null;
  endDate: string|null;
}
export const KuchikomyuOverallScoreAnswerCountCard: React.FC<KuchikomyuOverallScoreAnswerCountCardProps> = memo(({restaurantId, startDate, endDate}) => {
  const { KuchikomyuOverallScoreAnswerCount, isLoading } = useFetchKuchikomyuOverallScoreAnswerCount(restaurantId, startDate, endDate);
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <SmallCardWithIcon data={`${KuchikomyuOverallScoreAnswerCount}人`} title="口コミュ評価回答数">
        <RiSurveyLine size='30px' />
      </SmallCardWithIcon>
    </Skeleton>
  );
});