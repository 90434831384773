import { Box, CardBody, CardFooter, CardHeader, FormControl, FormLabel, VStack } from "@chakra-ui/react";
import { TitleInput } from "../../../molecules/form/TitleInput";
import { ExplanationTextarea } from "../../../molecules/form/ExplanationTextarea";
import { NotesTextarea } from "../../../molecules/form/NotesTextarea";
import { ExpirationDateInput } from "../../../molecules/form/ExpirationDateInput";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { RiCoupon2Line } from "react-icons/ri";
import { SubmitBtn } from "../../../atoms/button/SubmitBtn";
import { SegmentCountInput } from "../../../molecules/form/SegmentCountInput";


type RouletteCouponFormProps = {
  register: any
  errors: any
  isSubmitting: boolean
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}
export const RouletteCouponForm = ({ register, errors, isSubmitting, onSubmit }: RouletteCouponFormProps) => {
  return (
    <Box m='auto' w={{ base: '100%', sm: '90%', md: '580px' }}>
      <form onSubmit={onSubmit} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<RiCoupon2Line/>} heading='クーポン'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>クーポン画像</FormLabel>
                <input type="file" accept="image/*" {...register('img')}  />
              </FormControl>
              <TitleInput register={register} errors={errors} />
              <ExplanationTextarea register={register} errors={errors} />
              <NotesTextarea register={register} errors={errors} />
              <ExpirationDateInput register={register} errors={errors} />
              <SegmentCountInput register={register} errors={errors} />
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};