import React, { memo } from "react";
import { useRecoilValue } from "recoil";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { lineProfileState } from "../../../store/lineProfileState";
import { Box, Grid, Text, VStack } from "@chakra-ui/react";
import { useFetchValidCoupons } from "../../../hooks/membership/useFetchValidCoupons";
import { ValidCoupon } from "../../organisms/membership/ValidCoupon";
import { customerRestaurantId } from "../../../store/customerRestaurantId";


export const CustomerValidCoupons = () => {
  const lineProfile = useRecoilValue(lineProfileState)
  const restaurantId = useRecoilValue(customerRestaurantId)
  const { validCoupons, isLoading } = useFetchValidCoupons(restaurantId!, lineProfile!.userId)
  if (isLoading) return <CircularLoading />
  if (!validCoupons) return null
  return (
    <VStack spacing={5}>
      <Box textAlign="center" bg='#5271FD' p='5' w='100%'>
        <Text as='b' fontSize='lg' color='white' >{lineProfile!.displayName}さんが使えるクーポン</Text>
      </Box>
      <ValidCoupons validCoupons={validCoupons} />
    </VStack>
  );
};


type ValidCoupons = {
  id: string;
  img: string | null;
  title: string;
}[]
type ValidCouponsProps = {
  validCoupons: ValidCoupons
}
const ValidCoupons: React.FC<ValidCouponsProps> = memo(({validCoupons}) => {
  if (validCoupons.length === 0) return <Text fontWeight='bold' textAlign='center'>有効なクーポンがありません。<br/>新しいクーポンが配信されるまでお待ちください。</Text>
  return (
    <Grid templateColumns='repeat(2, 1fr)' gap='2vw' px='3.2vw' w='100%'>
      {validCoupons.map((coupon) => <ValidCoupon key={coupon.id} {...coupon} />)}
    </Grid>
  );
})