import React, { memo } from 'react';
import { HStack, Progress, Text } from '@chakra-ui/react';
import { MultipliedText } from '../atoms/MultipliedText';

export const RatingAndProgressRow: React.FC<{count: number, percentage: number, score: number}> = memo(({count, percentage, score}) => {

  return (
    <HStack spacing='3px' justifyContent='space-between'>
      <Text fontSize='sm' as='b' w='45px' align='left'>{count}件</Text>
      <Text fontSize='sm' as='b'color='orange' w='80px' align='left'><MultipliedText text="★" count={score} /></Text>
      <Progress value={percentage} w='50%' colorScheme='orange' h='10px' />
      <Text fontSize='sm' as='b' w='45px' align='right'>{percentage}%</Text>
    </HStack>
  );
});
