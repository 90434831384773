import { Button, ButtonProps } from "@chakra-ui/react";
import React, { memo } from "react";
import { Link as RouterLink } from "react-router-dom";

type PageTransitionBtnProps = {
  text: string;
  path: string;
  size?: string;
}
type CombinedProps = PageTransitionBtnProps & ButtonProps

export const PageTransitionBtn: React.FC<CombinedProps> = memo(({ text, path, size='sm', ...props }) => {
  return (
    <Button
      as={RouterLink}
      to={path}
      colorScheme="messenger"
      size={size}
      sx={{
        '&:hover': {
          color: 'white',
        },
      }}
      {...props}
    >
      {text}
    </Button>
  );
});