import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const lineDevSchema = yup.object().shape({
  access_token: yup.string().required('アクセストークンを入力してください'),
  channel_secret: yup.string().required('チャンネルシークレットを入力してください'),
});
type LineDev = {
  id: string;
  access_token: string;
  channel_secret: string;
}
export const useEditLineDevForm = (lineDev?: LineDev) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(lineDevSchema),});
  useEffect(() => {
    if (lineDev) {
      setValue('access_token', lineDev.access_token);
      setValue('channel_secret', lineDev.channel_secret);
    }
  }, [lineDev]);
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};