import React, { memo } from "react";
import { HamburgerIcon } from '@chakra-ui/icons'
import { LogoutModal } from './LogoutModal';
import useSidebar from '../../hooks/useSidebar';
import { Box, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, List, ListItem, useDisclosure } from "@chakra-ui/react";


type HeaderHeaderProps = {
  sideBarMenu: React.ReactNode;
  name: React.ReactNode;
  navItems?: React.ReactNode[];
}
const Header: React.FC<HeaderHeaderProps> = ({ sideBarMenu, name, navItems }) => {
  const {toggleSidebar} = useSidebar();
  return (
    <Flex
      align='center'
      justify='space-between'
      h='100%'
    >
      <List m='0'>
        <ListItem>
          <SideDrawer name={name}>{sideBarMenu}</SideDrawer>
          <HamburgerIcon color='white' cursor='pointer' display={{ base: 'none', sm: 'none', lg: 'block' }} onClick={toggleSidebar} />
        </ListItem>
      </List>
      <List m='0' display='flex' alignItems='center' gap={2}>
        {navItems?.map((item, index) => (
          <ListItem key={index}>
            {item}
          </ListItem>
        ))}
        <ListItem>
          <LogoutModal />
        </ListItem>
      </List>
    </Flex>
  );
};
export default Header;


const SideDrawer: React.FC<{ children: React.ReactNode, name: React.ReactNode }> = memo(({ children, name }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef(null)
  return (
    <Box display={{ base: 'block', sm: 'block', lg: 'none' }}>
      <HamburgerIcon color='white' cursor='pointer' onClick={onOpen} ref={btnRef}/>
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
        blockScrollOnMount={false}
      >
        <DrawerOverlay bgColor='rgb(14, 15, 14, .2)' />
        <DrawerContent maxW="250px" color='white'>
          <DrawerCloseButton />
          <DrawerHeader bgColor="#0E0F0E" p={0}>
            <Box fontSize='24px' px={4} py={3}>BLOCKs</Box>
            <Divider m='0' />
            <Box px={4} py={3} fontSize='md'>
              {name}
            </Box>
            <Box px={2}>
              <Divider m='0' />
            </Box>
          </DrawerHeader>
          <DrawerBody fontWeight='bold' px={0} bgColor="#0E0F0E">
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
});