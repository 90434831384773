import { Box, CardBody, CardFooter, CardHeader, HStack, Image, Skeleton, Text, Tooltip, VStack } from "@chakra-ui/react";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { RiCoupon2Line } from "react-icons/ri";
import { PageTransitionBtn } from "../../../atoms/button/PageTransitionBtn";
import { useParams } from "react-router-dom";
import { useFetchRouletteSettingByRestaurantId } from "../../../../hooks/games/roulette/useFetchRouletteSettingByRestaurantId";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { AlertMsg } from "../../../molecules/AlertMsg";
import { SettingAlert } from "../../../molecules/SettingAlert";
import { PartialCircularLoading } from "../../../molecules/loading/PartialCircularLoading";
import { TableRowWithNavigationLayout } from "../../../template/table/TableRowWithNavigationLayout";
import { TableLayout } from "../../../template/table/TableLayout";
import { formatExpirationDate } from "../../../../utils/formatDate";
import { useFetchTotalRouletteCouponSegmentsByRestaurantId } from "../../../../hooks/games/roulette/useFetchTotalRouletteCouponSegmentsByRestaurantId";
import { useFetchRouletteCouponSegmentsByRestaurantId } from "../../../../hooks/games/roulette/useFetchRouletteCouponSegmentsByRestaurantId";
import { WarningIcon, WarningTwoIcon } from "@chakra-ui/icons";


export const RouletteSegments = () => {
  const { restaurantId } = useParams()
  const { rouletteSetting, isLoading, error } = useFetchRouletteSettingByRestaurantId(restaurantId)
  if (error && error.response.status === 404) return <AlertMsg msg={error.response.data.message}/>
  if (!restaurantId || isLoading || !rouletteSetting) return <CircularLoading />
  const alerts = [
    { condition: !rouletteSetting.g_url, message: "遷移先URLが設定されていません。営業担当に連絡してください。" },
    { condition: !rouletteSetting.branch_score, message: "ブランチスコアを設定してください" },
  ];
  return (
    <Box>
      {alerts.map((alert, index) => alert.condition ? <SettingAlert key={index} msg={alert.message} /> : null)}
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<RiCoupon2Line />} heading='クーポン'/>
        </CardHeader>
        <CardBody py={0}>
          <VStack align='start' spacing={2}>
            <SegmentData restaurantId={restaurantId} segmentCount={rouletteSetting?.segment_count} />
            <Box w='100%'>
              <CouponsField restaurantId={restaurantId} />
            </Box>
          </VStack>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='register' text='作成' />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
};


const SegmentData = ({restaurantId, segmentCount}: {restaurantId: string, segmentCount: number}) => {
  const { totalCouponSegmentCount = 0, isLoading } = useFetchTotalRouletteCouponSegmentsByRestaurantId(restaurantId)
  const isExcess = totalCouponSegmentCount > segmentCount
  return (
    <HStack fontSize="sm" fontWeight="bold" mb={2} spacing={3}>
      <DarkShadowCard rounded='lg'>
        <CardHeader p={3} pb={0}>
          <Box color="teal.500">ルーレットセグメント数</Box>
        </CardHeader>
        <CardBody p={3} pt={1}>
          <Text m={0} fontSize='xl'>{segmentCount}</Text>
        </CardBody>
      </DarkShadowCard>
      <Skeleton isLoaded={!isLoading} rounded='lg' h='79px'>
        <DarkShadowCard rounded='lg'>
          <CardHeader p={3} pb={0}>
            <Box color='teal.500'>当たりセグメント数</Box>
          </CardHeader>
          <CardBody p={3} pt={1}>
            <HStack spacing={1}>
              <Text m={0} fontSize='xl' color={isExcess ? "red.500" : "black"}>{totalCouponSegmentCount}</Text>
              {isExcess && (
                <Tooltip hasArrow label='ルーレットセグメント数を超えています。超過した分は使用されません。クーポンはランダムに選ばれて使用されます。' bg='red.600'>
                  <WarningTwoIcon color='red.500' fontSize='lg' />
                </Tooltip>
              )}
            </HStack>
          </CardBody>
        </DarkShadowCard>
      </Skeleton>
    </HStack>
  )
};



const CouponsField = ({restaurantId}: {restaurantId: string}) => {
  const { couponSegments, isLoading } = useFetchRouletteCouponSegmentsByRestaurantId(restaurantId)
  if (isLoading) return <PartialCircularLoading/>
  if (!couponSegments) return null
  const headers = ['クーポン名', 'セグメント数', '有効期限'];
  const rows = couponSegments.map((couponSegment) => (
    <TableRowWithNavigationLayout
      key={couponSegment.id}
      path={`${couponSegment.id}`}
      size='sm'
      childrenList={[
        <HStack>
          {couponSegment.coupon.img ? (
            <Image src={couponSegment.coupon.img} boxSize='38px' minW='38px' />
          ) : (
            <Box bg='#F99E16' boxSize='38px' minW='38px' outline="1px solid rgb(255, 255, 255)" outlineOffset="-3px" />
          )}
          <Text m='0'>{couponSegment.coupon.title}</Text>
        </HStack>,
        <Text m='0'>{couponSegment.segment_count}</Text>,
        <Text m='0'>{formatExpirationDate(couponSegment.coupon.expiration_date)}</Text>,
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />;
};