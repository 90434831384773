import { Button } from "@chakra-ui/react";
import { memo, useState } from "react"
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { CardWithFooter } from "../../molecules/card/CardWithFooter";
import { useRecoilState } from "recoil";
import { isLoadingState } from "../../../store/isLoadingState";
import { yupResolver } from '@hookform/resolvers/yup';
import { apiUrlTableAuth } from "../../../apiUrls/tableApiUrls";
import { authTableSchema } from "../../../yupSchema/tableSchema";
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";


const SErrorMessage = styled.div`
  color: red;
  font-weight: bold;
`;


export const TableAuth: React.FC<{restaurantId?:string, tableId?:string;}> = memo(({restaurantId, tableId}) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();

  const { handleSubmit, register, formState: { errors }, control, reset } = useForm({
    resolver: yupResolver(authTableSchema),
  });

  const onSubmit = async (data:any) => {
    setErrorMessage('')
    data['table_id'] = tableId

    setIsLoading(true)
    try {
      const tableAuthRes = await customNoAuthAxios.post(
        apiUrlTableAuth,
        data
      );
      navigate(`/line-usage-check/${restaurantId}/${tableId}`);

    } catch (error:any) {
      setErrorMessage(error.response.data.message)
    } finally {
      setIsLoading(false)
    }
  };


  return (
    <div className="justify-content-center p-3">
      <form onSubmit={handleSubmit(onSubmit)} >
        <CardWithFooter cardTitle="テーブル認証">
          <>
            <input type="text" className='form-control' placeholder="認証コード" {...register(`auth_code`)} />
            {errors.auth_code?.message && <SErrorMessage>{errors.auth_code?.message }</SErrorMessage>}
            {errorMessage && <SErrorMessage>{errorMessage}</SErrorMessage>}
          </>
          <Button colorScheme='messenger' size='sm' type='submit' isDisabled={isLoading}>送信</Button>
        </CardWithFooter>
      </form>
    </div>


  );
});


