import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const lineSegmentMsgSchema = yup.object().shape({
  visitCounts: yup.array().of(yup.number()).min(1, '来店回数を選択してください').required('来店回数を選択してください'),
  textMsg: yup.string(),
  altText: yup.string().required('通知メッセージを入力してください'),
  title: yup.string().required('タイトルを入力してください'),
  text: yup.string().required('本文を入力してください'),
  label: yup.string().required('ラベルを入力してください'),
  url: yup.string().required('URLを入力してください'),
  img: yup.mixed()
});

export const useLineSegmentMsgForm = () => {
  const { handleSubmit, formState: { errors, isSubmitting }, control, register, reset } = useForm({
    resolver: yupResolver(lineSegmentMsgSchema),
    defaultValues: {
      visitCounts: [],
    },
  });

  return {
    handleSubmit,
    errors,
    control,
    register,
    reset,
    isSubmitting
  };
};
