
export const useGetRestaurantIdFromMembershipUrl = (isCouponsInUrl: boolean) => {
  const getRestaurantIdFromPathParts = (url: URL) => {
    const pathParts = url.pathname.split("/").filter(part => part !== "");
    if (isCouponsInUrl) return pathParts[3];
    return pathParts[2];
  };
  const getRestaurantIdFromLiffState = (liffState: string) => {
    const parts = decodeURIComponent(liffState).split('/').filter(part => part !== '');
    return parts[0].split('?')[0];
  };

  const getRestaurantIdFromUrl = async () => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const liffState = url.searchParams.get('liff.state');
    const restaurantId = liffState
      ? getRestaurantIdFromLiffState(liffState)
      : getRestaurantIdFromPathParts(url)
    return restaurantId;
  };

  return getRestaurantIdFromUrl;
}