import { memo } from "react"
import { FormControl, FormErrorMessage, FormLabel, Skeleton } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";

type SelectBranchScoreProps = {
  control: any;
  errors: any;
  isLoading?: boolean;
  label?: string;
  name?: string;
}
export const SelectBranchScore: React.FC<SelectBranchScoreProps> = memo(({control, errors, isLoading, name = 'branch_score', label = 'ブランチスコア'}) => {
  const options = Array.from({ length: 5 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));
  const branchScoreError = errors[name]?.message;
  return (
    <FormControl isInvalid={!!errors[name]} isRequired w='100%'>
      <FormLabel>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Skeleton isLoaded={!isLoading} w='100%' borderRadius={5}>
            <ReactSelectWithChakraStyle
              placeholder="選択"
              options={options}
              value={options.find(option => option.value === field.value)}
              onChange={(option) => field.onChange(option?.value)}
            />
          </Skeleton>
        )}
      />
      <FormErrorMessage>{branchScoreError}</FormErrorMessage>
    </FormControl>
  );
})