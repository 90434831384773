import useSWR from "swr";
import { UUID } from "crypto";


type UserData = {
  id: UUID;
  username: string;
  email: string;
  phone_number: string;
  last_name: string;
  first_name: string;
  groups: number[];
}

type Restaurant = {
  id: string;
  user: string;
  corporation: string;
  corporation_name: string;
  name: string;
  address: string;
  manager_last_name: string;
  manager_first_name: string;
  manager_phone_number: string;
  user_data: UserData;
  plan: string;
  plan_name: string;
  add_ons: string[];
  add_ons_names: string[];
  logo: string;
}

export const useFetchRestaurant = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `accounts/restaurants/${restaurantId}/` : null;
  const { data: restaurant, error, isLoading } = useSWR<Restaurant>(fetcherKey)
  return { restaurant, isLoading, error };
};