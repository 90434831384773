import React, { memo } from "react";
import { Box, Skeleton } from "@chakra-ui/react";

export const SkeltonCoupon: React.FC = memo(() => {

  return (
    <Skeleton borderRadius='20px'>
      <Box h='30vw' w='30vw' />
    </Skeleton>
  );
});
