import useSWR from "swr";


type Managers = {
    id: string;
    name: string;
    username: string;
    restaurants: string[];
}[];
type ManagersPerPage = {
  count: number;
  managers: Managers;
};
export const useFetchManagersPerPage = (pageSize: number, currentPage: number, corporationId?: string) => {
  const fetcKey = `accounts/corporations/${corporationId}/managers/per-page/?pageSize=${pageSize}&currentPage=${currentPage}`
  const { data: managersPerPage, error, isLoading } = useSWR<ManagersPerPage>(fetcKey)
  return { managersPerPage, isLoading, error };
};