import useSWR from "swr";


export type Staff = {
  id: string;
  name: string;
  nickname: string;
  img: string
  restaurant_survey_response_count: number;
  staff_survey_response_count: number;
  latest_response_day: string;
}
export const useFetchRestaurantStaff = (staffId?: string) => {
  const fetchKey = `restaurant_staff/staff/${staffId}/`
  const { data: staff, error, isLoading } = useSWR<Staff>(fetchKey)
  return { staff, isLoading, error };
};