import useSWR from "swr";


export type Scores = {
  'id': string,
  'name': string,
  'total_acquisitions': number,
  'img'?: string,
  'survey_answer_scores_count': number,
  'survey_answer_scores_average': number,
  'survey_answer_scores_5_count': number,
  'survey_answer_scores_5_percentage': number,
  'survey_answer_scores_4_count': number,
  'survey_answer_scores_4_percentage': number,
  'survey_answer_scores_3_count': number,
  'survey_answer_scores_3_percentage': number,
  'survey_answer_scores_2_count': number,
  'survey_answer_scores_2_percentage': number,
  'survey_answer_scores_1_count': number,
  'survey_answer_scores_1_percentage': number,
}[]
export const useFetchStaffSurveyRankingTopScores = (restaurantId?: string, questionId?: string, queryParams?: URLSearchParams) => {
  const fetchKey = restaurantId ? `restaurant_staff/restaurants/${restaurantId}/survey/questions/${questionId}/staff/ranking/top/${queryParams?.toString() && `?${queryParams}`}` : null;
  const { data: scores, error, isLoading } = useSWR<Scores>(fetchKey)
  return { scores, isLoading, error };
};