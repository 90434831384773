import useSWR from "swr";


type SalesmanLinking = {
  id: string;
  ifrea_analytics_salesman_id: string;
}
export const useFetchSalesmanLinkingBySalesmanId = (salesmanId?: string) => {
  const fetcherKey = salesmanId ? `/ifrea_analytics/salesmen/${salesmanId}/salesman-linking/` : null;
  const { data: salesmanLinking, error, isLoading } = useSWR<SalesmanLinking>(fetcherKey)
  return { salesmanLinking, isLoading, error };
};