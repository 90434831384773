import React from 'react';
import { Box, Divider } from '@chakra-ui/react';
import { CorporationName } from '../../atoms/CorporationName';
import { CorporationSidebarMenu } from './CorporationSidebarMenu';
import { SidebarNavWrapper } from '../../template/SidebarNavWrapper';


export const CorporationSidebar: React.FC = () => {
  return (
    <Box>
      <Box px={4} py={3} fontSize='24px'>BLOCKs</Box>
      <Divider m='0' />
      <Box px={4} py={3}>
        <CorporationName/>
      </Box>
      <Box px={2}>
        <Divider m='0' />
      </Box>
      <SidebarNavWrapper>
        <CorporationSidebarMenu/>
      </SidebarNavWrapper>
    </Box>
  );
};