import { memo } from "react"
import { Controller } from "react-hook-form";
import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Skeleton } from "@chakra-ui/react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { RiSurveyLine } from "react-icons/ri";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { useFetchStaffSurveySelectedQuestions } from "../../../hooks/restaurantStaff/useFetchStaffSurveySelectedQuestions";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { useEditStaffSurveySelectedQuestionsForm } from "../../../hooks/restaurantStaff/useEditStaffSurveySelectedQuestionsForm";
import { useEditStaffSurveySelectedQuestions } from "../../../hooks/restaurantStaff/useEditStaffSurveySelectedQuestions";
import { useFetchStaffSurveyQuestions } from "../../../hooks/restaurantStaff/useFetchStaffSurveyQuestions";


export const EditStaffSurveySelectedQuestions = () => {
  const { restaurantId } = useParams()
  const { selectedQuestions, isLoading } = useFetchStaffSurveySelectedQuestions(restaurantId)
  const { handleSubmit, errors, isSubmitting, control } = useEditStaffSurveySelectedQuestionsForm(selectedQuestions)
  const onSubmit = useEditStaffSurveySelectedQuestions(restaurantId)
  if (isLoading) return <CircularLoading />
  return (
    <Box m='auto' w={{ base: '100%', md: '80%', lg: '600px' }}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<RiSurveyLine/>} heading='使用中従業員アンケート'/>
          </CardHeader>
          <CardBody py={2}>
            <QuestionSelect restaurantId={restaurantId} control={control} errors={errors}/>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};


const QuestionSelect = memo(({restaurantId, control, errors}:{restaurantId?: string, control: any, errors: any}) => {
  const { surveyQuestions, isLoading } = useFetchStaffSurveyQuestions(restaurantId)
  const questionOptions = surveyQuestions?.map(question => ({
    label: question.question,
    value: question.id
  }));
  return (
    <FormControl isRequired isInvalid={!!errors.questions}>
      <FormLabel>アンケート</FormLabel>
      <Skeleton isLoaded={!isLoading} rounded={5}>
        <Controller
          name='questions'
          control={control}
          render={({ field }) => (
            <ReactSelectWithChakraStyle
              placeholder="選択"
              options={questionOptions}
              value={Array.isArray(field.value)
                ? questionOptions?.filter(option => field.value.includes(option.value))
                : questionOptions?.find(option => option.value === field.value)}
              onChange={(newValue:any) => {field.onChange(newValue.map((item:any) => item.value));}}
              isMulti
              required
            />
          )}
        />
      </Skeleton>
      <FormErrorMessage>{errors.questions?.message}</FormErrorMessage>
    </FormControl>
  )
});