import { memo } from "react"
import {
  Button,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Center,
  VStack,
  HStack,
  Text,
  Image,
} from '@chakra-ui/react'
import QRCode from "qrcode.react";
import { useDownloadNameTag } from "../../hooks/useDownloadNameTag";
import { useFetchRestaurant } from "../../hooks/restaurants/useFetchRestaurant";
import { useFetchRestaurantStaff } from "../../hooks/restaurantStaff/useFetchRestaurantStaff";
import { useFetchStaffSettingByRestaurantId } from "../../hooks/restaurantStaff/useFetchStaffSettingByRestaurantId";


type NameTagDownloadModalProps = {
  restaurantId: string
  staffId: string
  url: string
  btnText?: string
}
export const NameTagDownloadModal = memo(({restaurantId, staffId, url, btnText='ネームタグ'}: NameTagDownloadModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {modalRef, handleDownload} = useDownloadNameTag(onClose)
  const { restaurant, isLoading } = useFetchRestaurant(restaurantId)
  const { staff, isLoading: staffLoading } = useFetchRestaurantStaff(staffId);
  if (isLoading || staffLoading || !restaurant || !staff) return null
  const { name, logo } = restaurant
  const { nickname } = staff
  return (
    <>
      <Button onClick={onOpen} colorScheme="orange" variant='outline' size='sm'>{btnText}</Button>
      <Modal isOpen={isOpen} onClose={onClose} size={{base: 'full', md: 'xl'}} scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>ネームタグ</ModalHeader>
          <Divider m='0' />
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Box ref={modalRef}>
                <NameTag restaurantId={restaurantId} name={name} logo={logo} nickname={nickname} url={url} />
              </Box>
            </Center>
          </ModalBody>
          <Divider m='0' />
          <ModalFooter>
            <Button colorScheme='messenger' size='sm' onClick={handleDownload}>ダウンロード</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});


const NameTag = ({restaurantId, name, logo, nickname, url}: {restaurantId: string, name: string, logo: string, nickname: string, url: string}) => {
  const {staffSetting, isLoading} = useFetchStaffSettingByRestaurantId(restaurantId);
  if (isLoading) return null
  const {name_tag_img} = staffSetting ?? {}
  if (!name_tag_img) return <DefaultNameTag name={name} logo={logo} nickname={nickname} url={url} />
  return <CustomNameTag name={name} logo={logo} nickname={nickname} url={url} bgImg={name_tag_img} />
}


const DefaultNameTag = ({name, logo, nickname, url}: {name: string, logo: string, nickname: string, url: string}) => {
  return (
    <Box w='450px' h='250px' bg='orange.300' p={2}>
      <VStack h='100%' spacing={3}>
        <HStack justify='space-between' w='100%'>
          <Text fontSize='22px' fontWeight='bold' m={0}>
            {name}
          </Text>
          {logo && <Image src={logo} boxSize='60px' />}
        </HStack>
        <HStack w='100%' h='100%' bg='white' p={4} justify='space-between'>
          <Box w='100%'>
            <Text fontSize='40px' fontWeight='bold' textAlign='center' m={0} lineHeight='1.2' className='nickname'>
              {nickname}
            </Text>
          </Box>
          <Box
            as={QRCode}
            value={url}
            sx={{
              width: '120px!important',
              height: '120px!important',
              objectFit: 'contain',
            }}
          />
        </HStack>
      </VStack>
    </Box>
  )
};


const CustomNameTag = ({name, logo, nickname, url, bgImg}: {name: string, logo: string, nickname: string, url: string, bgImg: string}) => {
  return (
    <Box w='450px' h='250px' p={2} bgImage={`url(${bgImg})`} bgSize="cover" bgPosition="center" bgRepeat="no-repeat">
      <VStack h='100%' spacing={3}>
        <HStack justify='space-between' w='100%'>
          <Text fontSize='18px' fontWeight='bold' m={0}>
            {name}
          </Text>
          {logo && <Image src={logo} boxSize='50px' />}
        </HStack>
        <HStack w='100%' h='100%' p={4} justify='space-between'>
          <Box w='100%'>
            <Text fontSize='40px' fontWeight='bold' textAlign='center' m={0} lineHeight='1.2' className='nickname'>
              {nickname}
            </Text>
          </Box>
          <Box
            as={QRCode}
            value={url}
            sx={{
              width: '120px!important',
              height: '120px!important',
              objectFit: 'contain',
            }}
          />
        </HStack>
      </VStack>
    </Box>
  )
};