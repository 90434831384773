import { useParams } from "react-router-dom";
import { useFetchGoogleSetting } from "../../../../hooks/externalApi/google/useFetchGoogleSetting";
import { useEditGoogleSetting } from "../../../../hooks/externalApi/google/useEditGoogleSetting";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { Box, CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { NotificationRecipientsSelect } from "../../../organisms/externalApi/google/NotificationRecipientsSelect";
import { SubmitBtn } from "../../../atoms/button/SubmitBtn";
import { useEditGoogleSettingFormForRestaurant } from "../../../../hooks/externalApi/google/useEditGoogleSettingFormForRestaurant";


export const EditGoogleSettingByRestaurant = () => {
  const { settingId } = useParams();
  const { googleSetting, isLoading } = useFetchGoogleSetting(settingId);
  const { handleSubmit, control, isSubmitting } = useEditGoogleSettingFormForRestaurant(googleSetting)
  const onSubmit = useEditGoogleSetting(settingId)
  if (isLoading) return <CircularLoading/>
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <DarkShadowCard as='form' onSubmit={handleSubmit(onSubmit)}>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='GBPマネジャー設定'/>
        </CardHeader>
        <CardBody py={1}>
          <NotificationRecipientsSelect control={control} />
        </CardBody>
        <CardFooter>
          <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
};