import { Navigate, Outlet } from "react-router-dom";
import Footer from "../../organisms/Footer";
import Layout from "../Layout";
import AdminHeader from "../../organisms/header/AdminHeader";
import { AdminSidebar } from "../../organisms/sidebar/AdminSidebar";


const AdminBase = () => {
  const token = localStorage.getItem('localJWT');
  if (!token) return <Navigate replace to="/login/admin"/>;
  return (
    <Layout
      header={<AdminHeader />}
      nav={<AdminSidebar />}
      main={<Outlet />}
      footer={<Footer />}
    />
  );
};


export default AdminBase;