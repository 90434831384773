import React, { memo } from "react";
import { Menu, MenuButton, MenuList, MenuItem, Center } from '@chakra-ui/react'
import { BsThreeDotsVertical } from "react-icons/bs";


type ThreeDotsMenuProps = {
  childrenList: React.ReactNode[];
}
export const ThreeDotsMenu: React.FC<ThreeDotsMenuProps> = memo(({childrenList}) => {
  return (
    <Menu>
      <Center>
        <MenuButton onClick={(e) => {e.stopPropagation();}}><BsThreeDotsVertical/></MenuButton>
      </Center>
      <MenuList minWidth="150px">
        {childrenList.map((child, index) => (
          <MenuItem key={index} fontWeight='bold'>{child}</MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
});