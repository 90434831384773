import { memo } from "react"
import { Center, Spinner } from "@chakra-ui/react";


export const CircularLoading: React.FC = memo(() => {
  return (
    <Center width="100%" pt={{base: '100px', sm: '200px'}}>
      <Spinner color='gray.200' size='xl' thickness='4px' speed='0.5s' />
    </Center>
  );
});