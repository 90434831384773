import React from 'react';
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import { FieldValues, UseFormRegister } from 'react-hook-form';


type MinVisitsInputFieldProps = {
  index: number;
  prevMaxVisits: number;
  register: UseFormRegister<FieldValues>;
}
export const MinVisitsInputField: React.FC<MinVisitsInputFieldProps> = ({ index, prevMaxVisits, register }) => {
  return (
    <InputGroup>
      <Input {...register(`rankSettings.${index}.min_visits`)} type="number" required isReadOnly value={prevMaxVisits}/>
      <InputRightAddon fontSize='12px' px={2} fontWeight='bold'>
        以上
      </InputRightAddon>
    </InputGroup>
  );
};

