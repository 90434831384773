import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useErrorToast } from '../toast/useErrorToast';


const apiUrlLogin = `${process.env.REACT_APP_API_URL}authen/jwt/create/`;


export const useLoginManager = () => {
  const navigate = useNavigate();
  const showErrorToast = useErrorToast();
  const onSubmit = async (data:any) => {
    try {
      const authRes = await axios.post(apiUrlLogin, data, { headers: { 'Content-Type': 'application/json' } });
      const token = authRes.data.access;
      localStorage.clear();
      localStorage.setItem('localJWT', token);
      const managerRes = await customAuthAxios.get(`accounts/managers/me/`);
      navigate(`/managers/${managerRes.data.id}/restaurants`)
    } catch (error:any) {
      if (error.response?.status === 401 || error.response?.status === 404) {
        showErrorToast("ユーザーネームまたはパスワードが誤っています。");
      } else {
        console.error("An unexpected error occurred:", error);
        showErrorToast("予期せぬエラーが発生しました。");
      }
    }
  };
  return onSubmit;
};