import { Checkbox, CheckboxGroup, FormControl, FormErrorMessage, FormHelperText, FormLabel, Grid, HStack, Input, InputGroup, InputRightAddon, Radio, RadioGroup, Select, Skeleton, Text, Textarea } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { useFetchCorporateRestaurants } from "../../../hooks/restaurants/useFetchCorporateRestaurants";
import { useParams } from "react-router-dom";
import { useFetchKuitanTeams } from "../../../hooks/kuitan/useFetchKuitanTeams";


export const RestaurantsSelect = ({ control, errors }: { control: any, errors: any }) => {
  const { corporationId } = useParams();
  const { restaurants, isLoading } = useFetchCorporateRestaurants(corporationId);
  const restaurantOptions = restaurants?.map(restaurant => ({
    label: restaurant.name,
    value: restaurant.id
  }));
  return (
    <FormControl isRequired isInvalid={!!errors.dispatch?.restaurants}>
      <FormLabel>店舗</FormLabel>
      <Skeleton isLoaded={!isLoading} rounded={5}>
        <Controller
          name='dispatch.restaurants'
          control={control}
          render={({ field }) => (
            <ReactSelectWithChakraStyle
              placeholder="選択"
              options={restaurantOptions}
              value={Array.isArray(field.value)
                ? restaurantOptions?.filter(option => field.value.includes(option.value))
                : restaurantOptions?.find(option => option.value === field.value)}
              onChange={(newValue:any) => {field.onChange(newValue.map((item:any) => item.value));}}
              isMulti
              required
            />
          )}
        />
      </Skeleton>
      <FormErrorMessage>{errors.dispatch?.restaurants?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const TeamSelect = ({ control, errors }: { control: any, errors: any }) => {
  const { teams, isLoading } = useFetchKuitanTeams();
  const teamOptions = teams?.map(team => ({
    label: team.name,
    value: team.id
  }));
  return (
    <FormControl isRequired isInvalid={!!errors.dispatch?.team}>
      <FormLabel>派遣チーム</FormLabel>
      <Skeleton isLoaded={!isLoading} rounded={5}>
        <Controller
            name='dispatch.team'
            control={control}
            render={({ field }) => (
              <ReactSelectWithChakraStyle
                noOptionsMessage={({inputValue}) => !inputValue && 'チームが存在しません'}
                placeholder="選択"
                options={teamOptions}
                value={teamOptions?.find((x) => x.value === field.value)}
                onChange={(newValue:any) => {
                  field.onChange(newValue?.value);
                }}
                required
              />
            )}
          />
      </Skeleton>
      <FormErrorMessage>{errors.dispatch?.team?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const MonthInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.dispatch?.month}>
      <FormLabel>派遣月</FormLabel>
      <Input type="month" {...register('dispatch.month')} />
      <FormErrorMessage>{errors.dispatch?.month?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const PeriodInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.dispatch?.period}>
      <FormLabel>期間</FormLabel>
      <InputGroup>
        <Input type="number" min={1} {...register('dispatch.period')} />
        <InputRightAddon>ヶ月</InputRightAddon>
      </InputGroup>
      <FormErrorMessage>{errors.dispatch?.period?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const NumberInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.dispatch?.number}>
      <FormLabel>派遣件数</FormLabel>
      <InputGroup>
        <Input type="number" min={1} {...register('dispatch.number')} />
        <InputRightAddon>件</InputRightAddon>
      </InputGroup>
      <FormErrorMessage>{errors.dispatch?.number?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const NgDaysCheckBoxes = ({ control, errors }: { control: any, errors: any }) => {
  const options = [
    { value: '月曜日', label: '月曜日' },
    { value: '火曜日', label: '火曜日' },
    { value: '水曜日', label: '水曜日' },
    { value: '木曜日', label: '木曜日' },
    { value: '金曜日', label: '金曜日' },
    { value: '土曜日', label: '土曜日' },
    { value: '日曜日', label: '日曜日' },
    { value: '祝日', label: '祝日' },
    { value: '祝前日', label: '祝前日' },
  ];
  return (
    <FormControl>
      <FormLabel>訪問NG曜日</FormLabel>
      <Controller
        name="dispatch.dispatch_ng_days"
        control={control}
        render={({ field }) => {
          return (
            <CheckboxGroup {...field}>
              <Grid templateColumns="repeat(4, 1fr)" gap={1}>
                {options.map((option) => (
                  <Checkbox key={option.value} value={option.value} name="dispatch.dispatch_ng_days">
                    <Text m={0} fontSize='sm'>{option.label}</Text>
                  </Checkbox>
                ))}
              </Grid>
            </CheckboxGroup>
          );
        }}
      />
      <FormErrorMessage>{errors.dispatch?.dispatch_ng_days?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const NgTimePeriodInputs = ({ register, errors, watch }: { register: any, errors: any, watch: any }) => {
  const sinceValue = watch('ng_time_period.since');
  const untilValue = watch('ng_time_period.until');
  return (
    <FormControl>
      <FormLabel>訪問NG時間帯</FormLabel>
      <HStack>
        <Input required={!!untilValue} type="time" max={untilValue} {...register('ng_time_period.since')} />
        <Text m={0}>~</Text>
        <Input required={!!sinceValue} type="time" min={sinceValue} {...register('ng_time_period.until')} />
      </HStack>
      <FormErrorMessage>{errors.ng_time_period?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const MessageTextArea = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl>
      <FormLabel>メッセージ</FormLabel>
      <Textarea {...register('dispatch.message')} />
      <FormErrorMessage>{errors.dispatch?.message?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const UnitPriceInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.dispatch?.unit_price}>
      <FormLabel>依頼単価</FormLabel>
      <InputGroup>
        <Input type="number" min={1} {...register('dispatch.unit_price')} />
        <InputRightAddon>円</InputRightAddon>
      </InputGroup>
      <FormHelperText>1件あたりの派遣金額</FormHelperText>
      <FormErrorMessage>{errors.dispatch?.unit_price?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const MenuSpecificationSelect = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.dispatch?.menu_specification}>
      <FormLabel>メニュー指定</FormLabel>
      <Select {...register('dispatch.menu_specification')}>
        <option value="single">単品</option>
        <option value="course">コース</option>
        <option value="none">なし</option>
      </Select>
      <FormErrorMessage>{errors.dispatch?.menu_specification?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const BudgetLimitInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.dispatch?.budget_limit}>
      <FormLabel>予算上限</FormLabel>
      <InputGroup>
        <Input type="number" min={1} {...register('dispatch.budget_limit')} />
        <InputRightAddon>円</InputRightAddon>
      </InputGroup>
      <FormHelperText>1人辺りの予算上限</FormHelperText>
      <FormErrorMessage>{errors.dispatch?.budget_limit?.message}</FormErrorMessage>
    </FormControl>
  )
}

export const MaxCompanionsAllowedInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.dispatch?.max_companions_allowed}>
      <FormLabel>同伴可能人数</FormLabel>
      <InputGroup>
        <Input type="number" min={1} {...register('dispatch.max_companions_allowed')} />
        <InputRightAddon>人</InputRightAddon>
      </InputGroup>
      <FormHelperText>エージェントを除く同伴可能人数</FormHelperText>
      <FormErrorMessage>{errors.dispatch?.max_companions_allowed?.message}</FormErrorMessage>
    </FormControl>
  )
}


export const IsTransportationCostProvidedRadio = ({ control, errors }: { control: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.dispatch?.is_transportation_cost_provided}>
      <FormLabel>交通費</FormLabel>
      <Controller
        name="dispatch.is_transportation_cost_provided"
        defaultValue="false"
        control={control}
        render={({ field }) => (
          <RadioGroup {...field}>
            <HStack spacing={4}>
              <Radio value="true">あり</Radio>
              <Radio value="false">なし</Radio>
            </HStack>
          </RadioGroup>
        )}
      />
      <FormErrorMessage>{errors.dispatch?.is_transportation_cost_provided?.message}</FormErrorMessage>
    </FormControl>
  )
}