import { TableLayout } from "../../template/table/TableLayout";
import { Box, CardBody, CardFooter, CardHeader, VStack, Text, HStack, Input, Select, Button } from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { CiShop } from "react-icons/ci";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { PartialCircularLoading } from "../../molecules/loading/PartialCircularLoading";
import { AlertMsg } from "../../molecules/AlertMsg";
import { BenchmarkRestaurantsTabelogScores, useFetchPaginatedBenchmarkRestaurantsTabelogScores } from "../../../hooks/ifreaAnalytics/useFetchPaginatedBenchmarkRestaurantsTabelogScores";
import { IoArrowDownCircleOutline, IoArrowForwardCircleOutline, IoArrowUpCircleOutline } from "react-icons/io5";
import { Dispatch, SetStateAction, useState } from "react";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";


export const TabelogScores = () => {
  const { salesmanId } = useParams();
  const [searchParams] = useSearchParams({ p: "1" });
  const currentPage = Number(searchParams.get('p') || "1");
  const restaurantName = searchParams.get('name');
  const targetDate = searchParams.get('targetDate');
  const targetDelta = searchParams.get('targetDelta');
  const pageSize = 20;
  const queryParams = buildQueryParams(pageSize, currentPage, restaurantName, targetDate, targetDelta);
  const { paginatedBenchmarkRestaurantsTabelogScores, isLoading, error } = useFetchPaginatedBenchmarkRestaurantsTabelogScores(salesmanId, queryParams)
  if (error?.response?.status === 404) return <AlertMsg msg={error.response.data.detail}/>
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<CiShop />} heading='食べログスコア'/>
      </CardHeader>
      <CardBody py={2}>
        <VStack align='start'>
          <Filters />
          <Box w='100%'>
            <BenchmarkRestaurantsScoresTable isLoading={isLoading} benchmarkRestaurantsTabelogScores={paginatedBenchmarkRestaurantsTabelogScores?.benchmark_restaurants_tabelog_scores}/>
          </Box>
        </VStack>
      </CardBody>
      <CardFooter>
        <Pagination totalItems={paginatedBenchmarkRestaurantsTabelogScores?.count} pageSize={pageSize}/>
      </CardFooter>
    </DarkShadowCard>
  );
};


const buildQueryParams = (pageSize: number, currentPage: number, restaurantName: string|null, targetDate: string|null, targetDelta: string|null) => {
  const queryParams: { pageSize: number; currentPage: number; name?: string; targetDate?: string; targetDelta?: string } = {pageSize, currentPage};
  restaurantName && (queryParams.name = restaurantName);
  targetDate && (queryParams.targetDate = targetDate);
  targetDelta && (queryParams.targetDelta = targetDelta);
  return queryParams;
};

const Filters = () => {
  const navigate = useNavigate();
  const [targetDelta, setTargetDelta] = useState<string|null>(null);
  const [restaurantName, setRestaurantName] = useState<string|null>(null);
  const [targetDate, setTargetDate] = useState<string|null>(null);
  const handleClickFilter = () => {
    const newSearchParams = new URLSearchParams();
    restaurantName && newSearchParams.set('name', restaurantName);
    targetDate && newSearchParams.set('targetDate', targetDate);
    targetDelta && newSearchParams.set('targetDelta', targetDelta);
    navigate(`?${newSearchParams.toString()}`);
  }
  const handleClickClear = () => {
    setRestaurantName(null);
    setTargetDate(null);
    setTargetDelta(null);
    navigate('');
  }
  return (
    <HStack>
      <DeltaFilter targetDelta={targetDelta} setTargetDelta={setTargetDelta} />
      <RestaurantNameFilter restaurantName={restaurantName} setRestaurantName={setRestaurantName} />
      <TargetDateFilter targetDate={targetDate} setTargetDate={setTargetDate} />
      <Button onClick={handleClickFilter} size='sm' colorScheme='messenger' variant='outline'>絞り込む</Button>
      <Button onClick={handleClickClear} size='sm' colorScheme='blackAlpha' variant='ghost'>クリア</Button>
    </HStack>
  )
}

const DeltaFilter = ({ targetDelta, setTargetDelta }: { targetDelta: string | null, setTargetDelta: Dispatch<SetStateAction<string | null>> }) => {
  const onChangeDelta = (e: React.ChangeEvent<HTMLSelectElement>) => setTargetDelta(e.target.value);
  return (
    <Select placeholder='UP/DOWN' size='sm' rounded={5} w='120px' onChange={onChangeDelta} value={targetDelta || ''}>
      <option value='UP'>UP</option>
      <option value='DOWN'>DOWN</option>
      <option value='KEEP'>KEEP</option>
    </Select>
  )
}

const RestaurantNameFilter = ({ restaurantName, setRestaurantName }: { restaurantName: string | null, setRestaurantName: Dispatch<SetStateAction<string | null>> }) => {
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => setRestaurantName(e.target.value);
  return <Input placeholder='店舗名' type='text' size='sm' bgColor='white' w='130px' rounded='md' onChange={onChangeSearch} value={restaurantName || ''}/>
}

const TargetDateFilter = ({ targetDate, setTargetDate }: { targetDate: string | null, setTargetDate: Dispatch<SetStateAction<string | null>> }) => {
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => setTargetDate(e.target.value);
  return <Input type='date' size='sm' bgColor='white' w='130px' rounded={5} onChange={onChangeSearch} value={targetDate || ''}/>
}


const BenchmarkRestaurantsScoresTable = ({isLoading, benchmarkRestaurantsTabelogScores}: {isLoading: boolean, benchmarkRestaurantsTabelogScores?: BenchmarkRestaurantsTabelogScores}) => {
  if (isLoading || !benchmarkRestaurantsTabelogScores) return <PartialCircularLoading />
  const headers = ['UP/DOWN', ' 店舗名', '点数', 'レビュー数', '保存数'];
  const rows = benchmarkRestaurantsTabelogScores.map((tabelogScore) => (
    <TableRowWithNavigationLayout
      key={tabelogScore.benchmark_restaurant_id}
      path={tabelogScore.benchmark_restaurant_id}
      childrenList={[
        <ScoreUpDownIcon key='up-down' deltaScore={tabelogScore.delta_score} />,
        tabelogScore.benchmark_restaurant_name,
        <ValueWithDelta key='score' value={tabelogScore.score} deltaValue={tabelogScore.delta_score} />,
        <ValueWithDelta key='review-count' value={tabelogScore.review_count} deltaValue={tabelogScore.delta_review_count} />,
        <ValueWithDelta key='save-count' value={tabelogScore.save_count} deltaValue={tabelogScore.delta_save_count} />,
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />
}

const ScoreUpDownIcon = ({deltaScore}: {deltaScore: number|string}) => {
  if (typeof deltaScore !== 'number') return <>{deltaScore}</>;
  if (deltaScore > 0) return <IoArrowUpCircleOutline color='#47BB78' size='24px' />;
  if (deltaScore < 0) return <IoArrowDownCircleOutline color='#F56565' size='24px' />;
  return <IoArrowForwardCircleOutline color='#718096' size='24px' />;
}

const ValueWithDelta = ({ value, deltaValue }: {value: number | string, deltaValue: number | string;}) => {
  const customDeltaValue = typeof deltaValue === 'number'
                ? deltaValue > 0
                  ? <Text m={0} color='#47BB78'>+{deltaValue}↑</Text>
                  : deltaValue < 0
                    ? <Text m={0} color='#F56565'>{deltaValue}↓</Text>
                    : ''
                : deltaValue
  return (
    <HStack>
      <Text m={0}>{value}</Text>
      <Box>{customDeltaValue}</Box>
    </HStack>
  )
}