import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useErrorToast } from '../toast/useErrorToast';

export const useSubmitMyRestaurantEdit = (userId?: string, restaurantId?: string) => {
  const showErrorToast = useErrorToast();
  const navigate = useNavigate();

  const editUser = async (userData: any) => {
    return await customAuthAxios.put(`accounts/users/${userId}/`, userData);
  };

  const editRestaurant = async (restaurantData: any) => {
    const uploadData = new FormData();
    const fields = ["name", "address", "manager_last_name", "manager_first_name", "manager_phone_number"];
    fields.forEach(field => uploadData.append(field, restaurantData[field]));
    restaurantData.logo[0] && uploadData.append("logo", restaurantData.logo[0], restaurantData.logo[0].name);
    return await customAuthAxios.put(`accounts/restaurants/${restaurantId}/`, uploadData);
  };

  const onSubmit = useCallback(async (data: any) => {
    try {
      await editUser(data.user);
      await editRestaurant(data.restaurant);
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.username) {
        showErrorToast(error.response.data.username)
      }
    }
  }, [navigate]);

  return onSubmit;
};