import { useParams, useSearchParams } from 'react-router-dom';
import { AnsweredCustomersLayout } from '../../template/AnsweredCustomersLayout';
import { Box, CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { LightShadowCard } from '../../molecules/card/LightShadowCard';
import { CardHeading } from '../../molecules/card/CardHeading';
import { GiStarsStack } from 'react-icons/gi';
import { CircularLoading } from '../../molecules/loading/CircularLoading';
import { Pagination } from '../../molecules/pagenation/Pagination';
import { useMemo } from 'react';
import { useFetchPaginatedRestaurantQuestionAnswers } from '../../../hooks/restaurantStaff/useFetchPaginatedRestaurantQuestionAnswers';


export const RestaurantSurveyQuestionAnswers = () => {
  const { staffId, questionId } = useParams();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('p'));
  const queryParams = useMemo(() => buildQueryParams(page), [page]);
  const { paginatedQuestionAnswers, isLoading } = useFetchPaginatedRestaurantQuestionAnswers(staffId, questionId, queryParams)
  if (isLoading) return <CircularLoading />
  if (!paginatedQuestionAnswers) return null
  return (
    <Box m='auto' w='100%'>
      <LightShadowCard>
        <CardHeader>
          <CardHeading icon={<GiStarsStack/>} heading={paginatedQuestionAnswers.question}/>
        </CardHeader>
        <CardBody py={1}>
          <AnsweredCustomersLayout
            answerScores={paginatedQuestionAnswers.question_answer_scores.map(score => ({
              id: score.id,
              line_account: score.line_account,
              score: score.answer_score,
              date: score.answered_at,
            }))}
          />
        </CardBody>
        <CardFooter>
          <Pagination totalItems={paginatedQuestionAnswers.count} pageSize={paginatedQuestionAnswers.page_size}/>
        </CardFooter>
      </LightShadowCard>
    </Box>
    );
  };


  const buildQueryParams = (currentPage: number|null) => {
    const queryParams = new URLSearchParams();
    currentPage && queryParams.set('currentPage', currentPage.toString());
    return queryParams;
  };