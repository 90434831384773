import React from "react";
import { useParams } from "react-router-dom";
import { RestaurantNameInputField } from "../../organisms/restaurant/RestaurantNameInputField";
import { UserUsernameInput } from "../../molecules/form/UserUsernameInput";
import { UserEmailInput } from "../../molecules/form/UserEmailInput";
import { UserPhoneNumberInput } from "../../molecules/form/UserPhoneNumberInput";
import { RestaurantAddressInputField } from "../../organisms/restaurant/RestaurantAddressInputField";
import { ManagerNameInputField } from "../../organisms/restaurant/ManagerNameInputField";
import { ManagerPhoneNumberInputField } from "../../organisms/restaurant/ManagerPhoneNumberInputField";
import { LogoInputField } from "../../organisms/restaurant/LogoInputField";
import { PlanSelector } from "../../organisms/restaurant/PlanSelector";
import { useEditRestaurantForm } from "../../../hooks/restaurants/useEditRestaurantForm";
import { useEditRestaurant } from "../../../hooks/restaurants/useEditRestaurant";
import { AddOnsSelector } from "../../organisms/restaurant/AddOnsSelector";
import { RestaurantForm } from "../../organisms/restaurant/RestaurantForm";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const EditRestaurant = () => {
  const { restaurantId } = useParams();
  const { handleSubmit, register, isSubmitting, control, errors, userId } = useEditRestaurantForm(restaurantId);
  const onSubmit = useEditRestaurant(userId!, restaurantId!);
  const inputFields: InputField[] = [
    { component: RestaurantNameInputField, props: { register, errors } },
    { component: UserUsernameInput, props: { register, errors } },
    { component: UserEmailInput, props: { register, errors } },
    { component: UserPhoneNumberInput, props: { register, errors } },
    { component: RestaurantAddressInputField, props: { register, errors } },
    { component: ManagerNameInputField, props: { register, errors } },
    { component: ManagerPhoneNumberInputField, props: { register, errors } },
    { component: LogoInputField, props: { register } },
    { component: PlanSelector, props: { control, errors } },
    { component: AddOnsSelector, props: { control, errors } },
  ];
  return (
    <RestaurantForm
      cardTitle='店舗編集'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      inputFields={inputFields}
    />
  );
};