import { memo } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { TableAuth } from "./TableAuth";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { apiUrlTableSecurity } from "../../../apiUrls/menuApiUrls";
import { useSwrNoAuth } from "../../../hooks/swr/useSwrNoAuth";


export const CheckTableSecurity: React.FC = memo(() => {
  const params = useParams();
  const { restaurantId, tableId } = params;
  const navigate = useNavigate();

  const { data, isLoading } = useSwrNoAuth(`${apiUrlTableSecurity}?restaurant_id=${restaurantId}`);
  if (isLoading) return <CircularLoading />;
  const isTableSecurity:boolean = data.is_table_security;

  if (!isTableSecurity) {
    navigate(`/line-usage-check/${restaurantId}/${tableId}`);
    return null;
  }

  return <TableAuth restaurantId={restaurantId} tableId={tableId} />;
});


