import { memo } from "react"
import { CustomerMenu } from "../menu/CustomerMenu";


export const MenuTest: React.FC = memo(() => {
  const restaurantId = 'a7d7ec02-7bcb-41fd-9a0f-f25aa300367b'
  const tableId = '1a7a6bf7-2883-4562-a0b2-267841dc4d84'

  return (
    <CustomerMenu restaurantId={restaurantId} tableId={tableId} />
  );
});


