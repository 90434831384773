import React from "react";
import { SelectCorporation } from "../../organisms/restaurant/SelectCorporation";
import { PlanSelector } from "../../organisms/restaurant/PlanSelector";
import { useRegisterRestaurant } from "../../../hooks/restaurants/useRegisterRestaurant";
import { UserUsernameInput } from "../../molecules/form/UserUsernameInput";
import { UserPasswordInput } from "../../organisms/restaurant/UserPasswordInput";
import { UserEmailInput } from "../../molecules/form/UserEmailInput";
import { UserPhoneNumberInput } from "../../molecules/form/UserPhoneNumberInput";
import { RestaurantNameInputField } from "../../organisms/restaurant/RestaurantNameInputField";
import { RestaurantAddressInputField } from "../../organisms/restaurant/RestaurantAddressInputField";
import { ManagerNameInputField } from "../../organisms/restaurant/ManagerNameInputField";
import { ManagerPhoneNumberInputField } from "../../organisms/restaurant/ManagerPhoneNumberInputField";
import { LogoInputField } from "../../organisms/restaurant/LogoInputField";
import { useRegisterRestaurantForm } from "../../../hooks/restaurants/useRegisterRestaurantForm";
import { AddOnsSelector } from "../../organisms/restaurant/AddOnsSelector";
import { RestaurantForm } from "../../organisms/restaurant/RestaurantForm";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const RegisterRestaurant = () => {
  const { handleSubmit, register, errors, isSubmitting, control } = useRegisterRestaurantForm();
  const onSubmit = useRegisterRestaurant();
  const inputFields: InputField[] = [
    { component: UserUsernameInput, props: { register, errors } },
    { component: UserPasswordInput, props: { register, errors } },
    { component: UserEmailInput, props: { register, errors } },
    { component: UserPhoneNumberInput, props: { register, errors } },
    { component: RestaurantNameInputField, props: { register, errors } },
    { component: RestaurantAddressInputField, props: { register, errors } },
    { component: ManagerNameInputField, props: { register, errors } },
    { component: ManagerPhoneNumberInputField, props: { register, errors } },
    { component: LogoInputField, props: { register } },
    { component: SelectCorporation, props: { control, errors } },
    { component: PlanSelector, props: { control, errors } },
    { component: AddOnsSelector, props: { control, errors } },
  ];
  return (
    <RestaurantForm
      cardTitle='店舗登録'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      inputFields={inputFields}
    />
  );
};