import React, { memo } from "react";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { Box, CardBody, CardFooter, CardHeader, Divider, VStack, Link as ChakraLink } from "@chakra-ui/react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { FaUserTie } from "react-icons/fa";
import { SettingItem } from "../../organisms/SettingItem";
import { useFetchManager } from "../../../hooks/manager/useFetchManager";
import { Link } from "react-router-dom";


export const Manager: React.FC = memo(() => {
  const { managerId } = useParams()
  const { manager, isLoading: managerLoading } = useFetchManager(managerId)
  if (managerLoading) return <CircularLoading />
  if (!manager) return null
  const managerDetails = [
    { label: 'ユーザーネーム', value: manager.username },
    { label: '名前', value: manager.name },
    { label: '担当店舗', value: manager.restaurant_names.join('\n') },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<FaUserTie />} heading='マネジャー詳細'/>
        </CardHeader>
        <CardBody py={2}>
          <VStack align='start' fontWeight='bold' spacing={3} w='100%'>
            <Divider my={2} />
            {managerDetails.map((detail) => (
              <React.Fragment key={detail.label}>
                <Box w='100%' whiteSpace='pre-wrap'>
                  <SettingItem label={detail.label} content={detail.value}/>
                </Box>
                <Divider my={2} />
              </React.Fragment>
            ))}
          </VStack>
          <ChakraLink as={Link} color='messenger.500' fontSize='12px' fontWeight='normal' to='pass/edit'>パスワード変更</ChakraLink>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集'/>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});