import React, { memo } from 'react';
import { RegisterMenuForm } from '../../organisms/menu/RegisterMenuForm';
import { useParams } from 'react-router-dom';
import { useRegisterKoreanMenuImages } from '../../../hooks/menu/useRegisterKoreanMenuImages';


export const KoreanFoodMenuRegister: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const onSubmit = useRegisterKoreanMenuImages('food', restaurantId)
  return <RegisterMenuForm menuName='フード' onSubmit={onSubmit} />;
});