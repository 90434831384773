import { Box, Icon, Text, VStack, Skeleton } from "@chakra-ui/react";
import { IconType } from "react-icons";

const StatCard = ({
                      label,
                      value,
                      icon,
                      isLoading = false,
                  }: {
    label: string;
    value: number;
    icon: IconType;
    isLoading?: boolean;
}) => {
    return (
        <Box
            p={4}
            bg="white"
            borderRadius="md"
            boxShadow="md"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="full"
        >
            <VStack align="start" spacing={0}>
                <Text fontWeight="bold" fontSize="lg">
                    {isLoading ? <Skeleton height="20px" width="60px" /> : value}
                </Text>
                <Text fontSize="sm" color="gray.500">
                    {label}
                </Text>
            </VStack>
            <Icon as={icon} color="blue.500" w={6} h={6} />
        </Box>
    );
};

export default StatCard;