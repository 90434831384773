import React, {useEffect, useRef, useState } from "react";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { CardHeader, CardBody, VStack, HStack, Avatar, Text, Grid, GridItem, Box, Flex, useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, CardFooter, Textarea, Skeleton } from '@chakra-ui/react'
import { useFetchGoogleReviews } from "../../../../hooks/externalApi/google/useFetchGoogleReviews";
import { FilterSection } from "../../../organisms/externalApi/google/FilterSection";
import { useExtractGoogleReviewQueryParams } from "../../../../hooks/externalApi/google/useExtractGoogleReviewQueryParams";
import { useNavigateToUrlWithQueryParams } from "../../../../hooks/externalApi/google/useNavigateToUrlWithQueryParams";
import { useInitializeFilter } from "../../../../hooks/externalApi/google/useInitializeFilter";
import { useResetReviewFilter } from "../../../../hooks/externalApi/google/useResetReviewFilter";
import { AlertMsg } from "../../../molecules/AlertMsg";
import { Skeletons } from "../../../atoms/Skeletons";
import { MdOutlineReply } from "react-icons/md";
import { useReplyToGoogleReview } from "../../../../hooks/externalApi/google/useReplyToGoogleReview";
import { useParams } from "react-router-dom";
import { StarRating } from "../../../molecules/StarRating";
import { useSaveGoogleReviews } from "../../../../hooks/externalApi/google/useSaveGoogleReviews";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { GoogleReviewFilterModal } from "../../../organisms/externalApi/google/GoogleReviewFilterModal";
import { BusinessTitleByRestaurantId } from "../../../organisms/externalApi/google/BusinessTitle";
import { useFetchGoogleReviewReplyText } from "../../../../hooks/externalApi/google/useFetchGoogleReviewReplyText";
import { useReplyToGoogleReviewForm } from "../../../../hooks/externalApi/google/useReplyToGoogleReviewForm";


export const Reviews = () => {
  const { restaurantId } = useParams()
  const { error } = useSaveGoogleReviews(restaurantId)
  if (error) return <AlertMsg msg={error.response.data.detail} />;
  return <ReviewContents restaurantId={restaurantId} />
};


const ReviewContents: React.FC<{restaurantId?: string}> = ({restaurantId}) => {
  const requestQueryParams = useExtractGoogleReviewQueryParams();
  useInitializeFilter()
  const { reviews, isLoading, error } = useFetchGoogleReviews(restaurantId, requestQueryParams)
  if (!restaurantId || isLoading) return <CircularLoading />
  if (error && error.response.status === 404) return <>{error.response.data.detail}</>;
  return (
    <VStack align='start' spacing={1} >
      <BusinessTitleByRestaurantId restaurantId={restaurantId} />
      <Flex align='center' justify='space-between' w='100%'>
        <Text fontSize='sm' m='0' color='gray.500'>{reviews && `${reviews.length}件`}</Text>
        <FilterModal />
      </Flex>
      <Grid templateColumns={{base: '1fr', lg: 'repeat(2, 1fr)'}} gap={3} w='100%'>
        <ReviewCards restaurantId={restaurantId} isLoading={isLoading} reviews={reviews} />
      </Grid>
    </VStack>
  );
}


const FilterModal: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigateToUrlWithQueryParams = useNavigateToUrlWithQueryParams(onClose);
  const resetFilter = useResetReviewFilter()
  const onClickResetFilter = () => {
    resetFilter()
    onClose()
  }
  return (
    <GoogleReviewFilterModal
      onClose={onClose}
      onOpen={onOpen}
      isOpen={isOpen}
      navigateToUrlWithQueryParams={navigateToUrlWithQueryParams}
      onClickResetFilter={onClickResetFilter}
    >
      <FilterSection />
    </GoogleReviewFilterModal>
  )
}


type Review = {
  id: string;
  date: string;
  rating: number;
  comment: string;
  reply: string;
  reviewerName: string;
  reviewerImageUrl: string;
}
const ReviewCards: React.FC<{restaurantId: string, isLoading: boolean, reviews?: Review[]}> = ({ restaurantId, isLoading, reviews }) => {
  if (isLoading || !reviews) return <Skeletons length={6} rounded='xl' w='100%' h='208px' />
  return (
    <>
      {reviews.map(review => (
        <GridItem key={review.id} w='100%'>
          <ReviewCard restaurantId={restaurantId} {...review} />
        </GridItem>
      ))}
    </>
  );
}

const ReviewCard: React.FC<Review & { restaurantId: string }> = ({id,  date, rating, comment, reply, reviewerName, reviewerImageUrl, restaurantId }) => {
  const formatAvatarUrl = (url: string): string => url.replace(/\\/g, '');
  const reviewText = comment.startsWith('(Translated by Google)') ? `${comment}` : `${comment}`.split('(Translated by Google)')[0].trim();
  const replyText = reply === 'No reply' ? null : `${reply}`.split('(Translated by Google)')[0].trim();
  return (
    <DarkShadowCard>
      <CardHeader pb='0'>
        <VStack align='start' spacing={1}>
          <HStack>
            <Avatar src={formatAvatarUrl(reviewerImageUrl)} size='sm' />
            <Text fontWeight='bold' m='0'>{reviewerName}</Text>
          </HStack>
          <HStack>
            <StarRating count={rating} />
            <Text fontSize='sm' m='0' color='gray.500'>
              {date}
            </Text>
          </HStack>
        </VStack>
      </CardHeader>
      <CardBody py={2}>
        <VStack align='start' spacing={1} whiteSpace='pre-wrap'>
          <ViewMoreText text={reviewText} />
        </VStack>
      </CardBody>
      <CardFooter pt={0}>
        {replyText ? (
          <Box borderLeft='2px solid #dadada' pl={2} whiteSpace='pre-wrap'>
            <Text fontSize='sm' m='0'>
              {replyText}
            </Text>
          </Box>
        ) : (
          <ReplyModal restaurantId={restaurantId} reviewId={id} />
        )}
      </CardFooter>
    </DarkShadowCard>
  )
}

const ViewMoreText: React.FC<{text: string}> = ({text}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (textRef.current && textRef.current.offsetHeight > 80) setIsOverflow(true);
  }, []);
  if (!isOverflow) return <Text m='0' ref={textRef}>{text}</Text>;
  if (isExpanded) {
    return (
      <>
        <Text m='0'>{text}</Text>
        <Text m='0' as="span" color="gray.500" cursor='pointer' onClick={() => setIsExpanded(false)}>
          閉じる
        </Text>
      </>
    );
  }
  return (
    <>
      <Text m='0' noOfLines={3} ref={textRef}>{text}</Text>
      <Text m='0' as="span" color="messenger.500" cursor='pointer' onClick={() => setIsExpanded(true)}>もっと見る</Text>
    </>
  );
}

const ReplyModal: React.FC<{restaurantId: string, reviewId: string}> = ({restaurantId, reviewId}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <HStack color="gray.500" cursor='pointer' spacing={0} onClick={onOpen}>
        <MdOutlineReply size='20px' />
        <Text fontSize='14px' m='0'>返信</Text>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} size={{base: 'xs', md: 'md'}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>口コミに返信</ModalHeader>
          <ModalCloseButton />
          <ReplyForm onClose={onClose} restaurantId={restaurantId} reviewId={reviewId} />
        </ModalContent>
      </Modal>
    </>
  )
}

const ReplyForm: React.FC<{onClose: () => void, restaurantId: string, reviewId: string}> = ({onClose, restaurantId, reviewId}) => {
  const { replyText, isLoading } = useFetchGoogleReviewReplyText(restaurantId, reviewId)
  const { handleSubmit, register, isSubmitting, watch } = useReplyToGoogleReviewForm(replyText);
  const onSubmit = useReplyToGoogleReview(restaurantId, reviewId, onClose)
  const currentReplyText = watch('replyText');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <Skeleton isLoaded={!isLoading} rounded='lg'>
          <Textarea isRequired maxLength={4000} rows={10} {...register('replyText')} />
        </Skeleton>
        <Text color='gray.500' fontSize='sm' textAlign='right' m='0'>{currentReplyText?.length} / 4000</Text>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme='messenger' size='sm' mr={3} type="submit" isLoading={isSubmitting} >返信</Button>
        <Button colorScheme='gray' size='sm' onClick={onClose} isDisabled={isSubmitting}>キャンセル</Button>
      </ModalFooter>
    </form>
  )
}