import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const googleSettingSchema = yup.object().shape({
  location_id: yup.string().required('入力必須項目です'),
  place_id: yup.string().required('入力必須項目です'),
  reply_mode: yup.string().required('入力必須項目です'),
  notification_recipients: yup.string()
});
export const useRegisterGoogleSettingForm = () => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(googleSettingSchema),});
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};