import React, { memo } from "react";
import { useChangeSegmentCouponState } from "../../../hooks/membership/useChangeSegmentCouponState";
import { ConfirmCouponUseDialog } from "./ConfirmCouponUseDialog";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { lineProfileState } from "../../../store/lineProfileState";
import { useRecoilValue } from "recoil";

type ConfirmSegmentCouponUseDialogProps = {
  couponId: string;
}
export const ConfirmSegmentCouponUseDialog: React.FC<ConfirmSegmentCouponUseDialogProps> = memo(({couponId}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const lineProfile = useRecoilValue(lineProfileState)
  const lineId = lineProfile!.userId
  const { isUsed, isLoading, handleOnClick } = useChangeSegmentCouponState({couponId, lineId, onClose})
  return (
    <Box textAlign='center'>
      <Button isDisabled={isUsed} variant='solid' colorScheme={isUsed ? 'red' : 'messenger'} w='70%' onClick={onOpen}>
        {isUsed ? '使用済み' : '使用する'}
      </Button>
      <ConfirmCouponUseDialog {...{isOpen, onClose, handleOnClick, isLoading}}/>
    </Box>
    );
});

