import React, { memo } from "react";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { getToday } from "../../../utils/getToday";
import { ManLaboSurveyAnswersCountCard } from "../manzokudoLabo/ManLaboSurveyAnswersCountCard";


type ManLaboDataSectionProps = {
  restaurantId: string;
  startDate: string|null;
  endDate: string|null;
}
export const ManLaboDataSection: React.FC<ManLaboDataSectionProps> = memo(({restaurantId, startDate, endDate}) => {
  const today = getToday();
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(`../manzokudo-labo/scores`)}
      cursor='pointer'
      w='100%'
      position="relative"
      transition='0.2s' _hover={{transform: 'translateY(-5px)'}}
    >
      <ManLaboSurveyAnswersCountCard restaurantId={restaurantId} startDate={startDate||today} endDate={endDate||today} />
    </Box>
  );
});