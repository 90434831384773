import React, { memo } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, CardHeader, CardBody } from '@chakra-ui/react'
import { SegmentMsgTable } from "../../../organisms/externalApi/line/SegmentMsgTable";
import { SegmentCouponTable } from "../../../organisms/externalApi/line/SegmentCouponTable";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { MdOutlineHistory } from "react-icons/md";


export const LineSegmentDeliveryHistory: React.FC = memo(() => {
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<MdOutlineHistory />} heading='配信履歴'/>
      </CardHeader>
      <CardBody py={2}>
        <Tabs isFitted isLazy variant='enclosed'>
          <TabList>
            <Tab fontWeight='bold'>メッセージ</Tab>
            <Tab fontWeight='bold'>クーポン</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SegmentMsgTable />
            </TabPanel>
            <TabPanel>
              <SegmentCouponTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </DarkShadowCard>
  );
});