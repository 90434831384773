import React, { memo } from "react";
import { SortLastVisitDateHoverPopover } from "../../molecules/SortLastVisitDateHoverPopover";
import { SortVisitCountHoverPopover } from "../../molecules/SortVisitCountHoverPopover";
import { WithLoadingAndNoDataLayout } from "../../template/WithLoadingAndNoDataLayout";
import { TableLayout } from "../../template/table/TableLayout";
import { Avatar, HStack, Text } from "@chakra-ui/react";
import { formatDate } from "../../../utils/formatDate";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";


type LineAccounts = {
  id: string;
  customer: string;
  restaurant: string;
  line_id: string;
  display_name: string;
  picture_url: string;
  visit_count: string;
  last_visit: string;
  registered_date: string;
  customer_sex : string;
  customer_birth_year : string;
  customer_birth_month : string;
}[]
type LineAccountsDetailTableProps = {
  lineAccounts?: LineAccounts;
}
export const LineAccountsDetailTable: React.FC<LineAccountsDetailTableProps> = memo(({lineAccounts}) => {
  const headers = ['アカウント', '初回来店日', <SortLastVisitDateHoverPopover />, <SortVisitCountHoverPopover />];
  const rows = lineAccounts?.map((lineAccount) => (
    <TableRowWithNavigationLayout
      key={lineAccount.id}
      path={lineAccount.id}
      size='sm'
      childrenList={[
        <HStack>
          <Avatar src={lineAccount.picture_url} boxSize={{base: '30px', sm: '34px'}} />
          <Text overflowWrap="break-word" whiteSpace="normal" noOfLines={1} m={0}>{lineAccount.display_name}</Text>
        </HStack>,
        formatDate(lineAccount.registered_date),
        formatDate(lineAccount.last_visit),
        lineAccount.visit_count + '回'
      ]}
    />
  ));
  return (
    <WithLoadingAndNoDataLayout data={lineAccounts}>
      <TableLayout headers={headers} rows={rows || []} size='sm' />
    </WithLoadingAndNoDataLayout>
  );
});