import { Route } from 'react-router-dom';
import { LineSegmentMsg } from '../components/pages/externalApi/line/LineSegmentMsg';
import { LineSegmentCoupon } from '../components/pages/externalApi/line/LineSegmentCoupon';
import { LineSegmentDeliveryHistory } from '../components/pages/externalApi/line/LineSegmentDeliveryHistory';
import { LineSegmentMsgReceivedUsers } from '../components/pages/externalApi/line/LineSegmentMsgReceivedUsers';
import { LineSegmentCouponReceivedUser } from '../components/pages/externalApi/line/LineSegmentCouponReceivedUser';


export const SegmentDeliveryRoutes = (
  <Route path='segment-delivery'>
    <Route path="msg" element={<LineSegmentMsg />} />
    <Route path="coupon" element={<LineSegmentCoupon />} />
    <Route path="history" element={<LineSegmentDeliveryHistory />} />
    <Route path="history/msgs/:msgId" element={<LineSegmentMsgReceivedUsers />} />
    <Route path="history/coupons/:couponId" element={<LineSegmentCouponReceivedUser />} />
  </Route>
);
