import React, { memo } from "react";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { Box, CardBody, CardFooter, CardHeader, Divider, VStack } from "@chakra-ui/react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useParams } from "react-router-dom";
import { useFetchCorporation } from "../../../hooks/corporations/useFetchCorporation";
import { useFetchUser } from "../../../hooks/user/useFetchUser";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { FaRegBuilding } from "react-icons/fa";
import { SettingItem } from "../../organisms/SettingItem";


export const CorporationBaseInfo: React.FC = memo(() => {
  const { corporationId } = useParams()
  const { user, isLoading: userLoading } = useFetchUser()
  const { corporation, isLoading: corporationLoading } = useFetchCorporation(corporationId)
  if (userLoading || corporationLoading) return <CircularLoading />
  if (!user || !corporation) return null
  const corporationDetails = [
    { label: 'ユーザーネーム', value: user.username },
    { label: 'メールアドレス', value: user.email },
    { label: '電話番号', value: user.phone_number },
    { label: '住所', value: `${corporation.address}` },
    { label: '代表者', value: `${corporation.president_last_name}${corporation.president_first_name}` },
    { label: '代表者電話番号', value: corporation.president_phone_number },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<FaRegBuilding />} heading={corporation.name}/>
        </CardHeader>
        <CardBody py={2}>
          <VStack align='start' fontWeight='bold' spacing={3} w='100%'>
            <Divider my={2} />
            {corporationDetails.map((detail) => (
              <React.Fragment key={detail.label}>
                <Box w='100%'>
                  <SettingItem label={detail.label} content={detail.value}/>
                </Box>
                <Divider my={2} />
              </React.Fragment>
            ))}
          </VStack>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集'/>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});