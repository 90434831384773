import useSWR from "swr";

type LineAccount = {
  id: string;
  customer_sex: string;
  customer_birth_year: string;
  customer_birth_month: string;
  line_id: string;
  display_name: string;
  picture_url?: string;
  visit_count: number;
  registered_date: string;
  last_visit: string;
  is_active: boolean;
  blocked_date: null | string;
  customer: string;
  restaurant: string;
};

type ManLaboLatestSurveyAnswerTexts = {
  id: string;
  answer_text: string;
  answered_at: string;
  line_account: LineAccount;
}[]

export const useFetchManLaboLatestSurveyAnswerTexts = (restaurantId: string) => {
  const { data: manLaboLatestSurveyAnswerTexts, error, isLoading } = useSWR<ManLaboLatestSurveyAnswerTexts>(`manzokudo_labo/survey/texts/latest/?restaurantId=${restaurantId}`)
  return { manLaboLatestSurveyAnswerTexts, isLoading, error };
};