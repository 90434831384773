import React, { memo } from 'react';
import { useFetchKuchikomyuOverallScore } from '../../../hooks/kuchikomyu/useFetchKuchikomyuOverallScore';
import { StarRateOverallScoreLayout } from '../../template/StarRateOverallScoreLayout';
import { CardBody, CardHeader, Skeleton, Text } from '@chakra-ui/react';
import { LightShadowCard } from '../../molecules/card/LightShadowCard';


type KuchikomyuOverallScoreProps = {
  restaurantId: string;
};
export const KuchikomyuOverallScore: React.FC<KuchikomyuOverallScoreProps> = memo(({restaurantId}) => {
  const { KuchikomyuOverallScore, isLoading } = useFetchKuchikomyuOverallScore(restaurantId)
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl' minH='280px'>
      <LightShadowCard >
        <CardHeader>
          <Text m={0} fontSize='lg' fontWeight='bold'>総合評価</Text>
        </CardHeader>
        <CardBody pt={0}>
          {KuchikomyuOverallScore && <StarRateOverallScoreLayout {...KuchikomyuOverallScore} />}
        </CardBody>
      </LightShadowCard>
    </Skeleton>
  );
});