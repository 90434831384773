import { useCallback, useRef } from 'react';
import html2canvas from 'html2canvas';


export const useDownloadQrCords = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const a4Width = 840;
  const a4Height = a4Width * Math.sqrt(2)
  const handleDownload = useCallback(() => {
    if (modalRef.current) {
      html2canvas(modalRef.current, { scale: 1, width: a4Width, height: a4Height, useCORS: true }).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = `qr-cords.png`;
        link.click();
      });
    }
  }, []);

  return { modalRef, handleDownload };
};