import React, { useEffect } from "react";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { useFetchLiffUrlCustomerByRestaurantId } from "../../../../hooks/externalApi/line/useFetchLiffUrlCustomerByRestaurantId";
import liff from '@line/liff';


type NavigateToMembershipCouponsProps = {
  restaurantId: string;
}
export const NavigateToMembershipCoupons: React.FC<NavigateToMembershipCouponsProps> = ({restaurantId}) => {
  const { liffUrl, isLoading } = useFetchLiffUrlCustomerByRestaurantId(restaurantId, 'membership-coupon');
  useEffect(() => {
    if (!isLoading && liffUrl) liff.openWindow({ url: liffUrl +'/'+ restaurantId });
  }, [isLoading, liffUrl]);
  return <CircularLoading/>;
};