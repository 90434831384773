import React, { useEffect } from 'react';
import { memo } from "react"
import { useFieldArray, useForm } from "react-hook-form";
import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Skeleton, VStack } from "@chakra-ui/react";
import { CardHeading } from '../../molecules/card/CardHeading';
import { MdMenuBook } from 'react-icons/md';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { DarkShadowCard } from '../../molecules/card/DarkShadowCard';
import { useRegisterMenuImagesForm } from '../../../hooks/menu/useRegisterMenuImagesForm';
import { SubmitBtn } from '../../atoms/button/SubmitBtn';
import { DropZone } from '../../molecules/form/DropZone';


type RegisterMenuFormProps = {
  menuName: string;
  onSubmit: (data: any) => Promise<void>;
}
export const RegisterMenuForm: React.FC<RegisterMenuFormProps> = memo(({menuName, onSubmit}) => {
  const { handleSubmit, control, isSubmitting, errors, setValue, getValues } = useRegisterMenuImagesForm()
  return (
    <Box m='auto' w={{base: '100%', md: '580px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<MdMenuBook fontSize='sm' />} heading={menuName}/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.images}>
                <FormLabel>画像</FormLabel>
                <DropZone control={control} name='images' setValue={setValue} getValues={getValues} errorMessage={errors.images?.message}/>
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  )
})