import { useSwrNoAuth } from "../swr/useSwrNoAuth";


export type SelectedQuestions = {
  id: string;
  restaurant: string;
  question: string;
  question_content: string;
  order: number;
}[]
export const useFetchRestaurantSurveySelectedQuestionsCustomer = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `restaurant_staff/restaurants/${restaurantId}/restaurant-survey/questions/selected/customer/` : null;
  const { data: selectedQuestions, error, isLoading } = useSwrNoAuth<SelectedQuestions>(fetcherKey)
  return { selectedQuestions, isLoading, error };
};