import React, { memo } from "react";
import { Text, Flex, Image, Box, Spacer } from '@chakra-ui/react'


type DefaultRankCardBottomProps = {
  lastVisitDate: string;
  logo?: string;
}
export const DefaultRankCardBottom: React.FC<DefaultRankCardBottomProps> = memo(({lastVisitDate, logo}) => {
  return (
    <Flex align='center' justifyContent='space-between' w='100%'>
      <Box fontWeight='bold'>
        <Text fontSize={{ base: '2.8vw', sm: '12px' }} m='0'>最終来店日</Text>
        <Text fontSize={{ base: '3.26vw', sm: '14px' }} m='0'>{lastVisitDate}</Text>
      </Box>
      {
        logo ?
          <Image
            src={logo}
            alt='店舗ロゴ'
            w={{ base: '10vw', sm: '43px' }}
          />
        : <Spacer />
      }
    </Flex>
  );
});