import { Route } from 'react-router-dom';
import { ManLaboSettingForRestaurant } from '../components/pages/manzokudoLabo/ManLaboSettingForRestaurant';
import { EditManlaboSettingByRestaurant } from '../components/pages/manzokudoLabo/EditManlaboSettingByRestaurant';
import { ManLaboQrCode } from '../components/pages/manzokudoLabo/ManLaboQrCode';
import { ManLaboSurveyQuestions } from '../components/pages/manzokudoLabo/ManLaboSurveyQuestions';
import { ManLaboSurveyQuestionRegister } from '../components/pages/manzokudoLabo/ManLaboSurveyQuestionRegister';
import { ManLaboSurveySelectedQuestionsEdit } from '../components/pages/manzokudoLabo/ManLaboSurveySelectedQuestionsEdit';
import { ManLaboSurveySelectedQuestions } from '../components/pages/manzokudoLabo/ManLaboSurveySelectedQuestions';
import { ManLaboScores } from '../components/pages/manzokudoLabo/ManLaboScores';
import { ManLaboSurveyQuestionAnsweredCustomers } from '../components/pages/manzokudoLabo/ManLaboSurveyQuestionAnsweredCustomers';
import { ManLaboSurveyAnswerTexts } from '../components/pages/manzokudoLabo/ManLaboSurveyAnswerTexts';


export const ManLaboRoutes = (
  <Route path='manzokudo-labo'>
    <Route path="setting" element={<ManLaboSettingForRestaurant />} />
    <Route path="setting/edit" element={<EditManlaboSettingByRestaurant />} />
    <Route path="qr-code" element={<ManLaboQrCode />} />
    <Route path="survey/questions" element={<ManLaboSurveyQuestions />} />
    <Route path="survey/questions/register" element={<ManLaboSurveyQuestionRegister />} />
    <Route path="survey/questions/selected" element={<ManLaboSurveySelectedQuestions />} />
    <Route path="survey/questions/selected/edit" element={<ManLaboSurveySelectedQuestionsEdit />} />
    <Route path="scores" element={<ManLaboScores />} />
    <Route path="scores/:questionId" element={<ManLaboSurveyQuestionAnsweredCustomers />} />
    <Route path="answer-texts" element={<ManLaboSurveyAnswerTexts />} />
  </Route>
);