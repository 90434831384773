import React, { memo } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  IconButton,
} from '@chakra-ui/react'
import { useForm } from "react-hook-form";
import { EditIcon } from "@chakra-ui/icons";

type MenuEditModalLayoutProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSubmit: (data: any) => Promise<void>;
}
export const MenuEditModalLayout: React.FC<MenuEditModalLayoutProps> = memo(({isOpen, onOpen, onClose, onSubmit }) => {
  const { handleSubmit, register, formState: { isSubmitting } } = useForm();
  return (
    <>
      <IconButton
        colorScheme='messenger'
        aria-label='Edit Menu'
        icon={<EditIcon />}
        size='sm'
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} size={{base: 'xs', sm: 'sm'}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>画像変更</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
            <ModalBody>
              <input required type="file" accept="image/*" {...register('img')} />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='messenger' size='sm' mr={3} type="submit" isLoading={isSubmitting} >変更</Button>
              <Button colorScheme='gray' size='sm' onClick={onClose} isDisabled={isSubmitting}>キャンセル</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
})