import useSWR from "swr";

type KuchikomyuLatestSurveyTexts = {
  id: string;
  answer_text: string;
  answered_at: string;
}[]

export const useFetchKuchikomyuLatestSurveyTexts = (restaurantId: string) => {
  const { data: kuchikomyuLatestSurveyTexts, error, isLoading } = useSWR<KuchikomyuLatestSurveyTexts>(`kuchikomyu/survey/texts/latest/?restaurantId=${restaurantId}`)
  return { kuchikomyuLatestSurveyTexts, isLoading, error };
};