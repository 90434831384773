import useSWR from "swr";


export type SelectedQuestions = {
  id: string;
  restaurant: string;
  question: string;
  question_content: string;
  order: number;
}[]
export const useFetchStaffSurveySelectedQuestions = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `restaurant_staff/restaurants/${restaurantId}/survey/questions/selected/` : null;
  const { data: selectedQuestions, error, isLoading } = useSWR<SelectedQuestions>(fetcherKey)
  return { selectedQuestions, isLoading, error };
};