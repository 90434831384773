import React from "react";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CardHeading } from "../../molecules/card/CardHeading";
import { FaRegBuilding } from "react-icons/fa";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";


type CorporationFormProps = {
  onSubmit: (data: any) => Promise<void>;
  isSubmitting: boolean;
  inputFields: {
    component: React.ComponentType<any>;
    props: Record<string, any>;
  }[];
  cardTitle: string;
}
export const CorporationForm = ({onSubmit, isSubmitting, inputFields, cardTitle}:CorporationFormProps) => {
  return (
    <Box m='auto' w={{base: '100%', md: '650px'}}>
      <form onSubmit={onSubmit} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<FaRegBuilding />} heading={cardTitle}/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              {inputFields.map(({ component: InputComponent, props }, index) => <InputComponent key={index} {...props} />)}
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};