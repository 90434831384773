import React, { memo } from "react";
import { CardBody, CardHeader, Skeleton, Text } from "@chakra-ui/react";
import { BarGraph } from "../../molecules/graph/BarGraph";
import { useFetchLineRegistrationTrends } from "../../../hooks/externalApi/line/useFetchLineRegistrationTrends";
import { useRecoilValue } from "recoil";
import { dashboardDateRangeState } from "../../../store/dashboardDateRangeState";
import { LightShadowCard } from "../../molecules/card/LightShadowCard";


type LineRegistrationTrendsGraphCardProps = {
  restaurantId: string;
}
export const LineRegistrationTrendsGraphCard: React.FC<LineRegistrationTrendsGraphCardProps> = memo(({restaurantId}) => {
  const dateRange = useRecoilValue(dashboardDateRangeState)
  const { lineRegistrationTrends, isLoading } = useFetchLineRegistrationTrends(restaurantId, dateRange.startDate, dateRange.endDate);
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <LightShadowCard>
        <CardHeader pb={1}>
          <Text m={0} fontSize='lg' fontWeight='bold'>ユーザー登録数推移</Text>
        </CardHeader>
        <CardBody px={{base: 1, sm: 3, lg: 3}} py={0}>
          <BarGraph labels={Object.keys(lineRegistrationTrends??[])} values={Object.values(lineRegistrationTrends??[])} seriesName='登録者' />
        </CardBody>
      </LightShadowCard>
    </Skeleton>
  );
});