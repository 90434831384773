import useSWR from "swr";


type Notices = {
  id: string;
  title: string;
  content: string;
  created_at: string;
}[];
export const useFetchUnreadNotices = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `general_setting/restaurants/${restaurantId}/notices/unread/` : null;
  const { data: notices, error, isLoading } = useSWR<Notices>(fetcherKey)
  return { notices, isLoading, error };
};