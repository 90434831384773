import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";


export const GameAccordionMenu = () => {
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          ミニゲーム
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItem border='none'>
            <AccordionButton px='10px' py={0} >
              <Box flex='1' textAlign='left' fontWeight='bold'>
                ルーレット
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <AccordionList>
                <AccordionItemLink to={`games/roulette/segments`} text="クーポン" />
                <AccordionItemLink to={`games/roulette/qr-code`} text="QRコード" />
                <AccordionItemLink to={`games/roulette/setting`} text="設定" />
              </AccordionList>
            </AccordionPanel>
          </AccordionItem>
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
};