import { memo } from "react"
import { FormControl, FormErrorMessage, FormLabel, Skeleton } from "@chakra-ui/react";
import { useFetchLiffTypes } from "../../../../hooks/externalApi/line/useFetchLiffTypes";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../../atoms/ReactSelectWithChakraStyle";


export const LiffTypeSelect : React.FC<{control: any, errors: any}> = memo(({control, errors}) => {
  const { liffTypes, isLoading } = useFetchLiffTypes();
  const liffTypesOption = liffTypes?.map(liffType => ({
    label: liffType.liff_name,
    value: liffType.id
  }));
  const liffTypeError = errors.liff_type?.message;
  return (
    <FormControl isRequired isInvalid={!!errors.liff_type} w='100%'>
      <FormLabel>LIFFタイプ</FormLabel>
      <Skeleton isLoaded={!isLoading} w='100%' borderRadius={5}>
        <Controller
          name='liff_type'
          control={control}
          render={({ field }) => (
            <ReactSelectWithChakraStyle
              placeholder="選択"
              options={liffTypesOption}
              value={liffTypesOption?.find((x) => x.value === field.value)}
              onChange={(newValue:any) => {
                field.onChange(newValue?.value);
              }}
            />
          )}
        />
      </Skeleton>
      <FormErrorMessage>{liffTypeError}</FormErrorMessage>
    </FormControl>
  );
})