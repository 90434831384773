import React, { memo } from "react";
import { Box, Skeleton } from "@chakra-ui/react";
import { useCouponUsedUsersCount } from "../../../hooks/useCouponUsedUsersCount";
import { RiCoupon3Line } from "react-icons/ri";
import { SmallCardWithIcon } from "../../molecules/card/SmallCardWithIcon";
import { useNavigate } from "react-router-dom";
import { getToday } from "../../../utils/getToday";


type CouponUsedUsersCountCardProps = {
  restaurantId: string;
  startDate: string|null;
  endDate: string|null;
}
export const CouponUsedUsersCountCard: React.FC<CouponUsedUsersCountCardProps> = memo(({restaurantId, startDate, endDate}) => {
  const today = getToday();
  const navigate = useNavigate();
  const { couponUsedUsersCount, isLoadingSegmentCouponUsedUsersCount, isLoadingSoCouponUsedCouponUsersCount } = useCouponUsedUsersCount(restaurantId, startDate||today, endDate||today);
  return (
    <Skeleton isLoaded={!isLoadingSegmentCouponUsedUsersCount && !isLoadingSoCouponUsedCouponUsersCount} rounded='2xl'>
      <Box cursor='pointer' position="relative" onClick={() => navigate(`../used-coupon-users`)} transition='0.2s' _hover={{transform: 'translateY(-5px)'}}>
        <SmallCardWithIcon data={`${couponUsedUsersCount}人`} title="クーポン利用者数">
          <RiCoupon3Line size='30px' />
        </SmallCardWithIcon>
      </Box>
    </Skeleton>
  );
});