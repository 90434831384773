import React from "react";
import { useParams } from "react-router-dom";
import { Box, CardBody, CardHeader, Divider, VStack } from "@chakra-ui/react";
import { useFetchGoogleSettingByRestaurantId } from "../../../../hooks/externalApi/google/useFetchGoogleSettingByRestaurantId";
import { SettingItem } from "../../../organisms/SettingItem";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { RestaurantSettingCardFooter } from "../../../organisms/restaurant/RestaurantSettingCardFooter";


export const GoogleSetting = () => {
  const { restaurantId } = useParams();
  const { gSetting, isLoading } = useFetchGoogleSettingByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />;
  const path = gSetting ? `${gSetting.id}/edit` : `register`;
  const gSettingsDetails = [
    { label: 'ビジネスプロフィールID', value: gSetting?.location_id ?? '未登録' },
    { label: 'プレイスID', value: gSetting?.place_id ?? '未登録' },
    { label: '口コミ返信', value: gSetting?.reply_mode_label ?? '未登録' },
    { label: '通知先', value: gSetting?.notification_recipient_names?.length ? gSetting.notification_recipient_names.join('') : '未登録' },
  ];
  return (
    <DarkShadowCard w='100%'>
      <CardHeader pb={0}>
        <CardHeading icon={<SettingsIcon />} heading='GBPマネジャー設定'/>
      </CardHeader>
      <CardBody>
        <VStack align='start' fontSize='sm' fontWeight='bold' spacing={4} w='100%'>
          <Divider my={0} />
          {gSettingsDetails.map((detail) => (
            <React.Fragment key={detail.label}>
              <Box w='100%'>
                <SettingItem label={detail.label} content={detail.value}/>
              </Box>
              <Divider my={0} />
            </React.Fragment>
          ))}
        </VStack>
      </CardBody>
      <RestaurantSettingCardFooter path={path} />
    </DarkShadowCard>
  );
};