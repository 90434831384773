import React, { memo } from "react";
import { Card, CardBody, Text, VStack, Badge } from '@chakra-ui/react'

type DefaultBenefitDesignProps = {
  title: string;
}

export const DefaultBenefitDesign: React.FC<DefaultBenefitDesignProps> = memo(({title}) => {

  return (
    <Card bgColor='#FE914C' borderRadius='20px' h='100%' outline='5px solid white' outlineOffset='-10px' variant='filled' w='100%'>
      <CardBody pt={9} px={4} pb='10px'>
        <VStack h='100%' spacing={3}>
          <Badge bgColor='white' borderRadius='10px' fontSize='3vw' px={2} py='2px'>会員限定メニュー</Badge>
          <Text fontSize='4.5vw' fontWeight='bold' m='0' textAlign='center'>{title}</Text>
        </VStack>
      </CardBody>
    </Card>
    );
});
