import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, VStack } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CardHeading } from "../../molecules/card/CardHeading";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { FaUser } from "react-icons/fa";


type TeamFormProps = {
  onSubmit: any;
  isSubmitting: boolean;
  register: any;
  errors: any;
  cardHeading: string;
  btnLabel: string;
}
export const RestaurantStaffForm = ({ onSubmit, isSubmitting, register, errors, cardHeading, btnLabel }: TeamFormProps) => {
  type FormComponent = {
    component: React.ComponentType<any>;
    props: Record<string, any>;
  }
  const formComponents: FormComponent[] = [
    { component: NameInput, props: { register, errors } },
    { component: NicknameInput, props: { register, errors } },
    { component: ImageInput, props: { register, errors } },
  ];
  return (
    <Box m='auto' w='650px' maxW='100%'>
      <form onSubmit={onSubmit} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<FaUser />} heading={cardHeading}/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              {formComponents.map(({ component: Component, props }, index) => <Component key={index} {...props} />)}
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>{btnLabel}</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};


const NameInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.name}>
      <FormLabel>名前</FormLabel>
      <Input {...register('name')} />
      <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
    </FormControl>
  )
}

const NicknameInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isInvalid={!!errors.nickname}>
      <FormLabel>ニックネーム</FormLabel>
      <Input {...register('nickname')} maxLength={12} />
      <FormHelperText>12文字以内</FormHelperText>
    </FormControl>
  )
}

const ImageInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isInvalid={!!errors.img}>
      <FormLabel>画像</FormLabel>
      <input type="file" accept="image/*" {...register('img')} />
    </FormControl>
  )
}