import { CardHeader, CardBody, CardFooter, Flex, IconButton, HStack, Image, Text, Box, InputGroup, Input, InputRightElement, Button, VStack } from '@chakra-ui/react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TableLayout } from "../../template/table/TableLayout";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { DarkShadowCard } from '../../molecules/card/DarkShadowCard';
import { CardHeading } from '../../molecules/card/CardHeading';
import { RiTeamFill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { TableRowWithNavigationLayout } from '../../template/table/TableRowWithNavigationLayout';
import { PartialCircularLoading } from '../../molecules/loading/PartialCircularLoading';
import { RestaurantStaffList, useFetchPaginatedRestaurantStaffList } from '../../../hooks/restaurantStaff/useFetchPaginatedRestaurantStaffList';
import { formatDate } from '../../../utils/formatDate';
import { useMemo, useState } from 'react';


export const StaffList = () => {
  const { restaurantId } = useParams();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('p'));
  const staffName = searchParams.get('name');
  const queryParams = useMemo(() => buildQueryParams(page, staffName), [page, staffName]);
  const { paginatedRestaurantStaffList, isLoading } = useFetchPaginatedRestaurantStaffList(restaurantId, queryParams)
  return (
    <DarkShadowCard>
      <CardHeader>
        <Flex align='center' justify='space-between'>
          <CardHeading icon={<RiTeamFill />} heading='スタッフ一覧'/>
          <IconButton
            as={Link}
            variant='outline'
            colorScheme='messenger'
            aria-label='register'
            icon={<AddIcon />}
            size='xs'
            to='register'
          />
        </Flex>
      </CardHeader>
      <CardBody py={2}>
        <VStack align='start'>
          <NameSearch />
          <Box w='100%'>
            <StaffTable isLoading={isLoading} staffList={paginatedRestaurantStaffList?.staff_list} />
          </Box>
        </VStack>
      </CardBody>
      <CardFooter>
        <Pagination totalItems={paginatedRestaurantStaffList?.count} pageSize={paginatedRestaurantStaffList?.page_size ?? 10}/>
      </CardFooter>
    </DarkShadowCard>
  );
};


const buildQueryParams = (currentPage: number|null, staffName: string|null) => {
  const queryParams = new URLSearchParams();
  currentPage && queryParams.set('currentPage', currentPage.toString());
  staffName && queryParams.set('name', staffName);
  return queryParams;
};


const NameSearch = () => {
  const [staffName, setStaffName] = useState('');
  const navigate = useNavigate();
  const onClickSearch = () => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('name', staffName);
    navigate(`?${newSearchParams.toString()}`);
  }
  const onClickClear = () => {
    setStaffName('');
    navigate('');
  }
  return (
    <Box>
      <InputGroup size='sm'>
        <Input
          placeholder="名前で検索"
          onChange={(e) => {setStaffName(e.target.value);}}
          rounded='md'
          onKeyDown={(e) => {if (e.key === 'Enter' && staffName) onClickSearch();}}
          value={staffName}
        />
        <InputRightElement>
          <Button size='xs' rounded='full' variant='ghost' onClick={onClickClear}>
            ✗
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}


const StaffTable = ({isLoading, staffList}: {isLoading: boolean, staffList?: RestaurantStaffList}) => {
  if (isLoading || !staffList) return <PartialCircularLoading />
  const headers = ['名前', 'ニックネーム', '取得件数', '最新取得日時'];
  const rows = staffList.map((staff) => (
    <TableRowWithNavigationLayout
      key={staff.id}
      path={staff.id}
      size='sm'
      childrenList={[
        <HStack>
          <Image src={staff.img} alt='staff' fallbackSrc='/images/fallback-user.png' boxSize='34px' rounded='md' />
          <Text m='0'>{staff.name}</Text>
        </HStack>,
        staff.nickname,
        staff.restaurant_survey_response_count + staff.staff_survey_response_count + '件',
        staff.latest_response_day ? formatDate(staff.latest_response_day) : '',
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />
}