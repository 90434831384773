import { Accordion, List, ListItem } from '@chakra-ui/react'
import { SidebarItemLink } from "../../molecules/SidebarItemLink";


export const KuitanAgentSidebarMenu = () => {
  const menuItems = [
    { component: <SidebarItemLink to='' text="基本情報" />, key: 'basic-info' },
    { component: <SidebarItemLink to='ratings' text="評価" />, key: 'ratings' },
  ];
  return (
    <Accordion allowMultiple>
      <List spacing={1}>
        {menuItems.map(item => (
          <ListItem key={item.key}>
            {item.component}
          </ListItem>
        ))}
      </List>
    </Accordion>
  );
};