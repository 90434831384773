import React from "react";
import { CardHeader, CardBody, VStack, Box, Divider } from '@chakra-ui/react'
import { CardHeading } from "../../molecules/card/CardHeading";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { MdOutlineHistory } from "react-icons/md";
import { SettingItem } from "../../organisms/SettingItem";
import { useFetchMonthlyDispatchRequest } from "../../../hooks/kuitan/useFetchMonthlyDispatchRequest";
import { useParams } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { formatDate, formatMonth } from "../../../utils/formatDate";


export const MonthlyDispatchRequestForRestaurant = () => {
  const { requestId = "" } = useParams();
  const { monthlyDispatchRequest, isLoading } = useFetchMonthlyDispatchRequest(requestId);
  if (isLoading || !monthlyDispatchRequest) return <CircularLoading />
  const { corporation_name, restaurant_names, team_name, month, number, message, unit_price, menu_specification_label, budget_limit, max_companions_allowed, is_transportation_cost_provided, dispatch_ng_days, dispatch_ng_time_periods, requested_at } = monthlyDispatchRequest;
  const historyDetails = [
    { label: '法人', value: corporation_name },
    { label: '店舗', value: restaurant_names.join('\n') },
    { label: '派遣月', value: formatMonth(month) },
    { label: '件数', value: number + '件' },
    { label: 'メニュー指定', value: menu_specification_label },
    { label: '予算上限', value: '¥' + budget_limit.toLocaleString() },
    { label: '同伴可能人数', value: max_companions_allowed + '人' },
    { label: '交通費', value: is_transportation_cost_provided ? 'あり' : 'なし' },
    { label: '依頼日時', value: formatDate(requested_at) },
    { label: '訪問NG曜日', value: dispatch_ng_days.join(',') },
    { label: '訪問NG時間', value: dispatch_ng_time_periods.join(',') },
    { label: 'メッセージ', value: message },
  ];
  return (
    <DarkShadowCard w='100%'>
      <CardHeader pb={2}>
        <CardHeading icon={<MdOutlineHistory />} heading='単月派遣依頼履歴'/>
      </CardHeader>
      <CardBody>
        <VStack align='start' fontSize='sm' fontWeight='bold' spacing={4} w='100%'>
          <Divider my={0} />
          {historyDetails.map((detail) => (
            <React.Fragment key={detail.label}>
              <Box w='100%'>
                <SettingItem label={detail.label} content={detail.value}/>
              </Box>
              <Divider my={0} />
            </React.Fragment>
          ))}
        </VStack>
      </CardBody>
    </DarkShadowCard>
  );
};