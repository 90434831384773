import React, { memo } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchCorporation } from "../../../hooks/corporations/useFetchCorporation";
import { Box, CardBody, CardFooter, CardHeader, Divider, HStack, IconButton, VStack, useDisclosure, Link as ChakraLink } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { FaRegBuilding } from "react-icons/fa";
import { SettingItem } from "../../organisms/SettingItem";
import { useFetchUser } from "../../../hooks/user/useFetchUser";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useDelete } from "../../../hooks/useDelete";
import { DeleteModal } from "../../molecules/DeleteModal";


export const Corporation = () => {
  const { corporationId } = useParams();
  const { corporation, isLoading } = useFetchCorporation(corporationId)
  if (isLoading) return <CircularLoading />
  if (!corporation) return null
  const corporationDetails = [
    { label: '営業担当', value: corporation.salesman_name },
    { label: 'ID', value: corporation.id },
    { label: 'ユーザーネーム', value: corporation.user_data.username },
    { label: 'メールアドレス', value: corporation.user_data.email },
    { label: '電話番号', value: corporation.user_data.phone_number },
    { label: '住所', value: corporation.address },
    { label: '代表者名', value: `${corporation.president_last_name}${corporation.president_first_name}` },
    { label: '代表者電話番号', value: corporation.president_phone_number },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '650px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<FaRegBuilding />} heading={corporation.name}/>
        </CardHeader>
        <CardBody py={0}>
          <VStack align='start' fontSize='sm' fontWeight='bold' spacing={4} w='100%'>
            <Divider my={0} />
            {corporationDetails.map((detail) => (
              <React.Fragment key={detail.label}>
                <Box w='100%'>
                  <SettingItem label={detail.label} content={detail.value}/>
                </Box>
                <Divider my={0} />
              </React.Fragment>
            ))}
          </VStack>
        </CardBody>
        <CardFooter>
          <EditAndDeleteIcons id={corporation.user} title={corporation.name} />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
};


const EditAndDeleteIcons: React.FC<{ id: string, title: string }> = memo(({ id, title }) => {
  const navigate = useNavigate()
  const { user, isLoading } = useFetchUser()
  if (isLoading || !user || !user.is_superuser) return null
  return (
    <HStack align='center' w='100%'>
      <ChakraLink as={Link} color='messenger.500' fontSize='12px' fontWeight='normal' to='pass/edit'>パスワード変更</ChakraLink>
      <IconButton
        colorScheme='messenger'
        aria-label='Edit Corporation'
        icon={<EditIcon />}
        size='sm'
        variant='ghost'
        onClick={() => navigate('edit')}
      />
      <DeleteCorporationModal id={id} title={title} />
    </HStack>
  )
})

const DeleteCorporationModal: React.FC<{ id: string, title: string }> = memo(({id, title}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDelete(`accounts/users/${id}/`, onClose)
  return (
    <>
      <IconButton
        colorScheme='red'
        aria-label='Delete Corporation'
        icon={<DeleteIcon />}
        size='sm'
        variant='ghost'
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={title}
        onSubmit={onSubmit}
      />
    </>
  );
});