import React, { memo } from "react";
import { Box, Text, VStack } from '@chakra-ui/react'
import { CouponImg } from "./CouponImg";
import { DefaultCouponDesignSm } from "./DefaultCouponDesignSm";
import { useNavigate } from "react-router-dom";

type LatestValidCouponProps = {
  id: string;
  title: string;
  img: string | null;
}

export const LatestValidCoupon: React.FC<LatestValidCouponProps> = memo(({id, title, img}) => {
  const navigate = useNavigate()

  return (
    <VStack onClick={() => navigate(`/membership/coupons/${id}`)} spacing={2}>
      <Box h='30vw' w='100%'>
        {img ? <CouponImg img={img} /> : <DefaultCouponDesignSm title={title} />}
      </Box>
      <Text fontSize='12px' fontWeight='bold' m='0' textAlign='center'>{title}</Text>
    </VStack>
    );
});
