import React, { memo } from "react";
import { UserUsernameInput } from "../../molecules/form/UserUsernameInput";
import { UserPasswordInput } from "../../organisms/restaurant/UserPasswordInput";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CardHeading } from "../../molecules/card/CardHeading";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { FaUserTie } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useManagerRegisterForm } from "../../../hooks/manager/useManagerRegisterForm";
import { useSubmitManagerRegister } from "../../../hooks/manager/useSubmitManagerRegister";
import { ManagerNameInput } from "../../organisms/managers/ManagerNameInput";
import { ManagerRestaurantsSelect } from "../../organisms/managers/ManagerRestaurantsSelect";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const ManagerRegister: React.FC = memo(() => {
  const { corporationId } = useParams();
  const { handleSubmit, register, errors, isSubmitting, control } = useManagerRegisterForm();
  const onSubmit = useSubmitManagerRegister(corporationId);
  const inputFields: InputField[] = [
    { component: UserUsernameInput, props: { register, errors } },
    { component: UserPasswordInput, props: { register, errors } },
    { component: ManagerNameInput, props: { register, errors } },
    { component: ManagerRestaurantsSelect, props: { corporationId, errors, control } }
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '650px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<FaUserTie />} heading='マネジャー登録'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={4}>
              {inputFields.map(({ component: InputComponent, props }, index) => <InputComponent key={index} {...props} />)}
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  )
});