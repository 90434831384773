import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type Benefits = {
  id: string;
  rank: string;
  rank_name: string;
  title: string;
  explanation: string;
  notes: string;
  img: string;
}

export const useFetchCustomerBenefit = (benefitId: string) => {
  const { data: benefit, error, isLoading } = useSwrNoAuth<Benefits>(`membership/customer-benefits/${benefitId}/`)
  return { benefit, isLoading, error };
};