import React from 'react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from '@chakra-ui/react'

type PhoneNumberInputProps = {
  register: any;
  errors: any;
}

export const AuthPhoneNumberInput: React.FC<PhoneNumberInputProps> = ({register, errors}) => {
  return (
    <FormControl isRequired isInvalid={!!errors.phone_number}>
      <FormLabel>電話番号</FormLabel>
      <Input placeholder="09012345678" type="tel" minLength={10} maxLength={11} pattern="[\d]*" {...register('phone_number')} />
      <FormHelperText>ハイフンなし</FormHelperText>
      <FormErrorMessage>{errors.phone_number?.message}</FormErrorMessage>
    </FormControl>
  );
};