import React, { memo } from "react";
import { Box, FormControl, FormErrorMessage, FormLabel, HStack, Input } from "@chakra-ui/react";

type PresidentNameInputFieldProps = {
  register: any;
  errors: any;
}
export const PresidentNameInputField: React.FC<PresidentNameInputFieldProps> = memo(({register, errors}) => {
  const presidentLastNameError = errors.corporation?.president_last_name?.message;
  const presidentFirstNameError = errors.corporation?.president_first_name?.message;
  return (
    <Box w='100%'>
      <HStack>
        <FormControl isRequired isInvalid={errors.corporation?.president_last_name} w='100%'>
          <FormLabel>代表者 性</FormLabel>
          <Input type="text" {...register('corporation.president_last_name')} />
          <FormErrorMessage>{presidentLastNameError}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={errors.corporation?.president_first_name} w='100%'>
          <FormLabel>代表者 名</FormLabel>
          <Input type="text" {...register('corporation.president_first_name')} />
          <FormErrorMessage>{presidentFirstNameError}</FormErrorMessage>
        </FormControl>
      </HStack>
    </Box>
  );
});
