import React, { memo, useState } from "react";
import { useFetchLineAccountCounts } from "../../../hooks/externalApi/line/useFetchLineAccountCounts";
import { Text, Select, Box, HStack } from '@chakra-ui/react'
import { useParams } from "react-router-dom";


export const LineAccountsPerVisitCount: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const [count, setCount] = useState<string>('-')
  const { lineAccountCounts, isLoading } = useFetchLineAccountCounts(restaurantId);
  if (isLoading || !lineAccountCounts || lineAccountCounts.length===0) return null
  return (
    <HStack spacing={3}>
      <Box w='90px'>
        <Select placeholder='来店回数' size='xs' onChange={(e) => setCount(e.target.value)}>
          {lineAccountCounts.map((lineAccountCount, i) => (
            <option key={i} value={lineAccountCount.count}>{lineAccountCount.visit_count}回</option>
          ))}
        </Select>
      </Box>
      <Text fontSize='20px' fontWeight='bold' m='0'>
        {count}
        <Box as="span" fontSize='12px' fontWeight='normal' ml={1}>人</Box>
      </Text>
    </HStack>
  );
});