import useSWRInfinite from "swr/infinite";
import { useGeneratePaginationKey } from "../../useGeneratePaginationKey";
import { usePaginationStatus } from "../../usePagenationStatus";

type LineSegmentMsgHistory = {
  id: string;
  line_accounts: string[];
  visit_count: number;
  img: string;
  notification_message: string;
  title: string;
  text: string;
  label: string;
  url: string;
  send_at: string;
}[]
export const useFetchLineSegmentMsgHistoryPerPage = (restaurantId?: string) => {
  const limit = 10;

  const getKey = useGeneratePaginationKey(`membership/line/segment-msg/history/?restaurantId=${restaurantId}&`, limit);

  const {
    data: lineSegmentMsgHistoryList,
    isValidating,
    isLoading,
    setSize
  } = useSWRInfinite<LineSegmentMsgHistory>(getKey);

  const isReachingEnd = usePaginationStatus(limit, lineSegmentMsgHistoryList);
  const lineSegmentMsgHistory = lineSegmentMsgHistoryList?.flat()


  return {
    lineSegmentMsgHistory,
    isValidating,
    isLoading,
    setSize,
    isReachingEnd
  };
};