import React, { memo } from 'react';
import { VStack } from '@chakra-ui/react';
import { KuchikomyuQuestionScores } from '../../organisms/kuchikomyu/KuchikomyuQuestionScores';
import { KuchikomyuOverallScoreAnswerCountSection } from '../../organisms/kuchikomyu/KuchikomyuOverallScoreAnswerCountSection';
import { KuchikomyuOverallScoreAndLatestReviewSection } from '../../organisms/kuchikomyu/KuchikomyuOverallScoreAndLatestReviewSection';
import { CircularLoading } from '../../molecules/loading/CircularLoading';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { kuchikomyuDateRangeState } from '../../../store/kuchikomyuDateRangeState';
import { DateFilterSection } from '../../organisms/DateFilterSection';


export const KuchikomyuScores: React.FC = memo(() => {
  const setDateRange = useSetRecoilState(kuchikomyuDateRangeState);
  const { restaurantId } = useParams()
  if (!restaurantId) return <CircularLoading />
  return (
    <VStack w='100%' spacing={5}>
      <DateFilterSection setDateRange={setDateRange} />
      <KuchikomyuOverallScoreAnswerCountSection restaurantId={restaurantId} />
      <KuchikomyuOverallScoreAndLatestReviewSection restaurantId={restaurantId} />
      <KuchikomyuQuestionScores restaurantId={restaurantId} />
    </VStack>
  );
});