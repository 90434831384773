import { memo } from "react"
import { Navigate, Outlet } from "react-router-dom";
import Footer from "../../organisms/Footer";
import { CorporationSidebar } from "../../organisms/sidebar/CorporationSidebar";
import CorporationHeader from "../../organisms/header/CorporationHeader";
import Layout from "../Layout";


const CorporationBase: React.FC = memo(() => {
  const token = localStorage.getItem('localJWT');
  if (!token) return <Navigate replace to="/corporation-login"/>;
  return (
    <Layout
      header={<CorporationHeader />}
      nav={<CorporationSidebar />}
      main={<Outlet />}
      footer={<Footer />}
    />
  );
});


export default CorporationBase;
