import { Box, CardBody, CardHeader, Divider, Flex, List, ListItem, Text } from "@chakra-ui/react";
import React, { memo } from "react"
import { ChevronRightIcon, SettingsIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";


export const MembershipSettings: React.FC = memo(() => {
  const navigate = useNavigate()
  const menuItems = [
    { name: 'ランク', navigateTo: 'rank' },
    { name: 'ランクカード', navigateTo: 'rank-card' },
    { name: 'スタンプカード', navigateTo: 'stamp-card' },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '580px'}}>
      <DarkShadowCard fontWeight='bold'>
        <CardHeader>
          <Flex align='center' justifyContent='space-between' px={2} >
            <Text fontSize='24px' m='0'>設定</Text>
            <SettingsIcon boxSize={6} color='gray.400' />
          </Flex>
        </CardHeader>
        <CardBody pt={0}>
          <Divider m='0' borderColor='gray.300' />
          <List m='0'>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem cursor='pointer' _hover={{ bg: 'gray.100' }} p={2} onClick={() => navigate(item.navigateTo)}>
                  <Flex align='center' justifyContent='space-between' w='100%'>
                    <Text m='0'>{item.name}</Text>
                    <ChevronRightIcon color='gray.400' />
                  </Flex>
                </ListItem>
                <Divider m='0' borderColor='gray.300' />
              </React.Fragment>
            ))}
          </List>
        </CardBody>
      </DarkShadowCard>
    </Box>
  );
});