import React, { memo } from "react";
import { VStack } from "@chakra-ui/react";
import { MembershipCardSection } from "../../organisms/membership/MembershipCardSection";
import { CouponSection } from "../../organisms/membership/CouponSection";
import { RankServiceSection } from "../../organisms/membership/RankServiceSection";
import { useRecoilValue } from "recoil";
import { lineProfileState } from "../../../store/lineProfileState";
import { customerRestaurantId } from "../../../store/customerRestaurantId";

export const Top: React.FC = memo(() => {
  const lineProfile = useRecoilValue(lineProfileState)
  const restaurantId = useRecoilValue(customerRestaurantId)

  return (
    <VStack spacing={6} w='100%'>
      <MembershipCardSection restaurantId={restaurantId!} lineId={lineProfile!.userId} displayName={lineProfile!.displayName} />
      <CouponSection restaurantId={restaurantId!} lineId={lineProfile!.userId} />
      <RankServiceSection />
    </VStack>
    );
});