import React, { memo } from 'react';
import { StarRateScoreLayout } from './StarRateScoreLayout';

type StarRateOverallScoreLayoutProps = {
  'overall_scores_count': number,
  'overall_scores_average': number,
  'overall_scores_5_count': number,
  'overall_scores_5_percentage': number,
  'overall_scores_4_count': number,
  'overall_scores_4_percentage': number,
  'overall_scores_3_count': number,
  'overall_scores_3_percentage': number,
  'overall_scores_2_count': number,
  'overall_scores_2_percentage': number,
  'overall_scores_1_count': number,
  'overall_scores_1_percentage': number,
}

export const StarRateOverallScoreLayout: React.FC<StarRateOverallScoreLayoutProps> = memo((props) => {

  return (
    <StarRateScoreLayout
      prefix="overall_scores"
      props={props}
    />
  );
});
