import { Skeleton } from '@chakra-ui/react';
import { useFetchRestaurant } from '../../hooks/restaurants/useFetchRestaurant';


type RestaurantNameProps = {
  restaurantId: string
}
export const RestaurantName: React.FC<RestaurantNameProps> = ({restaurantId}) => {
  const {restaurant, isLoading} = useFetchRestaurant(restaurantId);
  if (isLoading) return <Skeleton h='24px' w='100%' />;
  if (!restaurant) return <>存在しない店舗</>;
  return <>{restaurant.name}</>;
};