import React, { memo } from "react"
import { Outlet } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { Box } from "@chakra-ui/react";
import { useLiff } from "../../../hooks/membership/useLiff";
import { MembershipHeader } from "../../organisms/membership/MembershipHeader";
import { MembershipFooter } from "../../organisms/membership/MembershipFooter";

const MembershipBase: React.FC = memo(() => {
  const fetchFin = useLiff();
  if (!fetchFin) return <CircularLoading />;

  return (
    <>
      <MembershipHeader />
      <Box as='main' bgColor='white' height='auto' minH='100vh' pt='60px' pb='80px' >
        <Outlet />
      </Box>
      <MembershipFooter />
    </>
  );
});

export default MembershipBase;
