import React, { memo } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useFetchMembershipRank } from "../../../hooks/membership/useFetchMembershipRank";

type BenefitsProps = {
  restaurantId: string;
  lineId: string;
  displayName: string;
}

export const BenefitsTop: React.FC<BenefitsProps> = memo(({restaurantId, lineId, displayName}) => {
  const { rank, isLoadingRank } = useFetchMembershipRank(restaurantId, lineId)
  if (!rank || isLoadingRank) return null
  return (
    <Box textAlign="center" bg='#5271FD' p='5' w='100%'>
      <Text as='b' fontSize='lg' color='white' >{displayName}さんのランクは{rank}です</Text>
    </Box>
  );
});
