import React, { memo } from 'react';
import { CardBody, CardHeader, Flex, Grid, HStack, Icon, Link, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { StarRateSurveyScoreLayout } from '../template/StarRateSurveyScoreLayout';
import { Skeletons } from '../atoms/Skeletons';
import { DarkShadowCard } from '../molecules/card/DarkShadowCard';


type SurveyScoreCardsProps = {
  survey_answer_scores_count: number;
  survey_answer_scores_average: number;
  survey_answer_scores_1_count: number;
  survey_answer_scores_2_count: number;
  survey_answer_scores_3_count: number;
  survey_answer_scores_4_count: number;
  survey_answer_scores_5_count: number;
  survey_answer_scores_1_percentage: number;
  survey_answer_scores_2_percentage: number;
  survey_answer_scores_3_percentage: number;
  survey_answer_scores_4_percentage: number;
  survey_answer_scores_5_percentage: number;
  id: string;
  title: string;
}[];
export const SurveyScoreCards: React.FC<{isLoading: boolean, surveyScores?: SurveyScoreCardsProps, to?: string}> = memo(({isLoading, surveyScores, to}) => {
  if (isLoading) return <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}} gap={3} w='100%'><Skeletons length={3} h='280px' rounded='2xl' /></Grid>
  if (!surveyScores || surveyScores.length===0) return null
  return (
    <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}} gap={3} w='100%'>
      {surveyScores.map((surveyScore) => (
        <DarkShadowCard key={surveyScore.id}>
          <CardHeader>
            <Flex align='center' fontWeight='bold'>
              <Text noOfLines={1} m={0} w='80%'>{surveyScore.title}</Text>
              <Link as={RouterLink} to={to ? `${to}/${surveyScore.id}` : surveyScore.id} fontSize='sm' color='gray.500' w='20%' _hover={{ color: 'gray.400' }}>
                <HStack align='center' justify='end' spacing={1}>
                  <Text m='0'>詳細</Text>
                  <Icon as={IoIosArrowForward} />
                </HStack>
              </Link>
            </Flex>
          </CardHeader>
          <CardBody pt={0}>
            <StarRateSurveyScoreLayout {...surveyScore} />
          </CardBody>
        </DarkShadowCard>
      ))}
    </Grid>
  );
});