import { useGeneratePaginationKey } from "../useGeneratePaginationKey";
import { usePaginationStatus } from "../usePagenationStatus";
import { useSwrInfiniteNoAuth } from "../swr/useSwrInfiniteNoAuth";

type LineSegmentMessagesPerPage = {
  id: string;
  title: string;
  text: string;
  send_at: string;
}[]

export const useFetchLineSegmentMessagesPerPage = (restaurantId: string, lineId: string) => {
  const limit = 10;

  const getKey = useGeneratePaginationKey(`membership/restaurants/${restaurantId}/line-accounts/${lineId}/segment-messages/?`, limit);
  const {
    data: lineSegmentMessagesList,
    isValidating,
    isLoading,
    setSize
  } = useSwrInfiniteNoAuth<LineSegmentMessagesPerPage>(getKey);

  const isReachingEnd = usePaginationStatus(limit, lineSegmentMessagesList);
  const lineSegmentMessages = lineSegmentMessagesList?.flat()

  return {
    lineSegmentMessages,
    isValidating,
    isLoading,
    setSize,
    isReachingEnd
  };
};