import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";
import { switchImgAppend } from "../../utils/switchImgAppend";

export const useSubmitBenefit = (restaurantId: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();

  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("restaurantId", restaurantId);
      uploadData.append("title", data.title);
      uploadData.append("explanation", data.explanation);
      uploadData.append("notes", data.notes);
      uploadData.append("ranks", JSON.stringify(data.ranks));
      switchImgAppend(data.img, uploadData);
      await customAuthAxios.post(`membership/benefits/`, uploadData);
      showToast('登録が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  };

  return onSubmit;
};