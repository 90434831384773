import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";


export const KuchikomyuAccordionMenu = () => {
  return (
    <AccordionItem border='none'>
      <AccordionButton px='10px' py={0} >
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          口コミュ
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItem border='none'>
            <AccordionButton px='10px' py={1} >
              <Box flex='1' textAlign='left' fontWeight='bold'>
                QRコード
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <AccordionList>
                <AccordionItemLink to={`kuchikomyu/qr-code`} text="日本語版" />
                <AccordionItemLink to={`kuchikomyu/qr-code/en`} text="英語版" />
              </AccordionList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem border='none'>
            <AccordionButton px='10px' py={1} >
              <Box flex='1' textAlign='left' fontWeight='bold'>
                アンケート
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <AccordionList>
                <AccordionItemLink to={`kuchikomyu/survey/questions`} text="アンケート設定" />
                <AccordionItemLink to={`kuchikomyu/survey/questions/selected`} text="アンケートセット" />
              </AccordionList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItemLink to={`kuchikomyu/scores`} text="データ解析" />
          <AccordionItemLink to={`kuchikomyu/setting`} text="設定" />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
};