import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { SelectBranchScore } from "../../../molecules/form/SelectBranchScore";
import { GUrlTextArea } from "../../soCoupon/GUrlTextArea";
import { SubmitBtn } from "../../../atoms/button/SubmitBtn";
import { SwitchSurveyStatus } from "../../../molecules/form/SwitchSurveyStatus";
import { SegmentCountInput } from "../../../molecules/form/SegmentCountInput";


type RouletteSettingFormProps = {
  onSubmit: any;
  register: any;
  control: any;
  isSubmitting: boolean;
  errors: any;
}
export const RouletteSettingFormForSalesman = ({onSubmit, register, control, isSubmitting, errors}: RouletteSettingFormProps) => {
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <form onSubmit={onSubmit} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='ルーレット設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <SelectBranchScore control={control} errors={errors} />
              <GUrlTextArea register={register} errors={errors} />
              <SegmentCountInput register={register} errors={errors} />
              <SwitchSurveyStatus control={control} />
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};