import { useEffect } from "react";
import confetti from "canvas-confetti";


export const useConfetti = () => {
  useEffect(() => {
    const handleNormalConfetti = () => {
      confetti({
        particleCount: 230,
        spread: 90,
        ticks: 100,
        colors: ['#b78700', '#eeb600', '#fff71', '#f8da45'],
        zIndex: 1000
      });
    };
    handleNormalConfetti();
  }, []);
};