import React, { memo } from 'react';
import { Flex, HStack, Link, Text } from '@chakra-ui/react';


export const AuthFooter: React.FC = memo(() => {
  return (
    <Flex
      as="footer"
      align='center'
      bg="#0E0F0E"
      color='white'
      fontSize={{ base: '10px', sm: 'sm', lg: 'sm' }}
      justify='space-between'
      p={4}
      w='100%'
      h='56px'
    >
      <Text m='0'>
        Copyright © BLOCKs
      </Text>
      <HStack spacing={4}>
        <Link href='https://blocks-agreemen.ifreagroup.co.jp' isExternal _hover={{ textDecoration: 'none', color: 'white' }}>
          利用規約
        </Link>
        <Link href='https://blocks-privacypolicy.ifreagroup.co.jp	' isExternal _hover={{ textDecoration: 'none', color: 'white' }}>
          プライバシーポリシー
        </Link>
      </HStack>
    </Flex>
  );
});