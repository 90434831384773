import React from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";


type ImgInputProps = {
  register: any,
}
export const ImgInput: React.FC<ImgInputProps> = ({register}) => (
    <FormControl isRequired>
      <FormLabel>画像</FormLabel>
      <Input
        {...register('img')}
        accept="image/*"
        borderRadius='none'
        type="file"
        variant='unstyled'
        required
      />
    </FormControl>
);