import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from "@chakra-ui/react";


type UserPasswordInputProps = {
  register: any;
  errors: any;
}
export const UserPasswordInput: React.FC<UserPasswordInputProps> = memo(({register, errors}) => {
  const passwordError = errors.user?.password?.message;
  return (
    <FormControl isRequired isInvalid={errors.user?.password} w='100%'>
      <FormLabel>パスワード</FormLabel>
      <Input type="text" {...register('user.password')} />
      <FormHelperText>4文字以上</FormHelperText>
      <FormErrorMessage>{passwordError}</FormErrorMessage>
    </FormControl>
  );
});