import { memo } from "react"
import { Navigate, Outlet } from "react-router-dom";
import Footer from "../../organisms/Footer";
import ManagerHeader from "../../organisms/header/ManagerHeader";
import { ManagerSidebar } from "../../organisms/sidebar/ManagerSidebar";
import Layout from "../Layout";


const ManagerBase: React.FC = memo(() => {
  const token = localStorage.getItem('localJWT');
  if (!token) return <Navigate replace to="/login/manager"/>;
  return (
    <Layout
      header={<ManagerHeader />}
      nav={<ManagerSidebar />}
      main={<Outlet />}
      footer={<Footer />}
    />
  );
});


export default ManagerBase;