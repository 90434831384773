import { Skeleton, SkeletonProps } from "@chakra-ui/react";

type SkeletonsProps = {
  length: number;
};
type CombinedProps = SkeletonsProps & SkeletonProps
export const Skeletons: React.FC<CombinedProps> = ({ length, ...props }) => (
  <>
    {Array.from({ length }, () => <Skeleton {...props} />)}
  </>
);