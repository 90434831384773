import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const editStaffSettingSchema = yup.object().shape({
  staff_branch_score: yup.number().required('入力必須項目です'),
  staff_branch_question: yup.string().required('入力必須項目です'),
  restaurant_branch_score: yup.number().required('入力必須項目です'),
  restaurant_branch_question: yup.string().required('入力必須項目です'),
  qr_card_img: yup.mixed(),
  is_navigate_g_url: yup.boolean(),
});
type StaffSetting = {
  id: string;
  restaurant: string;
  g_url: string;
  is_navigate_g_url: boolean;
  restaurant_branch_score: number;
  restaurant_branch_question: string;
  staff_branch_score: number;
  staff_branch_question: string;
}
export const useEditStaffSettingForm = (staffSetting?: StaffSetting) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(editStaffSettingSchema),});
  useEffect(() => {
    if (staffSetting) {
      setValue('restaurant_branch_score', staffSetting.restaurant_branch_score);
      setValue('restaurant_branch_question', staffSetting.restaurant_branch_question);
      setValue('staff_branch_score', staffSetting.staff_branch_score);
      setValue('staff_branch_question', staffSetting.staff_branch_question);
      setValue('is_navigate_g_url', staffSetting.is_navigate_g_url);
    }
  }, [staffSetting]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
    setValue
  };
};