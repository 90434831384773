import useSWR from "swr";


export type SurveyAnswerTrend = {
  date: string;
  count: number;
}[]
export const useFetchRestaurantSurveyAnswerTrend = (staffId?: string, queryParams?: URLSearchParams) => {
  const fetchKey = staffId ? `restaurant_staff/staff/${staffId}/restaurant-survey/answer/trend/${queryParams?.toString() && `?${queryParams}`}` : null;
  const { data: restaurantSurveyAnswerTrend, error, isLoading } = useSWR<SurveyAnswerTrend>(fetchKey)
  return { restaurantSurveyAnswerTrend, isLoading, error };
};