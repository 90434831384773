import { atom } from 'recoil';

type DateRange = {
  startDate: string | null;
  endDate: string | null;
};

export const manLaboDateRangeState = atom<DateRange>({
  key: 'manLaboDateRangeState',
  default: { startDate: null, endDate: null }
});