import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const surveySchema = yup.object().shape({
  survey: yup.array().of(
    yup.object().shape({
      answer_score: yup.number().required('Please select without fail'),
    }),
  ),
  answer_text: yup.string(),
});

export const useAnswerKuchikomyuSurveyEnForm = () => {
  const { handleSubmit, register, formState: { isSubmitting, errors }, control } = useForm({
    resolver: yupResolver(surveySchema),
  });

  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};