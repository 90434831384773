import React, { memo } from "react";
import Rating from '@mui/material/Rating';
import { Box, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Controller } from "react-hook-form";


export const OverallScoreRating: React.FC<{control: any, errors: any}> = memo(({control, errors}) => {
  const theme = createTheme();
  return (
    <FormControl isInvalid={!!errors.score}>
      <Controller
        name={`score`}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <ThemeProvider theme={theme}>
            <Box
              as={Rating}
              fontSize='4rem'
              name="score"
              size="large"
              onChange={(event: any, newValue: any) => {
                field.onChange(newValue);
              }}
            />
          </ThemeProvider>
        )}
      />
      <FormErrorMessage justifyContent='center'>{errors.score?.message}</FormErrorMessage>
    </FormControl>
  );
});