import useSWR from "swr";

type Tables = {
  id: string;
  table_name: string;
  qr_code: string;
  in_use: boolean;
  auth_code: number;
}[]
export const useFetchTablesByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `table_stock/restaurants/${restaurantId}/tables/` : null;
  const { data: tables, error, isLoading } = useSWR<Tables>(fetcherKey)
  return { tables, isLoading, error }
}