import { memo } from "react"
import {
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'


export const SettingAlert: React.FC<{msg:string}> = memo(({msg}) => {
  return (
    <>
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>{msg}</AlertTitle>
      </Alert>
      <br />
    </>
  );
});