import React, { memo } from "react";
import { useFetchLineSegmentCouponHistoryPerPage } from "../../../../hooks/externalApi/line/useFetchLineSegmentCouponHistoryPerPage";
import { SegmentTableHoc } from "../../../template/SegmentTableHoc";
import { LoadMoreButton } from "../../../atoms/button/LoadMoreBtn";
import { useParams } from "react-router-dom";
import { TableLayout } from "../../../template/table/TableLayout";
import { formatDate } from "../../../../utils/formatDate";
import { TableRowWithNavigationLayout } from "../../../template/table/TableRowWithNavigationLayout";


export const SegmentCouponTable: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const {
    lineSegmentCouponHistory,
    isValidating,
    isLoading,
    setSize,
    isReachingEnd
  } = useFetchLineSegmentCouponHistoryPerPage(restaurantId);
  const headers = ['配信日時', '配信アカウント数', '対象来店回数', 'タイトル'];
  const rows = lineSegmentCouponHistory?.map((lineSegmentCoupon) => (
    <TableRowWithNavigationLayout
      key={lineSegmentCoupon.id}
      path={`coupons/${lineSegmentCoupon.id}`}
      childrenList={[
        formatDate(lineSegmentCoupon.send_at),
        lineSegmentCoupon.has_coupon_users.length+lineSegmentCoupon.used_coupon_users.length,
        lineSegmentCoupon.visit_count,
        lineSegmentCoupon.title
      ]}
    />
  ));
  return (
    <SegmentTableHoc isLoading={isLoading} data={lineSegmentCouponHistory}>
      <TableLayout headers={headers} rows={rows || []} />
      <LoadMoreButton isValidating={isValidating} setSize={setSize} isReachingEnd={isReachingEnd} />
    </SegmentTableHoc>
  );
});