import { customNoAuthAxios } from '../../utils/customNoAuthAxios';
import { useErrorToast } from '../toast/useErrorToast';
import { useSuccessToast } from '../toast/useSuccessToast';


export const useAuthKuitanAgent = (onClose:()=>void) => {
  const showSuccessToast = useSuccessToast();
  const showErrorToast = useErrorToast();

  const onSubmit = async (data:any) => {
    try {
      const res = await customNoAuthAxios.post('kuitan/signup/auth/', data);
      showSuccessToast(res.data.message);
      onClose();
    } catch (error:any) {
      if (error.response?.status === 400) {
        showErrorToast(error.response.data.message);
      } else {
        showErrorToast("予期せぬエラーが発生しました。");
      }
    }
  };
  return onSubmit;
};