import React from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { Divider, CardHeader, CardBody, List, ListItem, Flex, Text, CardFooter } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { useFetchCorporateRestaurantsPerPage } from "../../../hooks/restaurants/useFetchCorporateRestaurantsPerPage";


export const SelectRestaurantForRatings = () => {
  const { corporationId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const pageSize = 10;
  const { restaurantsPerPage, isLoading } = useFetchCorporateRestaurantsPerPage(pageSize, page, corporationId)
  if (isLoading || !restaurantsPerPage) return <CircularLoading />
  return (
    <DarkShadowCard fontWeight='bold'>
      <CardHeader>
        <Text fontSize='lg' m='0'>店舗選択</Text>
      </CardHeader>
      <CardBody py={0}>
        {restaurantsPerPage.restaurants.length === 0 ? <Text m={0}>店舗が存在しません。</Text> : (
          <>
            <Divider m='0' borderColor='gray.300' />
            <List m='0'>
              {restaurantsPerPage.restaurants.map((restaurant) => (
                <React.Fragment key={restaurant.id}>
                  <ListItem cursor='pointer' _hover={{ bg: 'gray.100' }} px={2} py={3} onClick={() => navigate(`${restaurant.id}/ratings`)}>
                    <Flex align='center' justifyContent='space-between' w='100%'>
                      <Text fontSize='14px' m='0'>{restaurant.name}</Text>
                      <ChevronRightIcon color='gray.400' />
                    </Flex>
                  </ListItem>
                  <Divider m='0' borderColor='gray.300' />
                </React.Fragment>
              ))}
            </List>
          </>
        )}
      </CardBody>
      <CardFooter>
        <Pagination totalItems={restaurantsPerPage.count} pageSize={pageSize} />
      </CardFooter>
    </DarkShadowCard>
  );
};