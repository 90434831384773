import useSWR from "swr";

export type KuchikomyuSurveyAnswerTexts = {
  id: string;
  answer_text: string;
  answered_at: string;
}[]

type KuchikomyuSurveyAnswerTextsPerPage = {
  count: number;
  kuchikomyu_survey_answer_texts: KuchikomyuSurveyAnswerTexts;
};

export const useFetchKuchikomyuSurveyAnswerTextsPerPage = (restaurantId: string, pageSize: number, currentPage: number) => {
  const { data: kuchikomyuSurveyAnswerTextsPerPage, error, isLoading } = useSWR<KuchikomyuSurveyAnswerTextsPerPage>(`kuchikomyu/survey/texts/?restaurantId=${restaurantId}&pageSize=${pageSize}&currentPage=${currentPage}`)
  return { kuchikomyuSurveyAnswerTextsPerPage, isLoading, error };
};