import { useFetchSegmentCouponUsedUsersCount } from './membership/useFetchSegmentCouponUsedUsersCount';
import { useFetchSoCouponUsedCouponUsersCount } from './socoupon/useFetchSoCouponUsedCouponUsersCount';


export const useCouponUsedUsersCount = (restaurantId: string, startDate: string|null, endDate: string|null) => {

  const { segmentCouponUsedUsersCount, isLoadingSegmentCouponUsedUsersCount } = useFetchSegmentCouponUsedUsersCount(restaurantId, startDate, endDate);
  const { soCouponUsedCouponUsersCount, isLoadingSoCouponUsedCouponUsersCount } = useFetchSoCouponUsedCouponUsersCount(restaurantId, startDate, endDate);

  const couponUsedUsersCount = (typeof segmentCouponUsedUsersCount === 'number' && typeof soCouponUsedCouponUsersCount === 'number') ? segmentCouponUsedUsersCount + soCouponUsedCouponUsersCount : '-'

  return { couponUsedUsersCount, isLoadingSegmentCouponUsedUsersCount, isLoadingSoCouponUsedCouponUsersCount };
};