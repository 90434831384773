import useSWR from "swr";

type Benefits = {
  id: string;
  rank_names: string[];
  title: string;
  explanation: string;
  notes: string;
  img: string;
}[]
export const useFetchBenefitsByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `membership/restaurants/${restaurantId}/benefits/` : null;
  const { data: benefits, error, isLoading } = useSWR<Benefits>(fetcherKey)
  return { benefits, isLoading, error };
};

