import { memo } from "react"
import { useWatch } from "react-hook-form";
import { Flex, FormControl, FormLabel, Select, Skeleton } from "@chakra-ui/react";


type SurveyQuestions = {
  id: string;
  restaurant: string;
  question: string;
  answer_count: number;
  is_selected: boolean;
}[]
type SurveyQuestionSelectProps = {
  register: any;
  index: number;
  control: any
  isLoading: boolean
  surveyQuestions?: SurveyQuestions
}
export const SurveyQuestionSelect: React.FC<SurveyQuestionSelectProps> = memo(({register, index, control, isLoading, surveyQuestions}) => {
  const selectedQuestion = useWatch({ name: `questions.${index}`, control }) || [];
  const selectedQuestions = useWatch({ name: `questions`, control }) || [];
  const unSelectedQuestions = surveyQuestions?.filter((question) => !selectedQuestions?.includes(question.id) || question.id === selectedQuestion)
  return (
    <FormControl isRequired>
      <Flex align={{base: 'start', sm: 'center'}} direction={{base: 'column', sm: 'row'}} gap={2}>
        <FormLabel m='0'>Q{index+1}</FormLabel>
        <Skeleton isLoaded={!isLoading} w='100%' rounded='md'>
          <Select {...register(`questions.${index}`)} placeholder='選択'  >
            {unSelectedQuestions?.map((unSelectedQuestion) => <option key={unSelectedQuestion.id} value={unSelectedQuestion.id}>{unSelectedQuestion.question}</option>)}
          </Select>
        </Skeleton>
      </Flex>
    </FormControl>
  );
});