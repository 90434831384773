import useSWR from "swr";


type KuitanSetting = {
  id: string;
  tabelog_url: string;
}
export const useFetchKuitanSettingByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `/kuitan/restaurants/${restaurantId}/setting/` : null;
  const { data: setting, error, isLoading } = useSWR<KuitanSetting>(fetcherKey)
  return { setting, isLoading, error };
};