import { CircularLoading } from '../molecules/loading/CircularLoading';
import { LiffLoginProcess } from '../organisms/LiffLoginProcess';
import { useExtractRestaurantIdFromUrl } from '../../hooks/externalApi/line/useExtractRestaurantIdFromUrl';
import { ThanksForRegistration } from './ThanksForRegistration';


// 古いアカウントの友達をから新しいアカウントに移行するため
// Line公式アカウントのメッセージ配信経由で会員登録するためのコンポーネント
export const MembershipRegistrationLiffApp = () => {
  const restaurantId = useExtractRestaurantIdFromUrl()
  if (restaurantId === null) return <CircularLoading />
  return (
    <LiffLoginProcess restaurantId={restaurantId} liffType="membership-registration">
      <ThanksForRegistration/>
    </LiffLoginProcess>
  );
};