import React from 'react';
import { memo } from "react"
import { useParams } from "react-router-dom";
import { DisplayMenu } from "./DisplayMenu";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchMenuTypeCustomer } from '../../../hooks/menu/useFetchMenuTypeCustomer';
import { NoUseLineMobileOrderQr } from './NoUseLineMobileOrderQr';


export const NoUseLineCustomerMenu: React.FC = memo(() => {
  const params = useParams();
  const { restaurantId, tableId } = params;
  const { menuType, isLoading } = useFetchMenuTypeCustomer(restaurantId)
  if (isLoading) return <CircularLoading />
  return (
    <>
      {menuType === '画像表示のみ' && <DisplayMenu restaurantId={restaurantId!} />}
      {menuType === 'Airモバイルオーダー' && <NoUseLineMobileOrderQr tableId={tableId!} />}
    </>
  );
});