import { Route } from 'react-router-dom';
import { SoCouponSettingForRestaurant } from '../components/pages/soCoupon/SoCouponSettingForRestaurant';
import { EditSoCouponSettingByRestaurant } from '../components/pages/soCoupon/EditSoCouponSettingByRestaurant';
import { SoCouponQrCode } from '../components/pages/soCoupon/SoCouponQrCode';
import { SoCouponSurveyQuestions } from '../components/pages/soCoupon/SoCouponSurveyQuestions';
import { SoCouponSurveySelectedQuestions } from '../components/pages/soCoupon/SoCouponSurveySelectedQuestions';
import { SoCouponSurveySelectedQuestionsEdit } from '../components/pages/soCoupon/SoCouponSurveySelectedQuestionsEdit';
import { SoCouponSurveyQuestionRegister } from '../components/pages/soCoupon/SoCouponSurveyQuestionRegister';
import { SoCouponScores } from '../components/pages/soCoupon/SoCouponScores';
import { SoCouponSurveyQuestionAnsweredCustomers } from '../components/pages/soCoupon/SoCouponSurveyQuestionAnsweredCustomers';
import { SoCouponOverallScoreCustomers } from '../components/pages/soCoupon/SoCouponOverallScoreCustomers';
import { SoCouponSurveyAnswerTexts } from '../components/pages/soCoupon/SoCouponSurveyAnswerTexts';


export const SoCouponRoutes = (
  <Route path='socoupon'>
    <Route path="setting" element={<SoCouponSettingForRestaurant />} />
    <Route path="setting/edit" element={<EditSoCouponSettingByRestaurant />} />
    <Route path="qr-code" element={<SoCouponQrCode />} />
    <Route path="survey/questions" element={<SoCouponSurveyQuestions />} />
    <Route path="survey/questions/selected" element={<SoCouponSurveySelectedQuestions />} />
    <Route path="survey/questions/selected/edit" element={<SoCouponSurveySelectedQuestionsEdit />} />
    <Route path="survey/questions/register" element={<SoCouponSurveyQuestionRegister />} />
    <Route path="scores" element={<SoCouponScores />} />
    <Route path="scores/:questionId" element={<SoCouponSurveyQuestionAnsweredCustomers />} />
    <Route path="scores/overall-score" element={<SoCouponOverallScoreCustomers />} />
    <Route path="answer-texts" element={<SoCouponSurveyAnswerTexts />} />
  </Route>
);