import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Corporation } from "./useFetchCorporation";


export const corporationEditSchema = yup.object().shape({
  user: yup.object().shape({
    username: yup.string().required('ユーザーIDを入力してください'),
    email: yup.string().required('メールアドレスを入力してください').email("メールアドレスを正しい形式で入力してください"),
    phone_number: yup.string().required('電話番号を入力してください')
  }),
  corporation: yup.object().shape({
    salesman: yup.string().required('営業担当者を選択してください'),
    name: yup.string().required('法人名を入力してください'),
    address: yup.string().required('住所を入力してください'),
    president_last_name: yup.string().required('代表者名を入力してください'),
    president_first_name: yup.string().required('代表者名を入力してください'),
    president_phone_number: yup.string().required('電話番号を入力してください')
  }),
});
export const useEditCorporationForm = (corporation?: Corporation) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({
    resolver: yupResolver(corporationEditSchema),
  });
  useEffect(() => {
    if (corporation) {
      const fields = {
        'user.username': corporation.user_data.username,
        'user.email': corporation.user_data.email,
        'user.phone_number': corporation.user_data.phone_number,
        'corporation.salesman': corporation.salesman,
        'corporation.name': corporation.name,
        'corporation.address': corporation.address,
        'corporation.president_last_name': corporation.president_last_name,
        'corporation.president_first_name': corporation.president_first_name,
        'corporation.president_phone_number': corporation.president_phone_number,
      };
      Object.entries(fields).forEach(([key, value]) => {
        setValue(key as keyof typeof fields, value);
      });
    }
  }, [corporation]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};