import { useEffect } from "react";

type SelectedQuestion = {
  id: string;
  restaurant: string;
  question: string;
  question_content: string;
  order: number;
}
export const useInitializeSelectedQuestions = (setValue: (name: string, value: any) => void, selectedQuestions?: SelectedQuestion[]) => {
  useEffect(() => {
    if (selectedQuestions) {
      const initialQuestions = selectedQuestions.map((itemData: SelectedQuestion) => itemData.question);
      setValue('questions', initialQuestions);
    }
  }, [selectedQuestions, setValue]);
};
