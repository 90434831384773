import { usePagination } from "@ajna/pagination";

export const useCustomPagination = (total: number, dataSize:number = 10) => {
  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
  } = usePagination({
    total: total,
    limits: {
      outer: 1,
      inner: 1,
    },
    initialState: {
      pageSize: dataSize,
      isDisabled: false,
      currentPage: 1,
    },
  });

  return { pages, pagesCount, currentPage, setCurrentPage, pageSize };
};