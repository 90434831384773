import React, { memo } from "react";
import Chart from "react-apexcharts";


type DonutChartProps = {
  labels: string[];
  series: number[];
  colors: string[];
};
export const DonutChart: React.FC<DonutChartProps> = memo(({ labels, series, colors }) => {
  const options = {
    chart: {
      id: "basic-donut",
      toolbar: {
        show: false
      }
    },
    labels: labels,
    colors: colors,
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: () => series.reduce((a, b) => a + b, 0).toLocaleString()
            }
          }
        }
      }
    },
    legend: {
      show: true,
      position: 'right' as 'right',
      fontSize: '14px',
      itemMargin: {
        vertical: 8
      },
      onItemClick: {
        toggleDataSeries: false
    },
      formatter: function(seriesName: string, opts: any) {
        const total = opts.w.globals.series.reduce((acc: number, val: number) => acc + val, 0);
        const value = opts.w.globals.series[opts.seriesIndex].toLocaleString();
        const percentage = parseFloat(((opts.w.globals.series[opts.seriesIndex] / total) * 100).toFixed(1));
        return `${value} • ${isNaN(percentage) ? 0 : percentage}%<br>${seriesName}`;
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '10px'
      }
    },
    tooltip: {
      theme: "dark",
      marker: {
        show: true,
      },
      y: {
        formatter: function(val: number) {
          return val.toLocaleString();
        }
      }
    },
    responsive: [{
      breakpoint: 768,
      options: {
        legend: {
          position: 'bottom' as 'bottom',
          fontSize: '10px',
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                total: {
                  fontSize: '10px'
                },
                value: {
                  fontSize: '10px',
                  offsetY: -2
                }
              }
            }
          }
        },
      }
    }]
  };
  return <Chart options={options} series={series} type="donut" />;
});