import useSWR from "swr";


type BusinessPerformances = {
  BUSINESS_IMPRESSIONS_DESKTOP_MAPS: Record<string, number>;
  BUSINESS_BOOKINGS: Record<string, number>;
  WEBSITE_CLICKS: Record<string, number>;
  BUSINESS_CONVERSATIONS: Record<string, number>;
  BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: Record<string, number>;
  BUSINESS_IMPRESSIONS_MOBILE_SEARCH: Record<string, number>;
  BUSINESS_DIRECTION_REQUESTS: Record<string, number>;
  BUSINESS_FOOD_ORDERS: Record<string, number>;
  BUSINESS_FOOD_MENU_CLICKS: Record<string, number>;
  CALL_CLICKS: Record<string, number>;
  BUSINESS_IMPRESSIONS_MOBILE_MAPS: Record<string, number>;
};
export const useFetchBusinessPerformances = (restaurantId?: string, requestQueryParams?: string) => {
  const fetcherKey = restaurantId ? `/external_api/restaurants/${restaurantId}/google/business-performances/${requestQueryParams}` : null;
  const { data: businessPerformances, error, isLoading: isBusinessPerformanceLoading } = useSWR<BusinessPerformances>(fetcherKey);

  // APIからのレスポンスをコンソールに表示
  // console.log("Business Performances Data:", businessPerformances);
  // console.log("Error:", error);
  return { businessPerformances, isBusinessPerformanceLoading, error };
};