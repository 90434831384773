import { memo } from "react"
import { Box, Grid } from "@chakra-ui/react";
import { LineGraphCard } from "../../molecules/card/LineGraphCard";


type AnalyticsLineGraphCardsProps = {
  restaurantMediaName: string
  data: { title: string, dataKey: string }[]
  analyticsDate: string[]
  analyticsContents: any[]
  graphColorRgb: string
}
export const AnalyticsLineGraphCards: React.FC<AnalyticsLineGraphCardsProps> = memo(({restaurantMediaName, data, analyticsDate, analyticsContents, graphColorRgb}) => {
  return (
    <Box key={restaurantMediaName} w='100%'>
      <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}} gap={2} w='100%'>
        {data.map(({title, dataKey}) => (
            <LineGraphCard
              key={dataKey}
              title={title}
              labels={analyticsDate}
              values={analyticsContents.map((data: any) => data[dataKey])}
              graphColorRgb={graphColorRgb}
            />
          )
        )}
      </Grid>
    </Box>
  );
});

