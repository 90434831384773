import { memo } from "react"
import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Select, VStack } from "@chakra-ui/react";
import { SubmitBtn } from "../../../atoms/button/SubmitBtn";
import { NotificationRecipientsSelect } from "./NotificationRecipientsSelect";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


type GoogleSettingsFormProps = {
  register: any;
  control: any;
  errors: any;
  handleSubmit: any
  isSubmitting: boolean;
}
export const GoogleSettingsForm: React.FC<GoogleSettingsFormProps> = memo(({ register, control, errors, handleSubmit, isSubmitting }) => {
  const locationIdError = errors.location_id?.message;
  const placeIdError = errors.place_id?.message;
  const replyModeError = errors.reply_mode?.message;
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <DarkShadowCard as='form' onSubmit={handleSubmit}>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='GBPマネジャー設定'/>
        </CardHeader>
        <CardBody py={1}>
          <VStack spacing={5}>
            <FormControl isRequired isInvalid={!!errors.location_id} w='100%'>
              <FormLabel>ビジネスプロフィールID</FormLabel>
              <Input type="text" {...register('location_id')} />
              <FormHelperText>ビジネスパフォーマンスデータを取得するために必要です。</FormHelperText>
              <FormErrorMessage>{locationIdError}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.location_id} w='100%'>
              <FormLabel>プレイスID</FormLabel>
              <Input type="text" {...register('place_id')} />
              <FormHelperText>口コミを取得するために必要です。</FormHelperText>
              <FormErrorMessage>{placeIdError}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.reply_mode} w='100%'>
              <FormLabel>口コミ返信</FormLabel>
              <Select {...register('reply_mode')} defaultValue='c'>
                <option value='a'>自動</option>
                <option value='b'>半自動</option>
                <option value='c'>手動</option>
              </Select>
              <FormErrorMessage>{replyModeError}</FormErrorMessage>
            </FormControl>
            <NotificationRecipientsSelect control={control} />
          </VStack>
        </CardBody>
        <CardFooter>
          <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});