import { Route } from 'react-router-dom';
import { FoodMenuRegister } from '../components/pages/menu/FoodMenuRegister';
import { Menu } from '../components/pages/menu/Menu';
import { DrinkMenuRegister } from '../components/pages/menu/DrinkMenuRegister';
import { MenuSetting } from '../components/pages/menu/MenuSetting';
import { MenuSettingEdit } from '../components/pages/menu/MenuSettingEdit';
import { EnglishFoodMenuRegister } from '../components/pages/menu/EnglishFoodMenuRegister';
import { EnglishDrinkMenuRegister } from '../components/pages/menu/EnglishDrinkMenuRegister';
import { ChineseFoodMenuRegister } from '../components/pages/menu/ChineseFoodMenuRegister';
import { ChineseDrinkMenuRegister } from '../components/pages/menu/ChineseDrinkMenuRegister';
import { KoreanFoodMenuRegister } from '../components/pages/menu/KoreanFoodMenuRegister';
import { KoreanDrinkMenuRegister } from '../components/pages/menu/KoreanDrinkMenuRegister';


export const MenuRoutes = (
  <Route path='menu'>
    <Route path="" element={<Menu />} />
    <Route path="food/register" element={<FoodMenuRegister />} />
    <Route path="drink/register" element={<DrinkMenuRegister />} />
    <Route path="food/english/register" element={<EnglishFoodMenuRegister />} />
    <Route path="drink/english/register" element={<EnglishDrinkMenuRegister />} />
    <Route path="food/chinese/register" element={<ChineseFoodMenuRegister />} />
    <Route path="drink/chinese/register" element={<ChineseDrinkMenuRegister />} />
    <Route path="food/korean/register" element={<KoreanFoodMenuRegister />} />
    <Route path="drink/korean/register" element={<KoreanDrinkMenuRegister />} />
    <Route path="setting" element={<MenuSetting />} />
    <Route path="setting/edit" element={<MenuSettingEdit />} />
  </Route>
);