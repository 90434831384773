import useSWR from "swr";

type SoCoupon = {
  id: string;
  title: string;
  explanation: string;
  notes: string;
  expiration_date: string;
  img: string;
}
export const useFetchSoCoupon = (soCouponId?: string) => {
  const fetcherKey = soCouponId ? `socoupon/socoupons/${soCouponId}` : null;
  const { data: soCoupon, error, isLoading } = useSWR<SoCoupon>(fetcherKey)
  return { soCoupon, isLoading, error }
}
