import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";
import { switchImgAppend } from "../../utils/switchImgAppend";

interface BenefitData {
  title: string;
  explanation: string;
  notes: string;
  ranks: string[];
  img?: FileList;
}

export const useEditBenefit = (benefitId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();

  const onSubmit = async (data: any) => {
    try {
      const uploadData = createUploadData(data);
      await customAuthAxios.put(`membership/benefits/${benefitId}/`, uploadData);
      showToast('編集が完了しました');
      navigate(-1);
    } catch (error) {
    }
  };
  return onSubmit;
};

const createUploadData = (data: BenefitData) => {
  const formData = new FormData();
  formData.append("title", data.title);
  formData.append("explanation", data.explanation);
  formData.append("notes", data.notes);
  formData.append("ranks", JSON.stringify(data.ranks));
  switchImgAppend(data.img, formData);
  return formData;
};

