import React, { memo } from 'react';
import { Card, CardBody, CardHeader, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { CardHeading } from '../../molecules/card/CardHeading';
import { MdMenuBook } from "react-icons/md";
import { useParams } from 'react-router-dom';
import { JapaneseMenuImages } from '../../organisms/menu/JapaneseMenuImages';
import { EnglishMenuImages } from '../../organisms/menu/EnglishMenuImages';
import { ChineseMenuImages } from '../../organisms/menu/ChineseMenuImages';
import { KoreanMenuImages } from '../../organisms/menu/KoreanMenuImages';
import { useLanguageTabs } from '../../../hooks/menu/useLanguageTabs';


export const Menu: React.FC = memo(() => {
  const { tabIdx, handleTabsChange } = useLanguageTabs();
  const { restaurantId } = useParams();
  if (!restaurantId) return null;
  const menuImageComponents = {
    日本語: JapaneseMenuImages,
    英語: EnglishMenuImages,
    中国語: ChineseMenuImages,
    韓国語: KoreanMenuImages,
  };
  return (
    <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.08) 1px 3.5px 5.5px'>
      <CardHeader>
        <CardHeading icon={<MdMenuBook fontSize='sm' />} heading='メニュー'/>
      </CardHeader>
      <CardBody pt={0}>
        <Tabs isLazy variant='enclosed' index={tabIdx} onChange={handleTabsChange}>
          <TabList>
            {Object.keys(menuImageComponents).map(language => <Tab key={language}>{language}</Tab>)}
          </TabList>
          <TabPanels>
            {Object.entries(menuImageComponents).map(([key, Component]) => (
              <TabPanel px={0} key={key}>
                <Component restaurantId={restaurantId} />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
});