import React, { memo } from "react";
import { Badge, Box, CardBody, CardFooter, CardHeader } from '@chakra-ui/react'
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchManlaboSurveyQuestionsByRestaurantId } from "../../../hooks/manzokudoLabo/useFetchManlaboSurveyQuestionsByRestaurantId";
import { TableLayout } from "../../template/table/TableLayout";
import { TableRowLayout } from "../../template/table/TableRowLayout";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { ManLaboSurveyQuestionDeleteModal } from "../../organisms/manzokudoLabo/ManLaboSurveyQuestionDeleteModal";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { RiSurveyLine } from "react-icons/ri";


export const ManLaboSurveyQuestions: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { surveyQuestions, isLoading } = useFetchManlaboSurveyQuestionsByRestaurantId(restaurantId)
  if (isLoading || !surveyQuestions) return <CircularLoading />
  const headers = ['質問', '回答数', ''];
  const rows = surveyQuestions.map((surveyQuestion) => (
    <TableRowLayout
      key={surveyQuestion.id}
      childrenList={[
        surveyQuestion.question,
        surveyQuestion.answer_count,
        surveyQuestion.is_selected ? (
          <Badge colorScheme='green'>使用中</Badge>
        ) : (
          <ManLaboSurveyQuestionDeleteModal id={surveyQuestion.id} mutateKey={`manzokudo_labo/restaurants/${restaurantId}/survey/questions/`} restaurantName={surveyQuestion.question} />
        )
      ]}
    />
  ));
  return (
    <Box m='auto' w={{ base: '100%', md: '80%' }}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<RiSurveyLine />} heading='アンケート一覧'/>
        </CardHeader>
        <CardBody py={2}>
          <TableLayout headers={headers} rows={rows || []} size='sm' />
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path={'register'} text="新規登録" />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});