import useSWR from "swr";


type Restaurants = {
  id: string;
  name: string;
}[]
type PaginatedRestaurants = {
count: number;
restaurants: Restaurants;
};
export const useFetchPaginatedTeamsAssignedRestaurants = (queryParams: URLSearchParams, salesmanId?: string) => {
  const queryString = queryParams.toString();
  const fetchKey = salesmanId ? `kuitan/salesmen/${salesmanId}/teams/restaurants/assigned/paginated/?${queryString}` : null;
  const { data: paginatedRestaurants, error, isLoading } = useSWR<PaginatedRestaurants>(fetchKey)
  return { paginatedRestaurants, isLoading, error };
};