import { Box, CardBody, CardHeader, Divider, Grid, HStack, Skeleton, Text, VStack } from "@chakra-ui/react";
import { AlertMsg } from "../../../molecules/AlertMsg";
import { useFetchBusinessPerformances } from "../../../../hooks/externalApi/google/useFetchBusinessPerformances";
import { useParams } from "react-router-dom";
import { BusinessTitleByRestaurantId } from "../../../organisms/externalApi/google/BusinessTitle";
import { useQueryParamsOfDateRange } from "../../../../hooks/useQueryParamsOfDateRange";
import { DateRangeFilter } from "../../../molecules/DateRangeFilter";
import { useSaveGoogleReviews } from "../../../../hooks/externalApi/google/useSaveGoogleReviews";
import { useFetchGoogleRatings } from "../../../../hooks/externalApi/google/useFetchGoogleRatings";
import { LineGraphCard } from "../../../molecules/card/LineGraphCard";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { useFetchCurrentGoogleRating } from "../../../../hooks/externalApi/google/useFetchCurrentGoogleRating";
import Rating from '@mui/material/Rating';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useFetchNeededRatingCountForNextRating } from "../../../../hooks/externalApi/google/useFetchNeededRatingCountForNextRating";
import { DonutChart } from "../../../molecules/graph/DonutChart";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import { AxiosError } from "axios";

// エラーモーダルのプロパティ定義
interface ErrorModalProps {
    isOpen: boolean;
    onClose: () => void;
    errorMessage: string;
    title?: string;  // title プロパティを追加し、オプショナルに設定
}

// エラーモーダルコンポーネント
const ErrorModal = ({ isOpen, onClose, errorMessage, title = "Error" }: ErrorModalProps) => {
    //console.log("Modal isOpen prop:", isOpen);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{errorMessage}</ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

// メインコンポーネント

export const BusinessPerformances = () => {
    const { restaurantId } = useParams();
    const { since, until, requestQueryParams } = useQueryParamsOfDateRange();

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorCount, setErrorCount] = useState(0);

    // handleError を useCallback でラップ
    const handleError = useCallback((error: unknown) => {
        if (errorCount > 0) {
            // エラーカウントが1以上の場合、処理をスキップ
            return;
        }

        if (error instanceof AxiosError && error?.response?.data?.detail) {
            setErrorMsg(error.response.data.detail);
        } else if (error instanceof AxiosError) {
            setErrorMsg("Googleからのレスポンスにエラーが発生しました。");
        } else if (error) {
            setErrorMsg("アカウント連携エラー：Googleアカウントを確認し、BLOCKsに正常に権限が与えられているか確認してください。");
        }

        setIsErrorModalOpen(true);  // モーダルを表示
        setErrorCount(prev => prev + 1);  // エラーカウントを増やす
    }, [errorCount]);  // errorCount を依存リストに追加

    useEffect(() => {
        if (!isErrorModalOpen) {
            // モーダルが閉じられた後にエラーメッセージをリセットする
            setErrorMsg('');
        }
    }, [isErrorModalOpen]);

    return (
        <>
            <VStack align='start' spacing={5} w='100%'>
                <BusinessTitleByRestaurantId restaurantId={restaurantId} />
                <DateRangeFilter since={since ?? ''} until={until ?? ''} />
                <Ratings restaurantId={restaurantId} requestQueryParams={requestQueryParams} handleError={handleError} />
                <UserViewCount restaurantId={restaurantId} requestQueryParams={requestQueryParams} handleError={handleError} />
                <BusinessPerformanceCards restaurantId={restaurantId} requestQueryParams={requestQueryParams} handleError={handleError} />
            </VStack>
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => {
                    setIsErrorModalOpen(false);  // モーダルを閉じる
                }}
                errorMessage={errorMsg}
                title="データ取得エラー"
            />
        </>
    );
};

// Ratingsコンポーネント
const Ratings = ({ restaurantId, requestQueryParams, handleError }: {
    restaurantId?: string,
    requestQueryParams: string,
    handleError: (error: any) => void
}) => {
    const { error } = useSaveGoogleReviews(restaurantId);
    if (error) {
        handleError(error);  // エラーが発生した場合にのみ handleError を呼び出す
        return null;
    }
    return <RatingContents restaurantId={restaurantId} requestQueryParams={requestQueryParams} />;
};

// RatingContentsコンポーネント
const RatingContents = ({ restaurantId, requestQueryParams }: { restaurantId?: string, requestQueryParams: string }) => {
    const { ratingData, isLoading } = useFetchGoogleRatings(restaurantId, requestQueryParams)
    const dates = ratingData?.map(item => item.date);
    const ratings = ratingData?.map(item => item.rating);

    return (
        <Box w='100%'>
            <DarkShadowCard rounded='md'>
                <CardHeader p={3}>評価</CardHeader>
                <Divider m='0' borderColor='gray.200' />
                <CardBody p={3}>
                    {ratingData?.length === 0 ? <>データが存在しません</> :
                        <VStack align='start' spacing={3}>
                            <VStack align='start' spacing={1}>
                                <CurrentRating restaurantId={restaurantId} />
                                <NeededRatingCount restaurantId={restaurantId} />
                            </VStack>
                            <Skeleton isLoaded={!isLoading} rounded='md' w='100%'>
                                <LineGraphCard
                                    title='平均評価推移'
                                    labels={dates ?? []}
                                    values={ratings ?? []}
                                    height='200'
                                />
                            </Skeleton>
                        </VStack>
                    }
                </CardBody>
            </DarkShadowCard>
        </Box>
    );
};

// CurrentRatingコンポーネント
const CurrentRating = ({restaurantId}: { restaurantId?: string }) => {
    const theme = createTheme();
    const {currentRating, isLoading} = useFetchCurrentGoogleRating(restaurantId)
    return (
        <Skeleton isLoaded={!isLoading}>
            <HStack>
                <Text m={0} fontSize='lg'>{currentRating}</Text>
                <ThemeProvider theme={theme}>
                    {/* currentRating が数値であることを保証する */}
                    <Box as={Rating} fontSize='24px' name="half-rating-read" value={Number(currentRating)} precision={0.1} readOnly/>
                </ThemeProvider>
            </HStack>
        </Skeleton>
    )
}

// NeededRatingCountコンポーネント
const NeededRatingCount = ({ restaurantId }: { restaurantId?: string }) => {
    const { neededRatingCountForNextRating, isLoading } = useFetchNeededRatingCountForNextRating(restaurantId)
    return (
        <Skeleton isLoaded={!isLoading}>
            <Text m={0} fontSize='sm' color='gray.500'>
                ★{neededRatingCountForNextRating?.next_higher_rating}をあと{neededRatingCountForNextRating?.needed_rating_count}個で評価アップ！
            </Text>
        </Skeleton>
    );
};

// UserViewCountコンポーネント
const UserViewCount = ({ restaurantId, requestQueryParams, handleError }: {
    restaurantId?: string,
    requestQueryParams: string,
    handleError: (error: any) => void
}) => {
    const { businessPerformances, isBusinessPerformanceLoading, error } = useFetchBusinessPerformances(restaurantId, requestQueryParams);
    if (error) {
        handleError(error);
        return null;
    }

    const labels = ['Google マップ - モバイル', 'Google 検索 - モバイル', 'Google マップ - PC', 'Google 検索 - PC'];
    const calculateTotal = (data: Record<string, number> | undefined) => Object.values(data ?? {}).reduce((acc, value) => acc + value, 0);

    const mapMobileTotal = calculateTotal(businessPerformances?.BUSINESS_IMPRESSIONS_MOBILE_MAPS);
    const searchMobileTotal = calculateTotal(businessPerformances?.BUSINESS_IMPRESSIONS_MOBILE_SEARCH);
    const searchPcTotal = calculateTotal(businessPerformances?.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH);
    const mapPcTotal = calculateTotal(businessPerformances?.BUSINESS_IMPRESSIONS_DESKTOP_MAPS);
    const keys = Object.keys(businessPerformances?.BUSINESS_IMPRESSIONS_MOBILE_MAPS ?? {});

    return (
        <Skeleton isLoaded={!isBusinessPerformanceLoading} borderRadius={5} w='100%'>
            <DarkShadowCard rounded='md'>
                <CardHeader p={3}>
                    ビジネスプロフィールを閲覧したユーザー数
                </CardHeader>
                <Divider m='0' borderColor='gray.200' />
                <CardBody p={3}>
                    <Box w={{ base: '100%', md: '500px' }}>
                        <Text m={0} color='gray.500' fontSize='sm'>{keys[0]}~{keys[keys.length - 1]}</Text>
                        <DonutChart
                            labels={labels}
                            series={[mapMobileTotal, searchMobileTotal, searchPcTotal, mapPcTotal]}
                            colors={['#D92F25', '#F19801', '#1B73E9', '#1E8E3C']}
                        />
                    </Box>
                </CardBody>
            </DarkShadowCard>
        </Skeleton>
    );
};

// BusinessPerformanceCardsコンポーネント
const performanceDataGroup1 = [
    { title: "Googleマップインプレッション - PC", dataKey: "BUSINESS_IMPRESSIONS_DESKTOP_MAPS" },
    { title: "Google検索インプレッション - PC", dataKey: "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH" },
    { title: "Googleマップインプレッション - モバイル", dataKey: "BUSINESS_IMPRESSIONS_MOBILE_MAPS" },
    { title: "Google検索インプレッション - モバイル", dataKey: "BUSINESS_IMPRESSIONS_MOBILE_SEARCH" },
];

const performanceDataGroup2 = [
    { title: "ルートリクエスト数", dataKey: "BUSINESS_DIRECTION_REQUESTS" },
    { title: "通話ボタンクリック数", dataKey: "CALL_CLICKS" },
    { title: "ウェブサイトクリック数", dataKey: "WEBSITE_CLICKS" },
    { title: "予約数", dataKey: "BUSINESS_BOOKINGS" },
    { title: "メニューコンテンツクリック数", dataKey: "BUSINESS_FOOD_MENU_CLICKS" },
    { title: "料理の注文数", dataKey: "BUSINESS_FOOD_ORDERS" },
    { title: "受信したメッセージスレッド数", dataKey: "BUSINESS_CONVERSATIONS" },
];

const group1Keys = [
    "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
    "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
    "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
    "BUSINESS_IMPRESSIONS_MOBILE_SEARCH"
];

const group2Keys = [
    "BUSINESS_DIRECTION_REQUESTS",
    "CALL_CLICKS",
    "WEBSITE_CLICKS",
    "BUSINESS_BOOKINGS",
    "BUSINESS_FOOD_MENU_CLICKS",
    "BUSINESS_FOOD_ORDERS",
    "BUSINESS_CONVERSATIONS"
];

// データが空かどうかを確認する関数
const isGroupEmpty = (businessPerformances: any, keys: string[]) => {
    return keys.every(key => {
        const data = businessPerformances[key as keyof typeof businessPerformances];
        return !data || Object.keys(data).length === 0;
    });
};

const BusinessPerformanceCards = ({
                                      restaurantId,
                                      requestQueryParams,
                                      handleError
                                  }: {
    restaurantId?: string;
    requestQueryParams: string;
    handleError: (error: any) => void;
}) => {
    const { businessPerformances, isBusinessPerformanceLoading, error } = useFetchBusinessPerformances(
        restaurantId,
        requestQueryParams
    );

    useEffect(() => {
        if (error) {
            handleError(error);
        }
    }, [error]);  // エラーが発生した場合にのみ処理

    // エラーハンドリング
    if (error && error.response?.status === 404) {
        return <AlertMsg msg={error.response.data.message} description={error.response.data.description} />;
    }

    // データが空、または存在しないものがある場合
    if (!businessPerformances) {
        // データがない場合の処理
        return null;
    }

    // グループ1が空の場合
    if (isGroupEmpty(businessPerformances, group1Keys)) {
        handleError({
            response: {
                data: { detail: "CODE1: Googleビジネスからの情報が取得できません。アカウントが連携されていて、取得権限が適切かご確認ください。" }
            }
        });
        return null;
    }

    // グループ2が空の場合
    if (isGroupEmpty(businessPerformances, group2Keys)) {
        handleError({
            response: {
                data: { detail: "CODE2: Googleビジネスからの情報が取得できません。アカウントが連携されていて、取得権限が適切かご確認ください。" }
            }
        });
        return null;
    }

    return (
        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={3} w="100%">
            {performanceDataGroup1.map(({ title, dataKey }) => (
                <Skeleton key={title} isLoaded={!isBusinessPerformanceLoading} borderRadius={5}>
                    <LineGraphCard
                        title={title}
                        labels={Object.keys(businessPerformances[dataKey as keyof typeof businessPerformances] || {})}
                        values={Object.values(businessPerformances[dataKey as keyof typeof businessPerformances] || {})}
                    />
                </Skeleton>
            ))}
            {performanceDataGroup2.map(({ title, dataKey }) => (
                <Skeleton key={title} isLoaded={!isBusinessPerformanceLoading} borderRadius={5}>
                    <LineGraphCard
                        title={title}
                        labels={Object.keys(businessPerformances[dataKey as keyof typeof businessPerformances] || {})}
                        values={Object.values(businessPerformances[dataKey as keyof typeof businessPerformances] || {})}
                    />
                </Skeleton>
            ))}
        </Grid>
    );
};