import { memo } from 'react';
import { Box, CardBody, CardHeader, Flex, Grid, GridItem, HStack, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DarkShadowCard } from '../../molecules/card/DarkShadowCard';
import { Skeletons } from '../../atoms/Skeletons';
import { StarRateSurveyScoreLayout } from '../../template/StarRateSurveyScoreLayout';
import { Pagination } from '../../molecules/pagenation/Pagination';
import { useFetchStaffSurveySelectedQuestions } from '../../../hooks/restaurantStaff/useFetchStaffSurveySelectedQuestions';
import { useFetchPagedStaffSurveyStaffRankingScores } from '../../../hooks/restaurantStaff/useFetchPagedStaffSurveyStaffRankingScores';


export const StaffSurveyStaffRankingTab = () => {
  return (
    <DarkShadowCard w='100%'>
      <CardHeader>
        <Text m={0} fontSize='lg' fontWeight='bold'>スタッフランキング</Text>
      </CardHeader>
      <CardBody py={0}>
        <QuestionTabs />
      </CardBody>
    </DarkShadowCard>
  );
};


const QuestionTabs = () => {
  const { restaurantId } = useParams()
  const { selectedQuestions, isLoading } = useFetchStaffSurveySelectedQuestions(restaurantId)
  if (isLoading) return null
  return (
    <Tabs variant='soft-rounded' colorScheme='green' isLazy w='100%' size='sm'>
      <TabList overflowX='scroll'>
        {selectedQuestions?.map((question) => (
          <Tab key={question.id} flexShrink={0}>{question.question_content}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {selectedQuestions?.map((question) => (
          <TabPanel key={question.id} px={0}>
            <RankingCards restaurantId={restaurantId} questionId={question.question} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};


const RankingCards = memo(({restaurantId, questionId}:{restaurantId?: string, questionId?: string}) => {
  const [searchParams] = useSearchParams();
  const since = searchParams.get('since');
  const until = searchParams.get('until');
  const currentPage = Number(searchParams.get('p'));
  const queryParams = buildQueryParams(since, until, currentPage);
  const { pagedScores, isLoading } = useFetchPagedStaffSurveyStaffRankingScores(restaurantId, questionId, queryParams);
  const pageSize = pagedScores?.page_size ?? 10;
  const startIndex = pageSize * ((currentPage || 1) - 1);
  if (isLoading) return <Grid templateColumns='repeat(2, 1fr)' gap={3} w='100%'><Skeletons length={4} h='280px' rounded='2xl' /></Grid>
  if (!pagedScores?.staff_scores || pagedScores.staff_scores.length===0) return null
  return (
    <VStack spacing={6}>
      <Grid templateColumns='repeat(2, 1fr)' gap={3} w='100%' >
        {pagedScores.staff_scores.map((surveyScore, i) => {
          const rank = i + 1 + startIndex;
          return (
            <GridItem key={surveyScore.id} w='100%'>
              <DarkShadowCard >
                <CardHeader pb={2}>
                  <HStack justify='space-between' fontWeight='bold'>
                    <HStack>
                      <Image src={surveyScore.img} alt='staff' fallbackSrc='/images/fallback-user.png' boxSize='34px' rounded='md' />
                      <Text noOfLines={1} m={0}>{surveyScore.name}</Text>
                    </HStack>
                    <HStack>
                      <Box bgGradient='linear(to-l, #FE964E, #FF0080)' bgClip='text' fontSize='lg' fontWeight='bold'>
                        {surveyScore.total_acquisitions}P
                      </Box>
                      <Flex color='white' bgColor='#FFA500' p={1} rounded='full' boxSize='26px' align='center' justify='center'>{rank}</Flex>
                    </HStack>
                  </HStack>
                </CardHeader>
                <CardBody pt={0}>
                  <StarRateSurveyScoreLayout {...surveyScore} />
                </CardBody>
              </DarkShadowCard>
            </GridItem>
          );
        })}
      </Grid>
      <Pagination totalItems={pagedScores.count} pageSize={pageSize}/>
    </VStack>
  );
});


const buildQueryParams = (since: string|null, until: string|null, currentPage: number) => {
  const queryParams = new URLSearchParams();
  since && queryParams.set('since', since);
  until && queryParams.set('until', until);
  currentPage && queryParams.set('currentPage', currentPage.toString());
  return queryParams;
};
