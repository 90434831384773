import { useMemo } from "react";


type CouponSegments = {
  id: string;
  coupon: {
    id: string;
    title: string;
    explanation: string;
    notes: string;
    expiration_date: string;
    img: string;
  }
  segment_count: number;
}[]
export const useCoupons = (couponSegments?: CouponSegments) => {
  return useMemo(() => {
    if (!couponSegments) return [];
    return couponSegments.map(segment => segment.coupon);
  }, [couponSegments]);
};