import { memo } from "react"
import { ImageRankCard } from "./ImageRankCard";
import { useFetchRankCardByRestaurantId } from "../../../hooks/membership/useFetchRankCardByRestaurantId";
import { DefaultRankCard } from "./DefaultRankCard";


type RankCardPreviewProps = {
  restaurantId: string;
  restaurantLogo: string;
}
export const RankCardPreview: React.FC<RankCardPreviewProps> = memo(({restaurantId, restaurantLogo}) => {
  const { rankCard, isLoading } = useFetchRankCardByRestaurantId(restaurantId)
  if (!rankCard || isLoading) return null
  if (!rankCard.img) return <DefaultRankCard displayName="山田太郎" rank="GOLD" lastVisitDate="2021/01/01" logo={restaurantLogo} />
  return <ImageRankCard imgUrl={rankCard.img} displayName='山田太郎' rank='GOLD' lastVisitDate='2021/01/01'  />
});