import { memo } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, CardHeader, CardBody } from '@chakra-ui/react'
import { CardHeading } from "../../molecules/card/CardHeading";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { MdOutlineHistory } from "react-icons/md";
import { useNavigate, useSearchParams } from 'react-router-dom';


type DispatchRequestHistoriesTabProps = {
  monthly: React.ReactNode;
  multiMonth: React.ReactNode;
}
export const DispatchRequestHistoriesTab = ({ monthly, multiMonth }: DispatchRequestHistoriesTabProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({ method: "" });
  const currentMethod = searchParams.get("method") || "monthly";
  const handleTabChange = (index: number) => {
    const method = index === 0 ? "monthly" : "multi-month";
    const newSearchParams = new URLSearchParams();
    newSearchParams.set("method", method);
    navigate({ search: newSearchParams.toString() });
  };
  const currentIndex = currentMethod === "multi-month" ? 1 : 0;
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<MdOutlineHistory />} heading='派遣依頼履歴'/>
      </CardHeader>
      <CardBody py={2}>
        <Tabs
          isFitted
          isLazy
          variant='enclosed'
          index={currentIndex}
          onChange={handleTabChange}
        >
          <TabList>
            <Tab fontWeight='bold'>単月</Tab>
            <Tab fontWeight='bold'>長期</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px={0}>
              {monthly}
            </TabPanel>
            <TabPanel px={0}>
              {multiMonth}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </DarkShadowCard>
  );
};