import React, { memo } from "react";
import { Skeleton } from "@chakra-ui/react";
import { useFetchSegmentDeliveriesCount } from "../../../hooks/membership/useFetchSegmentDeliveriesCount";
import { SmallCardWithIcon } from "../../molecules/card/SmallCardWithIcon";
import { MdOutlineMarkEmailRead } from "react-icons/md"


type SegmentMsgDeliveriesCountCardProps = {
  restaurantId: string;
  startDate: string|null;
  endDate: string|null;
}
export const SegmentDeliveriesCountCard: React.FC<SegmentMsgDeliveriesCountCardProps> = memo(({restaurantId, startDate, endDate}) => {
  const { segmentDeliveriesCount,isLoading } = useFetchSegmentDeliveriesCount(restaurantId, startDate, endDate);
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <SmallCardWithIcon data={`${segmentDeliveriesCount ?? '-'}人`} title="セグメント配信数" >
        <MdOutlineMarkEmailRead size='30px' />
      </SmallCardWithIcon>
    </Skeleton>
  );
});