import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {customAuthAxios} from "../../../utils/customAuthAxios";

const InstagramApiReturn: React.FC = () => {
    const [restaurantId, setRestaurantId] = useState<string | null>(null);
    const [apiData, setApiData] = useState<any>(null);

    useEffect(() => {
        // 1. Restaurant IDの取得
        const fetchRestaurantId = async () => {
            try {
                const response = await customAuthAxios.get('/external_api/instagram-auth/<restaurant_id>/');
                setRestaurantId(response.data.restaurantId);
            } catch (error) {
                console.error('Restaurant IDの取得に失敗しました', error);
            }
        };
        fetchRestaurantId();
    }, []);

    useEffect(() => {
        if (restaurantId) {
            // 2. Instagram APIのデータ取得
            const fetchInstagramData = async () => {
                try {
                    const response = await axios.get(
                        `/api/restaurants/${restaurantId}/instagram-data`
                    );
                    setApiData(response.data);
                } catch (error) {
                    console.error('Instagram APIデータの取得に失敗しました', error);
                }
            };
            fetchInstagramData();
        }
    }, [restaurantId]);

    return (
        <div>
            <h1>Instagram APIデータ</h1>
            {apiData ? (
                <pre>{JSON.stringify(apiData, null, 2)}</pre>
            ) : (
                <p>データを読み込んでいます...</p>
            )}
        </div>
    );
};

export default InstagramApiReturn;