import { memo, useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSubmitTableRegister } from "../../../hooks/tableStock/useSubmitTableRegister";
import { Box, Button, CardBody, CardFooter, CardHeader, Flex, FormControl, IconButton, Input, VStack } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { MdOutlineTableRestaurant } from "react-icons/md";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";


export const TableRegister: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { handleSubmit, register, formState: { isSubmitting }, control } = useForm();
  const { fields, append, remove } = useFieldArray({ control, name: "tables" });
  const onSubmit = useSubmitTableRegister(restaurantId)
  useEffect(() => {
    append({});
  }, [append]);
  return (
    <Box w={{base: '100%', sm: '450px', lg: '500px'}} m='auto'>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<MdOutlineTableRestaurant fontSize='sm' />} heading='テーブル登録'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={4}>
              <VStack spacing={4} w='100%'>
                {fields.map((item, index) => (
                  <Flex key={item.id} direction={{base: 'column', sm: 'row'}} justify='space-between' w='100%'>
                    <VStack>
                      <NameInput register={register} index={index} />
                      <ImageInput register={register} index={index} />
                    </VStack>
                    <Box>
                      <IconButton aria-label='delete' icon={<DeleteIcon/>} colorScheme='red' size='sm' variant='ghost' onClick={() => remove(index)}/>
                    </Box>
                  </Flex>
                ))}
              </VStack>
              <Box textAlign="right" w='100%'>
                <IconButton aria-label='add' icon={<AddIcon/>} colorScheme='green' size='sm' variant='outline' onClick={() => append({})}/>
              </Box>
            </VStack>
          </CardBody>
          <CardFooter>
            <Button colorScheme='messenger' size='sm' type='submit' isLoading={isSubmitting}>登録</Button>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});



type InputProps = {
  register: any;
  index: number;
}
const NameInput: React.FC<InputProps> = memo(({register, index}) => {
  return (
    <FormControl>
      <Input required placeholder='テーブル名' {...register(`tables.${index}.name`)} />
    </FormControl>
  )
})
const ImageInput: React.FC<InputProps> = memo(({register, index}) => {
  return (
    <FormControl>
      <input type="file" accept="image/*" {...register(`tables.${index}.image`)} />
    </FormControl>
  )
})