import { useEffect, useState } from "react";
import { initLiff } from "../../../utils/initLiff";

type Profile = {
  userId: string;
  displayName: string;
  pictureUrl?: string;
};
export const useFetchLineProfile = (liffId: string) => {
  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (liffId) {
          const profileData = await initLiff(liffId);
          setProfile(profileData);
        }
      } catch (err) {
      } finally {
      }
    };
    fetchProfile();
  }, [liffId]);

  return profile;
};