import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { BsSliders } from "react-icons/bs";

type GoogleReviewFilterModalProps = {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  navigateToUrlWithQueryParams: () => void;
  onClickResetFilter: () => void;
  children: React.ReactNode;
}
export const GoogleReviewFilterModal: React.FC<GoogleReviewFilterModalProps> = ({ onClose, onOpen, isOpen, navigateToUrlWithQueryParams, onClickResetFilter, children }) => {
  return (
    <>
      <Button rightIcon={<BsSliders size='18px' />} p={0} fontWeight='normal' size='sm' variant='ghost' onClick={onOpen}>フィルタ</Button>
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'xs', md: '2xl' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>検索フィルタ</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {children}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='messenger' size='sm' mr={3} onClick={navigateToUrlWithQueryParams}>
              絞り込む
            </Button>
            <Button size='sm' variant='ghost' onClick={onClickResetFilter}>
              クリア
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}