import React, { memo } from 'react';
import { RegisterMenuForm } from '../../organisms/menu/RegisterMenuForm';
import { useParams } from 'react-router-dom';
import { useRegisterEnglishMenuImages } from '../../../hooks/menu/useRegisterEnglishMenuImages';


export const EnglishFoodMenuRegister: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const onSubmit = useRegisterEnglishMenuImages('food', restaurantId)
  return <RegisterMenuForm menuName='フード' onSubmit={onSubmit} />;
});