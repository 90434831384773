import React from 'react';
import { HStack, Divider, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/formatDate';
import { ProfileIcon } from '../molecules/ProfileIcon';


type LineAccount = {
  id: string;
  customer_sex: string;
  customer_birth_year: string;
  customer_birth_month: string;
  line_id: string;
  display_name: string;
  picture_url?: string;
  visit_count: number;
  registered_date: string;
  last_visit: string;
  is_active: boolean;
  blocked_date: null | string;
  customer: string;
  restaurant: string;
};

type LatestSurveyAnswerTexts = {
  id: string;
  answer_text: string;
  answered_at: string;
  line_account: LineAccount;
}[]

type LatestSurveyAnswerTextsLayoutProps = {
  latestSurveyAnswerTexts: LatestSurveyAnswerTexts;
}

export const LatestSurveyAnswerTextsLayout: React.FC<LatestSurveyAnswerTextsLayoutProps> = ({latestSurveyAnswerTexts}) => {
  const navigation = useNavigate()
  if (latestSurveyAnswerTexts.length === 0) return <>データは存在しません</>
  return (
    <>
      <Divider m={0} />
      {latestSurveyAnswerTexts.map((latestSurveyAnswerText) => (
        <React.Fragment key={latestSurveyAnswerText.id}>
          <HStack fontSize='sm' cursor='pointer' p={2} _hover={{ backgroundColor: '#e9eef085' }} onClick={() => navigation(`../../line/accounts/${latestSurveyAnswerText.line_account.id}`)} >
            <HStack spacing={2} w='20%'>
              <ProfileIcon pictureUrl={latestSurveyAnswerText.line_account.picture_url} size='28px' />
              <Text noOfLines={1} m={0}>{latestSurveyAnswerText.line_account.display_name}</Text>
            </HStack>
            <Text noOfLines={1} m={0} w='50%'>{latestSurveyAnswerText.answer_text}</Text>
            <Text noOfLines={1} m={0} color='gray.500' textAlign='right' w='30%'>{formatDate(latestSurveyAnswerText.answered_at)}</Text>
          </HStack>
          <Divider m={0} />
        </React.Fragment>
      ))}
    </>
  );
};