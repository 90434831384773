import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const surveySchema = yup.object().shape({
  survey: yup.array().of(
    yup.object().shape({
      answer_score: yup.number().required('選択してください'),
    }),
  ),
  answer_text: yup.string(),
});
export const useAnswerStaffSurveyForm = () => {
  const { handleSubmit, register, formState: { isSubmitting, errors }, control } = useForm({
    resolver: yupResolver(surveySchema),
  });
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};