import { useRecoilValue } from "recoil";
import { totalState } from "../../store/totalState";
import { useCustomPagination } from "../useCustomPagenation";
import { useUpdateTotal } from "../useUpdateTotal";
import { useFetchSoCouponSurveyAnswerTextsWithLineAccountsPerPage } from "./useFetchSoCouponSurveyAnswerTextsWithLineAccountsPerPage";

export const useFetchPaginateSoCouponSurveyAnswerTextsWithLineAccounts = (restaurantId: string) => {
  const total = useRecoilValue(totalState);
  const { pages, pagesCount, currentPage, setCurrentPage, pageSize } = useCustomPagination(total, 20);
  const { soCouponSurveyAnswerTextsWithLineAccountsPerPage, isLoading } = useFetchSoCouponSurveyAnswerTextsWithLineAccountsPerPage(restaurantId, pageSize, currentPage)
  useUpdateTotal(soCouponSurveyAnswerTextsWithLineAccountsPerPage);

  return { soCouponSurveyAnswerTextsWithLineAccountsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage };
};