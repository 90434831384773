import React, { memo } from "react";
import {
  Accordion,
  List,
  ListItem,
} from '@chakra-ui/react'
import { SidebarItemLink } from "../../molecules/SidebarItemLink";


export const ManagerSidebarMenu: React.FC = memo(() => {
  return (
    <Accordion allowMultiple>
      <List spacing={1}>
        <ListItem >
          <SidebarItemLink to='' text="基本情報" />
        </ListItem>
        <ListItem >
          <SidebarItemLink to='restaurants' text="店舗" />
        </ListItem>
      </List>
    </Accordion>
  );
});