import { useNavigate } from "react-router-dom";
import { useSuccessToast } from "../../toast/useSuccessToast";
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";

export const useSubmitGoogleReviewReplyEdit = (replyId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();

  const onSubmit = async (data: any) => {
    try {
      await customNoAuthAxios.put(`external_api/google/reviews/replies/${replyId}/`, data)
      showToast('保存しました');
      navigate(-1);
    } catch (error: any) {
    }
  };

  return onSubmit;
};