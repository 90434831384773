import { memo } from "react"
import { useParams } from "react-router-dom";
import { useFetchGoogleSetting } from "../../../../hooks/externalApi/google/useFetchGoogleSetting";
import { useEditGoogleSettingFormForAdmin } from "../../../../hooks/externalApi/google/useEditGoogleSettingFormForAdmin";
import { useEditGoogleSetting } from "../../../../hooks/externalApi/google/useEditGoogleSetting";
import { GoogleSettingsForm } from "../../../organisms/externalApi/google/GoogleSettingsForm";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";


export const EditGoogleSettingByAdmin = () => {
  const { settingId } = useParams();
  const { googleSetting, isLoading } = useFetchGoogleSetting(settingId);
  const { handleSubmit, register, control, isSubmitting, errors } = useEditGoogleSettingFormForAdmin(googleSetting)
  const onSubmit = useEditGoogleSetting(settingId)
  if (isLoading) return <CircularLoading/>
  return (
    <GoogleSettingsForm
      register={register}
      control={control}
      errors={errors}
      handleSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    />
  );
};