import { useParams } from "react-router-dom";
import { useEditPasswordForm } from "../../../hooks/useEditPasswordForm";
import { useEditRestaurantPass } from "../../../hooks/restaurants/useEditRestaurantPass";
import { Box } from "@chakra-ui/react";
import { EditPasswordForm } from "../../molecules/form/EditPasswordForm";


export const EditRestaurantPassword = () => {
  const { restaurantId } = useParams();
  const { handleSubmit, register, isSubmitting, errors } = useEditPasswordForm();
  const onSubmit = useEditRestaurantPass(restaurantId!);
  return (
    <Box m='auto' w={{base: '100%', md: '500px'}}>
      <EditPasswordForm handleSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} register={register} errors={errors} />
    </Box>
  );
};