import { useSWRConfig } from "swr";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";


export const useEditCustomer = (lineAccountId: string, mutateKey: string) => {
  const { mutate } = useSWRConfig()
  const onSubmit = async (data:any) => {
    try {
      const customerData = {
        sex: data.sex,
        birth_year: data.birthYear,
        birth_month: data.birthMonth,
      }
      await customNoAuthAxios.put(
        `accounts/customers/${lineAccountId}/`,
        customerData
      );
      mutate(mutateKey)
    } catch (error:any) {
    }
  };
  return onSubmit;
};