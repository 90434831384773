import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";

const googleReviewReplySchema = yup.object().shape({
  reply: yup.string().required('入力必須項目です'),
});

export const useGoogleReviewReplyEditForm = (reply?: string) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(googleReviewReplySchema),});

  useEffect(() => {
    if (reply) {
      setValue('reply', reply);
    }
  }, [reply]);

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};