import { useSwrWithQueryParams } from "../swr/useSwrWithQueryParams";


type BenchmarkRestaurants = {
  id: string;
  registrant: string;
  name: string;
  url: string;
}[];
type PaginatedBenchmarkRestaurants = {
count: number;
benchmark_restaurants: BenchmarkRestaurants;
};
export const useFetchPaginatedBenchmarkRestaurants = (salesmanId?: string, queryParams?: object) => {
  const fetchKey = salesmanId ? `ifrea_analytics/salesmen/${salesmanId}/benchmark-restaurants/paginated/` : null;
  const { data: paginatedBenchmarkRestaurants, error, isLoading, mutate } = useSwrWithQueryParams<PaginatedBenchmarkRestaurants>(fetchKey, queryParams)
  return { paginatedBenchmarkRestaurants, isLoading, error, mutate };
};