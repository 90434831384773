import { Box, Button, Center, FormControl, FormErrorMessage, FormLabel, Image, Text, Textarea, VStack } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Rating } from "@mui/material";
import { useRateForm } from "../../../hooks/kuitan/useRateForm";
import { RestaurantName } from "../../atoms/RestaurantName";
import { useRate } from "../../../hooks/kuitan/useRate";


type RatingField = {
  name: 'food' | 'drink' | 'customer_service' | 'hygiene' | 'overall';
  label: string;
};
export const Rate = () => {
  const { kuitanAgentId, restaurantId } = useParams();
  const theme = createTheme();
  const { handleSubmit, register, control, isSubmitting, errors } = useRateForm();
  const onSubmit = useRate(restaurantId, kuitanAgentId);
  if (!restaurantId) return null;
  const token = localStorage.getItem('localJWT');
  if (!token) return <Navigate replace to={`/login/kuitan-agent/rate?r=${restaurantId}`}/>;
  const ratingFields: RatingField[] = [
    { name: 'food', label: 'お食事の満足度' },
    { name: 'drink', label: 'お飲み物の満足度' },
    { name: 'customer_service', label: '接客の満足度' },
    { name: 'hygiene', label: '店内衛生の満足度' },
    { name: 'overall', label: '総合評価' },
  ];
  return (
    <Box p={4} bg='white'>
      <VStack align='start' spacing={10}>
        <VStack>
          <Image w='200px' src="/images/kuitan.jpg" />
          <Text m={0} align='center'>
            本日は<span>『<RestaurantName restaurantId={restaurantId} />』</span>へのご来店ありがとうございます。以下の5つの質問への回答にご協力お願いいたします。
          </Text>
        </VStack>
        <Box w='100%'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={5}>
              {ratingFields.map((field) => (
                <FormControl key={field.name} w='100%' isRequired isInvalid={!!errors[field.name]}>
                  <FormLabel>{field.label}</FormLabel>
                  <Controller
                    name={field.name}
                    control={control}
                    render={({ field }) => (
                      <Center>
                        <ThemeProvider theme={theme}>
                          <Box
                            as={Rating}
                            fontSize='3rem'
                            name="score"
                            color='#FFCD00'
                            onChange={(_: any, newValue: any) => {
                              field.onChange(newValue);
                            }}
                          />
                        </ThemeProvider>
                      </Center>
                    )}
                  />
                  <FormErrorMessage justifyContent='center' m={0}>
                    {errors[field.name]?.message}
                  </FormErrorMessage>
                </FormControl>
              ))}
              <FormControl>
                <FormLabel>その他のご意見</FormLabel>
                <Textarea {...register('comment')} rows={8} />
              </FormControl>
              <Button type='submit' isLoading={isSubmitting} colorScheme="messenger" w='100%'>送信</Button>
            </VStack>
          </form>
        </Box>
      </VStack>
    </Box>
  );
};