import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { memo } from "react"


type TitleInputProps = {
  register: any
  errors: any
}
export const TitleInput = memo(({register, errors}: TitleInputProps) => {
  return (
    <FormControl isRequired>
      <FormLabel>タイトル</FormLabel>
      <Input {...register('title')} />
      <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
    </FormControl>
  )
})