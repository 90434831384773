import React, { memo } from "react";
import { Skeleton } from "@chakra-ui/react";
import { MainScoreCard } from "../../molecules/card/MainScoreCard";
import { useFetchActiveLineAccountsCount } from "../../../hooks/externalApi/line/useFetchActiveLineAccountsCount";


type ActiveUsersCountCardProps = {
  restaurantId: string;
}
export const ActiveUsersCountCard: React.FC<ActiveUsersCountCardProps> = memo(({restaurantId}) => {
  const { activeLineAccountsCount, isLoading } = useFetchActiveLineAccountsCount(restaurantId);
  return (
    <Skeleton isLoaded={!isLoading} w='100%' rounded='2xl'>
      <MainScoreCard title='アクティブユーザー数' data={`${activeLineAccountsCount}人`} />
    </Skeleton>
  );
});