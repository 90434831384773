import React, { memo } from "react";
import { useDisclosure, IconButton } from '@chakra-ui/react'
import { DeleteIcon } from "@chakra-ui/icons";
import { DeleteModal } from "../../molecules/DeleteModal";
import { useDelete } from "../../../hooks/useDelete";

type DeleteModalProps = {
  id: string;
  title: string;
}
export const BenefitDeleteModal: React.FC<DeleteModalProps> = memo(({id, title}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDelete(`membership/benefits/${id}/`, onClose)
  return (
    <>
      <IconButton
        colorScheme='red'
        aria-label='Delete SoCoupon'
        icon={<DeleteIcon />}
        size='sm'
        variant='ghost'
        onClick={onOpen}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={title}
        onSubmit={onSubmit}
      />
    </>
  );
});
