import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const registerStaffSettingSchema = yup.object().shape({
  staff_branch_score: yup.number().required('必須項目です'),
  restaurant_branch_score: yup.number().required('必須項目です'),
  g_url: yup.string().required('必須項目です'),
  is_navigate_g_url: yup.boolean(),
});
export const useRegisterStaffSettingForm = () => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(registerStaffSettingSchema),});
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};