import React, { memo } from "react";
import { useDisclosure, IconButton } from '@chakra-ui/react'
import { DeleteIcon } from "@chakra-ui/icons";
import { DeleteModal } from "../../molecules/DeleteModal";
import { useDeleteWithMutateKey } from "../../../hooks/useDeleteWithMutateKey";

type DeleteModalProps = {
  mutateKey: string;
  id: string;
  tableName: string;
}
export const TableDeleteModal: React.FC<DeleteModalProps> = memo(({mutateKey, id, tableName}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDeleteWithMutateKey(`table_stock/tables/${id}/`, onClose, mutateKey)
  return (
    <>
      <IconButton
        colorScheme='red'
        aria-label='Delete SoCoupon'
        icon={<DeleteIcon />}
        size='sm'
        variant='ghost'
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={tableName}
        onSubmit={onSubmit}
      />
    </>
  );
});
