import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { Divider, CardHeader, CardBody, List, ListItem, Flex, Text } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useFetchAssignedCorporations } from "../../../hooks/corporations/useFetchAssignedCorporations";


export const SelectDispatchRequestRecipientCorporation = () => {
  const { salesmanId } = useParams();
  const navigate = useNavigate();
  const { assignedCorporations, isLoading } = useFetchAssignedCorporations(salesmanId)
  if (isLoading || !assignedCorporations) return <CircularLoading />
  return (
    <DarkShadowCard fontWeight='bold'>
      <CardHeader>
        <Text fontSize='lg' m='0'>依頼先法人</Text>
      </CardHeader>
      <CardBody pt={0}>
        <Divider m='0' borderColor='gray.300' />
        <List m='0'>
          {assignedCorporations.map((corporation) => (
            <React.Fragment key={corporation.id}>
              <ListItem cursor='pointer' _hover={{ bg: 'gray.100' }} px={2} py={3} onClick={() => navigate(corporation.id)}>
                <Flex align='center' justifyContent='space-between' w='100%'>
                  <Text fontSize='14px' m='0'>{corporation.name}</Text>
                  <ChevronRightIcon color='gray.400' />
                </Flex>
              </ListItem>
              <Divider m='0' borderColor='gray.300' />
            </React.Fragment>
          ))}
        </List>
      </CardBody>
    </DarkShadowCard>
  );
};