import { Route } from 'react-router-dom';
import { TableRegister } from '../components/pages/table/TableRegister';
import { TableQrCodes } from '../components/pages/table/TableQrCodes';
import { Tables } from '../components/pages/table/Tables';
import { TableEdit } from '../components/pages/table/TableEdit';


export const TableRoutes = (
  <Route path="tables">
    <Route path="" element={<Tables />} />
    <Route path="register" element={<TableRegister />} />
    <Route path=":tableId/edit" element={<TableEdit />} />
    <Route path="qr" element={<TableQrCodes />} />
  </Route>
);