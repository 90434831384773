import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const kuchikomyuSettingEditByRestaurantSchema = yup.object().shape({
  branch_score: yup.number().required('ブランチスコアを入力してください'),
  qr_card_img: yup.mixed(),
  en_qr_card_img: yup.mixed(),
});
type KuchikomyuSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  en_url: string;
}
export const useEditKuchikomyuSettingFormForRestaurant = (kuchikomyuSetting?: KuchikomyuSetting) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue, } = useForm({resolver: yupResolver(kuchikomyuSettingEditByRestaurantSchema),});
  useEffect(() => {
    if (kuchikomyuSetting) {
      setValue('branch_score', kuchikomyuSetting.branch_score);
    }
  }, [kuchikomyuSetting]);
  return {
    handleSubmit,
    control,
    register,
    isSubmitting,
    errors,
    setValue
  };
};