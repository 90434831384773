import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const PasswordEditSchema = yup.object().shape({
  new_password: yup.string().required('入力必須項目です').test('password-length', 'パスワードは4文字以上で入力してください', value => value.length >= 4),
});
export const useEditPasswordForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(PasswordEditSchema),
  });
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};