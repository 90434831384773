import React, { memo } from "react";
import { Grid, VStack } from "@chakra-ui/react";
import { UserRegistrationsCountCard } from "./UserRegistrationsCountCard";
import { BlockCountCard } from "./BlockCountCard";
import { SegmentDeliveriesCountCard } from "./SegmentDeliveriesCountCard";
import { CouponUsedUsersCountCard } from "./CouponUsedUsersCountCard";
import { useRecoilValue } from "recoil";
import { dashboardDateRangeState } from "../../../store/dashboardDateRangeState";


type CenterLeftDataSectionProps = {
  restaurantId: string;
}
export const CenterLeftDataSection: React.FC<CenterLeftDataSectionProps> = memo(({restaurantId}) => {
  const dateRange = useRecoilValue(dashboardDateRangeState)
  return (
    <VStack spacing={4} w='100%'>
      <UserRegistrationsCountCard restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />
      <BlockCountCard restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />
      <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)'}} gap={{base: 4, sm: 5, lg: 5}} w='100%'>
        <SegmentDeliveriesCountCard restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />
        <CouponUsedUsersCountCard restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />
      </Grid>
    </VStack>
  );
});