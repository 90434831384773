import useSWR from "swr";
import { UUID } from "crypto";


type UserData = {
  id: UUID;
  username: string;
  email: string;
  phone_number: string;
  last_name: string;
  first_name: string;
  groups: number[];
}
export type Corporation = {
  id: string;
  user: string;
  name: string;
  address: string;
  president_last_name: string;
  president_first_name: string;
  president_phone_number: string;
  user_data: UserData;
  salesman: string;
  salesman_name: string;
}
export const useFetchCorporation = (corporationId?: string) => {
  const fetchKey = `accounts/corporations/${corporationId}/`
  const { data: corporation, error, isLoading } = useSWR<Corporation>(fetchKey)
  return { corporation, isLoading, error };
};