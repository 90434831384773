import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type SelectedQuestions = {
  id: string;
  question: string;
  question_content: string;
}[]

export const useFetchKuchikomyuSurveySelectedQuestionsCustomer = (restaurantId: string) => {
  const { data: selectedQuestions, isLoading } = useSwrNoAuth<SelectedQuestions>(`kuchikomyu/restaurants/${restaurantId}/survey/selected-questions/customer/`)
  return { selectedQuestions, isLoading };
};