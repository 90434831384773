import { memo } from "react"
import { Grid, Skeleton } from "@chakra-ui/react";
import { useFetchTotalMediaReservations } from "../../../hooks/ifreaAnalytics/useFetchTotalMediaReservations";
import { LineGraphCard } from "../../molecules/card/LineGraphCard";
import { useFetchTotalMediaCall } from "../../../hooks/ifreaAnalytics/useFetchTotalMediaCall";
import { useFetchTotalMediaTopPv } from "../../../hooks/ifreaAnalytics/useFetchTotalMediaTopPv";


type TotalAnalyticsProps = {
  restaurantId: string
}
export const TotalAnalytics: React.FC<TotalAnalyticsProps> = memo(({restaurantId}) => {
  const graphCards = [
    { component: TotalMediaReservationsGraphCard },
    { component: TotalMediaCallGraphCard },
    { component: TotalMediaTopPvGraphCard },
  ];
  return (
    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}} gap={2} w='100%'>
      {graphCards.map((GraphCard, index) => (
        <GraphCard.component key={index} restaurantId={restaurantId} />
      ))}
    </Grid>
  );
});


const TotalMediaReservationsGraphCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { totalMediaReservations, isLoading } = useFetchTotalMediaReservations(restaurantId)
  return (
    <Skeleton isLoaded={!isLoading} borderRadius={5}>
      <LineGraphCard
        title='予約数'
        labels={totalMediaReservations ? Object.keys(totalMediaReservations) : []}
        values={totalMediaReservations ? Object.values(totalMediaReservations) : []}
        graphColorRgb='54, 161, 104'
      />
    </Skeleton>
  )
})

const TotalMediaCallGraphCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { totalMediaCall, isLoading } = useFetchTotalMediaCall(restaurantId)
  return (
    <Skeleton isLoaded={!isLoading} borderRadius={5}>
      <LineGraphCard
        title='コール数'
        labels={totalMediaCall ? Object.keys(totalMediaCall) : []}
        values={totalMediaCall ? Object.values(totalMediaCall) : []}
        graphColorRgb='54, 161, 104'
      />
    </Skeleton>
  )
})

const TotalMediaTopPvGraphCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { totalMediaTopPv, isLoading } = useFetchTotalMediaTopPv(restaurantId)
  return (
    <Skeleton isLoaded={!isLoading} borderRadius={5}>
      <LineGraphCard
        title='店舗TOP PV'
        labels={totalMediaTopPv ? Object.keys(totalMediaTopPv) : []}
        values={totalMediaTopPv ? Object.values(totalMediaTopPv) : []}
        graphColorRgb='54, 161, 104'
      />
    </Skeleton>
  )
})