import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const registerCorporationSchema = yup.object().shape({
  user: yup.object().shape({
    username: yup.string().required('ユーザーIDを入力してください'),
    password: yup.string().required('パスワードを入力してください').test('password-length', 'パスワードは4文字以上で入力してください', value => value.length >= 4),
    email: yup.string().required('メールアドレスを入力してください').email("メールアドレスを正しい形式で入力してください"),
    phone_number: yup.string().required('電話番号を入力してください')
  }),
  corporation: yup.object().shape({
    salesman: yup.string().required('営業担当者を選択してください'),
    name: yup.string().required('法人名を入力してください'),
    address: yup.string().required('住所を入力してください').test('password-length', 'パスワードは4文字以上で入力してください', value => value.length >= 4),
    president_last_name: yup.string().required('代表者名を入力してください'),
    president_first_name: yup.string().required('代表者名を入力してください'),
    president_phone_number: yup.string().required('電話番号を入力してください')
  }),
});
export const useRegisterCorporationForm = () => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(registerCorporationSchema),
  });
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};