import React, { memo } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from '@chakra-ui/react'
import { useForm } from "react-hook-form";

type DeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  deleteTarget: string;
  onSubmit: (data: any) => void;
}
export const DeleteModal: React.FC<DeleteModalProps> = memo(({isOpen, onClose, deleteTarget, onSubmit}) => {
  const { handleSubmit, formState: { isSubmitting }  } = useForm();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xs' >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>注意！</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box fontWeight='bold' fontSize='16px'>「{deleteTarget}」を削除してもよろしいですか？</Box>
          <Box fontWeight='bold' fontSize='16px'>この操作は元に戻せません。</Box>
        </ModalBody>
        <ModalFooter>
          <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
            <Button colorScheme='red' size='sm' mr={3} type="submit" isLoading={isSubmitting} >削除</Button>
            <Button colorScheme='gray' size='sm' onClick={onClose} isDisabled={isSubmitting}>キャンセル</Button>
          </form>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});