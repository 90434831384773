import useSWR from "swr";

type Reviews = {
  id: string;
  date: string;
  rating: number;
  comment: string;
  reply: string;
  reviewerName: string;
  reviewerImageUrl: string;
}[]
export const useFetchGoogleReviews = (restaurantId?: string, queryParams?: string) => {
  const fetcherKey = restaurantId ? `/external_api/restaurants/${restaurantId}/google/reviews/${queryParams}` : null;
  const { data: reviews, error, isLoading } = useSWR<Reviews>(fetcherKey);
  return { reviews, isLoading, error };
};