import React, { memo } from "react";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { Box, CardBody, CardFooter, CardHeader, Divider, Flex, Image, VStack } from "@chakra-ui/react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useParams } from "react-router-dom";
import { useFetchRestaurant } from "../../../hooks/restaurants/useFetchRestaurant";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { CiShop } from "react-icons/ci";
import { SettingItem } from "../../organisms/SettingItem";


export const RestaurantBaseInfo: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { restaurant, isLoading } = useFetchRestaurant(restaurantId)
  if (isLoading) return <CircularLoading />
  if (!restaurant) return null
  const restaurantDetails = [
    { label: '店舗ロゴ画像', value: <Image src={restaurant.logo} boxSize='200px' objectFit='contain' /> },
    { label: 'ユーザーネーム', value: restaurant.user_data.username },
    { label: 'メールアドレス', value: restaurant.user_data.email },
    { label: '電話番号', value: restaurant.user_data.phone_number },
    { label: '住所', value: restaurant.address },
    { label: '代表者名', value: `${restaurant.manager_last_name}${restaurant.manager_first_name}` },
    { label: '代表者電話番号', value: restaurant.manager_phone_number },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '650px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<CiShop />} heading={restaurant.name}/>
        </CardHeader>
        <CardBody py={2}>
          <VStack align='start' fontWeight='bold' spacing={3} w='100%'>
            <Divider my={2} />
            {restaurantDetails.map((detail) => (
              <React.Fragment key={detail.label}>
                <Box w='100%'>
                  <SettingItem label={detail.label} content={detail.value}/>
                </Box>
                <Divider my={2} />
              </React.Fragment>
            ))}
          </VStack>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集'/>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});