import React, { memo } from "react";
import { Card, CardBody, Text, VStack, Badge } from '@chakra-ui/react'

type DefaultCouponDesignProps = {
  title: string;
}

export const DefaultCouponDesignSm: React.FC<DefaultCouponDesignProps> = memo(({title}) => {

  return (
    <Card bgColor='#FE914C' borderRadius='20px' h='100%' outline='3px solid white' outlineOffset='-6px' variant='filled' w='100%'>
      <CardBody pt={5} px={2} pb='6px'>
        <VStack h='100%'>
          <Badge bgColor='white' borderRadius='10px' fontSize='1.86vw' px={2} py='2px'>1回のみ使用可能</Badge>
          <Text fontSize='3.2vw' fontWeight='bold' m='0' textAlign='center'>{title}</Text>
        </VStack>
      </CardBody>
    </Card>
    );
});
