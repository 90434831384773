import React from "react";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Textarea } from "@chakra-ui/react";


type AltTextTextareaProps = {
  register: any,
  errors: any,
}
export const AltTextTextarea: React.FC<AltTextTextareaProps> = ({register, errors}) => (
  <FormControl isRequired isInvalid={errors.altText}>
    <FormLabel>通知メッセージ</FormLabel>
    <Textarea
      {...register('altText')}
      maxLength={400}
      placeholder='クーポンが届いてます！'
    />
    <FormHelperText>最大400文字</FormHelperText>
    <FormErrorMessage>{errors['altText']?.message}</FormErrorMessage>
  </FormControl>
);