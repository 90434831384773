import { Route } from 'react-router-dom';
import { ManagerRestaurants } from '../components/pages/managers/ManagerRestaurants';
import { ManagerBaseInfoEdit } from '../components/pages/managers/ManagerBaseInfoEdit';
import { CorporateRestaurantsRoutes } from './CorporateRestaurantsRoutes';
import ManagerBase from '../components/template/base/ManagerBase';
import { ManagerBaseInfo } from '../components/pages/managers/ManagerBaseInfo';


export const ManagerRoutes = (
  <Route path="managers/:managerId" element={<ManagerBase />}>
    <Route path="" element={<ManagerBaseInfo />} />
    <Route path="edit" element={<ManagerBaseInfoEdit />} />
    <Route path="restaurants" element={<ManagerRestaurants />} />
    {CorporateRestaurantsRoutes}
  </Route>
);