import { Box, CardBody, CardHeader, Divider, Grid, Skeleton } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { DonutChart } from "../../molecules/graph/DonutChart";
import { LightShadowCard } from "../../molecules/card/LightShadowCard";
import { useFetchOverallRating } from "../../../hooks/kuitan/useFetchOverallRating";
import { useFetchFoodRating } from "../../../hooks/kuitan/useFetchFoodRating";
import { useFetchDrinkRating } from "../../../hooks/kuitan/useFetchDrinkRating";
import { useFetchCustomerServiceRating } from "../../../hooks/kuitan/useFetchCustomerServiceRating";
import { useFetchHygieneRating } from "../../../hooks/kuitan/useFetchHygieneRating";


export const KuitanDashboard = () => {
  const chartComponents = [
    { component: createDonutChart(useFetchOverallRating, '総合', 'overallRating'), title: '総合' },
    { component: createDonutChart(useFetchFoodRating, '料理', 'foodRating'), title: '料理' },
    { component: createDonutChart(useFetchDrinkRating, 'ドリンク', 'drinkRating'), title: 'ドリンク' },
    { component: createDonutChart(useFetchCustomerServiceRating, '接客', 'customerServiceRating'), title: '接客' },
    { component: createDonutChart(useFetchHygieneRating, '店内衛生', 'hygieneRating'), title: '店内衛生' },
  ];
  const { restaurantId } = useParams();
  return (
    <Grid templateColumns={{base: '1fr', md: 'repeat(2, 1fr)'}} gap={4} w='100%'>
      {chartComponents.map(({ component: ChartComponent, title }, index) => <ChartComponent key={index} restaurantId={restaurantId} />)}
    </Grid>
  );
};

const createDonutChart = (useFetchRating: (restaurantId?: string) => { [key: string]: any, isLoading: boolean }, title: string, ratingKey: string) =>
  ({ restaurantId }: { restaurantId?: string }) => {
    const { [ratingKey]: rating, isLoading } = useFetchRating(restaurantId);
    const series = [5, 4, 3, 2, 1].map(r => rating?.[r.toString()] ?? 0);
    return <DonutChartCard title={title} series={series} isLoaded={isLoading} />;
};


const DonutChartCard = ({title, series, isLoaded}: {title: string, series: number[], isLoaded: boolean}) => {
  const labels = ['☆☆☆☆☆', '☆☆☆☆', '☆☆☆', '☆☆', '☆']
  return (
    <Skeleton isLoaded={!isLoaded} borderRadius={5} w='100%'>
      <LightShadowCard rounded='md'>
        <CardHeader p={3}>
          {title}
        </CardHeader>
        <Divider m='0' borderColor='gray.200' />
        <CardBody p={3}>
          <Box w={{base: '100%', lg: '85%'}}>
            <DonutChart
              labels={labels}
              series={series}
              colors={['#D92F25', '#F19801', '#1B73E9', '#1E8E3C', '#C63CC4']}
            />
          </Box>
        </CardBody>
      </LightShadowCard>
    </Skeleton>
  );
};