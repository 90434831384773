import { memo } from "react"
import { CardBody, CardFooter, CardHeader, Center, Grid, HStack, IconButton, Image, Text } from "@chakra-ui/react";
import { useFetchTablesByRestaurantId } from "../../../hooks/tableStock/useFetchTablesByRestaurantId";
import { CardHeading } from "../../molecules/card/CardHeading";
import { useNavigate, useParams } from "react-router-dom";
import { EditIcon } from "@chakra-ui/icons";
import { TableDeleteModal } from "../../organisms/table/TableDeleteModal";
import { MdOutlineTableRestaurant } from "react-icons/md";
import { CreateNewBox } from "../../molecules/CreateNewBox";
import { Skeletons } from "../../atoms/Skeletons";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";


export const Tables: React.FC = memo(() => {
  const { restaurantId } = useParams()
  if (!restaurantId) return null;
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<MdOutlineTableRestaurant fontSize='sm' />} heading='テーブル一覧'/>
      </CardHeader>
      <CardBody pt={0}>
        <TableCards restaurantId={restaurantId} />
      </CardBody>
    </DarkShadowCard>
  );
});


const TableCards: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { tables, isLoading } = useFetchTablesByRestaurantId(restaurantId)
  if (isLoading || !tables) return <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(5, 1fr)'}} gap={2}><Skeletons length={5} h='20vw' rounded='2xl' /></Grid>
  return (
    <Grid templateColumns={{base: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(5, 1fr)'}} gap={2}>
      <>
        <CreateNewBox path={'register'} h='100%' minH='20vw' rounded='2xl' />
        {tables.map(table => <TableCard key={table.id} {...table} restaurantId={restaurantId} />)}
      </>
    </Grid>
  )
})

type Table = {
  id: string;
  table_name: string;
  qr_code: string;
  restaurantId: string;
}
const TableCard: React.FC<Table> = memo(({id, table_name, qr_code, restaurantId}) => {
  return (
    <DarkShadowCard>
      <CardHeader py={{base: 2, sm: 4}}>
        <Text fontWeight='bold' m='0' fontSize={{base: 'xs', sm: 'sm'}} textAlign='center'>{table_name}</Text>
      </CardHeader>
      <CardBody px={{base: 2, sm: 5}} py={0}>
        <Center h='100%'>
          <Image src={qr_code} alt={table_name} objectFit='cover' w='100%' />
        </Center>
      </CardBody>
      <CardFooter py={{base: 0, sm: 2}}>
        <EditAndDeleteIcons id={id} tableName={table_name} restaurantId={restaurantId} />
      </CardFooter>
    </DarkShadowCard>
  )
})


const EditAndDeleteIcons: React.FC<{ id: string, tableName: string, restaurantId: string }> = memo(({ id, tableName, restaurantId }) => {
  const navigate = useNavigate()
  return (
    <HStack justify='center' w='100%'>
      <IconButton
        colorScheme='messenger'
        aria-label='Edit Table'
        icon={<EditIcon />}
        size='sm'
        variant='ghost'
        onClick={() => navigate(`${id}/edit`)}
      />
      <TableDeleteModal mutateKey={`table_stock/restaurants/${restaurantId}/tables/`} id={id} tableName={tableName} />
    </HStack>
  )
})