import React, { memo } from "react";
import { Controller } from "react-hook-form";
import { Checkbox } from "@chakra-ui/react";

type VisitCountCheckboxProps = {
  visitCount: number
  control: any;
}

export const VisitCountCheckbox: React.FC<VisitCountCheckboxProps> = memo(({visitCount, control}) => {
  return (
    <Controller
      name="visitCounts"
      control={control}
      render={({ field }) => (
        <Checkbox
          m={0}
          value={visitCount}
          checked={field.value ? field.value.includes(visitCount) : false}
          onChange={() => {
            const isAlreadySelected = field.value ? field.value.includes(visitCount) : false;
            const newValue = isAlreadySelected
              ? field.value.filter((value: number) => value !== visitCount)
              : [...field.value, visitCount];
            field.onChange(newValue);
          }}
        >
          {visitCount}
        </Checkbox>
      )}
    />
  );
});

