import { useForm } from "react-hook-form";
import { useEffect } from "react";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const benefitSchema = yup.object().shape({
  title: yup.string().required('タイトルを入力してください'),
  explanation: yup.string().required('説明を入力してください'),
  notes: yup.string().required('注意事項を入力してください'),
  ranks: yup.array().of(yup.string()).min(1, '入力必須項目です').required('入力必須項目です'),
  img: yup.mixed()
});

type Benefit = {
  id: string;
  ranks: string[];
  title: string;
  explanation: string;
  notes: string;
  img: string;
}
export const useEditBenefitForm = (benefit?: Benefit) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue, } = useForm({resolver: yupResolver(benefitSchema),});

  useEffect(() => {
    if (benefit) {
      setValue('title', benefit.title);
      setValue('explanation', benefit.explanation);
      setValue('notes', benefit.notes);
      setValue('ranks', benefit.ranks);
    }
  }, [benefit]);

  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
    setValue
  };
};