import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";
import { switchImgAppend } from "../../utils/switchImgAppend";

export const useSubmitSoCouponEdit = (soCouponId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();

  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("title", data.title);
      uploadData.append("explanation", data.explanation);
      uploadData.append("notes", data.notes);
      uploadData.append("expiration_date", data.expiration_date);
      switchImgAppend(data.img, uploadData);
      await customAuthAxios.put(`socoupon/socoupons/${soCouponId}/`, uploadData)
      showToast('編集が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  };

  return onSubmit;
};