import React, { memo } from "react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { Box, Button, Divider, Flex, Image, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useFetchLineSegmentMsg } from "../../../hooks/membership/useFetchLineSegmentMsg";
import { formatDate } from "../../../utils/formatDate";

export const SegmentMessage: React.FC = memo(() => {
  const params = useParams();
  const { msgId } = params;

  const { segmentMsg, error, isLoading } = useFetchLineSegmentMsg(msgId!);
  if (isLoading) return <CircularLoading />;
  if (!segmentMsg) return null;

  return (
    <>
      <Box p={2}>
        <Text fontSize='4.65vw' fontWeight='bold' m='0'>{segmentMsg.title}</Text>
      </Box>
      <Divider m='0' />
      <VStack align='start' p={2} spacing={3}>
        <Text color='#969696' fontSize='3.26vw' m='0' textAlign='right' w='100%'>{formatDate(segmentMsg.send_at)}</Text>
        <Image
          src={segmentMsg.img}
          alt={segmentMsg.title}
        />
        <Text fontSize='3.72vw' fontWeight='bold' m='0'>{segmentMsg.text}</Text>
        <Flex justify='center' w='100%'>
          <Button size='sm' colorScheme="messenger" onClick={() => window.open(segmentMsg.url, '_blank')} >{segmentMsg.label}</Button>
        </Flex>
      </VStack>
    </>
  );
});
