import { memo } from "react"
import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Skeleton, VStack } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { useEditSoCouponSettingFormForRestaurant } from "../../../hooks/socoupon/useEditSoCouponSettingFormForRestaurant";
import { useEditSoCouponSettingByRestaurant } from "../../../hooks/socoupon/useEditSoCouponSettingByRestaurant";
import { useFetchSoCouponsByRestaurantId } from "../../../hooks/socoupon/useFetchSoCouponsByRestaurantId";
import { SelectBranchScore } from "../../molecules/form/SelectBranchScore";
import { QrCardImgInput } from "../../molecules/form/QrCardImgInput";
import { useFetchSoCouponSettingByRestaurantId } from "../../../hooks/socoupon/useFetchSoCouponSettingByRestaurantId";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { SwitchRemindStatus } from "../../organisms/soCoupon/SwitchRemindStatus";


export const EditSoCouponSettingByRestaurant = () => {
  const { restaurantId } = useParams()
  const {soCouponSetting, isLoading} = useFetchSoCouponSettingByRestaurantId(restaurantId);
  const { handleSubmit, register, control, isSubmitting, errors, setValue } = useEditSoCouponSettingFormForRestaurant(soCouponSetting)
  const onSubmit = useEditSoCouponSettingByRestaurant(soCouponSetting?.id)
  if (!restaurantId) return null
  return (
    <Box m='auto' w={{base: '100%', md: '580px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='即ーポン設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <SelectBranchScore control={control} errors={errors} isLoading={isLoading} />
              <SelectCoupon restaurantId={restaurantId} control={control} errors={errors} />
              <SwitchRemindStatus control={control} />
              <QrCardImgInput qrCardImg={soCouponSetting?.qr_card_img} register={register} setValue={setValue} />
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};


const SelectCoupon : React.FC<{restaurantId: string, control: any, errors: any}> = memo(({restaurantId, control, errors}) => {
  const { soCoupons, isLoading } = useFetchSoCouponsByRestaurantId(restaurantId);
  const soCouponsOption = soCoupons?.map(soCoupon => ({
    label: soCoupon.title,
    value: soCoupon.id
  }));
  const soCouponInUseError = errors.socoupon_in_use?.message;
  return (
    <FormControl isInvalid={!!errors.socoupon_in_use} isRequired w='100%'>
      <FormLabel>使用中クーポン</FormLabel>
      <Skeleton isLoaded={!isLoading } w='100%' borderRadius={5}>
        <Controller
          name='socoupon_in_use'
          control={control}
          render={({ field }) => (
            <ReactSelectWithChakraStyle
              noOptionsMessage={({inputValue}) => !inputValue && 'クーポンを作成してください'}
              placeholder="選択"
              options={soCouponsOption}
              value={soCouponsOption?.find((x) => x.value === field.value)}
              onChange={(newValue:any) => {
                field.onChange(newValue?.value);
              }}
            />
          )}
        />
      </Skeleton>
      <FormErrorMessage>{soCouponInUseError}</FormErrorMessage>
    </FormControl>
  );
})