import useSWR from "swr";


type SurveyQuestions = {
  id: string;
  restaurant: string;
  question: string;
  answer_count: number;
  is_selected: boolean;
}[]
export const useFetchRestaurantSurveyQuestions = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `restaurant_staff/restaurants/${restaurantId}/restaurant-survey/questions/` : null;
  const { data: surveyQuestions, error, isLoading, mutate } = useSWR<SurveyQuestions>(fetcherKey)
  return { surveyQuestions, isLoading, error, mutate };
};