import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Box, Button, VStack, Text, Input, HStack, Spinner,Card,CardBody,CardHeader,Heading } from "@chakra-ui/react";
import { FaInstagram } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
    initializeFacebookSDK,
    facebookLogin,
    obtainLongLivedToken
} from "../../../utils/instagram/services/facebookService";
import {
    saveAuthData,
    getDjangoToken
} from "../../../utils/instagram/services/apiService";
import { customAuthAxios } from "../../../utils/customAuthAxios";

const InstagramSetting: React.FC = () => {
    const { restaurantId } = useParams<{ restaurantId: string }>();
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [businessAccountId, setBusinessAccountId] = useState<string | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // ローディング状態を管理
    const [isEditingId, setIsEditingId] = useState(false);
    const [newBusinessAccountId, setNewBusinessAccountId] = useState<string>("");

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const djangoToken = await getDjangoToken();
                if (!djangoToken) {
                    console.warn("Django token not available");
                    return;
                }

                const response = await customAuthAxios.get(
                    `external_api/instagram-auth/${restaurantId}/`,
                    { headers: { Authorization: `JWT ${djangoToken}` } }
                );

                const { token, instagram_id } = response.data;
                setAccessToken(token);
                setBusinessAccountId(instagram_id);
                setIsAuthenticated(true);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === 404) {
                        console.log("No auth data found. Prompting user to login.");
                    } else {
                        console.error("Error checking auth status:", error);
                    }
                }
            } finally {
                setIsLoading(false); // ローディング終了
            }
        };

        checkAuthStatus();
    }, [restaurantId]);

    const handleLogin = async () => {
        try {
            await initializeFacebookSDK();
            const shortLivedToken = await facebookLogin();
            if (shortLivedToken) {
                const longLivedToken = await obtainLongLivedToken(shortLivedToken);
                if (longLivedToken) {
                    setAccessToken(longLivedToken);

                    const djangoToken = await getDjangoToken();
                    if (djangoToken && restaurantId && businessAccountId) {
                        await saveAuthData(restaurantId, longLivedToken, businessAccountId, djangoToken);
                    }

                    setIsAuthenticated(true);
                } else {
                    console.log("Failed to obtain long-lived token.");
                }
            } else {
                console.log("Facebook login failed or token retrieval failed.");
            }
        } catch (error) {
            console.error("Error during Facebook login:", error);
        }
    };


    const handleSaveNewId = async () => {
        try {
            const djangoToken = await getDjangoToken();
            if (!djangoToken || !newBusinessAccountId) return;

            await customAuthAxios.post(
                `external_api/instagram-auth/${restaurantId}/`,
                { instagram_id: newBusinessAccountId, token: accessToken },
                { headers: { Authorization: `JWT ${djangoToken}` } }
            );

            setBusinessAccountId(newBusinessAccountId);
            setIsEditingId(false);
            setNewBusinessAccountId("");
        } catch (error) {
            console.error("Error saving new Instagram ID:", error);
        }
    };

    return (
        <Box>
            {/* ページタイトル */}
            <HStack spacing={2} mb={4}>
                <FaInstagram size={24} />
                <Text fontSize="2xl" fontWeight="bold">Instagram連携設定</Text>
            </HStack>

            {isLoading ? (
                // ローディング中のスピナーを表示
                <VStack spacing={4} p={4} w="full">
                    <Spinner size="xl" />
                    <Text>読み込み中...</Text>
                </VStack>
            ) : (
                <VStack spacing={4} p={4} w="full" align="start"> {/* 左揃え */}
                    {!isAuthenticated ? (
                        <>
                            {/* 認証前の説明とログインボタン */}
                            <Heading size="md">Instagramアカウントの連携</Heading> {/* 見出しを追加 */}
                            <Text>Instagramアカウントを連携するには、Facebookでログインしてください。</Text>
                            <Button colorScheme="teal" onClick={handleLogin}>
                                Facebookでログイン
                            </Button>
                        </>
                    ) : (
                        <>
                            {/* 現在のInstagram ID確認セクション */}
                            <Heading size="md">現在のInstagram ID</Heading> {/* 見出しを追加 */}
                            <Text>現在設定されているInstagramビジネスIDは下記のIDです。<br></br>正常に表示されない場合は再度連携をしてください。</Text>
                            <Box>
                                <Text fontSize="lg" fontWeight="bold">
                                    Instagram ID: {businessAccountId || "未設定"}
                                </Text>
                                {businessAccountId ? (
                                    !isEditingId ? (
                                        <Button mt={2} colorScheme="blue" onClick={() => setIsEditingId(true)}>
                                            Instagram IDを変更
                                        </Button>
                                    ) : (
                                        <>
                                            <Input
                                                placeholder="新しいInstagram IDを入力"
                                                value={newBusinessAccountId}
                                                onChange={(e) => setNewBusinessAccountId(e.target.value)}
                                            />
                                            <Button mt={2} mr={2} colorScheme="teal" onClick={handleSaveNewId} disabled={!newBusinessAccountId}>
                                                保存
                                            </Button>
                                            <Button mt={2} colorScheme="red" onClick={() => setIsEditingId(false)}>
                                                キャンセル
                                            </Button>
                                        </>
                                    )
                                ) : (
                                    <>
                                        <Input
                                            placeholder="InstagramビジネスアカウントIDを入力"
                                            value={newBusinessAccountId}
                                            onChange={(e) => setNewBusinessAccountId(e.target.value)}
                                        />
                                        <Button
                                            mt={2}
                                            colorScheme="teal"
                                            onClick={handleSaveNewId}
                                            disabled={!newBusinessAccountId}
                                        >
                                            Instagram IDを保存
                                        </Button>
                                    </>
                                )}
                            </Box>

                            {/* Facebook連携設定変更セクション */}
                            <Box  mt={6} w="full">
                                <Heading size="md">Facebook連携の再設定</Heading> {/* 見出しを追加 */}
                                <Text mt={2}>
                                    Facebookの連携設定自体を変更する場合はこちらをご利用ください。
                                </Text>
                                <Button mt={2} colorScheme="teal" onClick={handleLogin}>
                                    Facebook連携を再設定
                                </Button>
                            </Box>
                        </>
                    )}
                </VStack>
            )}
        </Box>
    );
};

export default InstagramSetting;