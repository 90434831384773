import { memo } from "react"
import { useRecoilValue } from "recoil";
import styled from 'styled-components';
import { isLoadingState } from "../../../store/isLoadingState";
import { PROPS_CARD_WITH_FOOTER } from "../../../types/cardTypes";
import { PartialCircularLoading } from "../loading/PartialCircularLoading";



const SCardHeader = styled.div`
  background-color: #367AFF;
  color:#FFF;
  font-weight: bold;
`;


export const CardWithFooter: React.FC<PROPS_CARD_WITH_FOOTER> = memo(({cardTitle, children: [ body, footer ]}) => {

  const isLoading = useRecoilValue(isLoadingState)

  return (
    <div className="card">
      <SCardHeader className="card-header">
        <div className="card-title font-weight-bold">{cardTitle}</div>
      </SCardHeader>
      <div className="card-body">
      {isLoading ? (
        <PartialCircularLoading/>
      ):(body)}

      </div>
      <div className="card-footer">
        {footer}
      </div>
    </div>
  );
});


