import { useEffect, useState } from 'react';
import { extractRestaurantIdFromUrl } from '../../../utils/extractRestaurantIdFromUrl';

export const useExtractRestaurantIdFromUrl = () => {
  const [restaurantId, setRestaurantId] = useState<string|null>(null);

  useEffect(() => {
    const fetchRestaurantId = async () => {
      const id = await extractRestaurantIdFromUrl();
      setRestaurantId(id);
    };
    fetchRestaurantId();
  }, []);

  return restaurantId;
};
