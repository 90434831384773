import React, { memo } from 'react';
import { useFetchManLaboQuestionScores } from '../../../hooks/manzokudoLabo/useFetchManLaboQuestionScores';
import { SurveyScoreCards } from '../SurveyScoreCards';


type ManlaboQuestionScoresProps = {
  restaurantId: string
}
export const ManlaboQuestionScores: React.FC<ManlaboQuestionScoresProps> = memo(({restaurantId}) => {
  const { manLaboQuestionScores, isLoading } = useFetchManLaboQuestionScores(restaurantId)
  return <SurveyScoreCards isLoading={isLoading} surveyScores={manLaboQuestionScores} />
});