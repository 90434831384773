import { Box, CardBody, CardFooter, CardHeader, HStack } from "@chakra-ui/react";
import { memo } from "react"
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { useFetchRankSettings } from "../../../hooks/membership/useFetchRankSettings";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { TableLayout } from "../../template/table/TableLayout";
import { TableRowLayout } from "../../template/table/TableRowLayout";


export const RankSettings: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { rankSettings, isLoading } = useFetchRankSettings(restaurantId)
  if (isLoading) return <CircularLoading />
  if (!rankSettings) return null
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='ランク設定'/>
        </CardHeader>
        <CardBody py={2}>
          <TableLayout
            headers={['ランク名', '来店回数']}
            rows={rankSettings.map((rankSetting, index) => (
              <TableRowLayout
                key={rankSetting.id}
                childrenList={[
                  rankSetting.name,
                  <HStack spacing={4}>
                    <Box>{rankSetting.min_visits}回以上</Box>
                    {rankSetting.max_visits && <Box>{rankSetting.max_visits}回未満</Box>}
                  </HStack>
                ]}
              />
            ))}
          />
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集' />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});