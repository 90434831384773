import React, { memo } from "react";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { useEditMyRestaurantForm } from "../../../hooks/restaurants/useEditMyRestaurantForm";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { useSubmitMyRestaurantEdit } from "../../../hooks/restaurants/useSubmitMyRestaurantEdit";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { RestaurantNameInputField } from "../../organisms/restaurant/RestaurantNameInputField";
import { UserUsernameInput } from "../../molecules/form/UserUsernameInput";
import { UserEmailInput } from "../../molecules/form/UserEmailInput";
import { UserPhoneNumberInput } from "../../molecules/form/UserPhoneNumberInput";
import { RestaurantAddressInputField } from "../../organisms/restaurant/RestaurantAddressInputField";
import { ManagerNameInputField } from "../../organisms/restaurant/ManagerNameInputField";
import { ManagerPhoneNumberInputField } from "../../organisms/restaurant/ManagerPhoneNumberInputField";
import { LogoInputField } from "../../organisms/restaurant/LogoInputField";
import { useParams } from "react-router-dom";
import { useFetchRestaurant } from "../../../hooks/restaurants/useFetchRestaurant";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { CiShop } from "react-icons/ci";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const RestaurantBaseInfoEdit: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { restaurant } = useFetchRestaurant(restaurantId)
  const { handleSubmit, register, isSubmitting, errors } = useEditMyRestaurantForm(restaurant);
  const onSubmit = useSubmitMyRestaurantEdit(restaurant?.user_data.id, restaurant?.id);
  if (!restaurant) return <CircularLoading />
  const inputFields: InputField[] = [
    { component: LogoInputField, props: { register } },
    { component: RestaurantNameInputField, props: { register, errors } },
    { component: UserUsernameInput, props: { register, errors } },
    { component: UserEmailInput, props: { register, errors } },
    { component: UserPhoneNumberInput, props: { register, errors } },
    { component: RestaurantAddressInputField, props: { register, errors } },
    { component: ManagerNameInputField, props: { register, errors } },
    { component: ManagerPhoneNumberInputField, props: { register, errors } },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<CiShop />} heading='店舗編集'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              {inputFields.map(({ component: C, props }, i) => <C key={i} {...props} />)}
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});