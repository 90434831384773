import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useErrorToast } from '../toast/useErrorToast';
import { useSuccessToast } from '../toast/useSuccessToast';


export const useSubmitManagerBaseInfoEdit = (userId?: string, managerId?: string) => {
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();
  const navigate = useNavigate();
  const editUser = async (userData: any) => {
    return await customAuthAxios.put(`accounts/users/${userId}/`, userData);
  };
  const editManager = async (managerData: any) => {
    return await customAuthAxios.put(`accounts/managers/${managerId}/`, managerData);
  };
  const onSubmit = useCallback(async (data: any) => {
    try {
      await editUser(data.user);
      await editManager(data.manager);
      showToast('編集が完了しました');
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.username) {
        showErrorToast(error.response.data.username)
      }
    }
  }, [navigate]);
  return onSubmit;
};