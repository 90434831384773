import React, { memo } from "react";
import { AnswerManLaboSurvey } from "./AnswerManLaboSurvey";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useExtractRestaurantIdFromUrl } from "../../../hooks/externalApi/line/useExtractRestaurantIdFromUrl";
import { LiffLoginProcess } from "../../organisms/LiffLoginProcess";


export const ManLaboLiffApp: React.FC = memo(() => {
  const restaurantId = useExtractRestaurantIdFromUrl()
  if (restaurantId === null) return <CircularLoading />
  return (
    <LiffLoginProcess restaurantId={restaurantId} liffType="manzokudolabo">
      <AnswerManLaboSurvey restaurantId={restaurantId} />
    </LiffLoginProcess>
  );
});