import React from "react";
import { useParams } from "react-router-dom";
import { useEditCorporationForm } from "../../../hooks/corporations/useEditCorporationForm";
import { useEditCorporation } from "../../../hooks/corporations/useEditCorporation";
import { CorporationNameInputField } from "../../organisms/corporations/CorporationNameInputField";
import { UserUsernameInput } from "../../molecules/form/UserUsernameInput";
import { UserEmailInput } from "../../molecules/form/UserEmailInput";
import { UserPhoneNumberInput } from "../../molecules/form/UserPhoneNumberInput";
import { CorporationAddressInputField } from "../../organisms/corporations/CorporationAddressInputField";
import { PresidentNameInputField } from "../../organisms/corporations/PresidentNameInputField";
import { PresidentPhoneNumberInputField } from "../../organisms/corporations/PresidentPhoneNumberInputField";
import { useFetchCorporation } from "../../../hooks/corporations/useFetchCorporation";
import { SelectSalesman } from "../../molecules/form/SelectSalesman";
import { CorporationForm } from "../../organisms/corporations/CorporationForm";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const EditCorporation = () => {
  const { corporationId } = useParams();
  const { corporation } = useFetchCorporation(corporationId)
  const { handleSubmit, register, control, isSubmitting, errors } = useEditCorporationForm(corporation);
  const onSubmit = useEditCorporation(corporation?.user_data.id, corporationId);
  const inputFields: InputField[] = [
    { component: SelectSalesman, props: { control, errors } },
    { component: CorporationNameInputField, props: { register, errors } },
    { component: UserUsernameInput, props: { register, errors } },
    { component: UserEmailInput, props: { register, errors } },
    { component: UserPhoneNumberInput, props: { register, errors } },
    { component: CorporationAddressInputField, props: { register, errors } },
    { component: PresidentNameInputField, props: { register, errors } },
    { component: PresidentPhoneNumberInputField, props: { register, errors } },
  ];
  return (
    <CorporationForm
      cardTitle='法人編集'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      inputFields={inputFields}
    />
  );
};