import { Navigate, Outlet } from "react-router-dom";
import Footer from "../../organisms/Footer";
import Layout from "../Layout";
import KutanAgentHeader from "../../organisms/header/KutanAgentHeader";
import { KuitanAgentSidebar } from "../../organisms/sidebar/KuitanAgentSidebar";


const KuitanAgentBase = () => {
  const token = localStorage.getItem('localJWT');
  if (!token) return <Navigate replace to="/login/kuitan-agent"/>;
  return (
    <Layout
      header={<KutanAgentHeader />}
      nav={<KuitanAgentSidebar />}
      main={<Outlet />}
      footer={<Footer />}
    />
  );
};


export default KuitanAgentBase;