import React, { memo } from "react";
import { Box, Flex, Spacer, Text, VStack } from '@chakra-ui/react'
import { StampField } from "./StampField";


type nextRank = {
  name: string;
  visitCountsNeededToRankUp: number;
}
type ImageStampCardProps = {
  imgUrl: string;
  visitCount: number;
  nextRank?: nextRank;
}
export const ImageStampCard: React.FC<ImageStampCardProps> = memo(({imgUrl, visitCount, nextRank}) => {
  return (
    <Box
      bgImage={`url('${imgUrl}')`}
      w='100%'
      h='100%'
      bgSize='cover'
      borderRadius='md'
      px={2}
      py={4}
    >
      <VStack align='center' justifyContent='space-between' h='100%' spacing={5}>
        <Spacer />
        <StampField visitCount={visitCount} />
        <RankData nextRank={nextRank} />
      </VStack>
    </Box>
  );
});

const RankData: React.FC<{nextRank?: nextRank}> = ({nextRank}) => {
  return (
    <Flex justify='center' w='100%'>
      <Text fontSize={{ base: '3.26vw', sm: '14px' }} fontWeight='bold' m='0'>
        {nextRank ? `あと${nextRank.visitCountsNeededToRankUp}回来店で${nextRank.name}にランクアップ` : 'ご利用ありがとうございます'}
      </Text>
    </Flex>
  )
}