import { Box, FormControl, FormLabel, Textarea, VStack } from "@chakra-ui/react";
import { memo } from "react"
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { lineAccountIdState } from "../../../store/fetchedData/lineAccountIdState";
import { SelectedQuestions, useFetchManlaboSurveySelectedQuestionsCustomerByRestaurantId } from "../../../hooks/manzokudoLabo/useFetchManlaboSurveySelectedQuestionsCustomerByRestaurantId";
import { ManLaboSetting, useFetchManlaboSettingCustomerByRestaurantId } from "../../../hooks/manzokudoLabo/useFetchManlaboSettingCustomerByRestaurantId";
import { BlocksLogoForCustomerPostScoreForm } from "../../atoms/BlocksLogoForCustomerPostScoreForm";
import { SurveyScoreRating } from "../../molecules/form/SurveyScoreRating";
import { CustomerPostScoreBtn } from "../../atoms/button/CustomerPostScoreBtn";
import { useAnswerManLaboSurveyForm } from "../../../hooks/manzokudoLabo/useAnswerManLaboSurveyForm";
import { useAnswerManLaboSurvey } from "../../../hooks/manzokudoLabo/useAnswerManLaboSurvey";
import { useRecoilValue } from "recoil";


export const AnswerManLaboSurvey = memo(({restaurantId}:{restaurantId:string}) => {
  const { selectedQuestions, isLoadingSelectedQuestions } = useFetchManlaboSurveySelectedQuestionsCustomerByRestaurantId(restaurantId);
  const { manLaboSetting, isLoadingManLaboSetting } = useFetchManlaboSettingCustomerByRestaurantId(restaurantId);
  if (isLoadingSelectedQuestions || isLoadingManLaboSetting || !selectedQuestions || !manLaboSetting) return <CircularLoading />
  return (
    <Box p={4}>
      <AnswerForm restaurantId={restaurantId} selectedQuestions={selectedQuestions} manLaboSetting={manLaboSetting} />
    </Box>
  );
});


const AnswerForm = ({restaurantId, selectedQuestions, manLaboSetting}:{restaurantId:string, selectedQuestions:SelectedQuestions, manLaboSetting:ManLaboSetting}) => {
  const lineAccountId = useRecoilValue(lineAccountIdState)
  const { handleSubmit, register, errors, control, isSubmitting } = useAnswerManLaboSurveyForm()
  const onSubmit = useAnswerManLaboSurvey(restaurantId, selectedQuestions, lineAccountId!, manLaboSetting)
  return (
    <VStack as="form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" spacing={6}>
      <BlocksLogoForCustomerPostScoreForm />
      {selectedQuestions.map((q, i) => (
        <SurveyScoreRating key={q.id} questionContent={q.question_content} index={i} control={control} errors={errors} />
      ))}
      <FormControl>
        <FormLabel>自由解答欄</FormLabel>
        <Textarea rows={5} {...register('answer_text')} />
      </FormControl>
      <CustomerPostScoreBtn isSubmitting={isSubmitting} text='回答する'/>
    </VStack>
  );
}