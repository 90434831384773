import React, { memo, ReactNode } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/react";


type HoverPopoverProps = {
  children: ReactNode[];
  w: string;
}
export const HoverPopover: React.FC<HoverPopoverProps> = memo(({ children, w }) => {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        {children[0]}
      </PopoverTrigger>
      <PopoverContent w={w} alignItems='start'>
        {children[1]}
      </PopoverContent>
    </Popover>
  );
});