import { memo, useEffect, useState } from "react"
import { Box, Checkbox, FormControl, FormHelperText, FormLabel, Text, VStack } from "@chakra-ui/react";

type QrCardImgInputProps = {
  qrCardImg?: string;
  register: any;
  setValue: any;
}

export const QrCardImgInput: React.FC<QrCardImgInputProps> = memo(({qrCardImg, register, setValue}) => {
  const [useDefaultImg, setUseDefaultImg] = useState<boolean>(qrCardImg ? false : true);
  useEffect(() => {
    if (useDefaultImg) setValue('qr_card_img', 'default');
    if (!useDefaultImg) setValue('qr_card_img', '');
  }, [useDefaultImg]);
  useEffect(() => {
    setUseDefaultImg(qrCardImg ? false : true)
  }, [qrCardImg]);
  return (
    <FormControl>
      <FormLabel>QRカード</FormLabel>
      <VStack align='start' spacing={1}>
        {qrCardImg &&
          <Box>
            <Text m='0' fontSize='xs' color='gray.500'>現在の画像</Text>
            <Box
              bgImage={`url(${qrCardImg})`}
              w="203px"
              h="287px"
              bgSize="contain"
              bgRepeat="no-repeat"
              border='0.1px solid #c0c0c09d'
            />
          </Box>
        }
        <input type="file" accept="image/*" {...register('qr_card_img')} disabled={useDefaultImg}  />
        <Checkbox isChecked={useDefaultImg} onChange={() => setUseDefaultImg(!useDefaultImg)} size='sm' m='0'>デフォルト画像を使用</Checkbox>
      </VStack>
      <FormHelperText>縦横比 1:√2</FormHelperText>
    </FormControl>
  )
})