import { memo } from "react"
import { useParams } from "react-router-dom";
import { Box, CardBody, CardHeader, Divider, FormControl, FormLabel, Grid, Switch } from '@chakra-ui/react'
import { TableQrCard } from "../../organisms/table/tableQr/TableQrCard";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { AlertMsg } from "../../molecules/AlertMsg";
import { DownloadTableQrCardsSection } from "../../organisms/table/tableQr/DownloadTableQrCardsSection";
import { useFetchLiffUrlByRestaurantId } from "../../../hooks/externalApi/line/useFetchLiffUrlByRestaurantId";
import { useFetchTablesByRestaurantId } from "../../../hooks/tableStock/useFetchTablesByRestaurantId";
import { useChangeTableSecurity } from "../menu/useChangeTableSecurity";
import { useFetchMenuSettingByRestaurantId } from "../../../hooks/menu/useFetchMenuSettingByRestaurantId";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { MdOutlineQrCode2 } from "react-icons/md";


export const TableQrCodes: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const {isLoading, error} = useFetchLiffUrlByRestaurantId(restaurantId, 'menu')
  if (error) return <AlertMsg msg={error.response.data.detail}/>
  if (!restaurantId || isLoading) return <CircularLoading />
  return <Contents restaurantId={restaurantId} />
});


type Tables = {
  id: string;
  table_name: string;
  qr_code: string;
  in_use: boolean;
  auth_code: number;
}[]
const Contents: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { tables, isLoading } = useFetchTablesByRestaurantId(restaurantId)
  if (isLoading || !tables) return <CircularLoading />
  const tableChoices: Tables[] = getTableChoices(tables, 10);
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<MdOutlineQrCode2 size='20px' />} heading='テーブルQRコード一覧'/>
      </CardHeader>
      <CardBody pt={2}>
        <Box>
          <TableSecuritySection restaurantId={restaurantId} />
          <Divider/>
          {tables.length !== 0 && <DownloadTableQrCardsSection tableChoices={tableChoices} />}
          <Grid templateColumns={{base: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(5, 1fr)'}} gap={2}>
            {tables.map((table, index) => (
              <TableQrCard key={table.id} tableId={table.id} tableName={table.table_name} inUse={table.in_use} authCode={table.auth_code} index={index+1} />
            ))}
          </Grid>
        </Box>
      </CardBody>
    </DarkShadowCard>
  )
})
const getTableChoices = (array: Tables, chunkSize: number): Tables[] => {
  const tableChoices: Tables[] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    tableChoices.push(array.slice(i, i + chunkSize));
  }
  return tableChoices;
};


const TableSecuritySection: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const changeTableSecurity = useChangeTableSecurity();
  const { menuSetting, isLoading } = useFetchMenuSettingByRestaurantId(restaurantId)
  return (
    <FormControl display='flex' alignItems='center'>
      <FormLabel mb='0'>
        テーブルセキュリティ
      </FormLabel>
      <Switch mb='0' isDisabled={isLoading} defaultChecked={menuSetting?.is_table_security} onChange={(e) => changeTableSecurity(e.target.checked, menuSetting?.id)} />
    </FormControl>
  );
});