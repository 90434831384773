import useSWR from "swr";

export type Plans = {
  id: string;
  name: string;
}[]

export const useFetchPlans = () => {
  const { data: plans, error, isLoading } = useSWR<Plans>(`general_setting/plans/`)
  return { plans, isLoading, error };
};