import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type StampCard = {
  id: string;
  img: string;
}

export const useFetchCustomerStampCard = (restaurantId: string) => {
  const { data: stampCard, error, isLoading: isLoadingStampCard } = useSwrNoAuth<StampCard>(`membership/restaurants/${restaurantId}/customer-stamp-card/`)
  return { stampCard, isLoadingStampCard, error };
};