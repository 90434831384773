import React, { memo } from 'react';
import { CircularLoading } from '../../molecules/loading/CircularLoading';
import { AnsweredCustomersLayout } from '../../template/AnsweredCustomersLayout';
import { useFetchPaginateSoCouponOverallScoreWithLineAccounts } from '../../../hooks/socoupon/useFetchPaginateSoCouponOverallScoreWithLineAccounts';
import { Paginator } from '../../molecules/pagenation/Paginator';
import { useParams } from 'react-router-dom';
import { Box, CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { LightShadowCard } from '../../molecules/card/LightShadowCard';
import { CardHeading } from '../../molecules/card/CardHeading';
import { GiStarsStack } from "react-icons/gi";


export const SoCouponOverallScoreCustomers: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { soCouponOverallScoresWithLineAccountsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage } = useFetchPaginateSoCouponOverallScoreWithLineAccounts(restaurantId!)
  if (isLoading) return <CircularLoading />
  if (!soCouponOverallScoresWithLineAccountsPerPage) return null
  return (
    <Box m='auto' w='100%'>
      <LightShadowCard>
        <CardHeader>
          <CardHeading icon={<GiStarsStack/>} heading='総合評価'/>
        </CardHeader>
        <CardBody py={1}>
          <AnsweredCustomersLayout
            answerScores={soCouponOverallScoresWithLineAccountsPerPage.socoupon_overall_scores_with_line_accounts.map(score => ({
              id: score.id,
              line_account: score.line_account,
              score: score.score,
              date: score.posted_at,
            }))}
          />
        </CardBody>
        <CardFooter>
          <Paginator pagesCount={pagesCount} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
        </CardFooter>
      </LightShadowCard>
    </Box>
  );
});