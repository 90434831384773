import { Route } from 'react-router-dom';
import { Restaurant } from '../components/pages/restaurants/Restaurant';
import { RegisterRestaurant } from '../components/pages/restaurants/RegisterRestaurant';
import { AssignedRestaurants } from '../components/pages/restaurants/AssignedRestaurants';
import { RegisterLineDev } from '../components/pages/externalApi/line/RegisterLineDev';
import { LineDevSetting } from '../components/pages/externalApi/line/LineDevSetting';
import { LiffSetting } from '../components/pages/externalApi/line/LiffSetting';
import { GoogleSetting } from '../components/pages/externalApi/google/GoogleSetting';
import { IfreaAnalyticsSetting } from '../components/pages/ifreaAnalytics/IfreaAnalyticsSetting';
import { SoCouponSetting } from '../components/pages/soCoupon/SoCouponSetting';
import { KuchikomyuSetting } from '../components/pages/kuchikomyu/KuchikomyuSetting';
import { ManLaboSetting } from '../components/pages/manzokudoLabo/ManLaboSetting';
import { GameSettings } from '../components/pages/games/GameSettings';
import { RouletteSetting } from '../components/pages/games/roulette/RouletteSetting';
import { KuitanSetting } from '../components/pages/kuitan/KuitanSetting';
import { StaffSetting } from '../components/pages/restaurantStaff/StaffSetting';


const routes = [
  { path: "", element: <AssignedRestaurants /> },
  { path: "register", element: <RegisterRestaurant /> },
  { path: ":restaurantId", element: <Restaurant /> },
  { path: ":restaurantId/settings/line-devs", element: <LineDevSetting /> },
  { path: ":restaurantId/settings/line-devs/register", element: <RegisterLineDev /> },
  { path: ":restaurantId/settings/liff", element: <LiffSetting /> },
  { path: ":restaurantId/settings/ifrea-analytics", element: <IfreaAnalyticsSetting /> },
  { path: ":restaurantId/settings/google", element: <GoogleSetting /> },
  { path: ":restaurantId/settings/kuchikomyu", element: <KuchikomyuSetting /> },
  { path: ":restaurantId/settings/socoupon", element: <SoCouponSetting /> },
  { path: ":restaurantId/settings/manzokudo-labo", element: <ManLaboSetting /> },
  { path: ":restaurantId/settings/games", element: <GameSettings /> },
  { path: ":restaurantId/settings/games/roulette", element: <RouletteSetting /> },
  { path: ":restaurantId/settings/kuitan", element: <KuitanSetting /> },
  { path: ":restaurantId/settings/restaurant-staff", element: <StaffSetting /> },
];
export const RestaurantsRoutesForSalesman = (
  <Route path='restaurants'>
    {routes.map((route) => <Route key={route.path} path={route.path} element={route.element} />)}
  </Route>
);