import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";


export const useRegisterLineAccount = (
  restaurantId: string,
  userId: string,
  mutate: any,
  signUpValues: {
    username: string;
    password: string;
    groups: number[];
  },
  lineAccountValues: {
    restaurant_id: string;
    line_id: string;
    display_name: string;
    picture_url?: string;
    visit_count: number;
  }
) => {
  const registerLineAccount = async () => {
    const uploadData = {
      user: signUpValues,
      customer: {restaurants: [restaurantId]},
      line_account: lineAccountValues
    };
    try {
      await customNoAuthAxios.post('authentication/line-account/',uploadData);
      mutate(`external_api/restaurants/${restaurantId}/line-accounts/${userId}/line-account-id/`);
    } catch (error: any) {
      console.log(error);
    }
  };

  return registerLineAccount;
};