import React, { memo } from "react";
import { Grid } from "@chakra-ui/react";
import { ActiveUsersCountCard } from "./ActiveUsersCountCard";
import { AverageSatisfactionCard } from "./AverageSatisfactionCard";


type TopDataSectionProps = {
  restaurantId: string;
}
export const TopDataSection: React.FC<TopDataSectionProps> = memo(({restaurantId}) => {
  return (
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }} w='100%' gap={{base: 3, sm: 5, lg: 5}}>
      <ActiveUsersCountCard restaurantId={restaurantId} />
      <AverageSatisfactionCard restaurantId={restaurantId} />
    </Grid>
  );
});