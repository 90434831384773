import React from "react";
import { LineSegmentDeliveryLayout } from "../../../template/LineSegmentDeliveryLayout";
import { LineSegmentCouponForm } from "../../../organisms/externalApi/line/LineSegmentCouponForm";
import { AlertMsg } from "../../../molecules/AlertMsg";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { useParams } from "react-router-dom";
import { useFetchLiffUrlByRestaurantId } from "../../../../hooks/externalApi/line/useFetchLiffUrlByRestaurantId";

export const LineSegmentCoupon: React.FC = () => {
  const { restaurantId } = useParams()
  const {isLoading, error} = useFetchLiffUrlByRestaurantId(restaurantId, 'membership')
  if (error) return <AlertMsg msg={error.response.data.detail}/>
  if (isLoading) return <CircularLoading />
  return (
    <LineSegmentDeliveryLayout FormComponent={LineSegmentCouponForm} />
  );
}