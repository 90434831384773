import React, { memo, useEffect, useMemo, useState } from "react";
import { useFetchCustomerVisitCount } from "../../hooks/externalApi/line/useFetchCustomerVisitCount";
import { DisplayMenu } from "./menu/DisplayMenu";
import { CustomerSoCoupon } from "./soCoupon/CustomerSoCoupon";

import { Roulette, useRoulette } from 'react-hook-roulette';
import { Box, Button, Center, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { CouponRoulette } from "./games/roulette/CouponRoulette";
import { PostRouletteOverallScore } from "../organisms/games/roulette/PostRouletteOverallScore";
import { useSetRecoilState } from "recoil";
import { lineAccountIdState } from "../../store/fetchedData/lineAccountIdState";
import { CustomerValidCoupons } from "./membership/CustomerValidCoupons";
import { customerRestaurantId } from "../../store/customerRestaurantId";
import { lineProfileState } from "../../store/lineProfileState";
import { CustomerValidCoupon } from "./membership/CustomerValidCoupon";

export const Test2: React.FC = memo(() => {
  const setCustomerRestaurantId = useSetRecoilState(customerRestaurantId)
  const setLineProfile = useSetRecoilState(lineProfileState)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setIsLoading(true)
    setLineProfile({
      userId: 'U30b4d78cebc502e7243046b8a04de34b',
      displayName: 'test',
      pictureUrl: 'https://pbs.twimg.com/profile_images/1478652274/test_400x400.png'
    })
    setCustomerRestaurantId('24254f5e-1e99-4ef7-b281-f290ce9b83f9')
    setIsLoading(false)
  }, [])
  if (isLoading) {
    return null
  }
  return (
    <CustomerValidCoupon/>
  );
});