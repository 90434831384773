import QRCode from "qrcode.react";
import { Box, CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { MdOutlineQrCode2 } from "react-icons/md";
import { useFetchLiffUrlByRestaurantId } from "../../hooks/externalApi/line/useFetchLiffUrlByRestaurantId";
import { AlertMsg } from "../molecules/AlertMsg";
import { CircularLoading } from "../molecules/loading/CircularLoading";
import { DarkShadowCard } from "../molecules/card/DarkShadowCard";
import { CardHeading } from "../molecules/card/CardHeading";
import { QrCordDownloadModal } from "../molecules/QrCordDownloadModal";


type QrCodeDownLoaderProps = {
  liffType: string
}
export const QrCodeDownLoader = ({liffType}: QrCodeDownLoaderProps) => {
  const { restaurantId } = useParams()
  const {liffUrl, isLoading, error} = useFetchLiffUrlByRestaurantId(restaurantId, liffType)
  if (error) return <AlertMsg msg={error.response.data.detail}/>
  if (isLoading) return <CircularLoading />
  const liffUrlWithParams = `${liffUrl}/${restaurantId}`
  return (
    <Box w='500px' maxW='100%' m='auto'>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<MdOutlineQrCode2 size='20px' />} heading='QRコード'/>
        </CardHeader>
        <CardBody>
          <QrCord url={liffUrlWithParams} />
        </CardBody>
        <CardFooter>
          <QrCordDownloadModal url={liffUrlWithParams} />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
};

const QrCord: React.FC<{url: string}> = ({url}) => {
  return (
    <Box
      as={QRCode}
      value={url}
      m='auto'
      sx={{
        width: '200px!important',
        height: '200px!important',
        objectFit: 'contain',
      }}
    />
  );
};