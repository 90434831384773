import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Input, Skeleton, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { useFetchSalesmanLinking } from "../../../hooks/ifreaAnalytics/useFetchSalesmanLinking";
import { useEditSalesmanLinkingForm } from "../../../hooks/ifreaAnalytics/useEditSalesmanLinkingForm";
import { useEditSalesmanLinking } from "../../../hooks/ifreaAnalytics/useEditSalesmanLinking";


export const EditSalesmanLinking = () => {
  const { salesmanLinkingId } = useParams();
  const { salesmanLinking, isLoading } = useFetchSalesmanLinking(salesmanLinkingId);
  const { handleSubmit, register, isSubmitting, errors } = useEditSalesmanLinkingForm(salesmanLinking)
  const onSubmit = useEditSalesmanLinking(salesmanLinkingId)
  const ifreaAnalyticsSalesmanIdError = errors.ifrea_analytics_salesman_id?.message;
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='ID紐づけ'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.ifrea_analytics_salesman_id} w='100%'>
                <FormLabel>ifreaアナリティクス営業マンID</FormLabel>
                <Skeleton isLoaded={!isLoading} w='100%' borderRadius={5}>
                  <Input type="text" {...register('ifrea_analytics_salesman_id')} />
                </Skeleton>
                <FormErrorMessage>{ifreaAnalyticsSalesmanIdError}</FormErrorMessage>
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};