import React, { memo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import styled from 'styled-components';
import { Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { RatingAndProgressRow } from '../molecules/RatingAndProgressRow';

type StarRateScoreLayoutProps = {
  prefix: string,
  props: any,
}

const SRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#FFA500',
  },
  fontSize: '2rem !important',
});

export const StarRateScoreLayout: React.FC<StarRateScoreLayoutProps> = memo(({ prefix, props }) => {
  const theme = createTheme();

  return (
    <>
      <VStack spacing='6px'>
        <ThemeProvider theme={theme}>
          <SRating name="half-rating-read" value={props[`${prefix}_average`]} precision={0.1} readOnly />
        </ThemeProvider>
        <HStack spacing='20px'>
          <Text fontSize='sm' as='b'>平均スコア {props[`${prefix}_average`]}</Text>
          <Text fontSize='sm' as='b'>回答数 {props[`${prefix}_count`]}</Text>
        </HStack>
      </VStack>
      <Divider />
      {[5, 4, 3, 2, 1].map(score => {
        const countKey = `${prefix}_${score}_count`;
        const percentageKey = `${prefix}_${score}_percentage`;
        return (
          <RatingAndProgressRow
            key={score}
            count={props[countKey]}
            percentage={props[percentageKey]}
            score={score}
          />
        );
      })}
    </>
  );
});