import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";
import { useErrorToast } from "../toast/useErrorToast";


export const useSubmitSoCouponSurveySelectedQuestionsEdit = (restaurantId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();

  const onSubmit = async (data: any) => {
    if (data.questions.length === 0) return showErrorToast('1つ以上選択してください');
    try {
      await customAuthAxios.post(
        `socoupon/restaurants/${restaurantId}/survey/questions/selected/`,
        data
      );
      showToast('登録が完了しました');
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        showErrorToast(error.response.data.detail)
      }
    }
  };
  return onSubmit;
};