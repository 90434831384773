import useSWR from "swr";

type LineAccount = {
  id: string;
  customer_sex: string;
  customer_birth_year: string;
  customer_birth_month: string;
  line_id: string;
  display_name: string;
  picture_url?: string;
  visit_count: number;
  registered_date: string;
  last_visit: string;
  is_active: boolean;
  blocked_date: null | string;
  customer: string;
  restaurant: string;
};

type SoCouponSurveyAnswerTexts = {
  id: string;
  line_account: LineAccount;
  answer_text: string;
  answered_at: string;
}[];

export const useFetchSoCouponLatestSurveyAnswerTexts = (restaurantId: string) => {
  const { data: soCouponLatestSurveyAnswerTexts, error, isLoading } = useSWR<SoCouponSurveyAnswerTexts>(`socoupon/survey/texts/latest/?restaurantId=${restaurantId}`)
  return { soCouponLatestSurveyAnswerTexts, isLoading, error };
};