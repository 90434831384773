import useSWR from "swr";

type SelectedQuestions = {
  id: string;
  restaurant: string;
  question: string;
  question_content: string;
  order: number;
}[]

export const useFetchKuchikomyuSurveySelectedQuestionsByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `kuchikomyu/restaurants/${restaurantId}/survey/questions/selected/` : null;
  const { data: selectedQuestions, error, isLoading } = useSWR<SelectedQuestions>(fetcherKey)
  return { selectedQuestions, isLoading, error };
};