export const formatDate = (inputDate:string) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}/${month}/${day}/${hours}:${minutes}`;
};

export const formatMonth = (inputDate:string) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  return `${year}/${month}`;
};

export const formatExpirationDate = (expirationDate:string) => {
  const specifiedDate = new Date(expirationDate);
  const year = specifiedDate.getFullYear();
  const month = specifiedDate.getMonth() + 1;
  const day = specifiedDate.getDate();
  return `${year}年${month}月${day}日`;
};
