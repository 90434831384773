import { memo } from 'react';
import { Box, CardBody, CardHeader, Grid, GridItem, HStack, Image, Link, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { CircularLoading } from '../../molecules/loading/CircularLoading';
import { useParams, useSearchParams } from 'react-router-dom';
import { DarkShadowCard } from '../../molecules/card/DarkShadowCard';
import { BarGraph } from '../../molecules/graph/BarGraph';
import { Skeletons } from '../../atoms/Skeletons';
import { StarRateSurveyScoreLayout } from '../../template/StarRateSurveyScoreLayout';
import { Link as RouterLink } from 'react-router-dom';
import { useFetchRestaurantSurveyResponsesTrend } from '../../../hooks/restaurantStaff/useFetchRestaurantSurveyResponsesTrend';
import { useFetchRestaurantSurveySelectedQuestions } from '../../../hooks/restaurantStaff/useFetchRestaurantSurveySelectedQuestions';
import { useFetchRestaurantSurveyRankingTopScores } from '../../../hooks/restaurantStaff/useFetchRestaurantSurveyRankingTopScores';


export const RestaurantDashboardTab = () => {
  const [searchParams] = useSearchParams();
  const since = searchParams.get('since');
  const until = searchParams.get('until');
  const queryParams = buildQueryParams(since, until);
  const { restaurantId } = useParams()
  if (!restaurantId) return <CircularLoading />
  return (
    <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'}} gap={3} w='100%'>
      <GridItem>
        <StaffTopRanking restaurantId={restaurantId} queryParams={queryParams} />
      </GridItem>
      <GridItem>
        <SurveyResponsesTrendGraph restaurantId={restaurantId} queryParams={queryParams} />
      </GridItem>
    </Grid>
  );
};


const buildQueryParams = (since: string|null, until: string|null) => {
  const queryParams = new URLSearchParams();
  since && queryParams.set('since', since);
  until && queryParams.set('until', until);
  return queryParams;
};


const StaffTopRanking = memo(({restaurantId, queryParams}: {restaurantId?: string, queryParams: URLSearchParams}) => {
  return (
    <DarkShadowCard w='100%'>
      <CardHeader pb={0}>
        <HStack justify='space-between'>
          <Text m={0} fontSize='lg' fontWeight='bold'>スタッフランキング</Text>
          <Link as={RouterLink} to='../ranking' color='gray.500' _hover={{ color: 'gray.400' }}>
            <Text m={0} fontSize='sm' fontWeight='bold'>詳細</Text>
          </Link>
        </HStack>
      </CardHeader>
      <CardBody>
        <QuestionTabs restaurantId={restaurantId} queryParams={queryParams} />
      </CardBody>
    </DarkShadowCard>
  );
});


const QuestionTabs = ({restaurantId, queryParams}: {restaurantId?: string, queryParams: URLSearchParams}) => {
  const { selectedQuestions, isLoading } = useFetchRestaurantSurveySelectedQuestions(restaurantId)
  if (isLoading) return null
  return (
    <Tabs variant='soft-rounded' colorScheme='green' isLazy w='100%' size='sm'>
      <TabList overflowX='scroll'>
        {selectedQuestions?.map((question) => (
          <Tab key={question.id} flexShrink={0}>{question.question_content}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {selectedQuestions?.map((question) => (
          <TabPanel key={question.id} px={0}>
            <RankingCards restaurantId={restaurantId} questionId={question.question} queryParams={queryParams} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};


const RankingCards = memo(({restaurantId, questionId, queryParams}:{restaurantId?: string, questionId?: string, queryParams: URLSearchParams}) => {
  const { scores, isLoading } = useFetchRestaurantSurveyRankingTopScores(restaurantId, questionId, queryParams);
  if (isLoading) return <Grid templateColumns='repeat(1, 1fr)' gap={3} w='100%'><Skeletons length={1} h='280px' rounded='2xl' /></Grid>
  if (!scores || scores.length===0) return null
  return (
    <Grid templateColumns='repeat(1, 1fr)' gap={3} w='100%' >
      {scores.map((score, i) => {
        const rank = i + 1;
        const medalColor = rank === 1 ? 'gold' : rank === 2 ? 'silver' : rank === 3 ? 'bronze' : 'gray';
        return (
          <GridItem key={score.id} w='100%'>
            <DarkShadowCard >
              <CardHeader pb={2}>
                <HStack justify='space-between'>
                  <HStack>
                    <Image src={score.img} alt='staff' fallbackSrc='/images/fallback-user.png' boxSize='34px' rounded='md' />
                    <Text noOfLines={1} m={0} fontWeight='bold'>{score.name}</Text>
                  </HStack>
                  <HStack>
                    <Box bgGradient='linear(to-l, #FE964E, #FF0080)' bgClip='text' fontSize='lg' fontWeight='bold'>
                      {score.total_acquisitions}P
                    </Box>
                    <Image src={`/images/medals/${medalColor}-medal.png`} alt='medal' boxSize='32px' />
                  </HStack>
                </HStack>
              </CardHeader>
              <CardBody pt={0}>
                <StarRateSurveyScoreLayout {...score} />
              </CardBody>
            </DarkShadowCard>
          </GridItem>
        );
      })}
    </Grid>
  );
});


const SurveyResponsesTrendGraph = memo(({restaurantId, queryParams}: {restaurantId?: string, queryParams: URLSearchParams}) => {
  const { surveyResponsesTrend } = useFetchRestaurantSurveyResponsesTrend(restaurantId, queryParams);
  const labels = surveyResponsesTrend?.map((response) => response.date);
  const values = surveyResponsesTrend?.map((response) => response.count);
  return (
    <DarkShadowCard w='100%'>
      <CardHeader pb={0}>
        <Text m={0} fontSize='lg' fontWeight='bold'>アンケート取得数推移</Text>
      </CardHeader>
      <CardBody py={2}>
        <BarGraph labels={labels??[]} values={values??[]} seriesName='取得数' />
      </CardBody>
    </DarkShadowCard>
  );
});
