import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { memo } from "react"
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { RankCardPreview } from "../../organisms/membership/RankCardPreview";
import { useParams } from "react-router-dom";
import { useFetchRestaurant } from "../../../hooks/restaurants/useFetchRestaurant";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const RankCardSettings: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const {restaurant, isLoading} = useFetchRestaurant(restaurantId)
  if (isLoading || !restaurant) return null
  return (
    <Box m='auto' w={{base: '100%', md: '500px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='ランクカード設定'/>
        </CardHeader>
        <CardBody py={2}>
          <VStack spacing={1}>
            <Box m='auto' h={{base: '50vw', sm: '215px'}} w={{base: '80vw', sm: '344px'}}>
              <RankCardPreview restaurantId={restaurant.id} restaurantLogo={restaurant.logo} />
            </Box>
            <Box m='auto' w={{base: '80vw', sm: '344px'}} fontSize='sm'>※縦横比 8:5</Box>
          </VStack>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集' />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});