import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type Restaurant = {
  id: string;
  name: string;
}

export const useFetchCustomerRestaurant = (restaurantId: string) => {
  const { data: restaurant, error, isLoading } = useSwrNoAuth<Restaurant>(`accounts/restaurants/${restaurantId}/customer-restaurant/`)
  return { restaurant, isLoading, error };
};