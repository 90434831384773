import useSWR from "swr";

type LineAccount = {
  id: string;
  customer: string;
  restaurant: string;
  line_id: string;
  display_name: string;
  picture_url: string;
  visit_count: string;
  last_visit: string;
  registered_date: string;
  customer_sex : string;
  customer_birth_year : string;
  customer_birth_month : string;
}
export type LineAccountPerPage = {
  count: number;
  line_accounts: LineAccount[]
}

export const useFetchLineAccountsPerPage = (pageSize: number, currentPage: number, ord: string|null, searchTargetName: string|null, restaurantId?: string,) => {
  const fetcherKey = restaurantId ? `external_api/restaurants/${restaurantId}/line/accounts/per-page/?pageSize=${pageSize}&currentPage=${currentPage}&ord=${ord}&targetName=${searchTargetName}` : null;
  const { data: lineAccounts, isLoading, error } = useSWR<LineAccountPerPage>(fetcherKey)
  return { lineAccounts, isLoading, error };
};