import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";

const tableSchema = yup.object().shape({
  table_name: yup.string().required('入力必須項目です'),
});

type Table = {
  table_name: string;
}
export const useTableEditForm = (table?: Table) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue, } = useForm({resolver: yupResolver(tableSchema),});

  useEffect(() => {
    if (table) {
      setValue('table_name', table.table_name);
    }
  }, [table]);

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};