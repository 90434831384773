import { useParams } from "react-router-dom";
import { useEditPasswordForm } from "../../../hooks/useEditPasswordForm";
import { Box } from "@chakra-ui/react";
import { EditPasswordForm } from "../../molecules/form/EditPasswordForm";
import { useEditCorporationPass } from "../../../hooks/corporations/useEditCorporationPass";


export const EditCorporationPassword = () => {
  const { corporationId } = useParams();
  const { handleSubmit, register, isSubmitting, errors } = useEditPasswordForm();
  const onSubmit = useEditCorporationPass(corporationId!);
  return (
    <Box m='auto' w={{base: '100%', md: '500px'}}>
      <EditPasswordForm handleSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} register={register} errors={errors} />
    </Box>
  );
};