import { useRecoilValue } from "recoil";
import { totalState } from "../../store/totalState";
import { useCustomPagination } from "../useCustomPagenation";
import { useUpdateTotal } from "../useUpdateTotal";
import { useFetchManLaboQuestionWithLineAccountsPerPage } from "./useFetchManLaboQuestionWithLineAccountsPerPage";

export const useFetchPaginateManLaboQuestionWithLineAccounts = (questionId: string) => {
  const total = useRecoilValue(totalState);
  const { pages, pagesCount, currentPage, setCurrentPage, pageSize } = useCustomPagination(total, 20);
  const { manLaboQuestionWithLineAccountsPerPage, isLoading } = useFetchManLaboQuestionWithLineAccountsPerPage(questionId, pageSize, currentPage)
  useUpdateTotal(manLaboQuestionWithLineAccountsPerPage);

  return { manLaboQuestionWithLineAccountsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage };
};