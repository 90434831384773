import { useSetRecoilState } from "recoil";
import { googleReviewsDateRangeState } from "../../../store/googleReviewsDateRangeState";
import { googleReviewRatingState } from "../../../store/googleReviewRatingState";
import { googleReviewReplyState } from "../../../store/googleReviewReplyState";
import { useNavigate } from "react-router-dom";

export const useResetReviewFilter = () => {
  const navigate = useNavigate()
  const setDateRange = useSetRecoilState(googleReviewsDateRangeState);
  const setReviewRating = useSetRecoilState(googleReviewRatingState);
  const setReviewReplyState = useSetRecoilState(googleReviewReplyState);
  const resetFilter = () => {
    navigate('../../google/reviews')
    setDateRange({ since: null, until: null })
    setReviewRating(null)
    setReviewReplyState(null)
  }
  return resetFilter
};