import React from 'react'
import { FormControl, FormErrorMessage, FormLabel, HStack, Input } from '@chakra-ui/react'

type FullNameInputProps = {
  register: any;
  errors: any;
}

export const FullNameInput: React.FC<FullNameInputProps> = ({register, errors}) => {
  return (
    <HStack w='100%'>
      <FormControl isRequired isInvalid={!!errors.last_name}>
        <FormLabel>性</FormLabel>
          <Input placeholder="山田" type="input" {...register('last_name')} />
        <FormErrorMessage>{errors.last_name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!errors.first_name}>
        <FormLabel>名</FormLabel>
          <Input placeholder="太郎" type="input" {...register('first_name')} />
        <FormErrorMessage>{errors.first_name?.message}</FormErrorMessage>
      </FormControl>
    </HStack>
  );
};