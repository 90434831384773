import { useEffect } from "react";
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";


export const useGiveCoupon = (couponId: string, lineAccountId: string|null) => {
  useEffect(() => {
    if (!lineAccountId) return
    const giveCoupon = async () => {
      await customNoAuthAxios.post(`games/roulette/coupon/holders/`, {
        line_account: lineAccountId,
        roulette_coupon: couponId,
      });
    };
    giveCoupon();
  }, [couponId, lineAccountId]);
};