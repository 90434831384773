import { useParams } from "react-router-dom";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { AlertMsg } from "../../../molecules/AlertMsg";
import { useFetchGoogleSettingByRestaurantId } from "../../../../hooks/externalApi/google/useFetchGoogleSettingByRestaurantId";
import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Input, Text, Textarea, VStack } from "@chakra-ui/react";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { SubmitBtn } from "../../../atoms/button/SubmitBtn";
import { usePostBusinessLatestInfoForm } from "../../../../hooks/externalApi/google/usePostBusinessLatestInfoForm";
import { usePostBusinessLatestInfo } from "../../../../hooks/externalApi/google/usePostBusinessLatestInfo";
import { useState } from "react";
import { MdOutlinePostAdd } from "react-icons/md";


export const PostBusinessLatestInfo = () => {
  const { restaurantId } = useParams()
  const { handleSubmit, errors, register, isSubmitting, reset } = usePostBusinessLatestInfoForm();
  const { onSubmit } = usePostBusinessLatestInfo(restaurantId!, reset);
  const [summary, setSummary] = useState('');
  const { isLoading, error } = useFetchGoogleSettingByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />
  if (error && error.response.status === 404) return <AlertMsg msg={error.response.data.message}/>;
  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" w={{base: '100%', lg: '700px'}} m='auto'>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<MdOutlinePostAdd fontSize='20px' />} heading='最新情報投稿'/>
        </CardHeader>
        <CardBody py={2}>
          <VStack align='start' spacing={{base: 4, sm: 6}} w='100%'>
            <FormControl isRequired isInvalid={!!errors.img}>
              <FormLabel>画像</FormLabel>
              <input accept="image/*" type="file" {...register('img')} />
              <FormErrorMessage>{errors.img?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.summary}>
              <FormLabel>説明</FormLabel>
              <Textarea {...register('summary')} maxLength={1500} onChange={(e) => setSummary(e.target.value)} />
              <Text color='gray.500' fontSize='sm' textAlign='right' m='0'>{summary.length} / 1500</Text>
              <FormErrorMessage>{errors.summary?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.action_button_url}>
              <FormLabel>ボタンのリンク</FormLabel>
              <Input type='url' {...register('action_button_url')} maxLength={1500} />
              <FormErrorMessage>{errors.action_button_url?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </CardBody>
        <CardFooter>
          <SubmitBtn isLoading={isSubmitting}>投稿</SubmitBtn>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
};