import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchKuchikomyuSettingCustomerByRestaurantId } from "../../../hooks/kuchikomyu/useFetchKuchikomyuSettingCustomerByRestaurantId";
import { Box } from "@chakra-ui/react";
import { useSubmitKuchikomyuCustomerOverallScore } from "../../../hooks/kuchikomyu/useSubmitKuchikomyuCustomerOverallScore";
import { usePostOverallScoreForm } from "../../../hooks/usePostOverallScoreForm";
import { PostOverallScoreForm } from "../../molecules/form/PostOverallScoreForm";


export const PostKuchikomyuOverallScore: React.FC = memo(() => {
  const params = useParams();
  const { restaurantId } = params;
  const { kuchikomyuSetting, isLoading } = useFetchKuchikomyuSettingCustomerByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />
  if (!kuchikomyuSetting) return null
  return (
    <Box p={4}>
      <ScorePost restaurantId={restaurantId!} branchScore={kuchikomyuSetting.branch_score} gUrl={kuchikomyuSetting.g_url} />
    </Box>
  );
});


const ScorePost: React.FC<{restaurantId: string, branchScore: number, gUrl: string}> = memo(({restaurantId, branchScore, gUrl}) => {
  const { handleSubmit, control, isSubmitting, errors } = usePostOverallScoreForm()
  const onSubmit = useSubmitKuchikomyuCustomerOverallScore(restaurantId!, branchScore, gUrl);
  return <PostOverallScoreForm onSubmit={handleSubmit(onSubmit)} control={control} isSubmitting={isSubmitting} errors={errors}/>
});