import React, { memo } from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormErrorMessage, FormLabel, Skeleton } from "@chakra-ui/react";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { useFetchCorporations } from "../../../hooks/corporations/useFetchCorporations";


type SelectCorporationProps = {
  control: any;
  errors: any;
}
export const SelectCorporation: React.FC<SelectCorporationProps> = memo(({control, errors}) => {
  const { corporations, isLoading } = useFetchCorporations()
  if (isLoading||!corporations) return null
  const corporationOption = corporations.map((corporation) => ({
    value: corporation.id,
    label: corporation.name
  }));
  const corporationError = errors.restaurant?.corporation?.message;
  return (
    <FormControl isRequired isInvalid={errors.restaurant?.corporation} w='100%'>
      <FormLabel>法人</FormLabel>
      <Skeleton isLoaded={!isLoading} rounded='md'>
        <Controller
          name='restaurant.corporation'
          control={control}
          render={({ field }) => (
            <ReactSelectWithChakraStyle
              noOptionsMessage={({inputValue}) => !inputValue && '法人が存在しません'}
              placeholder="選択"
              options={corporationOption}
              value={corporationOption?.find((x) => x.value === field.value)}
              onChange={(newValue:any) => {
                field.onChange(newValue?.value);
              }}
              instanceId="restaurant-corporation-select"
            />
          )}
          />
      </Skeleton>
      <FormErrorMessage>{corporationError}</FormErrorMessage>
    </FormControl>
  );
});