import useSWR from "swr";


type KuitanSetting = {
  id: string;
  tabelog_url: string;
}
export const useFetchKuitanSetting = (settingsId?: string) => {
  const fetcherKey = settingsId ? `kuitan/settings/${settingsId}` : null;
  const { data: setting, error, isLoading } = useSWR<KuitanSetting>(fetcherKey)
  return { setting, isLoading, error };
};