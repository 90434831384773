import React, { memo } from "react";
import { Grid, Radio, RadioGroup } from "@chakra-ui/react"
import { MultipliedText } from "../atoms/MultipliedText";

type RatingFilterProps = {
  reviewRating: string | null;
  setReviewRating: (reviewRating: string) => void;
}
export const RatingFilter: React.FC<RatingFilterProps> = memo(({ reviewRating, setReviewRating }) => {
  const ratings = [5, 4, 3, 2, 1];
  return (
    <RadioGroup onChange={setReviewRating} defaultValue={reviewRating ?? ''}>
      <Grid templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(5, 1fr)' }} gap={{ base: 2, md: 5 }} color='orange'>
        {ratings.map(rating => (
          <Radio key={rating} m='0' value={rating.toString()} spacing={1}>
            <MultipliedText text="★" count={rating}/>
          </Radio>
        ))}
      </Grid>
    </RadioGroup>
  );
});
