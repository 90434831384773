import { useState } from "react";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";
import { useRecoilValue } from "recoil";
import { customerRestaurantId } from "../../store/customerRestaurantId";

export const useChangeSoCouponStateByLineId = (couponId: string, lineId: string, onClose: () => void) => {
  const restaurantId = useRecoilValue(customerRestaurantId)
  const [isUsed, setIsUsed] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const markCouponAsUsed = async () => {
    const data = {lineId: lineId, restaurantId: restaurantId}
    await customNoAuthAxios.post(
      `socoupon/socoupons/${couponId}/mark-as-used/line-id/`,
      data,
    );
  }

  const handleOnClick = async () => {
    setIsSubmitting(true)
    try {
      await markCouponAsUsed();
      setIsUsed(true)
      onClose()
    } catch (error) {
    } finally {
      setIsSubmitting(false)
    }
  }

  return { isUsed, isSubmitting, handleOnClick }
}

