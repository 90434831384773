import { WarningTwoIcon } from "@chakra-ui/icons";
import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";


export const NotFound = () => {
  return (
    <Box h='100vh'>
    <VStack spacing={5} align='center' justify='center' h='100%'>
      <Image src='/images/blocks.png' alt='500' boxSize='200px' />
      <HStack fontSize='24px' justify='center' spacing={2}>
        <WarningTwoIcon boxSize='28px' color='#FFC007' />
        <Text m='0' fontWeight='bold'>ページが存在しません（404）</Text>
      </HStack>
      <Text m='0' textAlign='center'>
        操作に誤りがありました。
        以下の可能性があります。
      </Text>
      <Text m='0'>
        入力内容が正しくない
        <br/>
        リンクが壊れている
        <br/>
        ページが移動した
      </Text>
    </VStack>
    </Box>
  );
};