import { memo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Skeleton } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { useFetchSalesmen } from "../../../hooks/salesmen/useFetchSalesmen";


export const SelectSalesman = memo(({control, errors}:{control: any, errors: any}) => {
  const { salesmen, isLoading } = useFetchSalesmen()
  const salesmanOption = salesmen?.map((salesman) => ({
    value: salesman.id,
    label: salesman.full_name
  }));
  const salesmanError = errors.corporation?.salesman?.message;
  return (
    <FormControl isRequired isInvalid={errors.corporation?.salesman} w='100%'>
      <FormLabel>営業担当</FormLabel>
      <Skeleton isLoaded={!isLoading} rounded='md'>
        <Controller
          name='corporation.salesman'
          control={control}
          render={({ field }) => (
            <ReactSelectWithChakraStyle
              noOptionsMessage={({inputValue}) => !inputValue && '営業担当が存在しません'}
              placeholder="選択"
              options={salesmanOption}
              value={salesmanOption?.find((x) => x.value === field.value)}
              onChange={(newValue:any) => {
                field.onChange(newValue?.value);
              }}
              instanceId="corporation-salesman-select"
            />
          )}
        />
      </Skeleton>
      <FormErrorMessage>{salesmanError}</FormErrorMessage>
    </FormControl>
  );
});