import jsQR from 'jsqr';


export const getQrCodeUrl = async (qrCodeImageUrl: string) => {
  const response = await fetch(qrCodeImageUrl);
  const blobUrl = await CreateBlobUrlForQrCode(response);
  const qrCodeUrlData = await extractQrCodeUrl(blobUrl);
  if (!qrCodeUrlData) throw new Error("QR code data not found");
  return qrCodeUrlData.data;
};

const CreateBlobUrlForQrCode = async (response: Response) => {
  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
};
const extractQrCodeUrl = async (blobUrl: string) => {
  try {
    const image = new Image();
    image.src = blobUrl;

    await new Promise((resolve, reject) => {
      image.onload = resolve;
      image.onerror = reject;
    });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) throw new Error("Failed to get canvas context");

    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0, image.width, image.height);

    const imageData = context.getImageData(0, 0, image.width, image.height);
    const qrCodeUrlData = jsQR(imageData.data, imageData.width, imageData.height);
    return qrCodeUrlData;

  } catch (error) {
    console.error('Error processing QR code image:', error);
  }
};
