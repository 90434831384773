import React from 'react';
import { Box } from '@chakra-ui/react';

type SidebarNavWrapperProps = {
  children: React.ReactNode
}
export const SidebarNavWrapper: React.FC<SidebarNavWrapperProps> = ({children}) => {
  return (
    <Box
      as="nav"
      pt={2}
      overflowY='scroll'
      h='calc(100vh - 108px)'
      sx={{
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
      }}
    >
      {children}
    </Box>
  );
};