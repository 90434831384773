import useSWR from "swr";

type IfreaAnalyticsSetting = {
  id: string;
  ifrea_analytics_restaurant_id: string;
}

export const useFetchIfreaAnalyticsSetting = (settingsId?: string) => {
  const fetcherKey = settingsId ? `ifrea_analytics/settings/${settingsId}` : null;
  const { data: setting, error, isLoading } = useSWR<IfreaAnalyticsSetting>(fetcherKey)
  return { setting, isLoading, error };
};