import React, { memo } from "react";
import { Text, VStack } from '@chakra-ui/react'


export const StampCardTop: React.FC = memo(() => {
  return (
    <VStack spacing={0}>
      <Text fontSize={{ base: '5.2vw', sm: '22px' }} fontWeight='bold' m='0'>スタンプカード</Text>
      <Text fontSize={{ base: '2.4vw', sm: '10px' }} fontWeight='bold' m='0'>来店回数に応じてスタンプが押されていきます。<br/>スタンプを集めて会員ランクをアップさせよう！</Text>
    </VStack>
    );
});