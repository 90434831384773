import { Box, Button, Flex, Image } from "@chakra-ui/react";
import React from "react";

export const LineUsageGuide: React.FC  = () => {

  return (
    <Box pt='60px' bgColor='white' height='100vh'>
      <Image src="/images/line-usage-guide.png" alt="line-usage-guide" />
      <br />
      <Flex justifyContent='center'>
        <Button
          size='lg'
          w='60%'
          colorScheme="whatsapp"
          onClick={() => {
            window.location.href = "https://line.me/R/";
          }}
        >
          LINEを開く
        </Button>
      </Flex>
    </Box>
  );
};

