import { Box } from "@chakra-ui/react";
import React, { memo } from "react";

type ErrorMessageProps = {
  errorMessage: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = memo(({errorMessage}) => {
  return (
    <Box color='#E53E3E' fontSize='14px' fontWeight='bold'>{errorMessage}</Box>
  );
});