import useSWR from "swr";


type CustomerServiceRating = {
  [key: string]: number;
}
export const useFetchCustomerServiceRating = (restaurantId?: string,) => {
  const fetcherKey = restaurantId ? `kuitan/restaurants/${restaurantId}/ratings/customer-service/` : null
  const { data: customerServiceRating, error, isLoading } = useSWR<CustomerServiceRating>(fetcherKey)
  return { customerServiceRating, isLoading, error };
};