import { useExtractRestaurantIdFromUrl } from "../../../../hooks/externalApi/line/useExtractRestaurantIdFromUrl";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { LiffLoginProcess } from "../../../organisms/LiffLoginProcess";
import { PostRouletteOverallScore } from "../../../organisms/games/roulette/PostRouletteOverallScore";


export const RouletteLiffApp = () => {
  const restaurantId = useExtractRestaurantIdFromUrl()
  if (restaurantId === null) return <CircularLoading />
  return (
    <LiffLoginProcess restaurantId={restaurantId} liffType="roulette">
      <PostRouletteOverallScore restaurantId={restaurantId} />
    </LiffLoginProcess>
  );
};