import { useNavigate } from "react-router-dom";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";

export const useSubmitKuchikomyuCustomerOverallScore = (restaurantId: string, branchScore: number, gUrl: string) => {
  const navigate = useNavigate();

  const onSubmit = async (data:any) => {
    try {
      const res = await customNoAuthAxios.post(
        'kuchikomyu/overall-score/customer/',
        {restaurant_id: restaurantId, score: data.score},
      );
      if (data.score && data.score > branchScore) {
        window.location.href = gUrl;
      } else {
        navigate(`/kuchikomyu/survey/${restaurantId}`);
      }
    } catch (error) {
    } finally {
    }
  }

  return onSubmit;
};