import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";


export const useRegisterSalesmanLinking = (salesmanId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    data.salesman = salesmanId;
    try {
      await customAuthAxios.post(`/ifrea_analytics/salesman-linkings/`, data)
      showToast('登録が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};