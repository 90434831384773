import React, { memo } from 'react';
import { VStack } from '@chakra-ui/react';
import { TopSoCouponAnalysisCards } from '../../organisms/soCoupon/TopSoCouponAnalysisCards';
import { CenterSoCouponAnalysisCards } from '../../organisms/soCoupon/CenterSoCouponAnalysisCards';
import { SoCouponQuestionScores } from '../../organisms/soCoupon/SoCouponQuestionScores';
import { CircularLoading } from '../../molecules/loading/CircularLoading';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { soCouponDateRangeState } from '../../../store/soCouponDateRangeState';
import { DateFilterSection } from '../../organisms/DateFilterSection';


export const SoCouponScores: React.FC = memo(() => {
  const setDateRange = useSetRecoilState(soCouponDateRangeState);
  const { restaurantId } = useParams()
  if (!restaurantId) return <CircularLoading />
  return (
    <VStack w='100%' spacing={5}>
      <DateFilterSection setDateRange={setDateRange} />
      <TopSoCouponAnalysisCards restaurantId={restaurantId} />
      <CenterSoCouponAnalysisCards restaurantId={restaurantId} />
      <SoCouponQuestionScores restaurantId={restaurantId} />
    </VStack>
  );
});