import { useNavigate } from "react-router-dom";
import { useSuccessToast } from "../../toast/useSuccessToast";
import { customAuthAxios } from "../../../utils/customAuthAxios";


export const useRegisterRouletteSetting = (restaurantId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    data.restaurant = restaurantId;
    try {
      await customAuthAxios.post(`games/roulette/settings/`, data)
      showToast('登録が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};