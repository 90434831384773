import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const managerRegisterSchema = yup.object().shape({
  user: yup.object().shape({
    username: yup.string().required('ユーザーネームを入力してください'),
    password: yup.string().required('パスワードを入力してください').test('password-length', 'パスワードは4文字以上で入力してください', value => value.length >= 4),
  }),
  manager: yup.object().shape({
    name: yup.string().required('必須項目です'),
    restaurants: yup.array().of(yup.string()).required('必須項目です'),
  }),
});
export const useManagerRegisterForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, control } = useForm({
    resolver: yupResolver(managerRegisterSchema),
  });
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
    control,
  };
};