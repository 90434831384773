import React, { memo } from "react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { UserUsernameInput } from "../../molecules/form/UserUsernameInput";
import { useParams } from "react-router-dom";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { FaUserTie } from "react-icons/fa";
import { CardHeading } from "../../molecules/card/CardHeading";
import { ManagerNameInput } from "../../organisms/managers/ManagerNameInput";
import { useFetchManager } from "../../../hooks/manager/useFetchManager";
import { useSubmitManagerBaseInfoEdit } from "../../../hooks/manager/useSubmitManagerBaseInfoEdit";
import { useManagerBaseInfoEditForm } from "../../../hooks/manager/useManagerBaseInfoEditForm";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const ManagerBaseInfoEdit: React.FC = memo(() => {
  const { managerId } = useParams()
  const { manager, isLoading } = useFetchManager(managerId)
  const { handleSubmit, register, isSubmitting, errors } = useManagerBaseInfoEditForm(manager);
  const onSubmit = useSubmitManagerBaseInfoEdit(manager?.user, managerId);
  if (isLoading || !manager) return <CircularLoading />
  const inputFields: InputField[] = [
    { component: UserUsernameInput, props: { register, errors } },
    { component: ManagerNameInput, props: { register, errors } },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<FaUserTie />} heading='基本情報編集'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={4}>
              {inputFields.map(({ component: C, props }, i) => <C key={i} {...props} />)}
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});