import { memo } from "react"
import { Navigate, Outlet, useParams } from "react-router-dom";
import Footer from "../../organisms/Footer";
import { RestaurantSidebar } from "../../organisms/sidebar/RestaurantSidebar";
import RestaurantHeader from "../../organisms/header/RestaurantHeader";
import Layout from "../Layout";


const RestaurantBase: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const token = localStorage.getItem('localJWT');
  if (!token) return <Navigate replace to="/restaurant-login"/>;
  if (!restaurantId) return null;
  return (
    <Layout
      header={<RestaurantHeader restaurantId={restaurantId}/>}
      nav={<RestaurantSidebar restaurantId={restaurantId}/>}
      main={<Outlet />}
      footer={<Footer />}
    />
  );
});


export default RestaurantBase;