import React, { memo } from "react";
import { Card, CardHeader, CardBody, Text, Box } from '@chakra-ui/react'


type MainScoreCardProps = {
  title: string;
  data: string;
}
export const MainScoreCard: React.FC<MainScoreCardProps> = memo(({title,data}) => {
  return (
    <Card align='center' variant='filled' bgColor='#3679FE' color='white' pt={{base: 3, sm: 3, lg: 3}} pb={{base: 2, sm: 0, lg: 0}} rounded='2xl'>
      <CardHeader p='0'>
        <Text mb={0} fontSize={{base: '2.6vw', sm: '1.6vw', lg: '1.3vw'}} fontWeight='bold'>{title}</Text>
      </CardHeader>
      <CardBody p='0'>
        <Box fontSize={{base: '7vw', sm: '4vw', lg: '3.5vw'}} fontWeight='bold'>{data}</Box>
      </CardBody>
    </Card>
  );
});