import React, { memo } from "react";
import { useDisclosure } from '@chakra-ui/react'
import { useDeleteWithMutateKey } from "../../../hooks/useDeleteWithMutateKey";
import { MenuDeleteModalLayout } from "./MenuDeleteModalLayout";

type MenuDeleteModalProps = {
  id: string;
  mutateKey: string;
}
export const KoreanMenuDeleteModal: React.FC<MenuDeleteModalProps> = memo(({id, mutateKey}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDeleteWithMutateKey(`menu/korean/${id}/`, onClose, mutateKey)
  return (
    <MenuDeleteModalLayout
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
});