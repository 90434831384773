import React, { memo } from "react";
import { Td, Tr } from "@chakra-ui/react";


type RecordProps = {
  childrenList: React.ReactNode[];
  size?: 'xs' | 'sm' | 'md';
}
export const TableRowLayout: React.FC<RecordProps> = memo(({childrenList, size='md'}) => {
  const styles = {
    xs: { px: 2, py: 3, fontSize: '12px' },
    sm: { p: 3, fontSize: '14px' },
    md: { p: 4, fontSize: '14px' },
  };
  const TdStyle = styles[size];
  return (
    <Tr>
      {childrenList.map((children, index) => {
        return <Td key={index} {...TdStyle}>{children}</Td>;
      })}
    </Tr>
  );
});
TableRowLayout.displayName = 'TableRowLayout'