import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";


export const useEditSignUpAuthCode = () => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      const res = await customAuthAxios.put(`kuitan/signup/auth-code/`, data)
      showToast(res.data.message)
      navigate(-1);
    } catch (error: any) {
    }
  };

  return onSubmit;
};