import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const editManLaboSettingByAdminSchema = yup.object().shape({
  branch_score: yup.number().required('ブランチスコアを入力してください'),
  g_url: yup.string().required('Urlを入力してください'),
  is_navigate_g_url: yup.boolean(),
});
type ManLaboSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  is_navigate_g_url: boolean;
}
export const useEditManLaboSettingFormForAdmin = (manLaboSetting?: ManLaboSetting) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(editManLaboSettingByAdminSchema),});
  useEffect(() => {
    if (manLaboSetting) {
      setValue('branch_score', manLaboSetting.branch_score);
      setValue('g_url', manLaboSetting.g_url);
      setValue('is_navigate_g_url', manLaboSetting.is_navigate_g_url);
    }
  }, [manLaboSetting]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};