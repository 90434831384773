import React, {useState, useEffect} from 'react'
import { Box, Center, Flex, FormErrorMessage, FormHelperText, Grid, Image, Text } from '@chakra-ui/react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { CloseIcon } from '@chakra-ui/icons';


type DropZoneProps = {
  control: any
  name: string
  setValue: any
  getValues: any
  errorMessage?: string
};
type FileWithPreview = FileWithPath & { preview: string };
export const DropZone = ({control, name, setValue, getValues, errorMessage}: DropZoneProps) => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const onDeleteFile = (index: number) => {
    const currentFiles = getValues(name) || [];
    setValue(name, currentFiles.length > 1 ? currentFiles.filter((f: FileWithPreview, i: number) => i !== index) : null)
    setFiles(prevFiles => prevFiles.filter((f: FileWithPreview, i: number) => i !== index))
  }
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: (acceptedFiles) => {
      setFiles(prevFiles => [
        ...prevFiles,
        ...acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      ]);
      const currentFiles = getValues(name) || [];
      setValue(name, [...currentFiles, ...acceptedFiles])
    },
    onDropRejected: () => {
    }
  });

  const thumbs = files.map((file, index) => (
    <Thumb key={file.name}>
      <Box overflow="hidden" >
        <Center cursor='pointer' rounded={20} boxSize='20px' bgColor='blackAlpha.500' position='absolute' top={0.5} right={0.5} _hover={{opacity: .9}} onClick={() => onDeleteFile(index)}>
          <CloseIcon boxSize='9px' color='white'/>
        </Center>
        <Image
          src={file.preview}
          display="block"
          width="auto"
          height="100%"
          objectFit='contain'
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </Box>
    </Thumb>
  ));

  useEffect(() => {
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);
  return (
    <Box  w='100%'>
      <Controller
        name={name}
        control={control}
        render={() => (
          <Center cursor='pointer' w='100%' h='160px' bgColor='gray.50' border='dashed' borderColor='gray.300' borderRadius={5} {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Text textAlign='center'>ファイルを選択するか、ドラッグアンドドロップしてください。</Text>
          </Center>
        )}
      />
      <FormHelperText>複数のファイルを選択できます。</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      <Box as='aside' mt={3}>
        <Grid templateColumns='repeat(5, 1fr)' gap={2}>
          {thumbs}
        </Grid>
      </Box>
    </Box>
  );
}


const Thumb = ({ children }: {children: React.ReactNode}) => (
  <Flex
    borderRadius="md"
    border="1px solid"
    borderColor="gray.200"
    boxSize="100px"
    p={1}
    boxSizing="border-box"
    justify='center'
    position='relative'
  >
    {children}
  </Flex>
);