import { memo, useEffect, useState } from "react"
import { Box, CardBody, CardFooter, CardHeader, Checkbox, FormControl, FormHelperText, FormLabel, Grid, Radio, RadioGroup, Switch, Text, VStack } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchMenuSettingByRestaurantId } from "../../../hooks/menu/useFetchMenuSettingByRestaurantId";
import { useMenuSettingEditForm } from "../../../hooks/menu/useMenuSettingEditForm";
import { useSubmitMenuSettingEdit } from "../../../hooks/menu/useSubmitMenuSettingEdit";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const MenuSettingEdit: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { menuSetting, isLoading } = useFetchMenuSettingByRestaurantId(restaurantId)
  const { handleSubmit, register, control, isSubmitting, errors, setValue } = useMenuSettingEditForm(menuSetting)
  const onSubmit = useSubmitMenuSettingEdit(menuSetting?.id)
  if (isLoading) return <CircularLoading />
  if (!menuSetting) return null
  return (
    <Box w={{base: '100%', sm: '100%', md: '600px'}} m='auto'>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='メニュー設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <SelectMenuType control={control} />
              <SwitchFirstSurvey register={register} isFirstSurvey={menuSetting.is_first_survey} />
              <QrCardImgInput qrCardImg={menuSetting.qr_card_img} register={register} setValue={setValue} />
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});


const SelectMenuType: React.FC<{control: any}> = ({control}) => {
  return (
    <FormControl>
      <FormLabel>メニュータイプ</FormLabel>
      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <RadioGroup {...field}>
            <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)'}} gap={1}>
              <Radio m={0} value='画像表示のみ'>画像表示のみ</Radio>
              <Radio m={0} value='Airモバイルオーダー'>モバイルオーダー</Radio>
              <Radio m={0} value='その他'>その他</Radio>
            </Grid>
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
const SwitchFirstSurvey: React.FC<{register: any, isFirstSurvey: boolean}> = ({register, isFirstSurvey}) => {
  return (
    <FormControl>
      <FormLabel>初回アンケート</FormLabel>
      <Switch size='md' mb='0' defaultChecked={isFirstSurvey} {...register('is_first_survey')}  />
    </FormControl>
  );
}

type QrCardImgInputProps = {
  qrCardImg?: string;
  register: any;
  setValue: any;
}
export const QrCardImgInput: React.FC<QrCardImgInputProps> = memo(({qrCardImg, register, setValue}) => {
  const [useDefaultImg, setUseDefaultImg] = useState<boolean>(qrCardImg ? false : true);
  useEffect(() => {
    if (useDefaultImg) setValue('qr_card_img', 'default');
    if (!useDefaultImg) setValue('qr_card_img', '');
  }, [useDefaultImg]);
  useEffect(() => {
    setUseDefaultImg(qrCardImg ? false : true)
  }, [qrCardImg]);
  return (
    <FormControl>
      <FormLabel>QRカード</FormLabel>
      <VStack align='start' spacing={1}>
        {qrCardImg &&
          <Box>
            <Text m='0' fontSize='xs' color='gray.500'>現在の画像</Text>
            <Box
              bgImage={`url(${qrCardImg})`}
              w="350px"
              h="200px"
              bgSize="contain"
              bgRepeat="no-repeat"
              border='0.1px solid #c0c0c09d'
            />
          </Box>
        }
        <input type="file" accept="image/*" {...register('qr_card_img')} disabled={useDefaultImg}  />
        <Checkbox isChecked={useDefaultImg} onChange={() => setUseDefaultImg(!useDefaultImg)} size='sm' m='0'>デフォルト画像を使用</Checkbox>
      </VStack>
      <FormHelperText>縦横比 7:4</FormHelperText>
    </FormControl>
  )
})