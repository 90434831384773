import { memo } from "react"
import { DisplayMenu } from "./DisplayMenu";
import { MobileOrderQr } from "./MobileOrderQr";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchMenuTypeCustomer } from "../../../hooks/menu/useFetchMenuTypeCustomer";

type CustomerMenuProps = {
  restaurantId: string;
  tableId: string;
}
export const CustomerMenu: React.FC<CustomerMenuProps> = memo(({restaurantId, tableId}) => {
  const { menuType, isLoading } = useFetchMenuTypeCustomer(restaurantId)
  if (isLoading) return <CircularLoading />
  return (
    <>
      {menuType === '画像表示のみ' && <DisplayMenu restaurantId={restaurantId} />}
      {menuType === 'Airモバイルオーダー' && <MobileOrderQr tableId={tableId} />}
    </>
  );
});
