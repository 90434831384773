import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  useMediaQuery,
} from '@chakra-ui/react'


type TableLayoutProps = {
  headers: React.ReactNode[];
  rows: React.ReactNode[];
  size?: 'xs' | 'sm' | 'md' | 'lg';
}
export const TableLayout: React.FC<TableLayoutProps> = ({headers, rows, size='md'}) => {
  const [isBase] = useMediaQuery("(max-width: 480px)");
  const styles = {
    xs: { px: 2, py: 3, fontSize: '12px' },
    sm: { p: 3, fontSize: '14px' },
    md: { p: 4, fontSize: '14px' },
    lg: { px: 4, py: 5, fontSize: '16px' }
  };
  const ThStyle = isBase ? styles.xs : styles[size];
  if (rows.length === 0) return <>データが存在しません</>;
  return (
    <TableContainer>
      <Table>
        <Thead backgroundColor='#F7FAFC'>
          <Tr>
            {headers.map((header, index) => (
              <Th key={index} {...ThStyle}>{header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody fontWeight='bold'>
          {rows}
        </Tbody>
      </Table>
    </TableContainer>
  );
}