import { useNavigate } from "react-router-dom";
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";


export const usePostOverallScore = (lineAccountId: string, restaurantId: string, branchScore: number) => {
  const navigate = useNavigate();
  const onSubmit = async (data:any) => {
    try {
      await customNoAuthAxios.post(
        `games/roulette/score/overall/`,
        {line_account: lineAccountId, score: data.score},
      );
      const nextRoute = data.score && data.score > branchScore ? 'google' : 'member';
      navigate(`/roulette/${restaurantId}?next=${nextRoute}`);
    } catch (error) {
    }
  }
  return onSubmit;
};