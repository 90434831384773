import React from 'react';
import { Box, Divider, } from '@chakra-ui/react';
import { RestaurantName } from '../../atoms/RestaurantName';
import { RestaurantSidebarMenu } from './RestaurantSidebarMenu';
import { SidebarNavWrapper } from '../../template/SidebarNavWrapper';


type RestaurantSidebarProps = {
  restaurantId: string
}
export const RestaurantSidebar: React.FC<RestaurantSidebarProps> = ({restaurantId}) => {
  return (
    <Box>
      <Box px={4} py={3}  fontSize='24px'>BLOCKs</Box>
      <Divider m='0' />
      <Box px={4} py={3}>
        <RestaurantName restaurantId={restaurantId}/>
      </Box>
      <Box px={2}>
        <Divider m='0' />
      </Box>
      <SidebarNavWrapper>
        <RestaurantSidebarMenu restaurantId={restaurantId}/>
      </SidebarNavWrapper>
    </Box>
  );
};