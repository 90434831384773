import { useState } from "react";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";
import { useRecoilValue } from "recoil";
import { customerRestaurantId } from "../../store/customerRestaurantId";

type UseChangeSegmentCouponStateProps = {
  couponId: string;
  lineId: string;
  onClose: () => void;
}

export const useChangeSegmentCouponState = ({couponId, lineId, onClose}: UseChangeSegmentCouponStateProps) => {
  const restaurantId = useRecoilValue(customerRestaurantId)
  const [isUsed, setIsUsed] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const markCouponAsUsed = async () => {
    const data = {lineId: lineId, restaurantId: restaurantId}
    await customNoAuthAxios.post(
      `membership/segment-coupons/${couponId}/mark-as-used/`,
      data,
    );
  }

  const handleOnClick = async () => {
    setIsLoading(true)
    try {
      await markCouponAsUsed();
      setIsUsed(true)
      onClose()
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  return { isUsed, isLoading, handleOnClick }
}

