import React from "react";
import { FormControl, FormErrorMessage, FormLabel, Textarea } from "@chakra-ui/react";


type NotesTextareaProps = {
  register: any,
  errors: any,
}
export const NotesTextarea: React.FC<NotesTextareaProps> = ({register, errors}) => (
  <FormControl isRequired isInvalid={errors.notes}>
    <FormLabel>注意事項</FormLabel>
    <Textarea {...register('notes')}/>
    <FormErrorMessage>{errors['notes']?.message}</FormErrorMessage>
  </FormControl>
);