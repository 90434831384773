import liff from '@line/liff';

export const initLiff = async (liffId: string) => {
  try {
    await liff.init({ liffId: liffId});
    if (!liff.isLoggedIn()) {
      await liff.login({});
    } else {
      const profile = await liff.getProfile();
      return profile;
    }
  } catch (error) {
    console.error(error);
  }
}
