import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const rouletteCouponSchema = yup.object().shape({
  title: yup.string().required('タイトルを入力してください'),
  explanation: yup.string().required('説明を入力してください'),
  notes: yup.string().required('注意事項を入力してください'),
  expiration_date: yup.string().required('有効期限を入力してください'),
  img: yup.mixed(),
  segment_count: yup.number().required('セグメント数を入力してください')
});
export const useRegisterRouletteCouponForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, } = useForm({resolver: yupResolver(rouletteCouponSchema),});
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};