import { salesmanGroupId } from '../../groupNumber';
import { useNavigate } from 'react-router-dom';
import { customNoAuthAxios } from '../../utils/customNoAuthAxios';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useErrorToast } from '../toast/useErrorToast';


export const useSignUpSalesman = () => {
  const showErrorToast = useErrorToast();
  const navigate = useNavigate();

  const onSubmit = async (data:any) => {
    const loginValues: { username: string; password: string } = {
      username: data.username,
      password: data.password
    };
    data.groups = [salesmanGroupId]
    try {
      const userRes = await customNoAuthAxios.post(
        'authentication/signup/',
        data
      );

      const authRes = await customNoAuthAxios.post(
        'authen/jwt/create/',
        loginValues
      );

      const token = authRes.data.access;
      localStorage.clear();
      localStorage.setItem('localJWT', token);

      const userId = userRes.data.id
      const salesmanRes = await customAuthAxios.post(
        'accounts/salesmen/',
        {user: userId}
      );
      navigate(`/salesmen/${salesmanRes.data.id}`)
    } catch (error:any) {
      if (error.response.data.username) {
        showErrorToast(error.response.data.username);
      } else {
        console.error("An unexpected error occurred:", error);
        showErrorToast("予期せぬエラーが発生しました。");
      }
    }
  };
  return onSubmit;
};