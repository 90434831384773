import React, { memo } from "react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { UserUsernameInput } from "../../molecules/form/UserUsernameInput";
import { useParams } from "react-router-dom";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { FaUserTie } from "react-icons/fa";
import { CardHeading } from "../../molecules/card/CardHeading";
import { ManagerNameInput } from "../../organisms/managers/ManagerNameInput";
import { ManagerRestaurantsSelect } from "../../organisms/managers/ManagerRestaurantsSelect";
import { useManagerEditForm } from "../../../hooks/manager/useManagerEditForm";
import { useFetchManager } from "../../../hooks/manager/useFetchManager";
import { useSubmitManagerEdit } from "../../../hooks/manager/useSubmitManagerEdit";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const ManagerEdit: React.FC = memo(() => {
  const { corporationId, managerId } = useParams()
  const { manager, isLoading } = useFetchManager(managerId)
  const { handleSubmit, register, isSubmitting, errors, control } = useManagerEditForm(manager);
  const onSubmit = useSubmitManagerEdit(manager?.user, managerId);
  if (isLoading) return <CircularLoading />
  const inputFields: InputField[] = [
    { component: UserUsernameInput, props: { register, errors } },
    { component: ManagerNameInput, props: { register, errors } },
    { component: ManagerRestaurantsSelect, props: { corporationId, errors, control } }
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<FaUserTie />} heading='マネジャー編集'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={4}>
              {inputFields.map(({ component: C, props }, i) => <C key={i} {...props} />)}
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});