import { memo } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { Button, useDisclosure } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from '@chakra-ui/react'
import { apiUrlLiffUrl } from "../../../apiUrls/lineApiUrls";
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";


export const LineUsageChecker: React.FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate();
  const params = useParams();
  const { restaurantId, tableId } = params;


  const transitionLiffUrl = () => {
    customNoAuthAxios
      .get(apiUrlLiffUrl, {
        params: {
          restaurantId: restaurantId,
          liffType: 'menu',
        }
      })
      .then((response) => {
        const liffUrl = response.data.liff_url
        const lineUrlScheme = `${liffUrl}/${restaurantId}/${tableId}`
        window.location.href = lineUrlScheme;
      })
      .catch((error) => {
      });
  }

  const transitionMenuUrl = () => navigate(`/menu/${restaurantId}/${tableId}`);


  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={true} onClose={onClose} size='xs'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>LINEで開きますか？</ModalHeader>
          <ModalFooter>
          <Button onClick={transitionMenuUrl} mr={3}>いいえ</Button>
          <Button colorScheme='blue' onClick={transitionLiffUrl}>
            はい
          </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
});


