import { customAuthAxios } from '../../../utils/customAuthAxios';
import { useNavigate } from "react-router-dom";
import { useSuccessToast } from "../../toast/useSuccessToast";


export const useRegisterGoogleSetting = (restaurantId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    data.restaurant = restaurantId;
    data.notification_recipients = data.notification_recipients ? [data.notification_recipients] : []
    try {
      await customAuthAxios.post(`/external_api/google-settings/`, data)
      showToast('登録が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};