import React, { memo } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import { useFetchManLaboSurveyAnswersCount } from '../../../hooks/manzokudoLabo/useFetchManLaboSurveyAnswersCount';
import { SmallCardWithIcon } from '../../molecules/card/SmallCardWithIcon';
import { RiSurveyLine } from 'react-icons/ri';

type ManLaboSurveyAnswersCountCardProps = {
  restaurantId: string;
  startDate: string|null;
  endDate: string|null;
}

export const ManLaboSurveyAnswersCountCard: React.FC<ManLaboSurveyAnswersCountCardProps> = memo(({restaurantId, startDate, endDate}) => {
  const { manLaboSurveyAnswerCount, isLoading } = useFetchManLaboSurveyAnswersCount(restaurantId, startDate, endDate);
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <SmallCardWithIcon data={`${manLaboSurveyAnswerCount}人`} title="満足度ラボアンケート回答数">
        <RiSurveyLine size='30px' />
      </SmallCardWithIcon>
    </Skeleton>
  );
});