import React, { memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { useFetchRestaurant } from "../../hooks/restaurants/useFetchRestaurant";
import { CircularLoading } from "../molecules/loading/CircularLoading";


export const DashboardWrapper: React.FC = memo(() => {
  const { restaurantId } = useParams();
  const {restaurant} = useFetchRestaurant(restaurantId);
  if (!restaurant) return <CircularLoading/>;
  const addOns = restaurant.add_ons_names
  if (addOns.length === 1 && addOns[0] === '口コミュ') return <NavigateToKuchikomyuScores />
  return (
    <Dashboard />
  );
});

const NavigateToKuchikomyuScores: React.FC = memo(() => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('../kuchikomyu/scores');
  }, []);
  return null
})