import React from "react";
import { PartialCircularLoading } from "../molecules/loading/PartialCircularLoading";


type SegmentTableHocProps = {
  isLoading: boolean;
  data?: any[];
  children: React.ReactNode;
}

export const SegmentTableHoc: React.FC<SegmentTableHocProps> = ({isLoading, data, children }) => {
  if (isLoading) return <PartialCircularLoading />
  if (!data) return null
  if (data.length === 0) return <>データが存在しません</>

  return <>{children}</>;
};