import { atom } from 'recoil';

type lineProfileState = {
  userId: string;
  displayName: string;
  pictureUrl?: string;
}


export const lineProfileState = atom<lineProfileState|null>({
  key: 'lineProfileState',
  default: null,
});


