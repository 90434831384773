import React, { memo } from "react";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { useFetchUser } from "../../../hooks/user/useFetchUser";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { Box, CardBody, CardFooter, CardHeader, Divider, VStack } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingItem } from "../../organisms/SettingItem";
import { FaRegUser } from "react-icons/fa";


export const SalesmanBaseInfo: React.FC = memo(() => {
  const { user, isLoading } = useFetchUser()
  if (isLoading) return <CircularLoading />
  if (!user) return null
  const salesmanDetails = [
    { label: '氏名', value: `${user.last_name}${user.first_name}` },
    { label: 'ユーザーネーム', value: user.username },
    { label: 'メールアドレス', value: user.email },
    { label: '電話番号', value: user.phone_number },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '650px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<FaRegUser />} heading='基本情報'/>
        </CardHeader>
        <CardBody py={2}>
          <VStack align='start' fontWeight='bold' spacing={3} w='100%'>
            <Divider my={2} />
            {salesmanDetails.map((detail) => (
              <React.Fragment key={detail.label}>
                <Box w='100%'>
                  <SettingItem label={detail.label} content={detail.value}/>
                </Box>
                <Divider my={2} />
              </React.Fragment>
            ))}
          </VStack>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集'/>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});

