import { memo } from "react"
import { useParams } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchSoCoupon } from "../../../hooks/socoupon/useFetchSoCoupon";
import { useSoCouponEditForm } from "../../../hooks/socoupon/useSoCouponEditForm";
import { useSubmitSoCouponEdit } from "../../../hooks/socoupon/useSubmitSoCouponEdit";
import { SoCouponForm } from "../../organisms/soCoupon/SoCouponForm";


export const SoCouponEdit: React.FC = memo(() => {
  const { soCouponId } = useParams();
  const { soCoupon, isLoading } = useFetchSoCoupon(soCouponId)
  const { handleSubmit, register, isSubmitting, errors, setValue } = useSoCouponEditForm(soCoupon)
  const onSubmit = useSubmitSoCouponEdit(soCouponId)
  if (isLoading) return <CircularLoading />
  if (!soCoupon) return null
  return (
    <SoCouponForm
      img={soCoupon.img}
      handleSubmit={handleSubmit(onSubmit)}
      register={register}
      isSubmitting={isSubmitting}
      errors={errors}
      setValue={setValue}
    />
  );
});