import React from "react";
import { useNavigate } from "react-router-dom";
import { Divider, CardHeader, CardBody, List, ListItem, Flex, Text } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { ChevronRightIcon } from "@chakra-ui/icons";


export const SelectRequestMethod = () => {
  const navigate = useNavigate();
  const requestMethods = [
    { path: 'monthly', name: '単月' },
    { path: 'multi-month', name: '長期' },
  ]
  return (
    <DarkShadowCard fontWeight='bold'>
      <CardHeader>
        <Text fontSize='lg' m='0'>依頼方式</Text>
      </CardHeader>
      <CardBody pt={0}>
        <Divider m='0' borderColor='gray.300' />
        <List m='0'>
          {requestMethods.map((method) => (
            <React.Fragment key={method.path}>
              <ListItem cursor='pointer' _hover={{ bg: 'gray.100' }} px={2} py={3} onClick={() => navigate(method.path)}>
                <Flex align='center' justifyContent='space-between' w='100%'>
                  <Text fontSize='14px' m='0'>{method.name}</Text>
                  <ChevronRightIcon color='gray.400' />
                </Flex>
              </ListItem>
              <Divider m='0' borderColor='gray.300' />
            </React.Fragment>
          ))}
        </List>
      </CardBody>
    </DarkShadowCard>
  );
};