import { memo, useState } from "react"
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchHotPepperAnalytics } from "../../../hooks/ifreaAnalytics/useFetchHotPepperAnalytics";
import { HStack, Radio, RadioGroup, Select, VStack } from "@chakra-ui/react";
import { AnalyticsLineGraphCards } from "./AnalyticsLineGraphCards";

type HotPepperAnalyticsProps = {
  restaurantId: string
}
export const HotPepperAnalytics: React.FC<HotPepperAnalyticsProps> = memo(({restaurantId}) => {
  const { hotPepperAnalytics, isLoading } = useFetchHotPepperAnalytics(restaurantId)
  if (isLoading) return <CircularLoading />
  if (!hotPepperAnalytics) return <>データが存在しません</>
  if (Object.keys(hotPepperAnalytics).length === 0) return <>データが存在しません</>
  return <HotPepperAnalyticsContents hotPepperAnalytics={hotPepperAnalytics} />
});


const HotPepperAnalyticsContents: React.FC<{hotPepperAnalytics: any}> = memo(({hotPepperAnalytics}) => {
  const restaurantMediaNames = Object.keys(hotPepperAnalytics)
  const [ restaurantMediaName, setRestaurantMediaName ] = useState(restaurantMediaNames[0])

  const data = {
    pc: [
      { title: "店舗総PV", dataKey: "pc_total_pv" },
      { title: "店舗TOP PV", dataKey: "pc_top_pv" },
      { title: "地図クーポンPV", dataKey: "pc_map_coupon_pv" },
      { title: "クーポン印刷PV", dataKey: "pc_coupon_print_pv" },
      { title: "媒体予約件数", dataKey: "pc_media_reservation" },
      { title: "ホームページ予約件数", dataKey: "pc_hp_reservation" },
    ],
    sp: [
      { title: "店舗総PV", dataKey: "sp_total_pv" },
      { title: "店舗TOP PV", dataKey: "sp_top_pv" },
      { title: "クーポンページPV", dataKey: "sp_coupon_page_pv" },
      { title: "媒体予約件数", dataKey: "sp_media_reservation" },
      { title: "ホームページ予約件数", dataKey: "sp_hp_reservation" },
    ],
    others: [
      { title: "HPC", dataKey: "hpc" },
      { title: "電話件数", dataKey: "call" },
    ],
  };
  type DataType = keyof typeof data;
  const [ dataType, setDataType ] = useState<DataType>('pc')
  return (
    <VStack spacing={4}>
      <VStack align='start' w='100%'>
        <Select bg="white" size='sm' rounded='md' w='200px' defaultValue={restaurantMediaName} onChange={(e) => setRestaurantMediaName(e.target.value)}>
          {restaurantMediaNames.map(name => <option key={name} value={name}>{name}</option>)}
        </Select>
        <RadioGroup onChange={(value:DataType) => setDataType(value)} value={dataType}>
          <HStack spacing={3}>
            <Radio colorScheme='red' size='sm' m='0' value='pc'>PC</Radio>
            <Radio colorScheme='red' size='sm' m='0' value='sp'>スマホ</Radio>
            <Radio colorScheme='red' size='sm' m='0' value='others'>その他</Radio>
          </HStack>
        </RadioGroup>
      </VStack>
      <AnalyticsLineGraphCards
        data={data[dataType]}
        analyticsDate={Object.keys(hotPepperAnalytics[restaurantMediaName])}
        analyticsContents={Object.values(hotPepperAnalytics[restaurantMediaName])}
        restaurantMediaName={restaurantMediaName}
        graphColorRgb='229, 62, 62'
      />
    </VStack>
  )
})