import { useLocation, useNavigate } from 'react-router-dom';


export const useLanguageTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const langToTabIndex = { jp: 0, en: 1, ch: 2, kr: 3 } as const;
  const tabIndexToLang = ['jp', 'en', 'ch', 'kr'] as const;

  const query = new URLSearchParams(location.search);
  const langQuery = query.get('lang');

  const tabIdx = tabIndexToLang.includes(langQuery as any) ? langToTabIndex[langQuery as keyof typeof langToTabIndex] : 0;

  const handleTabsChange = (index: number) => {
    const lang = tabIndexToLang[index];
    query.set('lang', lang);
    navigate(`?${query.toString()}`);
  };

  return { tabIdx, handleTabsChange };
};