import React, { memo } from 'react';
import { Grid } from '@chakra-ui/react';
import { KuchikomyuLatestSurveyAnswerTexts } from './KuchikomyuLatestSurveyAnswerTexts';
import { KuchikomyuOverallScore } from './KuchikomyuOverallScore';


type KuchikomyuOverallScoreAndLatestReviewSectionProps = {
  restaurantId: string;
};
export const KuchikomyuOverallScoreAndLatestReviewSection: React.FC<KuchikomyuOverallScoreAndLatestReviewSectionProps> = memo(({restaurantId}) => {
  return (
    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap={3} w='100%'>
      <KuchikomyuOverallScore restaurantId={restaurantId} />
      <KuchikomyuLatestSurveyAnswerTexts restaurantId={restaurantId} />
    </Grid>
  );
});