import React, { memo } from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";


export const TableAccordionMenu: React.FC = memo(() => {
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          テーブル
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItemLink to={`tables`} text="テーブル一覧" />
          <AccordionItemLink to={`tables/qr`} text="テーブルQRコード" />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
});