import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";


export const useRegisterStaffSurveyQuestion = (restaurantId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();

  const onSubmit = async (data:any) => {
    try {
      data['restaurant'] = restaurantId
      await customAuthAxios.post(
        'restaurant_staff/survey/questions/',
        data
      );
      showToast('登録が完了しました')
      navigate(-1);
    } catch (error:any) {
    }
  };
  return onSubmit;
};