import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type DrinkMenu = {
  id: string;
  restaurant: string;
  menu_type: string;
  img: string;
}[]
export const useFetchCustomerDrinkMenu = (restaurantId: string) => {
  const { data: drinkMenu, error, isLoading } = useSwrNoAuth<DrinkMenu>(`menu/restaurants/${restaurantId}/drink/customer/`)
  return { drinkMenu, isLoading, error };
};

