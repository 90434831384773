import React, { memo } from "react";
import { useDisclosure, IconButton } from '@chakra-ui/react'
import { DeleteIcon } from "@chakra-ui/icons";
import { useDelete } from "../../../../hooks/useDelete";
import { DeleteModal } from "../../../molecules/DeleteModal";


type DeleteModalProps = {
  id: string;
  title: string;
}
export const RouletteCouponDeleteModal: React.FC<DeleteModalProps> = memo(({id, title}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDelete(`games/roulette/coupons/${id}/`, onClose)
  return (
    <>
      <IconButton
        colorScheme='red'
        aria-label='Delete SoCoupon'
        icon={<DeleteIcon />}
        size='sm'
        variant='ghost'
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={title}
        onSubmit={onSubmit}
      />
    </>
  );
});
