import React, { memo } from 'react';
import { useFetchSoCouponOverallScore } from '../../../hooks/socoupon/useFetchSoCouponOverallScore';
import { StarRateOverallScoreLayout } from '../../template/StarRateOverallScoreLayout';
import { CardBody, CardHeader, Flex, HStack, Icon, Link, Skeleton, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { LightShadowCard } from '../../molecules/card/LightShadowCard';


type SoCouponOverallScoreProps = {
  restaurantId: string;
}
export const SoCouponOverallScore: React.FC<SoCouponOverallScoreProps> = memo(({restaurantId}) => {
  const { soCouponOverallScore, isLoading } = useFetchSoCouponOverallScore(restaurantId)
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl' minH='280px'>
      <LightShadowCard >
        <CardHeader>
          <Flex align='center' justify='space-between'>
            <Text m={0} fontSize='lg' fontWeight='bold'>総合評価</Text>
            <Link as={RouterLink} to='overall-score' fontSize='sm' color='gray.500' fontWeight='bold' _hover={{ color: 'gray.400' }}>
              <HStack align='center' spacing={1}>
                <Text m='0'>詳細</Text>
                <Icon as={IoIosArrowForward} />
              </HStack>
            </Link>
          </Flex>
        </CardHeader>
        <CardBody pt={0}>
          {soCouponOverallScore && <StarRateOverallScoreLayout {...soCouponOverallScore} />}
        </CardBody>
      </LightShadowCard>
    </Skeleton>
  );
});