import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { Box, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ValidRouletteCoupon, ValidSegmentCoupon, ValidSoCoupon, useFetchValidCoupon } from "../../../hooks/membership/useFetchValidCoupon";
import { CustomerValidSegmentCoupon } from "../../organisms/membership/CustomerValidSegmentCoupon";
import { CustomerValidSoCoupon } from "../../organisms/membership/CustomerValidSoCoupon";
import { CustomerValidRouletteCoupon } from "../../organisms/membership/CustomerValidRouletteCoupon";


const couponComponents = {
  segment: (props: ValidSegmentCoupon) => <CustomerValidSegmentCoupon {...props} />,
  socoupon: (props: ValidSoCoupon) => <CustomerValidSoCoupon {...props} />,
  roulette: (props: ValidRouletteCoupon) => <CustomerValidRouletteCoupon {...props} />,
};
export const CustomerValidCoupon = () => {
  const params = useParams();
  const { couponId } = params;
  const { validCoupon, isLoading } = useFetchValidCoupon(couponId);
  if (isLoading) return <CircularLoading />;
  if (!validCoupon) return null;
  const CouponComponent = couponComponents[validCoupon.coupon_type];
  return (
    <VStack spacing='4.65vw'>
      <Box textAlign="center" bg='#E60123' p='4.65vw' w='100%'>
        <Text as='b' fontSize='4.2vw' color='white'>こちらをスタッフまでご提示ください</Text>
      </Box>
      <Box w='100%'>
        {CouponComponent && <CouponComponent {...(validCoupon.coupon as any)} />}
      </Box>
    </VStack>
  );
};