import React, { memo } from "react";
import { FormControl, FormLabel, Grid } from "@chakra-ui/react";
import { useFetchAddOns } from "../../../hooks/useFetchAddOns";
import { Controller } from "react-hook-form";
import { Checkbox } from "@chakra-ui/react";


type AddOnsSelectorProps = {
  control: any;
  errors: any;
}
export const AddOnsSelector: React.FC<AddOnsSelectorProps> = memo(({control, errors}) => {
  const {addOns, isLoading} = useFetchAddOns()
  if (isLoading || !addOns) return null
  return (
    <FormControl>
      <FormLabel>アドオン</FormLabel>
      <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)'}} gap={1}>
        {addOns.map((addOn) => (
          <Controller
            key={addOn.id}
            name='restaurant.add_ons'
            control={control}
            render={({ field }) => (
              <Checkbox
                value={addOn.id}
                isChecked={field.value ? field.value.includes(addOn.id) : false}
                onChange={() => {
                  const currentValue = Array.isArray(field.value) ? field.value : [];
                  const isAlreadySelected = currentValue.includes(addOn.id);
                  const newValue = isAlreadySelected
                    ? currentValue.filter((value: string) => value !== addOn.id)
                    : [...currentValue, addOn.id];
                  field.onChange(newValue);
                }}
                m={0}
              >
                {addOn.name}
              </Checkbox>
            )}
          />
        ))}
      </Grid>
    </FormControl>
  );
});