import useSWR from "swr";

type LiffRestaurants = {
  id: string;
  liff_name: string;
  liff_id: string;
  liff_url: string;
}[]

export const useFetchLiffRestaurantsByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `external_api/restaurants/${restaurantId}/liff-restaurants/` : null;
  const { data: liffRestaurants, error, isLoading } = useSWR<LiffRestaurants>(fetcherKey)
  return { liffRestaurants, isLoading, error };
};