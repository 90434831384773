import useSWR from "swr";

type Restaurants = {
    id: string;
    name: string;
    username: string;
    manager_last_name: string;
    manager_first_name: string;
    manager_phone_number: string;
    corporation_name: string;
}[];

type RestaurantsPerPage = {
  count: number;
  restaurants: Restaurants;
};

export const useFetchCorporateRestaurantsPerPage = (pageSize: number, currentPage: number, corporationId?: string) => {
  const fetchKey = `accounts/corporations/${corporationId}/restaurants/per-page/?pageSize=${pageSize}&currentPage=${currentPage}`
  const { data: restaurantsPerPage, error, isLoading } = useSWR<RestaurantsPerPage>(fetchKey)
  return { restaurantsPerPage, isLoading, error };
};