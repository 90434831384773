import React, { memo } from 'react';
import { Box, CardBody, Grid, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/formatDate';
import { MultipliedText } from '../atoms/MultipliedText';
import { DarkShadowCard } from '../molecules/card/DarkShadowCard';


type LineAccount = {
  id: string;
  customer_sex: string;
  customer_birth_year: string;
  customer_birth_month: string;
  line_id: string;
  display_name: string;
  picture_url?: string;
  visit_count: number;
  registered_date: string;
  last_visit: string;
  is_active: boolean;
  blocked_date: null | string;
  customer: string;
  restaurant: string;
};

type AnswerScore = {
  id: string;
  line_account: LineAccount;
  score: number;
  date: string;
};

type AnsweredCustomersLayoutProps = {
  answerScores: AnswerScore[];
}

export const AnsweredCustomersLayout: React.FC<AnsweredCustomersLayoutProps> = memo(({answerScores}) => {
  const navigation = useNavigate()
  if (answerScores.length === 0) return <>データは存在しません</>
  return (
    <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)'}} gap={4}>
      {answerScores?.map((answerScore) => (
        <DarkShadowCard transition='0.3s' _hover={{transform: 'translateY(-5px)'}}>
          <CardBody cursor='pointer' p={{base: 4, sm: 5}} onClick={() => navigation(`../../line/accounts/${answerScore.line_account.id}`)}>
            <HStack spacing={3}>
              <Image boxSize='80px' rounded='2xl' fallbackSrc='/images/fallback-user.png' src={answerScore.line_account.picture_url}  />
              <VStack align='start' spacing={0}>
                <Text noOfLines={1} fontWeight='bold' fontSize='md' m={0}>{answerScore.line_account.display_name}</Text>
                <Box color='orange' fontSize='xl'><MultipliedText text="★" count={answerScore.score}/></Box>
                <Text m={0} color='gray.500' textAlign='right' fontSize='xs'>{formatDate(answerScore.date)}</Text>
              </VStack>
            </HStack>
          </CardBody>
        </DarkShadowCard>
      ))}
    </Grid>
  );
});