import useSWR from "swr";

type LineAccount = {
  id: string;
  customer_sex: string;
  customer_birth_year: string;
  customer_birth_month: string;
  line_id: string;
  display_name: string;
  picture_url?: string;
  visit_count: number;
  registered_date: string;
  last_visit: string;
  is_active: boolean;
  blocked_date: null | string;
  customer: string;
  restaurant: string;
};

type ManLaboSurveyAnswerScoreWithLineAccounts = {
  id: string;
  line_account: LineAccount;
  answer_score: number;
  answered_at: string;
}[];

type ManlaboQuestionWithLineAccountsPerPage = {
  count: number;
  question: string;
  manzokudo_labo_survey_answer_score_with_line_accounts: ManLaboSurveyAnswerScoreWithLineAccounts;
};

  export const useFetchManLaboQuestionWithLineAccountsPerPage = (questionId: string, pageSize: number, currentPage: number) => {
  const { data: manLaboQuestionWithLineAccountsPerPage, error, isLoading } = useSWR<ManlaboQuestionWithLineAccountsPerPage>(`manzokudo_labo/survey/questions/${questionId}/with-line-accounts/?pageSize=${pageSize}&currentPage=${currentPage}`)
  return { manLaboQuestionWithLineAccountsPerPage, isLoading, error };
};