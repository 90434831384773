import React, { memo } from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";


export const IfreaAnalyticsAccordionMenu: React.FC = memo(() => {
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          ifreaアナリティクス
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItemLink to={'ifrea-analytics/aggregate'} text="予約集計" />
          <AccordionItemLink to={'ifrea-analytics/media-analytics'} text="媒体レポート" />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
});