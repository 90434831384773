import { BudgetLimitInput, IsTransportationCostProvidedRadio, MaxCompanionsAllowedInput, MenuSpecificationSelect, MessageTextArea, MonthInput, NgDaysCheckBoxes, NgTimePeriodInputs, NumberInput, TeamSelect, UnitPriceInput } from "../../organisms/kuitan/DispatchRequestFormComponents";
import { useRequestMonthlyDispatchFormForRestaurant } from "../../../hooks/kuitan/useRequestMonthlyDispatchFormForRestaurant";
import { DispatchRequestForm } from "../../organisms/kuitan/DispatchRequestForm";
import { useRequestMonthlyDispatchByRestaurant } from "../../../hooks/kuitan/useRequestMonthlyDispatchByRestaurant";


type FormComponent = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const RequestMonthlyDispatchByRestaurant = () => {
  const { handleSubmit, register, control, isSubmitting, errors, watch } = useRequestMonthlyDispatchFormForRestaurant();
  const onSubmit = useRequestMonthlyDispatchByRestaurant()
  const formComponents: FormComponent[] = [
    { component: TeamSelect, props: { control, errors } },
    { component: MonthInput, props: { register, errors } },
    { component: NumberInput, props: { register, errors } },
    { component: UnitPriceInput, props: { register, errors } },
    { component: MenuSpecificationSelect, props: { register, errors } },
    { component: BudgetLimitInput, props: { register, errors } },
    { component: MaxCompanionsAllowedInput, props: { register, errors } },
    { component: IsTransportationCostProvidedRadio, props: { control, errors } },
    { component: NgDaysCheckBoxes, props: { control, errors } },
    { component: NgTimePeriodInputs, props: { register, errors, watch } },
    { component: MessageTextArea, props: { register, errors } },
  ];
  return <DispatchRequestForm cardTitle='単月派遣依頼' formComponents={formComponents} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} />
};