import React, { memo } from "react";
import { TableLayout } from "../../template/table/TableLayout";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { CardBody, CardFooter, CardHeader, Text } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { useFetchNoticesPerPage } from "../../../hooks/notice/useFetchNoticesPerPage";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { formatDate } from "../../../utils/formatDate";
import { IoIosNotifications } from "react-icons/io";


export const Notices: React.FC = () => {
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const pageSize = 10;
  const { noticesPerPage, isLoading } = useFetchNoticesPerPage(pageSize, page)
  if (isLoading) return <CircularLoading />;
  if (!noticesPerPage) return null
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<IoIosNotifications />} heading='履歴'/>
      </CardHeader>
      <CardBody py={2}>
        <NoticesTable notices={noticesPerPage.notices} />
      </CardBody>
      <CardFooter>
        <Pagination totalItems={noticesPerPage.count} pageSize={pageSize}/>
      </CardFooter>
    </DarkShadowCard>
  );
};


type Notices = {
  id: string;
  title: string;
  content: string;
  created_at: string;
}[];
const NoticesTable: React.FC<{notices: Notices}> = memo(({notices}) => {
  const headers = ['タイトル', '内容', '日時'];
  const rows = notices.map((notice) => (
    <TableRowWithNavigationLayout
      key={notice.id}
      path={`${notice.id}`}
      childrenList={[
        <Text overflowWrap="break-word" whiteSpace="normal" noOfLines={1} m={0}>{notice.title}</Text>,
        <Text overflowWrap="break-word" whiteSpace="normal" noOfLines={1} m={0}>{notice.content}</Text>,
        formatDate(notice.created_at),
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} />
})