import { memo } from "react"
import { useParams } from "react-router-dom";
import { Avatar, Badge, Box, Card, CardBody, CardHeader, HStack, Text, VStack } from "@chakra-ui/react";
import { CardHeading } from "../../../molecules/card/CardHeading";
import QRCode from "qrcode.react";
import { useFetchLineAccountsForReviewCheck } from "../../../../hooks/externalApi/google/useFetchLineAccountsForReviewCheck";
import { TableRowLayout } from "../../../template/table/TableRowLayout";
import { formatDate } from "../../../../utils/formatDate";
import { TableLayout } from "../../../template/table/TableLayout";
import { BellIcon } from "@chakra-ui/icons";
import { LineAccountForReviewCheckDeleteModal } from "./LineAccountForReviewCheckDeleteModal";
import { FaUsers } from "react-icons/fa6";


export const GoogleNotice = () => {
  const { restaurantId } = useParams()
  if (!restaurantId) return null
  return (
    <VStack rounded='2xl' boxShadow='rgba(0, 0, 0, 0.1) 1px 1px 7px' p={6} bg='white' spacing={8}>
      <Box w='100%'>
        <QrCodeCard restaurantId={restaurantId}/>
      </Box>
      <Box w='100%'>
        <LineAccountsTableCard restaurantId={restaurantId}/>
      </Box>
    </VStack>
  );
};


const QrCodeCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  return (
    <Card variant='unstyled'>
      <CardHeader pb={4}>
        <CardHeading icon={<BellIcon fontSize='18px' />} heading='通知ボットQRコード'/>
      </CardHeader>
      <CardBody>
        <Box
          as={QRCode}
          value={`https://liff.line.me/2004571337-p9WPeDGv/${restaurantId}`}
          sx={{
            width: '150px!important',
            height: '150px!important',
            objectFit: 'contain',
            margin: '0 auto',
          }}
        />
      </CardBody>
    </Card>
  )
});


const LineAccountsTableCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { lineAccounts, isLoading } = useFetchLineAccountsForReviewCheck(restaurantId)
  if (isLoading || !lineAccounts) return null
  const headers = ['アカウント', '登録日', '通知', ''];
  const rows = lineAccounts.map((lineAccount) => (
    <TableRowLayout
      key={lineAccount.id}
      size='xs'
      childrenList={[
        <HStack>
          <Avatar src={lineAccount.picture_url} boxSize='30px' />
          <Text m='0'>{lineAccount.display_name}</Text>
        </HStack>,
        formatDate(lineAccount.created_at),
        <Badge colorScheme={lineAccount.has_notification_recipient ? 'green' : 'gray'}>
          {lineAccount.has_notification_recipient ? 'ON' : 'OFF'}
        </Badge>,
        lineAccount.has_notification_recipient ?
        null
        : <LineAccountForReviewCheckDeleteModal id={lineAccount.id} title={lineAccount.display_name} mutateKey={`external_api/restaurants/${restaurantId}/line/accounts/for-review-check/`} />
      ]}
    />
  ));
  return (
    <Card variant='unstyled'>
      <CardHeader pb={4}>
        <CardHeading icon={<FaUsers />} heading='通知先アカウント'/>
      </CardHeader>
      <CardBody>
        <TableLayout headers={headers} rows={rows || []} size='xs' />
      </CardBody>
    </Card>
  )
});