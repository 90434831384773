import { Route } from 'react-router-dom';
import { SoCoupons } from '../components/pages/soCoupon/SoCoupons';
import { SoCouponRegister } from '../components/pages/soCoupon/SoCouponRegister';
import { SoCoupon } from '../components/pages/soCoupon/SoCoupon';
import { SoCouponEdit } from '../components/pages/soCoupon/SoCouponEdit';


export const SoCouponsRoutes = (
  <Route path='socoupons'>
    <Route path="" element={<SoCoupons />} />
    <Route path="register" element={<SoCouponRegister />} />
    <Route path=":soCouponId" element={<SoCoupon />} />
    <Route path=":soCouponId/edit" element={<SoCouponEdit />} />
  </Route>
);