import React, { memo } from "react";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { TruncatedText } from "../../atoms/TruncatedText";
import { formatDate } from "../../../utils/formatDate";
import { useNavigate } from "react-router-dom";

type SegmentMsgFieldProps = {
  id: string;
  title: string;
  send_at: string;
  text: string;
}

export const SegmentMsgField: React.FC<SegmentMsgFieldProps> = memo(({id, title, send_at, text}) => {
  const navigate = useNavigate()
  return (
    <>
      <Box onClick={() => navigate(`/membership/messages/${id}`)} px={3} py={2}>
        <Flex align='center' justifyContent="space-between">
          <TruncatedText fontSize='4.186vw' fontWeight='bold' m='0' text={title} maxLength={13} />
          <Text color='#969696' fontSize='3.26vw' m='0'>{formatDate(send_at)}</Text>
        </Flex>
        <TruncatedText fontSize='3.72vw' m='0' text={text} maxLength={70} />
      </Box>
      <Divider my={0} />
    </>
  );
});
