import React, { Suspense } from 'react';
import { RecoilRoot } from "recoil";
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";
import { ChakraProvider } from '@chakra-ui/react';
import { ToastContainer } from 'react-toastify';
import { SWRConfig } from 'swr'
import 'react-toastify/dist/ReactToastify.css';
import { customAuthAxios } from './utils/customAuthAxios';
import { CircularLoading } from './components/molecules/loading/CircularLoading';
import { extendTheme } from '@chakra-ui/react'


export const Providers: React.FC<{children: React.ReactNode}> = ({children}) => {
  const theme = extendTheme({
    breakpoints: {
      base: '0px',
      sm: '480px',
      md: '768px',
      lg: '1025px',
    },
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false
    }
  });
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <ToastContainer />
        <RecoilRoot>
          <Suspense fallback={ <CircularLoading/> }>
            <ErrorBoundary fallbackRender={({ error, resetErrorBoundary }) => (<></>)}>
              <SWRConfig
                value={{
                  refreshInterval: 300000,
                  fetcher: (url: string) => customAuthAxios.get(url).then(res => res.data),
                  errorRetryCount: 0,
                }}
              >
                {children}
              </SWRConfig>
            </ErrorBoundary>
          </Suspense>
        </RecoilRoot>
      </ChakraProvider>
    </BrowserRouter>
  );
}