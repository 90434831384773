import { CopyIcon } from "@chakra-ui/icons";
import { HStack, Text } from "@chakra-ui/react";
import { useSuccessToast } from "../../hooks/toast/useSuccessToast";


export const CopyableText: React.FC<{text: string}> = ({text}) => {
  const showToast = useSuccessToast();
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      showToast('テキストがクリップボードにコピーされました！');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }
  return (
    <HStack
      align='center'
      cursor='pointer'
      spacing={1}
      onClick={(e) => {
      e.stopPropagation();
      copyToClipboard(text);
      }}
    >
      <Text m={0}>{text}</Text>
      <CopyIcon fontSize='sm'/>
    </HStack>
  )
}