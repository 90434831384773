import { useParams } from "react-router-dom";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { useFetchGoogleSettingByRestaurantId } from "../../../../hooks/externalApi/google/useFetchGoogleSettingByRestaurantId";
import { SettingItem } from "../../../organisms/SettingItem";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { PageTransitionBtn } from "../../../atoms/button/PageTransitionBtn";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { AlertMsg } from "../../../molecules/AlertMsg";
import { GoogleNotice } from "../../../organisms/externalApi/google/GoogleNotice";


export const GoogleSettingForRestaurant = () => {
  const { restaurantId } = useParams();
  const { gSetting, isLoading } = useFetchGoogleSettingByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />;
  if (!gSetting) return <AlertMsg msg="設定が完了してません。営業担当に連絡してください。"/>
  return (
    <VStack m='auto' w={{base: '100%', md: '650px'}} spacing={5}>
      <Box w='100%'>
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='GBPマネジャー設定'/>
          </CardHeader>
          <CardBody py={1}>
            <Box fontWeight='bold' fontSize='16px'>
              <SettingItem label='通知先' content={gSetting?.notification_recipient_names?.length ? gSetting.notification_recipient_names.join('') : '未登録'} />
            </Box>
          </CardBody>
          <CardFooter>
            <PageTransitionBtn path={`${gSetting?.id}/edit`} text='編集' />
          </CardFooter>
        </DarkShadowCard>
      </Box>
      <Box w='100%'>
        <GoogleNotice/>
      </Box>
    </VStack>
  );
};