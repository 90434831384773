import React from "react";
import { UserUsernameInput } from "../../molecules/form/UserUsernameInput";
import { UserEmailInput } from "../../molecules/form/UserEmailInput";
import { UserPhoneNumberInput } from "../../molecules/form/UserPhoneNumberInput";
import { CorporationAddressInputField } from "../../organisms/corporations/CorporationAddressInputField";
import { PresidentNameInputField } from "../../organisms/corporations/PresidentNameInputField";
import { PresidentPhoneNumberInputField } from "../../organisms/corporations/PresidentPhoneNumberInputField";
import { CorporationNameInputField } from "../../organisms/corporations/CorporationNameInputField";
import { UserPasswordInput } from "../../organisms/restaurant/UserPasswordInput";
import { useRegisterCorporationForm } from "../../../hooks/corporations/useRegisterCorporationForm";
import { useRegisterCorporation } from "../../../hooks/corporations/useRegisterCorporation";
import { SelectSalesman } from "../../molecules/form/SelectSalesman";
import { CorporationForm } from "../../organisms/corporations/CorporationForm";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const RegisterCorporation = () => {
  const { handleSubmit, register, control, isSubmitting, errors } = useRegisterCorporationForm();
  const onSubmit = useRegisterCorporation()
  const inputFields: InputField[] = [
    { component: SelectSalesman, props: { control, errors } },
    { component: UserUsernameInput, props: { register, errors } },
    { component: UserPasswordInput, props: { register, errors } },
    { component: CorporationNameInputField, props: { register, errors } },
    { component: UserEmailInput, props: { register, errors } },
    { component: UserPhoneNumberInput, props: { register, errors } },
    { component: CorporationAddressInputField, props: { register, errors } },
    { component: PresidentNameInputField, props: { register, errors } },
    { component: PresidentPhoneNumberInputField, props: { register, errors } },
  ];
  return (
    <CorporationForm
      cardTitle='法人登録'
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      inputFields={inputFields}
    />
  );
};