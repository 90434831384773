import { Box, CardBody, CardFooter, CardHeader, Checkbox, FormControl, FormHelperText, FormLabel, VStack } from "@chakra-ui/react";
import { memo, useState } from "react"
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { useEditRankCardSettingsForm } from "../../../hooks/membership/useEditRankCardSettingsForm";
import { useEditRankCardSettings } from "../../../hooks/membership/useEditRankCardSettings";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const RankCardSettingsEdit: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const [isUseDefault, setIsUseDefault] =  useState<boolean>(false)
  const { handleSubmit, register, isSubmitting } = useEditRankCardSettingsForm();
  const onSubmit = useEditRankCardSettings(restaurantId ?? '', isUseDefault);
  return (
    <Box m='auto' w={{ base: '100%', sm: '400px' }}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='ランクカード設定編集'/>
          </CardHeader>
          <CardBody py={2}>
            <FormControl>
              <FormLabel>ランクカード画像</FormLabel>
              <VStack align='start'>
                <input type="file" accept="image/*" {...register('img')} />
                <Checkbox m={0} size='sm' defaultChecked={false} onChange={(e) => setIsUseDefault(e.target.checked)}>デフォルト画像を使用</Checkbox>
              </VStack>
              <FormHelperText>縦横比 8:5</FormHelperText>
            </FormControl>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});