import React, { memo } from "react";
import { useDisclosure, IconButton } from '@chakra-ui/react'
import { DeleteIcon } from "@chakra-ui/icons";
import { DeleteModal } from "../../../molecules/DeleteModal";
import { useDeleteWithMutateKey } from "../../../../hooks/useDeleteWithMutateKey";


type DeleteModalProps = {
  id: string;
  title: string;
  mutateKey: any;
}
export const LineAccountForReviewCheckDeleteModal: React.FC<DeleteModalProps> = memo(({id, title, mutateKey}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDeleteWithMutateKey(`external_api/line/accounts/for-review-check/${id}/destroy/`, onClose, mutateKey)
  return (
    <>
      <IconButton
        colorScheme='red'
        aria-label='Delete Line Account'
        icon={<DeleteIcon />}
        size='sm'
        variant='ghost'
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={title}
        onSubmit={onSubmit}
      />
    </>
  );
});
