import { useSwrNoAuth } from "../../swr/useSwrNoAuth";

type Reply = {
  id: string;
  review_id: string;
  reviewer_image_url: string;
  reviewer_name: string;
  review_date: string;
  rating: number;
  comment: string;
  reply: string;
}
export const useFetchGoogleReviewReply = (replyId?: string) => {
  const fetcherKey = replyId ? `/external_api/google/reviews/replies/${replyId}` : null;
  const { data: reply, error, isLoading } = useSwrNoAuth<Reply>(fetcherKey);
  return { reply, isLoading, error };
};