import { Box } from "@chakra-ui/react";
import { memo } from "react"
import { customerGroupId } from "../../../../groupNumber";
import { getRandomStringWithDate } from "../../../../utils/getRandomStringWithDate";
import { useSWRConfig } from "swr";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { useSwrNoAuth } from "../../../../hooks/swr/useSwrNoAuth";
import { useSubmitLineAccountRegister } from "../../../../hooks/externalApi/line/useSubmitLineAccountRegister";
import { useRegisterLineAccount } from "../../../../hooks/externalApi/line/useRegisterLineAccount";
import { CustomerForm } from "../../../organisms/externalApi/line/CustomerForm";

type Profile = {
  userId: string;
  displayName: string;
  pictureUrl?: string;
}
type LineAccountRegisterProps = {
  restaurantId: string;
  profile: Profile;
}
export const LineAccountRegister: React.FC<LineAccountRegisterProps> = memo(({restaurantId, profile}) => {
  const { mutate } = useSWRConfig();
  const signUpValues = {
    username: getRandomStringWithDate(),
    password: getRandomStringWithDate(),
    groups: [customerGroupId]
  };
  const lineAccountValues = {
    restaurant_id: restaurantId,
    line_id: profile.userId,
    display_name: profile.displayName,
    picture_url: profile.pictureUrl,
    visit_count: 1
  };
  const registerLineAccount = useRegisterLineAccount(restaurantId, profile.userId, mutate, signUpValues, lineAccountValues)
  const onSubmit = useSubmitLineAccountRegister(restaurantId, profile.userId, mutate, signUpValues, lineAccountValues)

  const { data: isFirstSurvey, isLoading } = useSwrNoAuth<boolean>(`menu/restaurants/${restaurantId}/first-survey/`);
  if (isLoading) return <CircularLoading />
  if (isFirstSurvey === false) {
    registerLineAccount();
    return <CircularLoading />;
  }
  return (
    <Box p={4}>
      <CustomerForm {...{onSubmit}} />
    </Box>
  );
});

