import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { formatDate } from "../../../utils/formatDate";
import { Avatar, Box, CardBody, CardHeader, Divider, Flex, HStack, VStack } from "@chakra-ui/react";
import { AnsweredQuestions } from "../../organisms/AnsweredQuestions";
import { AnsweredText } from "../../organisms/AnsweredText";
import { useFetchLineAccount } from "../../../hooks/externalApi/line/useFetchLineAccount";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { FaUser } from "react-icons/fa";
import { useFetchVisitHistory } from "../../../hooks/externalApi/line/useFetchVisitHistory";


export const LineAccount: React.FC = memo(() => {
  const { lineAccountId } = useParams();
  const { lineAccount, isLoading } = useFetchLineAccount(lineAccountId);

  if (isLoading || !lineAccount) return <CircularLoading />
  const accountDetails = {
    '性別': lineAccount.customer_sex,
    '生年月': lineAccount.customer_birth_year && `${lineAccount.customer_birth_year}年${lineAccount.customer_birth_month}月`,
    '来店回数': `${lineAccount.visit_count}回`,
    '来店履歴': <VisitHistory lineAccountId={lineAccountId} />,
  };

  return (
    <Box m='auto' w={{base: '100%', sm: '100%', lg: '700px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<FaUser size='16px' />} heading='顧客ステータス'/>
        </CardHeader>
        <CardBody pt={0}>
          <VStack fontWeight='bold' spacing={4}>
            <HStack w='100%' spacing={{base: '14px', sm: '30px'}}>
              <Avatar src={lineAccount.picture_url} boxSize={{base: '50px', sm: '100px'}} />
              <Flex fontSize={{base: 'xl', sm: '3xl'}} alignItems='center'>{lineAccount.display_name}</Flex>
            </HStack>
            <Divider m='0' />
            {Object.entries(accountDetails).map(([label, value]) => (
              <React.Fragment key={label}>
                <Flex w='100%'>
                  <Box w={{base: '30%', sm: '18%'}}>{label}</Box>
                  <Box w={{base: '70%', sm: '82%'}}>{value}</Box>
                </Flex>
                <Divider m='0' />
              </React.Fragment>
            ))}
            <AnsweredQuestions />
            <AnsweredText />
          </VStack>
        </CardBody>
      </DarkShadowCard>
    </Box>
  );
});

const VisitHistory: React.FC<{lineAccountId?: string}> = memo(({lineAccountId}) => {
  const { visitHistory, isLoading } = useFetchVisitHistory(lineAccountId);
  if (isLoading || !visitHistory) return null
  return (
    <VStack align='start' spacing={2} w='100%'>
      {visitHistory.map((visitDate, index) => (
        <React.Fragment key={visitDate}>
          <Box>{formatDate(visitDate)}</Box>
          {index !== visitHistory.length - 1 && <Divider m='0 ' variant='dashed' />}
        </React.Fragment>
      ))}
    </VStack>
  )
})