import { useSwrNoAuth } from "../swr/useSwrNoAuth";


export type ValidSegmentCoupon = {
  id: string;
  img: string;
  title: string;
  text: string;
  expiration_date: string;
  notes: string;
}
export type ValidSoCoupon = {
  id: string;
  title: string;
  explanation: string;
  notes: string;
  img: string;
  expiration_date: string;
}
export type ValidRouletteCoupon = {
  id: string;
  title: string;
  explanation: string;
  notes: string;
  img: string;
  expiration_date: string;
}
type ValidCoupon = {
  coupon_type: 'segment' | 'socoupon' | 'roulette';
  coupon: ValidSegmentCoupon | ValidSoCoupon | ValidRouletteCoupon;
}
export const useFetchValidCoupon = (couponId?: string) => {
  const fetcherKey = couponId ? `membership/valid-coupons/${couponId}` : null;
  const { data: validCoupon, error, isLoading } = useSwrNoAuth<ValidCoupon>(fetcherKey)
  return { validCoupon, isLoading, error };
};