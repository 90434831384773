import { memo } from "react";
import { VStack, Box } from '@chakra-ui/react'
import { useParams, useSearchParams } from 'react-router-dom';
import { TableLayout } from "../../template/table/TableLayout";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { DispatchRequestHistoriesTab } from "../../organisms/kuitan/DispatchRequestHistoriesTab";
import { useFetchPaginatedMonthlyDispatchRequestsByRestaurantId } from "../../../hooks/kuitan/useFetchPaginatedMonthlyDispatchRequestsByRestaurantId";
import { PartialCircularLoading } from "../../molecules/loading/PartialCircularLoading";
import { formatDate, formatMonth } from "../../../utils/formatDate";
import { useFetchPaginatedMultiMonthDispatchRequestsByRestaurantId } from "../../../hooks/kuitan/useFetchPaginatedMultiMonthDispatchRequestsByRestaurantId";


export const DispatchRequestsForRestaurant = () => {
  const { restaurantId = '' } = useParams();
  return (
    <DispatchRequestHistoriesTab
      monthly={<MonthlyRequestHistory restaurantId={restaurantId} />}
      multiMonth={<MultiMonthRequestHistory restaurantId={restaurantId} />}
    />
  );
};


const MonthlyRequestHistory = memo(({restaurantId}: {restaurantId: string}) => {
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const pageSize = 10;
  const { paginatedMonthlyDispatchRequests, isLoading } = useFetchPaginatedMonthlyDispatchRequestsByRestaurantId(pageSize, page, restaurantId)
  if (isLoading || !paginatedMonthlyDispatchRequests) return <PartialCircularLoading />
  const headers = ['派遣月', '件数', '受付日'];
  const rows = paginatedMonthlyDispatchRequests.monthly_dispatch_requests.map((monthly_dispatch_request) => (
    <TableRowWithNavigationLayout
      key={monthly_dispatch_request.id}
      path={`monthly/${monthly_dispatch_request.id}`}
      size='sm'
      childrenList={[
        formatMonth(monthly_dispatch_request.month),
        monthly_dispatch_request.number + '件',
        formatDate(monthly_dispatch_request.requested_at),
      ]}
    />
  ));
  return (
    <VStack>
      <Box w='100%'>
        <TableLayout headers={headers} rows={rows || []} size='sm' />
      </Box>
      <Pagination totalItems={paginatedMonthlyDispatchRequests.count} pageSize={pageSize}/>
    </VStack>
  );
});



const MultiMonthRequestHistory = memo(({restaurantId}: {restaurantId: string}) => {
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const pageSize = 10;
  const { paginatedMultiMonthDispatchRequests, isLoading } = useFetchPaginatedMultiMonthDispatchRequestsByRestaurantId(pageSize, page, restaurantId)
  if (isLoading || !paginatedMultiMonthDispatchRequests) return <PartialCircularLoading />
  const headers = ['期間', '件数', '受付日'];
  const rows = paginatedMultiMonthDispatchRequests.multi_month_dispatch_requests.map((multi_month_dispatch_request) => (
    <TableRowWithNavigationLayout
      key={multi_month_dispatch_request.id}
      path={`multi-month/${multi_month_dispatch_request.id}`}
      size='sm'
      childrenList={[
        multi_month_dispatch_request.period + 'ヶ月',
        multi_month_dispatch_request.number + '件',
        formatDate(multi_month_dispatch_request.requested_at),
      ]}
    />
  ));
  return (
    <VStack>
      <Box w='100%'>
        <TableLayout headers={headers} rows={rows || []} size='sm' />
      </Box>
      <Pagination totalItems={paginatedMultiMonthDispatchRequests.count} pageSize={pageSize}/>
    </VStack>
  );
});