import React, { memo } from "react";
import { CardBody, CardHeader, Divider, Text } from "@chakra-ui/react";
import { LineGraph } from "../graph/LineGraph";
import { DarkShadowCard } from "./DarkShadowCard";


type LineGraphCardProps = {
  title: string;
  labels: string[];
  values: number[];
  graphColorRgb?: string
  height?: string
}
export const LineGraphCard: React.FC<LineGraphCardProps> = memo(({title, labels, values, graphColorRgb, height='200'}) => {
  return (
    <DarkShadowCard rounded='lg'>
      <CardHeader p={2}>
        <Text fontSize='14px' m='0'>{title}</Text>
      </CardHeader>
      <Divider m='0' borderColor='gray.200' />
      <CardBody p={1}>
        <LineGraph labels={labels} values={values} graphColorRgb={graphColorRgb} height={height} />
      </CardBody>
    </DarkShadowCard>
  );
})