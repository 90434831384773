import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { memo } from "react"
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { StampCardPreview } from "../../organisms/membership/StampCardPreview";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const StampCardSettings: React.FC = memo(() => {
  const { restaurantId } = useParams()
  if (!restaurantId) return null;
  return (
    <Box m='auto' w={{base: '100%', md: '500px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='スタンプカード設定'/>
        </CardHeader>
        <CardBody py={2}>
          <VStack spacing={1}>
            <Box m='auto' h={{base: '50vw', sm: '215px'}} w={{base: '80vw', sm: '344px'}}>
              <StampCardPreview restaurantId={restaurantId} />
            </Box>
            <Box m='auto' w={{base: '80vw', sm: '344px'}} fontSize='sm'>※縦横比 8:5</Box>
          </VStack>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn text="編集" path="edit" />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});