import React, { memo } from "react";
import { HStack, Icon } from '@chakra-ui/react'
import { GoCircle } from "react-icons/go";
import { GoCheckCircle } from "react-icons/go";


type StampFieldProps = {
  visitCount: number;
}
export const StampField: React.FC<StampFieldProps> = memo(({visitCount}) => {
  const total = 5;
  const checkCount = visitCount % total === 0 ? total : visitCount % total;
  const numberOfCards = Math.ceil(visitCount / total);
  const stamp = Array(total).fill(0).map((_, index) => index < checkCount ? <Icon as={GoCheckCircle} boxSize={{base: '10vw', sm: '43px'}} /> : <Icon as={GoCircle} boxSize={{base: '10vw', sm: '43px'}} />);
  return (
    <HStack justifyContent='center' spacing={{ base: '3.72vw', sm: '16px' }} w='100%' >
      {stamp.map((item, index) => (
        <React.Fragment key={index}>{item}</React.Fragment>
      ))}
    </HStack>
  );
});