import useSWR from "swr";


type DrinkRating = {
  [key: string]: number;
}
export const useFetchDrinkRating = (restaurantId?: string,) => {
  const fetcherKey = restaurantId ? `kuitan/restaurants/${restaurantId}/ratings/drink/` : null
  const { data: drinkRating, error, isLoading } = useSWR<DrinkRating>(fetcherKey)
  return { drinkRating, isLoading, error };
};