import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const overallScoreSchema = yup.object().shape({
  score: yup.number().required('総合評価を回答してください'),
});
export const usePostOverallScoreForm = () => {
  const { handleSubmit, formState: { isSubmitting, errors }, control } = useForm({
    resolver: yupResolver(overallScoreSchema),
  });
  return {
    handleSubmit,
    control,
    isSubmitting,
    errors,
  };
};