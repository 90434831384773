import { useParams } from "react-router-dom";
import { useEditRouletteSetting } from "../../../../hooks/games/roulette/useEditRouletteSetting";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { SettingsIcon } from "@chakra-ui/icons";
import { SwitchSurveyStatus } from "../../../molecules/form/SwitchSurveyStatus";
import { SelectBranchScore } from "../../../molecules/form/SelectBranchScore";
import { SubmitBtn } from "../../../atoms/button/SubmitBtn";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { useEditRouletteSettingFormForRestaurant } from "../../../../hooks/games/roulette/useEditRouletteSettingFormForRestaurant";
import { useFetchRouletteSettingByRestaurantId } from "../../../../hooks/games/roulette/useFetchRouletteSettingByRestaurantId";
import { SegmentCountInput } from "../../../molecules/form/SegmentCountInput";


export const EditRouletteSettingByRestaurant = () => {
  const { restaurantId } = useParams();
  const { rouletteSetting, isLoading } = useFetchRouletteSettingByRestaurantId(restaurantId);
  const { handleSubmit, register, control, isSubmitting, errors } = useEditRouletteSettingFormForRestaurant(rouletteSetting)
  const onSubmit = useEditRouletteSetting(rouletteSetting?.id)
  if (isLoading) return <CircularLoading />
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='ルーレット設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <SelectBranchScore control={control} errors={errors} />
              <SegmentCountInput register={register} errors={errors} />
              <SwitchSurveyStatus control={control} />
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  )
};