import React, { memo } from "react";
import { useFetchNextRank } from "../../../hooks/membership/useFetchNextRank";
import { useFetchCustomerVisitCount } from "../../../hooks/externalApi/line/useFetchCustomerVisitCount";
import { useFetchCustomerStampCard } from "../../../hooks/membership/useFetchCustomerStampCard";
import { ImageStampCard } from "./ImageStampCard";
import { DefaultStampCard } from "./DefaultStampCard";
import { Skeleton } from "@chakra-ui/react";

type StampCardProps = {
  restaurantId: string;
  lineId: string;
}

export const StampCard: React.FC<StampCardProps> = memo(({restaurantId, lineId}) => {
  const { nextRank, isLoadingNextRank } = useFetchNextRank(restaurantId, lineId)
  const { visitCount, isLoadingVisitCount } = useFetchCustomerVisitCount(restaurantId, lineId)
  const { stampCard, isLoadingStampCard } = useFetchCustomerStampCard(restaurantId)

  if (!visitCount || isLoadingNextRank || isLoadingVisitCount || isLoadingStampCard) return <Skeleton h='100%' w='100%' borderRadius='5px' />
  if (!stampCard || !stampCard.img) return <DefaultStampCard visitCount={visitCount} nextRank={nextRank} />

  return (
    <ImageStampCard imgUrl={stampCard.img} visitCount={visitCount} nextRank={nextRank} />
  );
});