import { useParams } from "react-router-dom";
import { Box, CardBody, CardHeader, Divider, VStack } from "@chakra-ui/react";
import { useFetchIfreaAnalyticsSettingByRestaurantId } from "../../../hooks/ifreaAnalytics/useFetchIfreaAnalyticsSettingByRestaurantId";
import { SettingItem } from "../../organisms/SettingItem";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { RestaurantSettingCardFooter } from "../../organisms/restaurant/RestaurantSettingCardFooter";


export const IfreaAnalyticsSetting = () => {
  const { restaurantId } = useParams();
  const {setting, isLoading} = useFetchIfreaAnalyticsSettingByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />;
  const path = setting ? `${setting.id}/edit` : `register`;
  return (
    <DarkShadowCard w='100%'>
      <CardHeader>
        <CardHeading icon={<SettingsIcon />} heading='ifreaアナリティクス設定'/>
      </CardHeader>
      <CardBody pt={0}>
        <VStack spacing={4} fontWeight='bold' fontSize='sm'>
          <Divider m={0} />
          <Box w='100%'>
            <SettingItem label='ifreaアナリティクス店舗ID' content={setting?.ifrea_analytics_restaurant_id ?? '未登録'} />
          </Box>
          <Divider m={0} />
        </VStack>
      </CardBody>
      <RestaurantSettingCardFooter path={path} />
    </DarkShadowCard>
  );
};