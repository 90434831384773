import useSWR from "swr";

type Table = {
  id: string;
  table_name: string;
  qr_code: string;
  in_use: boolean;
  auth_code: number;
}
export const useFetchTable = (tableId?: string) => {
  const fetcherKey = tableId ? `table_stock/tables/${tableId}` : null;
  const { data: table, error, isLoading } = useSWR<Table>(fetcherKey)
  return { table, isLoading, error }
}