import { useFetchPaginatedAssignedCorporations } from "../../../hooks/corporations/useFetchPaginatedAssignedCorporations";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { TableLayout } from "../../template/table/TableLayout";
import { useParams, useSearchParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { FaRegBuilding } from "react-icons/fa";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { Pagination } from "../../molecules/pagenation/Pagination";


export const AssignedCorporations = () => {
  const { salesmanId } = useParams();
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const pageSize = 10;
  const { paginatedCorporations, isLoading } = useFetchPaginatedAssignedCorporations(pageSize, page, salesmanId)
  if (isLoading) return <CircularLoading />;
  if (!paginatedCorporations) return null
  const headers = ['店舗名', 'ユーザーネーム', '代表者名', '代表者電話番号'];
  const rows = paginatedCorporations.corporations.map((corporation) => (
    <TableRowWithNavigationLayout
      key={corporation.id}
      path={`${corporation.id}`}
      childrenList={[
        corporation.name,
        corporation.username,
        corporation.president_last_name + corporation.president_first_name,
        corporation.president_phone_number
      ]}
    />
  ));
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<FaRegBuilding />} heading='法人一覧'/>
      </CardHeader>
      <CardBody py={2}>
        <TableLayout headers={headers} rows={rows || []} size='sm' />
      </CardBody>
      <CardFooter>
        <Pagination totalItems={paginatedCorporations.count} pageSize={pageSize}/>
      </CardFooter>
    </DarkShadowCard>
  );
};