import { useSwrNoAuth } from "../swr/useSwrNoAuth";


export type StaffSetting = {
  g_url: string;
  is_navigate_g_url: boolean;
  restaurant_branch_score: number;
  restaurant_branch_question: string;
  staff_branch_score: number;
  staff_branch_question: string;
}
export const useFetchStaffSettingCustomerByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `restaurant_staff/restaurants/${restaurantId}/setting/customer/` : null;
  const { data: staffSetting, error, isLoading: isLoadingStaffSetting } = useSwrNoAuth<StaffSetting>(fetcherKey)
  return { staffSetting, isLoadingStaffSetting, error };
};