import { Box, Divider } from '@chakra-ui/react';
import { SidebarNavWrapper } from '../../template/SidebarNavWrapper';
import { AdminSidebarMenu } from './AdminSidebarMenu';


export const AdminSidebar = () => {
  return (
    <Box>
      <Box px={4} py={3} fontSize='24px'>BLOCKs</Box>
      <Divider m='0' />
      <Box px={4} py={3}>
        管理者
      </Box>
      <Box px={2}>
        <Divider m='0' />
      </Box>
      <SidebarNavWrapper>
        <AdminSidebarMenu/>
      </SidebarNavWrapper>
    </Box>
  );
};