import React, { memo } from "react";
import { Text, Flex, Image } from '@chakra-ui/react'

type DefaultRankCardTopProps = {
  name: string;
}

export const DefaultRankCardTop: React.FC<DefaultRankCardTopProps> = memo(({name}) => {
  return (
    <Flex align='center' justifyContent='space-between' w='100%'>
      <Text fontSize={{ base: '3.26vw', sm: '14px' }} fontWeight='bold' m='0'>{name}さん</Text>
      <Image
        src='https://storage.googleapis.com/blocks-api/images/membership-decoration1.png'
        alt=''
        boxSize={{ base: '8vw', sm: '34.5px' }}
      />
    </Flex>
  );
});

