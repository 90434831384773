import React from "react";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { Box, CardBody, CardFooter, CardHeader, Divider, HStack, Icon, Image, Link, VStack } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingItem } from "../../organisms/SettingItem";
import { IoMdPerson } from "react-icons/io";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { useFetchKuitanAgent } from "../../../hooks/kuitan/useFetchKuitanAgent";
import { CircularLoading } from "../../molecules/loading/CircularLoading";


export const KuitanAgentBaseInfo = () => {
  const { kuitanAgentId } = useParams()
  const { kuitanAgent, isLoading } = useFetchKuitanAgent(kuitanAgentId)
  if (isLoading) return <CircularLoading />
  if (!kuitanAgent) return null
  const { tabelog, instagram, tiktok, x } = kuitanAgent;
  const { username, email, phone_number, last_name, first_name } = kuitanAgent.user_data;
  const agentDetails = [
    { label: 'ユーザーネーム', value: username },
    { label: '電話番号', value: phone_number },
    { label: 'メールアドレス', value: email },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<IoMdPerson />} heading={`${last_name}${first_name}`}/>
        </CardHeader>
        <CardBody py={2}>
          <VStack align='start' fontWeight='bold' spacing={3} w='100%' fontSize='sm'>
            <Divider my={2} />
            {agentDetails.map((detail) => (
              <React.Fragment key={detail.label}>
                <Box w='100%'>
                  <SettingItem label={detail.label} content={detail.value}/>
                </Box>
                <Divider my={2} />
              </React.Fragment>
            ))}
            <Sns tabelogUrl={tabelog} tiktokUrl={tiktok} instagramUrl={instagram} xUrl={x}/>
          </VStack>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集'/>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
};


const Sns = ({tabelogUrl, tiktokUrl, instagramUrl, xUrl}: {tabelogUrl: string, tiktokUrl: string, instagramUrl: string, xUrl: string}) => {
  const snsLinks = [
    { href: tabelogUrl, icon: <Image src='/images/tabelog.png' alt='tabelog' boxSize='28px' rounded={5} boxShadow='0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)' /> },
    { href: tiktokUrl, icon: <Icon as={FaTiktok} boxSize='28px' p={1} rounded={5} bg='black' color='white' /> },
    { href: instagramUrl, icon: <Icon as={FaInstagram} boxSize='28px' p={.5} rounded={5} bg='linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888)' color='white' boxShadow='0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)' /> },
    { href: xUrl, icon: <Icon as={FaXTwitter} boxSize='28px' p={1} rounded={5} bg='black' color='white' /> }
  ];
  return (
    <HStack spacing={3}>
      {snsLinks.map((link, index) => (
        <Link key={index} href={link.href} isExternal>
          {link.icon}
        </Link>
      ))}
    </HStack>
  );
};