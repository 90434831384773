import React, { memo } from "react";
import { Card, CardBody, VStack } from '@chakra-ui/react'
import { DefaultRankCardTop } from "./DefaultRankCardTop";
import { DefaultRankCardCenter } from "./DefaultRankCardCenter";
import { DefaultRankCardBottom } from "./DefaultRankCardBottom";

type DefaultRankCardProps = {
  displayName: string;
  rank: string;
  lastVisitDate: string;
  logo?: string;
}

export const DefaultRankCard: React.FC<DefaultRankCardProps> = memo(({displayName, rank, lastVisitDate, logo}) => {
  return (
    <Card w='100%' h='100%' variant='filled'>
      <CardBody p={3}>
        <VStack justifyContent='space-between' h='100%'>
          <DefaultRankCardTop name={displayName} />
          <DefaultRankCardCenter rank={rank} />
          <DefaultRankCardBottom lastVisitDate={lastVisitDate} logo={logo} />
        </VStack>
      </CardBody>
    </Card>
  );
});

