import { useEffect, useState } from "react"
import liff from '@line/liff';
import { useSetRecoilState } from "recoil";
import { lineProfileState } from "../../store/lineProfileState";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";
import { customerRestaurantId } from "../../store/customerRestaurantId";
import { useGetRestaurantIdFromMembershipUrl } from "./useGetRestaurantIdFromMembershipUrl";

export const useLiff = () => {
  const setLineProfile = useSetRecoilState(lineProfileState)
  const setCustomerRestaurantId = useSetRecoilState(customerRestaurantId)
  const [fetchFin, setFetchFin] = useState<boolean>(false)

  const isCouponsInUrl = (): boolean => {
    return window.location.href.includes('/coupons/');
  };
  const liffType = isCouponsInUrl() ? 'membership-coupon' : 'membership';

  const getRestaurantIdFromUrl = useGetRestaurantIdFromMembershipUrl(isCouponsInUrl());

  const initLiff = async (liffId: string) => {
    try {
      await liff.init({ liffId: liffId})
      if (liff.isLoggedIn() === false) {
        await liff.login({});
      } else {
        const profile = await liff.getProfile();
        setLineProfile(profile);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchFin(false)
        const restaurantId = await getRestaurantIdFromUrl();
        setCustomerRestaurantId(restaurantId)
        const response = await customNoAuthAxios.get(`external_api/restaurants/${restaurantId}/liff-restaurants/${liffType}/liff-id/`);
        const liffId = response.data;
        await initLiff(liffId);
      } catch (error) {
      } finally {
        setFetchFin(true)
      }
    };
    fetchData();
    }, []);

  return fetchFin;
}