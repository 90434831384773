import { Route } from 'react-router-dom';
import AdminBase from '../components/template/base/AdminBase';
import { Teams } from '../components/pages/kuitan/Teams';
import { Team } from '../components/pages/kuitan/Team';
import { EditTeam } from '../components/pages/kuitan/EditTeam';
import { SignUpAuthCode } from '../components/pages/kuitan/SignUpAuthCode';
import { EditSignUpAuthCode } from '../components/pages/kuitan/EditSignUpAuthCode';
import { CreateTeam } from '../components/pages/kuitan/CreateTeam';
import { CorporationsRoutesForAdmin } from './CorporationsRoutesForAdmin';
import { RestaurantsRoutesForAdmin } from './RestaurantsRoutesForAdmin';


export const AdminRoutes = (
  <Route path="admin" element={<AdminBase />}>
    <Route path="teams" element={<Teams/>} />
    <Route path="teams/:teamId" element={<Team/>} />
    <Route path="teams/:teamId/edit" element={<EditTeam/>} />
    <Route path="teams/create" element={<CreateTeam/>} />
    <Route path="signup/auth-code" element={<SignUpAuthCode/>} />
    <Route path="signup/auth-code/edit" element={<EditSignUpAuthCode/>} />
    {CorporationsRoutesForAdmin}
    {RestaurantsRoutesForAdmin}
  </Route>
);