import React from "react"
import { useParams } from "react-router-dom";
import { Badge, Box, CardBody, CardHeader, Divider, VStack } from "@chakra-ui/react";
import { SettingItem } from "../../organisms/SettingItem";
import { useFetchManLaboSettingByRestaurantId } from "../../../hooks/manzokudoLabo/useFetchManLaboSettingByRestaurantId";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { RestaurantSettingCardFooter } from "../../organisms/restaurant/RestaurantSettingCardFooter";


export const ManLaboSetting = () => {
  const { restaurantId } = useParams();
  const {manLaboSetting, isLoading} = useFetchManLaboSettingByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />;
  const path = manLaboSetting ? `${manLaboSetting?.id}/edit` : 'register';
  const settingItems = [
    {label: 'ブランチスコア', content: manLaboSetting?.branch_score ?? '未登録'},
    {label: '遷移先URL', content: manLaboSetting?.g_url ?? '未登録'},
    {label: '遷移先URLへの遷移', content: manLaboSetting?.is_navigate_g_url ? <Badge colorScheme='green'>ON</Badge> : <Badge colorScheme='gray'>OFF</Badge>},
    {label: 'ブランチクエッション', content: manLaboSetting?.branch_question_name ?? '未登録'},
  ];
  return (
    <DarkShadowCard w='100%'>
      <CardHeader pb={0}>
        <CardHeading icon={<SettingsIcon />} heading='満ラボ設定'/>
      </CardHeader>
      <CardBody>
        <VStack align='start' fontSize='sm' fontWeight='bold' spacing={4} w='100%'>
          <Divider my={0} />
          {settingItems.map((detail) => (
            <React.Fragment key={detail.label}>
              <Box w='100%'>
                <SettingItem label={detail.label} content={detail.content}/>
              </Box>
              <Divider my={0} />
            </React.Fragment>
          ))}
        </VStack>
      </CardBody>
      <RestaurantSettingCardFooter path={path} />
    </DarkShadowCard>
  );
};