import { Box, CardBody, CardHeader, Divider, VStack } from "@chakra-ui/react";
import { SettingItem } from "../../../organisms/SettingItem";
import { useFetchLineDevByRestaurantId } from "../../../../hooks/externalApi/line/useFetchLineDevByRestaurantId";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { RestaurantSettingCardFooter } from "../../../organisms/restaurant/RestaurantSettingCardFooter";


export const LineDevSetting = () => {
  const {restaurantId} = useParams();
  const {lineDev, isLoading} =useFetchLineDevByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />;
  const path = lineDev ? `${lineDev.id}/edit` : 'register';
  return (
    <DarkShadowCard w='100%'>
      <CardHeader pb={0}>
        <CardHeading icon={<SettingsIcon />} heading='LineDev設定'/>
      </CardHeader>
      <CardBody>
        <VStack align='start' spacing={4} fontWeight='bold' fontSize='sm'>
          <Divider m={0} />
          <Box w='100%'>
            <SettingItem label='アクセストークン' content={lineDev?.access_token ?? '未登録'} />
          </Box>
          <Divider m={0} />
          <Box w='100%'>
            <SettingItem label='チャンネルシークレット' content={lineDev?.channel_secret ?? '未登録'} />
          </Box>
          <Divider m={0} />
        </VStack>
      </CardBody>
      <RestaurantSettingCardFooter path={path} />
    </DarkShadowCard>
  );
};