import React, { memo } from 'react';
import { useFetchSoCouponSurveyScores } from '../../../hooks/socoupon/useFetchSoCouponQuestionScores';
import { SurveyScoreCards } from '../SurveyScoreCards';


type SoCouponQuestionScoresProps = {
  restaurantId: string;
}
export const SoCouponQuestionScores: React.FC<SoCouponQuestionScoresProps> = memo(({restaurantId}) => {
  const { soCouponSurveyScores, isLoading, } = useFetchSoCouponSurveyScores(restaurantId)
  return <SurveyScoreCards isLoading={isLoading} surveyScores={soCouponSurveyScores} />
});