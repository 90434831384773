import {
  Accordion,
  List,
  ListItem,
} from '@chakra-ui/react'
import { SidebarItemLink } from "../../molecules/SidebarItemLink";


export const AdminSidebarMenu = () => {
  const menuItems = [
    { component: <SidebarItemLink to='corporations' text="法人" />, key: 'corporations' },
    { component: <SidebarItemLink to='restaurants' text="店舗" />, key: 'restaurants' },
    { component: <SidebarItemLink to='teams' text="チーム" />, key: 'teams' },
    { component: <SidebarItemLink to='signup/auth-code' text="サインアップ認証コード" />, key: 'signup-codes' },
  ];
  return (
    <Accordion allowMultiple>
      <List spacing={1}>
        {menuItems.map(item => (
          <ListItem key={item.key}>
            {item.component}
          </ListItem>
        ))}
      </List>
    </Accordion>
  );
};