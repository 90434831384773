import useSWRInfinite from "swr/infinite";


type InsightsValue = {
  value?: string;
  threshold?: string;
};
export type SearchKeywordCount = {
  searchKeyword: string;
  insightsValue: InsightsValue;
};
type SearchKeywordsResponse = {
  searchKeywordsCounts: SearchKeywordCount[];
  nextPageToken: string;
};
export const useFetchSearchKeywords = (restaurantId?: string, requestQueryParams?: string) => {
  const fetcherKey = restaurantId ? `/external_api/restaurants/${restaurantId}/google/search-keywords/${requestQueryParams}` : null;
  const getKey = (pageIndex: number, previousPageData: any) => {
    if (previousPageData && !previousPageData.searchKeywordsCounts) return null
    if (pageIndex === 0) return `${fetcherKey}`
    return `${fetcherKey}${requestQueryParams ? '&' : '?'}page_token=${encodeURIComponent(previousPageData.nextPageToken)}`;
  }
  const { data, error, isLoading, isValidating, size, setSize } = useSWRInfinite<SearchKeywordsResponse>(getKey)
  return { data, isLoading, error, size, setSize, isValidating };
};