import React, { memo } from "react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useNavigate, useParams } from "react-router-dom";
import { BenefitContents } from "../../organisms/membership/BenefitContents";
import { useFetchBenefit } from "../../../hooks/membership/useFetchBenefit";
import { Badge, Box, Card, CardBody, HStack, IconButton, VStack } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { BenefitDeleteModal } from "../../organisms/membership/BenefitDeleteModal";


export const Benefit: React.FC = memo(() => {
  const { benefitId } = useParams();
  const { benefit, isLoading } = useFetchBenefit(benefitId)
  if (isLoading) return <CircularLoading/>
  if (!benefit) return null
  return (
    <VStack w={{base: '100%', sm: '405px'}} m='auto'>
      <HStack justify='left' w='100%'>
        {benefit.rank_names.map(rank => <Badge key={rank} colorScheme="messenger">{rank}</Badge>)}
      </HStack>
      <Box w='100%'>
        <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.08) 1px 3.5px 5.5px'>
          <CardBody>
            <BenefitContents {...benefit} />
          </CardBody>
        </Card>
      </Box>
      <EditAndDeleteIcons id={benefit.id} title={benefit.title}/>
    </VStack>
  );
});

const EditAndDeleteIcons: React.FC<{ id: string, title: string }> = memo(({ id, title }) => {
  const navigate = useNavigate()
  return (
    <HStack justify='center' spacing={1} w='100%'>
      <IconButton
        colorScheme='messenger'
        aria-label='Edit Benefit'
        icon={<EditIcon />}
        size='sm'
        variant='ghost'
        onClick={() => navigate('edit')}
      />
      <BenefitDeleteModal id={id} title={title}/>
    </HStack>
  )
})