import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Input, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { useRegisterBenchmarkRestaurantForm } from "../../../hooks/ifreaAnalytics/useRegisterBenchmarkRestaurantForm";
import { useRegisterBenchmarkRestaurant } from "../../../hooks/ifreaAnalytics/useRegisterBenchmarkRestaurant";


export const RegisterBenchmarkRestaurant = () => {
  const { salesmanId } = useParams();
  const { handleSubmit, register, isSubmitting, errors } = useRegisterBenchmarkRestaurantForm();
  const onSubmit = useRegisterBenchmarkRestaurant(salesmanId);
  const nameError = errors.name?.message;
  const urlError = errors.url?.message;
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='ベンチマーク店舗登録'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.name} w='100%'>
                <FormLabel>店舗名</FormLabel>
                <Input type="text" {...register('name')} />
                <FormErrorMessage>{nameError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.url} w='100%'>
                <FormLabel>URL</FormLabel>
                <Input type="text" {...register('url')} />
                <FormErrorMessage>{urlError}</FormErrorMessage>
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};