import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";

type CorporationNameInputFieldProps = {
  register: any;
  errors: any;
}
export const CorporationNameInputField: React.FC<CorporationNameInputFieldProps> = memo(({register, errors}) => {
  const corporationNameError = errors.corporation?.name?.message;
  return (
    <FormControl isRequired isInvalid={errors.corporation?.name} w='100%'>
      <FormLabel>法人名</FormLabel>
      <Input type="text" {...register('corporation.name')} />
      <FormErrorMessage>{corporationNameError}</FormErrorMessage>
    </FormControl>
  );
});