import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';


export const useRequestMultiMonthDispatchBySalesman = () => {
  const { corporationId } = useParams();
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = useCallback(async (data: any) => {
    try {
      data.dispatch.corporation = corporationId;
      const res = await customAuthAxios.post(
        'kuitan/salesman/dispatch/request/multi-month/',
        data
      );
      showToast(res.data.message)
      navigate('../dispatch/requests?method=multi-month');
    } catch (error:any) {
    }
  }, [navigate, showToast]);
  return onSubmit;
};