import React, { ReactNode, memo } from "react"
import { Card, CardProps } from "@chakra-ui/react";

type DarkShadowCardProps = {
  children: ReactNode
}
type CombineProps = DarkShadowCardProps & CardProps
export const DarkShadowCard: React.FC<CombineProps> = memo(({children, ...props}) => {
  return (
    <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.1) 1px 1px 7px' {...props}>
      {children}
    </Card>
  );
});