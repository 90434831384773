import { memo } from "react"
import { Box, Card, CardBody, CardFooter, CardHeader, HStack, Image, Text } from "@chakra-ui/react";
import { useFetchBenefitsByRestaurantId } from "../../../hooks/membership/useFetchBenefitsByRestaurantId";
import { CardHeading } from "../../molecules/card/CardHeading";
import { IoDiamondSharp } from "react-icons/io5";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { TableLayout } from "../../template/table/TableLayout";
import { PartialCircularLoading } from "../../molecules/loading/PartialCircularLoading";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { useParams } from "react-router-dom";


export const Benefits: React.FC = memo(() => {
  const { restaurantId } = useParams()
  if (!restaurantId) return null;
  return (
    <Box w={{base: '100%', lg: '90%'}} m='auto'>
      <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.08) 1px 3.5px 5.5px'>
        <CardHeader>
          <CardHeading icon={<IoDiamondSharp fontSize='sm' />} heading='特典一覧'/>
        </CardHeader>
        <CardBody py={0}>
          <BenefitsTable restaurantId={restaurantId} />
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='register' text='作成' />
        </CardFooter>
      </Card>
    </Box>
  );
});


const BenefitsTable: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { benefits, isLoading} = useFetchBenefitsByRestaurantId(restaurantId)
  if (isLoading || !benefits) return <PartialCircularLoading />
  const headers = ['特典名', 'ランク'];
  const rows = benefits.map((benefit) => (
    <TableRowWithNavigationLayout
      key={benefit.id}
      path={`${benefit.id}`}
      size='sm'
      childrenList={[
        <HStack>
          {benefit.img ? (
            <Image src={benefit.img} boxSize='38px' />
          ) : (
            <Box bg='#F99E16' boxSize='38px' outline="1px solid rgb(255, 255, 255)" outlineOffset="-3px" />
          )}
          <Text m='0' overflowWrap="break-word" whiteSpace="normal" noOfLines={1}>{benefit.title}</Text>
        </HStack>,
        <Text m='0' overflowWrap="break-word" whiteSpace="normal" noOfLines={1}>{benefit.rank_names.join(', ')}</Text>
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />;
})