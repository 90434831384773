import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';


export const useEditCorporationPass = (corporationId: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();

  const editPass = async (data: any) => {
    return await customAuthAxios.put(`authentication/corporations/${corporationId}/change-password/`, data);
  };

  const onSubmit = useCallback(async (data: any) => {
    try {
      await editPass(data);
      showToast('変更が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  }, [navigate]);

  return onSubmit;
};