import React, { memo } from "react";
import { Box, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { HoverPopover } from "../organisms/HoverPopover";
import { useRecoilState } from "recoil";
import { ordState } from "../../store/ordState";

export const SortLastVisitDateHoverPopover: React.FC = memo(() => {
  const [ord, setOrd] = useRecoilState(ordState)
  return (
    <HoverPopover w='130px'>
      <Box>
        最終来店日 <ChevronDownIcon />
      </Box>
      <>
        <Button
          onClick={() => {setOrd('new')}}
          isDisabled={ord === 'new' || ord === null}
          w='100%'
          borderRadius='none'
          justifyContent='start'
          size='sm'
        >
          新しい順
        </Button>
        <Button
          onClick={() => {setOrd('old')}}
          isDisabled={ord === 'old'}
          w='100%'
          borderRadius='none'
          justifyContent='start'
          size='sm'
        >
          古い順
        </Button>
      </>
    </HoverPopover>
  );
});
