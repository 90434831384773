import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { Box, Card, CardBody, Text, VStack } from "@chakra-ui/react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useSwrNoAuth } from "../../../hooks/swr/useSwrNoAuth";
import { CouponLayout } from "../../molecules/CouponLayout";
import { SoCouponUsageStatusBtn } from "../../organisms/soCoupon/SoCouponUsageStatusBtn";


type SoCoupon = {
  id: string;
  title: string;
  explanation: string;
  notes: string;
  expiration_date: string;
  img: string;
}
export const CustomerSoCoupon: React.FC = memo(() => {
  const { lineAccountId } = useParams();
  const { data: soCoupon, isLoading: isLoadingSoCoupon } = useSwrNoAuth<SoCoupon>(`socoupon/customers/${lineAccountId}/coupon/`);
  if (isLoadingSoCoupon || !soCoupon) return <CircularLoading />
  return (
    <Box>
      <Box textAlign="center" bg='#E60123' p='5'>
        <Text as='b' fontSize='lg' color='white'>こちらをスタッフまでご提示ください</Text>
      </Box>
      <VStack p={5} spacing={4}>
        <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.1) 0px 0px 6px'>
          <CardBody >
            <CouponLayout {...soCoupon} />
          </CardBody>
        </Card>
        <SoCouponUsageStatusBtn soCouponId={soCoupon.id} lineAccountId={lineAccountId!} />
      </VStack>
    </Box>
  );
});