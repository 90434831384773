import { memo } from "react";

export const MultipliedText: React.FC<{ text: string; count: number;}> = memo(({ text, count }) => {
  const multipliedText = text.repeat(count);

  return (
    <>
      {multipliedText}
    </>
  );
});