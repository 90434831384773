import React, { memo } from "react";
import { Box, Divider, Flex, HStack, Radio, RadioGroup, Text, VStack } from "@chakra-ui/react"
import { useRecoilState } from "recoil";
import { googleReviewsDateRangeState } from "../../../../store/googleReviewsDateRangeState";
import { googleReviewRatingState } from "../../../../store/googleReviewRatingState";
import { googleReviewReplyState } from "../../../../store/googleReviewReplyState";
import { DateFilter } from "../../../molecules/DateFilter";
import { RatingFilter } from "../../../molecules/RatingFilter";


export const FilterSection: React.FC = () => {
  const [dateRange, setDateRange] = useRecoilState(googleReviewsDateRangeState);
  const [reviewRating, setReviewRating] = useRecoilState(googleReviewRatingState);
  const filters = [
    { label: '投稿日', component: <DateFilter dateRange={dateRange} setDateRange={setDateRange} /> },
    { label: '口コミ評価', component: <RatingFilter reviewRating={reviewRating} setReviewRating={setReviewRating} /> },
    { label: '返信状況', component: <ReplyStateFilter /> },
  ];
  return (
    <VStack spacing={0}>
      {filters.map((filter, index) => (
        <React.Fragment key={index}>
          <VStack align='start' w='100%'>
            <Box fontSize='sm' fontWeight='bold'>{filter.label}</Box>
            <Box w='100%'>{filter.component}</Box>
          </VStack>
          {index < filters.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </VStack>
  );
}


const ReplyStateFilter: React.FC = memo(() => {
  const [reviewReplyState, setReviewReplyState] = useRecoilState(googleReviewReplyState);
  const replyStates = [
    { value: '0', label: '未返信' },
    { value: '1', label: '返信済' },
  ];
  return (
    <RadioGroup onChange={setReviewReplyState} defaultValue={reviewReplyState ?? ''}>
      <HStack spacing={5} fontSize='sm'>
        {replyStates.map(state => (
          <Radio key={state.value} m='0' value={state.value} spacing={1}>
            <Text fontSize='sm' m='0' fontWeight='normal'>
              {state.label}
            </Text>
          </Radio>
        ))}
      </HStack>
    </RadioGroup>
  );
});