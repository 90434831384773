// export const corporationGroupId = 2
// export const salesmanGroupId = 3
// export const restaurantGroupId = 4
// export const customerGroupId = 5


// 本番
export const salesmanGroupId = 1
export const corporationGroupId = 2
export const restaurantGroupId = 3
export const customerGroupId = 4
export const managerGroupId = 5
export const kuitanAgentGroupId = 6