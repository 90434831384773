import { useEffect } from "react";
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";


export const useResult = (lineAccountId: string|null ,isWin: boolean) => {
  useEffect(() => {
    if (!lineAccountId) return
    const giveCoupon = async () => {
      await customNoAuthAxios.post(`games/roulette/results/`, {
        line_account: lineAccountId,
        is_win: isWin,
      });
    };
    giveCoupon();
  }, [lineAccountId, isWin]);
};