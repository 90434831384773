import React, { memo } from "react";
import { Grid, VStack } from "@chakra-ui/react";
import { CenterRightDataSection } from "./CenterRightDataSection";
import { CenterLeftDataSection } from "./CenterLeftDataSection";
import { useSetRecoilState } from "recoil";
import { dashboardDateRangeState } from "../../../store/dashboardDateRangeState";
import { DateFilterSection } from "../DateFilterSection";
import { useFetchRestaurant } from "../../../hooks/restaurants/useFetchRestaurant";


type CenterDataSectionProps = {
  restaurantId: string;
}
export const CenterDataSection: React.FC<CenterDataSectionProps> = memo(({restaurantId}) => {
  const setDateRange = useSetRecoilState(dashboardDateRangeState)
  const {restaurant} = useFetchRestaurant(restaurantId);
  if (!restaurant) return null;
  const addOns = restaurant.add_ons_names
  return (
    <VStack w='100%' spacing={2}>
      <DateFilterSection setDateRange={setDateRange} />
      <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)'}} gap={{base: 4, sm: 5, lg: 5}} w='100%'>
        <CenterLeftDataSection restaurantId={restaurantId}/>
        <CenterRightDataSection restaurantId={restaurantId} addOns={addOns} />
      </Grid>
    </VStack>
  );
});