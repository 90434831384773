import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { Controller } from "react-hook-form";

type SwitchRemindStatusProps = {
  control: any;
}
export const SwitchRemindStatus: React.FC<SwitchRemindStatusProps> = ({control}) => {
  return (
    <Controller
      name="remind"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl>
          <FormLabel>リマインド</FormLabel>
          <Switch size='md' mb='0' isChecked={value} onChange={(e: any) => onChange(e.target.checked)} />
        </FormControl>
      )}
    />
  );
}