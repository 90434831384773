import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { AnsweredCustomersLayout } from '../../template/AnsweredCustomersLayout';
import { useFetchPaginateSoCouponQuestionWithLineAccounts } from '../../../hooks/socoupon/useFetchPaginateSoCouponQuestionWithLineAccounts';
import { Paginator } from '../../molecules/pagenation/Paginator';
import { CircularLoading } from '../../molecules/loading/CircularLoading';
import { Box, CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { LightShadowCard } from '../../molecules/card/LightShadowCard';
import { CardHeading } from '../../molecules/card/CardHeading';
import { GiStarsStack } from "react-icons/gi";


export const SoCouponSurveyQuestionAnsweredCustomers: React.FC = memo(() => {
  const { questionId } = useParams();
  const { soCouponQuestionWithLineAccountsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage } = useFetchPaginateSoCouponQuestionWithLineAccounts(questionId!)
  if (isLoading) return <CircularLoading />
  if (!soCouponQuestionWithLineAccountsPerPage) return null
  return (
    <Box m='auto' w='100%'>
      <LightShadowCard>
        <CardHeader>
          <CardHeading icon={<GiStarsStack/>} heading={soCouponQuestionWithLineAccountsPerPage.question}/>
        </CardHeader>
        <CardBody py={1}>
          <AnsweredCustomersLayout
            answerScores={soCouponQuestionWithLineAccountsPerPage.socoupon_survey_answer_score_with_line_accounts.map(score => ({
              id: score.id,
              line_account: score.line_account,
              score: score.answer_score,
              date: score.answered_at,
            }))}
          />
        </CardBody>
        <CardFooter>
          <Paginator pagesCount={pagesCount} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
        </CardFooter>
      </LightShadowCard>
    </Box>
  );
});