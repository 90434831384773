import { customAuthAxios } from "../../../utils/customAuthAxios";
import { useSuccessToast } from "../../toast/useSuccessToast";


export const usePostBusinessLatestInfo = (restaurantId: string, reset: () => void) => {
  const showToast = useSuccessToast();
  const onSubmit = async (data:any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("summary", data.summary);
      uploadData.append("action_button_url", data.action_button_url);
      data.img[0] && uploadData.append("img", data.img[0]);
      const res = await customAuthAxios.post(`external_api/restaurants/${restaurantId}/google/business-latest-info/`, uploadData);
      showToast(res.data.message);
      reset();
    } catch (error:any) {
    }
  };
  return {onSubmit};
};