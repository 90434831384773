import React from "react";
import { PartialCircularLoading } from "../molecules/loading/PartialCircularLoading";


type LineAccountsTableHocProps = {
  isLoading: boolean;
  data?: any[];
  children: (data: any[]) => React.ReactNode;
}

export const LineAccountsTableHoc: React.FC<LineAccountsTableHocProps> = ({isLoading, data, children }) => {
  if (isLoading) return <PartialCircularLoading />
  if (!data) return null
  const flatData = data.flat();
  if (flatData.length===0) return <>データが存在しません</>

  return <>{children(flatData)}</>;
};