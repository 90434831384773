import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const editSoCouponSettingByRestaurantSchema = yup.object().shape({
  branch_score: yup.number().required('入力必須項目です'),
  socoupon_in_use: yup.string().required('入力必須項目です'),
  remind: yup.boolean(),
  qr_card_img: yup.mixed(),
});
type SoCouponSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  socoupon_in_use: string|null;
  remind: boolean;
}
export const useEditSoCouponSettingFormForRestaurant = (soCouponSetting?: SoCouponSetting) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(editSoCouponSettingByRestaurantSchema),});
  useEffect(() => {
    if (soCouponSetting) {
      setValue('branch_score', soCouponSetting.branch_score);
      setValue('socoupon_in_use', soCouponSetting.socoupon_in_use ?? '');
      setValue('remind', soCouponSetting.remind);
    }
  }, [soCouponSetting]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
    setValue
  };
};