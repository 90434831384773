import useSWR from "swr";

type LineAccount = {
  id: string;
  customer_sex: string;
  customer_birth_year: string;
  customer_birth_month: string;
  line_id: string;
  display_name: string;
  picture_url?: string;
  visit_count: number;
  registered_date: string;
  last_visit: string;
  is_active: boolean;
  blocked_date: null | string;
  customer: string;
  restaurant: string;
};

type SoCouponSurveyAnswerTextsWithLineAccounts = {
  id: string;
  line_account: LineAccount;
  answer_text: string;
  answered_at: string;
}[];

type SoCouponSurveyAnswerTextsWithLineAccountsPerPage = {
  count: number;
  socoupon_survey_answer_texts_with_line_accounts: SoCouponSurveyAnswerTextsWithLineAccounts;
};

export const useFetchSoCouponSurveyAnswerTextsWithLineAccountsPerPage = (restaurantId: string, pageSize: number, currentPage: number) => {
  const { data: soCouponSurveyAnswerTextsWithLineAccountsPerPage, error, isLoading } = useSWR<SoCouponSurveyAnswerTextsWithLineAccountsPerPage>(`socoupon/survey/texts/with-line-accounts/?restaurantId=${restaurantId}&pageSize=${pageSize}&currentPage=${currentPage}`)
  return { soCouponSurveyAnswerTextsWithLineAccountsPerPage, isLoading, error };
};