import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  List,
  ListItem,
} from '@chakra-ui/react'
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";
import { SidebarItemLink } from "../../molecules/SidebarItemLink";
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";
import { useParams } from 'react-router-dom';
import { useFetchManagerStatus } from '../../../hooks/kuitan/useFetchManagerStatus';


export const SalesmanSidebarMenu = () => {
  const menuItems = [
    { component: <SidebarItemLink to='' text="基本情報" />, key: 'basic-info' },
    { component: <SidebarItemLink to='corporations' text="法人" />, key: 'corporations' },
    { component: <SidebarItemLink to='restaurants' text="店舗" />, key: 'restaurants' },
    { component: <NoticeAccordionMenu />, key: 'notice-menu' },
    { component: <KuitanAccordionMenu />, key: 'kuitan-menu' },
    { component: <TabelogScoreAccordionMenu />, key: 'tabelog-score-menu' },
  ];
  return (
    <Accordion allowMultiple>
      <List spacing={1}>
        {menuItems.map(item => (
          <ListItem key={item.key}>
            {item.component}
          </ListItem>
        ))}
      </List>
    </Accordion>
  );
};


const NoticeAccordionMenu = () => {
  const links = [
    { to: 'notices/new', text: '新規作成' },
    { to: 'notices', text: '履歴' },
  ];
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          お知らせ
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          {links.map(link => <AccordionItemLink key={link.to} to={link.to} text={link.text} />)}
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
};


const KuitanAccordionMenu = () => {
  const links = [
    { to: 'kuitan/dispatch/request', text: '派遣依頼' },
    { to: 'kuitan/dispatch/requests', text: '派遣依頼履歴' },
    { to: 'kuitan/dispatch/corporations', text: '投稿履歴' },
  ];
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          食イタン
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          {links.map(link => <AccordionItemLink key={link.to} to={link.to} text={link.text} />)}
          <ManagerAccordionMenu />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
};

const ManagerAccordionMenu = () => {
  const { salesmanId } = useParams();
  const { isManager, isLoading } = useFetchManagerStatus(salesmanId);
  if (isLoading || !isManager) return null;
  return (
    <AccordionItem border='none'>
      <AccordionButton px='10px' py={1} >
        <Box flex='1' textAlign='left' fontWeight='bold'>
          責任者
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItemLink to={`kuitan/dispatch/requests/restaurants`} text="依頼店舗" />
          <AccordionItemLink to={`kuitan/teams/dispatch/requests`} text="チーム派遣依頼履歴" />
          <AccordionItemLink to={`kuitan/signup/auth-code`} text="サインアップコード" />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
};


const TabelogScoreAccordionMenu = () => {
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          食べログスコア
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItemLink to={'tabelog-scores/benchmark-restaurants'} text="ベンチマーク店舗" />
          <AccordionItemLink to={'tabelog-scores'} text="点数反映日データ" />
          <AccordionItemLink to={'tabelog-scores/salesman-linking'} text="ID紐づけ" />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
};