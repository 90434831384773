import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchRestaurant } from "../../../hooks/restaurants/useFetchRestaurant";
import { Divider, CardHeader, CardBody, List, ListItem, Flex, Text } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { ChevronRightIcon, SettingsIcon } from "@chakra-ui/icons";


export const GameSettings = () => {
  const navigate = useNavigate()
  const { restaurantId } = useParams();
  const { restaurant, isLoading } = useFetchRestaurant(restaurantId)
  if (isLoading) return <CircularLoading />
  if (!restaurant) return null
  const addOnsNames = restaurant.add_ons_names
  const settingItems = [
    ...(addOnsNames.includes('ルーレット') ? [{ name: 'ルーレット', navigateTo: 'roulette' }] : [])
  ];
  return (
    <DarkShadowCard fontWeight='bold'>
      <CardHeader>
        <CardHeading icon={<SettingsIcon />} heading='ゲーム設定'/>
      </CardHeader>
      <CardBody pt={0}>
        <Divider m='0' borderColor='gray.300' />
        <List m='0'>
          {settingItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem cursor='pointer' _hover={{ bg: 'gray.100' }} px={2} py={3} onClick={() => navigate(`${item.navigateTo}`)}>
                <Flex align='center' justifyContent='space-between' w='100%'>
                  <Text fontSize='14px' m='0'>{item.name}</Text>
                  <ChevronRightIcon color='gray.400' />
                </Flex>
              </ListItem>
              <Divider m='0' borderColor='gray.300' />
            </React.Fragment>
          ))}
        </List>
      </CardBody>
    </DarkShadowCard>
  )
}