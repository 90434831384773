import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";
import { Staff } from "./useFetchRestaurantStaff";


const schema = yup.object().shape({
  name: yup.string().required('必須項目です'),
  nickname: yup.string().max(12, '12文字以内で入力してください'),
  img: yup.mixed()
});
export const useEditStaffForm = (staff?: Staff) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({ resolver: yupResolver(schema) });
  useEffect(() => {
    if (staff) {
      setValue('name', staff.name);
      setValue('nickname', staff.nickname);
    }
  }, [staff]);
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};