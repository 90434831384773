import React, { memo } from "react";
import { SortLastVisitDateHoverPopover } from "../../molecules/SortLastVisitDateHoverPopover";
import { SortVisitCountHoverPopover } from "../../molecules/SortVisitCountHoverPopover";
import { TableLayout } from "../../template/table/TableLayout";
import { formatDate } from "../../../utils/formatDate";
import { Avatar, HStack, Text } from "@chakra-ui/react";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";


type LineAccounts = {
  id: string;
  display_name: string;
  picture_url: string;
  visit_count: string;
  last_visit: string;
}[]
type RegisteredLineAccountsTableProps = {
  lineAccounts?: LineAccounts;
}
export const RegisteredLineAccountsTable: React.FC<RegisteredLineAccountsTableProps> = memo(({lineAccounts}) => {
  const headers = ['アカウント', <SortVisitCountHoverPopover />, <SortLastVisitDateHoverPopover />];
  const rows = lineAccounts?.map((lineAccount) => (
    <TableRowWithNavigationLayout
      key={lineAccount.id}
      path={`../line/accounts/${lineAccount.id}`}
      size='xs'
      childrenList={[
        <HStack>
          <Avatar src={lineAccount.picture_url} boxSize='30px' />
          <Text overflowWrap="break-word" whiteSpace="normal" noOfLines={1} m={0}>{lineAccount.display_name}</Text>
        </HStack>,
        lineAccount.visit_count + '回',
        <Text overflowWrap="break-word" whiteSpace="normal" noOfLines={1} m={0}>{formatDate(lineAccount.last_visit)}</Text>,
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='xs' />;
});