import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const soCouponSchema = yup.object().shape({
  title: yup.string().required('タイトルを入力してください'),
  explanation: yup.string().required('説明を入力してください'),
  notes: yup.string().required('注意事項を入力してください'),
  expiration_date: yup.string().required('有効期限を入力してください'),
  img: yup.mixed()
});


export const useSoCouponRegisterForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue, } = useForm({resolver: yupResolver(soCouponSchema),});

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
    setValue
  };
};