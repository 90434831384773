import React from 'react';
import { AuthHeader } from '../organisms/authentication/AuthHeader';
import { AuthFooter } from '../organisms/authentication/AuthFooter';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';


export const AuthBase: React.FC = () => {
  return (
    <>
      <AuthHeader />
      <Box as='main'>
        <Box p={4} minH='calc(100vh - 112px)'>
          <Outlet />
        </Box>
      </Box>
      <AuthFooter />
    </>
  );
};