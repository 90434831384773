import { memo } from "react"
import { Box, Grid, GridItem } from "@chakra-ui/react";


type SettingItemProps = {
  label: string;
  content: React.ReactNode;
}
export const SettingItem: React.FC<SettingItemProps> = memo(({label, content}) => {
  return (
    <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(3, 1fr)" }} gap={1} >
      <GridItem>
        <Box color="gray.500">{label}：</Box>
      </GridItem>
      <GridItem colSpan={{ base: 1, sm: 2 }}>
        <Box wordBreak="break-word" whiteSpace="pre-wrap">{content}</Box>
      </GridItem>
    </Grid>
  );
});