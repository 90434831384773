import { useEditTeamForm } from "../../../hooks/kuitan/useEditTeamForm";
import { useParams } from "react-router-dom";
import { useFetchKuitanTeam } from "../../../hooks/kuitan/useFetchKuitanTeam";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useEditTeam } from "../../../hooks/kuitan/useEditTeam";
import { TeamForm } from "../../organisms/kuitan/TeamForm";


export const EditTeam = () => {
  const { teamId } = useParams();
  const { team, isLoading } = useFetchKuitanTeam(teamId);
  const { handleSubmit, register, control, isSubmitting, errors } = useEditTeamForm(team);
  const onSubmit = useEditTeam(teamId || '')
  if (isLoading) return <CircularLoading />;
  return <TeamForm onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} register={register} control={control} errors={errors} cardHeading='チーム編集' btnLabel='変更'/>;
};