import { BudgetLimitInput, IsTransportationCostProvidedRadio, MaxCompanionsAllowedInput, MenuSpecificationSelect, MessageTextArea, NgDaysCheckBoxes, NgTimePeriodInputs, NumberInput, PeriodInput, RestaurantsSelect, TeamSelect, UnitPriceInput } from "../../organisms/kuitan/DispatchRequestFormComponents";
import { useRequestMultiMonthDispatchFormForSalesman } from "../../../hooks/kuitan/useRequestMultiMonthDispatchFormForSalesman";
import { useRequestMultiMonthDispatchBySalesman } from "../../../hooks/kuitan/useRequestMultiMonthDispatchBySalesman";
import { DispatchRequestForm } from "../../organisms/kuitan/DispatchRequestForm";


type FormComponent = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const RequestMultiMonthDispatchForSalesman = () => {
  const { handleSubmit, register, control, isSubmitting, errors, watch } = useRequestMultiMonthDispatchFormForSalesman();
  const onSubmit = useRequestMultiMonthDispatchBySalesman()
  const formComponents: FormComponent[] = [
    { component: RestaurantsSelect, props: { control, errors } },
    { component: TeamSelect, props: { control, errors } },
    { component: PeriodInput, props: { register, errors } },
    { component: NumberInput, props: { register, errors } },
    { component: UnitPriceInput, props: { register, errors } },
    { component: MenuSpecificationSelect, props: { register, errors } },
    { component: BudgetLimitInput, props: { register, errors } },
    { component: MaxCompanionsAllowedInput, props: { register, errors } },
    { component: IsTransportationCostProvidedRadio, props: { control, errors } },
    { component: NgDaysCheckBoxes, props: { control, errors } },
    { component: NgTimePeriodInputs, props: { register, errors, watch } },
    { component: MessageTextArea, props: { register, errors } },
  ];
  return <DispatchRequestForm cardTitle='長期派遣依頼' formComponents={formComponents} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} />
};