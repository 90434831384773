import { useSwrNoAuth } from "../swr/useSwrNoAuth";



type LineSegmentMsg = {
  id: string;
  img: string;
  title: string;
  text: string;
  label: string;
  url: string;
  send_at: string;
}
export const useFetchLineSegmentMsg = (msgId: string) => {
  const { data: segmentMsg, error, isLoading } = useSwrNoAuth<LineSegmentMsg>(`membership/segment-messages/${msgId}/`)
  return { segmentMsg, isLoading, error };
};