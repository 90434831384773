import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DateRangeFilter } from '../../molecules/DateRangeFilter';
import { RestaurantSurveyStaffRankingTab } from '../../organisms/restaurantStaff/RestaurantSurveyStaffRankingTab';
import { StaffSurveyStaffRankingTab } from '../../organisms/restaurantStaff/StaffSurveyStaffRankingTab';


export const StaffRanking = () => {
  return (
    <VStack w='100%' spacing={5}>
      <DateRangeFilter/>
      <StatusTabs/>
    </VStack>
  );
};


const StatusTabs = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const currentTab = searchParams.get("tab") || "restaurant";
  const handleTabChange = (index: number) => {
    const tab = index === 0 ? "restaurant" : "staff";
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("tab", tab);
    newSearchParams.delete("p");
    navigate({ search: newSearchParams.toString() });
  };
  const currentIndex = currentTab === "staff" ? 1 : 0;
  return (
    <Tabs variant='soft-rounded' colorScheme='messenger' isLazy onChange={handleTabChange} index={currentIndex} w='100%'>
      <TabList>
        <Tab>店舗</Tab>
        <Tab>従業員</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <RestaurantSurveyStaffRankingTab />
        </TabPanel>
        <TabPanel px={0}>
          <StaffSurveyStaffRankingTab />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};