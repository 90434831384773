import { Route } from 'react-router-dom';
import { CorporateRestaurantsRoutes } from './CorporateRestaurantsRoutes';
import { ManagersRoutes } from './ManagersRoutes ';
import { CorporationBaseInfoEdit } from '../components/pages/corporations/CorporationBaseInfoEdit';
import { CorporateRestaurants } from '../components/pages/corporations/CorporateRestaurants';
import { CorporationBaseInfo } from '../components/pages/corporations/CorporationBaseInfo';
import CorporationBase from '../components/template/base/CorporationBase';


export const CorporationRoutes = (
  <Route path='corporations/:corporationId' element={<CorporationBase />}>
    <Route path="" element={<CorporationBaseInfo />} />
    <Route path="edit" element={<CorporationBaseInfoEdit />} />
    <Route path="restaurants" element={<CorporateRestaurants />} />
    {CorporateRestaurantsRoutes}
    {ManagersRoutes}
  </Route>
);