import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const rouletteCouponSchema = yup.object().shape({
  title: yup.string().required('タイトルを入力してください'),
  explanation: yup.string().required('説明を入力してください'),
  notes: yup.string().required('注意事項を入力してください'),
  expiration_date: yup.string().required('有効期限を入力してください'),
  img: yup.mixed(),
  segment_count: yup.number().required('セグメント数を入力してください')
});
type CouponSegment = {
  coupon: {
    id: string;
    title: string;
    explanation: string;
    notes: string;
    expiration_date: string;
    img: string;
  }
  segment_count: number;
}
export const useEditRouletteCouponForm = (couponSegment?: CouponSegment) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue, } = useForm({resolver: yupResolver(rouletteCouponSchema),});
  useEffect(() => {
    if (couponSegment) {
      setValue('title', couponSegment.coupon.title);
      setValue('explanation', couponSegment.coupon.explanation);
      setValue('notes', couponSegment.coupon.notes);
      setValue('expiration_date', couponSegment.coupon.expiration_date);
      setValue('segment_count', couponSegment.segment_count);
    }
  }, [couponSegment]);
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};