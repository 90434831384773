import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { useRegisterSoCouponSettingForm } from "../../../hooks/socoupon/useRegisterSoCouponSettingForm";
import { useRegisterSoCouponSetting } from "../../../hooks/socoupon/useRegisterSoCouponSetting";
import { SelectBranchScore } from "../../molecules/form/SelectBranchScore";
import { SwitchRemindStatus } from "../../organisms/soCoupon/SwitchRemindStatus";
import { GUrlTextArea } from "../../organisms/soCoupon/GUrlTextArea";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const RegisterSoCouponSetting = () => {
  const { restaurantId } = useParams();
  const { handleSubmit, register, control, isSubmitting, errors } = useRegisterSoCouponSettingForm()
  const onSubmit = useRegisterSoCouponSetting(restaurantId)
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='即ーポン設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <SelectBranchScore control={control} errors={errors} />
              <GUrlTextArea register={register} errors={errors} />
              <SwitchRemindStatus control={control} />
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};