import { useRegisterGoogleSettingForm } from "../../../../hooks/externalApi/google/useRegisterGoogleSettingForm";
import { useRegisterGoogleSetting } from "../../../../hooks/externalApi/google/useRegisterGoogleSetting";
import { useParams } from "react-router-dom";
import { GoogleSettingsForm } from "../../../organisms/externalApi/google/GoogleSettingsForm";


export const RegisterGoogleSetting = () => {
  const { restaurantId } = useParams();
  const { handleSubmit, register, control, isSubmitting, errors } = useRegisterGoogleSettingForm();
  const onSubmit = useRegisterGoogleSetting(restaurantId);
  return (
    <GoogleSettingsForm
      register={register}
      control={control}
      errors={errors}
      handleSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    />
  );
};