import React, { useEffect, useState } from "react";
import { useExtractRestaurantIdFromUrl } from "../../../hooks/externalApi/line/useExtractRestaurantIdFromUrl";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchLineProfile } from "../../../hooks/externalApi/line/useFetchLineProfile";
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";
import { Box, Center, Heading, Image, keyframes } from '@chakra-ui/react';


export const NotifyBotLiffApp: React.FC = () => {
  const restaurantId = useExtractRestaurantIdFromUrl()
  if (restaurantId === null) return <CircularLoading />
  return (
    <LineProfileFetcher restaurantId={restaurantId}/>
  );
};

const LineProfileFetcher: React.FC<{restaurantId: string}> = ({restaurantId}) => {
  const profile = useFetchLineProfile('2004571337-p9WPeDGv');
  if (!profile) return <CircularLoading />
  return (
    <AssociateLineAccountWithRestaurant restaurantId={restaurantId} {...profile} />
  );
}

const shakeAnimation = keyframes`
0% { transform: translateX(0); }
25% { transform: translateX(-5px) rotate(0.5deg); }
50% { transform: translateX(5px) rotate(-0.5deg); }
75% { transform: translateX(-5px) rotate(0.5deg); }
100% { transform: translateX(0); }
`;
const AssociateLineAccountWithRestaurant: React.FC<{restaurantId: string, userId: string, displayName: string, pictureUrl?: string}> = ({restaurantId, userId, displayName, pictureUrl}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const associateLineAccountWithRestaurant = async () => {
      try {
        setIsLoading(true)
        await customNoAuthAxios.post(`external_api/restaurants/${restaurantId}/associate-line-account/`,{lineId: userId, displayName: displayName, pictureUrl: pictureUrl});
      } catch (error: any) {
        console.log(error);
        setError(true)
      } finally {
        setIsLoading(false)
      }
    };
    associateLineAccountWithRestaurant()
  }, [])
  if (isLoading) return <CircularLoading />
  if (error) return <>通知設定に失敗しました。もう一度QRコードを読み込んでください。</>
  return (
    <Center
      h="100vh"
      backgroundColor="#f8f8f8"
    >
      <Box
        textAlign="center"
        p="20px"
        backgroundColor="#fff"
        borderRadius="8px"
        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      >
      <Heading as='h1'size='3xl' letterSpacing='3px' >
        THANKS!
      </Heading>
        <p>「設定」から通知先アカウントに登録できます</p>
        <Image
          src='/images/logo.png'
          animation={`${shakeAnimation} 0.5s ease-in-out infinite`}
          width="200px"
          margin="auto"
        />
      </Box>
    </Center>
  );
}