import useSWR from "swr";

export type AssignedCorporations = {
  id: string;
  name: string;
}[]
export const useFetchAssignedCorporations = (salesmanId?: string) => {
  const fetchKey = `accounts/salesmen/${salesmanId}/corporations/`
  const { data: assignedCorporations, error, isLoading } = useSWR<AssignedCorporations>(fetchKey)
  return { assignedCorporations, isLoading, error };
};