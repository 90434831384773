import React, { memo } from "react";
import { Paginator } from "../../molecules/pagenation/Paginator";
import { RegisteredLineAccountsTable } from "./RegisteredLineAccountsTable";
import { Box, CardBody, CardFooter, CardHeader, Flex, HStack, Icon, Link, Skeleton, Text, VStack } from "@chakra-ui/react";
import { useFetchSortPaginateLineAccounts } from "../../../hooks/externalApi/line/useFetchSortPaginateLineAccounts";
import { LineAccountsPerVisitCount } from "./LineAccountsPerVisitCount";
import { Link as RouterLink } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { LightShadowCard } from "../../molecules/card/LightShadowCard";


type RegisteredLineAccountsCardProps = {
  restaurantId: string
}
export const RegisteredLineAccountsCard: React.FC<RegisteredLineAccountsCardProps> = memo(({ restaurantId }) => {
  const { lineAccounts, isLoading, pages, pagesCount, currentPage, setCurrentPage } = useFetchSortPaginateLineAccounts(restaurantId);
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <LightShadowCard>
        <CardHeader>
          <Flex align='center' justify='space-between'>
            <Text m={0} fontSize='lg' fontWeight='bold'>登録ユーザー</Text>
            <Link as={RouterLink} to='../line/accounts' fontSize='sm' color='gray.500' fontWeight='bold' _hover={{ color: 'gray.400' }}>
              <HStack align='center' spacing={1}>
                <Text m='0'>もっと見る</Text>
                <Icon as={IoIosArrowForward} />
              </HStack>
            </Link>
          </Flex>
        </CardHeader>
        <CardBody py={0}>
          <VStack align='start' spacing={2}>
            <LineAccountsPerVisitCount/>
            <Box w='100%'>
              <RegisteredLineAccountsTable lineAccounts={lineAccounts?.line_accounts} />
            </Box>
          </VStack>
        </CardBody>
        <CardFooter>
          <Paginator pagesCount={pagesCount} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
        </CardFooter>
      </LightShadowCard>
    </Skeleton>
  );
});