import React, { memo } from "react";
import { Divider, Image, Text, VStack } from "@chakra-ui/react";


type BenefitWithImgProps = {
  explanation: string;
  img: string;
}
export const BenefitWithImg: React.FC<BenefitWithImgProps> = memo(({explanation, img}) => {
  return (
    <VStack spacing={3}>
      <Image
        boxSize={{ base: '70vw', sm: '250px' }}
        objectFit="cover"
        m="auto"
        src={img}
        alt=""
      />
      <Divider m='0' />
      <VStack align='start' fontSize='sm' w='100%'>
        <Text m='0'>{explanation}</Text>
      </VStack>
      <Divider m='0' />
    </VStack>
  );
});