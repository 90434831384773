import useSWR from "swr";

type Benefit = {
  id: string;
  ranks: string[];
  rank_names: string[];
  title: string;
  explanation: string;
  notes: string;
  img: string;
}
export const useFetchBenefit = (benefitId?: string) => {
  const fetcherKey = `membership/benefits/${benefitId}`;

  const { data: benefit, error, isLoading } = useSWR<Benefit>(fetcherKey)
  return { benefit, isLoading, error };
};