import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const benchmarkRestaurantSchema = yup.object().shape({
  name: yup.string().required('入力必須項目です'),
  url: yup.string().required('入力必須項目です'),
});
export const useRegisterBenchmarkRestaurantForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(benchmarkRestaurantSchema),});
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};