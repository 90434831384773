import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const editMySalesmanSchema = yup.object().shape({
  username: yup.string().required('ユーザーIDを入力してください'),
  email: yup.string().required('メールアドレスを入力してください').email("メールアドレスを正しい形式で入力してください"),
  phone_number: yup.string().required('電話番号を入力してください'),
  last_name: yup.string().required('性を入力してください'),
  first_name: yup.string().required('名を入力してください'),
});


export const useEditMySalesmanForm = (user: any) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({
    resolver: yupResolver(editMySalesmanSchema),
  });
  useEffect(() => {
    if (user) {
      setValue('username', user.username);
      setValue('email', user.email);
      setValue('phone_number', user.phone_number);
      setValue('last_name', user.last_name);
      setValue('first_name', user.first_name);
    }
  }, [user]);

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};