import React, { memo, useEffect, useState } from 'react';
import { Box, Button, HStack, Input, InputProps } from '@chakra-ui/react';

type DateRange = {
  startDate: string | null;
  endDate: string | null;
};

type DateFilterSectionProps = {
  setDateRange: (dateRange: DateRange) => void;
  size?: 'xs' | 'sm';
};

export const DateFilterSection: React.FC<DateFilterSectionProps> = memo(({ setDateRange, size='sm' }) => {
  const [inputDateRange, setInputDateRange] = useState<DateRange>({ startDate: null, endDate: null });
  useEffect(() => {
    setDateRange(inputDateRange)
  }, []);
  return (
    <HStack w='100%' justify='start' spacing={{base: 1, sm: 3, lg: 3}} >
      <DateInput
        size={size}
        w={size === 'xs' ? '110px' : '130px'}
        onChange={(e) => setInputDateRange({ ...inputDateRange, startDate: e.target.value })}
      />
      <Box>~</Box>
      <DateInput
        size={size}
        w={size === 'xs' ? '110px' : '130px'}
        onChange={(e) => setInputDateRange({ ...inputDateRange, endDate: e.target.value })}
      />
      <Button colorScheme="messenger" fontSize='xs' size={{base: 'xs', sm: 'sm', lg: 'sm'}} variant='outline' px={2} onClick={() => setDateRange(inputDateRange)} >
        絞り込む
      </Button>
    </HStack>
  );
});

const DateInput: React.FC<InputProps> = memo((props) => {
  return (
    <Input
      {...props}
      size={{base: 'xs', sm: 'sm', lg: 'sm'}}
      type="date"
      bgColor='white'
      rounded={6}
      w={{base: '110px', sm: '130px', lg: '130px'}}
    />
  )
})

