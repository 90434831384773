import { KeyedMutator } from "swr";
import { customAuthAxios } from "../utils/customAuthAxios";
import { useSuccessToast } from "./toast/useSuccessToast";


export const useDeleteWithMutate = (path: string, onClose: () => void, mutate: KeyedMutator<any>) => {
  const showToast = useSuccessToast();

  const onSubmit = async () => {
    try {
      await customAuthAxios.delete(path)
      mutate()
      onClose()
      showToast('削除が完了しました')
    } catch (error: any) {
    }
  };

  return onSubmit;
};