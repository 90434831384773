import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type FoodMenu = {
  id: string;
  restaurant: string;
  menu_type: string;
  img: string;
}[]
export const useFetchCustomerFoodMenu = (restaurantId: string) => {
  const { data: foodMenu, error, isLoading } = useSwrNoAuth<FoodMenu>(`menu/restaurants/${restaurantId}/food/customer/`)
  return { foodMenu, isLoading, error };
};