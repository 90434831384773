import React, { memo } from "react";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { Box, Button, CardBody, CardFooter, CardHeader, Divider, HStack, VStack, useDisclosure } from "@chakra-ui/react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { FaUserTie } from "react-icons/fa";
import { SettingItem } from "../../organisms/SettingItem";
import { useFetchManager } from "../../../hooks/manager/useFetchManager";
import { useDelete } from "../../../hooks/useDelete";
import { DeleteModal } from "../../molecules/DeleteModal";


export const ManagerBaseInfo: React.FC = memo(() => {
  const { managerId } = useParams()
  const { manager, isLoading: managerLoading } = useFetchManager(managerId)
  if (managerLoading) return <CircularLoading />
  if (!manager) return null
  const managerDetails = [
    { label: 'ユーザーネーム', value: manager.username },
    { label: '名前', value: manager.name },
    { label: '担当店舗', value: manager.restaurant_names.join('\n') },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<FaUserTie />} heading='基本情報'/>
        </CardHeader>
        <CardBody py={2}>
          <VStack align='start' fontWeight='bold' spacing={3} w='100%'>
            <Divider my={2} />
            {managerDetails.map((detail) => (
              <React.Fragment key={detail.label}>
                <Box w='100%' whiteSpace='pre-wrap'>
                  <SettingItem label={detail.label} content={detail.value}/>
                </Box>
                <Divider my={2} />
              </React.Fragment>
            ))}
          </VStack>
        </CardBody>
        <CardFooter>
          <HStack>
            <PageTransitionBtn path='edit' text='編集'/>
            <ManagerDeleteModal id={manager.user} managerName={manager.name}/>
          </HStack>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});

type DeleteModalProps = {
  id: string;
  managerName: string;
}
export const ManagerDeleteModal: React.FC<DeleteModalProps> = memo(({id, managerName}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDelete(`accounts/users/${id}/`, onClose, '/login/manager')
  return (
    <>
      <Button colorScheme='red' variant='ghost' size='sm' onClick={(e) => { e.stopPropagation(); onOpen(); }}>アカウント削除</Button>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={managerName}
        onSubmit={onSubmit}
      />
    </>
  );
});