import { Route } from 'react-router-dom';
import { Login } from '../components/pages/authentication/Login';
import { LoginAdmin } from '../components/pages/authentication/LoginAdmin';
import { LoginSalesman } from '../components/pages/authentication/LoginSalesman';
import { SignUpSalesman } from '../components/pages/authentication/SignUpSalesman';
import { LoginRestaurant } from '../components/pages/authentication/LoginRestaurant';
import { LoginCorporation } from '../components/pages/authentication/LoginCorporation';
import { LoginManager } from '../components/pages/authentication/LoginManager';
import { AuthBase } from '../components/template/AuthBase';
import { LoginKuitanAgent } from '../components/pages/authentication/LoginKuitanAgent';
import { SignUpKuitanAgent } from '../components/pages/authentication/SignUpKuitanAgent';
import { LoginKuitanAgentForRate } from '../components/pages/authentication/LoginKuitanAgentForRate';


const routes = [
  { path: "login", element: <Login /> },
  { path: "login/admin", element: <LoginAdmin /> },
  { path: "salesman-login", element: <LoginSalesman /> },
  { path: "signup", element: <SignUpSalesman /> },
  { path: "corporation-login", element: <LoginCorporation /> },
  { path: "restaurant-login", element: <LoginRestaurant /> },
  { path: "login/manager", element: <LoginManager /> },
  { path: "login/kuitan-agent", element: <LoginKuitanAgent /> },
  { path: "login/kuitan-agent/rate", element: <LoginKuitanAgentForRate /> },
  { path: "signup/kuitan-agent", element: <SignUpKuitanAgent /> },
];
export const AuthRoutes = (
  <Route path="" element={<AuthBase />}>
    {routes.map(({ path, element }) => <Route key={path} path={path} element={element} />)}
  </Route>
);