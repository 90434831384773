import { memo, useState } from "react"
import { Box, Divider, HStack, VStack } from '@chakra-ui/react'
import { TableQrCardModal } from "./TableQrCardModal";
import { ReactSelectWithChakraStyle } from "../../../atoms/ReactSelectWithChakraStyle";


type Tables = {
  id: string;
  table_name: string;
  qr_code: string;
  in_use: boolean;
  auth_code: number;
}[]
export const DownloadTableQrCardsSection: React.FC<{tableChoices: Tables[]}> = memo(({tableChoices}) => {
  const [downloadTables, setDownloadTables] = useState<Tables|null>(null);
  const tableNumberOption = tableChoices?.map((tableChoice, index) => ({
    value: tableChoice,
    label: `${index*10+1}〜${tableChoice.length+index*10}`
  }));
  return (
    <Box>
      <Box fontWeight='bold' mb='2'>QRカードダウンロード</Box>
      <VStack align='start'>
        <Box w='300px'>
          <ReactSelectWithChakraStyle
            placeholder="ダウンロードするテーブルを選択"
            options={tableNumberOption}
            onChange={(e) => setDownloadTables(e?.value ?? null)}
            formatOptionLabel={({ label }) => {
              const parts = label.split('〜');
              return (
                <TableChoice choiceHead={parts[0]} choiceTail={parts[1]} />
              );
            }}
          />
        </Box>
        {downloadTables && <TableQrCardModal tables={downloadTables} />}
      </VStack>
      <Divider />
    </Box>
  );
});

const TableChoice: React.FC<{choiceHead: string, choiceTail: string}> = ({choiceHead, choiceTail}) => {
  return (
    <HStack gap={1}>
      <Box
        w='30px'
        textAlign="center"
        fontSize="12px"
        fontWeight="bold"
        backgroundColor="#0078FF"
        color="white"
      >
        {choiceHead}
      </Box>
      <>
      〜
      </>
      <Box
        w='30px'
        textAlign="center"
        fontSize="12px"
        fontWeight="bold"
        backgroundColor="#0078FF"
        color="white"
      >
        {choiceTail}
      </Box>
    </HStack>
  )
}