import React, { memo } from "react";
import { useFetchMembershipRank } from "../../../hooks/membership/useFetchMembershipRank";
import { useFetchCustomerLastVisitDate } from "../../../hooks/externalApi/line/useFetchCustomerLastVisitDate";
import { useFetchRestaurantLogoByLineId } from "../../../hooks/membership/useFetchRestaurantLogoByLineId";
import { useFetchCustomerRankCard } from "../../../hooks/membership/useFetchCustomerRankCard";
import { DefaultRankCard } from "./DefaultRankCard";
import { ImageRankCard } from "./ImageRankCard";
import { Skeleton } from "@chakra-ui/react";

type RankCardProps = {
  restaurantId: string;
  lineId: string;
  displayName: string;
}

export const RankCard: React.FC<RankCardProps> = memo(({restaurantId, lineId, displayName}) => {
  const { rank, isLoadingRank } = useFetchMembershipRank(restaurantId, lineId)
  const {lastVisitDate, isLoadingLastVisitDate} = useFetchCustomerLastVisitDate(restaurantId, lineId);
  const {logo, isLoadingLogo} = useFetchRestaurantLogoByLineId(restaurantId);
  const {rankCard, isLoadingRankCard} = useFetchCustomerRankCard(restaurantId);

  if (!rank || !lastVisitDate || isLoadingRank || isLoadingLastVisitDate || isLoadingLogo || isLoadingRankCard) return <Skeleton h='100%' w='100%' borderRadius='5px' />
  if (!rankCard || !rankCard.img) return <DefaultRankCard displayName={displayName} rank={rank} lastVisitDate={lastVisitDate} logo={logo} />

  return (
    <ImageRankCard imgUrl={rankCard.img} displayName={displayName} rank={rank} lastVisitDate={lastVisitDate}  />
  );
});