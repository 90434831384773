import { memo } from "react"
import QRCode from "qrcode.react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { AlertMsg } from "../../molecules/AlertMsg";
import { useFetchManLaboSettingByRestaurantId } from "../../../hooks/manzokudoLabo/useFetchManLaboSettingByRestaurantId";
import { Box, CardBody, CardFooter, CardHeader, Flex } from "@chakra-ui/react";
import { QrCardsDownloader } from "../../molecules/QrCardsDownloader";
import { useFetchLiffUrlByRestaurantId } from "../../../hooks/externalApi/line/useFetchLiffUrlByRestaurantId";
import { useParams } from "react-router-dom";
import { QrCordDownloadModal } from "../../molecules/QrCordDownloadModal";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { MdOutlineQrCode2 } from "react-icons/md";


export const ManLaboQrCode: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { manLaboSetting, isLoading, error } = useFetchManLaboSettingByRestaurantId(restaurantId)
  if (!restaurantId || isLoading) return <CircularLoading />
  if (error && error.response.status === 404 || !manLaboSetting) return <AlertMsg msg='設定を完了してください。' />;
  if (!manLaboSetting.g_url) return <AlertMsg msg="遷移先URLが設定されていません。営業担当に連絡してください。"/>
  if (!manLaboSetting.branch_score) return <AlertMsg msg="ブランチスコアを設定してください"/>
  if (!manLaboSetting.branch_question) return <AlertMsg msg="ブランチクエッションを設定してください"/>
  const qrCardImg = manLaboSetting.qr_card_img ?? '/images/manzokudo-labo.png'
  return (
    <Box w={{ base: '100%', md: '500px' }} m='auto'>
      <QrCodeCardContents restaurantId={restaurantId} qrCardImg={qrCardImg} />
    </Box>
  );
});


const QrCodeCardContents: React.FC<{restaurantId: string, qrCardImg: string}> = ({restaurantId, qrCardImg}) => {
  const {liffUrl, isLoading, error} = useFetchLiffUrlByRestaurantId(restaurantId, 'manzokudolabo')
  if (error) return <AlertMsg msg={error.response.data.detail}/>
  if (isLoading) return <CircularLoading />
  const liffUrlWithParams = `${liffUrl}/${restaurantId}`
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<MdOutlineQrCode2 size='20px' />} heading='QRコード'/>
      </CardHeader>
      <CardBody>
        <QrCard url={liffUrlWithParams} qrCardImg={qrCardImg} />
      </CardBody>
      <CardFooter>
        <Flex direction={{ base: 'column', sm: 'row' }} gap={2}>
          <QrCardsDownloader>
            <QrCard url={liffUrlWithParams} qrCardImg={qrCardImg} />
          </QrCardsDownloader>
          <QrCordDownloadModal url={liffUrlWithParams} />
        </Flex>
      </CardFooter>
    </DarkShadowCard>
  );
};
const QrCard: React.FC<{url: string, qrCardImg: string}> = ({url, qrCardImg}) => {
  return (
    <Box
      bgImage={qrCardImg}
      w={{ base: '250px', sm: '297px' }}
      h={{ base: '353.5px', sm: '420px' }}
      bgSize="contain"
      bgRepeat="no-repeat"
      m="auto"
      boxShadow='rgba(0, 0, 0, 0.08) 0px 0px 5.5px'
    >
      <Box
        as={QRCode}
        value={url}
        sx={{
          boxSize: '115px!important',
          objectFit: 'contain',
          position: 'relative',
          top: '61%',
          margin: 'auto'
        }}
      />
    </Box>
  );
};
