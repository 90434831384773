import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const loginSchema = yup.object().shape({
    username: yup.string().required('必須項目です'),
    password: yup.string().required('必須項目です')
});

export const useLoginForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(loginSchema),
  });

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};