import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";


type SegmentCountInputProps = {
  register: any
  errors: any
}
export const SegmentCountInput = ({ register, errors }: SegmentCountInputProps) => {
  return (
    <FormControl isRequired>
      <FormLabel>セグメント数</FormLabel>
      <Input type="number" min={1} {...register('segment_count')} />
      <FormErrorMessage>{errors.segment_count?.message}</FormErrorMessage>
    </FormControl>
  );
};