import React from "react"
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchSoCouponSettingByRestaurantId } from "../../../hooks/socoupon/useFetchSoCouponSettingByRestaurantId";
import { AlertMsg } from "../../molecules/AlertMsg";
import { Badge, Box, CardBody, CardFooter, CardHeader, Divider } from "@chakra-ui/react";
import { SettingItem } from "../../organisms/SettingItem";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const SoCouponSettingForRestaurant = () => {
  const { restaurantId } = useParams()
  const {soCouponSetting, isLoading} = useFetchSoCouponSettingByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />
  if (!soCouponSetting) return <AlertMsg msg="設定が完了してません。営業担当に連絡してください。"/>
  const qrCardImg = soCouponSetting.qr_card_img ?? '/images/socoupon.png'
  const settingItems = [
    {label: 'ブランチスコア', content: soCouponSetting.branch_score},
    {label: '使用中クーポン', content: soCouponSetting.socoupon_title ?? '未登録'},
    {
      label: 'リマインド',
      content: (
        <Badge colorScheme={soCouponSetting.remind ? 'green' : 'gray'} fontSize='sm'>
          {soCouponSetting.remind ? 'ON' : 'OFF'}
        </Badge>
      ),
    },
    {
      label: 'QRカード画像',
      content: (
        <Box
          bgImage={`url(${qrCardImg})`}
          w="210px"
          h="297px"
          bgSize="contain"
          bgRepeat="no-repeat"
          border='0.1px solid #c0c0c09d'
        />
      ),
    },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}} fontSize='sm'>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='即ーポン設定'/>
        </CardHeader>
        <CardBody py={2}>
          <Box fontWeight='bold'>
            {settingItems.map((item, index) => (
              <React.Fragment key={index}>
                <SettingItem label={item.label} content={item.content} />
                {index < settingItems.length - 1 && <Divider my={3} />}
              </React.Fragment>
            ))}
          </Box>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集' />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
};