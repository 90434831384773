import React, { memo, FC } from "react";
import { useFetchRestaurantAccessToken } from "../../hooks/externalApi/line/useFetchRestaurantAccessToken";
import { CircularLoading } from "../molecules/loading/CircularLoading";
import { AlertMsg } from "../molecules/AlertMsg";
import { Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

type LineSegmentDeliveryLayoutProps = {
  FormComponent: FC<{ restaurantId: string, accessToken: string }>;
}

export const LineSegmentDeliveryLayout: React.FC<LineSegmentDeliveryLayoutProps> = memo(({ FormComponent }) => {
  const { restaurantId } = useParams()
  const { accessToken, isLoading, error } = useFetchRestaurantAccessToken(restaurantId);
  if (isLoading) return <CircularLoading />
  if (error) return <AlertMsg msg={error.response.data.detail}/>
  if (!restaurantId || !accessToken) return null
  return (
    <Flex justifyContent='center' w='100%'>
      <FormComponent restaurantId={restaurantId} accessToken={accessToken} />
    </Flex>
  );
});