import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Team } from "./useFetchKuitanTeam";
import { useEffect } from "react";


const schema = yup.object().shape({
  managers: yup.array().of(yup.string().required('必須項目です')),
  name: yup.string().required('必須項目です'),
  email: yup.string().required('必須項目です'),
  cc_emails: yup.array().of(yup.string()),
});
export const useEditTeamForm = (team?: Team) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({ resolver: yupResolver(schema) });
  useEffect(() => {
    if (team) {
      setValue('managers', team.managers);
      setValue('name', team.name);
      setValue('email', team.email);
      setValue('cc_emails', team.cc_emails);
    }
  }, [team]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};