import useSWR from "swr";


export type Scores = {
  'id': string,
  'title': string,
  'survey_answer_scores_count': number,
  'survey_answer_scores_average': number,
  'survey_answer_scores_5_count': number,
  'survey_answer_scores_5_percentage': number,
  'survey_answer_scores_4_count': number,
  'survey_answer_scores_4_percentage': number,
  'survey_answer_scores_3_count': number,
  'survey_answer_scores_3_percentage': number,
  'survey_answer_scores_2_count': number,
  'survey_answer_scores_2_percentage': number,
  'survey_answer_scores_1_count': number,
  'survey_answer_scores_1_percentage': number,
}[]
export const useFetchRestaurantScores = (staffId?: string, queryParams?: URLSearchParams) => {
  const fetchKey = staffId ? `restaurant_staff/staff/${staffId}/restaurant-survey/scores/${queryParams?.toString() && `?${queryParams}`}` : null;
  const { data: restaurantScores, error, isLoading } = useSWR<Scores>(fetchKey)
  return { restaurantScores, isLoading, error };
};