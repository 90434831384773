import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const  ifreaAnalyticsSettingSchema = yup.object().shape({
  ifrea_analytics_restaurant_id: yup.string().required('入力必須項目です'),
});
type IfreaAnalyticsSetting = {
  id: string;
  ifrea_analytics_restaurant_id: string;
}
export const useEditIfreaAnalyticsSettingForm = (ifreaAnalyticsSetting?: IfreaAnalyticsSetting) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(ifreaAnalyticsSettingSchema),});

  useEffect(() => {
    if (ifreaAnalyticsSetting) {
      setValue('ifrea_analytics_restaurant_id', ifreaAnalyticsSetting.ifrea_analytics_restaurant_id);
    }
  }, [ifreaAnalyticsSetting]);

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};