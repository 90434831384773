import React from "react";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from "@chakra-ui/react";


type LabelInputProps = {
  register: any,
  errors: any,
}
export const LabelInput: React.FC<LabelInputProps> = ({register, errors}) => (
  <FormControl isRequired isInvalid={errors.label}>
    <FormLabel>ラベル</FormLabel>
    <Input
      {...register('label')}
      placeholder='今すぐ確認'
      maxLength={10}
    />
    <FormHelperText>最大10文字</FormHelperText>
    <FormErrorMessage>{errors['label']?.message}</FormErrorMessage>
  </FormControl>
);