import { useToast } from "@chakra-ui/react";

export const useSuccessToast = () => {
  const toast = useToast();
  const showToast = (title: string, description?: string) => {
    toast({
      position: 'top',
      title: `${title}`,
      description: description ? `${description}` : null,
      status: 'success',
      duration: 10000,
      isClosable: true,
      containerStyle: {
        whiteSpace: 'pre-wrap',
      },
    });
  };

  return showToast;
};