import useSWR from "swr";


type StaffSetting = {
  id: string;
  restaurant: string;
  restaurant_branch_score: number;
  staff_branch_score: number;
  g_url: string;
  is_navigate_g_url: boolean;
  staff_branch_question: string;
  staff_branch_question_name: string;
  restaurant_branch_question: string;
  restaurant_branch_question_name: string;
  name_tag_img?: string;
}
export const useFetchStaffSettingByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `restaurant_staff/restaurants/${restaurantId}/setting/` : null;
  const { data: staffSetting, error, isLoading } = useSWR<StaffSetting>(fetcherKey)
  return { staffSetting, isLoading, error };
};