import { useSearchParams } from "react-router-dom";


export const useQueryParamsOfDateRange = () => {
  const [searchParams] = useSearchParams({ since: "", until: "" });
  const since = searchParams.get('since');
  const until = searchParams.get('until');
  const queryParamItems = [
    since && `since=${since}`,
    until && `until=${until}`
  ].filter(Boolean);
  const requestQueryParams = queryParamItems.length ? `?${queryParamItems.join('&')}` : '';
  return { since, until, requestQueryParams };
}