import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


export const postBusinessLatestInfoSchema = yup.object().shape({
  img: yup.mixed().required('入力必須項目です'),
  summary: yup.string().required('入力必須項目です'),
  action_button_url: yup.string()
});
export const usePostBusinessLatestInfoForm = () => {
  const { handleSubmit, formState: { errors, isSubmitting }, register, reset } = useForm({resolver: yupResolver(postBusinessLatestInfoSchema)});
  return {
    handleSubmit,
    errors,
    register,
    isSubmitting,
    reset
  };
};