import useSWR from "swr";


type Restaurants = {
  id: string;
  name: string;
  username: string;
  manager_last_name: string;
  manager_first_name: string;
  manager_phone_number: string;
  corporation_name: string;
}[];
type PaginatedRestaurants = {
count: number;
restaurants: Restaurants;
};
export const useFetchPaginateAssignedRestaurants = (pageSize: number, currentPage: number, salesmanId?: string) => {
  const { data: paginatedRestaurants, error, isLoading } = useSWR<PaginatedRestaurants>(`accounts/salesmen/${salesmanId}/restaurants/paginated/?pageSize=${pageSize}&currentPage=${currentPage}`)
  return { paginatedRestaurants, isLoading, error };
};