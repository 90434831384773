import { useRecoilValue } from "recoil";
import { ordState } from "../../../store/ordState";
import { totalState } from "../../../store/totalState";
import { useCustomPagination } from "../../useCustomPagenation";
import { useFetchLineAccountsPerPage } from "./useFetchLineAccountsPerPage";
import { useUpdateTotal } from "../../useUpdateTotal";
import { searchTargetNameState } from "../../../store/searchTargetNameState";

export const useFetchSortPaginateLineAccounts = (restaurantId?: string) => {
  const ord = useRecoilValue(ordState)
  const total = useRecoilValue(totalState);
  const searchTargetName = useRecoilValue(searchTargetNameState);

  const { pages, pagesCount, currentPage, setCurrentPage, pageSize } = useCustomPagination(total);
  const { lineAccounts, isLoading } = useFetchLineAccountsPerPage(pageSize, currentPage, ord, searchTargetName, restaurantId)
  useUpdateTotal(lineAccounts);


  return { lineAccounts, isLoading, pages, pagesCount, currentPage, setCurrentPage };
};