import useSWR from "swr";

type LineAccount = {
  id: string;
  customer_sex: string;
  customer_birth_year: string;
  customer_birth_month: string;
  line_id: string;
  display_name: string;
  picture_url?: string;
  visit_count: number;
  registered_date: string;
  last_visit: string;
  is_active: boolean;
  blocked_date: null | string;
  customer: string;
  restaurant: string;
};

type SoCouponOverallScoresWithLineAccounts = {
  id: string;
  line_account: LineAccount;
  score: number;
  posted_at: string;
}[];

type SoCouponOverallScoresWithLineAccountsPerPage = {
  socoupon_overall_scores_with_line_accounts: SoCouponOverallScoresWithLineAccounts;
  count: number;
};

export const useFetchSoCouponOverallScoreWithLineAccountsPerPage = (restaurantId: string, pageSize: number, currentPage: number) => {
  const { data: soCouponOverallScoresWithLineAccountsPerPage, isLoading, error } = useSWR<SoCouponOverallScoresWithLineAccountsPerPage>(`socoupon/overall-scores/with-line-accounts/?restaurantId=${restaurantId}&pageSize=${pageSize}&currentPage=${currentPage}`)
  return { soCouponOverallScoresWithLineAccountsPerPage, isLoading, error };
};