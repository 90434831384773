import useSWRInfinite from "swr/infinite";
import { useGeneratePaginationKey } from "../../useGeneratePaginationKey";
import { usePaginationStatus } from "../../usePagenationStatus";


type LineAccounts = {
  id: string;
  customer: string;
  restaurant: string;
  line_id: string;
  display_name: string;
  picture_url: string;
  visit_count: string;
  last_visit: string;
  registered_date: string;
  customer_sex : string;
  customer_birth_year : string;
  customer_birth_month : string;
}
export const useFetchLineSegmentDeliveryReceivedAccountPerPage = (apiUrl: string) => {
  const limit = 10;
  const getKey = useGeneratePaginationKey(`${apiUrl}?`, limit);
  const {
    data: lineAccountsList,
    isValidating,
    isLoading,
    setSize
  } = useSWRInfinite<LineAccounts>(getKey);
  const isReachingEnd = usePaginationStatus(limit, lineAccountsList);
  return {
    lineAccountsList,
    isValidating,
    isLoading,
    setSize,
    isReachingEnd
  };
};