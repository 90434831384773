import React, { memo } from "react";
import { Skeleton } from "@chakra-ui/react";
import { useFetchLineAccountRegistrationsCount } from "../../../hooks/externalApi/line/useFetchLineAccountRegistrationsCount";
import { SmallCardWithIcon } from "../../molecules/card/SmallCardWithIcon";
import { ImUserPlus } from "react-icons/im";


type UserRegistrationsCountCardProps = {
  restaurantId: string;
  startDate: string|null;
  endDate: string|null;
}
export const UserRegistrationsCountCard: React.FC<UserRegistrationsCountCardProps> = memo(({restaurantId, startDate, endDate}) => {
  const { lineAccountRegistrationsCount, isLoading } = useFetchLineAccountRegistrationsCount(restaurantId, startDate, endDate);
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl' w='100%'>
      <SmallCardWithIcon data={`${lineAccountRegistrationsCount}人`} title='ユーザー登録数'>
        <ImUserPlus size='30px' />
      </SmallCardWithIcon>
    </Skeleton>
  );
});