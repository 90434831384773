import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerOverlay,
} from '@chakra-ui/react'
import { DrawerNav } from './DrawerNav';

type DrawerContentsProps = {
  isOpen: boolean;
  onClose: () => void;
}

export const DrawerContents: React.FC<DrawerContentsProps> = ({isOpen, onClose}) => {

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size='xs'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton color='white' />
        <DrawerBody bgColor='#222222' color='white' fontSize='20px' fontWeight='bold' p='50px 16px'>
          <DrawerNav onClose={onClose} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}