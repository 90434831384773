import { memo } from "react"
import { useFetchStampCardByRestaurantId } from "../../../hooks/membership/useFetchStampCardByRestaurantId";
import { ImageStampCard } from "./ImageStampCard";
import { DefaultStampCard } from "./DefaultStampCard";


type StampCardPreviewProps = {
  restaurantId: string;
}
export const StampCardPreview: React.FC<StampCardPreviewProps> = memo(({restaurantId}) => {
  const { stampCard, isLoading} = useFetchStampCardByRestaurantId(restaurantId)
  if (!stampCard || isLoading) return null
  if (!stampCard.img) return <DefaultStampCard visitCount={3} nextRank={{name: 'ゴールド', visitCountsNeededToRankUp: 3}} />
  return <ImageStampCard imgUrl={stampCard.img} visitCount={3} nextRank={{name: 'ゴールド', visitCountsNeededToRankUp: 3}}  />
});