import { useState, useEffect } from 'react';

export const usePaginationStatus = ( limit: number, dataList?: any[],) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [isReachingEnd, setIsReachingEnd] = useState(false);

  useEffect(() => {
    setIsEmpty(dataList?.[0]?.length === 0);
    setIsReachingEnd(
      isEmpty || (dataList && dataList[dataList.length - 1]?.length < limit) || false
    );
  }, [dataList, limit, isEmpty]);

  return isReachingEnd;
};