import { memo } from "react"
import { EditIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { IconButton } from "@chakra-ui/react";


export const EditIconBtn: React.FC<{path: string}> = memo(({path}) => {
  return <IconButton
          as={RouterLink}
          variant='ghost'
          colorScheme='messenger'
          aria-label='edit'
          icon={<EditIcon />}
          size='sm'
          to={path}
        />
});