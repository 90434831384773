import React, { memo } from "react";
import { Td, Tr, useMediaQuery } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


type RecordProps = {
  path: string;
  childrenList: React.ReactNode[];
  size?: 'xs' | 'sm' | 'md' | 'lg';
}
export const TableRowWithNavigationLayout: React.FC<RecordProps> = memo(({path, childrenList, size='md'}) => {
  const [isBase] = useMediaQuery("(max-width: 480px)");
  const styles = {
    xs: { px: 2, py: 3, fontSize: '12px' },
    sm: { p: 3, fontSize: '14px' },
    md: { p: 4, fontSize: '14px' },
    lg: { px: 4, py: 5, fontSize: '16px' }
  };
  const TdStyle = isBase ? styles.xs : styles[size];
  const navigation = useNavigate()
  return (
    <Tr
      cursor='pointer'
      onClick={() => navigation(path)}
      _hover={{ backgroundColor: '#e9eef085' }}
    >
      {childrenList.map((children, index) => {
        return <Td key={index} {...TdStyle}>{children}</Td>;
      })}
    </Tr>
  );
});