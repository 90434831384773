import useSWR from "swr";


type Salesmen = {
  id: string;
  full_name: string;
}[]
export const useFetchSalesmen = () => {
  const fetchKey = `accounts/salesmen/`
  const { data: salesmen, error, isLoading } = useSWR<Salesmen>(fetchKey)
  return { salesmen, isLoading, error };
};