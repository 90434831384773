import React, { memo } from "react";
import { useRecoilValue } from "recoil";
import { lineProfileState } from "../../../store/lineProfileState";
import { Box, Button, useDisclosure } from '@chakra-ui/react'
import { ConfirmCouponUseDialog } from "./ConfirmCouponUseDialog";
import { useChangeRouletteCouponState } from "../../../hooks/games/roulette/useChangeRouletteCouponState";


type ConfirmRouletteCouponUseDialogProps = {
  couponId: string;
}
export const ConfirmRouletteCouponUseDialog: React.FC<ConfirmRouletteCouponUseDialogProps> = memo(({couponId}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const lineProfile = useRecoilValue(lineProfileState)
  const lineId = lineProfile!.userId
  const { isLoading, handleOnClick } = useChangeRouletteCouponState(couponId, lineId, onClose)
  return (
    <Box textAlign='center'>
      <Button variant='solid' colorScheme='messenger' w='70%' onClick={onOpen}>
        使用する
      </Button>
      <ConfirmCouponUseDialog {...{isOpen, onClose, handleOnClick, isLoading}}/>
    </Box>
  );
});