import useSWR from "swr";
import { useRecoilValue } from "recoil";
import { soCouponDateRangeState } from "../../store/soCouponDateRangeState";

type SurveyScores = {
  survey_answer_scores_count: number;
  survey_answer_scores_average: number;
  survey_answer_scores_1_count: number;
  survey_answer_scores_2_count: number;
  survey_answer_scores_3_count: number;
  survey_answer_scores_4_count: number;
  survey_answer_scores_5_count: number;
  survey_answer_scores_1_percentage: number;
  survey_answer_scores_2_percentage: number;
  survey_answer_scores_3_percentage: number;
  survey_answer_scores_4_percentage: number;
  survey_answer_scores_5_percentage: number;
  id: string;
  title: string;
}[];

export const useFetchSoCouponSurveyScores = (restaurantId: string) => {
  const dateRange = useRecoilValue(soCouponDateRangeState)
  const { data: soCouponSurveyScores, error, isLoading } = useSWR<SurveyScores>(`socoupon/survey/scores/?restaurantId=${restaurantId}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`)
  return { soCouponSurveyScores, isLoading, error };
};