import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { TopDataSection } from "../../organisms/dashboard/TopDataSection";
import { CenterDataSection } from "../../organisms/dashboard/CenterDataSection";
import { BottomDataSection } from "../../organisms/dashboard/BottomDataSection";
import { Stack } from "@chakra-ui/react";


export const Dashboard: React.FC = memo(() => {
  const { restaurantId } = useParams()
  if (!restaurantId) return null;
  return (
    <Stack spacing={{base: 5, sm: 6, lg: 6}}>
      <TopDataSection restaurantId={restaurantId} />
      <CenterDataSection restaurantId={restaurantId} />
      <BottomDataSection restaurantId={restaurantId} />
    </Stack>
  );
});