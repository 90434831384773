import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const managerEditSchema = yup.object().shape({
  user: yup.object().shape({
    username: yup.string().required('必須項目です'),
  }),
  manager: yup.object().shape({
    name: yup.string().required('必須項目です'),
    restaurants: yup.array().of(yup.string()).required('必須項目です'),
  }),
});
type Manager = {
  id: string;
  name: string;
  username: string;
  restaurants: string[];
}
export const useManagerEditForm = (manager?: Manager) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, control, setValue } = useForm({resolver: yupResolver(managerEditSchema)});
  useEffect(() => {
    if (manager) {
      setValue('manager.name', manager.name);
      setValue('user.username', manager.username);
      setValue('manager.restaurants', manager.restaurants);
    }
  }, [manager]);
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
    control,
  };
};