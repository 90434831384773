import React from 'react';
import { Input, IconButton, Flex, Grid } from "@chakra-ui/react";
import { FieldValues, UseFieldArrayRemove, UseFormRegister } from 'react-hook-form';
import { MinVisitsInputField } from './MinVisitsInputField';
import { MaxVisitsInputField } from './MaxVisitsInputField';
import { DeleteIcon } from '@chakra-ui/icons';


type RankSettingsEditInputsFieldProps = {
  index: number;
  prevMaxVisits: number;
  register: UseFormRegister<FieldValues>;
  remove: UseFieldArrayRemove;
  fieldsLength: number;
}
export const RankSettingsEditInputsField: React.FC<RankSettingsEditInputsFieldProps> = ({ index, prevMaxVisits, register, remove, fieldsLength }) => {
  return (
    <Flex align={{base: 'start', sm: 'center'}} direction={{base: 'column', sm: 'row'}} gap={{base: 0, sm: 2}} w='100%'>
      <Flex direction={{base: 'column', sm: 'row'}}  gap={2} w='100%'>
        <Input {...register(`rankSettings.${index}.name`)} placeholder='ランク名' required w={{base: '100%', sm: '60%'}} />
        <Grid templateColumns='repeat(2, 1fr)' gap={2} w={{base: '100%', sm: '40%'}}>
          <MinVisitsInputField index={index} prevMaxVisits={prevMaxVisits} register={register} />
          {fieldsLength !== index + 1 && <MaxVisitsInputField index={index} prevMaxVisits={prevMaxVisits} register={register} />}
        </Grid>
      </Flex>
      <IconButton aria-label='delete' icon={<DeleteIcon/>} colorScheme='red' size='sm' variant='ghost' onClick={() => remove(index)}/>
    </Flex>
  );
};