import React, { memo } from "react";
import { SelectVisitCount } from "./SelectVisitCount";
import { ImgInput } from "./ImgInput";
import { LineDeliverySampleImages } from "../../../molecules/LineDeliverySampleImages";
import { useLineSegmentCouponSubmit } from "../../../../hooks/externalApi/line/useLineSegmentCouponSubmit";
import { SubmitBtn } from "../../../atoms/button/SubmitBtn";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { useLineSegmentCouponForm } from "../../../../hooks/externalApi/line/useLineSegmentCouponForm";
import { LabelInput } from "./LabelInput";
import { ExpirationDateInput } from "./ExpirationDateInput";
import { TextTextarea } from "./TextTextarea";
import { TitleTextarea } from "./TitleTextarea";
import { AltTextTextarea } from "./AltTextTextarea";
import { TextMsgTextarea } from "./TextMsgTextarea";
import { NotesTextarea } from "./NotesTextarea";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { IoIosSend } from "react-icons/io";


type LineSegmentCouponFormProps = {
  restaurantId: string
  accessToken: string
}
const formComponents = [
  TextMsgTextarea,
  ImgInput,
  AltTextTextarea,
  TitleTextarea,
  TextTextarea,
  LabelInput,
  NotesTextarea,
  ExpirationDateInput
];
export const LineSegmentCouponForm: React.FC<LineSegmentCouponFormProps> = memo(({restaurantId, accessToken}) => {
  const { handleSubmit, errors, control, register, reset, isSubmitting } = useLineSegmentCouponForm();
  const { onSubmit } = useLineSegmentCouponSubmit(restaurantId, accessToken, reset);
  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" w={{base: '100%', lg: '810px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<IoIosSend />} heading='クーポン配信'/>
        </CardHeader>
        <CardBody py={2}>
          <VStack align='start' spacing={{base: 6, sm: 8}} w='100%'>
            <LineDeliverySampleImages />
            <SelectVisitCount control={control} errors={errors} restaurantId={restaurantId} />
            {formComponents.map((Component, index) => (
              <Component key={index} register={register} errors={errors} />
            ))}
          </VStack>
        </CardBody>
        <CardFooter>
          <SubmitBtn isLoading={isSubmitting}>配信</SubmitBtn>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});