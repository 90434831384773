import { Box, Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";


export const RestaurantNameSearch = () => {
  const [restaurantName, setRestaurantName] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onClickSearch = () => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('name', restaurantName);
    navigate(`?${newSearchParams.toString()}`);
  }
  const onClickClear = () => {
    setRestaurantName('');
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('name');
    navigate(`?${newSearchParams.toString()}`);
  }
  return (
    <Box>
      <InputGroup size='sm'>
        <Input
          placeholder="店舗名で検索"
          onChange={(e) => {setRestaurantName(e.target.value);}}
          rounded='md'
          onKeyDown={(e) => {if (e.key === 'Enter' && restaurantName) onClickSearch();}}
          value={restaurantName}
        />
        <InputRightElement>
          <Button size='xs' rounded='full' variant='ghost' onClick={onClickClear}>
            ✗
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}