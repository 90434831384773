import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import liff from '@line/liff';
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";


export const useNavigateNextPage = (isOpen: boolean, restaurantId?: string) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const next = queryParams.get('next');
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(async () => {
        if (next === 'member') {
          const res = await customNoAuthAxios.get(`external_api/restaurants/${restaurantId}/liff-restaurants/membership-coupon/liff-url/customer/`);
          liff.openWindow({ url: res.data + '/' + restaurantId });
        } else if (next === 'google') {
          const res = await customNoAuthAxios.get(`games/roulette/restaurants/${restaurantId}/setting/g-url/`);
          liff.openWindow({ url: res.data, external: true });
          liff.closeWindow();
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isOpen, next, restaurantId]);
};