import useSWR from "swr";


export type RestaurantStaffList = {
  id: string;
  name: string;
  nickname: string;
  img: string
  restaurant_survey_response_count: number;
  staff_survey_response_count: number;
  latest_response_day: string | null;
}[]
type PaginatedRestaurantStaffList = {
  count: number;
  staff_list: RestaurantStaffList;
  page_size: number;
};
export const useFetchPaginatedRestaurantStaffList = (restaurantId?: string, queryParams?: URLSearchParams) => {
  const fetchKey = restaurantId ? `restaurant_staff/restaurants/${restaurantId}/staff/paginated/${queryParams?.toString() && `?${queryParams}`}` : null;
  const { data: paginatedRestaurantStaffList, error, isLoading } = useSWR<PaginatedRestaurantStaffList>(fetchKey)
  return { paginatedRestaurantStaffList, isLoading, error };
};