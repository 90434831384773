import useSWR from "swr";


type Corporations = {
  id: string;
  name: string;
  username: string;
  president_last_name: string;
  president_first_name: string;
  president_phone_number: string;
}[]
type PaginatedCorporations = {
count: number;
corporations: Corporations;
};
export const useFetchPaginatedAssignedCorporations = (pageSize: number, currentPage: number, salesmanId?: string) => {
  const { data: paginatedCorporations, error, isLoading } = useSWR<PaginatedCorporations>(`accounts/salesmen/${salesmanId}/corporations/paginated/?pageSize=${pageSize}&currentPage=${currentPage}`)
  return { paginatedCorporations, isLoading, error };
};