import React, { useState } from 'react'
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';


type PasswordInputProps = {
  register: any;
  errors: any;
}
export const AuthPasswordInput: React.FC<PasswordInputProps> = ({register, errors}) => {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  return (
    <FormControl isRequired isInvalid={!!errors.password}>
      <FormLabel>パスワード</FormLabel>
      <InputGroup>
        <Input
          type={show ? 'text' : 'password'}
          placeholder="********"
          {...register('password')}
        />
        <InputRightElement>
          {show ? <ViewOffIcon cursor='pointer' onClick={handleClick} /> : <ViewIcon cursor='pointer' onClick={handleClick} />}
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
    </FormControl>
  );
};