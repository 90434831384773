import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchKuchikomyuSettingCustomerByRestaurantId } from "../../../hooks/kuchikomyu/useFetchKuchikomyuSettingCustomerByRestaurantId";
import { Box } from "@chakra-ui/react";
import { useSubmitKuchikomyuCustomerOverallScoreEn } from "../../../hooks/kuchikomyu/useSubmitKuchikomyuCustomerOverallScoreEn";
import { usePostKuchikomyuOverallScoreFormEn } from "../../../hooks/kuchikomyu/usePostKuchikomyuOverallScoreFormEn";
import { PostOverallScoreFormEn } from "../../molecules/form/PostOverallScoreFormEn";


export const PostKuchikomyuOverallScoreEn: React.FC = memo(() => {
  const params = useParams();
  const { restaurantId } = params;
  const { kuchikomyuSetting, isLoading } = useFetchKuchikomyuSettingCustomerByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />
  if (!kuchikomyuSetting) return null
  return (
    <Box p={4}>
      <ScorePost restaurantId={restaurantId!} branchScore={kuchikomyuSetting.branch_score} enUrl={kuchikomyuSetting.en_url} />
    </Box>
  );
});


const ScorePost: React.FC<{restaurantId: string, branchScore: number, enUrl: string}> = memo(({restaurantId, branchScore, enUrl}) => {
  const { handleSubmit, control, isSubmitting, errors } = usePostKuchikomyuOverallScoreFormEn()
  const onSubmit = useSubmitKuchikomyuCustomerOverallScoreEn(restaurantId!, branchScore, enUrl);
  return <PostOverallScoreFormEn onSubmit={handleSubmit(onSubmit)} control={control} isSubmitting={isSubmitting} errors={errors}/>
});