import useSWR from "swr";

type LineAccount = {
  id: string;
  customer: string;
  restaurant: string;
  line_id: string;
  display_name: string;
  picture_url: string;
  visit_count: string;
  last_visit: string;
  registered_date: string;
  customer_sex : string;
  customer_birth_year : string;
  customer_birth_month : string;
}
export const useFetchLineAccount = (lineAccountId?: string) => {
  const fetcherKey = lineAccountId ? `external_api/line-accounts/${lineAccountId}/` : null;
  const { data: lineAccount, error, isLoading } = useSWR<LineAccount>(fetcherKey)
  return { lineAccount, isLoading, error };
};
