import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { corporationGroupId, kuitanAgentGroupId, managerGroupId, restaurantGroupId, salesmanGroupId } from '../../groupNumber';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
} from '@chakra-ui/react'
import { useFetchUser } from '../../hooks/user/useFetchUser';


export const LogoutModal: React.FC = () => {
  const {user} = useFetchUser()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const groupToPath: { [key: number]: string } = {
    [salesmanGroupId]: '/salesman-login',
    [corporationGroupId]: '/corporation-login',
    [restaurantGroupId]: '/restaurant-login',
    [managerGroupId]: '/login/manager',
    [kuitanAgentGroupId]: '/login/kuitan-agent',
  };
  const path = user?.groups?.map(group => groupToPath[group]).find(path => path) || '/';
  const navigate = useNavigate();
  const logout = useCallback(() => {
    localStorage.clear();
    navigate(path);
  }, [path]);
  return (
    <>
      <Box fontSize={{base: 'xs', sm: 'sm'}} color='white' cursor='pointer' onClick={onOpen}>ログアウト</Box>
      <Modal isOpen={isOpen} onClose={onClose} size='xs'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb='0'>ログアウトしますか？</ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button colorScheme='messenger' size='sm' mr={3} onClick={logout}>
              はい
            </Button>
            <Button variant='ghost' size='sm' onClick={onClose}>いいえ</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};