import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const schema = yup.object().shape({
  auth_code: yup.string().required('入力必須項目です'),
});
export const useEditSignUpAuthCodeForm = (signUpAuthCode?: string) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(schema),});
  useEffect(() => {
    if (signUpAuthCode) {
      setValue('auth_code', signUpAuthCode);
    }
  }, [signUpAuthCode]);

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
    setValue
  };
};