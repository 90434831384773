import { useNavigate } from "react-router-dom";
import { useSuccessToast } from "../../toast/useSuccessToast";
import { useErrorToast } from "../../toast/useErrorToast";
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";


export const useSubmitSemiAutoReply = (reviewId: string, replyText: string, onClose: () => void, setIsSubmitting: (isSubmitting: boolean) => void) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await customNoAuthAxios.post(`external_api/google/reviews/${reviewId}/reply/semi-auto/`, {replyText: replyText})
      onClose()
      showToast('口コミに返信しました。');
      navigate(-1);
    } catch (error: any) {
      showErrorToast(error.response.data.detail)
    } finally {
      setIsSubmitting(false);
    }
  };

  return onSubmit;
};