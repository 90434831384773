import { useSwrNoAuth } from "../swr/useSwrNoAuth";


export type ManLaboSetting = {
  branch_score: number;
  g_url: string;
  is_navigate_g_url: boolean;
  branch_question: string;
}
export const useFetchManlaboSettingCustomerByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `manzokudo_labo/restaurants/${restaurantId}/setting/customer/` : null;
  const { data: manLaboSetting, error, isLoading: isLoadingManLaboSetting } = useSwrNoAuth<ManLaboSetting>(fetcherKey)
  return { manLaboSetting, isLoadingManLaboSetting, error };
};