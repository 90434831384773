import React, { memo } from 'react';
import { Grid } from '@chakra-ui/react';
import { SoCouponOverallScoreAnswerCountCard } from './SoCouponOverallScoreAnswerCountCard';
import { SoCouponUsedCouponUsersCountCard } from './SoCouponUsedCouponUsersCountCard';
import { soCouponDateRangeState } from '../../../store/soCouponDateRangeState';
import { useRecoilValue } from 'recoil';


type TopSoCouponAnalysisCardsProps = {
  restaurantId: string;
}
export const TopSoCouponAnalysisCards: React.FC<TopSoCouponAnalysisCardsProps> = memo(({restaurantId}) => {
  const dateRange = useRecoilValue(soCouponDateRangeState)
  return (
    <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'}} gap={3} w='100%'>
      <SoCouponOverallScoreAnswerCountCard restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />
      <SoCouponUsedCouponUsersCountCard restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />
    </Grid>
  );
});