import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const schema = yup.object().shape({
  auth_code: yup.string(),
});
export const useAuthKuitanAgentForm = () => {
  const { handleSubmit, register, formState: { isSubmitting } } = useForm({
    resolver: yupResolver(schema),
  });
  return {
    handleSubmit,
    register,
    isSubmitting,
  };
};