import React, { memo } from "react";
import { Box, Divider, Flex, HStack, Image, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { useFetchGoogleReviewReplies } from "../../../../hooks/externalApi/google/useFetchGoogleReviewReplies";
import { useNavigate } from "react-router-dom";
import { StarRating } from "../../../molecules/StarRating";
import { TruncatedText } from "../../../atoms/TruncatedText";
import { useResetReviewReplyFilter } from "../../../../hooks/externalApi/google/useResetReviewReplyFilter";
import { useGenerateReviewReplyQueryParams } from "../../../../hooks/externalApi/google/useGenerateReviewReplyQueryParams";
import { useRecoilState } from "recoil";
import { googleReviewsDateRangeState } from "../../../../store/googleReviewsDateRangeState";
import { googleReviewRatingState } from "../../../../store/googleReviewRatingState";
import { DateFilter } from "../../../molecules/DateFilter";
import { RatingFilter } from "../../../molecules/RatingFilter";
import { useExtractGoogleReviewReplyQueryParams } from "../../../../hooks/externalApi/google/useExtractGoogleReviewReplyQueryParams";
import { GoogleReviewFilterModal } from "../../../organisms/externalApi/google/GoogleReviewFilterModal";
import { BusinessTitleByRestaurantId } from "../../../organisms/externalApi/google/BusinessTitle";
import { useExtractRestaurantIdFromUrl } from "../../../../hooks/externalApi/line/useExtractRestaurantIdFromUrl";


export const GoogleReviewRepliesLiffApp = () => {
  const restaurantId = useExtractRestaurantIdFromUrl();
  if (!restaurantId) return <CircularLoading />
  return <ReviewsReplies restaurantId={restaurantId} />
};

const ReviewsReplies: React.FC<{restaurantId: string}> = ({restaurantId}) => {
  const requestQueryParams = useExtractGoogleReviewReplyQueryParams();
  const { replies, isLoading } = useFetchGoogleReviewReplies(restaurantId, requestQueryParams)
  if (isLoading) return <CircularLoading />
  return (
    <VStack align='start' p={4} spacing={1}>
      <BusinessTitleByRestaurantId restaurantId={restaurantId} />
      <Flex align='center' justify='space-between' w='100%'>
        <Text fontSize='sm' m='0' color='gray.500'>{replies && `${replies.length}件`}</Text>
        <FilterModal />
      </Flex>
      <VStack spacing={3}>
        {replies?.map((reply) => (
          <Reply key={reply.id} {...reply} />
        ))}
      </VStack>
    </VStack>
  );
};


type Reply = {
  id: string;
  reviewer_image_url: string;
  reviewer_name: string;
  review_date: string;
  rating: number;
  comment: string;
  reply: string;
}
const Reply: React.FC<Reply> = memo(({id, rating, comment, reviewer_image_url, reviewer_name, review_date, reply}) => {
  const navigate = useNavigate()
  const reviewText = comment.startsWith('(Translated by Google)') ? `${comment}` : `${comment}`.split('(Translated by Google)')[0].trim();
  return (
    <VStack onClick={() => navigate(id)} align='start' p={4} bg='white' rounded={10} boxShadow='rgba(0, 0, 0, 0.08) 1px 1px 4px' fontSize='sm' w='100%' spacing={1}>
      <HStack>
        <Image src={reviewer_image_url} boxSize='40px' />
        <Box>
          <Text fontWeight='bold' m='0'>{reviewer_name}</Text>
          <HStack>
            <StarRating count={rating} />
            <Text color='gray.500' m='0'>{review_date}</Text>
          </HStack>
        </Box>
      </HStack>
      <VStack align='start' spacing='1' fontSize='sm' whiteSpace='pre-wrap'>
        <TruncatedText m='0' text={reviewText} maxLength={60} />
        <Box borderLeft='2px solid #dadada' pl={2}>
          <TruncatedText m='0' text={reply} maxLength={60} />
        </Box>
      </VStack>
    </VStack>
  );
});


const FilterModal: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const generateQueryParams = useGenerateReviewReplyQueryParams();
  const resetFilter = useResetReviewReplyFilter()
  const onClickResetFilter = () => {
    resetFilter()
    onClose()
  }
  const navigateToUrlWithQueryParams = () => {
    navigate(`?${generateQueryParams()}`);
    onClose()
  }
  return (
    <GoogleReviewFilterModal
      onClose={onClose}
      onOpen={onOpen}
      isOpen={isOpen}
      navigateToUrlWithQueryParams={navigateToUrlWithQueryParams}
      onClickResetFilter={onClickResetFilter}
    >
      <FilterSection />
    </GoogleReviewFilterModal>
  )
}

const FilterSection: React.FC = () => {
  const [dateRange, setDateRange] = useRecoilState(googleReviewsDateRangeState);
  const [reviewRating, setReviewRating] = useRecoilState(googleReviewRatingState);
  const filters = [
    { label: '投稿日', component: <DateFilter dateRange={dateRange} setDateRange={setDateRange} /> },
    { label: '口コミ評価', component: <RatingFilter reviewRating={reviewRating} setReviewRating={setReviewRating} /> },
  ];
  return (
    <VStack spacing={0}>
      {filters.map((filter, index) => (
        <React.Fragment key={index}>
          <VStack align='start' w='100%'>
            <Box fontSize='sm' fontWeight='bold'>{filter.label}</Box>
            <Box w='100%'>{filter.component}</Box>
          </VStack>
          {index < filters.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </VStack>
  );
}
