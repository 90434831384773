import { memo } from "react"
import { useSoCouponRegisterForm } from "../../../hooks/socoupon/useSoCouponRegisterForm";
import { useSubmitSoCouponRegister } from "../../../hooks/socoupon/useSubmitSoCouponRegister";
import { SoCouponForm } from "../../organisms/soCoupon/SoCouponForm";
import { useParams } from "react-router-dom";


export const SoCouponRegister: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { handleSubmit, register, isSubmitting, errors, setValue } = useSoCouponRegisterForm()
  const onSubmit = useSubmitSoCouponRegister(restaurantId!)
  return (
    <SoCouponForm
      handleSubmit={handleSubmit(onSubmit)}
      register={register}
      isSubmitting={isSubmitting}
      errors={errors}
      setValue={setValue}
    />
  );
});