import React from 'react';
import { Avatar, CardBody, Grid, HStack, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/formatDate';
import { DarkShadowCard } from '../molecules/card/DarkShadowCard';


type LineAccount = {
  id: string;
  customer_sex: string;
  customer_birth_year: string;
  customer_birth_month: string;
  line_id: string;
  display_name: string;
  picture_url?: string;
  visit_count: number;
  registered_date: string;
  last_visit: string;
  is_active: boolean;
  blocked_date: null | string;
  customer: string;
  restaurant: string;
};
type SurveyAnswerTexts = {
  id: string;
  answer_text: string;
  answered_at: string;
  line_account: LineAccount;
}[]
type SurveyAnswerTextsLayoutProps = {
  surveyAnswerTexts: SurveyAnswerTexts;
}
export const SurveyAnswerTextsLayout: React.FC<SurveyAnswerTextsLayoutProps> = ({surveyAnswerTexts}) => {
  const navigation = useNavigate()
  if (surveyAnswerTexts.length === 0) return <>データは存在しません</>
  return (
    <Grid templateColumns='repeat(1, 1fr)' gap={4}>
      {surveyAnswerTexts?.map((surveyAnswerText) => (
        <DarkShadowCard key={surveyAnswerText.id} transition='0.3s' _hover={{transform: 'translateY(-5px)'}}>
          <CardBody cursor='pointer' p={{base: 4, sm: 5}} onClick={() => navigation(`../../line/accounts/${surveyAnswerText.line_account.id}`)}>
            <VStack align='start'>
              <HStack>
                <Avatar src={surveyAnswerText.line_account.picture_url} size='md'/>
                <VStack align='start' spacing={0}>
                  <Text fontWeight='bold' m='0' noOfLines={1}>{surveyAnswerText.line_account.display_name}</Text>
                  <Text m='0' color='gray.500' textAlign='right' fontSize='xs'>{formatDate(surveyAnswerText.answered_at)}</Text>
                </VStack>
              </HStack>
              <Text fontSize={{base: 'sm', sm: 'md'}} m={0}>{surveyAnswerText.answer_text}</Text>
            </VStack>
          </CardBody>
        </DarkShadowCard>
      ))}
    </Grid>
  );
};