import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";
import { useSWRConfig } from "swr";


export const useEditKoreanMenuImage = (id: string, mutateKey: string, onClose: () => void) => {
  const { mutate } = useSWRConfig()
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      data.img[0] && uploadData.append("img", data.img[0], data.img[0].name);
      await customAuthAxios.put(`menu/korean/${id}/`, uploadData)
      mutate(mutateKey)
      onClose()
      showToast('編集が完了しました');
    } catch (error: any) {
    }
  };
  return onSubmit;
};