import { CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { useParams, useSearchParams } from 'react-router-dom';
import { TableLayout } from "../../template/table/TableLayout";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { DarkShadowCard } from '../../molecules/card/DarkShadowCard';
import { TableRowLayout } from '../../template/table/TableRowLayout';
import { CardHeading } from '../../molecules/card/CardHeading';
import { CiStar } from "react-icons/ci";
import { CommentPopover } from '../../molecules/CommentPopover';
import { useFetchPaginatedRatingsByRestaurantId } from '../../../hooks/kuitan/useFetchPaginatedRatingsByRestaurantId';
import { CircularLoading } from '../../molecules/loading/CircularLoading';
import { formatDate } from '../../../utils/formatDate';


export const RatingsForRestaurant = () => {
  const { restaurantId } = useParams();
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const pageSize = 10;
  const { paginatedRatings, isLoading } = useFetchPaginatedRatingsByRestaurantId(pageSize, page, restaurantId);
  if (isLoading || !paginatedRatings) return <CircularLoading />
  const headers = ['総合評価', 'お料理', 'ドリンク', '接客', '店内衛生', 'コメント', '投稿日'];
  const rows = paginatedRatings.ratings.map((rating) => (
    <TableRowLayout
      key={rating.id}
      size='sm'
      childrenList={[
        rating.overall,
        rating.food,
        rating.drink,
        rating.customer_service,
        rating.hygiene,
        rating.comment ? <CommentPopover comment={rating.comment} /> : '',
        formatDate(rating.posted_at),
      ]}
    />
  ));
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<CiStar />} heading='評価'/>
      </CardHeader>
      <CardBody py={2}>
        <TableLayout headers={headers} rows={rows || []} size='sm' />
      </CardBody>
      <CardFooter>
        <Pagination totalItems={paginatedRatings.count} pageSize={pageSize}/>
      </CardFooter>
    </DarkShadowCard>
  );
};