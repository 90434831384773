import { useForm } from "react-hook-form";

export const useEditStampCardSettingsForm = () => {
  const { handleSubmit, register, formState: { isSubmitting } } = useForm();

  return {
    handleSubmit,
    register,
    isSubmitting,
  };
};