import React, { memo } from "react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { UserUsernameInput } from "../../molecules/form/UserUsernameInput";
import { UserEmailInput } from "../../molecules/form/UserEmailInput";
import { UserPhoneNumberInput } from "../../molecules/form/UserPhoneNumberInput";
import { useParams } from "react-router-dom";
import { useFetchCorporation } from "../../../hooks/corporations/useFetchCorporation";
import { useEditMyCorporationForm } from "../../../hooks/corporations/useEditMyCorporationForm";
import { useFetchUser } from "../../../hooks/user/useFetchUser";
import { useSubmitMyCorporationEdit } from "../../../hooks/corporations/useSubmitMyCorporationEdit";
import { CorporationNameInputField } from "../../organisms/corporations/CorporationNameInputField";
import { CorporationAddressInputField } from "../../organisms/corporations/CorporationAddressInputField";
import { PresidentNameInputField } from "../../organisms/corporations/PresidentNameInputField";
import { PresidentPhoneNumberInputField } from "../../organisms/corporations/PresidentPhoneNumberInputField";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { FaRegBuilding } from "react-icons/fa";
import { CardHeading } from "../../molecules/card/CardHeading";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const CorporationBaseInfoEdit: React.FC = memo(() => {
  const { corporationId } = useParams()
  const { user } = useFetchUser()
  const { corporation } = useFetchCorporation(corporationId)
  const { handleSubmit, register, isSubmitting, errors } = useEditMyCorporationForm(user, corporation);
  const onSubmit = useSubmitMyCorporationEdit(user?.id, corporation?.id);
  if (!user || !corporation) return <CircularLoading />
  const inputFields: InputField[] = [
    { component: CorporationNameInputField, props: { register, errors } },
    { component: UserUsernameInput, props: { register, errors } },
    { component: UserEmailInput, props: { register, errors } },
    { component: UserPhoneNumberInput, props: { register, errors } },
    { component: CorporationAddressInputField, props: { register, errors } },
    { component: PresidentNameInputField, props: { register, errors } },
    { component: PresidentPhoneNumberInputField, props: { register, errors } },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<FaRegBuilding />} heading='法人編集'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={4}>
              {inputFields.map(({ component: C, props }, i) => <C key={i} {...props} />)}
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});

