import React from 'react'
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'

type EmailInputProps = {
  register: any;
  errors: any;
}

export const AuthEmailInput: React.FC<EmailInputProps> = ({register, errors}) => {
  return (
    <FormControl isRequired isInvalid={!!errors.email}>
      <FormLabel>メールアドレス</FormLabel>
      <Input placeholder="ifrea@example.com" type="email" {...register('email')} />
      <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
    </FormControl>
  );
};