import React, { memo } from "react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useParams } from "react-router-dom";
import { BenefitContents } from "../../organisms/membership/BenefitContents";
import { useFetchCustomerBenefit } from "../../../hooks/membership/useFetchCustomerBenefit";
import { Box, Card, CardBody } from "@chakra-ui/react";


export const CustomerBenefit: React.FC = memo(() => {
  const params = useParams();
  const { benefitId } = params;
  const {benefit, isLoading} = useFetchCustomerBenefit(benefitId!)
  if (isLoading) return <CircularLoading/>
  if (!benefit) return null
  return (
    <Box p={5}>
      <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.1) 0px 0px 6px'>
        <CardBody >
          <BenefitContents {...benefit} />
        </CardBody>
      </Card>
    </Box>
  );
});