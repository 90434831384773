import { memo, useState } from 'react';
import { Box, Button, HStack, Input, InputProps } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';


type DateRange = {
  since: string;
  until: string;
};
type DateFilterSectionProps = {
  size?: 'xs' | 'sm';
  since?: string;
  until?: string;
};
export const MonthRangeFilter = memo(({ size='sm', since, until }: DateFilterSectionProps) => {
  const navigate = useNavigate()
  const location = useLocation();
  const searchParams = location.search
  const [inputDateRange, setInputDateRange] = useState<DateRange>({ since: since ?? '', until: until ?? '' });
  const handleSearch = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    inputDateRange.since && newSearchParams.set('since', inputDateRange.since);
    inputDateRange.until && newSearchParams.set('until', inputDateRange.until);
    navigate(`?${newSearchParams.toString()}`, { replace: true })
  }
  return (
    <HStack w='100%' justify='start' spacing={{base: 1, sm: 3, lg: 3}} >
      <DateInput
        size={size}
        w={size === 'xs' ? '110px' : '130px'}
        value={inputDateRange.since}
        onChange={(e) => setInputDateRange({ ...inputDateRange, since: e.target.value })}
        max={inputDateRange.until}
      />
      <Box>~</Box>
      <DateInput
        size={size}
        w={size === 'xs' ? '110px' : '130px'}
        value={inputDateRange.until}
        onChange={(e) => setInputDateRange({ ...inputDateRange, until: e.target.value })}
        min={inputDateRange.since}
      />
      <Button colorScheme="messenger" fontSize='xs' size={{base: 'xs', sm: 'sm', lg: 'sm'}} variant='outline' px={2} onClick={handleSearch}>
        適用
      </Button>
    </HStack>
  );
});


const DateInput = memo((props: InputProps) => {
  return (
    <Input
      {...props}
      size={{base: 'xs', sm: 'sm', lg: 'sm'}}
      type="month"
      bgColor='white'
      rounded={6}
      w={{base: '110px', sm: '130px', lg: '130px'}}
    />
  )
})