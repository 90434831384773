import React, { memo } from "react";
import { Button, ButtonProps } from "@chakra-ui/react";


export const SubmitBtn: React.FC<ButtonProps> = memo((props) => {
  return (
    <Button {...props} colorScheme='messenger' size='sm' type='submit'>
      {props.children}
    </Button>
  );
});


