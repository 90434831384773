import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const lineDevSchema = yup.object().shape({
  access_token: yup.string().required('アクセストークンを入力してください'),
  channel_secret: yup.string().required('チャネルシークレットを入力してください'),
});
export const useRegisterLineDevForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(lineDevSchema),});
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};