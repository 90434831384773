import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";


export const useRegisterEnglishMenuImages = (menuType: string, restaurantId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("restaurant", restaurantId ?? '');
      uploadData.append("menu_type", menuType);
      data.images?.forEach((image: any) => uploadData.append("images", image));
      const res = await customAuthAxios.post(`menu/english/`, uploadData);
      showToast(res.data.message);
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};