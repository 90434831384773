import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';
import { useErrorToast } from '../toast/useErrorToast';


export const useCreateAndNotifyNotice = () => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();
  const onSubmit = useCallback(async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append('title', data.title);
      uploadData.append('content', data.content);
      uploadData.append('url', data.url);
      data.img[0] && uploadData.append("img", data.img[0], data.img[0].name);
      const createRes = await customAuthAxios.post(
        'general_setting/notices/',
        uploadData
      );
      const imgUrl = createRes.data.img
      const notifyRes = await customAuthAxios.post(
        'general_setting/notify/update/',
        {img_url: imgUrl}
      );
      showToast(notifyRes.data.message)
      navigate('..', { relative: 'path' });
    } catch (error:any) {
    }
  }, [navigate, showToast, showErrorToast]);
  return onSubmit;
};