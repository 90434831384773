import { Button, Center, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";


type PaginationProps = {
  totalItems?: number;
  pageSize: number;
}
export const Pagination: React.FC<PaginationProps> = ({ totalItems, pageSize }) => {
  const [itemCount, setItemCount] = useState(0);
  useEffect(() => {
    if (totalItems !== undefined) setItemCount(totalItems);
  }, [totalItems]);
  const totalPages = Math.ceil(itemCount / pageSize)
  const [searchParams] = useSearchParams({ p: "" });
  const currentPage = Number(searchParams.get('p')) || 1
  const renderPageNumber = (page: number) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('p', page.toString());
    return (
      <Button
        key={page}
        as={Link}
        to={`?${newSearchParams.toString()}`}
        colorScheme={currentPage === page ? 'messenger' : 'gray'}
        mx={1}
        size='sm'
      >
        {page}
      </Button>
    );
  };
  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
      paginationItems.push(renderPageNumber(i));
    } else if (i === currentPage - 2 || i === currentPage + 2) {
      paginationItems.push(<Text mx={1}>...</Text>);
    }
  }
  return (
    <Center w='100%'>
      {paginationItems}
    </Center>
  );
};