import React, { memo } from 'react';
import { Box, Card, CardBody, Grid, Text, VStack } from '@chakra-ui/react';
import { MultipliedText } from '../atoms/MultipliedText';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';


type CustomerScores = {
  id: string;
  answer_score: number;
  answered_at: string;
  question_title: string;
}[]
export const AnsweredQuestions: React.FC = memo(() => {
  const { lineAccountId } = useParams();
  const { data: scores, error, isLoading } = useSWR<CustomerScores>(`socoupon/customer-survey-scores/?line_account_id=${lineAccountId}`)
  if (!scores || isLoading || error) return null
  return (
    <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)'}} gap={3} w='100%'>
      {scores.map((score) => (
        <Card key={score.id} border='4px solid #ffa6006b' boxShadow='md'>
          <CardBody>
            <VStack>
              <Box>{score.question_title}</Box>
              <Text fontSize='4xl' fontWeight='bold' m={0} color='orange' align='left'><MultipliedText text="★" count={score.answer_score} /></Text>
            </VStack>
          </CardBody>
        </Card>
      ))}
    </Grid>
  );
});