import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";

type UseSyncMaxVisitsParams = {
  fields: Array<{ id: string }>;
  setValue: UseFormSetValue<any>;
};

export const useSyncMaxVisits = ({ fields, setValue }: UseSyncMaxVisitsParams) => {
  useEffect(() => {
    const lastItemIndex = fields.length - 1;
    setValue(`rankSettings.${lastItemIndex}.max_visits`, null);
  }, [fields]);
};