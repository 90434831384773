import { Route } from 'react-router-dom';
import { KuitanDashboard } from '../components/pages/kuitan/KuitanDashboard';
import { RatingsForRestaurant } from '../components/pages/kuitan/RatingsForRestaurant';
import { SelectRequestMethod } from '../components/pages/kuitan/SelectRequestMethod';
import { RequestMonthlyDispatchByRestaurant } from '../components/pages/kuitan/RequestMonthlyDispatchByRestaurant';
import { RequestMultiMonthDispatchForRestaurant } from '../components/pages/kuitan/RequestMultiMonthDispatchForRestaurant';
import { DispatchRequestsForRestaurant } from '../components/pages/kuitan/DispatchRequestsForRestaurant';
import { MonthlyDispatchRequest } from '../components/pages/kuitan/MonthlyDispatchRequest';
import { MultiMonthDispatchRequest } from '../components/pages/kuitan/MultiMonthDispatchRequest';
import { EditKuitanSetting } from '../components/pages/kuitan/EditKuitanSetting';
import { KuitanSetting } from '../components/pages/kuitan/KuitanSetting';
import { RegisterKuitanSetting } from '../components/pages/kuitan/RegisterKuitanSetting';
import { MonthlyDispatchRequestForRestaurant } from '../components/pages/kuitan/MonthlyDispatchRequestForRestaurant';
import { MultiMonthDispatchRequestForRestaurant } from '../components/pages/kuitan/MultiMonthDispatchRequestForRestaurant';


const routes = [
  { path: 'dashboard', element: <KuitanDashboard /> },
  { path: 'ratings', element: <RatingsForRestaurant /> },
  { path: 'dispatch/request', element: <SelectRequestMethod /> },
  { path: 'dispatch/request/monthly', element: <RequestMonthlyDispatchByRestaurant /> },
  { path: 'dispatch/request/multi-month', element: <RequestMultiMonthDispatchForRestaurant /> },
  { path: 'dispatch/requests', element: <DispatchRequestsForRestaurant /> },
  { path: 'dispatch/requests/monthly/:requestId', element: <MonthlyDispatchRequestForRestaurant /> },
  { path: 'dispatch/requests/multi-month/:requestId', element: <MultiMonthDispatchRequestForRestaurant /> },
  { path: 'setting', element: <KuitanSetting /> },
  { path: 'setting/register', element: <RegisterKuitanSetting /> },
  { path: 'setting/:settingId/edit', element: <EditKuitanSetting /> },
];
export const KuitanRoutesForRestaurant = (
  <Route path='kuitan'>
    {routes.map((route) => <Route key={route.path} path={route.path} element={route.element} />)}
  </Route>
);