import useSWR from "swr";


export type StaffSurveyAnswerTrend = {
  date: string;
  count: number;
}[]
export const useFetchStaffSurveyAnswerTrend = (staffId?: string, queryParams?: URLSearchParams) => {
  const fetchKey = staffId ? `restaurant_staff/staff/${staffId}/survey/answer/trend/${queryParams?.toString() && `?${queryParams}`}` : null;
  const { data: staffSurveyAnswerTrend, error, isLoading } = useSWR<StaffSurveyAnswerTrend>(fetchKey)
  return { staffSurveyAnswerTrend, isLoading, error };
};