import useSWR from "swr";


type OverallRating = {
  [key: string]: number;
}
export const useFetchOverallRating = (restaurantId?: string,) => {
  const fetcherKey = restaurantId ? `kuitan/restaurants/${restaurantId}/ratings/overall/` : null
  const { data: overallRating, error, isLoading } = useSWR<OverallRating>(fetcherKey)
  return { overallRating, isLoading, error };
};