import axios from "axios";

export const initializeFacebookSDK = () => {
    return new Promise<void>((resolve) => {
        if (window.FB) {
            console.log("Facebook SDK already loaded, initializing...");
            window.FB.init({
                appId: process.env.REACT_APP_FACEBOOKAPP_ID || '',
                cookie: true,
                xfbml: true,
                version: 'v21.0',
            });
            resolve();
        } else {
            const script = document.createElement('script');
            script.src = "https://connect.facebook.net/en_US/sdk.js";
            script.async = true;
            script.onload = () => {
                console.log("Facebook SDK loaded, initializing...");
                window.FB.init({
                    appId: process.env.REACT_APP_FACEBOOKAPP_ID || '',
                    cookie: true,
                    xfbml: true,
                    version: 'v21.0',
                });
                resolve();
            };
            document.body.appendChild(script);
        }
    });
};

// 短期トークンを取得するための関数
export const facebookLogin = (): Promise<string | null> => {
    return new Promise((resolve) => {
        console.log("Invoking FB.login...");
        window.FB.login((response) => {
            console.log("FB.login response:", response);

            if (response.authResponse) {
                const shortLivedToken = response.authResponse.accessToken;
                console.log("Short-lived token obtained:", shortLivedToken);
                resolve(shortLivedToken); // 短期トークンを返す
            } else {
                console.log("Facebook login failed or cancelled by the user.");
                resolve(null);
            }
        }, { scope: 'instagram_basic,instagram_manage_insights,pages_show_list,pages_read_engagement' });
    });
};

// 長期アクセストークンを取得するための非同期関数
export const obtainLongLivedToken = async (shortLivedToken: string): Promise<string | null> => {
    const appId = process.env.REACT_APP_FACEBOOKAPP_ID;
    const appSecret = process.env.REACT_APP_FACEBOOKAPP_SECRET;

    console.log("Using App ID:", appId);
    console.log("Using App Secret:", appSecret);

    const url = `https://graph.facebook.com/v21.0/oauth/access_token`;
    const params = {
        grant_type: 'fb_exchange_token',
        client_id: appId,
        client_secret: appSecret,
        fb_exchange_token: shortLivedToken,
    };

    try {
        console.log("Requesting long-lived token...");
        const response = await axios.get(url, { params });
        const longLivedToken = response.data.access_token;
        console.log("Long-lived token obtained:", longLivedToken);
        return longLivedToken;
    } catch (error) {
        console.error("Error obtaining long-lived token:", error);
        return null;
    }
};