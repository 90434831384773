import React, { memo } from "react";
import { Box, Card, CardBody } from "@chakra-ui/react";
import { ValidRouletteCoupon } from "../../../hooks/membership/useFetchValidCoupon";
import { CouponLayout } from "../../molecules/CouponLayout";
import { ConfirmRouletteCouponUseDialog } from "./ConfirmRouletteCouponUseDialog";


export const CustomerValidRouletteCoupon: React.FC<ValidRouletteCoupon> = memo(({id, title, explanation, notes, img, expiration_date}) => {
  return (
    <Box>
      <Box p={5}>
        <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.1) 0px 0px 6px'>
          <CardBody >
            <CouponLayout
              title={title}
              explanation={explanation}
              expiration_date={expiration_date}
              notes={notes}
              img={img}
            />
          </CardBody>
        </Card>
      </Box>
      <ConfirmRouletteCouponUseDialog couponId={id} />
    </Box>
  );
});