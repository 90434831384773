import useSWR from "swr";


type RatingData = {
  rating: number;
  date: string;
}[]
export const useFetchGoogleRatings = (restaurantId?: string, requestQueryParams?: string) => {
  const fetcherKey = restaurantId ? `/external_api/restaurants/${restaurantId}/google/ratings/${requestQueryParams}` : null;
  const { data: ratingData, error, isLoading } = useSWR<RatingData>(fetcherKey);
  return { ratingData, isLoading, error };
};