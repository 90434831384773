import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";


export const useEditStaffSetting = (settingId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("restaurant_branch_score", data.restaurant_branch_score);
      uploadData.append("restaurant_branch_question", data.restaurant_branch_question);
      uploadData.append("staff_branch_score", data.staff_branch_score);
      uploadData.append("staff_branch_question", data.staff_branch_question);
      uploadData.append("is_navigate_g_url", data.is_navigate_g_url);
      if (data.name_tag_img[0]) {
        uploadData.append("name_tag_img", data.name_tag_img[0], data.name_tag_img[0].name);
      } else {
        uploadData.append("name_tag_img", '');
      }
      await customAuthAxios.put(`restaurant_staff/settings/${settingId}/`, uploadData)
      showToast('登録が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};