import * as yup from 'yup';




export const surveySchema = yup.object().shape({
  survey: yup.array().of(
    yup.object().shape({
      answer_score: yup.number().required('選択してください'),
    }),
  ),
  answer_text: yup.string(),
});