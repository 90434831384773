import useSWR from "swr";


type SalesmanLinking = {
  id: string;
  ifrea_analytics_salesman_id: string;
}
export const useFetchSalesmanLinking = (salesmanLinkingId?: string) => {
  const fetcherKey = salesmanLinkingId ? `/ifrea_analytics/salesman-linkings/${salesmanLinkingId}/` : null;
  const { data: salesmanLinking, error, isLoading } = useSWR<SalesmanLinking>(fetcherKey)
  return { salesmanLinking, isLoading, error };
};