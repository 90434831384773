import { useSwrWithQueryParams } from "../swr/useSwrWithQueryParams";


type TabelogSaveCounts = {
  save_count: number;
  date: string;
}[]
export const useFetchBenchmarkRestaurantTabelogSaveCounts = (benchmarkRestaurantId?: string, queryParams?: object) => {
  const fetchKey = benchmarkRestaurantId ? `ifrea_analytics/benchmark-restaurants/${benchmarkRestaurantId}/save-counts/` : null;
  const { data: saveCounts, error, isLoading } = useSwrWithQueryParams<TabelogSaveCounts>(fetchKey, queryParams)
  return { saveCounts, isLoading, error };
};