import { memo } from "react"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'


export const AlertMsg: React.FC<{msg:string, description?:string}> = memo(({msg, description}) => {
  return (
    <Alert
      status='error'
      variant='subtle'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      height='200px'
    >
      <AlertIcon boxSize='40px' mr={0} />
      <AlertTitle mt={4} mb={1} fontSize='xl' whiteSpace='pre-wrap'>
        {msg}
      </AlertTitle>
      <AlertDescription maxWidth='md' whiteSpace='pre-wrap'>
        {description}
      </AlertDescription>
    </Alert>
  );
});