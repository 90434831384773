import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Input, Skeleton, VStack } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CardHeading } from "../../molecules/card/CardHeading";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { RiTeamFill } from "react-icons/ri";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { useFetchSalesmen } from "../../../hooks/salesmen/useFetchSalesmen";


type TeamFormProps = {
  onSubmit: any;
  isSubmitting: boolean;
  register: any;
  control: any;
  errors: any;
  cardHeading: string;
  btnLabel: string;
}
export const TeamForm = ({ onSubmit, isSubmitting, register, control, errors, cardHeading, btnLabel }: TeamFormProps) => {
  type FormComponent = {
    component: React.ComponentType<any>;
    props: Record<string, any>;
  }
  const formComponents: FormComponent[] = [
    { component: ManagersSelect, props: { control, errors } },
    { component: NameInput, props: { register, errors } },
    { component: EmailInput, props: { register, errors } },
    { component: CcEmailInputs, props: { register, errors } },
  ];
  return (
    <Box m='auto' w='650px' maxW='100%'>
      <form onSubmit={onSubmit}>
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<RiTeamFill />} heading={cardHeading}/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              {formComponents.map(({ component: Component, props }, index) => <Component key={index} {...props} />)}
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>{btnLabel}</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};


const ManagersSelect = ({ control, errors }: { control: any, errors: any }) => {
  const { salesmen, isLoading } = useFetchSalesmen();
  const salesmanOptions = salesmen?.map(salesman => ({
    label: salesman.full_name,
    value: salesman.id
  }));
  return (
    <FormControl isRequired isInvalid={!!errors.managers}>
      <FormLabel>責任者</FormLabel>
      <Skeleton isLoaded={!isLoading} rounded={5}>
        <Controller
          name='managers'
          control={control}
          render={({ field }) => (
            <ReactSelectWithChakraStyle
              placeholder="選択"
              options={salesmanOptions}
              value={Array.isArray(field.value)
                ? salesmanOptions?.filter(option => field.value.includes(option.value))
                : salesmanOptions?.find(option => option.value === field.value)}
              onChange={(newValue:any) => {field.onChange(newValue.map((item:any) => item.value));}}
              isMulti
              required
            />
          )}
        />
      </Skeleton>
      <FormErrorMessage>{errors.managers?.message}</FormErrorMessage>
    </FormControl>
  )
}

const NameInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.name}>
      <FormLabel>チーム名</FormLabel>
      <Input {...register('name')} />
      <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
    </FormControl>
  )
}

const EmailInput = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isRequired isInvalid={!!errors.email}>
      <FormLabel>メールアドレス：To</FormLabel>
      <Input type='email' {...register('email')} />
      <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
    </FormControl>
  )
}

const CcEmailInputs = ({ register, errors }: { register: any, errors: any }) => {
  return (
    <FormControl isInvalid={!!errors.cc_emails}>
      <FormLabel>メールアドレス：Cc</FormLabel>
      <VStack>
        <Input type='email' {...register('cc_emails.0')} />
        <Input type='email' {...register('cc_emails.1')} />
        <Input type='email' {...register('cc_emails.2')} />
        <Input type='email' {...register('cc_emails.3')} />
      </VStack>
      <FormErrorMessage>{errors.cc_emails?.message}</FormErrorMessage>
    </FormControl>
  )
}