import React, { memo } from "react";
import { Box, Text } from "@chakra-ui/react"
import { Link } from 'react-router-dom';

export const SidebarItemLink: React.FC<{to: string, text: string}> = memo(({to, text}) => {
  return(
    <Box p='8px 16px'>
      <Link to={to} >
        <Text m='0'>{text}</Text>
      </Link>
    </Box>
  )
})