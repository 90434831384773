import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type ValidCoupons = {
  id: string;
  img: string | null;
  title: string;
}[]

export const useFetchValidCoupons = (restaurantId: string, lineId: string) => {
  const { data: validCoupons, error, isLoading } = useSwrNoAuth<ValidCoupons>(`membership/restaurants/${restaurantId}/line-accounts/${lineId}/valid-coupons/`)
  return { validCoupons, isLoading, error };
};