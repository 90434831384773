import React, { memo, useEffect, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { customerRestaurantId } from "../../store/customerRestaurantId";
import { lineProfileState } from "../../store/lineProfileState";
import { KuitanAgentBaseInfo } from "./kuitan/KuitanAgentBaseInfo";
import { EditKuitanAgentBaseInfo } from "./kuitan/EditKuitanAgentBaseInfo";
import { RatingsForKuitanAgent } from "./kuitan/RatingsForKuitanAgent";
import { AnswerManLaboSurvey } from "./manzokudoLabo/AnswerManLaboSurvey";
import { lineAccountIdState } from "../../store/fetchedData/lineAccountIdState";
import { AnswerStaffSurvey } from "./restaurantStaff/AnswerStaffSurvey";
import { AnswerRestaurantSurvey } from "./restaurantStaff/AnswerRestaurantSurvey";

export const Test: React.FC = memo(() => {
  const setLineAccountId = useSetRecoilState(lineAccountIdState)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setIsLoading(true)
    setLineAccountId('138d5fb5-de67-4b94-ae49-dc218bf327f2')
    setIsLoading(false)
  }, [])
  if (isLoading) {
    return null
  }
  return (
    <AnswerRestaurantSurvey restaurantId='24254f5e-1e99-4ef7-b281-f290ce9b83f9'/>
  );
});