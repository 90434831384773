import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const schema = yup.object().shape({
  replyText: yup.string(),
});
export const useReplyToGoogleReviewForm = (replyText?: string) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue, watch } = useForm({resolver: yupResolver(schema),});
  useEffect(() => {
    if (replyText) {
      setValue('replyText', replyText);
    }
  }, [replyText]);
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
    watch
  };
};