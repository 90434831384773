import useSWR from "swr";

type LiffTypes = {
  id: string;
  liff_name: string;
  type: string;
}[]

export const useFetchLiffTypes = () => {
  const { data: liffTypes, error, isLoading } = useSWR<LiffTypes>(`external_api/liff-types/`)
  return { liffTypes, isLoading, error };
};