import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";


type UserEmailInputProps = {
  register: any;
  errors: any;
  isRequired?: boolean;
}
export const UserEmailInput: React.FC<UserEmailInputProps> = memo(({register, errors, isRequired=true}) => {
  const emailError = errors.user?.email?.message;
  return (
    <FormControl isRequired={isRequired} isInvalid={errors.user?.email} w='100%'>
      <FormLabel>メールアドレス</FormLabel>
      <Input type="text" {...register('user.email')} />
      <FormErrorMessage>{emailError}</FormErrorMessage>
    </FormControl>
  );
});
