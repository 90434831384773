import React from "react";
import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";


type UrlInputProps = {
  register: any,
  errors: any,
}
export const UrlInput: React.FC<UrlInputProps> = ({register, errors}) => (
  <FormControl isRequired isInvalid={errors.url}>
    <FormLabel>URL</FormLabel>
    <Input
      {...register('url')}
      placeholder='https://example.com'
      type="url"
    />
    <FormErrorMessage>{errors['url']?.message}</FormErrorMessage>
  </FormControl>
);