import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";


export const useSubmitTableRegister = (restaurantId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("restaurant", restaurantId ?? '');
      data.tables.forEach((table: any) => {
        uploadData.append('names', table.name);
        table.image[0] && uploadData.append('images', table.image[0]);
      });
      const res = await customAuthAxios.post(`table_stock/tables/`, uploadData);
      showToast(res.data.message);
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};