import React, { memo } from 'react';
import { useFetchSoCouponLatestSurveyAnswerTexts } from '../../../hooks/socoupon/useFetchSoCouponLatestSurveyAnswerTexts';
import { LatestSurveyAnswerTextsLayout } from '../../template/LatestSurveyAnswerTextsLayout';
import { CardBody, CardHeader, Flex, HStack, Icon, Link, Skeleton, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { LightShadowCard } from '../../molecules/card/LightShadowCard';


type SoCouponLatestSurveyAnswerTextsProps = {
  restaurantId: string;
}
export const SoCouponLatestSurveyAnswerTexts: React.FC<SoCouponLatestSurveyAnswerTextsProps> = memo(({restaurantId}) => {
  const { soCouponLatestSurveyAnswerTexts, isLoading } = useFetchSoCouponLatestSurveyAnswerTexts(restaurantId)
  return(
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <LightShadowCard >
        <CardHeader>
          <Flex align='center' justify='space-between'>
            <Text m={0} fontSize='lg' fontWeight='bold'>最新レビュー</Text>
            <Link as={RouterLink} to='../answer-texts' fontSize='sm' color='gray.500' fontWeight='bold' _hover={{ color: 'gray.400' }}>
              <HStack align='center' spacing={1}>
                <Text m='0'>詳細</Text>
                <Icon as={IoIosArrowForward} />
              </HStack>
            </Link>
          </Flex>
        </CardHeader>
        <CardBody pt={0}>
          {soCouponLatestSurveyAnswerTexts && <LatestSurveyAnswerTextsLayout latestSurveyAnswerTexts={soCouponLatestSurveyAnswerTexts} />}
        </CardBody>
      </LightShadowCard>
    </Skeleton>
  );
});