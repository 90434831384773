import { customAuthAxios } from "../../../utils/customAuthAxios";
import { useSuccessToast } from "../../../hooks/toast/useSuccessToast";


export const useChangeTableSecurity = () => {
  const showToast = useSuccessToast();
  const changeTableSecurity = async (isEnabled: boolean, menuSettingId?: string) => {
    try {
      await customAuthAxios.put(
        `menu/settings/${menuSettingId}/`,
        { is_table_security: isEnabled }
      );
      showToast(isEnabled ? 'テーブルセキュリティをONにしました' : 'テーブルセキュリティをOFFにしました');
    } catch (error: any) {
      alert('エラー：設定を変更できませんでした');
      window.location.reload();
    }
  };

  return changeTableSecurity;
};