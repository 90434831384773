import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Input, VStack } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


type KuitanSettingFormProps = {
  onSubmit: () => void;
  isSubmitting: boolean;
  register: any;
  errors: any;
}
export const KuitanSettingForm: React.FC<KuitanSettingFormProps> = ({ onSubmit, isSubmitting, errors, register }) => {
  const tabelogUrlError = errors.tabelog_url?.message;
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <form onSubmit={onSubmit}>
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='食イタン設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.tabelog_url} w='100%'>
                <FormLabel>食べログURL</FormLabel>
                <Input type="url" {...register('tabelog_url')} />
                <FormErrorMessage>{tabelogUrlError}</FormErrorMessage>
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};