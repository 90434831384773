import { useParams } from "react-router-dom";
import { Box, CardBody, CardFooter, CardHeader, Divider, VStack } from "@chakra-ui/react";
import { SettingItem } from "../../organisms/SettingItem";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchSalesmanLinkingBySalesmanId } from "../../../hooks/ifreaAnalytics/useFetchSalesmanLinkingBySalesmanId";
import { EditIconBtn } from "../../atoms/button/EditIconBtn";


export const SalesmanLinking = () => {
  const { salesmanId } = useParams();
  const {salesmanLinking, isLoading} = useFetchSalesmanLinkingBySalesmanId(salesmanId);
  if (isLoading) return <CircularLoading />;
  const path = salesmanLinking?.id ? `${salesmanLinking.id}/edit` : 'register';
  return (
    <DarkShadowCard w='100%'>
      <CardHeader>
        <CardHeading icon={<SettingsIcon />} heading='ID紐づけ'/>
      </CardHeader>
      <CardBody pt={0}>
        <VStack spacing={4} fontWeight='bold' fontSize='sm'>
          <Divider m={0} />
          <Box w='100%'>
            <SettingItem label='ifreaアナリティクス営業マンID' content={salesmanLinking?.ifrea_analytics_salesman_id ?? '未登録'} />
          </Box>
          <Divider m={0} />
        </VStack>
      </CardBody>
      <CardFooter pt={0}>
        <EditIconBtn path={path} />
      </CardFooter>
    </DarkShadowCard>
  );
};