import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";

export const useEditStampCardSettings = (restaurantId: string, isUseDefault: boolean) => {
  const navigate = useNavigate();

  const createUploadData = (data: any) => {
    if (isUseDefault) return { ...data, img: null};

    const formData = new FormData();
    if (data.img && data.img[0]) {
      formData.append("img", data.img[0], data.img[0].name);
    }
    return formData;
  };

  const onSubmit = async (data: any) => {
    try {
      const uploadData = createUploadData(data);
      await customAuthAxios.post(`membership/restaurants/${restaurantId}/stamp-card/`, uploadData);
      navigate(-1);
    } catch (error) {
    }
  };

  return onSubmit;
};