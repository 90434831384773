import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const  schema = yup.object().shape({
  tabelog_url: yup.string().required('入力必須項目です'),
});
type KuitanSetting = {
  id: string;
  tabelog_url: string;
}
export const useEditKuitanSettingForm = (kuitanSetting?: KuitanSetting) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(schema)});
  useEffect(() => {
    if (kuitanSetting) {
      setValue('tabelog_url', kuitanSetting.tabelog_url);
    }
  }, [kuitanSetting]);
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};