import React, { memo } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchRestaurant } from "../../../hooks/restaurants/useFetchRestaurant";
import { Box, Divider, Image, VStack, CardHeader, CardBody, CardFooter, Link as ChakraLink, List, ListItem, Flex, Text, HStack, IconButton, useDisclosure } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { CiShop } from "react-icons/ci";
import { SettingItem } from "../../organisms/SettingItem";
import { ChevronRightIcon, DeleteIcon, EditIcon, SettingsIcon } from "@chakra-ui/icons";
import { useFetchUser } from "../../../hooks/user/useFetchUser";
import { useDelete } from "../../../hooks/useDelete";
import { DeleteModal } from "../../molecules/DeleteModal";


export const Restaurant = () => {
  const { restaurantId } = useParams();
  const { restaurant, isLoading } = useFetchRestaurant(restaurantId)
  if (isLoading) return <CircularLoading />
  if (!restaurantId || !restaurant) return null
  const restaurantDetails = [
    { label: '店舗ロゴ画像', value: <Image src={restaurant.logo} boxSize='100px' objectFit='contain' /> },
    { label: 'ID', value: restaurant.id },
    { label: 'ユーザーネーム', value: restaurant.user_data.username },
    { label: 'メールアドレス', value: restaurant.user_data.email },
    { label: '電話番号', value: restaurant.user_data.phone_number },
    { label: '住所', value: restaurant.address },
    { label: '代表者名', value: `${restaurant.manager_last_name}${restaurant.manager_first_name}` },
    { label: '代表者電話番号', value: restaurant.manager_phone_number },
    { label: '法人', value: restaurant.corporation_name },
    { label: 'プラン', value: restaurant.plan_name },
    { label: 'アドオン', value: restaurant.add_ons_names.join('、') },
  ];
  const isNeedLineSettings = ['即ーポン', '満足度ラボ', 'セグメント配信'].some(addOn => restaurant.add_ons_names.includes(addOn));
  return (
    <VStack spacing={4} w='100%'>
      <DarkShadowCard w='100%'>
        <CardHeader>
          <CardHeading icon={<CiShop />} heading={restaurant.name}/>
        </CardHeader>
        <CardBody py={0}>
          <VStack align='start' fontSize='sm' fontWeight='bold' spacing={4} w='100%'>
            <Divider my={0} />
            {restaurantDetails.map((detail) => (
              <React.Fragment key={detail.label}>
                <Box w='100%'>
                  <SettingItem label={detail.label} content={detail.value}/>
                </Box>
                <Divider my={0} />
              </React.Fragment>
            ))}
          </VStack>
        </CardBody>
        <CardFooter>
          <EditAndDeleteIcons id={restaurant.user} title={restaurant.name} />
        </CardFooter>
      </DarkShadowCard>
      <Box w='100%'>
        <Settings isNeedLineSettings={isNeedLineSettings} addOnsNames={restaurant.add_ons_names} />
      </Box>
    </VStack>
  );
};


const EditAndDeleteIcons: React.FC<{ id: string, title: string }> = memo(({ id, title }) => {
  const navigate = useNavigate()
  const { user, isLoading } = useFetchUser()
  if (isLoading || !user || !user.is_superuser) return null
  return (
    <HStack align='center' w='100%'>
      <ChakraLink as={Link} color='messenger.500' fontSize='12px' fontWeight='normal' to='pass/edit'>パスワード変更</ChakraLink>
      <IconButton
        colorScheme='messenger'
        aria-label='Edit Restaurant'
        icon={<EditIcon />}
        size='sm'
        variant='ghost'
        onClick={() => navigate('edit')}
      />
      <DeleteRestaurantModal id={id} title={title} />
    </HStack>
  )
})

const DeleteRestaurantModal: React.FC<{ id: string, title: string }> = memo(({id, title}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDelete(`accounts/users/${id}/`, onClose)
  return (
    <>
      <IconButton
        colorScheme='red'
        aria-label='Delete Restaurant'
        icon={<DeleteIcon />}
        size='sm'
        variant='ghost'
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={title}
        onSubmit={onSubmit}
      />
    </>
  );
});



type SettingsProps = {
  isNeedLineSettings: boolean
  addOnsNames: string[]
}
const Settings = ({isNeedLineSettings, addOnsNames}: SettingsProps) => {
  const navigate = useNavigate()
  const settingItems = [
    ...(isNeedLineSettings ? [
      { name: 'LineDev', navigateTo: 'line-devs' },
      { name: 'Liff', navigateTo: 'liff' }
    ] : []),
    ...(addOnsNames.includes('即ーポン') ? [{ name: '即ーポン', navigateTo: 'socoupon' }] : []),
    ...(addOnsNames.includes('口コミュ') ? [{ name: '口コミュ', navigateTo: 'kuchikomyu' }] : []),
    ...(addOnsNames.includes('満足度ラボ') ? [{ name: '満足度ラボ', navigateTo: 'manzokudo-labo' }] : []),
    ...(addOnsNames.includes('GBPマネジャー') ? [{ name: 'GBPマネジャー', navigateTo: 'google' }] : []),
    ...(addOnsNames.includes('ifreaアナリティクス') ? [{ name: 'ifreaアナリティクス', navigateTo: 'ifrea-analytics' }] : []),
    ...(addOnsNames.includes('ルーレット') ? [{ name: 'ゲーム', navigateTo: 'games' }] : []),
    ...(addOnsNames.includes('食イタン') ? [{ name: '食イタン', navigateTo: 'kuitan' }] : []),
    ...(addOnsNames.includes('従業員') ? [{ name: '従業員', navigateTo: 'restaurant-staff' }] : [])
  ];
  return (
    <DarkShadowCard fontWeight='bold'>
      <CardHeader>
        <CardHeading icon={<SettingsIcon />} heading='設定'/>
      </CardHeader>
      <CardBody pt={0}>
        <Divider m='0' borderColor='gray.300' />
        <List m='0'>
          {settingItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem cursor='pointer' _hover={{ bg: 'gray.100' }} px={2} py={3} onClick={() => navigate(`settings/${item.navigateTo}`)}>
                <Flex align='center' justifyContent='space-between' w='100%'>
                  <Text fontSize='14px' m='0'>{item.name}</Text>
                  <ChevronRightIcon color='gray.400' />
                </Flex>
              </ListItem>
              <Divider m='0' borderColor='gray.300' />
            </React.Fragment>
          ))}
        </List>
      </CardBody>
    </DarkShadowCard>
  )
}