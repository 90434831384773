import { Center, HStack, Text } from "@chakra-ui/react"

type CardHeadingProps = {
  icon: React.ReactNode
  heading: string
}
export const CardHeading: React.FC<CardHeadingProps> = ({icon, heading}) => {
  return (
    <HStack spacing={2}>
      <Center bgColor='messenger.500' color='white' p={1} borderRadius='20%' boxSize='30px'>
        {icon}
      </Center>
      <Text fontSize='lg' fontWeight='bold' m='0'>{heading}</Text>
    </HStack>
  )
}