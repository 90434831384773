import { memo } from "react"
import { GridItem, VStack } from "@chakra-ui/react";
import { CreateNewBox } from "../../molecules/CreateNewBox";
import { MenuImageLayout } from "./MenuImageLayout";
import { useFetchChineseFoodMenuByRestaurantId } from "../../../hooks/menu/useFetchChineseFoodMenuByRestaurantId";
import { useFetchChineseDrinkMenuByRestaurantId } from "../../../hooks/menu/useFetchChineseDrinkMenuByRestaurantId";
import { ChineseMenuEditModal } from "./ChineseMenuEditModal";
import { ChineseMenuDeleteModal } from "./ChineseMenuDeleteModal";
import { MenuImagesWrapper } from "./MenuImagesWrapper";
import { MenuImagesSkelton } from "./MenuImagesSkelton";


export const ChineseMenuImages: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  return (
    <VStack spacing={6}>
      <MenuImagesWrapper menuName="フード">
        <FoodMenuImages restaurantId={restaurantId} />
      </MenuImagesWrapper>
      <MenuImagesWrapper menuName="ドリンク">
        <DrinkMenuImages restaurantId={restaurantId} />
      </MenuImagesWrapper>
    </VStack>
  )
})


const FoodMenuImages: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { foodMenuImages, isLoading } = useFetchChineseFoodMenuByRestaurantId(restaurantId)
  return <MenuImages isLoading={isLoading} menuImages={foodMenuImages} restaurantId={restaurantId} menuType="food" />
})
const DrinkMenuImages: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { drinkMenuImages, isLoading } = useFetchChineseDrinkMenuByRestaurantId(restaurantId)
  return <MenuImages isLoading={isLoading} menuImages={drinkMenuImages} restaurantId={restaurantId} menuType="drink" />
})


type MenuImages = {
  id: string;
  restaurant: string;
  menu_type: string;
  img: string;
}[]
const MenuImages: React.FC<{isLoading: boolean, menuImages?: MenuImages, restaurantId: string, menuType: string}> = memo(({isLoading, menuImages, restaurantId, menuType}) => {
  if (isLoading || !menuImages) return <MenuImagesSkelton />
  return (
    <>
      {menuImages.map((menuImg) => (
        <GridItem key={menuImg.id}  aspectRatio={1 / Math.sqrt(2)}>
          <MenuImage img={menuImg.img} id={menuImg.id} menuType={menuImg.menu_type} restaurantId={restaurantId} />
        </GridItem>
      ))}
      <CreateNewBox path={`${menuType}/chinese/register`} aspectRatio={1 / Math.sqrt(2)} h='100%' />
    </>
  )
})


const MenuImage: React.FC<{img: string, id: string, menuType: string, restaurantId: string}> = memo(({img, id, menuType, restaurantId}) => {
  return (
    <MenuImageLayout img={img}>
      <>
        <ChineseMenuEditModal id={id} mutateKey={`menu/restaurants/${restaurantId}/${menuType}/chinese/`} />
        <ChineseMenuDeleteModal id={id} mutateKey={`menu/restaurants/${restaurantId}/${menuType}/chinese/`} />
      </>
    </MenuImageLayout>
  )
})