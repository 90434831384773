import React, { memo } from "react";
import { Image, Stack } from "@chakra-ui/react";


export const LineDeliverySampleImages: React.FC = memo(() => {
  return (
    <Stack align='center' direction={{base: 'column', sm: 'row'}} justify='center' >
      <Image w={{base: '60%', sm: '40%'}} objectFit='contain' src="/images/line-notification-example.png" alt="" />
      <Image w={{base: '50%', sm: '30%'}} objectFit='contain' src="/images/line-message-example.png" alt="" />
    </Stack>
  );
});