import { memo } from "react"
import { useFetchRankSettings } from "../../../hooks/membership/useFetchRankSettings";
import { Controller } from "react-hook-form";
import { Checkbox, CheckboxGroup, FormControl, FormErrorMessage, FormLabel, Grid, Text } from '@chakra-ui/react'
import { useParams } from "react-router-dom";

type RankCheckboxProps = {
  control: any;
  errors: any;
}
export const RankCheckbox: React.FC<RankCheckboxProps> = memo(({control, errors}) => {
  const { restaurantId } = useParams()
  const { rankSettings, isLoading } = useFetchRankSettings(restaurantId)
  if (isLoading || !rankSettings) return null
  return (
    <FormControl isInvalid={!!errors.ranks}>
      <FormLabel>特典を使用できるランク</FormLabel>
      <Controller
        name="ranks"
        control={control}
        render={({ field }) => {
          return (
            <CheckboxGroup {...field}>
              <Grid templateColumns={{base: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)'}} gap={1}>
                {rankSettings.map((setting) => (
                  <Checkbox key={setting.id} value={setting.id} name="ranks" w="100%" m='0'>
                    <Text fontSize='sm' m='0'>{setting.name}</Text>
                  </Checkbox>
                ))}
              </Grid>
            </CheckboxGroup>
          );
        }}
      />
      <FormErrorMessage>{errors.ranks?.message}</FormErrorMessage>
    </FormControl>
  );
});