import { Route } from 'react-router-dom';
import { Aggregate } from '../components/pages/ifreaAnalytics/Aggregate';
import { MediaAnalytics } from '../components/pages/ifreaAnalytics/MediaAnalytics';


export const IfreaAnalyticsRoutes = (
  <Route path='ifrea-analytics'>
    <Route path="aggregate" element={<Aggregate />} />
    <Route path="media-analytics" element={<MediaAnalytics />} />
  </Route>
);