import { Route } from 'react-router-dom';
import { RouletteSettingForRestaurant } from '../components/pages/games/roulette/RouletteSettingForRestaurant';
import { EditRouletteSettingByRestaurant } from '../components/pages/games/roulette/EditRouletteSettingByRestaurant';
import { RouletteSegments } from '../components/pages/games/roulette/RouletteSegments';
import { RegisterRouletteCoupon } from '../components/pages/games/roulette/RegisterRouletteCoupon';
import { RouletteSegment } from '../components/pages/games/roulette/RouletteSegment';
import { EditRouletteCoupon } from '../components/pages/games/roulette/EditRouletteCoupon';
import { RouletteQrCode } from '../components/pages/games/roulette/RouletteQrCode';


export const RouletteRoutes = (
  <Route path='roulette'>
    <Route path="setting" element={<RouletteSettingForRestaurant />} />
    <Route path="setting/edit/" element={<EditRouletteSettingByRestaurant />} />
    <Route path="segments" element={<RouletteSegments />} />
    <Route path="segments/register" element={<RegisterRouletteCoupon />} />
    <Route path="segments/:segmentId" element={<RouletteSegment />} />
    <Route path="segments/:segmentId/edit" element={<EditRouletteCoupon />} />
    <Route path="qr-code" element={<RouletteQrCode />} />
  </Route>
);