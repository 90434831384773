import React, { memo } from 'react';
import { CardBody, CardFooter, CardHeader, Divider, Text, VStack } from '@chakra-ui/react';
import { CircularLoading } from '../../molecules/loading/CircularLoading';
import { formatDate } from "../../../utils/formatDate";
import { useFetchPaginateKuchikomyuSurveyAnswerTexts } from '../../../hooks/kuchikomyu/useFetchPaginateKuchikomyuSurveyAnswerTexts';
import { Paginator } from '../../molecules/pagenation/Paginator';
import { useParams } from 'react-router-dom';
import { LightShadowCard } from '../../molecules/card/LightShadowCard';
import { CardHeading } from '../../molecules/card/CardHeading';
import { MdOutlineRateReview } from 'react-icons/md';


export const KuchikomyuSurveyAnswerTexts: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { kuchikomyuSurveyAnswerTextsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage } = useFetchPaginateKuchikomyuSurveyAnswerTexts(restaurantId!)
  if (isLoading || !kuchikomyuSurveyAnswerTextsPerPage) return <CircularLoading />
  return (
    <LightShadowCard >
      <CardHeader>
        <CardHeading icon={<MdOutlineRateReview />} heading='レビュー'/>
      </CardHeader>
      <CardBody py={0}>
        {kuchikomyuSurveyAnswerTextsPerPage.kuchikomyu_survey_answer_texts.length !== 0 ? (
          <>
            <Divider m={0} />
            {kuchikomyuSurveyAnswerTextsPerPage.kuchikomyu_survey_answer_texts.map((kuchikomyuSurveyAnswerText) => (
              <React.Fragment key={kuchikomyuSurveyAnswerText.id}>
                <VStack align='start' p={{base: 2, sm: 3}} spacing={1}>
                  <Text m='0' color='gray.500' fontSize={{base: 'xs', sm: 'sm'}} >{formatDate(kuchikomyuSurveyAnswerText.answered_at)}</Text>
                  <Text m='0' fontSize={{base: 'sm', sm: 'md'}}>{kuchikomyuSurveyAnswerText.answer_text}</Text>
                </VStack>
                <Divider m={0} />
              </React.Fragment>
            ))}
          </>
        ) : (
          <>データは存在しません</>
        )}
      </CardBody>
      <CardFooter>
        <Paginator pagesCount={pagesCount} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
      </CardFooter>
    </LightShadowCard>
  );
});