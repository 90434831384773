import useSWRInfinite from "swr/infinite";
import { useGeneratePaginationKey } from "../../useGeneratePaginationKey";
import { usePaginationStatus } from "../../usePagenationStatus";

type LineSegmentCouponHistory = {
  id: string;
  visit_count: number;
  img: string;
  notification_message: string;
  title: string;
  text: string;
  label: string;
  expiration_date: string;
  notes: string;
  send_at: string;
  has_coupon_users: string[];
  used_coupon_users: string[];
}[]
export const useFetchLineSegmentCouponHistoryPerPage = (restaurantId?: string) => {
  const limit = 10;

  const getKey = useGeneratePaginationKey(`membership/line/segment-coupon/history/?restaurantId=${restaurantId}&`, limit);

  const {
    data: lineSegmentCouponHistoryList,
    isValidating,
    isLoading,
    setSize
  } = useSWRInfinite<LineSegmentCouponHistory>(getKey);


  const isReachingEnd = usePaginationStatus(limit, lineSegmentCouponHistoryList);
  const lineSegmentCouponHistory = lineSegmentCouponHistoryList?.flat()

  return {
    lineSegmentCouponHistory,
    isValidating,
    isLoading,
    setSize,
    isReachingEnd
  };
};