import { memo, useState } from "react"
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { HStack, Radio, RadioGroup, Select, VStack } from "@chakra-ui/react";
import { useFetchTabelogAnalytics } from "../../../hooks/ifreaAnalytics/useFetchTabelogAnalytics";
import { AnalyticsLineGraphCards } from "./AnalyticsLineGraphCards";


type TabelogAnalyticsProps = {
  restaurantId: string
}
export const TabelogAnalytics: React.FC<TabelogAnalyticsProps> = memo(({restaurantId}) => {
  const { tabelogAnalytics, isLoading } = useFetchTabelogAnalytics(restaurantId)
  if (isLoading) return <CircularLoading />
  if (!tabelogAnalytics) return <>データが存在しません</>
  if (Object.keys(tabelogAnalytics).length === 0) return <>データが存在しません</>
  return <TabelogAnalyticsContents tabelogAnalytics={tabelogAnalytics} />
});


const TabelogAnalyticsContents: React.FC<{tabelogAnalytics: any}> = memo(({tabelogAnalytics}) => {
  const restaurantMediaNames = Object.keys(tabelogAnalytics)
  const [ restaurantMediaName, setRestaurantMediaName ] = useState(restaurantMediaNames[0])

  const data = {
    pc: [
      { title: "店舗TOP PV", dataKey: "pc_top_pv" },
      { title: "料理写真PV", dataKey: "pc_food_img_pv" },
      { title: "ドリンク写真PV", dataKey: "pc_drink_img_pv" },
      { title: "内観写真PV", dataKey: "pc_interior_img_pv" },
      { title: "外観写真PV", dataKey: "pc_exterior_img_pv" },
      { title: "口コミ・評価PV", dataKey: "pc_review_pv" },
      { title: "メニューPV", dataKey: "pc_menu_pv" },
      { title: "地図クーポンPV", dataKey: "pc_map_coupon_pv" },
      { title: "プレミアムクーポンPV", dataKey: "pc_premium_coupon_pv" },
      { title: "地図印刷PV", dataKey: "pc_map_print_pv" },
      { title: "座席情報PV", dataKey: "pc_seat_pv" },
      { title: "その他PV", dataKey: "pc_others_pv" },
    ],
    sp: [
      { title: "店舗TOP PV", dataKey: "sp_top_pv" },
      { title: "写真一覧PV", dataKey: "sp_img_pv" },
      { title: "写真詳細PV", dataKey: "sp_img_detail_pv" },
      { title: "口コミ・評価PV", dataKey: "sp_review_pv" },
      { title: "メニューPV", dataKey: "sp_menu_pv" },
      { title: "地図PV", dataKey: "sp_map_pv" },
      { title: "クーポンPV", dataKey: "sp_coupon_pv" },
      { title: "プレミアムクーポンPV", dataKey: "sp_premium_coupon_pv" },
      { title: "座席情報PV", dataKey: "sp_seat_pv" },
      { title: "その他PV", dataKey: "sp_others_pv" },
    ],
    reservation: [
      { title: "予約人数", dataKey: "reservation_num_people" },
      { title: "予約組数", dataKey: "reservation_num_groups" },
    ],
  };
  type DataType = keyof typeof data;
  const [ dataType, setDataType ] = useState<DataType>('pc')
  return (
    <VStack spacing={5}>
      <VStack align='start' w='100%'>
        <Select bg="white" size='sm' rounded='md' w='200px' defaultValue={restaurantMediaName} onChange={(e) => setRestaurantMediaName(e.target.value)}>
          {restaurantMediaNames.map(name => <option key={name} value={name}>{name}</option>)}
        </Select>
        <RadioGroup onChange={(value:DataType) => setDataType(value)} value={dataType}>
          <HStack spacing={3}>
            <Radio colorScheme='blue' size='sm' m='0' value='pc'>PC</Radio>
            <Radio colorScheme='blue' size='sm' m='0' value='sp'>スマホ</Radio>
            <Radio colorScheme='blue' size='sm' m='0' value='reservation'>予約</Radio>
          </HStack>
        </RadioGroup>
      </VStack>
      <AnalyticsLineGraphCards
        data={data[dataType]}
        analyticsDate={Object.keys(tabelogAnalytics[restaurantMediaName])}
        analyticsContents={Object.values(tabelogAnalytics[restaurantMediaName])}
        restaurantMediaName={restaurantMediaName}
        graphColorRgb='49, 130, 206'
      />
    </VStack>
  )
})