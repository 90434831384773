import useSWR from "swr";


export type StaffRankingScores = {
  'id': string,
  'name': string,
  'total_acquisitions': number,
  'img'?: string,
  'survey_answer_scores_count': number,
  'survey_answer_scores_average': number,
  'survey_answer_scores_5_count': number,
  'survey_answer_scores_5_percentage': number,
  'survey_answer_scores_4_count': number,
  'survey_answer_scores_4_percentage': number,
  'survey_answer_scores_3_count': number,
  'survey_answer_scores_3_percentage': number,
  'survey_answer_scores_2_count': number,
  'survey_answer_scores_2_percentage': number,
  'survey_answer_scores_1_count': number,
  'survey_answer_scores_1_percentage': number,
}[]
type PagedStaffRanking = {
  'count': number,
  'page_size': number,
  'staff_scores': StaffRankingScores,
}
export const useFetchPagedRestaurantSurveyStaffRankingScores = (restaurantId?: string, questionId?: string, queryParams?: URLSearchParams) => {
  const fetchKey = restaurantId ? `restaurant_staff/restaurants/${restaurantId}/restaurant-survey/questions/${questionId}/staff/ranking/paged${queryParams?.toString() && `?${queryParams}`}` : null;
  const { data: pagedScores, error, isLoading } = useSWR<PagedStaffRanking>(fetchKey)
  return { pagedScores, isLoading, error };
};