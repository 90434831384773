import React, { memo, useEffect, useState } from "react";
import { Paginator } from "../../molecules/pagenation/Paginator";
import { LineAccountsDetailTable } from "../../organisms/dashboard/LineAccountsDetailTable";
import { useFetchSortPaginateLineAccounts } from "../../../hooks/externalApi/line/useFetchSortPaginateLineAccounts";
import { Box, CardBody, CardFooter, CardHeader, HStack, IconButton, Input, VStack } from "@chakra-ui/react";
import { useSetRecoilState } from "recoil";
import { searchTargetNameState } from "../../../store/searchTargetNameState";
import { useParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { LightShadowCard } from "../../molecules/card/LightShadowCard";
import { FaUsers } from "react-icons/fa6";
import { CardHeading } from "../../molecules/card/CardHeading";


export const LineAccounts: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { lineAccounts, pages, pagesCount, currentPage, setCurrentPage } = useFetchSortPaginateLineAccounts(restaurantId);
  return (
    <Box w='100%'>
      <LightShadowCard>
        <CardHeader>
          <CardHeading icon={<FaUsers />} heading='登録ユーザー'/>
        </CardHeader>
        <CardBody py={1}>
          <VStack align='start' spacing={3}>
            <NameSearch setCurrentPage={setCurrentPage} />
            <Box w='100%'>
              {/*LineAccountsDetailTableコンポーネントの読み込み*/}
              <LineAccountsDetailTable lineAccounts={lineAccounts?.line_accounts} />
            </Box>
          </VStack>
        </CardBody>
        <CardFooter>
          <Paginator pagesCount={pagesCount} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
        </CardFooter>
      </LightShadowCard>
    </Box>
  );
});

const NameSearch: React.FC<{setCurrentPage: (value: React.SetStateAction<number>) => void}> = memo(({setCurrentPage}) => {
  const [name, setName] = useState<string|null>(null);
  const setSearchTargetName = useSetRecoilState(searchTargetNameState);
  useEffect(() => {
    setSearchTargetName(name)
    return () => {
      setSearchTargetName(null)
    }
  }, []);
  const search = () => {
    setCurrentPage(1)
    setSearchTargetName(name)
  }
  return (
    <HStack spacing={3}>
      <Input placeholder='アカウント名' onChange={(e) => setName(e.target.value)} size='sm' rounded={5} />
      <IconButton icon={<FaSearch />} colorScheme="messenger" size="sm" variant='outline' onClick={search} aria-label="search" />
    </HStack>
  );
});