import { TableLayout } from "../../template/table/TableLayout";
import { Box, CardBody, CardFooter, CardHeader, Flex, IconButton, VStack, Link as ChakraLink, Text, useDisclosure } from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { CiShop } from "react-icons/ci";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { Link } from 'react-router-dom';
import { AddIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { PartialCircularLoading } from "../../molecules/loading/PartialCircularLoading";
import { useFetchPaginatedBenchmarkRestaurants } from "../../../hooks/ifreaAnalytics/useFetchPaginatedBenchmarkRestaurants";
import { TableRowLayout } from "../../template/table/TableRowLayout";
import { ThreeDotsMenu } from "../../molecules/ThreeDotsMenu";
import { DeleteModal } from "../../molecules/DeleteModal";
import { KeyedMutator } from "swr";
import { useDeleteWithMutate } from "../../../hooks/useDeleteWithMutate";
import { AlertMsg } from "../../molecules/AlertMsg";
import { RestaurantNameSearch } from "../../molecules/RestaurantNameSearch";


export const BenchmarkRestaurants = () => {
  const { salesmanId } = useParams();
  const [searchParams] = useSearchParams({ p: "1" });
  const currentPage = Number(searchParams.get('p') || "1");
  const restaurantName = searchParams.get('name');
  const pageSize = 10;
  const queryParams = buildQueryParams(pageSize, currentPage, restaurantName);
  const { paginatedBenchmarkRestaurants, isLoading, error, mutate } = useFetchPaginatedBenchmarkRestaurants(salesmanId, queryParams)
  if (error?.response?.status === 404) return <AlertMsg msg={error.response.data.detail}/>
  return (
    <DarkShadowCard>
      <CardHeader>
        <Flex align='center' justify='space-between'>
          <CardHeading icon={<CiShop />} heading='店舗'/>
          <IconButton
            as={Link}
            variant='outline'
            colorScheme='messenger'
            aria-label='register'
            icon={<AddIcon />}
            size='xs'
            to='register'
          />
        </Flex>
      </CardHeader>
      <CardBody py={2}>
        <VStack align='start'>
          <RestaurantNameSearch />
          <Box w='100%'>
            <BenchmarkRestaurantsTable isLoading={isLoading} benchmarkRestaurants={paginatedBenchmarkRestaurants?.benchmark_restaurants} mutate={mutate} />
          </Box>
        </VStack>
      </CardBody>
      <CardFooter>
        <Pagination totalItems={paginatedBenchmarkRestaurants?.count} pageSize={pageSize}/>
      </CardFooter>
    </DarkShadowCard>
  );
};


const buildQueryParams = (pageSize: number, currentPage: number, restaurantName: string|null) => {
  const queryParams: { pageSize: number; currentPage: number; name?: string } = {pageSize, currentPage};
  restaurantName && (queryParams.name = restaurantName);
  return queryParams;
};


const BenchmarkRestaurantsTable = ({isLoading, benchmarkRestaurants, mutate}: {isLoading: boolean, benchmarkRestaurants?: {id: string, name: string, url: string}[], mutate: KeyedMutator<any>}) => {
  if (isLoading || !benchmarkRestaurants) return <PartialCircularLoading />
  const headers = ['店舗名', ''];
  const rows = benchmarkRestaurants.map((benchmarkRestaurant) => (
    <TableRowLayout
      key={benchmarkRestaurant.id}
      childrenList={[
        <ChakraLink href={benchmarkRestaurant.url} isExternal>
          <Text m={0}>{benchmarkRestaurant.name}<ExternalLinkIcon mx='4px'/></Text>
        </ChakraLink>,
        <ThreeDotsMenu childrenList={[
          <Link to={`${benchmarkRestaurant.id}/edit`}>編集</Link>,
          <BenchmarkRestaurantDeleteModal
            id={benchmarkRestaurant.id}
            restaurantName={benchmarkRestaurant.name}
            mutate={mutate}
          />
          ]}
        />
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />
}


export const BenchmarkRestaurantDeleteModal = ({id, restaurantName, mutate}: {id: string, restaurantName: string, mutate: KeyedMutator<any>}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDeleteWithMutate(`ifrea_analytics/benchmark-restaurants/${id}/`, onClose, mutate)
  return (
    <>
      <Text color='red' m={0} onClick={(e) => { e.stopPropagation(); onOpen(); }} w='100%' _hover={{ opacity: '.7' }}>削除</Text>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={restaurantName}
        onSubmit={onSubmit}
      />
    </>
  );
};