import useSWR from "swr";

type MenuImages = {
  id: string;
  restaurant: string;
  menu_type: string;
  img: string;
}[]
export const useFetchFoodMenuByRestaurantId = (restaurantId: string) => {
  const fetcherKey = restaurantId ? `menu/restaurants/${restaurantId}/food/` : null;
  const { data: foodMenuImages, error, isLoading } = useSWR<MenuImages>(fetcherKey)
  return { foodMenuImages, isLoading, error };
};