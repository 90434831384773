import { LineRoutes } from './LineRoutes';
import { TableRoutes } from './TableRoutes';
import { SegmentDeliveryRoutes } from './SegmentDeliveryRoutes';
import { MenuRoutes } from './MenuRoutes';
import { MembershipRoutes } from './MembershipRoutes';
import { SoCouponRoutes } from './SoCouponRoutes';
import { SoCouponsRoutes } from './SoCouponsRoutes';
import { KuchikomyuRoutes } from './KuchikomyuRoutes';
import { ManLaboRoutes } from './ManLaboRoutes';
import { GoogleRoutes } from './GoogleRoutes';
import { IfreaAnalyticsRoutes } from './IfreaAnalyticsRoutes';
import { GamesRoutes } from './GamesRoutes';
import { KuitanRoutesForRestaurant } from './KuitanRoutesForRestaurant';
import { RestaurantStaffRoutes } from './RestaurantStaffRoutes';


export const AddOnsRoutes = (
  <>
    {LineRoutes}
    {TableRoutes}
    {SegmentDeliveryRoutes}
    {MenuRoutes}
    {MembershipRoutes}
    {SoCouponRoutes}
    {SoCouponsRoutes}
    {KuchikomyuRoutes}
    {ManLaboRoutes}
    {GoogleRoutes}
    {IfreaAnalyticsRoutes}
    {GamesRoutes}
    {KuitanRoutesForRestaurant}
    {RestaurantStaffRoutes}
  </>
);