import React, { memo } from 'react';
import { Grid, VStack } from '@chakra-ui/react';
import { ManLaboSurveyAnswersCountCard } from '../../organisms/manzokudoLabo/ManLaboSurveyAnswersCountCard';
import { useRecoilState } from 'recoil';
import { ManLaboLatestSurveyAnswerTextsCard } from '../../organisms/manzokudoLabo/ManLaboLatestSurveyAnswerTextsCard';
import { ManlaboQuestionScores } from '../../organisms/manzokudoLabo/ManlaboQuestionScores';
import { manLaboDateRangeState } from '../../../store/manLaboDateRangeState';
import { CircularLoading } from '../../molecules/loading/CircularLoading';
import { useParams } from 'react-router-dom';
import { DateFilterSection } from '../../organisms/DateFilterSection';


export const ManLaboScores: React.FC = memo(() => {
  const [dateRange, setDateRange] = useRecoilState(manLaboDateRangeState)
  const { restaurantId } = useParams()
  if (!restaurantId) return <CircularLoading />
  return (
    <VStack w='100%' spacing={5}>
      <DateFilterSection setDateRange={setDateRange} />
      <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'}} gap={3} w='100%'>
        <ManLaboSurveyAnswersCountCard restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />
        <ManLaboLatestSurveyAnswerTextsCard restaurantId={restaurantId} />
      </Grid>
      <ManlaboQuestionScores restaurantId={restaurantId} />
    </VStack>
  );
});