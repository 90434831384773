import React, { memo } from "react";
import { PostSoCouponOverallScore } from "../../organisms/soCoupon/PostSoCouponOverallScore";
import { useExtractRestaurantIdFromUrl } from "../../../hooks/externalApi/line/useExtractRestaurantIdFromUrl";
import { LiffLoginProcess } from "../../organisms/LiffLoginProcess";
import { CircularLoading } from "../../molecules/loading/CircularLoading";


export const SoCouponLiffApp: React.FC = memo(() => {
  const restaurantId = useExtractRestaurantIdFromUrl()
  if (restaurantId === null) return <CircularLoading />
  return (
    <LiffLoginProcess restaurantId={restaurantId} liffType="socoupon">
      <PostSoCouponOverallScore restaurantId={restaurantId}/>
    </LiffLoginProcess>
  );
});