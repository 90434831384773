import React, { memo } from "react";
import { Box, Text, CardBody, Flex, Center } from "@chakra-ui/react";
import { LightShadowCard } from "./LightShadowCard";


type SmallCardWithIconProps = {
  title: string
  data: string
  children: React.ReactNode
}
export const SmallCardWithIcon: React.FC<SmallCardWithIconProps> = memo(({data, title, children}) => {
  return (
    <LightShadowCard>
      <CardBody px={{base: 4, sm: 4, lg: 5}} py={{base: 4, sm: 3, lg: 3}} >
        <Flex align='center' justifyContent='space-between'>
          <Box fontWeight='bold'>
            <Text m={0} fontSize={{base: '6vw', sm: '3.5vw', lg: '2.5vw'}}>{data}</Text>
            <Text color='#929fad' mb={1} fontSize={{base: '3.2vw', sm: '1.4vw', lg: '0.95vw'}}>{title}</Text>
          </Box>
          <Center bgColor='messenger.500' color='white' p={1} borderRadius='20%' boxSize={{base: '45px', sm: '50px', lg: '50px'}}>
            {children}
          </Center>
        </Flex>
      </CardBody>
    </LightShadowCard>
  );
});