import { List } from "@chakra-ui/react";
import React, { memo } from "react";


export const AccordionList = memo(({children}: {children: React.ReactNode}) => {
  return (
    <List spacing={3}>
      {children}
    </List>
  );
});