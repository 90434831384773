import useSWR from "swr";


type ManLaboSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  is_navigate_g_url: boolean;
  branch_question: string;
  branch_question_name: string;
  qr_card_img?: string;
}
export const useFetchManlaboSetting = (settingsId?: string) => {
  const fetcherKey = settingsId ? `manzokudo_labo/settings/${settingsId}/` : null;
  const { data: manLaboSetting, error, isLoading } = useSWR<ManLaboSetting>(fetcherKey)
  return { manLaboSetting, isLoading, error };
};