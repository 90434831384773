import React, { memo } from "react";
import { useFetchUser } from "../../../hooks/user/useFetchUser";
import { SignUpUsernameInput } from "../../molecules/form/SignUpUsernameInput";
import { AuthEmailInput } from "../../molecules/form/AuthEmailInput";
import { AuthPhoneNumberInput } from "../../molecules/form/AuthPhoneNumberInput";
import { FullNameInput } from "../../molecules/form/FullNameInput";
import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { useEditMySalesmanForm } from "../../../hooks/salesmen/useEditMySalesmanForm";
import { useSubmitMySalesmanEdit } from "../../../hooks/salesmen/useSubmitMySalesmanEdit";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { FaRegUser } from "react-icons/fa";


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const SalesmanBaseInfoEdit: React.FC = memo(() => {
  const { user } = useFetchUser()
  const { handleSubmit, register, isSubmitting, errors } = useEditMySalesmanForm(user);
  const onSubmit = useSubmitMySalesmanEdit(user?.id)
  const inputFields: InputField[] = [
    { component: SignUpUsernameInput, props: { register, errors } },
    { component: AuthEmailInput, props: { register, errors } },
    { component: AuthPhoneNumberInput, props: { register, errors } },
    { component: FullNameInput, props: { register, errors } },
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}}>
      <DarkShadowCard as='form' onSubmit={handleSubmit(onSubmit)}>
        <CardHeader>
          <CardHeading icon={<FaRegUser />} heading='基本情報編集'/>
        </CardHeader>
        <CardBody py={2}>
          <VStack spacing={4}>
            {inputFields.map(({ component: InputComponent, props }, index) => (
              <InputComponent key={index} {...props} />
            ))}
          </VStack>
        </CardBody>
        <CardFooter>
          <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});