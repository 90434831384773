import { Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useLocation } from "react-router-dom"

type IconProps = {
  size: string;
}
type FooterNavProps = {
  Icon: React.ComponentType<IconProps>;
  label: string;
  navigateTo: string;
}

export const FooterNav: React.FC<FooterNavProps> = ({Icon, label, navigateTo}) => {
  const navigate = useNavigate()
  const location = useLocation();
  const isCurrentPath = location.pathname === navigateTo;
  return (
    <VStack color={isCurrentPath ? '#FFBD58' : 'white'} spacing={0} onClick={() => navigate(navigateTo)}>
      <Icon size='7vw' />
      <Text fontSize='2.8vw' m='0'>{label}</Text>
    </VStack>
  );
};
