import useSWR from "swr";


type Restaurants = {
    id: string;
    name: string;
    username: string;
    manager_last_name: string;
    manager_first_name: string;
    manager_phone_number: string;
    corporation_name: string;
}[];
type RestaurantsPerPage = {
  count: number;
  restaurants: Restaurants;
};
export const useFetchManagerRestaurantsPerPage = (pageSize: number, currentPage: number, managerId?: string) => {
  const fetcKey = `accounts/managers/${managerId}/restaurants/per-page/?pageSize=${pageSize}&currentPage=${currentPage}`
  const { data: restaurantsPerPage, error, isLoading } = useSWR<RestaurantsPerPage>(fetcKey)
  return { restaurantsPerPage, isLoading, error };
};