import React, { memo } from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { useFetchPlans } from "../../../hooks/useFetchPlans";

type PlanSelectorProps = {
  control: any;
  errors: any;
}
export const PlanSelector: React.FC<PlanSelectorProps> = memo(({control, errors}) => {
  const { plans, isLoading } = useFetchPlans()
  if (isLoading || !plans) return null

  const planOption = plans.map((plan) => ({
    value: plan.id,
    label: plan.name
  }));
  const planError = errors.restaurant?.plan?.message;

  return (
    <FormControl isRequired isInvalid={errors.restaurant?.plan} w='100%'>
      <FormLabel>プラン</FormLabel>
      <Controller
        name='restaurant.plan'
        control={control}
        render={({ field }) => (
          <ReactSelectWithChakraStyle
            noOptionsMessage={({inputValue}) => !inputValue && 'プランが存在しません'}
            placeholder="選択"
            options={planOption}
            value={planOption?.find((x) => x.value === field.value)}
            onChange={(newValue:any) => {
              field.onChange(newValue?.value);
            }}
            instanceId="restaurant-plan-select"
          />
        )}
      />
      <FormErrorMessage>{planError}</FormErrorMessage>
    </FormControl>
  );
});