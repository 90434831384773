import { Flex, Text } from "@chakra-ui/react";
import React, { memo } from "react";

type StarRatingProps = {
  count: number;
}
export const StarRating: React.FC<StarRatingProps> = memo(({ count }) => {
  const rating = '★'.repeat(count);
  const blank = '☆'.repeat(5 - count);
  return (
    <Flex>
      <Text color='orange' m='0'>{rating}</Text>
      <Text color='gray.300' m='0'>{blank}</Text>
    </Flex>
  );
});