import { useRecoilValue } from "recoil";
import { totalState } from "../../store/totalState";
import { useCustomPagination } from "../useCustomPagenation";
import { useUpdateTotal } from "../useUpdateTotal";
import { useFetchSoCouponQuestionWithLineAccountsPerPage } from "./useFetchSoCouponQuestionWithLineAccountsParPage";

export const useFetchPaginateSoCouponQuestionWithLineAccounts = (questionId: string) => {
  const total = useRecoilValue(totalState);
  const { pages, pagesCount, currentPage, setCurrentPage, pageSize } = useCustomPagination(total, 24);
  const { soCouponQuestionWithLineAccountsPerPage, isLoading } = useFetchSoCouponQuestionWithLineAccountsPerPage(questionId, pageSize, currentPage)
  useUpdateTotal(soCouponQuestionWithLineAccountsPerPage);

  return { soCouponQuestionWithLineAccountsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage };
};