import { useParams } from "react-router-dom";
import { useRegisterRouletteCouponForm } from "../../../../hooks/games/roulette/useRegisterRouletteCouponForm";
import { useRegisterRouletteCouponAndSegments } from "../../../../hooks/games/roulette/useRegisterRouletteCouponAndSegments";
import { RouletteCouponForm } from "../../../organisms/games/roulette/RouletteCouponForm";


export const RegisterRouletteCoupon = () => {
  const { restaurantId } = useParams()
  const { handleSubmit, register, isSubmitting, errors } = useRegisterRouletteCouponForm()
  const onSubmit = useRegisterRouletteCouponAndSegments(restaurantId!)
  return (
    <RouletteCouponForm
      register={register}
      errors={errors}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};