import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useErrorToast } from '../toast/useErrorToast';


export const useSubmitMySalesmanEdit = (userId?: string) => {
  const showErrorToast = useErrorToast();
  const navigate = useNavigate();

  const editUser = async (userData: any) => {
    return await customAuthAxios.put(`accounts/users/${userId}/`, userData);
  };

  const onSubmit = useCallback(async (data: any) => {
    try {
      await editUser(data);
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.username) {
        showErrorToast(error.response.data.username)
      }
    }
  }, [navigate]);

  return onSubmit;
};