import React, { memo } from "react"
import QRCode from "qrcode.react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { AlertMsg } from "../../molecules/AlertMsg";
import { Box, CardBody, CardFooter, CardHeader, Flex } from "@chakra-ui/react";
import { useFetchKuchikomyuSettingByRestaurantId } from "../../../hooks/kuchikomyu/useFetchKuchikomyuSettingByRestaurantId";
import { QrCardsDownloader } from "../../molecules/QrCardsDownloader";
import { useParams } from "react-router-dom";
import { QrCordDownloadModal } from "../../molecules/QrCordDownloadModal";
import { CardHeading } from "../../molecules/card/CardHeading";
import { MdOutlineQrCode2 } from "react-icons/md";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";

const frontUrl = process.env.REACT_APP_FRONT_URL

export const KuchikomyuQrCodeEn: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { kuchikomyuSetting, isLoading, error} = useFetchKuchikomyuSettingByRestaurantId(restaurantId)
  if (!restaurantId || isLoading) return <CircularLoading />
  if (error && error.response.status === 404 || !kuchikomyuSetting) return <AlertMsg msg='設定を完了してください。' />;
  if (!kuchikomyuSetting.en_url) return <AlertMsg msg="遷移先URLが設定されていません。営業担当に連絡してください。"/>
  if (!kuchikomyuSetting.branch_score) return <AlertMsg msg="ブランチスコアを設定してください"/>
  const transitionUrl = `${frontUrl}kuchikomyu/post-score/en/${restaurantId}`
  const qrCardImg = kuchikomyuSetting.en_qr_card_img ?? '/images/kuchikomyu-en.png'
  return (
    <Box w={{ base: '100%', md: '500px' }} m='auto'>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<MdOutlineQrCode2 size='20px' />} heading='QRコード'/>
        </CardHeader>
        <CardBody>
          <QrCard url={transitionUrl} qrCardImg={qrCardImg} />
        </CardBody>
        <CardFooter>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={2}>
            <QrCardsDownloader>
              <QrCard url={transitionUrl} qrCardImg={qrCardImg} />
            </QrCardsDownloader>
            <QrCordDownloadModal url={transitionUrl} />
          </Flex>
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});

const QrCard: React.FC<{url: string, qrCardImg: string}> = ({url, qrCardImg}) => {
  return (
    <Box
      bgImage={qrCardImg}
      w={{ base: '250px', sm: '297px' }}
      h={{ base: '353.5px', sm: '420px' }}
      bgSize="contain"
      bgRepeat="no-repeat"
      m="auto"
      boxShadow='rgba(0, 0, 0, 0.08) 0px 0px 5.5px'
    >
      <Box
        as={QRCode}
        value={url}
        sx={{
          width: "120px !important",
          objectFit: "contain",
          position: "relative",
          top: '34%',
          margin: "auto",
        }}
      />
    </Box>
  );
};
