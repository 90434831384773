import React, { memo } from "react"
import {
  Button,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'


type QrCardsDownloadModalProps = {
  children: React.ReactNode
  handleDownload: () => void
}
export const QrCardsDownloadModal: React.FC<QrCardsDownloadModalProps> = memo(({children, handleDownload}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen} colorScheme="messenger" size='sm'>QRカードダウンロード</Button>
      <Modal isOpen={isOpen} onClose={onClose} size={{base: 'full', md: '4xl'}} scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>QRカード</ModalHeader>
          <Divider m='0' />
          <ModalCloseButton />
          <ModalBody css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}>
            {children}
          </ModalBody>
          <Divider m='0' />
          <ModalFooter>
            <Button colorScheme='messenger' size='sm' onClick={handleDownload}>ダウンロード</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});