import React from "react";
import { FormControl, FormLabel, Textarea } from "@chakra-ui/react";


type TextMsgTextareaProps = {
  register: any,
}
export const TextMsgTextarea: React.FC<TextMsgTextareaProps> = ({register}) => (
  <FormControl>
    <FormLabel>テキストメッセージ</FormLabel>
    <Textarea
      {...register('textMsg')}
      maxLength={5000}
      placeholder='17時以降のご来店で特別なディナーセットが15%オフ。夜景と共にお楽しみください。有効期限: 2025年01月31日'
    />
  </FormControl>
);