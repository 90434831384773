import React from 'react';
import { Flex, Button } from '@chakra-ui/react';

interface LoadMoreButtonProps {
  isValidating: boolean;
  setSize: (size: number | ((prevSize: number) => number)) => void;
  isReachingEnd: boolean;
}

export const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({ isValidating, setSize, isReachingEnd }) => {
  if (isReachingEnd) return null;

  return (
    <Flex justifyContent="center" mt={4}>
      <Button isLoading={isValidating} onClick={() => setSize(prevSize => prevSize + 1)} size='sm'>もっと見る</Button>
    </Flex>
  );
};