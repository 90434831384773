import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { Paginator } from "../../molecules/pagenation/Paginator";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { TableLayout } from "../../template/table/TableLayout";
import { CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { CiShop } from "react-icons/ci";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { useFetchPaginateManagerRestaurants } from "../../../hooks/restaurants/useFetchPaginateManagerRestaurants";


export const ManagerRestaurants: React.FC = memo(() => {
  const { managerId } = useParams();
  const { restaurantsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage } = useFetchPaginateManagerRestaurants(managerId)
  if (isLoading) return <CircularLoading />;
  if (!restaurantsPerPage) return null
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<CiShop />} heading='店舗一覧'/>
      </CardHeader>
      <CardBody py={2}>
        <RestaurantsTable restaurants={restaurantsPerPage.restaurants} />
      </CardBody>
      <CardFooter>
        <Paginator pagesCount={pagesCount} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
      </CardFooter>
    </DarkShadowCard>
  );
});


type Restaurants = {
  id: string;
  name: string;
  username: string;
  manager_last_name: string;
  manager_first_name: string;
  manager_phone_number: string;
  corporation_name: string;
}[];
const RestaurantsTable: React.FC<{restaurants: Restaurants}> = memo(({restaurants}) => {
  const headers = ['店舗名', 'ユーザーネーム', '代表者名', '代表者電話番号'];
  const rows = restaurants.map((restaurant) => (
    <TableRowWithNavigationLayout
      key={restaurant.id}
      path={`${restaurant.id}/dashboard`}
      childrenList={[
        restaurant.name,
        restaurant.username,
        restaurant.manager_last_name + restaurant.manager_first_name,
        restaurant.manager_phone_number,
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} />
})