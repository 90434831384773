import { Badge, Box, CardBody, CardFooter, CardHeader, Divider } from "@chakra-ui/react";
import { useFetchManLaboSettingByRestaurantId } from "../../../hooks/manzokudoLabo/useFetchManLaboSettingByRestaurantId";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { AlertMsg } from "../../molecules/AlertMsg";
import { SettingItem } from "../../organisms/SettingItem";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const ManLaboSettingForRestaurant = () => {
  const { restaurantId } = useParams()
  const {manLaboSetting, isLoading} = useFetchManLaboSettingByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />
  if (!manLaboSetting) return <AlertMsg msg="設定が完了してません。営業担当に連絡してください。"/>
  const qrCardImg = manLaboSetting.qr_card_img ?? '/images/manzokudo-labo.png'
  return (
    <Box m='auto' w={{base: '100%', md: '700px'}} fontSize='sm'>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='満足度ラボ設定'/>
        </CardHeader>
        <CardBody py={2}>
          <Box fontWeight='bold'>
            <SettingItem label='ブランチスコア' content={manLaboSetting.branch_score} />
            <Divider />
            <SettingItem label='ブランチクエッション' content={manLaboSetting.branch_question_name ?? '未登録'} />
            <Divider />
            <SettingItem label='レビューへの遷移' content={manLaboSetting?.is_navigate_g_url ? <Badge colorScheme='green'>ON</Badge> : <Badge colorScheme='gray'>OFF</Badge>} />
            <Divider />
            <SettingItem
              label='QRカード画像'
              content={
                <Box
                  bgImage={`url(${qrCardImg})`}
                  w="210px"
                  h="297px"
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  border='0.1px solid #c0c0c09d'
                />
              }
            />
          </Box>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集' />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
};