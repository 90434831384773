import { useForm, useFieldArray } from "react-hook-form";

export const useRankSettingsForm = () => {
  const { handleSubmit, formState: { isSubmitting }, register, reset, control, watch, setValue } = useForm({});

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rankSettings",
  });

  return { handleSubmit, isSubmitting, register, reset, watch, fields, append, remove, setValue };
};