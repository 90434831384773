import React, { memo } from "react";
import { AnswerManLaboSurvey } from "../manzokudoLabo/AnswerManLaboSurvey";
import { useSetRecoilState } from "recoil";
import { lineAccountIdState } from "../../../store/fetchedData/lineAccountIdState";

export const ManLaboTest: React.FC = memo(() => {
  const restaurantId = 'a7d7ec02-7bcb-41fd-9a0f-f25aa300367b'
  const setLineAccountId = useSetRecoilState(lineAccountIdState)
  setLineAccountId('257466db-dd19-4de5-9978-a87e67a1d455')

  return (
    <AnswerManLaboSurvey restaurantId={restaurantId} />
  );
});

