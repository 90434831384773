import React, { useEffect, useState } from 'react';
import { Button, Center, FormControl, Input, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, Text, VStack, useDisclosure, } from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom';
import { AuthPasswordInput } from '../../molecules/form/AuthPasswordInput';
import { AuthEmailInput } from '../../molecules/form/AuthEmailInput';
import { AuthPhoneNumberInput } from '../../molecules/form/AuthPhoneNumberInput';
import { FullNameInput } from '../../molecules/form/FullNameInput';
import { useSignUpSalesmanForm } from "../../../hooks/auth/useSignUpSalesmanForm";
import { useSignUpSalesman } from "../../../hooks/auth/useSignUpSalesman";
import { SignUpUsernameInput } from "../../molecules/form/SignUpUsernameInput";
import { useFetchAuthCode } from '../../../hooks/auth/useFetchAuthCode';
import { useSuccessToast } from '../../../hooks/toast/useSuccessToast';
import { useErrorToast } from '../../../hooks/toast/useErrorToast';


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const SignUpSalesman = () => {
  const { handleSubmit, register, isSubmitting, errors } = useSignUpSalesmanForm();
  const onSubmit = useSignUpSalesman();
  const inputFields: InputField[] = [
    { component: SignUpUsernameInput, props: { register, errors } },
    { component: AuthPasswordInput, props: { register, errors } },
    { component: AuthEmailInput, props: { register, errors } },
    { component: AuthPhoneNumberInput, props: { register, errors } },
    { component: FullNameInput, props: { register, errors } },
  ];
  return (
    <Center>
      <AuthModal/>
      <VStack as='form' align='start' spacing={6} bg="white" p={{ base: 6, sm: 10 }} rounded={6} w={{ base: '100%', sm: '560px' }} onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize='2xl' fontWeight='bold' mb={0}>サインアップ</Text>
        <VStack align='start' w='100%' spacing={4}>
          {inputFields.map(({ component: InputComponent, props }, index) => <InputComponent key={index} {...props} />)}
        </VStack>
        <VStack align='start' w='100%'>
          <Button colorScheme="messenger" w='100%' isLoading={isSubmitting} type='submit'>サインアップ</Button>
          <Link as={RouterLink} to='/salesman-login' color='messenger.500' fontSize='sm'>ログイン</Link>
        </VStack>
      </VStack>
    </Center>
  );
};


const AuthModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { authCode, isLoading } = useFetchAuthCode();
  const [inputValue, setInputValue] = useState('');
  const showSuccessToast = useSuccessToast();
  const showErrorToast = useErrorToast();
  const handleSubmit = () => {
    if (inputValue === authCode) {
      onClose();
      showSuccessToast('認証に成功しました');
    } else {
      showErrorToast('認証コードが誤っています');
    }
  };
  useEffect(() => onOpen(), [])
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size='xs'>
      <ModalOverlay backdropFilter='blur(20px)' />
      <ModalContent>
        <ModalHeader fontSize='md'>認証コードを入力してください</ModalHeader>
        <ModalBody>
          <FormControl isRequired>
            <Skeleton isLoaded={!isLoading} rounded='md'>
              <Input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </Skeleton>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button type='submit' colorScheme='messenger' size='sm' isDisabled={isLoading} onClick={handleSubmit}>
            送信
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};