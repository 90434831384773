import { useParams } from "react-router-dom";
import { useFetchRouletteSetting } from "../../../../hooks/games/roulette/useFetchRouletteSetting";
import { useEditRouletteSettingFormForAdmin } from "../../../../hooks/games/roulette/useEditRouletteSettingFormForAdmin";
import { useEditRouletteSetting } from "../../../../hooks/games/roulette/useEditRouletteSetting";
import { RouletteSettingFormForSalesman } from "../../../organisms/games/roulette/RouletteSettingFormForSalesman";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";


export const EditRouletteSettingByAdmin = () => {
  const { settingId } = useParams();
  const { rouletteSetting, isLoading } = useFetchRouletteSetting(settingId);
  const { handleSubmit, register, control, isSubmitting, errors } = useEditRouletteSettingFormForAdmin(rouletteSetting)
  const onSubmit = useEditRouletteSetting(settingId)
  if (isLoading) return <CircularLoading />
  return <RouletteSettingFormForSalesman onSubmit={handleSubmit(onSubmit)} register={register} control={control} isSubmitting={isSubmitting} errors={errors} />
};