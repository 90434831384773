import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';


export const useRegisterStaff = (restaurantId: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = useCallback(async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("restaurant", restaurantId);
      uploadData.append("name", data.name);
      uploadData.append("nickname", data.nickname);
      data.img[0] && uploadData.append("img", data.img[0], data.img[0].name);
      await customAuthAxios.post(
        'restaurant_staff/staff/',
        uploadData
      );
      showToast('スタッフを登録しました')
      navigate(-1)
    } catch (error:any) {
    }
  }, [navigate, showToast]);
  return onSubmit;
};