import React, { memo, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'


type ConfirmCouponUseDialogProps ={
  isOpen: boolean;
  onClose: () => void;
  handleOnClick: () => void;
  isLoading: boolean;
}
export const ConfirmCouponUseDialog: React.FC<ConfirmCouponUseDialogProps> = memo(({isOpen, onClose, handleOnClick, isLoading}) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size='xs'
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent fontWeight='bold'>
          <AlertDialogHeader fontSize='lg'>
            クーポンを使用しますか？
          </AlertDialogHeader>
          <AlertDialogBody color='red' fontSize='sm'>
            こちらのボタンは店舗スタッフが押しますので、ご自身で押さないで下さい。<br/>クーポンは一度だけ使用でき、再度使用することはできません。
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button size='sm' ref={cancelRef} onClick={onClose}>
              キャンセル
            </Button>
            <Button size='sm' colorScheme='messenger' onClick={handleOnClick} ml={3} isLoading={isLoading}>
              使用する
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
});