import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const  salesmanLinkingSchema = yup.object().shape({
  ifrea_analytics_salesman_id: yup.string().required('入力必須項目です'),
});
type SalesmanLinking = {
  id: string;
  ifrea_analytics_salesman_id: string;
}
export const useEditSalesmanLinkingForm = (salesmanLinking?: SalesmanLinking) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(salesmanLinkingSchema),});

  useEffect(() => {
    if (salesmanLinking) {
      setValue('ifrea_analytics_salesman_id', salesmanLinking.ifrea_analytics_salesman_id);
    }
  }, [salesmanLinking]);

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};