import { Grid, GridItem } from "@chakra-ui/react";
import useSidebar from "../../hooks/useSidebar";


type LayoutProps = {
  header: React.ReactNode;
  nav: React.ReactNode;
  main: React.ReactNode;
  footer: React.ReactNode;
}
const Layout = ({ header, nav, main, footer }: LayoutProps) => {
  const {sidebarOpen} = useSidebar();
  return (
    <Grid
      templateAreas={`"nav header"
                      "nav main"
                      "nav footer"`}
      gridTemplateRows={'64px 1fr auto'}
      gridTemplateColumns={{ base: '0px 100vw', lg: sidebarOpen ? '250px calc(100vw - 250px)' : '0px 100vw' }}
      h='100vh'
      overflow='auto'
      sx={{
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
      }}
    >
      <GridItem area={'header'} as='header' boxSizing='border-box' p={4} bg="#0E0F0E" position='sticky' top={0} zIndex={10}>
        {header}
      </GridItem>
      <GridItem area={'nav'} bgColor="#0E0F0E" color='white' display={{base: 'none', lg: sidebarOpen ? 'block' : 'none'}} fontWeight='bold' position='fixed' top={0} zIndex={10} w='250px'>
        {nav}
      </GridItem>
      <GridItem area={'main'} as='main' p={4}>
        {main}
      </GridItem>
      <GridItem area={'footer'} as='footer' p={4} bg="#0E0F0E" color='white' fontSize={{base: 'xs', sm: 'sm'}}>
        {footer}
      </GridItem>
    </Grid>
  );
};


export default Layout;