import useSWR from "swr";
import { UUID } from "crypto";


type UserData = {
  id: UUID;
  username: string;
  email: string;
  phone_number: string;
  last_name: string;
  first_name: string;
  groups: number[];
}
export type KuitanAgent = {
  id: string;
  user: string;
  user_data: UserData;
  tabelog: string;
  instagram: string;
  tiktok: string;
  x: string;
}
export const useFetchKuitanAgent = (kuitanAgentId?: string) => {
  const fetcherKey = kuitanAgentId ? `accounts/kuitan-agents/${kuitanAgentId}/` : null;
  const { data: kuitanAgent, error, isLoading } = useSWR<KuitanAgent>(fetcherKey)
  return { kuitanAgent, isLoading, error };
};