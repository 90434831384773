import { Box, Text, Textarea, VStack } from "@chakra-ui/react";
import { memo } from "react"
import { useParams } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useAnswerKuchikomyuSurveyEnForm } from "../../../hooks/kuchikomyu/useAnswerKuchikomyuSurveyEnForm";
import { useSubmitKuchikomyuCustomerSurveyEn } from "../../../hooks/kuchikomyu/useSubmitKuchikomyuCustomerSurveyEn";
import { BlocksLogoForCustomerPostScoreForm } from "../../atoms/BlocksLogoForCustomerPostScoreForm";
import { CustomerPostScoreBtn } from "../../atoms/button/CustomerPostScoreBtn";
import { SurveyScoreRating } from "../../molecules/form/SurveyScoreRating";
import { useFetchKuchikomyuSurveyEnSelectedQuestionsCustomer } from "../../../hooks/kuchikomyu/useFetchKuchikomyuSurveyEnSelectedQuestionsCustomer";


export const AnswerKuchikomyuSurveyEn: React.FC = memo(() => {
  const params = useParams();
  const { restaurantId } = params;
  const { selectedQuestions, isLoading } = useFetchKuchikomyuSurveyEnSelectedQuestionsCustomer(restaurantId!)
  if (isLoading) return <CircularLoading />
  if (!selectedQuestions) return null

  return (
    <Box p={4}>
      <AnswerForm restaurantId={restaurantId!} selectedQuestions={selectedQuestions} />
    </Box>
  );
});


type SelectedQuestions = {
  id: string;
  question: string;
  question_content: string;
}[]
const AnswerForm: React.FC<{restaurantId: string, selectedQuestions: SelectedQuestions }> = memo(({restaurantId, selectedQuestions}) => {
  const { handleSubmit, register, control, isSubmitting, errors } = useAnswerKuchikomyuSurveyEnForm()
  const onSubmit = useSubmitKuchikomyuCustomerSurveyEn(restaurantId!, selectedQuestions)
  return (
    <VStack as="form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" spacing={4}>
      <BlocksLogoForCustomerPostScoreForm />
      {selectedQuestions.map((selectedQuestion, index) => (
        <SurveyScoreRating key={selectedQuestion.id} questionContent={selectedQuestion.question_content} index={index} control={control} errors={errors} />
      ))}
      <Box w='100%'>
        <Text as='label' fontSize='sm' >FreeText</Text>
        <Textarea rows={5} {...register('answer_text')} />
      </Box>
      <CustomerPostScoreBtn isSubmitting={isSubmitting} text='NEXT'/>
    </VStack>
  );
});