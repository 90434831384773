import React, { memo } from "react";
import { Center, Text, VStack } from "@chakra-ui/react";
import { BlocksLogoForCustomerPostScoreForm } from "../../atoms/BlocksLogoForCustomerPostScoreForm";
import { OverallScoreRating } from "./OverallScoreRating";
import { CustomerPostScoreBtn } from "../../atoms/button/CustomerPostScoreBtn";


type PostOverallScoreFormProps = {
  onSubmit: any;
  control: any;
  isSubmitting: boolean;
  errors: any;
}
export const PostOverallScoreFormEn: React.FC<PostOverallScoreFormProps> = memo(({onSubmit, control, isSubmitting, errors}) => {
  return (
    <VStack as="form" onSubmit={onSubmit} spacing={4}>
      <BlocksLogoForCustomerPostScoreForm />
      <Text fontWeight='bold' align='center' m='0'>
        Thank you for visiting today! Please tell us your overall evaluation of the our service in 5 stars!
      </Text>
      <Text fontWeight='bold' fontSize='2xl' m='0'>Overall Evaluation</Text>
      <Center>
        <OverallScoreRating control={control} errors={errors} />
      </Center>
      <CustomerPostScoreBtn isSubmitting={isSubmitting} text='NEXT'/>
    </VStack>
  );
});