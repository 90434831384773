import useSWR from "swr";

type LineAccount = {
  id: string;
  customer_sex: string;
  customer_birth_year: string;
  customer_birth_month: string;
  line_id: string;
  display_name: string;
  picture_url?: string;
  visit_count: number;
  registered_date: string;
  last_visit: string;
  is_active: boolean;
  blocked_date: null | string;
  customer: string;
  restaurant: string;
};

type SoCouponSurveyAnswerScoreWithLineAccounts = {
  id: string;
  line_account: LineAccount;
  answer_score: number;
  answered_at: string;
}[];


type SoCouponQuestionWithLineAccountsPerPage = {
  count: number;
  question: string;
  socoupon_survey_answer_score_with_line_accounts: SoCouponSurveyAnswerScoreWithLineAccounts;
};

export const useFetchSoCouponQuestionWithLineAccountsPerPage = (questionId: string, pageSize: number, currentPage: number) => {
  const { data: soCouponQuestionWithLineAccountsPerPage, error, isLoading } = useSWR<SoCouponQuestionWithLineAccountsPerPage>(`socoupon/survey/questions/${questionId}/with-line-accounts/?pageSize=${pageSize}&currentPage=${currentPage}`)
  return { soCouponQuestionWithLineAccountsPerPage, isLoading, error };
};