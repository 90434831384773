import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';
import { useErrorToast } from '../toast/useErrorToast';


export const useRate = (restaurantId?: string, kuitanAgentId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();
  const onSubmit = useCallback(async (data: any) => {
    try {
      data.restaurant = restaurantId;
      data.kuitan_agent = kuitanAgentId;
      await customAuthAxios.post(
        'kuitan/ratings/',
        data
      );
      showToast('評価を送信しました')
      navigate('../../ratings', { relative: 'path', replace: true });
    } catch (error:any) {
      showErrorToast('評価の送信に失敗しました')
    }
  }, [navigate, showToast, restaurantId, kuitanAgentId]);
  return onSubmit;
};