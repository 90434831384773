import { memo } from "react"
import { Box, HStack, Image, Text } from "@chakra-ui/react";
import { useFetchSoCouponsByRestaurantId } from "../../../hooks/socoupon/useFetchSoCouponsByRestaurantId";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { TableLayout } from "../../template/table/TableLayout";
import { formatDate } from "../../../utils/formatDate";
import { PartialCircularLoading } from "../../molecules/loading/PartialCircularLoading";


type SoCouponsFieldProps = {
  restaurantId: string;
  soCouponInUseId: string|null;
}
export const SoCouponsField: React.FC<SoCouponsFieldProps> = memo(({restaurantId, soCouponInUseId}) => {
  const { soCoupons, isLoading } = useFetchSoCouponsByRestaurantId(restaurantId)
  if (isLoading) return <PartialCircularLoading/>
  if (!soCoupons) return null
  const headers = ['', 'クーポン名', '概要', '注意事項', '有効期限'];
  const rows = soCoupons.map((soCoupon) => (
    <TableRowWithNavigationLayout
      key={soCoupon.id}
      path={`${soCoupon.id}`}
      size='sm'
      childrenList={[
        soCouponInUseId !== null && soCouponInUseId === soCoupon.id && <Text color="green.300" m={0}>●</Text>,
        <HStack>
          {soCoupon.img ? (
            <Image src={soCoupon.img} boxSize='38px' />
          ) : (
            <Box bg='#F99E16' boxSize='38px' minW='38px' outline="1px solid rgb(255, 255, 255)" outlineOffset="-3px" />
          )}
          <Text m='0'>{soCoupon.title}</Text>
        </HStack>,
        <Text m='0' overflowWrap="break-word" whiteSpace="normal" noOfLines={1}>{soCoupon.explanation}</Text>,
        <Text m='0' overflowWrap="break-word" whiteSpace="normal" noOfLines={1}>{soCoupon.notes}</Text>,
        <Text m='0' overflowWrap="break-word" whiteSpace="normal" noOfLines={1}>{formatDate(soCoupon.expiration_date)}</Text>,
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />;
});