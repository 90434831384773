import useSWR from "swr";

type LineDev = {
  id: string;
  liff_type: string;
  liff_id: string;
  liff_url: string;
}

export const useFetchLiff = (liffId?: string) => {
  const fetcherKey = liffId ? `external_api/liff-restaurants/${liffId}` : null;
  const { data: liffRestaurants, error, isLoading } = useSWR<LineDev>(fetcherKey)
  return { liffRestaurants, isLoading, error };
};