import { useRecoilState } from 'recoil';
import { sidebarState } from '../store/sidebarState';


const useSidebar = ():{sidebarOpen: boolean, toggleSidebar: () => void} => {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(sidebarState);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return {sidebarOpen, toggleSidebar};
};

export default useSidebar;
