import { useSwrWithQueryParams } from "../swr/useSwrWithQueryParams";


export type BenchmarkRestaurantsTabelogScores = {
  benchmark_restaurant_id: string;
  benchmark_restaurant_name: string;
  registrant_name: string;
  score: number;
  review_count: number;
  save_count: number;
  delta_score: number|string;
  delta_review_count: number|string;
  delta_save_count: number|string;
}[]
type PaginatedBenchmarkRestaurantsTabelogScores = {
count: number;
benchmark_restaurants_tabelog_scores: BenchmarkRestaurantsTabelogScores;
};
export const useFetchPaginatedBenchmarkRestaurantsTabelogScores = (salesmanId?: string, queryParams?: object) => {
  const fetchKey = salesmanId ? `ifrea_analytics/salesmen/${salesmanId}/benchmark-restaurants/scores/paginated/` : null;
  const { data: paginatedBenchmarkRestaurantsTabelogScores, error, isLoading } = useSwrWithQueryParams<PaginatedBenchmarkRestaurantsTabelogScores>(fetchKey, queryParams)
  return { paginatedBenchmarkRestaurantsTabelogScores, isLoading, error };
};