import { Flex } from '@chakra-ui/react';
import React from 'react';
import { IoMdHome } from "react-icons/io";
import { BiSolidFoodMenu } from "react-icons/bi";
import { AiFillMessage } from "react-icons/ai";
import { RiCoupon2Fill } from "react-icons/ri";
import { FooterNav } from './FooterNav';

const navItems = [
  { Icon: IoMdHome, label: "トップ", navigateTo: "/membership/top" },
  { Icon: BiSolidFoodMenu, label: "メニュー", navigateTo: "/membership/menu" },
  { Icon: AiFillMessage, label: "メッセージ", navigateTo: "/membership/messages" },
  { Icon: RiCoupon2Fill, label: "クーポン", navigateTo: "/membership/coupons" },
];

export const MembershipFooter: React.FC = () => {

  return (
    <Flex
      alignItems='center'
      as="footer"
      bgColor='#5371FE'
      bottom='0'
      h='16.3vw'
      justify="space-between"
      p='0 3.72vw'
      position="fixed"
      w='100%'
      zIndex={3}
    >
      {navItems.map((item, index) => (
        <FooterNav key={index} Icon={item.Icon} label={item.label} navigateTo={item.navigateTo} />
      ))}
    </Flex>
  );
};
