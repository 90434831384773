import React from "react";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Textarea } from "@chakra-ui/react";


type TitleTextareaProps = {
  register: any,
  errors: any,
}
export const TitleTextarea: React.FC<TitleTextareaProps> = ({register, errors}) => (
  <FormControl isRequired isInvalid={errors.title}>
    <FormLabel>タイトル</FormLabel>
    <Textarea
      {...register('title')}
      maxLength={40}
      placeholder='LINE友達限定クーポン'
    />
    <FormHelperText>最大40文字</FormHelperText>
    <FormErrorMessage>{errors['title']?.message}</FormErrorMessage>
  </FormControl>
);