import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const schema = yup.object().shape({
  name: yup.string().required('必須項目です'),
  nickname: yup.string().max(12, '12文字以内で入力してください'),
  img: yup.mixed()
});
export const useRegisterStaffForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm({ resolver: yupResolver(schema) });
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};