import React, { memo } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Box } from "@chakra-ui/react";
import { useFetchIsExistsOverallScore } from "../../../../hooks/games/roulette/useFetchIsExistsOverallScore";
import { lineAccountIdState } from "../../../../store/fetchedData/lineAccountIdState";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { useFetchSurveyStatus } from "../../../../hooks/games/roulette/useFetchSurveyStatus";
import { usePostOverallScoreForm } from "../../../../hooks/usePostOverallScoreForm";
import { usePostOverallScore } from "../../../../hooks/games/roulette/usePostOverallScore";
import { useFetchBranchScore } from "../../../../hooks/games/roulette/useFetchBranchScore";
import { PostOverallScoreForm } from "../../../molecules/form/PostOverallScoreForm";
import { NavigateToMembershipCoupons } from "./NavigateToMembershipCoupons";


type PostRouletteOverallScoreProps = {
  restaurantId: string;
}
export const PostRouletteOverallScore: React.FC<PostRouletteOverallScoreProps> = ({restaurantId}) => {
  const lineAccountId = useRecoilValue(lineAccountIdState)
  const { isExistsOverallScore, isLoading: isLoadingOverallScore } = useFetchIsExistsOverallScore(lineAccountId)
  const { surveyStatus, isLoading: isLoadingSurveyStatus } = useFetchSurveyStatus(restaurantId)
  if (isLoadingOverallScore || isLoadingSurveyStatus) return <CircularLoading />
  if (isExistsOverallScore) return <NavigateToMembershipCoupons restaurantId={restaurantId} />;
  if (!surveyStatus) return <Navigate replace to={`/roulette/${restaurantId}?next=member&survey=false`}/>;
  return (
    <Box p={4}>
      <PostScore lineAccountId={lineAccountId!} restaurantId={restaurantId!}/>
    </Box>
  );
};


const PostScore: React.FC<{lineAccountId: string, restaurantId: string}> = memo(({lineAccountId, restaurantId}) => {
  const { branchScore, isLoading } = useFetchBranchScore(restaurantId)
  const { handleSubmit, control, isSubmitting, errors } = usePostOverallScoreForm()
  const onSubmit = usePostOverallScore(lineAccountId, restaurantId, branchScore||0);
  if (isLoading) return <CircularLoading />
  return <PostOverallScoreForm onSubmit={handleSubmit(onSubmit)} control={control} isSubmitting={isSubmitting} errors={errors}/>
});