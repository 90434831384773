import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useErrorToast } from '../toast/useErrorToast';
import { useSuccessToast } from '../toast/useSuccessToast';


export const useEditKuitanAgent = (userId?: string, kuitanAgentId?: string) => {
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();
  const navigate = useNavigate();

  const onSubmit = useCallback(async (data: any) => {
    try {
      await editUser(data.user, userId);
      await editKuitanAgent(data.account, kuitanAgentId);
      showToast('編集が完了しました');
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.username) {
        showErrorToast(error.response.data.username)
      }
    }
  }, [navigate]);
  return onSubmit;
};

const editUser = async (userData: any, userId?: string) => await customAuthAxios.put(`accounts/users/${userId}/`, userData);
const editKuitanAgent = async (kuitanAgentData: any, kuitanAgentId?: string) => await customAuthAxios.put(`accounts/kuitan-agents/${kuitanAgentId}/`, kuitanAgentData);