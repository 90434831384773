import useSWR from "swr";

type LineAccounts = {
  id: string;
  line_id: string;
  display_name: string;
  picture_url: string;
  has_notification_recipient: boolean;
  created_at: string;
}[]
export const useFetchLineAccountsForReviewCheck = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `external_api/restaurants/${restaurantId}/line/accounts/for-review-check/` : null;
  const { data: lineAccounts, error, isLoading } = useSWR<LineAccounts>(fetcherKey)
  return { lineAccounts, isLoading, error };
};