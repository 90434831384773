import { CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { useParams, useSearchParams } from 'react-router-dom';
import { TableLayout } from "../../template/table/TableLayout";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { DarkShadowCard } from '../../molecules/card/DarkShadowCard';
import { CardHeading } from '../../molecules/card/CardHeading';
import { PartialCircularLoading } from '../../molecules/loading/PartialCircularLoading';
import { useFetchPaginatedTeamsAssignedRestaurants } from '../../../hooks/kuitan/useFetchPaginatedTeamsAssignedRestaurants';
import { CiShop } from 'react-icons/ci';
import { TableRowLayout } from '../../template/table/TableRowLayout';
import { CopyableText } from '../../molecules/CopyableText';


export const TeamsAssignedRestaurants = () => {
  const { salesmanId } = useParams();
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const pageSize = 10;
  const queryParams = buildQueryParams(pageSize, page);
  const { paginatedRestaurants, isLoading } = useFetchPaginatedTeamsAssignedRestaurants(queryParams, salesmanId)
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<CiShop />} heading='店舗'/>
      </CardHeader>
      <CardBody py={2}>
        <RestaurantsTable isLoading={isLoading} restaurants={paginatedRestaurants?.restaurants} />
      </CardBody>
      <CardFooter>
        <Pagination totalItems={paginatedRestaurants?.count} pageSize={pageSize}/>
      </CardFooter>
    </DarkShadowCard>
  );
};


const buildQueryParams = (pageSize: number, page: number) => {
  const queryParams = new URLSearchParams();
  queryParams.set('pageSize', pageSize.toString());
  queryParams.set('currentPage', page.toString());
  return queryParams;
};

const RestaurantsTable = ({isLoading, restaurants}: {isLoading: boolean, restaurants?: {id: string, name: string}[]}) => {
  if (isLoading || !restaurants) return <PartialCircularLoading />
  const FRONT_URL = process.env.REACT_APP_FRONT_URL
  const headers = ['店舗名', '評価ページURL'];
  const rows = restaurants.map((restaurant) => (
    <TableRowLayout
      key={restaurant.id}
      size='sm'
      childrenList={[
        restaurant.name,
        <CopyableText text={`${FRONT_URL}login/kuitan-agent/rate?r=${restaurant.id}`} />
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />
}