// src/components/hoc/WithLoading.tsx
import React from "react";
import { PartialCircularLoading } from "../molecules/loading/PartialCircularLoading";


type WithLoadingAndNoDataLayoutProps = {
  data?: any[];
  children: React.ReactNode;
}

export const WithLoadingAndNoDataLayout: React.FC<WithLoadingAndNoDataLayoutProps> = ({ data, children }) => {
  if (!data) return <PartialCircularLoading />
  if (data.length === 0) return <>データが存在しません</>

  return <>{children}</>;
};