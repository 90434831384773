import React, { memo } from "react";
import { Flex, Text, VStack } from "@chakra-ui/react";
import { LatestValidCoupons } from "./LatestValidCoupons";
import { Link } from "react-router-dom";

type CouponSectionProps = {
  restaurantId: string;
  lineId: string;
}

export const CouponSection: React.FC<CouponSectionProps> = memo(({restaurantId, lineId}) => {

  return (
    <VStack px={3} w='100%' spacing={4}>
      <Flex w='100%' fontSize='14px' fontWeight='bold' justifyContent='space-between'>
        <Text m='0'>有効なクーポン</Text>
        <Link to='/membership/coupons'>クーポン一覧へ</Link>
      </Flex>
      <LatestValidCoupons restaurantId={restaurantId} lineId={lineId} />
    </VStack>
    );
});
