import { useParams } from "react-router-dom";
import { useRegisterRouletteSettingForm } from "../../../../hooks/games/roulette/useRegisterRouletteSettingForm";
import { useRegisterRouletteSetting } from "../../../../hooks/games/roulette/useRegisterRouletteSetting";
import { RouletteSettingFormForSalesman } from "../../../organisms/games/roulette/RouletteSettingFormForSalesman";


export const RegisterRouletteSetting = () => {
  const { restaurantId } = useParams();
  const { handleSubmit, register, control, isSubmitting, errors } = useRegisterRouletteSettingForm()
  const onSubmit = useRegisterRouletteSetting(restaurantId)
  return <RouletteSettingFormForSalesman onSubmit={handleSubmit(onSubmit)} register={register} control={control} isSubmitting={isSubmitting} errors={errors} />
};