import { memo } from "react"
import { Box, Divider, Image, Text, VStack } from "@chakra-ui/react";
import { DefaultCoupon } from "./DefaultCoupon";
import { formatExpirationDate } from "../../utils/formatDate";


type CouponLayoutProps = {
  title: string
  img: string
  explanation: string
  expiration_date: string
  notes: string
}
export const CouponLayout: React.FC<CouponLayoutProps> = memo(({title, img, explanation, expiration_date, notes}) => {
  return (
    <VStack spacing={3}>
      <Box textAlign='left' w='100%'>
        <Text fontWeight='bold' m='0'>{title}</Text>
      </Box>
      <Box boxSize={{ base: '70vw', sm: '70%' }}>
        {img ? (
          <Image objectFit="cover" m="auto" src={img} alt=""/>
        ) : (
          <DefaultCoupon title={title} />
        )}
      </Box>
      <Divider m='0' />
      <VStack align='start' fontSize='sm' w='100%'>
        <Text m='0'>{explanation}</Text>
        <Text m='0'>有効期限：{formatExpirationDate(expiration_date)}</Text>
      </VStack>
      <Divider m='0' />
      <Box fontSize='sm' w='100%' >
        <Text color='red.500' m='0'>■注意事項</Text>
        <Text m='0'>{notes}</Text>
      </Box>
    </VStack>
  );
});