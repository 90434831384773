import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { switchQrCardImgAppend } from "../../utils/switchQrCardImgAppend";
import { useSuccessToast } from "../toast/useSuccessToast";

export const useSubmitMenuSettingEdit = (settingsId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();

  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("type", data.type);
      uploadData.append("is_first_survey", data.is_first_survey);
      switchQrCardImgAppend(data.qr_card_img, uploadData);

      await customAuthAxios.put(`menu/settings/${settingsId}/`, uploadData)
      showToast('編集が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  };

  return onSubmit;
};
