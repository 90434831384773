import useSWR from "swr";


type NeededRatingCountForNextRating = {
  needed_rating_count: number;
  next_higher_rating: number;
}
export const useFetchNeededRatingCountForNextRating = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `/external_api/restaurants/${restaurantId}/google/ratings/count/needed-next-rating/` : null;
  const { data: neededRatingCountForNextRating, isLoading } = useSWR<NeededRatingCountForNextRating>(fetcherKey);
  return { neededRatingCountForNextRating, isLoading };
};