import useSWR from "swr";


type Corporations = {
  id: string;
  name: string;
  username: string;
  president_last_name: string;
  president_first_name: string;
  president_phone_number: string;
}[]
type PaginatedCorporations = {
count: number;
corporations: Corporations;
};
export const useFetchPaginatedCorporations = (queryParams: URLSearchParams) => {
  const queryString = queryParams.toString();
  const { data: paginatedCorporations, error, isLoading } = useSWR<PaginatedCorporations>(`accounts/corporations/paginated/?${queryString}`)
  return { paginatedCorporations, isLoading, error };
};