import useSWR from "swr";

type AddOns = {
  id: string;
  name: string;
}[]

export const useFetchAddOns = () => {
  const { data: addOns, error, isLoading } = useSWR<AddOns>('/general_setting/add-ons/')
  return { addOns, isLoading, error };
};