import React from "react"
import { Box, CardBody, CardFooter, CardHeader, Divider, VStack } from "@chakra-ui/react";
import { SettingItem } from "../../organisms/SettingItem";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchSignUpAuthCode } from "../../../hooks/kuitan/useFetchSignUpAuthCode";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { useFetchUser } from "../../../hooks/user/useFetchUser";


export const SignUpAuthCode = () => {
  const {signUpAuthCode, isLoading} = useFetchSignUpAuthCode();
  if (isLoading) return <CircularLoading />;
  const settingItems = [
    {label: '認証コード', content: signUpAuthCode},
  ];
  return (
    <Box m='auto' w={{base: '100%', md: '580px'}}>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='サインアップ認証コード'/>
        </CardHeader>
        <CardBody pt={0}>
          <VStack align='start' fontSize='sm' fontWeight='bold' spacing={4} w='100%'>
            <Divider my={0} />
            {settingItems.map((detail) => (
              <React.Fragment key={detail.label}>
                <Box w='100%'>
                  <SettingItem label={detail.label} content={detail.content}/>
                </Box>
                <Divider my={0} />
              </React.Fragment>
            ))}
          </VStack>
        </CardBody>
        <SignUpAuthCodeCardFooter />
      </DarkShadowCard>
    </Box>
  );
};


const SignUpAuthCodeCardFooter = () => {
  const { user, isLoading } = useFetchUser()
  if (isLoading || !user) return null
  if (!user.is_superuser) return null
  return (
    <CardFooter pt={0}>
      <PageTransitionBtn path='edit' text='変更'/>
    </CardFooter>
  );
};