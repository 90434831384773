import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Switch, Textarea, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { useRegisterStaffSettingForm } from "../../../hooks/restaurantStaff/useRegisterStaffSettingForm";
import { useRegisterStaffSetting } from "../../../hooks/restaurantStaff/useRegisterStaffSetting";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";


export const RegisterStaffSetting = () => {
  const { restaurantId = '' } = useParams();
  const { handleSubmit, register, control,  isSubmitting, errors } = useRegisterStaffSettingForm()
  const onSubmit = useRegisterStaffSetting(restaurantId)
  const options = Array.from({ length: 5 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));
  const staffBranchScoreError = errors.staff_branch_score?.message;
  const restaurantBranchScoreError = errors.restaurant_branch_score?.message;
  const gUrlError = errors.g_url?.message;
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='従業員設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.restaurant_branch_score} w='100%'>
                <FormLabel>店舗ブランチスコア</FormLabel>
                <Controller
                  name='restaurant_branch_score'
                  control={control}
                  render={({ field }) => (
                    <ReactSelectWithChakraStyle
                      placeholder="選択"
                      options={options}
                      value={options.find(option => option.value === field.value)}
                      onChange={(option) => field.onChange(option?.value)}
                    />
                  )}
                />
                <FormErrorMessage>{restaurantBranchScoreError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.staff_branch_score} w='100%'>
                <FormLabel>従業員ブランチスコア</FormLabel>
                <Controller
                  name='staff_branch_score'
                  control={control}
                  render={({ field }) => (
                    <ReactSelectWithChakraStyle
                      placeholder="選択"
                      options={options}
                      value={options.find(option => option.value === field.value)}
                      onChange={(option) => field.onChange(option?.value)}
                    />
                  )}
                />
                <FormErrorMessage>{staffBranchScoreError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.g_url} w='100%'>
                <FormLabel>GoogleUrl</FormLabel>
                <Textarea {...register('g_url')} />
                <FormErrorMessage>{gUrlError}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>GoogleUrlへの遷移</FormLabel>
                <Switch size='md' mb='0' defaultChecked={true} {...register('is_navigate_g_url')}  />
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};