import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from "@chakra-ui/react";


type UserPhoneNumberInputProps = {
  register: any;
  errors: any;
}
export const UserPhoneNumberInput: React.FC<UserPhoneNumberInputProps> = memo(({register, errors}) => {
  const phoneNumberError = errors.user?.phone_number?.message;
  return (
    <FormControl isRequired isInvalid={errors.user?.phone_number} w='100%'>
      <FormLabel>電話番号</FormLabel>
      <Input type="tel" minLength={10} maxLength={11} pattern="[\d]*" {...register('user.phone_number')} />
      <FormHelperText>ハイフンなし</FormHelperText>
      <FormErrorMessage>{phoneNumberError}</FormErrorMessage>
    </FormControl>
  );
});