import React, { memo } from "react";
import { Skeleton } from "@chakra-ui/react";
import { MainScoreCard } from "../../molecules/card/MainScoreCard";
import { useFetchAverageSatisfaction } from "../../../hooks/useFetchAverageSatisfaction";


type AverageSatisfactionCardProps = {
  restaurantId: string;
}
export const AverageSatisfactionCard: React.FC<AverageSatisfactionCardProps> = memo(({restaurantId}) => {
  const { averageSatisfaction, isLoading } = useFetchAverageSatisfaction(restaurantId);
  return (
    <Skeleton isLoaded={!isLoading} w='100%' rounded='2xl'>
      <MainScoreCard title='満足度' data={`${averageSatisfaction}`} />
    </Skeleton>
  );
});