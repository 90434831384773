import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const signUpSchema = yup.object().shape({
  user: yup.object().shape({
    username: yup.string().required('入力必須項目です'),
    password: yup.string().required('入力必須項目です').test('password-length', 'パスワードは4文字以上で入力してください', value => value.length >= 4),
    email: yup.string().email("メールアドレスを正しい形式で入力してください"),
    phone_number: yup.string().required('入力必須項目です'),
    last_name: yup.string().required('入力必須項目です'),
    first_name: yup.string().required('入力必須項目です'),
  }),
  account: yup.object().shape({
    tabelog: yup.string(),
    instagram: yup.string(),
    tiktok: yup.string(),
    x: yup.string(),
  }),
});
export const useSignUpKuitanAgentForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(signUpSchema),
  });
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};