import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";


export const useSubmitLineAccountRegister = (
  restaurantId: string,
  userId: string,
  mutate: any,
  signUpValues: {
    username: string;
    password: string;
    groups: number[];
  },
  lineAccountValues: {
    restaurant_id: string;
    line_id: string;
    display_name: string;
    picture_url?: string;
    visit_count: number;
  }
) => {
  const onSubmit = async (data: any) => {
    const uploadData = {
      user: signUpValues,
      customer: {
        restaurants: [restaurantId],
        sex: data.sex,
        birth_year: data.birthYear,
        birth_month: data.birthMonth,
      },
      line_account: lineAccountValues
    };
    try {
      await customNoAuthAxios.post('authentication/line-account/', uploadData);
      mutate(`external_api/restaurants/${restaurantId}/line-accounts/${userId}/line-account-id/`);
    } catch (error: any) {
      console.error("Error submitting line account", error);
    }
  };

  return onSubmit;
};
