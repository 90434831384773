import React, { memo } from "react";
import Chart from "react-apexcharts";

type LineGraphProps = {
  labels: string[];
  values: number[];
  graphColorRgb?: string;
  height?: string;
};
export const LineGraph: React.FC<LineGraphProps> = memo(({ labels, values, graphColorRgb = '54,121,254', height = '500' }) => {
  const options = {
    chart: {
      id: "basic-area",
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          fontSize: '10px'
        },
        rotate: 0,
        rotateAlways: false
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        style: {
          fontSize: '10px'
        },
      },
    },
    stroke: {
      curve: 'smooth' as 'smooth',
      colors: [`rgb(${graphColorRgb})`],
      width: 2
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
        colorStops: [
          {
            offset: 0,
            color: `rgb(${graphColorRgb})`,
            opacity: 0.4
          },
          {
            offset: 100,
            color: `rgb(${graphColorRgb})`,
            opacity: 0
          },
        ]
      }
    },
    markers: {
      size: 0,
      hover: {
        size: 4
      },
      colors: [`rgb(${graphColorRgb})`]
    },
    tooltip: {
      theme: "dark",
      marker: {
        show: true,
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    }
  };

  const series = [
    {
      name: '',
      data: values,
      color: `rgb(${graphColorRgb},.9)`
    }
  ];

  return <Chart options={options} series={series} type="area" height={height} />;
});