import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";


export const useEditStaffSettingByAdmin = (settingId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      await customAuthAxios.put(`restaurant_staff/settings/${settingId}/`, data)
      showToast('編集が完了しました')
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};