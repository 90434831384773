import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { useEditPasswordForm } from "../../../hooks/useEditPasswordForm";
import { Box } from "@chakra-ui/react";
import { EditPasswordForm } from "../../molecules/form/EditPasswordForm";
import { useSubmitManagerPassEdit } from "../../../hooks/manager/useSubmitManagerPassEdit";


export const ManagerPasswordEdit: React.FC = memo(() => {
  const { managerId } = useParams();
  const { handleSubmit, register, isSubmitting, errors } = useEditPasswordForm();
  const onSubmit = useSubmitManagerPassEdit(managerId!);
  return (
    <Box m='auto' w={{base: '100%', md: '500px'}}>
      <EditPasswordForm handleSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} register={register} errors={errors} />
    </Box>
  );
});