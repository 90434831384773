import { memo, useState } from "react"
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { Grid, HStack, Radio, RadioGroup, Select, VStack } from "@chakra-ui/react";
import { useFetchGurunaviAnalytics } from "../../../hooks/ifreaAnalytics/useFetchGurunaviAnalytics";
import { AnalyticsLineGraphCards } from "./AnalyticsLineGraphCards";


type GurunaviAnalyticsProps = {
  restaurantId: string
}
export const GurunaviAnalytics: React.FC<GurunaviAnalyticsProps> = memo(({restaurantId}) => {
  const { gurunaviAnalytics, isLoading } = useFetchGurunaviAnalytics(restaurantId)
  if (isLoading) return <CircularLoading />
  if (!gurunaviAnalytics) return <>データが存在しません</>
  if (Object.keys(gurunaviAnalytics).length === 0) return <>データが存在しません</>
  return <GurunaviAnalyticsContents gurunaviAnalytics={gurunaviAnalytics} />
});


const GurunaviAnalyticsContents: React.FC<{gurunaviAnalytics: any}> = memo(({gurunaviAnalytics}) => {
  const restaurantMediaNames = Object.keys(gurunaviAnalytics)
  const [ restaurantMediaName, setRestaurantMediaName ] = useState(restaurantMediaNames[0])

  const data = {
    pc: [
      { title: "店舗TOP PV", dataKey: "pc_top_pv" },
      { title: "メニューPV", dataKey: "pc_menu_pv" },
      { title: "席・個室・貸切PV", dataKey: "pc_seat_pv" },
      { title: "写真PV", dataKey: "pc_img_pv" },
      { title: "こだわりPV", dataKey: "pc_picky_pv" },
      { title: "地図PV", dataKey: "pc_map_pv" },
      { title: "クーポンPV", dataKey: "pc_coupon_pv" },
      { title: "予約PV", dataKey: "pc_reservation_pv" },
      { title: "その他PV", dataKey: "pc_others_pv" },
    ],
    sp: [
      { title: "店舗TOP PV", dataKey: "sp_top_pv" },
      { title: "メニューPV", dataKey: "sp_menu_pv" },
      { title: "席・個室・貸切PV", dataKey: "sp_seat_pv" },
      { title: "写真PV", dataKey: "sp_img_pv" },
      { title: "こだわりPV", dataKey: "sp_picky_pv" },
      { title: "地図PV", dataKey: "sp_map_pv" },
      { title: "クーポンPV", dataKey: "sp_coupon_pv" },
      { title: "予約PV", dataKey: "sp_reservation_pv" },
    ],
    app: [
      { title: "店舗TOP PV", dataKey: "app_top_pv" },
      { title: "メニューPV", dataKey: "app_menu_pv" },
      { title: "席・個室・貸切PV", dataKey: "app_seat_pv" },
      { title: "写真PV", dataKey: "app_img_pv" },
      { title: "こだわりPV", dataKey: "app_picky_pv" },
      { title: "地図PV", dataKey: "app_map_pv" },
      { title: "クーポンPV", dataKey: "app_coupon_pv" },
      { title: "予約PV", dataKey: "app_reservation_pv" },
    ],
    call: [
      { title: "コール数", dataKey: "call" },
      { title: "通話数", dataKey: "on_the_phone" },
      { title: "話中数", dataKey: "busy" },
      { title: "切電数", dataKey: "disconnect" },
    ],
    reservation: [
      { title: "コース予約人数", dataKey: "course_reservation_num_people" },
      { title: "席のみ予約人数", dataKey: "seat_reservation_num_people" },
      { title: "問い合わせ予約人数", dataKey: "request_reservation_num_people" },
      { title: "コース予約組数", dataKey: "course_reservation_num_groups" },
      { title: "席のみ予約組数", dataKey: "seat_reservation_num_groups" },
      { title: "問い合わせ予約組数", dataKey: "request_reservation_num_groups" },
    ],
  };
  type DataType = keyof typeof data;
  const [ dataType, setDataType ] = useState<DataType>('pc')
  return (
    <VStack spacing={5}>
      <VStack align='start' w='100%'>
        <Select bg="white" size='sm' rounded='md' w='200px' defaultValue={restaurantMediaName} onChange={(e) => setRestaurantMediaName(e.target.value)}>
          {restaurantMediaNames.map(name => <option key={name} value={name}>{name}</option>)}
        </Select>
        <RadioGroup onChange={(value:DataType) => setDataType(value)} value={dataType}>
          <Grid templateColumns={{base: 'repeat(3, 1fr)', sm: 'repeat(5, 1fr)'}} gap={2}>
            <Radio colorScheme='yellow' size='sm' m='0' value='pc'>PC</Radio>
            <Radio colorScheme='yellow' size='sm' m='0' value='sp'>スマホ</Radio>
            <Radio colorScheme='yellow' size='sm' m='0' value='app'>アプリ</Radio>
            <Radio colorScheme='yellow' size='sm' m='0' value='call'>通話</Radio>
            <Radio colorScheme='yellow' size='sm' m='0' value='reservation'>予約</Radio>
          </Grid>
        </RadioGroup>
      </VStack>
      <AnalyticsLineGraphCards
        data={data[dataType]}
        analyticsDate={Object.keys(gurunaviAnalytics[restaurantMediaName])}
        analyticsContents={Object.values(gurunaviAnalytics[restaurantMediaName])}
        restaurantMediaName={restaurantMediaName}
        graphColorRgb='214, 157, 46'
      />
    </VStack>
  )
})