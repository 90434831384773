import { useState } from "react";
import { useRecoilValue } from "recoil";
import { customNoAuthAxios } from "../../../utils/customNoAuthAxios";
import { customerRestaurantId } from "../../../store/customerRestaurantId";
import { useNavigate } from "react-router-dom";
import { useSuccessToast } from "../../toast/useSuccessToast";


export const useChangeRouletteCouponState = (couponId: string, lineId: string, onClose: () => void) => {
  const restaurantId = useRecoilValue(customerRestaurantId)
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const markCouponAsUsed = async () => {
    const data = {lineId: lineId, restaurantId: restaurantId}
    const res = await customNoAuthAxios.post(
      `games/roulette/coupons/${couponId}/mark-as-used/`,
      data,
    );
    return res;
  }

  const handleOnClick = async () => {
    setIsLoading(true)
    try {
      const res = await markCouponAsUsed();
      onClose()
      showToast(res.data.message);
      navigate(-1);
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  return {isLoading, handleOnClick }
}