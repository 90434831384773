import React, { memo } from "react";
import { Box, FormControl, FormErrorMessage, FormLabel, HStack, Input } from "@chakra-ui/react";

type ManagerNameInputFieldProps = {
  register: any;
  errors: any;
}
export const ManagerNameInputField: React.FC<ManagerNameInputFieldProps> = memo(({register, errors}) => {
  const managerLastNameError = errors.restaurant?.manager_last_name?.message;
  const managerFirstNameError = errors.restaurant?.manager_first_name?.message;
  return (
    <Box w='100%'>
      <HStack>
        <FormControl isRequired isInvalid={errors.restaurant?.manager_last_name} w='100%'>
          <FormLabel>代表者 性</FormLabel>
          <Input type="text" {...register('restaurant.manager_last_name')} />
          <FormErrorMessage>{managerLastNameError}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={errors.restaurant?.manager_first_name} w='100%'>
          <FormLabel>代表者 名</FormLabel>
          <Input type="text" {...register('restaurant.manager_first_name')} />
          <FormErrorMessage>{managerFirstNameError}</FormErrorMessage>
        </FormControl>
      </HStack>
    </Box>
  );
});