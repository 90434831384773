import { Route } from 'react-router-dom';
import { KuchikomyuSettingForRestaurant } from '../components/pages/kuchikomyu/KuchikomyuSettingForRestaurant';
import { EditKuchikomyuSettingByRestaurant } from '../components/pages/kuchikomyu/EditKuchikomyuSettingByRestaurant';
import { KuchikomyuSurveyQuestions } from '../components/pages/kuchikomyu/KuchikomyuSurveyQuestions';
import { KuchikomyuSurveyQuestionRegister } from '../components/pages/kuchikomyu/KuchikomyuSurveyQuestionRegister';
import { KuchikomyuSurveySelectedQuestionsEdit } from '../components/pages/kuchikomyu/KuchikomyuSurveySelectedQuestionsEdit';
import { KuchikomyuSurveySelectedQuestions } from '../components/pages/kuchikomyu/KuchikomyuSurveySelectedQuestions';
import { KuchikomyuQrCode } from '../components/pages/kuchikomyu/KuchikomyuQrCode';
import { KuchikomyuQrCodeEn } from '../components/pages/kuchikomyu/KuchikomyuQrCodeEn';
import { KuchikomyuScores } from '../components/pages/kuchikomyu/KuchikomyuScores';
import { KuchikomyuSurveyAnswerTexts } from '../components/pages/kuchikomyu/KuchikomyuSurveyAnswerTexts';


export const KuchikomyuRoutes = (
  <Route path='kuchikomyu'>
    <Route path="setting" element={<KuchikomyuSettingForRestaurant />} />
    <Route path="setting/edit" element={<EditKuchikomyuSettingByRestaurant />} />
    <Route path="survey/questions" element={<KuchikomyuSurveyQuestions />} />
    <Route path="survey/questions/register" element={<KuchikomyuSurveyQuestionRegister />} />
    <Route path="survey/questions/selected/edit" element={<KuchikomyuSurveySelectedQuestionsEdit />} />
    <Route path="survey/questions/selected" element={<KuchikomyuSurveySelectedQuestions />} />
    <Route path="qr-code" element={<KuchikomyuQrCode />} />
    <Route path="qr-code/en" element={<KuchikomyuQrCodeEn />} />
    <Route path="scores" element={<KuchikomyuScores />} />
    <Route path="answer-texts" element={<KuchikomyuSurveyAnswerTexts />} />
  </Route>
);