import { Box, FormControl, FormLabel, Textarea, VStack } from "@chakra-ui/react";
import { memo } from "react"
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { lineAccountIdState } from "../../../store/fetchedData/lineAccountIdState";
import { BlocksLogoForCustomerPostScoreForm } from "../../atoms/BlocksLogoForCustomerPostScoreForm";
import { SurveyScoreRating } from "../../molecules/form/SurveyScoreRating";
import { CustomerPostScoreBtn } from "../../atoms/button/CustomerPostScoreBtn";
import { useRecoilValue } from "recoil";
import { SelectedQuestions, useFetchStaffSurveySelectedQuestionsCustomer } from "../../../hooks/restaurantStaff/useFetchStaffSurveySelectedQuestionsCustomer";
import { useAnswerStaffSurveyForm } from "../../../hooks/restaurantStaff/useAnswerStaffSurveyForm";
import { useAnswerStaffSurvey } from "../../../hooks/restaurantStaff/useAnswerStaffSurvey";
import { StaffSetting, useFetchStaffSettingCustomerByRestaurantId } from "../../../hooks/restaurantStaff/useFetchStaffSettingCustomerByRestaurantId";


export const AnswerStaffSurvey = memo(({restaurantId}:{restaurantId:string}) => {
  const { selectedQuestions, isLoading } = useFetchStaffSurveySelectedQuestionsCustomer(restaurantId);
  const { staffSetting, isLoadingStaffSetting } = useFetchStaffSettingCustomerByRestaurantId(restaurantId);
  if (isLoading || !selectedQuestions || isLoadingStaffSetting || !staffSetting) return <CircularLoading />
  return (
    <Box p={4}>
      <AnswerForm selectedQuestions={selectedQuestions} staffSetting={staffSetting} />
    </Box>
  );
});


const AnswerForm = ({selectedQuestions, staffSetting}:{selectedQuestions:SelectedQuestions, staffSetting:StaffSetting}) => {
  const lineAccountId = useRecoilValue(lineAccountIdState)
  const { handleSubmit, register, errors, control, isSubmitting } = useAnswerStaffSurveyForm()
  const onSubmit = useAnswerStaffSurvey(selectedQuestions, lineAccountId!, staffSetting)
  return (
    <VStack as="form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" spacing={6}>
      <BlocksLogoForCustomerPostScoreForm />
      {selectedQuestions.map((q, i) => (
        <SurveyScoreRating key={q.id} questionContent={q.question_content} index={i} control={control} errors={errors} />
      ))}
      <FormControl>
        <FormLabel>自由解答欄</FormLabel>
        <Textarea rows={5} {...register('answer_text')} />
      </FormControl>
      <CustomerPostScoreBtn isSubmitting={isSubmitting} text='回答する'/>
    </VStack>
  );
}