import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { useFetchNotice } from "../../../hooks/notice/useFetchNotice";
import { useEditNoticeForm } from "../../../hooks/notice/useEditNoticeForm";
import { useEditNotice } from "../../../hooks/notice/useEditNotice";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { IoIosNotifications } from "react-icons/io";
import { NoticeFormContents } from "../../organisms/notice/NoticeFormContents";


export const EditNotice = () => {
  const { noticeId } = useParams();
  const { notice, isLoading } = useFetchNotice(noticeId)
  const [title, setTitle] = useState(notice?.title || '');
  const { handleSubmit, register, isSubmitting, errors } = useEditNoticeForm(notice);
  const onSubmit = useEditNotice(noticeId!);
  if (isLoading) return <CircularLoading />;
  return (
    <Box m='auto' w='650px' maxW='100%'>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<IoIosNotifications />} heading='通知'/>
          </CardHeader>
          <CardBody py={2}>
            <NoticeFormContents register={register} errors={errors} title={title} setTitle={setTitle} />
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>編集</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};