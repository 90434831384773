import useSWR from "swr";

type SoCouponSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  socoupon_in_use: string;
  socoupon_title: string;
  qr_card_img?: string;
  remind: boolean;
}

export const useFetchSoCouponSetting = (settingsId?: string) => {
  const fetcherKey = settingsId ? `socoupon/settings/${settingsId}/` : null;
  const { data: soCouponSetting, error, isLoading } = useSWR<SoCouponSetting>(fetcherKey)
  return { soCouponSetting, isLoading, error };
};