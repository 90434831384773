import useSWR from "swr";


type FoodRating = {
  [key: string]: number;
}
export const useFetchFoodRating = (restaurantId?: string,) => {
  const fetcherKey = restaurantId ? `kuitan/restaurants/${restaurantId}/ratings/food/` : null
  const { data: foodRating, error, isLoading } = useSWR<FoodRating>(fetcherKey)
  return { foodRating, isLoading, error };
};