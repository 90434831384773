import React, { memo } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  List,
  ListItem,
} from '@chakra-ui/react'
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";
import { SidebarItemLink } from "../../molecules/SidebarItemLink";
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";


export const CorporationSidebarMenu: React.FC = memo(() => {
  return (
    <Accordion allowMultiple>
      <List spacing={1}>
        <ListItem >
          <SidebarItemLink to='' text="基本情報" />
        </ListItem>
        <ListItem>
          <RestaurantAccordionMenu />
        </ListItem>
        <ListItem >
          <ManagerAccordionMenu />
        </ListItem>
      </List>
    </Accordion>
  );
});


const RestaurantAccordionMenu : React.FC = memo(() => {
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          店舗
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItemLink to='restaurants' text="一覧" />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
});

const ManagerAccordionMenu : React.FC = memo(() => {
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          マネジャー
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          <AccordionItemLink to='managers' text="一覧" />
          <AccordionItemLink to='managers/register' text="登録" />
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
});