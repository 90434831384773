import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const registerSoCouponSettingSchema = yup.object().shape({
  branch_score: yup.number().required('ブランチスコアを入力してください'),
  g_url: yup.string().required('Urlを入力してください'),
  remind: yup.boolean(),
});
export const useRegisterSoCouponSettingForm = () => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(registerSoCouponSettingSchema),});
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};