import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../../utils/customAuthAxios";
import { useSuccessToast } from "../../toast/useSuccessToast";


export const useEditGoogleSetting = (settingsId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      data.notification_recipients = data.notification_recipients ? [data.notification_recipients] : []
      await customAuthAxios.put(`external_api/google-settings/${settingsId}/`, data)
      showToast('登録が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};