import { memo } from "react"
import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import { useKuchikomyuSurveyQuestionRegisterForm } from "../../../hooks/kuchikomyu/useKuchikomyuSurveyQuestionRegisterForm";
import { useSubmitKuchikomyuSurveyQuestionRegister } from "../../../hooks/kuchikomyu/useSubmitKuchikomyuSurveyQuestionRegister";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { RiSurveyLine } from "react-icons/ri";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";


export const KuchikomyuSurveyQuestionRegister: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { handleSubmit, register, errors, isSubmitting } = useKuchikomyuSurveyQuestionRegisterForm()
  const onSubmit = useSubmitKuchikomyuSurveyQuestionRegister(restaurantId)
  return (
    <Box m='auto' w={{ base: '100%', md: '60%' }}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<RiSurveyLine />} heading='アンケート登録'/>
          </CardHeader>
          <CardBody py={2}>
            <FormControl isRequired isInvalid={!!errors.question}>
              <FormLabel>アンケート質問</FormLabel>
              <Input type="input" {...register('question')} />
              <FormErrorMessage>{errors.question?.message}</FormErrorMessage>
            </FormControl>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});