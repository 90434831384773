import {Route} from 'react-router-dom';
import {RestaurantBaseInfoEdit} from '../components/pages/restaurants/RestaurantBaseInfoEdit';
import {DashboardWrapper} from '../components/template/DashboardWrapper';
import {UsedCouponUsers} from '../components/pages/UsedCouponUsers';
import {VisitQrCode} from '../components/pages/externalApi/line/VisitQrCode';
import {Notices} from '../components/pages/notice/Notices';
import {Notice} from '../components/pages/notice/Notice';
import RestaurantBase from '../components/template/base/RestaurantBase';
import {RestaurantBaseInfo} from '../components/pages/restaurants/RestaurantBaseInfo';
import {AddOnsRoutes} from './AddOnsRoutes';
import InstagramPosts from '../components/pages/instagram/InstagramPosts';
import InstagramInsights from '../components/pages/instagram/InstagramInsights';
import InstagramApiReturn from '../components/pages/instagram/InstagramApiReturn';
import Instagram from '../components/pages/instagram/Instagram';
import InstagramSetting from "../components/pages/instagram/InstagramSetting";

export const RestaurantRoutes = (
    <Route path="restaurants/:restaurantId" element={<RestaurantBase />}>
            <Route path="" element={<RestaurantBaseInfo />} />
            <Route path="edit" element={<RestaurantBaseInfoEdit />} />
            <Route path="notices" element={<Notices />} />
            <Route path="notices/:noticeId" element={<Notice />} />
            <Route path="dashboard" element={<DashboardWrapper />} />
            <Route path="used-coupon-users" element={<UsedCouponUsers />} />
            <Route path="visit/qr-code" element={<VisitQrCode />} />
            {/* Instagram関連のルートを追加 */}
            <Route path="instagram/view" element={<Instagram />} />
            <Route path="instagram/settings" element={<InstagramSetting />} />
            <Route path="instagram/posts" element={<InstagramPosts />} />
            <Route path="instagram/insights" element={<InstagramInsights />} />
            <Route path="instagram-api-return" element={<InstagramApiReturn />} /> {/* リダイレクト後の戻り先 */}

            {AddOnsRoutes}
    </Route>
);