import { Route } from 'react-router-dom';
import { StaffList } from '../components/pages/restaurantStaff/StaffList';
import { RegisterStaff } from '../components/pages/restaurantStaff/RegisterStaff';
import { Staff } from '../components/pages/restaurantStaff/Staff';
import { EditStaff } from '../components/pages/restaurantStaff/EditStaff';
import { RestaurantStaffDashboard } from '../components/pages/restaurantStaff/StaffDashboard';
import { StaffSurveyQuestions } from '../components/pages/restaurantStaff/StaffSurveyQuestions';
import { RegisterStaffSurveyQuestion } from '../components/pages/restaurantStaff/RegisterStaffSurveyQuestion';
import { StaffSurveySelectedQuestions } from '../components/pages/restaurantStaff/StaffSurveySelectedQuestions';
import { EditStaffSurveySelectedQuestions } from '../components/pages/restaurantStaff/EditStaffSurveySelectedQuestions';
import { StaffSurveyQuestionAnswers } from '../components/pages/restaurantStaff/StaffSurveyQuestionAnswers';
import { StaffRanking } from '../components/pages/restaurantStaff/StaffRanking';
import { RestaurantSurveyQuestionAnswers } from '../components/pages/restaurantStaff/RestaurantSurveyQuestionAnswers';
import { StaffSettingForRestaurant } from '../components/pages/restaurantStaff/StaffSettingForRestaurant';
import { RegisterStaffSetting } from '../components/pages/restaurantStaff/RegisterStaffSetting';
import { EditStaffSetting } from '../components/pages/restaurantStaff/EditStaffSetting';
import { RestaurantSurveyQuestions } from '../components/pages/restaurantStaff/RestaurantSurveyQuestions';
import { RestaurantSurveySelectedQuestions } from '../components/pages/restaurantStaff/RestaurantSurveySelectedQuestions';
import { RegisterRestaurantSurveyQuestion } from '../components/pages/restaurantStaff/RegisterRestaurantSurveyQuestion';
import { EditRestaurantSurveySelectedQuestions } from '../components/pages/restaurantStaff/EditRestaurantSurveySelectedQuestions';


const routes = [
  { path: '', element: <StaffList /> },
  { path: 'register', element: <RegisterStaff /> },
  { path: ':staffId', element: <Staff /> },
  { path: ':staffId/edit', element: <EditStaff /> },
  { path: ':staffId/questions/:questionId', element: <StaffSurveyQuestionAnswers /> },
  { path: ':staffId/restaurant-survey/questions/:questionId', element: <RestaurantSurveyQuestionAnswers /> },
  { path: 'dashboard', element: <RestaurantStaffDashboard /> },
  { path: 'ranking', element: <StaffRanking /> },
  { path: 'survey/questions', element: <StaffSurveyQuestions /> },
  { path: 'survey/questions/register', element: <RegisterStaffSurveyQuestion /> },
  { path: 'survey/questions/selected', element: <StaffSurveySelectedQuestions /> },
  { path: 'survey/questions/selected/edit', element: <EditStaffSurveySelectedQuestions /> },

  { path: 'restaurant/survey/questions', element: <RestaurantSurveyQuestions /> },
  { path: 'restaurant/survey/questions/register', element: <RegisterRestaurantSurveyQuestion /> },
  { path: 'restaurant/survey/questions/selected', element: <RestaurantSurveySelectedQuestions /> },
  { path: 'restaurant/survey/questions/selected/edit', element: <EditRestaurantSurveySelectedQuestions /> },

  { path: 'setting', element: <StaffSettingForRestaurant /> },
  { path: 'setting/register', element: <RegisterStaffSetting /> },
  { path: 'setting/:settingId/edit', element: <EditStaffSetting /> },
];
export const RestaurantStaffRoutes = (
  <Route path='restaurant-staff'>
    {routes.map((route) => <Route key={route.path} path={route.path} element={route.element} />)}
  </Route>
);