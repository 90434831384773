import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useExtractRestaurantIdFromUrl } from "../../../hooks/externalApi/line/useExtractRestaurantIdFromUrl";
import { LiffLoginProcess } from "../../organisms/LiffLoginProcess";
import { AnswerStaffSurvey } from "./AnswerStaffSurvey";


export const StaffSurveyLiffApp = () => {
  const restaurantId = useExtractRestaurantIdFromUrl()
  if (restaurantId === null) return <CircularLoading />
  return (
    <LiffLoginProcess restaurantId={restaurantId} liffType="staff-survey">
      <AnswerStaffSurvey restaurantId={restaurantId} />
    </LiffLoginProcess>
  );
};