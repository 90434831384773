import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Input, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { SubmitBtn } from "../../../atoms/button/SubmitBtn";
import { useRegisterLiffForm } from "../../../../hooks/externalApi/line/useRegisterLiffForm";
import { useRegisterLiff } from "../../../../hooks/externalApi/line/useRegisterLiff";
import { LiffTypeSelect } from "../../../organisms/externalApi/line/LiffTypeSelect";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const RegisterLiff = () => {
  const { restaurantId } = useParams();
  const { handleSubmit, register, control, isSubmitting, errors } = useRegisterLiffForm()
  const onSubmit = useRegisterLiff(restaurantId)
  const liffIdError = errors.liff_id?.message;
  const liffUrlError = errors.liff_url?.message;
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='Liff設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <LiffTypeSelect control={control} errors={errors} />
              <FormControl isRequired isInvalid={!!errors.liff_id} w='100%'>
                <FormLabel>LIFF ID</FormLabel>
                <Input {...register('liff_id')} />
                <FormErrorMessage>{liffIdError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.liff_url} w='100%'>
                <FormLabel>LIFF URL</FormLabel>
                <Input {...register('liff_url')} />
                <FormErrorMessage>{liffUrlError}</FormErrorMessage>
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};