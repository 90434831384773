
const getRestaurantIdFromLiffState = (liffState: string) => {
  const decodedState = decodeURIComponent(liffState);
  const parts = decodedState.split('/');
  return parts.length > 1 ? parts[1].split('?')[0] : '';
};

const getRestaurantIdAndTableIdFromLiffState = (liffState: string) => {
  const decodedState = decodeURIComponent(liffState);
  const pathParts = decodedState.split('/');
  return { restaurantId: pathParts[1], tableId: pathParts[2] }
};

const getRestaurantIdFromPathParts = (url: URL) => {
  const pathParts = url.pathname.split("/").filter(part => part !== "");
  return pathParts[2];
};

const getRestaurantIdAndTableIdFromPathParts = (url: URL) => {
  const pathParts = url.pathname.split("/").filter(part => part !== "");
  return { restaurantId: pathParts[2], tableId: pathParts[3] }
};


export const extractRestaurantIdFromUrl = async () => {
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const liffState = url.searchParams.get('liff.state');
  const restaurantId = liffState
    ? getRestaurantIdFromLiffState(liffState)
    : getRestaurantIdFromPathParts(url)
  return restaurantId;
};

export const extractRestaurantIdAndTableIdFromUrl = async () => {
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const liffState = url.searchParams.get('liff.state');

  const {restaurantId, tableId} = liffState
    ? getRestaurantIdAndTableIdFromLiffState(liffState)
    : getRestaurantIdAndTableIdFromPathParts(url)

  return { restaurantId, tableId };
};