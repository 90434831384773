import { Button, Center, HStack, List, ListItem, Skeleton, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { BusinessTitleByRestaurantId } from "../../../organisms/externalApi/google/BusinessTitle";
import { useQueryParamsOfDateRange } from "../../../../hooks/useQueryParamsOfDateRange";
import { SearchKeywordCount, useFetchSearchKeywords } from "../../../../hooks/externalApi/google/useFetchSearchKeywords";
import { MonthRangeFilter } from "../../../molecules/MonthRangeFilter";
import { Skeletons } from "../../../atoms/Skeletons";


export const SearchKeywords = () => {
  const { restaurantId } = useParams()
  const { since, until, requestQueryParams } = useQueryParamsOfDateRange();
  const currentDate = new Date();
  const previousDate = new Date(currentDate);
  previousDate.setMonth(currentDate.getMonth() - 1);
  const previousMonth = `${previousDate.getFullYear()}-${String(previousDate.getMonth() + 1).padStart(2, '0')}`;
  return (
    <VStack align='start' spacing={5} w='100%'>
      <BusinessTitleByRestaurantId restaurantId={restaurantId} />
      <MonthRangeFilter since={since || previousMonth} until={until || previousMonth} />
      <Contents restaurantId={restaurantId} requestQueryParams={requestQueryParams} />
    </VStack>
  );
};


const Contents = ({restaurantId, requestQueryParams}: {restaurantId?: string, requestQueryParams: string}) => {
  const { data, isLoading, size, setSize, isValidating } = useFetchSearchKeywords(restaurantId, requestQueryParams)
  if (isLoading || !data) return <LoadingSkeletons/>
  const combinedSearchKeywordsCounts = data.reduce((acc: SearchKeywordCount[], curr) => {
    return acc.concat(curr.searchKeywordsCounts);
  }, []);
  return (
    <VStack align='start' w='100%' spacing={3}>
      <Text m={0} color='gray.500' fontSize='sm'>{combinedSearchKeywordsCounts.length}件</Text>
      <List spacing={3} w='100%'>
        {combinedSearchKeywordsCounts.map((searchKeywordCount, index) => {
          return (
            <ListItem key={index} rounded="lg" bg='white' boxShadow="sm" p={4}>
              <HStack spacing={4} fontWeight="bold" fontSize='sm'>
                <Text m={0} minW='36px' color="teal.500">{index + 1}.</Text>
                <Text m={0} color="gray.700" wordBreak="break-all" flex={1}>{searchKeywordCount.searchKeyword}</Text>
                <Text m={0} color="gray.500">{searchKeywordCount.insightsValue.value ? Number(searchKeywordCount.insightsValue.value).toLocaleString() : `< ${searchKeywordCount.insightsValue.threshold}`}</Text>
              </HStack>
            </ListItem>
          )
        })}
      </List>
      <Center w='100%'>
        {data[data.length - 1].nextPageToken && <Button colorScheme="messenger" size='sm' variant='outline' isLoading={isValidating} onClick={() => setSize(size + 1)}>もっと見る</Button>}
      </Center>
    </VStack>
  )
}

const LoadingSkeletons = () => {
  return (
    <VStack align='start' w='100%' spacing={3} h='100%'>
      <Skeleton w='50px' h='20px' />
      <VStack spacing={3} w='100%' h='100%'>
        <Skeletons length={8} h='53px' w='100%' rounded="lg" />
      </VStack>
    </VStack>
  )
}