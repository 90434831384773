import useSWR from "swr";


export type SurveyResponsesTrend = {
  date: string;
  count: number;
}[]
export const useFetchStaffSurveyResponsesTrend = (restaurantId?: string, queryParams?: URLSearchParams) => {
  const fetchKey = restaurantId ? `restaurant_staff/restaurants/${restaurantId}/survey/responses/trend/${queryParams?.toString() && `?${queryParams}`}` : null;
  const { data: surveyResponsesTrend, error, isLoading } = useSWR<SurveyResponsesTrend>(fetchKey)
  return { surveyResponsesTrend, isLoading, error };
};