import { memo } from "react"
import { Box, Card, CardBody, HStack, IconButton, Tag, VStack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { EditIcon } from "@chakra-ui/icons";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { CouponLayout } from "../../../molecules/CouponLayout";
import { RouletteCouponDeleteModal } from "../../../organisms/games/roulette/RouletteCouponDeleteModal";
import { useFetchRouletteCouponSegment } from "../../../../hooks/games/roulette/useFetchRouletteCouponSegment";


export const RouletteSegment = () => {
  const { segmentId } = useParams();
  const { couponSegment, isLoading } = useFetchRouletteCouponSegment(segmentId)
  if (isLoading || !couponSegment) return <CircularLoading />
  return (
    <VStack align='start' m='auto' w={{ base: '100%', sm: '400px' }}>
      <Tag size='sm' colorScheme='messenger'>{couponSegment.segment_count}セグメント</Tag>
      <Box w='100%'>
        <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.08) 1px 3.5px 5.5px'>
          <CardBody>
            <CouponLayout {...couponSegment.coupon} />
          </CardBody>
        </Card>
      </Box>
      <EditAndDeleteIcons id={couponSegment.coupon.id} title={couponSegment.coupon.title} />
    </VStack>
  );
};

const EditAndDeleteIcons: React.FC<{ id: string, title: string }> = memo(({ id, title }) => {
  const navigate = useNavigate()
  return (
    <HStack justify='center' spacing={1} w='100%'>
      <IconButton
        colorScheme='messenger'
        aria-label='Edit SoCoupon'
        icon={<EditIcon />}
        size='sm'
        variant='ghost'
        onClick={() => navigate('edit')}
      />
      <RouletteCouponDeleteModal id={id} title={title} />
    </HStack>
  )
})