import { Box, CardBody, CardFooter, CardHeader, Checkbox, FormControl, FormErrorMessage, FormHelperText, FormLabel, Skeleton, Switch, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { memo } from "react";
import { useEditStaffSettingForm } from "../../../hooks/restaurantStaff/useEditStaffSettingForm";
import { useEditStaffSetting } from "../../../hooks/restaurantStaff/useEditStaffSetting";
import { SelectBranchScore } from "../../molecules/form/SelectBranchScore";
import { useFetchRestaurantSurveySelectedQuestions } from "../../../hooks/restaurantStaff/useFetchRestaurantSurveySelectedQuestions";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";
import { useFetchStaffSettingByRestaurantId } from "../../../hooks/restaurantStaff/useFetchStaffSettingByRestaurantId";
import { useFetchStaffSurveySelectedQuestions } from "../../../hooks/restaurantStaff/useFetchStaffSurveySelectedQuestions";


export const EditStaffSetting = () => {
  const { restaurantId } = useParams();
  const { staffSetting, isLoading } = useFetchStaffSettingByRestaurantId(restaurantId);
  const { handleSubmit, register, control, isSubmitting, errors, setValue } = useEditStaffSettingForm(staffSetting)
  const onSubmit = useEditStaffSetting(staffSetting?.id)
  if (!restaurantId) return null
  return (
    <Box m='auto' w={{base: '100%', md: '600px'}}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<SettingsIcon />} heading='従業員設定'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              <SelectBranchScore control={control} errors={errors} isLoading={isLoading} name='restaurant_branch_score' label='店舗ブランチスコア' />
              <SelectRestaurantBranchQuestion restaurantId={restaurantId} control={control} errors={errors} />
              <SelectBranchScore control={control} errors={errors} isLoading={isLoading} name='staff_branch_score' label='従業員ブランチスコア' />
              <SelectStaffBranchQuestion restaurantId={restaurantId} control={control} errors={errors} />
              <FormControl>
                <FormLabel>レビューへの遷移</FormLabel>
                <Controller
                  name='is_navigate_g_url'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      size='md'
                      mb='0'
                      isChecked={value}
                      onChange={onChange}
                      colorScheme="messenger"
                    />
                  )}
                />
              </FormControl>
              <ImgInput register={register} setValue={setValue} />
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};


const SelectRestaurantBranchQuestion : React.FC<{restaurantId: string, control: any, errors: any}> = memo(({restaurantId, control, errors}) => {
  const { selectedQuestions, isLoading } = useFetchRestaurantSurveySelectedQuestions(restaurantId);
  const selectedQuestionsOption = selectedQuestions?.map(selectedQuestion => ({
    label: selectedQuestion.question_content,
    value: selectedQuestion.question
  }));
  const branchQuestionError = errors.restaurant_branch_question?.message;
  return (
    <FormControl isInvalid={!!errors.restaurant_branch_question} isRequired w='100%'>
      <FormLabel>店舗ブランチクエッション</FormLabel>
      <Skeleton isLoaded={!isLoading } w='100%' borderRadius={5}>
        <Controller
          name='restaurant_branch_question'
          control={control}
          render={({ field }) => (
            <ReactSelectWithChakraStyle
              noOptionsMessage={({inputValue}) => !inputValue && '質問が選択されていません'}
              placeholder="選択"
              options={selectedQuestionsOption}
              value={selectedQuestionsOption?.find((x) => x.value === field.value)}
              onChange={(newValue:any) => {
                field.onChange(newValue?.value);
              }}
            />
          )}
        />
      </Skeleton>
      <FormErrorMessage>{branchQuestionError}</FormErrorMessage>
    </FormControl>
  );
})


const SelectStaffBranchQuestion : React.FC<{restaurantId: string, control: any, errors: any}> = memo(({restaurantId, control, errors}) => {
  const { selectedQuestions, isLoading } = useFetchStaffSurveySelectedQuestions(restaurantId);
  const selectedQuestionsOption = selectedQuestions?.map(selectedQuestion => ({
    label: selectedQuestion.question_content,
    value: selectedQuestion.question
  }));
  const branchQuestionError = errors.staff_branch_question?.message;
  return (
    <FormControl isInvalid={!!errors.staff_branch_question} isRequired w='100%'>
      <FormLabel>従業員ブランチクエッション</FormLabel>
      <Skeleton isLoaded={!isLoading } w='100%' borderRadius={5}>
        <Controller
          name='staff_branch_question'
          control={control}
          render={({ field }) => (
            <ReactSelectWithChakraStyle
              noOptionsMessage={({inputValue}) => !inputValue && '質問が選択されていません'}
              placeholder="選択"
              options={selectedQuestionsOption}
              value={selectedQuestionsOption?.find((x) => x.value === field.value)}
              onChange={(newValue:any) => {
                field.onChange(newValue?.value);
              }}
            />
          )}
        />
      </Skeleton>
      <FormErrorMessage>{branchQuestionError}</FormErrorMessage>
    </FormControl>
  );
})


type ImgInputProps = {
  register: any;
  setValue: any;
}
const ImgInput = memo(({register, setValue}: ImgInputProps) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    isChecked && setValue('name_tag_img', '')
  }
  return (
    <FormControl>
      <FormLabel>ネームタグ画像</FormLabel>
      <VStack align='start' spacing={1}>
        <input type="file" accept="image/*" {...register('name_tag_img')} />
        <Checkbox onChange={handleCheckboxChange} size='sm' m='0'>デフォルト画像を使用</Checkbox>
      </VStack>
      <FormHelperText>縦横比 9:5</FormHelperText>
    </FormControl>
  )
})