import { FormControl, FormErrorMessage, FormLabel, Textarea } from "@chakra-ui/react"
import { memo } from "react"


type NotesTextareaProps = {
  register: any
  errors: any
}
export const NotesTextarea = memo(({register, errors}: NotesTextareaProps) => {
  return (
    <FormControl isRequired>
      <FormLabel>注意事項</FormLabel>
      <Textarea {...register('notes')} />
      <FormErrorMessage>{errors.notes?.message}</FormErrorMessage>
    </FormControl>
  )
})