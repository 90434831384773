import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardBody } from '@chakra-ui/react';
import useSWR from 'swr';

type CustomerText = {
  id: string;
  answer_text: number;
  answered_at: string;
  question_title: string;
}
export const AnsweredText: React.FC = memo(() => {
  const { lineAccountId } = useParams();
  const { data: text, error, isLoading } = useSWR<CustomerText>(`socoupon/customer-survey-text/?line_account_id=${lineAccountId}`)
  if (!text || isLoading || error) return null
  return (
    <Card border='4px solid #ffa6006b' boxShadow='md' w='100%'>
      <CardBody>
        <Box>{text.answer_text}</Box>
      </CardBody>
    </Card>
  );
});