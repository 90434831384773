import React, { memo } from 'react';
import { CardBody, CardHeader, Grid, Text } from '@chakra-ui/react';
import { useFetchKuchikomyuSurveyScores } from '../../../hooks/kuchikomyu/useFetchKuchikomyuSurveyScores';
import { StarRateSurveyScoreLayout } from '../../template/StarRateSurveyScoreLayout';
import { LightShadowCard } from '../../molecules/card/LightShadowCard';
import { Skeletons } from '../../atoms/Skeletons';


type KuchikomyuQuestionScoresProps = {
  restaurantId: string;
}
export const KuchikomyuQuestionScores: React.FC<KuchikomyuQuestionScoresProps> = memo(({restaurantId}) => {
  const { kuchikomyuSurveyScores, isLoading } = useFetchKuchikomyuSurveyScores(restaurantId)
  if (isLoading) return <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}} gap={3} w='100%'><Skeletons length={3} h='280px' rounded='2xl' /></Grid>
  if (!kuchikomyuSurveyScores || kuchikomyuSurveyScores.length===0) return null
  return (
    <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}} gap={3} w='100%'>
      {kuchikomyuSurveyScores.map((surveyScore) => (
        <LightShadowCard key={surveyScore.id}>
          <CardHeader>
            <Text fontWeight='bold' noOfLines={1} m={0}>{surveyScore.title}</Text>
          </CardHeader>
          <CardBody pt={0}>
            <StarRateSurveyScoreLayout {...surveyScore} />
          </CardBody>
        </LightShadowCard>
      ))}
    </Grid>
  );
});