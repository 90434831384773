import { Route } from 'react-router-dom';
import { BusinessPerformances } from '../components/pages/externalApi/google/BusinessPerformances';
import { Reviews } from '../components/pages/externalApi/google/Reviews';
import { GoogleSettingForRestaurant } from '../components/pages/externalApi/google/GoogleSettingForRestaurant';
import { EditGoogleSettingByRestaurant } from '../components/pages/externalApi/google/EditGoogleSettingByRestaurant';
import { PostBusinessLatestInfo } from '../components/pages/externalApi/google/PostBusinessLatestInfo';
import { SearchKeywords } from '../components/pages/externalApi/google/SearchKeywords';


export const GoogleRoutes = (
  <Route path='google'>
    <Route path="business-performances" element={<BusinessPerformances />} />
    <Route path="search-keywords" element={<SearchKeywords />} />
    <Route path="reviews" element={<Reviews />} />
    <Route path="posts/latest-info/new" element={<PostBusinessLatestInfo />} />
    <Route path="settings" element={<GoogleSettingForRestaurant />} />
    <Route path="settings/:settingId/edit" element={<EditGoogleSettingByRestaurant />} />
  </Route>
);