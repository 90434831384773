import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { googleReviewsDateRangeState } from "../../../store/googleReviewsDateRangeState";
import { googleReviewRatingState } from "../../../store/googleReviewRatingState";
import { googleReviewReplyState } from "../../../store/googleReviewReplyState";

export const useNavigateToUrlWithQueryParams = (onClose: () => void) => {
  const navigate = useNavigate();
  const dateRange = useRecoilValue(googleReviewsDateRangeState);
  const reviewRating = useRecoilValue(googleReviewRatingState);
  const replyState = useRecoilValue(googleReviewReplyState);

  const generateQueryParams = () => {
    const queryParams = [];
    if (dateRange.since) queryParams.push(`since=${dateRange.since}`);
    if (dateRange.until) queryParams.push(`until=${dateRange.until}`);
    if (reviewRating) queryParams.push(`rating=${reviewRating}`);
    if (replyState) queryParams.push(`reply=${replyState}`);
    return queryParams.join('&');
  };

  const navigateToUrlWithQueryParams = () => {
    const queryParamsString = generateQueryParams();
    navigate(`?${queryParamsString}`);
    onClose()
  };

  return navigateToUrlWithQueryParams;
};