import { Route } from 'react-router-dom';
import { CreateNotice } from '../components/pages/notice/CreateNotice';
import { Notices } from '../components/pages/notice/Notices';
import { Notice } from '../components/pages/notice/Notice';
import { EditNotice } from '../components/pages/notice/EditNotice';


export const NoticeRoutes = (
  <Route path='notices'>
    <Route path="" element={<Notices />} />
    <Route path="new" element={<CreateNotice />} />
    <Route path=":noticeId" element={<Notice />} />
    <Route path=":noticeId/edit" element={<EditNotice />} />
  </Route>
);