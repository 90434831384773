import useSWR from "swr";


export type Team = {
  id: string;
  managers: string[]
  manager_names: string[];
  name: string;
  email: string;
  cc_emails: string[];
  dispatch_count: number;
}
export const useFetchKuitanTeam = (teamId?: string) => {
  const fetchKey = `kuitan/teams/${teamId}/`
  const { data: team, error, isLoading } = useSWR<Team>(fetchKey)
  return { team, isLoading, error };
};