import { useNavigate, useSearchParams } from "react-router-dom";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";
import { SelectedQuestions } from "../manzokudoLabo/useFetchManlaboSurveySelectedQuestionsCustomerByRestaurantId";
import { StaffSetting } from "./useFetchStaffSettingCustomerByRestaurantId";
import liff from '@line/liff';


export const useAnswerStaffSurvey = (selectedQuestions: SelectedQuestions, lineAccountId: string, setting: StaffSetting) => {
  const [searchParams] = useSearchParams();
  const staffId = searchParams.get('staff');
  const navigate = useNavigate();
  const onSubmit = async (data:any) => {
    const questions = selectedQuestions.map(selectedQuestion => selectedQuestion.question);
    data['line_account_id'] = lineAccountId
    data['staff_id'] = staffId
    data['questions'] = questions
    try {
      await customNoAuthAxios.post('restaurant_staff/survey/answers/', data);
      const branchQuestionIndex = questions.findIndex(question => question === setting.staff_branch_question);
      const branchQuestionScore = data.survey[branchQuestionIndex!].answer_score
      if (branchQuestionScore > setting.staff_branch_score && setting.is_navigate_g_url) {
        await liff.openWindow({url: setting.g_url, external: true});
        liff.closeWindow();
      } else {
        navigate('/thanks', {replace: true});
      }
    } catch (error:any) {
    }
  };
  return onSubmit;
};