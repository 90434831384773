import useSWR from "swr";


type MonthlyDispatchRequests = {
  id: string;
  team_name: string;
  month: string;
  number: number;
  requested_at: string;
}[]
type PaginatedMonthlyDispatchRequests = {
  count: number;
  monthly_dispatch_requests: MonthlyDispatchRequests;
};
export const useFetchPaginatedMonthlyDispatchRequestsByRestaurantId = (pageSize: number, currentPage: number, restaurantId?: string,) => {
  const fetcherKey = restaurantId ? `kuitan/restaurants/${restaurantId}/dispatch/requests/monthly/paginated/?pageSize=${pageSize}&currentPage=${currentPage}` : null
  const { data: paginatedMonthlyDispatchRequests, error, isLoading } = useSWR<PaginatedMonthlyDispatchRequests>(fetcherKey)
  return { paginatedMonthlyDispatchRequests, isLoading, error };
};