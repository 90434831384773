import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const registerMenuSchema = yup.object().shape({
  images: yup.array().of(yup.mixed()).required('ファイルを選択してください'),
});
export const useRegisterMenuImagesForm = () => {
  const { handleSubmit, control, formState: { errors, isSubmitting }, setValue, getValues } = useForm({resolver: yupResolver(registerMenuSchema),});
  return {
    handleSubmit,
    control,
    isSubmitting,
    errors,
    setValue,
    getValues
  };
};