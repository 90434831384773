import React, { memo } from 'react';
import { RegisterMenuForm } from '../../organisms/menu/RegisterMenuForm';
import { useParams } from 'react-router-dom';
import { useRegisterChineseMenuImages } from '../../../hooks/menu/useRegisterChineseMenuImages';


export const ChineseDrinkMenuRegister: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const onSubmit = useRegisterChineseMenuImages('drink', restaurantId)
  return <RegisterMenuForm menuName='ドリンク' onSubmit={onSubmit} />;
});