import React, { memo } from "react";
import Chart from "react-apexcharts";

type BarGraphProps = {
  labels: string[];
  values: number[];
  seriesName: string;
}
export const BarGraph: React.FC<BarGraphProps> = memo(({ labels, values, seriesName }) => {
  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          fontSize: '10px'
        },
        rotate: 0,
        rotateAlways: false
      },
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        style: {
          fontSize: '10px'
        },
      },
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      colors: ['#3679FE']
    },
  };

  const series = [
    {
      name: seriesName,
      data: values,
      color: '#0078FF'
    }
  ];

  return (
    <Chart options={options} series={series} type="bar" height="300" />
  );
});

