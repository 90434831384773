import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Textarea, VStack } from "@chakra-ui/react";


type Props = {
  register: any;
  errors: any;
  title: string;
  setTitle: (title: string) => void;
}
export const NoticeFormContents = ({ register, errors, title, setTitle }: Props) => {
  return (
    <VStack spacing={4}>
      <FormControl isRequired isInvalid={!!errors.title} >
        <FormLabel>タイトル</FormLabel>
        <Input type="text" maxLength={100} {...register('title')} onChange={(e) => setTitle(e.target.value)} />
        <FormHelperText>{title.length} / 100</FormHelperText>
        <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!errors.content} >
        <FormLabel>内容</FormLabel>
        <Textarea {...register('content')} />
        <FormErrorMessage>{errors.content?.message}</FormErrorMessage>
      </FormControl>
      <FormControl >
        <FormLabel>画像</FormLabel>
        <input type="file" accept="image/*" {...register('img')} />
      </FormControl>
      <FormControl >
        <FormLabel>Url</FormLabel>
        <Input type="url" {...register('url')} />
      </FormControl>
    </VStack>
  );
};