import { memo } from "react"
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { Box, CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { SettingAlert } from "../../molecules/SettingAlert";
import { SoCouponsField } from "../../organisms/soCoupon/SoCouponsField";
import { useFetchSoCouponSettingByRestaurantId } from "../../../hooks/socoupon/useFetchSoCouponSettingByRestaurantId";
import { CardHeading } from "../../molecules/card/CardHeading";
import { RiCoupon2Line } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";


export const SoCoupons: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { soCouponSetting, isLoading } = useFetchSoCouponSettingByRestaurantId(restaurantId)
  if (!restaurantId || isLoading) return <CircularLoading />
  if (!soCouponSetting) return <SettingAlert msg='即ーポンの設定を完了してください。' />
  const alerts = [
    { condition: !soCouponSetting.g_url, message: "遷移先URLが設定されていません。営業担当に連絡してください。" },
    { condition: !soCouponSetting.branch_score, message: "ブランチスコアを設定してください" },
    { condition: !soCouponSetting.socoupon_in_use, message: "使用するクーポンを設定してください" },
  ];
  return (
    <Box>
      {alerts.map((alert, index) => alert.condition ? <SettingAlert key={index} msg={alert.message} /> : null)}
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<RiCoupon2Line />} heading='クーポン一覧'/>
        </CardHeader>
        <CardBody py={0}>
          <SoCouponsField restaurantId={restaurantId} soCouponInUseId={soCouponSetting.socoupon_in_use} />
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='register' text='作成' />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});