import React, { memo } from "react";
import { CardHeader, CardBody, VStack, Box, Divider, CardFooter, HStack, IconButton, useDisclosure, Image, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import { CardHeading } from "../../molecules/card/CardHeading";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { SettingItem } from "../../organisms/SettingItem";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useDelete } from "../../../hooks/useDelete";
import { DeleteModal } from "../../molecules/DeleteModal";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchRestaurantStaff } from "../../../hooks/restaurantStaff/useFetchRestaurantStaff";
import { FaUser } from "react-icons/fa";
import { formatDate } from "../../../utils/formatDate";
import { RestaurantTab } from "../../organisms/restaurantStaff/RestaurantTab";
import { StaffTab } from "../../organisms/restaurantStaff/StaffTab";


export const Staff = () => {
  const { staffId } = useParams();
  const { staff, isLoading } = useFetchRestaurantStaff(staffId);
  if (isLoading || !staff) return <CircularLoading />
  const { id, name, nickname, img, restaurant_survey_response_count, staff_survey_response_count, latest_response_day } = staff;
  const historyDetails = [
    { label: 'ニックネーム', value: nickname },
    { label: '取得件数', value: `従業員 ${staff_survey_response_count}件\n店舗 ${restaurant_survey_response_count}件` },
    { label: '最新取得日時', value: latest_response_day ? formatDate(latest_response_day) : '' },
  ];
  return (
    <VStack spacing={10}>
      <DarkShadowCard w='100%'>
        <CardHeader>
          <CardHeading icon={<FaUser />} heading={name}/>
        </CardHeader>
        <CardBody py={2}>
          <VStack align='start' fontSize='sm' fontWeight='bold' spacing={4} w='100%'>
            <HStack spacing={12}>
              <Image src={img} alt='staff' fallbackSrc='/images/fallback-user.png' boxSize='70px' rounded='md' />
              <Box fontSize='46px' bgGradient='linear(to-l, #FE964E, #FF0080)' bgClip='text'>
                {staff_survey_response_count + restaurant_survey_response_count}P
              </Box>
            </HStack>
            <Divider my={0} />
            {historyDetails.map((detail) => (
              <React.Fragment key={detail.label}>
                <Box w='100%'>
                  <SettingItem label={detail.label} content={detail.value}/>
                </Box>
                <Divider my={0} />
              </React.Fragment>
            ))}
          </VStack>
        </CardBody>
        <CardFooter>
          <HStack>
            <EditAndDeleteIcons id={id} title={name} />
          </HStack>
        </CardFooter>
      </DarkShadowCard>
      <Box w='100%'>
        <StatusTabs staffId={staffId} />
      </Box>
    </VStack>
  );
};


const EditAndDeleteIcons: React.FC<{ id: string, title: string }> = memo(({ id, title }) => {
  const navigate = useNavigate()
  return (
    <HStack align='start' w='100%'>
      <IconButton
        colorScheme='messenger'
        aria-label='Edit Staff'
        icon={<EditIcon />}
        size='sm'
        variant='ghost'
        onClick={() => navigate('edit')}
      />
      <DeleteStaffModal id={id} title={title} />
    </HStack>
  )
})

const DeleteStaffModal: React.FC<{ id: string, title: string }> = memo(({id, title}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDelete(`restaurant_staff/staff/${id}/`, onClose)
  return (
    <>
      <IconButton
        colorScheme='red'
        aria-label='Delete Staff'
        icon={<DeleteIcon />}
        size='sm'
        variant='ghost'
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={title}
        onSubmit={onSubmit}
      />
    </>
  );
});


const StatusTabs = ({staffId}: {staffId?: string}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams({ tab: "" });
  const currentTab = searchParams.get("tab") || "restaurant";
  const handleTabChange = (index: number) => {
    const tab = index === 0 ? "restaurant" : "staff";
    const newSearchParams = new URLSearchParams();
    newSearchParams.set("tab", tab);
    navigate({ search: newSearchParams.toString() });
  };
  const currentIndex = currentTab === "staff" ? 1 : 0;
  return (
    <Tabs variant='soft-rounded' colorScheme='messenger' isLazy onChange={handleTabChange} index={currentIndex}>
      <TabList>
        <Tab>店舗</Tab>
        <Tab>従業員</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <RestaurantTab staffId={staffId} />
        </TabPanel>
        <TabPanel px={0}>
          <StaffTab staffId={staffId} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};