import { Box, CardBody, CardHeader, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useFetchLiffRestaurantsByRestaurantId } from "../../../../hooks/externalApi/line/useFetchLiffRestaurantsByRestaurantId";
import { TableLayout } from "../../../template/table/TableLayout";
import { Link as RouterLink, useParams } from "react-router-dom";
import { PlusSquareIcon, SettingsIcon } from "@chakra-ui/icons";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { TableRowLayout } from "../../../template/table/TableRowLayout";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { LiffDeleteModal } from "../../../organisms/externalApi/line/LiffDeleteModal";
import { PartialCircularLoading } from "../../../molecules/loading/PartialCircularLoading";
import { useFetchUser } from "../../../../hooks/user/useFetchUser";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";


export const LiffSetting = () => {
  const { user, isLoading } = useFetchUser()
  if (isLoading || !user) return <CircularLoading />
  const isSuperuser = user.is_superuser
  return (
    <DarkShadowCard w='100%'>
      <CardHeader>
        <LiffSettingCardHeader isSuperuser={isSuperuser} />
      </CardHeader>
      <CardBody pt={0}>
        <LiffTable isSuperuser={isSuperuser}/>
      </CardBody>
    </DarkShadowCard>
  );
};


const LiffSettingCardHeader = ({isSuperuser}: {isSuperuser: boolean}) => {
  if (!isSuperuser) return <CardHeading icon={<SettingsIcon />} heading='Liff設定'/>
  return (
    <Flex align='center' justify='space-between'>
      <CardHeading icon={<SettingsIcon />} heading='Liff設定'/>
      <IconButton
        as={RouterLink}
        variant='ghost'
        colorScheme='messenger'
        aria-label='register'
        icon={<PlusSquareIcon />}
        size='sm'
        to='register'
      />
    </Flex>
  );
};

const LiffTable = ({isSuperuser}: {isSuperuser: boolean}) => {
  const {restaurantId} = useParams();
  const {liffRestaurants, isLoading} =useFetchLiffRestaurantsByRestaurantId(restaurantId);
  if (isLoading || !liffRestaurants) return <PartialCircularLoading />;
  if (liffRestaurants?.length === 0) return <Box fontSize='14px'>データが存在しません</Box>;
  const headers = ['LIFFネーム', 'LIFFID', 'LIFFURL'];
  if (isSuperuser) headers.push('');
  const rows = liffRestaurants.map((liff) => (
    <TableRowLayout
      key={liff.id}
      size="sm"
      childrenList={[
        liff.liff_name,
        liff.liff_id,
        liff.liff_url,
        isSuperuser &&
          <Flex justify='center'>
            <Menu>
              <MenuButton><BsThreeDotsVertical/></MenuButton>
              <MenuList minWidth="150px">
                <MenuItem as={Link} to={`${liff.id}/edit`}>編集</MenuItem>
                <MenuItem>
                  <LiffDeleteModal liffId={liff.id} liffName={liff.liff_name} mutateKey={`external_api/restaurants/${restaurantId}/liff-restaurants/`} />
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
      ].filter(Boolean)}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size="sm" />
}