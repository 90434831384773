import React, { memo } from "react";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { CardBody, CardFooter, CardHeader, HStack, IconButton, Image, Link, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { formatDate } from "../../../utils/formatDate";
import { useFetchNotice } from "../../../hooks/notice/useFetchNotice";
import { IoIosNotifications } from "react-icons/io";
import { DeleteIcon, EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useDelete } from "../../../hooks/useDelete";
import { DeleteModal } from "../../molecules/DeleteModal";
import { useMarkNoticeAsRead } from "../../../hooks/notice/useMarkNoticeAsRead";


export const Notice = () => {
  const location = useLocation();
  const isSalesmen = location.pathname.includes("salesmen");
  const { noticeId, restaurantId } = useParams();
  const { notice, isLoading } = useFetchNotice(noticeId)
  useMarkNoticeAsRead(noticeId, restaurantId, notice?.read_by);
  if (isLoading) return <CircularLoading />;
  if (!notice) return null
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<IoIosNotifications />} heading={notice.title}/>
      </CardHeader>
      <CardBody py={0}>
        <VStack align='start' spacing={8}>
          <Text fontSize='sm' color="gray.500" m={0}>{formatDate(notice.created_at)}</Text>
          {notice.img && <Image src={notice.img} alt={notice.title} h='300px' />}
          <Text whiteSpace='pre-wrap' m={0}>{notice.content}</Text>
          {notice.url && <Link href={notice.url} isExternal color='messenger.500'>{notice.url}<ExternalLinkIcon ml='4px' /></Link>}
        </VStack>
      </CardBody>
      <CardFooter>
        {isSalesmen && <EditAndDeleteIcons id={notice.id} title={notice.title} />}
      </CardFooter>
    </DarkShadowCard>
  );
};


const EditAndDeleteIcons: React.FC<{ id: string, title: string }> = memo(({ id, title }) => {
  const navigate = useNavigate()
  return (
    <HStack align='start' spacing={4} w='100%'>
      <IconButton
        colorScheme='messenger'
        aria-label='Edit Notice'
        icon={<EditIcon />}
        size='sm'
        variant='outline'
        onClick={() => navigate('edit')}
      />
      <NoticeDeleteModal id={id} title={title} />
    </HStack>
  )
})

const NoticeDeleteModal: React.FC<{ id: string, title: string }> = memo(({id, title}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDelete(`general_setting/notices/${id}/`, onClose)
  return (
    <>
      <IconButton
        colorScheme='red'
        aria-label='Delete Notice'
        icon={<DeleteIcon />}
        size='sm'
        variant='outline'
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={title}
        onSubmit={onSubmit}
      />
    </>
  );
});
