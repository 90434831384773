import { atom } from 'recoil';

type DateRange = {
  since: string | null;
  until: string | null;
};

export const googleReviewsDateRangeState = atom<DateRange>({
  key: 'googleReviewsDateRangeState',
  default: { since: null, until: null }
});

