import { Route } from 'react-router-dom';
import { Restaurant } from '../components/pages/restaurants/Restaurant';
import { EditRestaurant } from '../components/pages/restaurants/EditRestaurant';
import { RegisterRestaurant } from '../components/pages/restaurants/RegisterRestaurant';
import { EditRestaurantPassword } from '../components/pages/restaurants/EditRestaurantPassword';
import { RegisterLineDev } from '../components/pages/externalApi/line/RegisterLineDev';
import { EditLineDev } from '../components/pages/externalApi/line/EditLineDev';
import { RegisterLiff } from '../components/pages/externalApi/line/RegisterLiff';
import { EditLiff } from '../components/pages/externalApi/line/EditLiff';
import { RegisterIfreaAnalyticsSettings } from '../components/pages/ifreaAnalytics/RegisterIfreaAnalyticsSettings';
import { EditIfreaAnalyticsSetting } from '../components/pages/ifreaAnalytics/EditIfreaAnalyticsSetting';
import { RegisterGoogleSetting } from '../components/pages/externalApi/google/RegisterGoogleSetting';
import { EditGoogleSettingByAdmin } from '../components/pages/externalApi/google/EditGoogleSettingByAdmin';
import { RegisterKuchikomyuSetting } from '../components/pages/kuchikomyu/RegisterKuchikomyuSetting';
import { EditKuchikomyuSettingByAdmin } from '../components/pages/kuchikomyu/EditKuchikomyuSettingByAdmin';
import { RegisterSoCouponSetting } from '../components/pages/soCoupon/RegisterSoCouponSetting';
import { EditSoCouponSettingByAdmin } from '../components/pages/soCoupon/EditSoCouponSettingByAdmin';
import { RegisterManLaboSetting } from '../components/pages/manzokudoLabo/RegisterManLaboSetting';
import { EditManLaboSettingByAdmin } from '../components/pages/manzokudoLabo/EditManLaboSettingByAdmin';
import { LineDevSetting } from '../components/pages/externalApi/line/LineDevSetting';
import { LiffSetting } from '../components/pages/externalApi/line/LiffSetting';
import { GoogleSetting } from '../components/pages/externalApi/google/GoogleSetting';
import { IfreaAnalyticsSetting } from '../components/pages/ifreaAnalytics/IfreaAnalyticsSetting';
import { SoCouponSetting } from '../components/pages/soCoupon/SoCouponSetting';
import { KuchikomyuSetting } from '../components/pages/kuchikomyu/KuchikomyuSetting';
import { ManLaboSetting } from '../components/pages/manzokudoLabo/ManLaboSetting';
import { GameSettings } from '../components/pages/games/GameSettings';
import { RouletteSetting } from '../components/pages/games/roulette/RouletteSetting';
import { RegisterRouletteSetting } from '../components/pages/games/roulette/RegisterRouletteSetting';
import { EditRouletteSettingByAdmin } from '../components/pages/games/roulette/EditRouletteSettingByAdmin';
import { KuitanSetting } from '../components/pages/kuitan/KuitanSetting';
import { RegisterKuitanSetting } from '../components/pages/kuitan/RegisterKuitanSetting';
import { EditKuitanSetting } from '../components/pages/kuitan/EditKuitanSetting';
import { Restaurants } from '../components/pages/restaurants/Restaurants';
import { StaffSetting } from '../components/pages/restaurantStaff/StaffSetting';
import { RegisterStaffSetting } from '../components/pages/restaurantStaff/RegisterStaffSetting';
import { EditStaffSettingByAdmin } from '../components/pages/restaurantStaff/EditStaffSettingByAdmin';


const routes = [
  { path: "", element: <Restaurants /> },
  { path: "register", element: <RegisterRestaurant /> },
  { path: ":restaurantId", element: <Restaurant /> },
  { path: ":restaurantId/edit", element: <EditRestaurant /> },
  { path: ":restaurantId/pass/edit", element: <EditRestaurantPassword /> },
  { path: ":restaurantId/settings/line-devs", element: <LineDevSetting /> },
  { path: ":restaurantId/settings/line-devs/register", element: <RegisterLineDev /> },
  { path: ":restaurantId/settings/line-devs/:lineDevId/edit", element: <EditLineDev /> },
  { path: ":restaurantId/settings/liff", element: <LiffSetting /> },
  { path: ":restaurantId/settings/liff/register", element: <RegisterLiff /> },
  { path: ":restaurantId/settings/liff/:liffId/edit", element: <EditLiff /> },
  { path: ":restaurantId/settings/ifrea-analytics", element: <IfreaAnalyticsSetting /> },
  { path: ":restaurantId/settings/ifrea-analytics/register", element: <RegisterIfreaAnalyticsSettings /> },
  { path: ":restaurantId/settings/ifrea-analytics/:settingsId/edit", element: <EditIfreaAnalyticsSetting /> },
  { path: ":restaurantId/settings/google", element: <GoogleSetting /> },
  { path: ":restaurantId/settings/google/register", element: <RegisterGoogleSetting /> },
  { path: ":restaurantId/settings/google/:settingId/edit", element: <EditGoogleSettingByAdmin /> },
  { path: ":restaurantId/settings/kuchikomyu", element: <KuchikomyuSetting /> },
  { path: ":restaurantId/settings/kuchikomyu/register", element: <RegisterKuchikomyuSetting /> },
  { path: ":restaurantId/settings/kuchikomyu/:settingsId/edit", element: <EditKuchikomyuSettingByAdmin /> },
  { path: ":restaurantId/settings/socoupon", element: <SoCouponSetting /> },
  { path: ":restaurantId/settings/socoupon/register", element: <RegisterSoCouponSetting /> },
  { path: ":restaurantId/settings/socoupon/:settingsId/edit", element: <EditSoCouponSettingByAdmin /> },
  { path: ":restaurantId/settings/manzokudo-labo", element: <ManLaboSetting /> },
  { path: ":restaurantId/settings/manzokudo-labo/register", element: <RegisterManLaboSetting /> },
  { path: ":restaurantId/settings/manzokudo-labo/:settingsId/edit", element: <EditManLaboSettingByAdmin /> },
  { path: ":restaurantId/settings/games", element: <GameSettings /> },
  { path: ":restaurantId/settings/games/roulette", element: <RouletteSetting /> },
  { path: ":restaurantId/settings/games/roulette/register", element: <RegisterRouletteSetting /> },
  { path: ":restaurantId/settings/games/roulette/:settingId/edit", element: <EditRouletteSettingByAdmin /> },
  { path: ":restaurantId/settings/kuitan", element: <KuitanSetting /> },
  { path: ":restaurantId/settings/kuitan/register", element: <RegisterKuitanSetting /> },
  { path: ":restaurantId/settings/kuitan/:settingId/edit", element: <EditKuitanSetting /> },
  { path: ":restaurantId/settings/restaurant-staff", element: <StaffSetting /> },
  { path: ":restaurantId/settings/restaurant-staff/register", element: <RegisterStaffSetting /> },
  { path: ":restaurantId/settings/restaurant-staff/:settingId/edit", element: <EditStaffSettingByAdmin /> },
];
export const RestaurantsRoutesForAdmin = (
  <Route path='restaurants'>
    {routes.map((route) => <Route key={route.path} path={route.path} element={route.element} />)}
  </Route>
);