import { useNavigate } from "react-router-dom";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";
import { getRandomStringWithDate } from "../../utils/getRandomStringWithDate";

type SelectedQuestions = {
  id: string;
  question: string;
  question_content: string;
}[]

export const useSubmitKuchikomyuCustomerSurveyEn = (restaurantId: string, selectedQuestions: SelectedQuestions) => {
  const navigate = useNavigate();

  const onSubmit = async (data:any) => {
    const questions = selectedQuestions?.map(selectedQuestion => selectedQuestion.question);
    data['restaurant_id'] = restaurantId
    data['questions'] = questions
    data['survey_set_id'] = getRandomStringWithDate()
    try {
      const res = await customNoAuthAxios.post(
        'kuchikomyu/survey/answer/customer/',
        data,
      );
      navigate(`/thanks/en`);

    } catch (error:any) {
    } finally {
    }
  };

  return onSubmit;
};