import React, { memo, useState } from "react";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { useParams } from "react-router-dom";
import { Box, Button, HStack, Image, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea, VStack, useDisclosure } from "@chakra-ui/react";
import { StarRating } from "../../../molecules/StarRating";
import { useFetchGoogleReviewReply } from "../../../../hooks/externalApi/google/useFetchGoogleReviewReply";
import { useGoogleReviewReplyEditForm } from "../../../../hooks/externalApi/google/useGoogleReviewReplyEditForm";
import { useSubmitGoogleReviewReplyEdit } from "../../../../hooks/externalApi/google/useSubmitGoogleReviewReplyEdit";
import { useSubmitSemiAutoReply } from "../../../../hooks/externalApi/google/useSubmitSemiAutoReply";


export const ReviewReply: React.FC = () => {
  const { replyId } = useParams()
  const { reply, isLoading } = useFetchGoogleReviewReply(replyId)
  if (!reply || isLoading || !replyId) return <CircularLoading />
  const reviewText = reply.comment.startsWith('(Translated by Google)') ? `${reply.comment}` : `${reply.comment}`.split('(Translated by Google)')[0].trim();
  return (
    <Box p={4}>
      <VStack align='start' p={4} bg='white' rounded={10} boxShadow='rgba(0, 0, 0, 0.08) 1px 1px 4px' fontSize='sm' spacing={1}>
        <HStack>
          <Image src={reply.reviewer_image_url} boxSize='40px' />
          <Box>
            <Text fontWeight='bold' m='0'>{reply.reviewer_name}</Text>
            <HStack>
              <StarRating count={reply.rating} />
              <Text color='gray.500' m='0'>{reply.review_date}</Text>
            </HStack>
          </Box>
        </HStack>
        <VStack align='start' spacing={1} whiteSpace='pre-wrap' w='100%'>
          <Text m='0'>{reviewText}</Text>
          <Box borderLeft='2px solid #dadada' pl={2} w='100%'>
            <ReplyForm reply={reply.reply} replyId={replyId} reviewId={reply.review_id} />
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};

const ReplyForm: React.FC<{reply: string, replyId: string, reviewId: string}> = memo(({reply, replyId, reviewId}) => {
  const [replyText, setReplyText] = useState(reply);
  const { handleSubmit, register, isSubmitting } = useGoogleReviewReplyEditForm(reply)
  const onSubmit = useSubmitGoogleReviewReplyEdit(replyId)
  return (
    <Box as='form' onSubmit={handleSubmit(onSubmit)}>
      <Textarea isRequired {...register('reply')} maxLength={4000} onChange={(e) => setReplyText(e.target.value)} fontSize='sm' rows={10} bgColor='white' px={2} />
      <Text color='gray.500' fontSize='xs' textAlign='right' m='0'>{replyText.length} / 4000</Text>
      <HStack>
        <Button type='submit' size='sm' isLoading={isSubmitting} colorScheme="messenger">保存</Button>
        <ReplyModal reviewId={reviewId} replyText={replyText} />
      </HStack>
    </Box>
  );
});


export const ReplyModal: React.FC<{reviewId: string, replyText: string}> = memo(({reviewId, replyText}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useSubmitSemiAutoReply(reviewId, replyText, onClose, setIsSubmitting)
  return (
    <>
      <Button size='sm' colorScheme="orange" onClick={onOpen}>返信</Button>
      <ReplyConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    </>
  );
});

type ReplyConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  isSubmitting: boolean;
}
const ReplyConfirmModal: React.FC<ReplyConfirmModalProps> = memo(({isOpen, onClose, onSubmit, isSubmitting}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xs'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={0} fontSize='lg'>返信してよろしいですか？</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <Button colorScheme='messenger' size='sm' mr={3} onClick={onSubmit} isLoading={isSubmitting}>返信</Button>
          <Button colorScheme='gray' size='sm' onClick={onClose} isDisabled={isSubmitting}>キャンセル</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});