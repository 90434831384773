import React, { memo } from "react";
import { Card, CardBody, VStack } from '@chakra-ui/react'
import { StampField } from "./StampField";
import { StampCardTop } from "./StampCardTop";
import { StampCardBottom } from "./StampCardBottom";

type nextRank = {
  name: string;
  visitCountsNeededToRankUp: number;
}
type DefaultStampCardProps = {
  visitCount: number;
  nextRank?: nextRank;
}

export const DefaultStampCard: React.FC<DefaultStampCardProps> = memo(({visitCount, nextRank}) => {
  return (
    <Card bgColor='#FFBD58' w='100%' h='100%' variant='filled'>
      <CardBody p={2}>
        <VStack border='2px solid #032963' color='#032963' h='100%' py={2} justifyContent='space-between' w='100%' spacing={0}>
          <StampCardTop />
          <StampField visitCount={visitCount} />
          <StampCardBottom nextRank={nextRank} />
        </VStack>
      </CardBody>
    </Card>
  );
});