import { memo, useState } from "react"
import { Box, Center, HStack, Image } from "@chakra-ui/react";


type MenuImageLayoutProps = {
  img: string;
  children: React.ReactNode;
}
export const MenuImageLayout: React.FC<MenuImageLayoutProps> = memo(({img, children}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const handleMouseEnter = () => setShowOverlay(true);
  const handleMouseLeave = () => setShowOverlay(false);
  return (
    <Box position="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} h='100%'>
      <Image
        src={img}
        objectFit="contain"
        h='100%'
        w='100%'
      />
      <MenuImgOverlay showOverlay={showOverlay}>
        {children}
      </MenuImgOverlay>
    </Box>
  )
})

type OverlayProps = {
  showOverlay: boolean;
  children: React.ReactNode;
}
const MenuImgOverlay: React.FC<OverlayProps> = memo(({showOverlay, children}) => {
  return (
    <Box display={showOverlay ? 'block' : 'none'}>
      <Center
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="rgba(0, 0, 0, 0.3)"
        zIndex="2"
      >
        <HStack spacing={3}>
          {children}
        </HStack>
      </Center>
    </Box>
  )
})