import { useLoginForm } from '../../../hooks/auth/useLoginForm';
import { Button, Center, Link, Text, VStack, } from "@chakra-ui/react";
import { useLoginSalesman } from '../../../hooks/auth/useLoginSalesman';
import { Link as RouterLink } from 'react-router-dom';
import { AuthPasswordInput } from '../../molecules/form/AuthPasswordInput';
import { LoginUsernameInput } from '../../molecules/form/LoginUsernameInput';


export const LoginSalesman = () => {
  const { handleSubmit, register, isSubmitting, errors } = useLoginForm();
  const onSubmit = useLoginSalesman();
  return (
    <Center mt={{base: 0, sm: '60px', lg: '60px'}}>
      <VStack as='form' align='start' spacing={6} bg="white" p={{ base: 6, sm: 10 }} rounded={6} w={{ base: '100%', sm: '450px' }} onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize='2xl' fontWeight='bold' mb={0}>営業マンログイン</Text>
        <VStack align='start' w='100%' spacing={4}>
          <LoginUsernameInput register={register} errors={errors} />
          <AuthPasswordInput register={register} errors={errors} />
        </VStack>
        <VStack align='start' w='100%'>
          <Button colorScheme="orange" w='100%' isLoading={isSubmitting} type='submit'>ログイン</Button>
          <Link as={RouterLink} to='/signup' color='messenger.500' fontSize='sm'>サインアップ</Link>
        </VStack>
      </VStack>
    </Center>
  );
};