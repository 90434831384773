import React, { memo, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { lineAccountIdState } from "../../../store/fetchedData/lineAccountIdState";
import { PostSoCouponOverallScore } from "../../organisms/soCoupon/PostSoCouponOverallScore";
import { CircularLoading } from "../../molecules/loading/CircularLoading";


export const SoCouponTest: React.FC = memo(() => {
  const restaurantId = 'a7d7ec02-7bcb-41fd-9a0f-f25aa300367b'
  const [lineAccountId, setLineAccountId] = useRecoilState(lineAccountIdState)

  useEffect(() => {
    setLineAccountId('257466db-dd19-4de5-9978-a87e67a1d455')
  }, []);

  if (!lineAccountId) return <CircularLoading />


  return (
    <PostSoCouponOverallScore restaurantId={restaurantId} />
  );
});

