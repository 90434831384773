import useSWR from "swr";

type MenuImages = {
  id: string;
  restaurant: string;
  menu_type: string;
  img: string;
  menu_num: string;
}[]
export const useFetchKoreanDrinkMenuByRestaurantId = (restaurantId: string) => {
  const fetcherKey = restaurantId ? `menu/restaurants/${restaurantId}/drink/korean/` : null;
  const { data: drinkMenuImages, error, isLoading } = useSWR<MenuImages>(fetcherKey)
  return { drinkMenuImages, isLoading, error };
};