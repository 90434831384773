import React, { memo } from "react";
import { Skeleton } from "@chakra-ui/react";
import { useFetchSoCouponOverallScoresCount } from "../../../hooks/socoupon/useFetchSoCouponOverallScoresCount";
import { SmallCardWithIcon } from "../../molecules/card/SmallCardWithIcon";
import { RiSurveyLine } from "react-icons/ri";


type SoCouponOverallScoreAnswerCountCardProps = {
  restaurantId: string;
  startDate: string | null;
  endDate: string | null;
}
export const SoCouponOverallScoreAnswerCountCard: React.FC<SoCouponOverallScoreAnswerCountCardProps> = memo(({restaurantId, startDate, endDate}) => {
  const { soCouponOverallScoresCount, isLoading } = useFetchSoCouponOverallScoresCount(restaurantId, startDate, endDate);
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <SmallCardWithIcon data={`${soCouponOverallScoresCount}人`} title='即ーポン評価回答数'>
        <RiSurveyLine size='30px'  />
      </SmallCardWithIcon>
    </Skeleton>
  );
});