import React, { memo } from "react"
import { Tabs, TabList, TabPanels, Tab, TabPanel, VStack, Image } from '@chakra-ui/react'
import { useFetchCustomerFoodMenu } from "../../../hooks/menu/useFetchCustomerFoodMenu";
import { useFetchCustomerDrinkMenu } from "../../../hooks/menu/useFetchCustomerDrinkMenu";
import { useFetchCustomerEnglishFoodMenu } from "../../../hooks/menu/useFetchCustomerEnglishFoodMenu";
import { useFetchCustomerEnglishDrinkMenu } from "../../../hooks/menu/useFetchCustomerEnglishDrinkMenu";
import { useFetchCustomerChineseFoodMenu } from "../../../hooks/menu/useFetchCustomerChineseFoodMenu";
import { useFetchCustomerChineseDrinkMenu } from "../../../hooks/menu/useFetchCustomerChineseDrinkMenu";
import { useFetchCustomerKoreanFoodMenu } from "../../../hooks/menu/useFetchCustomerKoreanFoodMenu";
import { useFetchCustomerKoreanDrinkMenu } from "../../../hooks/menu/useFetchCustomerKoreanDrinkMenu";
import { PartialCircularLoading } from "../../molecules/loading/PartialCircularLoading";

type DisplayMenuProps = {
  restaurantId: string
}
export const DisplayMenu: React.FC<DisplayMenuProps> = memo(({restaurantId}) => {
  const menuComponents = {
    日本語: JapaneseMenu,
    English: EnglishMenu,
    中文: ChineseMenu,
    한국어: KoreanMenu,
  };
  return (
    <Tabs isLazy isFitted>
      <TabList>
        {Object.keys(menuComponents).map((language) => <Tab key={language}>{language}</Tab>)}
      </TabList>
      <TabPanels>
        {Object.values(menuComponents).map((MenuComponent, index) => (
          <TabPanel key={index}>
            <MenuComponent restaurantId={restaurantId} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
});


const JapaneseMenu: React.FC<DisplayMenuProps> = memo(({restaurantId}) => {
  return <TabsLayout tabNames={["フード", "ドリンク"]} tabContents={[<JapaneseFoodMenu restaurantId={restaurantId} />, <JapaneseDrinkMenu restaurantId={restaurantId} />]} />
});
const JapaneseFoodMenu: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { foodMenu, isLoading } = useFetchCustomerFoodMenu(restaurantId)
  return <MenuLayout menu={foodMenu} isLoading={isLoading} />
});
const JapaneseDrinkMenu: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { drinkMenu, isLoading } = useFetchCustomerDrinkMenu(restaurantId)
  return <MenuLayout menu={drinkMenu} isLoading={isLoading} />
});


const EnglishMenu: React.FC<DisplayMenuProps> = memo(({restaurantId}) => {
  return <TabsLayout tabNames={["Food", "Drink"]} tabContents={[<EnglishFoodMenu restaurantId={restaurantId} />, <EnglishDrinkMenu restaurantId={restaurantId} />]} />
});
const EnglishFoodMenu: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { foodMenu, isLoading } = useFetchCustomerEnglishFoodMenu(restaurantId)
  return <MenuLayout menu={foodMenu} isLoading={isLoading} />
});
const EnglishDrinkMenu: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { drinkMenu, isLoading } = useFetchCustomerEnglishDrinkMenu(restaurantId)
  return <MenuLayout menu={drinkMenu} isLoading={isLoading} />
});


const ChineseMenu: React.FC<DisplayMenuProps> = memo(({restaurantId}) => {
  return <TabsLayout tabNames={["食物", "喝"]} tabContents={[<ChineseFoodMenu restaurantId={restaurantId} />, <ChineseDrinkMenu restaurantId={restaurantId} />]} />
});
const ChineseFoodMenu: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { foodMenu, isLoading } = useFetchCustomerChineseFoodMenu(restaurantId)
  return <MenuLayout menu={foodMenu} isLoading={isLoading} />
});
const ChineseDrinkMenu: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { drinkMenu, isLoading } = useFetchCustomerChineseDrinkMenu(restaurantId)
  return <MenuLayout menu={drinkMenu} isLoading={isLoading} />
});


const KoreanMenu: React.FC<DisplayMenuProps> = memo(({restaurantId}) => {
  return <TabsLayout tabNames={["음식", "음료"]} tabContents={[<KoreanFoodMenu restaurantId={restaurantId} />, <KoreanDrinkMenu restaurantId={restaurantId} />]} />
});
const KoreanFoodMenu: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { foodMenu, isLoading } = useFetchCustomerKoreanFoodMenu(restaurantId)
  return <MenuLayout menu={foodMenu} isLoading={isLoading} />
});
const KoreanDrinkMenu: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { drinkMenu, isLoading } = useFetchCustomerKoreanDrinkMenu(restaurantId)
  return <MenuLayout menu={drinkMenu} isLoading={isLoading} />
});


const TabsLayout: React.FC<{tabNames: string[], tabContents: React.ReactNode[]}> = memo(({tabNames, tabContents}) => {
  return (
    <Tabs isLazy isFitted variant='soft-rounded' size='sm'>
      <TabList>
        {tabNames.map((tabName) => (
          <Tab key={tabName}>{tabName}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabContents.map((tabContent, index) => (
          <TabPanel key={index}>{tabContent}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
});


type Menu = {
  id: string;
  img: string;
}[]
const MenuLayout: React.FC<{menu?: Menu, isLoading: boolean}> = memo(({menu, isLoading}) => {
  if (isLoading || !menu) return <PartialCircularLoading />
  return (
    <VStack spacing={3}>
      {menu.map((menuItem) => (
        <Image key={menuItem.id} src={menuItem.img} w='100%' h='100%' />
      ))}
    </VStack>
  );
});