import React, { memo } from "react";
import { useDisclosure, IconButton } from '@chakra-ui/react'
import { DeleteIcon } from "@chakra-ui/icons";
import { useDeleteWithMutateKey } from "../../../hooks/useDeleteWithMutateKey";
import { DeleteModal } from "../../molecules/DeleteModal";

type DeleteModalProps = {
  id: string;
  restaurantName: string;
  mutateKey: string;
}

export const SoCouponSurveyQuestionDeleteModal: React.FC<DeleteModalProps> = memo(({id, mutateKey, restaurantName}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onSubmit = useDeleteWithMutateKey(`socoupon/survey-questions/${id}/`, onClose, mutateKey)
  return (
    <>
      <IconButton
        variant='ghost'
        colorScheme='red'
        aria-label='Delete'
        icon={<DeleteIcon />}
        size='xs'
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
      />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        deleteTarget={restaurantName}
        onSubmit={onSubmit}
      />
    </>
  );
});