import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customNoAuthAxios } from '../../utils/customNoAuthAxios';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';
import { useErrorToast } from '../toast/useErrorToast';


export const useEditCorporation = (userId?: string, corporationId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();

  const editUser = async (userData: any) => {
    return await customNoAuthAxios.put(`accounts/other-user/${userId}/`, userData);
  };

  const editCorporation = async (corporationData: any) => {
    return await customAuthAxios.put(`accounts/corporations/${corporationId}/`, corporationData);
  };

  const onSubmit = useCallback(async (data: any) => {
    try {
      await editUser(data.user);
      await editCorporation(data.corporation);
      showToast('変更が完了しました');
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.username) {
        showErrorToast(error.response.data.username);
      }
    }
  }, [navigate]);

  return onSubmit;
};