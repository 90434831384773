import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customNoAuthAxios } from '../../utils/customNoAuthAxios';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';
import { useErrorToast } from '../toast/useErrorToast';


export const useEditRestaurant = (userId: string, restaurantId: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();

  const editUser = async (userData: any) => {
    return await customNoAuthAxios.put(`accounts/other-user/${userId}/`, userData);
  };

  const editRestaurant = async (restaurantData: any) => {
    const uploadData = new FormData();
    const fields = ["name", "address", "manager_last_name", "manager_first_name", "manager_phone_number"];
    fields.forEach(field => uploadData.append(field, restaurantData[field]));
    restaurantData.logo[0] && uploadData.append("logo", restaurantData.logo[0], restaurantData.logo[0].name);
    uploadData.append("plan_id", restaurantData.plan);
    restaurantData.add_ons.length
    ? restaurantData.add_ons.forEach((addOnId: string) => uploadData.append("add_ons", addOnId))
    : uploadData.set("add_ons", JSON.stringify(null));
    return await customAuthAxios.put(`accounts/restaurants/${restaurantId}/`, uploadData);
  };

  const onSubmit = useCallback(async (data: any) => {
    try {
      await editUser(data.user);
      await editRestaurant(data.restaurant);
      showToast('変更が完了しました');
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.username) {
        showErrorToast(error.response.data.username);
      }
    }
  }, [navigate]);

  return onSubmit;
};