import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";

const soCouponSchema = yup.object().shape({
  title: yup.string().required('タイトルを入力してください'),
  explanation: yup.string().required('説明を入力してください'),
  notes: yup.string().required('注意事項を入力してください'),
  expiration_date: yup.string().required('有効期限を入力してください'),
  img: yup.mixed()
});

type SoCoupon = {
  id: string;
  title: string;
  explanation: string;
  notes: string;
  expiration_date: string;
  img: string;
}

export const useSoCouponEditForm = (soCoupon?: SoCoupon) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue, } = useForm({resolver: yupResolver(soCouponSchema),});

  useEffect(() => {
    if (soCoupon) {
      setValue('title', soCoupon.title);
      setValue('explanation', soCoupon.explanation);
      setValue('notes', soCoupon.notes);
      setValue('expiration_date', soCoupon.expiration_date);
    }
  }, [soCoupon]);

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
    setValue
  };
};