import React, { memo } from "react"
import { Badge, Box, CardBody, CardFooter, CardHeader, Divider } from "@chakra-ui/react";
import { useFetchMenuSettingByRestaurantId } from "../../../hooks/menu/useFetchMenuSettingByRestaurantId";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { PageTransitionBtn } from "../../atoms/button/PageTransitionBtn";
import { SettingItem } from "../../organisms/SettingItem";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";


export const MenuSetting: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { menuSetting, isLoading } = useFetchMenuSettingByRestaurantId(restaurantId)
  if (isLoading) return <CircularLoading />
  if (!menuSetting) return null
  const qrCardImg = menuSetting.qr_card_img ?? '/images/menu.png'
  return (
    <Box w={{base: '100%', sm: '100%', md: '600px'}} m='auto'>
      <DarkShadowCard>
        <CardHeader>
          <CardHeading icon={<SettingsIcon />} heading='メニュー設定'/>
        </CardHeader>
        <CardBody py={2}>
          <Box fontWeight='bold'>
            <SettingItem label='メニュータイプ' content={menuSetting.type === 'Airモバイルオーダー' ? 'モバイルオーダー' : menuSetting.type} />
            <Divider />
            <SettingItem
              label='初回アンケート'
              content={
                menuSetting.is_first_survey
                  ? <Badge colorScheme='green' fontSize='sm'>ON</Badge>
                  : <Badge fontSize='sm'>OFF</Badge>
              }
            />
            <Divider />
            <SettingItem
              label='QRカード'
              content={
                <Box
                  bgImage={`url(${qrCardImg})`}
                  w={{base: '280px', sm: '350px'}}
                  h={{base: '160px', sm: '200px'}}
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  border='0.1px solid #c0c0c09d'
                />
              }
            />
          </Box>
        </CardBody>
        <CardFooter>
          <PageTransitionBtn path='edit' text='編集' />
        </CardFooter>
      </DarkShadowCard>
    </Box>
  );
});