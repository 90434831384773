import React, { memo } from "react";
import { useRecoilValue } from "recoil";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { lineProfileState } from "../../../store/lineProfileState";
import { Grid, VStack } from "@chakra-ui/react";
import { BenefitCard } from "../../organisms/membership/BenefitCard";
import { useFetchBenefitsByLineId } from "../../../hooks/membership/useFetchBenefitsByLineId";
import { BenefitsTop } from "../../organisms/membership/BenefitsTop";
import { customerRestaurantId } from "../../../store/customerRestaurantId";


export const CustomerBenefits: React.FC = memo(() => {
  const lineProfile = useRecoilValue(lineProfileState)
  const restaurantId = useRecoilValue(customerRestaurantId)

  const { benefits, isLoading } = useFetchBenefitsByLineId(restaurantId!, lineProfile!.userId)
  if (isLoading) return <CircularLoading/>
  if (!benefits) return null

  return (
    <VStack spacing={5}>
      <BenefitsTop restaurantId={restaurantId!} lineId={lineProfile!.userId} displayName={lineProfile!.displayName} />
      <Grid templateColumns='repeat(2, 1fr)' gap='2vw' px='3.2vw' w='100%'>
        {benefits.map((benefit) => (
          <BenefitCard key={benefit.id} {...benefit} />
        ))}
      </Grid>
    </VStack>
  );
});
