import React from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";
import { SoCouponAccordionMenu } from "./SoCouponAccordionMenu";
import { KuchikomyuAccordionMenu } from "./KuchikomyuAccordionMenu";
import { ManzokudoLaboAccordionMenu } from "./ManzokudoLaboAccordionMenu";


type SurveyAccordionMenuProps = {
  addOns: string[];
}
export const SurveyAccordionMenu = ({ addOns }: SurveyAccordionMenuProps) => {
  const menuItems = [
    { conditions: ['即ーポン'], component: <SoCouponAccordionMenu /> },
    { conditions: ['口コミュ'], component: <KuchikomyuAccordionMenu /> },
    { conditions: ['満足度ラボ'], component: <ManzokudoLaboAccordionMenu /> },
  ];
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          アンケート
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          {menuItems.map((item, i) =>
            item.conditions.some(condition => addOns.includes(condition)) && (
              <React.Fragment key={i}>
                {item.component}
              </React.Fragment>
            )
          )}
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
};