import {
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'

export const UnregisteredQrAlert = () => {
  return (
    <Alert
      status='warning'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      height='200px'
    >
      <AlertIcon boxSize='40px' />
      <AlertTitle mt={4} mr={0} fontSize='lg'>
        モバイルオーダー用QRコードが<br/>登録されていません
      </AlertTitle>
    </Alert>
  );
};