import React from "react"
import { useParams } from "react-router-dom";
import { Badge, Box, CardBody, CardFooter, CardHeader, Divider, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { SettingItem } from "../../organisms/SettingItem";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchStaffSettingByRestaurantId } from "../../../hooks/restaurantStaff/useFetchStaffSettingByRestaurantId";
import { EditIconBtn } from "../../atoms/button/EditIconBtn";
import { useFetchRestaurant } from "../../../hooks/restaurants/useFetchRestaurant";


export const StaffSettingForRestaurant = () => {
  const { restaurantId } = useParams();
  const {staffSetting, isLoading} = useFetchStaffSettingByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />;
  const path = `${staffSetting?.id}/edit`
  const nameTagImg = staffSetting?.name_tag_img
  const settingItems = [
    {label: '店舗ブランチスコア', content: staffSetting?.restaurant_branch_score ?? '未登録'},
    {label: '店舗ブランチクエッション', content: staffSetting?.restaurant_branch_question_name ?? '未登録'},
    {label: '従業員ブランチスコア', content: staffSetting?.staff_branch_score ?? '未登録'},
    {label: '従業員ブランチクエッション', content: staffSetting?.staff_branch_question_name ?? '未登録'},
    {label: 'レビューへの遷移', content: staffSetting?.is_navigate_g_url ? <Badge colorScheme='green'>ON</Badge> : <Badge colorScheme='gray'>OFF</Badge>},
    {
      label: 'ネームタグ画像',
      content: nameTagImg ? (
        <NameTag restaurantId={restaurantId || ''} bgImg={nameTagImg} />
      ) : (
        <DefaultNameTag restaurantId={restaurantId || ''} />
      ),
    },
  ];
  return (
    <DarkShadowCard w='100%'>
      <CardHeader pb={0}>
        <CardHeading icon={<SettingsIcon />} heading='従業員設定'/>
      </CardHeader>
      <CardBody>
        <VStack align='start' fontSize='sm' fontWeight='bold' spacing={4} w='100%'>
          <Divider my={0} />
          {settingItems.map((detail) => (
            <React.Fragment key={detail.label}>
              <Box w='100%'>
                <SettingItem label={detail.label} content={detail.content}/>
              </Box>
              <Divider my={0} />
            </React.Fragment>
          ))}
        </VStack>
      </CardBody>
      <CardFooter pt={0}>
        <EditIconBtn path={path} />
      </CardFooter>
    </DarkShadowCard>
  );
};


const NameTag = ({restaurantId, bgImg}: {restaurantId: string, bgImg: string}) => {
  const { restaurant, isLoading } = useFetchRestaurant(restaurantId)
  if (isLoading || !restaurant) return null
  const { name, logo } = restaurant
  return (
    <Box w='360px' h='200px' p={2} bgImage={`url(${bgImg})`} bgSize="cover" bgPosition="center" bgRepeat="no-repeat" border='1px solid #c0c0c09d'>
      <VStack h='100%' spacing={3}>
        <HStack justify='space-between' w='100%'>
          <Text fontSize='18px' fontWeight='bold' m={0}>
            {name}
          </Text>
          {logo && <Image src={logo} boxSize='50px' />}
        </HStack>
        <HStack w='100%' h='100%' p={4} justify='space-between'>
          <Box flex={1} >
            <Text fontSize='34px' fontWeight='bold' textAlign='center' m={0} lineHeight='1.2'>
              やまちゃん
            </Text>
          </Box>
          <Box boxSize='90px' border='1px solid #c0c0c09d' />
        </HStack>
      </VStack>
    </Box>
  )
};


const DefaultNameTag = ({restaurantId}: {restaurantId: string}) => {
  const { restaurant, isLoading } = useFetchRestaurant(restaurantId)
  if (isLoading || !restaurant) return null
  const { name, logo } = restaurant
  return (
    <Box w='360px' h='200px' bg='orange.300' p={2}>
      <VStack h='100%' spacing={3}>
        <HStack justify='space-between' w='100%'>
          <Text fontSize='18px' fontWeight='bold' m={0}>
            {name}
          </Text>
          {logo && <Image src={logo} boxSize='50px' />}
        </HStack>
        <HStack w='100%' h='100%' bg='white' p={4} justify='space-between'>
          <Box flex={1} >
            <Text fontSize='34px' fontWeight='bold' textAlign='center' m={0} lineHeight='1.2'>
              やまちゃん
            </Text>
          </Box>
          <Box boxSize='90px' border='1px solid #c0c0c09d' />
        </HStack>
      </VStack>
    </Box>
  )
};