import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from "@chakra-ui/react";

type ManagerPhoneNumberInputFieldProps = {
  register: any;
  errors: any;
}
export const ManagerPhoneNumberInputField: React.FC<ManagerPhoneNumberInputFieldProps> = memo(({register, errors}) => {
  const managerPhoneNumberError = errors.restaurant?.manager_phone_number?.message;
  return (
    <FormControl isRequired isInvalid={errors.restaurant?.manager_phone_number} w='100%'>
      <FormLabel>代表者電話番号</FormLabel>
      <Input type="tel" minLength={10} maxLength={11} pattern="[\d]*" {...register('restaurant.manager_phone_number')} />
      <FormHelperText>ハイフンなし</FormHelperText>
      <FormErrorMessage>{managerPhoneNumberError}</FormErrorMessage>
    </FormControl>
  );
});