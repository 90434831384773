import { memo } from 'react';
import { Grid, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { CircularLoading } from '../../molecules/loading/CircularLoading';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DateRangeFilter } from '../../molecules/DateRangeFilter';
import { SmallCardWithIcon } from '../../molecules/card/SmallCardWithIcon';
import { RiSurveyLine, RiTeamFill } from 'react-icons/ri';
import { useFetchTotalStaff } from '../../../hooks/restaurantStaff/useFetchTotalStaff';
import { useFetchTotalStaffSurveyResponse } from '../../../hooks/restaurantStaff/useFetchTotalStaffSurveyResponse';
import { useFetchTotalRestaurantSurveyResponse } from '../../../hooks/restaurantStaff/useFetchTotalRestaurantSurveyResponse';
import { RestaurantDashboardTab } from '../../organisms/restaurantStaff/RestaurantDashboardTab';
import { StaffDashboardTab } from '../../organisms/restaurantStaff/StaffDashboardTab';


export const RestaurantStaffDashboard = () => {
  const [searchParams] = useSearchParams();
  const since = searchParams.get('since');
  const until = searchParams.get('until');
  const queryParams = buildQueryParams(since, until);
  const { restaurantId } = useParams()
  if (!restaurantId) return <CircularLoading />
  return (
    <VStack w='100%' spacing={6}>
      <DateRangeFilter/>
      <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'}} gap={3} w='100%'>
        <TotalResponses restaurantId={restaurantId} queryParams={queryParams} />
        <TotalStaff restaurantId={restaurantId} />
      </Grid>
      <StatusTabs />
    </VStack>
  );
};


const buildQueryParams = (since: string|null, until: string|null) => {
  const queryParams = new URLSearchParams();
  since && queryParams.set('since', since);
  until && queryParams.set('until', until);
  return queryParams;
};


const TotalResponses = memo(({restaurantId, queryParams}:{restaurantId: string, queryParams: URLSearchParams}) => {
  const { totalRestaurantSurveyResponse, isLoading: isLoadingRestaurantSurveyResponse } = useFetchTotalRestaurantSurveyResponse(restaurantId, queryParams);
  const { totalStaffSurveyResponse, isLoading: isLoadingStaffSurveyResponse } = useFetchTotalStaffSurveyResponse(restaurantId, queryParams);
  const totalResponses = (totalRestaurantSurveyResponse || 0) + (totalStaffSurveyResponse || 0);
  return (
    <Skeleton isLoaded={!isLoadingRestaurantSurveyResponse && !isLoadingStaffSurveyResponse} rounded='2xl'>
      <SmallCardWithIcon data={`${totalResponses}件`} title='アンケート総取得数'>
        <RiSurveyLine size='30px'  />
      </SmallCardWithIcon>
    </Skeleton>
  );
});


const TotalStaff = memo(({restaurantId}:{restaurantId: string}) => {
  const { totalStaff, isLoading } = useFetchTotalStaff(restaurantId);
  return (
    <Skeleton isLoaded={!isLoading} rounded='2xl'>
      <SmallCardWithIcon data={`${totalStaff}人`} title='従業員数'>
        <RiTeamFill size='30px'  />
      </SmallCardWithIcon>
    </Skeleton>
  );
});


const StatusTabs = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams({ tab: "" });
  const currentTab = searchParams.get("tab") || "restaurant";
  const handleTabChange = (index: number) => {
    const tab = index === 0 ? "restaurant" : "staff";
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("tab", tab);
    navigate({ search: newSearchParams.toString() });
  };
  const currentIndex = currentTab === "staff" ? 1 : 0;
  return (
    <Tabs variant='soft-rounded' colorScheme='messenger' isLazy onChange={handleTabChange} index={currentIndex} w='100%'>
      <TabList>
        <Tab>店舗</Tab>
        <Tab>従業員</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <RestaurantDashboardTab/>
        </TabPanel>
        <TabPanel px={0}>
          <StaffDashboardTab/>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};