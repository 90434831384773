import { Box, CardBody, CardFooter, CardHeader, VStack } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CardHeading } from "../../molecules/card/CardHeading";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { FaPeopleGroup } from "react-icons/fa6";


type FormComponent = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
type DispatchRequestFormProps = {
  cardTitle: string;
  formComponents: FormComponent[];
  onSubmit: () => void;
  isSubmitting: boolean;
}
export const DispatchRequestForm = ({ cardTitle, formComponents, onSubmit, isSubmitting }: DispatchRequestFormProps) => {
  return (
    <Box m='auto' w='650px' maxW='100%'>
      <form onSubmit={onSubmit} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<FaPeopleGroup />} heading={cardTitle}/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={5}>
              {formComponents.map(({ component: Component, props }, index) => <Component key={index} {...props} />)}
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>依頼する</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};