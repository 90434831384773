import useSWR from "swr";


export type BenchmarkRestaurant = {
  id: string;
  registrant: string;
  name: string;
  url: string;
}
export const useFetchBenchmarkRestaurant = (benchmarkRestaurantId?: string) => {
  const fetchKey = benchmarkRestaurantId ? `ifrea_analytics/benchmark-restaurants/${benchmarkRestaurantId}/` : null;
  const { data: benchmarkRestaurant, error, isLoading, mutate } = useSWR<BenchmarkRestaurant>(fetchKey)
  return { benchmarkRestaurant, isLoading, error, mutate };
};