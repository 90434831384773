import React, { memo } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { lineAccountIdState } from "../../../store/fetchedData/lineAccountIdState";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useSwrNoAuth } from "../../../hooks/swr/useSwrNoAuth";
import { Box } from "@chakra-ui/react";
import { usePostOverallScoreForm } from "../../../hooks/usePostOverallScoreForm";
import { useSubmitSoCouponCustomerOverallScore } from "../../../hooks/socoupon/useSubmitSoCouponCustomerOverallScore";
import { PostOverallScoreForm } from "../../molecules/form/PostOverallScoreForm";


type PostSoCouponOverallScoreProps = {
  restaurantId: string;
}
type SoCouponSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  socoupon_in_use: string;
  socoupon_title: string;
}
export const PostSoCouponOverallScore: React.FC<PostSoCouponOverallScoreProps> = memo(({restaurantId}) => {
  const lineAccountId = useRecoilValue(lineAccountIdState)
  const { data: overallScore, isLoading: isLoadingOverallScore } = useSwrNoAuth(`socoupon/customers/${lineAccountId}/overall-score/`);
  const { data: soCouponSetting, isLoading: isLoadingSoCouponSetting } = useSwrNoAuth<SoCouponSetting>(`socoupon/restaurants/${restaurantId}/setting/customer/`);
  if (isLoadingOverallScore || isLoadingSoCouponSetting || !soCouponSetting) return <CircularLoading />
  if (overallScore) return <Navigate replace to={`/customers/${lineAccountId}/socoupon`}/>;
  return (
    <Box p={4}>
      <ScorePost lineAccountId={lineAccountId!} restaurantId={restaurantId!} branchScore={soCouponSetting.branch_score} gUrl={soCouponSetting.g_url} />
    </Box>
  );
});

const ScorePost: React.FC<{lineAccountId: string, restaurantId: string, branchScore: number, gUrl: string}> = memo(({lineAccountId, restaurantId, branchScore, gUrl}) => {
  const { handleSubmit, control, isSubmitting, errors } = usePostOverallScoreForm()
  const onSubmit = useSubmitSoCouponCustomerOverallScore(lineAccountId, restaurantId, branchScore, gUrl);
  return <PostOverallScoreForm onSubmit={handleSubmit(onSubmit)} control={control} isSubmitting={isSubmitting} errors={errors}/>
});