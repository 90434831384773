import React from "react";
import Header from "../Header";
import { RestaurantSidebarMenu } from "../sidebar/RestaurantSidebarMenu";
import { RestaurantName } from "../../atoms/RestaurantName";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Text,
  Box,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { Link } from "react-router-dom";
import { useFetchUnreadNotices } from "../../../hooks/notice/useFetchUnreadNotices";
import { IoNotificationsOutline } from "react-icons/io5";


type RestaurantHeaderProps = {
  restaurantId: string;
};
const RestaurantHeader: React.FC<RestaurantHeaderProps> = ({restaurantId}) => {
  return (
    <Header
      name={<RestaurantName restaurantId={restaurantId}/>}
      sideBarMenu={<RestaurantSidebarMenu restaurantId={restaurantId}/>}
      navItems={[
        <NoticeMenu restaurantId={restaurantId} />
      ]}
    />
  );
};
export default RestaurantHeader;


const NoticeMenu = ({restaurantId}: {restaurantId: string}) => {
  const { notices, isLoading } = useFetchUnreadNotices(restaurantId)
  if (isLoading || !notices) return null;
  const isUnread = notices.length > 0
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='Notifications'
        icon={
          isUnread ? (
            <Box position="relative">
              <IoNotificationsOutline size='20px' />
              <Box
                position="absolute"
                top="-1px"
                right="-1px"
                boxSize='6px'
                borderRadius="50%"
                backgroundColor="#34C658"
              />
            </Box>
          ) : (
            <IoNotificationsOutline size='20px' />
          )
        }
        size='sm'
        colorScheme="whiteAlpha"
        color={isUnread ? 'white' : 'white'}
        variant='ghost'
      />
      <MenuList maxW='320px' maxH='450px' overflowY='auto'>
        <MenuGroup title={`未読（${notices.length}件）`}>
          <UnreadNotices notices={notices} />
        </MenuGroup>
        <MenuDivider />
        <MenuItem as={Link} to={'notices'}>
          <Text m={0} fontSize='sm' color='black'>
            すべて見る
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  )
};


type Notices = {
  id: string;
  title: string;
  content: string;
  created_at: string;
}[];
const UnreadNotices = ({notices}: {notices: Notices}) => {
  return (
    <>
      {notices.map((notice) => {
        return (
          <MenuItem key={notice.id} as={Link} to={`notices/${notice.id}`}>
            <Box>
              <Text m={0} fontSize='sm' noOfLines={1} color='black'>
                {notice.title}
              </Text>
              <Text m={0} fontSize='xs' color='gray.400'>
                {new Date(notice.created_at).toLocaleDateString()}
              </Text>
            </Box>
          </MenuItem>
        )
      })}
    </>
  )
};