import React, { memo } from "react";
import { FormHelperText } from "@chakra-ui/react";
import { useWatch } from "react-hook-form";
import { calcTotalTargetAccountCount } from "../../../../utils/calcTotalTargetAccountCount";


type LineAccountCounts = {
  visit_count: number;
  count: number;
}[]
type TargetAccountCountProps = {
  control: any;
  lineAccountCounts: LineAccountCounts;
}
export const TargetAccountCount: React.FC<TargetAccountCountProps> = memo(({ control, lineAccountCounts }) => {
  const selectedVisitCounts = useWatch({ name: 'visitCounts', control }) || [];
  const totalTargetAccountCount = calcTotalTargetAccountCount(selectedVisitCounts, lineAccountCounts)
  return <FormHelperText>対象アカウント数：{totalTargetAccountCount}</FormHelperText>
});