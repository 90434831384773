import { memo } from "react"
import { useFetchKuchikomyuSurveySelectedQuestionsByRestaurantId } from "../../../hooks/kuchikomyu/useFetchKuchikomyuSurveySelectedQuestionsByRestaurantId";
import { useFieldArray, useForm } from "react-hook-form";
import { Box, CardBody, CardFooter, CardHeader, Flex, IconButton, VStack } from "@chakra-ui/react";
import { useInitializeSelectedQuestions } from "../../../hooks/useInitializeSelectedQuestions";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchKuchikomyuSurveyQuestionsByRestaurantId } from "../../../hooks/kuchikomyu/useFetchKuchikomyuSurveyQuestionsByRestaurantId";
import { useSubmitKuchikomyuSurveySelectedQuestionsEdit } from "../../../hooks/kuchikomyu/useSubmitKuchikomyuSurveySelectedQuestionsEdit";
import { useParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { RiSurveyLine } from "react-icons/ri";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { SurveyQuestionSelect } from "../../molecules/form/SurveyQuestionSelect";


export const KuchikomyuSurveySelectedQuestionsEdit: React.FC = memo(() => {
  const { restaurantId } = useParams()
  const { selectedQuestions, isLoading } = useFetchKuchikomyuSurveySelectedQuestionsByRestaurantId(restaurantId)
  const { handleSubmit, register, formState: { isSubmitting }, control, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({ control, name: "questions" });
  const onSubmit = useSubmitKuchikomyuSurveySelectedQuestionsEdit(restaurantId)
  useInitializeSelectedQuestions(setValue, selectedQuestions)
  if (isLoading) return <CircularLoading />
  return (
    <Box m='auto' w={{ base: '100%', md: '80%', lg: '600px' }}>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<RiSurveyLine/>} heading='使用中アンケート'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={4}>
              <VStack spacing={4} w='100%'>
                {fields.map((item, index) => (
                  <Flex key={item.id} align={{base: 'start', sm: 'center'}} direction={{base: 'column', sm: 'row'}} justify='space-between' gap={{base: 0, sm: 2}} w='100%'>
                    <QuestionSelect register={register} restaurantId={restaurantId} index={index} control={control} />
                    <IconButton aria-label='delete' icon={<DeleteIcon/>} colorScheme='red' size='sm' variant='ghost' onClick={() => remove(index)}/>
                  </Flex>
                ))}
              </VStack>
              <Box textAlign="right" w='100%'>
                <IconButton aria-label='add' icon={<AddIcon/>} colorScheme='green' size='sm' variant='outline' onClick={() => append({})}/>
              </Box>
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});


type QuestionSelectProps = {
  register: any;
  restaurantId?: string;
  index: number;
  control: any
}
export const QuestionSelect: React.FC<QuestionSelectProps> = memo(({register, restaurantId, index, control}) => {
  const { surveyQuestions, isLoading } = useFetchKuchikomyuSurveyQuestionsByRestaurantId(restaurantId)
  return <SurveyQuestionSelect register={register} index={index} control={control} isLoading={isLoading} surveyQuestions={surveyQuestions}/>
});