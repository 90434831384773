import React from 'react';
import { Flex, HStack, Link, Text } from '@chakra-ui/react';


const Footer: React.FC = () => {
  return (
    <Flex
      align='center'
      justify='space-between'
    >
      <Text m='0'>
        Copyright © BLOCKs
      </Text>
      <HStack spacing={{base: 2, sm: 4, lg: 4}}>
        <Link href='https://blocks-agreemen.ifreagroup.co.jp' isExternal _hover={{ textDecoration: 'none', color: 'white' }}>
          利用規約
        </Link>
        <Link href='https://blocks-privacypolicy.ifreagroup.co.jp	' isExternal _hover={{ textDecoration: 'none', color: 'white' }}>
          プライバシーポリシー
        </Link>
      </HStack>
    </Flex>
  );
};
export default Footer;