import React from 'react'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from '@chakra-ui/react'

type UsernameInputProps = {
  register: any;
  errors: any;
}
export const SignUpUsernameInput: React.FC<UsernameInputProps> = ({register, errors}) => {
  return (
    <FormControl isRequired isInvalid={!!errors.username}>
      <FormLabel>ユーザーネーム</FormLabel>
      <Input placeholder="blocks-user" type="input" {...register('username')} />
      <FormHelperText>半角アルファベット、半角数字、@/./+/-/_ で150文字以下。</FormHelperText>
      <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
    </FormControl>
  );
};