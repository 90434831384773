import useSWR from "swr";


type Teams = {
  id: string;
  name: string;
  email: string;
  dispatch_count: number;
}[]
export const useFetchKuitanTeams = () => {
  const { data: teams, error, isLoading } = useSWR<Teams>('kuitan/teams/')
  return { teams, isLoading, error };
};