import React, { memo } from "react";
import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../card/DarkShadowCard";
import { CardHeading } from "../card/CardHeading";
import { MdOutlinePassword } from "react-icons/md";


type EditPasswordFormProps = {
  handleSubmit: any
  isSubmitting: boolean
  register: any
  errors: any
}
export const EditPasswordForm: React.FC<EditPasswordFormProps> = memo(({handleSubmit, isSubmitting, register, errors}) => {
  return (
    <Box m='auto' w={{base: '100%', md: '500px'}}>
      <form onSubmit={handleSubmit}>
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<MdOutlinePassword />} heading='パスワード変更'/>
          </CardHeader>
          <CardBody py={2}>
            <FormControl isRequired isInvalid={!!errors.new_password} w='100%'>
              <FormLabel>新しいパスワード</FormLabel>
              <Input type="text" {...register('new_password')} />
              <FormHelperText>4文字以上</FormHelperText>
              <FormErrorMessage>{errors.new_password?.message}</FormErrorMessage>
            </FormControl>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});