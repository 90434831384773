import { memo } from "react"
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { Box, Card, CardBody, HStack, IconButton, VStack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchSoCoupon } from "../../../hooks/socoupon/useFetchSoCoupon";
import { CouponLayout } from "../../molecules/CouponLayout";
import { EditIcon } from "@chakra-ui/icons";
import { SoCouponDeleteModal } from "../../organisms/soCoupon/SoCouponDeleteModal";
import { useFetchSoCouponSettingByRestaurantId } from "../../../hooks/socoupon/useFetchSoCouponSettingByRestaurantId";


export const SoCoupon: React.FC = memo(() => {
  const { soCouponId } = useParams();
  const { soCoupon, isLoading } = useFetchSoCoupon(soCouponId)
  if (isLoading || !soCoupon) return <CircularLoading />
  return (
    <VStack m='auto' w={{ base: '100%', sm: '400px' }}>
      <Box w='100%'>
        <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.08) 1px 3.5px 5.5px'>
          <CardBody>
            <CouponLayout {...soCoupon} />
          </CardBody>
        </Card>
      </Box>
      <EditAndDeleteIcons id={soCoupon.id} title={soCoupon.title} />
    </VStack>
  );
});

const EditAndDeleteIcons: React.FC<{ id: string, title: string }> = memo(({ id, title }) => {
  const navigate = useNavigate()
  const { restaurantId } = useParams()
  const { soCouponSetting, isLoading } = useFetchSoCouponSettingByRestaurantId(restaurantId)
  if (isLoading || !soCouponSetting) return null
  return (
    <HStack justify='center' spacing={1} w='100%'>
      <IconButton
        colorScheme='messenger'
        aria-label='Edit SoCoupon'
        icon={<EditIcon />}
        size='sm'
        variant='ghost'
        onClick={() => navigate('edit')}
      />
      {soCouponSetting.socoupon_in_use !== id && <SoCouponDeleteModal id={id} title={title} />}
    </HStack>
  )
})