import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type RankCard = {
  id: string;
  img: string;
}

export const useFetchCustomerRankCard = (restaurantId: string) => {
  const { data: rankCard, error, isLoading: isLoadingRankCard } = useSwrNoAuth<RankCard>(`membership/restaurants/${restaurantId}/customer-rank-card/`)
  return { rankCard, isLoadingRankCard, error };
};