import React, { memo } from "react"
import QRCode from "qrcode.react";
import { Box, Grid, Flex } from '@chakra-ui/react'
import { useDownloadQrCords } from "../../../../hooks/useDownloadQrCard";
import { useFetchMenuSettingByRestaurantId } from "../../../../hooks/menu/useFetchMenuSettingByRestaurantId";
import { QrCardsDownloadModal } from "../../../molecules/QrCardsDownloadModal";
import { useParams } from "react-router-dom";

const frontUrl = process.env.REACT_APP_FRONT_URL

type Tables = {
  id: string;
  table_name: string;
  qr_code: string;
  in_use: boolean;
  auth_code: number;
}[]
export const TableQrCardModal: React.FC<{tables:Tables}> = memo(({tables}) => {
  const { modalRef, handleDownload } = useDownloadQrCords();
  const { restaurantId } = useParams()
  const { menuSetting, isLoading } = useFetchMenuSettingByRestaurantId(restaurantId)
  if (!restaurantId || !menuSetting || isLoading) return null
  const qrCardImg = menuSetting.qr_card_img ?? '/images/menu.png'
  return (
    <QrCardsDownloadModal handleDownload={handleDownload}>
      <Grid ref={modalRef} gap={1} templateColumns='repeat(2, 1fr)' templateRows='repeat(5, 1fr)'>
        {tables?.map((table) => (
          <QrCard
            key={table.id}
            url={`${frontUrl}check-table-security/${restaurantId}/${table.id}/`}
            qrCardImg={qrCardImg}
            tableName={table.table_name}
          />
        ))}
      </Grid>
    </QrCardsDownloadModal>
  );
});


const QrCard: React.FC<{url: string, qrCardImg: string, tableName: string}> = ({url, qrCardImg, tableName}) => {
  return (
    <Box w='420px' h='234px' bgImage={qrCardImg} bgSize="contain" bgRepeat="no-repeat" position='relative'>
      <Box w='52%'>
        <Flex justify='center' >
          <canvas ref={(canvas) => {
            if (canvas) {
              const ctx = canvas.getContext('2d');
              if (ctx) {
                ctx.font = 'bold 20px sans-serif';
                const textWidth = ctx.measureText(tableName).width;
                canvas.width = textWidth;
                canvas.height = 60;
                ctx.fillStyle = 'white';
                ctx.font = 'bold 20px sans-serif';
                ctx.fillText(tableName, 0, 36);
              }
            }
          }} />
        </Flex>
      </Box>
      <Box
        as={QRCode}
        value={url}
        sx={{
          width: '115px!important',
          height: '115px!important',
          objectFit: 'contain',
          position: 'absolute',
          top: '91px',
          left: '49px',
        }}
      />
    </Box>
  );
};

