import useSWR from "swr";

type RankSettings = {
  id: string;
  name: string;
  min_visits: number;
  max_visits: number;
}[]

export const useFetchRankSettings = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `membership/restaurants/${restaurantId}/rank-settings/` : null;
  const { data: rankSettings, error, isLoading } = useSWR<RankSettings>(fetcherKey)
  return { rankSettings, isLoading, error };
};