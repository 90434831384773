import { WarningTwoIcon } from "@chakra-ui/icons";
import { HStack, Image, Text, VStack } from "@chakra-ui/react";


export const BadRequest = () => {
  return (
    <VStack spacing={5} h='100vh' justify='center'>
      <Image src='/images/blocks.png' alt='500' boxSize='200px' />
      <HStack align='center' fontSize='24px' justify='center' spacing={2}>
        <WarningTwoIcon boxSize='28px' color='#FFC007' />
        <Text m='0' fontWeight='bold'>操作が正しくありません（400）</Text>
      </HStack>
      <Text m='0' textAlign='center'>
        操作が正しくありませんでした。
        <br/>
        以下のいずれかの理由が考えられます。
        <br/>
        <br/>
        行った操作に誤りがあるなど
        <br/>
        <br/>
        ブラウザを閉じて操作をやり直してください。
      </Text>
    </VStack>
  );
};