import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const editSoCouponSettingBySalesmanSchema = yup.object().shape({
  branch_score: yup.number().required('ブランチスコアを入力してください'),
  g_url: yup.string().required('Urlを入力してください'),
  remind: yup.boolean(),
});
type SoCouponSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  remind: boolean;
}
export const useEditSoCouponSettingFormForSalesman = (soCouponSetting?: SoCouponSetting) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(editSoCouponSettingBySalesmanSchema),});
  useEffect(() => {
    if (soCouponSetting) {
      setValue('branch_score', soCouponSetting.branch_score);
      setValue('g_url', soCouponSetting.g_url);
      setValue('remind', soCouponSetting.remind);
    }
  }, [soCouponSetting]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};