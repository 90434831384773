import { Box, Button, CardBody, CardFooter, CardHeader, Flex, IconButton, Input, InputGroup, InputRightElement, VStack } from "@chakra-ui/react";
import { TableLayout } from "../../template/table/TableLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { FaRegBuilding } from "react-icons/fa";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { useFetchPaginatedCorporations } from "../../../hooks/corporations/useFetchPaginatedCorporations";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { AddIcon } from "@chakra-ui/icons";
import { Link } from 'react-router-dom';
import { PartialCircularLoading } from "../../molecules/loading/PartialCircularLoading";
import { useState } from "react";


export const Corporations = () => {
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const corporationName = searchParams.get('name');
  const pageSize = 10;
  const queryParams = buildQueryParams(pageSize, page, corporationName);
  const { paginatedCorporations, isLoading } = useFetchPaginatedCorporations(queryParams)
  return (
    <DarkShadowCard>
      <CardHeader>
        <Flex align='center' justify='space-between'>
          <CardHeading icon={<FaRegBuilding />} heading='法人'/>
          <IconButton
            as={Link}
            variant='outline'
            colorScheme='messenger'
            aria-label='register'
            icon={<AddIcon />}
            size='xs'
            to='register'
          />
        </Flex>
      </CardHeader>
      <CardBody py={2}>
        <VStack align='start'>
          <NameSearch />
          <Box w='100%'>
            <CorporationsTable isLoading={isLoading} corporations={paginatedCorporations?.corporations} />
          </Box>
        </VStack>
      </CardBody>
      <CardFooter>
        <Pagination totalItems={paginatedCorporations?.count} pageSize={pageSize}/>
      </CardFooter>
    </DarkShadowCard>
  );
};


const buildQueryParams = (pageSize: number, page: number, corporationName: string|null) => {
  const queryParams = new URLSearchParams();
  queryParams.set('pageSize', pageSize.toString());
  queryParams.set('currentPage', page.toString());
  corporationName && queryParams.set('name', corporationName);
  return queryParams;
};

const NameSearch = () => {
  const [corporationName, setCorporationName] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onClickSearch = () => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('name', corporationName);
    navigate(`?${newSearchParams.toString()}`);
  }
  const onClickClear = () => {
    setCorporationName('');
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('name');
    navigate(`?${newSearchParams.toString()}`);
  }
  return (
    <Box>
      <InputGroup size='sm'>
        <Input
          placeholder="法人名で検索"
          onChange={(e) => {setCorporationName(e.target.value);}}
          rounded='md'
          onKeyDown={(e) => {if (e.key === 'Enter' && corporationName) onClickSearch();}}
          value={corporationName}
        />
        <InputRightElement>
          <Button size='xs' rounded='full' variant='ghost' onClick={onClickClear}>
            ✗
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}

const CorporationsTable = ({isLoading, corporations}: {isLoading: boolean, corporations?: {id: string, name: string, username: string, president_last_name: string, president_first_name: string, president_phone_number: string}[]}) => {
  if (isLoading || !corporations) return <PartialCircularLoading />
  const headers = ['店舗名', 'ユーザーネーム', '代表者名', '代表者電話番号'];
  const rows = corporations.map((corporation) => (
    <TableRowWithNavigationLayout
      key={corporation.id}
      path={`${corporation.id}`}
      childrenList={[
        corporation.name,
        corporation.username,
        corporation.president_last_name + corporation.president_first_name,
        corporation.president_phone_number,
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />
}