import { Route } from 'react-router-dom';
import { SalesmanLinking } from '../components/pages/ifreaAnalytics/SalesmanLinking';
import { RegisterSalesmanLinking } from '../components/pages/ifreaAnalytics/RegisterSalesmanLinking';
import { EditSalesmanLinking } from '../components/pages/ifreaAnalytics/EditSalesmanLinking';
import { BenchmarkRestaurants } from '../components/pages/ifreaAnalytics/BenchmarkRestaurants';
import { EditBenchmarkRestaurant } from '../components/pages/ifreaAnalytics/EditBenchmarkRestaurant';
import { RegisterBenchmarkRestaurant } from '../components/pages/ifreaAnalytics/RegisterBenchmarkRestaurant';
import { TabelogScores } from '../components/pages/ifreaAnalytics/TabelogScores';
import { TabelogScore } from '../components/pages/ifreaAnalytics/TabelogScore';


export const TabelogScoreRoutes = (
  <Route path='tabelog-scores'>
    <Route path="" element={<TabelogScores />} />
    <Route path=":benchmarkRestaurantId" element={<TabelogScore />} />
    <Route path="salesman-linking" element={<SalesmanLinking />} />
    <Route path="salesman-linking/register" element={<RegisterSalesmanLinking />} />
    <Route path="salesman-linking/:salesmanLinkingId/edit" element={<EditSalesmanLinking />} />
    <Route path="benchmark-restaurants" element={<BenchmarkRestaurants />} />
    <Route path="benchmark-restaurants/register" element={<RegisterBenchmarkRestaurant />} />
    <Route path="benchmark-restaurants/:benchmarkRestaurantId/edit" element={<EditBenchmarkRestaurant />} />
  </Route>
);