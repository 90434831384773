import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const benefitSchema = yup.object().shape({
  title: yup.string().required('タイトルを入力してください'),
  explanation: yup.string().required('説明を入力してください'),
  notes: yup.string().required('注意事項を入力してください'),
  ranks: yup.array().of(yup.string()).min(1, '入力必須項目です').required('入力必須項目です'),
  img: yup.mixed()
});

export const useBenefitRegisterForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue, control } = useForm({ resolver: yupResolver(benefitSchema) });
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
    setValue
  };
};