import { useSwrNoAuth } from "../../swr/useSwrNoAuth";

type Replies = {
  id: string;
  review_id: string;
  reviewer_image_url: string;
  reviewer_name: string;
  review_date: string;
  rating: number;
  comment: string;
  reply: string;
}[]
export const useFetchGoogleReviewReplies = (restaurantId?: string, queryParams?: string) => {
  const fetcherKey = restaurantId ? `/external_api/restaurants/${restaurantId}/google/reviews/replies/${queryParams}` : null;
  const { data: replies, error, isLoading } = useSwrNoAuth<Replies>(fetcherKey);
  return { replies, isLoading, error };
};