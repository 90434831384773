import { TableLayout } from "../../template/table/TableLayout";
import { Box, Button, CardBody, CardFooter, CardHeader, Flex, IconButton, Input, InputGroup, InputRightElement, VStack } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { CiShop } from "react-icons/ci";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { useFetchPaginatedRestaurants } from "../../../hooks/restaurants/useFetchPaginatedRestaurants";
import { Link } from 'react-router-dom';
import { AddIcon } from "@chakra-ui/icons";
import { PartialCircularLoading } from "../../molecules/loading/PartialCircularLoading";
import { useState } from "react";


export const Restaurants = () => {
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const restaurantName = searchParams.get('name');
  const pageSize = 10;
  const queryParams = buildQueryParams(pageSize, page, restaurantName);
  const { paginatedRestaurants, isLoading } = useFetchPaginatedRestaurants(queryParams)
  return (
    <DarkShadowCard>
      <CardHeader>
        <Flex align='center' justify='space-between'>
          <CardHeading icon={<CiShop />} heading='店舗'/>
          <IconButton
            as={Link}
            variant='outline'
            colorScheme='messenger'
            aria-label='register'
            icon={<AddIcon />}
            size='xs'
            to='register'
          />
        </Flex>
      </CardHeader>
      <CardBody py={2}>
        <VStack align='start'>
          <NameSearch />
          <Box w='100%'>
            <RestaurantsTable isLoading={isLoading} restaurants={paginatedRestaurants?.restaurants} />
          </Box>
        </VStack>
      </CardBody>
      <CardFooter>
        <Pagination totalItems={paginatedRestaurants?.count} pageSize={pageSize}/>
      </CardFooter>
    </DarkShadowCard>
  );
};


const buildQueryParams = (pageSize: number, page: number, restaurantName: string|null) => {
  const queryParams = new URLSearchParams();
  queryParams.set('pageSize', pageSize.toString());
  queryParams.set('currentPage', page.toString());
  restaurantName && queryParams.set('name', restaurantName);
  return queryParams;
};

const NameSearch = () => {
  const [restaurantName, setRestaurantName] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onClickSearch = () => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('name', restaurantName);
    navigate(`?${newSearchParams.toString()}`);
  }
  const onClickClear = () => {
    setRestaurantName('');
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('name');
    navigate(`?${newSearchParams.toString()}`);
  }
  return (
    <Box>
      <InputGroup size='sm'>
        <Input
          placeholder="店舗名で検索"
          onChange={(e) => {setRestaurantName(e.target.value);}}
          rounded='md'
          onKeyDown={(e) => {if (e.key === 'Enter' && restaurantName) onClickSearch();}}
          value={restaurantName}
        />
        <InputRightElement>
          <Button size='xs' rounded='full' variant='ghost' onClick={onClickClear}>
            ✗
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}

const RestaurantsTable = ({isLoading, restaurants}: {isLoading: boolean, restaurants?: {id: string, name: string, username: string, manager_last_name: string, manager_first_name: string, manager_phone_number: string, corporation_name: string}[]}) => {
  if (isLoading || !restaurants) return <PartialCircularLoading />
  const headers = ['店舗名', 'ユーザーネーム', '代表者名', '代表者電話番号', '法人'];
  const rows = restaurants.map((restaurant) => (
    <TableRowWithNavigationLayout
      key={restaurant.id}
      path={`${restaurant.id}`}
      childrenList={[
        restaurant.name,
        restaurant.username,
        restaurant.manager_last_name + restaurant.manager_first_name,
        restaurant.manager_phone_number,
        restaurant.corporation_name
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} size='sm' />
}