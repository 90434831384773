import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useFetchRestaurant } from "./useFetchRestaurant";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const editRestaurantSchema = yup.object().shape({
  user: yup.object().shape({
    username: yup.string().required('ユーザーネームを入力してください'),
    email: yup.string().required('メールアドレスを入力してください').email("メールアドレスを正しい形式で入力してください"),
    phone_number: yup.string().required('電話番号を入力してください'),
  }),
  restaurant: yup.object().shape({
    name: yup.string().required('店舗名を入力してください'),
    address: yup.string().required('住所を入力してください').test('password-length', 'パスワードは4文字以上で入力してください', value => value.length >= 4),
    manager_last_name: yup.string().required('代表者名を入力してください'),
    manager_first_name: yup.string().required('代表者名を入力してください'),
    manager_phone_number: yup.string().required('電話番号を入力してください'),
    plan: yup.string().required('プランを選択してください'),
    add_ons: yup.array().of(yup.string()),
    logo: yup.mixed()
  }),
});
export const useEditRestaurantForm = (restaurantId?: string) => {
  const { restaurant } = useFetchRestaurant(restaurantId)
  const { handleSubmit, register, formState: { errors, isSubmitting }, control, setValue } = useForm({
    resolver: yupResolver(editRestaurantSchema),
  });
  useEffect(() => {
    if (restaurant) {
      setValue('user.username', restaurant.user_data.username);
      setValue('user.email', restaurant.user_data.email);
      setValue('user.phone_number', restaurant.user_data.phone_number);
      setValue('restaurant.name', restaurant.name);
      setValue('restaurant.address', restaurant.address);
      setValue('restaurant.manager_last_name', restaurant.manager_last_name);
      setValue('restaurant.manager_first_name', restaurant.manager_first_name);
      setValue('restaurant.manager_phone_number', restaurant.manager_phone_number);
      setValue('restaurant.plan', restaurant.plan);
      setValue('restaurant.add_ons', restaurant.add_ons);
    }
  }, [restaurant]);


  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
    control,
    userId: restaurant?.user_data.id,
  };
};