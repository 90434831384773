import { useNavigate } from "react-router-dom";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";

export const useSubmitKuchikomyuCustomerOverallScoreEn = (restaurantId: string, branchScore: number, enUrl: string) => {
  const navigate = useNavigate();

  const onSubmit = async (data:any) => {
    try {
      const res = await customNoAuthAxios.post(
        'kuchikomyu/overall-score/customer/',
        {restaurant_id: restaurantId, score: data.score},
      );
      if (data.score && data.score > branchScore) {
        window.location.href = enUrl;
      } else {
        navigate(`/kuchikomyu/survey/en/${restaurantId}`);
      }
    } catch (error) {
    } finally {
    }
  }

  return onSubmit;
};