import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { Paginator } from "../../molecules/pagenation/Paginator";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { TableLayout } from "../../template/table/TableLayout";
import { CardBody, CardFooter, CardHeader, HStack, Text } from "@chakra-ui/react";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../molecules/card/CardHeading";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { useFetchPaginateManagers } from "../../../hooks/manager/useFetchPaginateManagers";
import { FaShop } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";


export const Managers: React.FC = memo(() => {
  const { corporationId } = useParams();
  const { managersPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage } = useFetchPaginateManagers(corporationId)
  if (isLoading) return <CircularLoading />;
  if (!managersPerPage) return null
  return (
    <DarkShadowCard>
      <CardHeader>
        <CardHeading icon={<FaUserTie />} heading='マネジャー一覧'/>
      </CardHeader>
      <CardBody py={2}>
        <ManagersTable managers={managersPerPage.managers} />
      </CardBody>
      <CardFooter>
        <Paginator pagesCount={pagesCount} currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
      </CardFooter>
    </DarkShadowCard>
  );
});


type Managers = {
  id: string;
  name: string;
  username: string;
  restaurants: {}[];
}[];
const ManagersTable: React.FC<{managers: Managers}> = memo(({managers}) => {
  const headers = ['名前', 'ユーザーネーム', '店舗数'];
  const rows = managers.map((manager) => (
    <TableRowWithNavigationLayout
      key={manager.id}
      path={`${manager.id}`}
      childrenList={[
        manager.name,
        manager.username,
        <HStack spacing={1}>
          <FaShop size={16}/>
          <Text m={0}>{manager.restaurants.length}</Text>
        </HStack>
      ]}
    />
  ));
  return <TableLayout headers={headers} rows={rows || []} />
})