import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';
import { useErrorToast } from '../toast/useErrorToast';


export const useEditNotice = (noticeId: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();
  const onSubmit = useCallback(async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append('title', data.title);
      uploadData.append('content', data.content);
      uploadData.append('url', data.url);
      data.img[0] && uploadData.append("img", data.img[0], data.img[0].name);
      await customAuthAxios.put(
        `general_setting/notices/${noticeId}/`,
        uploadData
      );
      showToast('編集が完了しました')
      navigate(-1);
    } catch (error:any) {
    }
  }, [navigate, showToast, showErrorToast]);
  return onSubmit;
};