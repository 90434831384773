import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { managerGroupId } from '../../groupNumber';
import { customNoAuthAxios } from '../../utils/customNoAuthAxios';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';
import { useErrorToast } from '../toast/useErrorToast';


export const useSubmitManagerRegister = (corporationId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();
  const registerUser = async (userData: any) => {
    userData.groups = [managerGroupId];
    const userRes = await customNoAuthAxios.post('authentication/signup/', userData);
    return userRes.data.id;
  };
  const registerManager = async (managerData: any, userId: string) => {
    managerData.user = userId;
    managerData.corporation = corporationId;
    await customAuthAxios.post('accounts/managers/', managerData);
  };
  const onSubmit = useCallback(async (data: any) => {
    try {
      const userId = await registerUser(data.user);
      await registerManager(data.manager, userId);
      showToast('登録が完了しました');
      navigate('..');
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.username) {
        showErrorToast(error.response.data.username);
      }
    }
  }, [navigate]);
  return onSubmit;
};