import React, { memo } from "react";
import { Box, Card, CardBody } from "@chakra-ui/react";
import { ConfirmSegmentCouponUseDialog } from "./ConfirmSegmentCouponUseDialog";
import { ValidSegmentCoupon } from "../../../hooks/membership/useFetchValidCoupon";
import { CouponLayout } from "../../molecules/CouponLayout";


export const CustomerValidSegmentCoupon: React.FC<ValidSegmentCoupon> = memo(({id, img, title, text, expiration_date, notes}) => {
  return (
    <Box>
      <Box p={5}>
        <Card rounded='2xl' boxShadow='rgba(0, 0, 0, 0.1) 0px 0px 6px'>
          <CardBody >
            <CouponLayout
              title={title}
              explanation={text}
              expiration_date={expiration_date}
              notes={notes}
              img={img}
            />
          </CardBody>
        </Card>
      </Box>
      <ConfirmSegmentCouponUseDialog couponId={id} />
    </Box>
  );
});