import { AddIcon } from "@chakra-ui/icons";
import { BoxProps, Button, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


type CreateNewBoxProps = {
  path: string
}
type CombinedProps = CreateNewBoxProps & BoxProps
export const CreateNewBox: React.FC<CombinedProps> = ({path, ...props}) => {
  const navigate = useNavigate();
  return (
    <VStack as={Button} variant='outline' colorScheme='gray' {...props} onClick={() => navigate(path)}>
      <AddIcon fontSize={{base: 'sm', sm: 'md'}} />
      <Text fontWeight='bold' m='0' fontSize={{base: 'sm', sm: 'md'}}>Create New</Text>
    </VStack>
  )
}