import { useSwrWithQueryParams } from "../swr/useSwrWithQueryParams";


type TabelogReviewCounts = {
  review_count: number;
  date: string;
}[]
export const useFetchBenchmarkRestaurantTabelogReviewCounts = (benchmarkRestaurantId?: string, queryParams?: object) => {
  const fetchKey = benchmarkRestaurantId ? `ifrea_analytics/benchmark-restaurants/${benchmarkRestaurantId}/review-counts/` : null;
  const { data: reviewCounts, error, isLoading } = useSwrWithQueryParams<TabelogReviewCounts>(fetchKey, queryParams)
  return { reviewCounts, isLoading, error };
};