import { useRecoilValue } from "recoil";
import { totalState } from "../../store/totalState";
import { useCustomPagination } from "../useCustomPagenation";
import { useUpdateTotal } from "../useUpdateTotal";
import { useFetchKuchikomyuSurveyAnswerTextsPerPage } from "./useFetchKuchikomyuSurveyTexts";

export const useFetchPaginateKuchikomyuSurveyAnswerTexts = (restaurantId: string) => {
  const total = useRecoilValue(totalState);
  const { pages, pagesCount, currentPage, setCurrentPage, pageSize } = useCustomPagination(total, 20);
  const { kuchikomyuSurveyAnswerTextsPerPage, isLoading } = useFetchKuchikomyuSurveyAnswerTextsPerPage(restaurantId, pageSize, currentPage)
  useUpdateTotal(kuchikomyuSurveyAnswerTextsPerPage);

  return { kuchikomyuSurveyAnswerTextsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage };
};