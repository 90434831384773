import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const rouletteSettingEditSchema = yup.object().shape({
  branch_score: yup.number().required('ブランチスコアを入力してください'),
  g_url: yup.string().required('Urlを入力してください'),
  is_survey: yup.boolean(),
  segment_count: yup.number().required('セグメント数を入力してください')
});
export const useRegisterRouletteSettingForm = () => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(rouletteSettingEditSchema),});
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};