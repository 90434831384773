import useSWR from "swr";
import { useRecoilValue } from "recoil";
import { manLaboDateRangeState } from "../../store/manLaboDateRangeState";

type ManLaboQuestionScores = {
  'id': string,
  'title': string,
  'survey_answer_scores_count': number,
  'survey_answer_scores_average': number,
  'survey_answer_scores_5_count': number,
  'survey_answer_scores_5_percentage': number,
  'survey_answer_scores_4_count': number,
  'survey_answer_scores_4_percentage': number,
  'survey_answer_scores_3_count': number,
  'survey_answer_scores_3_percentage': number,
  'survey_answer_scores_2_count': number,
  'survey_answer_scores_2_percentage': number,
  'survey_answer_scores_1_count': number,
  'survey_answer_scores_1_percentage': number,
  }[]

  export const useFetchManLaboQuestionScores = (restaurantId: string) => {
  const dateRange = useRecoilValue(manLaboDateRangeState)
  const { data: manLaboQuestionScores, error, isLoading } = useSWR<ManLaboQuestionScores>(`manzokudo_labo/survey/scores/?restaurantId=${restaurantId}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`)
  return { manLaboQuestionScores, isLoading, error };
};