import React, { memo } from 'react';
import { Grid } from '@chakra-ui/react';
import { SoCouponOverallScore } from './SoCouponOverallScore';
import { SoCouponLatestSurveyAnswerTexts } from './SoCouponLatestSurveyAnswerTexts';


type CenterSoCouponAnalysisCardsProps = {
  restaurantId: string;
}
export const CenterSoCouponAnalysisCards: React.FC<CenterSoCouponAnalysisCardsProps> = memo(({restaurantId}) => {
  return (
    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap={3} w='100%'>
      <SoCouponOverallScore restaurantId={restaurantId} />
      <SoCouponLatestSurveyAnswerTexts restaurantId={restaurantId} />
    </Grid>
  );
});