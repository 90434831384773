import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AccordionList } from "../../molecules/SidebarAccordionPanelList";
import { AccordionItemLink } from "../../molecules/SidebarAccordionItemLink";


export const KuitanAccordionMenu = () => {
  const links = [
    { to: 'kuitan/dashboard', text: 'ダッシュボード' },
    { to: 'kuitan/ratings', text: '評価' },
    // { to: 'kuitan/dispatch/request', text: '派遣依頼' },
    { to: 'kuitan/dispatch/requests', text: '派遣依頼履歴' },
    { to: 'kuitan/setting', text: '設定' },
  ];
  return (
    <AccordionItem border='none'>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left' fontWeight='bold'>
          食イタン
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <AccordionList>
          {links.map(link => <AccordionItemLink key={link.to} to={link.to} text={link.text} />)}
        </AccordionList>
      </AccordionPanel>
    </AccordionItem>
  );
};