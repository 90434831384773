import { memo } from "react"
import { useParams } from "react-router-dom";
import { useEditBenefitForm } from "../../../hooks/membership/useEditBenefitForm";
import { useEditBenefit } from "../../../hooks/membership/useEditBenefit";
import { useFetchBenefit } from "../../../hooks/membership/useFetchBenefit";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { BenefitForm } from "../../organisms/membership/BenefitForm";


export const BenefitEdit: React.FC = memo(() => {
  const { benefitId } = useParams();
  const { benefit, isLoading } = useFetchBenefit(benefitId)
  const { handleSubmit, register, control, isSubmitting, errors, setValue } = useEditBenefitForm(benefit)
  const onSubmit = useEditBenefit(benefitId);
  if (isLoading) return <CircularLoading />
  if (!benefit) return null
  return (
    <BenefitForm
      img={benefit.img}
      handleSubmit={handleSubmit(onSubmit)}
      register={register}
      control={control}
      isSubmitting={isSubmitting}
      errors={errors}
      setValue={setValue}
    />
  );
});