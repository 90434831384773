import { memo } from "react"
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { Card, CardHeader, CardBody, Flex, VStack, Text, Grid, Skeleton } from '@chakra-ui/react'
import { RiAdvertisementFill } from "react-icons/ri";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { BsPeopleFill } from "react-icons/bs";
import { LineGraph } from "../../molecules/graph/LineGraph";
import { RiMoneyCnyBoxFill } from "react-icons/ri";
import { MdAutoGraph } from "react-icons/md";
import { BsGraphUp } from "react-icons/bs";
import { useFetchAdvertisingExpenses } from "../../../hooks/ifreaAnalytics/useFetchAdvertisingExpenses";
import { useFetchAveragePrice } from "../../../hooks/ifreaAnalytics/useFetchAveragePrice";
import { useFetchLatestReservationNumPeople } from "../../../hooks/ifreaAnalytics/useFetchLatestReservationNumPeople";
import { useFetchReservationNumPeople } from "../../../hooks/ifreaAnalytics/useFetchReservationNumPeople";
import { useParams } from "react-router-dom";
import { LightShadowCard } from "../../molecules/card/LightShadowCard";
import { useSetRecoilState } from "recoil";
import { reservationNumPeopleGraphDateRangeState } from "../../../store/reservationNumPeopleGraphDateRangeState";
import { DateFilterSection } from "../../organisms/DateFilterSection";


export const Aggregate: React.FC = memo(() => {
  const { restaurantId } = useParams()
  if (!restaurantId) return <CircularLoading />
  const cardData = [
    { CardComponent: AdvertisingExpensesCard },
    { CardComponent: SalesTargetCard },
    { CardComponent: ReservationsTargetCard },
    { CardComponent: ReservationNumPeopleCard },
    { CardComponent: TotalReservationAmountCard },
    { CardComponent: SalesTargetAchievementRateCard },
    { CardComponent: RoiCard },
    { CardComponent: AveragePriceCard },
  ];
  return (
    <VStack spacing={5}>
      <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)'}} gap={4} w='100%'>
        {cardData.map(({ CardComponent }, index) => (
          <CardComponent key={index} restaurantId={restaurantId} />
        ))}
      </Grid>
      <ReservationNumPeopleGraphCard restaurantId={restaurantId} />
    </VStack>
  );
});

const AggregateDetailCard: React.FC<{title: string, value: string, icon: React.ReactNode, isLoading: boolean}> = memo(({title, value, icon, isLoading}) => {
  return (
    <Skeleton isLoaded={!isLoading} borderRadius='15px'>
      <LightShadowCard>
        <CardBody>
          <Flex align='center' justify='space-between'>
            <VStack align='start' fontWeight='bold' spacing={0}>
              <Text color='#929fad' fontSize='12px' m='0'>{title}</Text>
              <Text fontSize='20px' m='0'>{value}</Text>
            </VStack>
            <Flex align='center' bgColor='#4ED0C5' p={2} borderRadius='20%' boxSize='45px' justify='center'>
              {icon}
            </Flex>
          </Flex>
        </CardBody>
      </LightShadowCard>
    </Skeleton>
  );
});


const AdvertisingExpensesCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { advertisingExpenses, isLoading } = useFetchAdvertisingExpenses(restaurantId)
  return (
    <AggregateDetailCard title='広告費' value={`¥${advertisingExpenses ? advertisingExpenses.toLocaleString() : '-'}`} icon={<RiAdvertisementFill color='white' size='24px' />} isLoading={isLoading} />
  );
});

const SalesTargetCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { advertisingExpenses, isLoading } = useFetchAdvertisingExpenses(restaurantId)
  const salesTarget = advertisingExpenses ? (advertisingExpenses * 5).toLocaleString() : '-'
  return (
    <AggregateDetailCard title='売上目標' value={`¥${salesTarget}`} icon={<FaMoneyBillTrendUp color='white' size='24px' />} isLoading={isLoading} />
  );
});

const ReservationsTargetCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { advertisingExpenses, isLoading: isAdvertisingExpensesLoading } = useFetchAdvertisingExpenses(restaurantId)
  const { averagePrice, isLoading: isAveragePriceLoading } = useFetchAveragePrice(restaurantId)

  const reservationsTarget = advertisingExpenses && averagePrice ? (Math.round(advertisingExpenses * 5 / averagePrice)).toLocaleString() : '-'
  return (
    <AggregateDetailCard title='目標予約人数' value={`${reservationsTarget}人`} icon={<BsPeopleFill color='white' size='24px' />} isLoading={isAdvertisingExpensesLoading || isAveragePriceLoading} />
  );
});

const ReservationNumPeopleCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { reservationNumPeople, isLoading } = useFetchLatestReservationNumPeople(restaurantId)
  return (
    <AggregateDetailCard title='集客人数' value={`${reservationNumPeople ? reservationNumPeople.toLocaleString() : '-'}人`} icon={<BsPeopleFill color='white' size='24px' />} isLoading={isLoading} />
  );
});

const TotalReservationAmountCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { reservationNumPeople, isLoading: isReservationNumPeopleLoading } = useFetchLatestReservationNumPeople(restaurantId)
  const { averagePrice, isLoading: isAveragePriceLoading } = useFetchAveragePrice(restaurantId)
  const totalReservationAmount = reservationNumPeople && averagePrice ? (reservationNumPeople * averagePrice).toLocaleString() : '-'

  return (
    <AggregateDetailCard title='予約金額合計' value={`¥${totalReservationAmount}`} icon={<RiMoneyCnyBoxFill color='white' size='24px' />} isLoading={isReservationNumPeopleLoading || isAveragePriceLoading} />
  );
});

const SalesTargetAchievementRateCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { reservationNumPeople, isLoading: isReservationNumPeopleLoading } = useFetchLatestReservationNumPeople(restaurantId)
  const { averagePrice, isLoading: isAveragePriceLoading } = useFetchAveragePrice(restaurantId)
  const { advertisingExpenses, isLoading: isAdvertisingExpensesLoading } = useFetchAdvertisingExpenses(restaurantId)

  const salesTarget = advertisingExpenses ? advertisingExpenses * 5 : 0;
  const totalReservationAmount = reservationNumPeople && averagePrice ? reservationNumPeople * averagePrice : 0;
  const salesTargetAchievementRate = (advertisingExpenses && reservationNumPeople && averagePrice && salesTarget > 0) ? Math.round(totalReservationAmount / salesTarget * 100) : '-';
  return (
    <AggregateDetailCard title='売上目標達成率' value={`${salesTargetAchievementRate}%`} icon={<MdAutoGraph color='white' size='24px' />} isLoading={isReservationNumPeopleLoading || isAveragePriceLoading || isAdvertisingExpensesLoading} />
  );
});

const RoiCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { advertisingExpenses, isLoading: isAdvertisingExpensesLoading } = useFetchAdvertisingExpenses(restaurantId)
  const { reservationNumPeople, isLoading: isReservationNumPeopleLoading } = useFetchLatestReservationNumPeople(restaurantId)
  const { averagePrice, isLoading: isAveragePriceLoading } = useFetchAveragePrice(restaurantId)

  const roi = (advertisingExpenses && reservationNumPeople && averagePrice) ? Number(((reservationNumPeople * averagePrice) / advertisingExpenses).toFixed(2)) : '-';
  return (
    <AggregateDetailCard title='費用対効果' value={`${roi}倍`} icon={<BsGraphUp color='white' size='24px' />} isLoading={isReservationNumPeopleLoading || isAveragePriceLoading || isAdvertisingExpensesLoading} />
  );
});

const AveragePriceCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const { averagePrice, isLoading: isAveragePriceLoading } = useFetchAveragePrice(restaurantId)
  return (
    <AggregateDetailCard title='平均単価' value={`¥${averagePrice ? averagePrice.toLocaleString() : '-'}`} icon={<RiMoneyCnyBoxFill color='white' size='24px' />} isLoading={isAveragePriceLoading} />
  );
});

const ReservationNumPeopleGraphCard: React.FC<{restaurantId: string}> = memo(({restaurantId}) => {
  const setDateRange = useSetRecoilState(reservationNumPeopleGraphDateRangeState);
  const { reservationNumPeople, isLoading } = useFetchReservationNumPeople(restaurantId)
  const graphLabels = reservationNumPeople ? Object.keys(reservationNumPeople) : []
  const graphValues = reservationNumPeople ? Object.values(reservationNumPeople) : []
  return (
    <LightShadowCard w='100%'>
      <CardHeader pb={0}>
        <Text fontWeight='bold' m='0'>集客人数推移</Text>
      </CardHeader>
      <CardBody p={4}>
        <VStack spacing={3}>
          <DateFilterSection setDateRange={setDateRange} />
          <Skeleton isLoaded={!isLoading} borderRadius='4px' w='100%'>
            <LineGraph labels={graphLabels} values={graphValues} graphColorRgb="78,208,197" height="500" />
          </Skeleton>
        </VStack>
      </CardBody>
    </LightShadowCard>
  );
});