import { Box, Divider } from '@chakra-ui/react';
import { SidebarNavWrapper } from '../../template/SidebarNavWrapper';
import { KuitanAgentSidebarMenu } from './KuitanAgentSidebarMenu';
import { KuitanAgentName } from '../../atoms/KuitanAgentName';


export const KuitanAgentSidebar = () => {
  return (
    <Box>
      <Box px={4} py={3} fontSize='24px'>KUITAN</Box>
      <Divider m='0' />
      <Box px={4} py={3}>
        <KuitanAgentName/>
      </Box>
      <Box px={2}>
        <Divider m='0' />
      </Box>
      <SidebarNavWrapper>
        <KuitanAgentSidebarMenu/>
      </SidebarNavWrapper>
    </Box>
  );
};