import React from "react";
import { CircularLoading } from "../molecules/loading/CircularLoading";
import { LineUsageGuide } from "../pages/error/LineUsageGuide";
import { useSwrNoAuth } from "../../hooks/swr/useSwrNoAuth";
import { useFetchLiffIdCustomerByRestaurantId } from "../../hooks/externalApi/line/useFetchLiffIdCustomerByRestaurantId";
import { LineAccountRegister } from "../pages/externalApi/line/LineAccountRegister";
import { EditCustomer } from "../pages/externalApi/line/EditCustomer";
import { useFetchLineProfile } from "../../hooks/externalApi/line/useFetchLineProfile";
import { lineAccountIdState } from "../../store/fetchedData/lineAccountIdState";
import { useSetRecoilState } from "recoil";


type LiffLoginProps = {
  restaurantId: string;
  liffType: string;
  children: React.ReactNode;
}
export const LiffLoginProcess: React.FC<LiffLoginProps> = ({restaurantId, liffType, children}) => {
  const { liffId, isLoading, error } = useFetchLiffIdCustomerByRestaurantId(restaurantId, liffType)
  if (error?.response?.status === 404 || error?.response?.status === 400) return <LineUsageGuide />
  if (!liffId || isLoading) return <CircularLoading />
  return (
    <LineProfileFetcher restaurantId={restaurantId} liffId={liffId}>
      {children}
    </LineProfileFetcher>
  );
};


type Profile = {
  userId: string;
  displayName: string;
  pictureUrl?: string;
}
const LineProfileFetcher: React.FC<{restaurantId: string, liffId: string, children: React.ReactNode;}> = ({restaurantId, liffId, children}) => {
  const profile = useFetchLineProfile(liffId);
  if (!profile) return <CircularLoading />
  return (
    <ExistsLineAccountChecker restaurantId={restaurantId} profile={profile}>
      {children}
    </ExistsLineAccountChecker>
  );
}

const ExistsLineAccountChecker: React.FC<{restaurantId: string, profile: Profile, children: React.ReactNode;}> = ({restaurantId, profile, children}) => {
  const setLineAccountId = useSetRecoilState(lineAccountIdState)
  const { data: lineAccountId, isLoading, error } = useSwrNoAuth<string>(`external_api/restaurants/${restaurantId}/line-accounts/${profile.userId}/line-account-id/`);
  if (error?.response.status === 404) return <LineAccountRegister restaurantId={restaurantId} profile={profile} />
  if (isLoading || !lineAccountId) return <CircularLoading />
  setLineAccountId(lineAccountId)
  return (
    <ShouldAnswerFirstSurveyChecker {...{restaurantId, lineAccountId}}>
      {children}
    </ShouldAnswerFirstSurveyChecker>
  );
}

const ShouldAnswerFirstSurveyChecker: React.FC<{restaurantId: string, lineAccountId: string, children: React.ReactNode;}> = ({restaurantId, lineAccountId, children}) => {
  const fetchKey = `external_api/restaurants/${restaurantId}/line-accounts/${lineAccountId}/has-answered-first-survey/`
  const { data: hasAnsweredFirstSurvey, isLoading: hasAnsweredFirstSurveyIsLoading } = useSwrNoAuth<boolean>(fetchKey);
  const { data: isFirstSurvey, isLoading: isFirstSurveyIsLoading } = useSwrNoAuth<boolean>(`menu/restaurants/${restaurantId}/first-survey/`);
  if (hasAnsweredFirstSurveyIsLoading || isFirstSurveyIsLoading) return <CircularLoading />
  if (hasAnsweredFirstSurvey === false && isFirstSurvey === true) return <EditCustomer mutateKey={fetchKey} lineAccountId={lineAccountId} />
  return <>{children}</>
}