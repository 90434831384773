import useSWR from "swr";

type MenuSetting = {
  id: string;
  restaurant: string;
  type: string;
  is_table_security: boolean;
  is_first_survey: boolean;
  qr_card_img?: string;
}
export const useFetchMenuSettingByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `menu/restaurants/${restaurantId}/setting/` : null;
  const { data: menuSetting, isLoading } = useSWR<MenuSetting>(fetcherKey)
  return { menuSetting, isLoading };
};