import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";

const menuSettingEditSchema = yup.object().shape({
  type: yup.string().required('入力必須項目です'),
  is_first_survey: yup.boolean().required('入力必須項目です'),
  qr_card_img: yup.mixed(),
});

type MenuSetting = {
  id: string;
  restaurant: string;
  type: string;
  is_first_survey: boolean;
  qr_card_img?: string;
}
export const useMenuSettingEditForm = (menuSetting?: MenuSetting) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(menuSettingEditSchema),});

  useEffect(() => {
    if (menuSetting) {
      setValue('type', menuSetting.type);
      setValue('is_first_survey', menuSetting.is_first_survey);
    }
  }, [menuSetting]);

  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
    setValue
  };
};