import React, { memo } from "react";
import { Badge, Box, Icon, Text, VStack } from "@chakra-ui/react";
import { RiCoupon2Line } from "react-icons/ri";


type DefaultCouponProps = {
  title: string;
}
export const DefaultCoupon: React.FC<DefaultCouponProps> = memo(({title}) => {
  return (
    <Box
      aspectRatio={1}
      boxSize='100%'
      p={4}
      bgColor="#f99e15"
      outline="2px solid rgb(255, 255, 255)"
      outlineOffset="-7px"
      m='auto'
      fontWeight="bold"
    >
      <VStack boxSize='100%' justify='space-between'>
        <Badge bgColor='white' borderRadius='10px' color="#f99e15" fontSize={{base: '3.8vw', sm: '14px'}} px={2} py='2px'>1回のみ使用可能</Badge>
        <Text color="#1E2336" fontSize={{base: '4.8vw', sm: '18px'}} textAlign='center' m='0'>{title}</Text>
        <Icon as={RiCoupon2Line} boxSize='26%' color='white' />
      </VStack>
    </Box>
  );
});