import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const googleSettingSchema = yup.object().shape({
  location_id: yup.string().required('入力必須項目です'),
  place_id: yup.string().required('入力必須項目です'),
  reply_mode: yup.string().required('入力必須項目です'),
  notification_recipients: yup.string()
});
type GoogleSetting = {
  id: string;
  location_id: string;
  place_id: string;
  reply_mode: string;
  notification_recipients: string[];
}
export const useEditGoogleSettingFormForAdmin = (googleSetting?: GoogleSetting) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(googleSettingSchema),});
  useEffect(() => {
    if (googleSetting) {
      setValue('location_id', googleSetting.location_id);
      setValue('place_id', googleSetting.place_id);
      setValue('reply_mode', googleSetting.reply_mode);
      setValue('notification_recipients', googleSetting.notification_recipients[0])
    }
  }, [googleSetting]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};