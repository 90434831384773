import { Box, CardBody, CardFooter, CardHeader } from "@chakra-ui/react";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { CardHeading } from "../../molecules/card/CardHeading";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { useCreateNoticeForm } from "../../../hooks/notice/useCreateNoticeForm";
import { useCreateAndNotifyNotice } from "../../../hooks/notice/useCreateAndNotifyNotice";
import { IoIosNotifications } from "react-icons/io";
import { useState } from "react";
import { NoticeFormContents } from "../../organisms/notice/NoticeFormContents";


export const CreateNotice= () => {
  const { handleSubmit, register, isSubmitting, errors } = useCreateNoticeForm();
  const onSubmit = useCreateAndNotifyNotice()
  const [title, setTitle] = useState('');
  return (
    <Box m='auto' w='650px' maxW='100%'>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<IoIosNotifications />} heading='通知'/>
          </CardHeader>
          <CardBody py={2}>
            <NoticeFormContents register={register} errors={errors} title={title} setTitle={setTitle} />
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>作成</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
};