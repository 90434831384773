import useSWR from "swr";


type MultiMonthDispatchRequests = {
  id: string;
  corporation_name: string;
  team_name: string;
  period: string;
  number: number;
  requested_at: string;
}[]
type PaginatedMultiMonthDispatchRequests = {
  count: number;
  multi_month_dispatch_requests: MultiMonthDispatchRequests;
};
export const useFetchPaginatedMultiMonthDispatchRequestsBySalesmanId = (pageSize: number, currentPage: number, salesmanId?: string,) => {
  const fetcherKey = salesmanId ? `kuitan/salesmen/${salesmanId}/corporations/dispatch/requests/multi-month/paginated/?pageSize=${pageSize}&currentPage=${currentPage}` : null
  const { data: paginatedMultiMonthDispatchRequests, error, isLoading } = useSWR<PaginatedMultiMonthDispatchRequests>(fetcherKey)
  return { paginatedMultiMonthDispatchRequests, isLoading, error };
};