import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useErrorToast } from '../toast/useErrorToast';


const apiUrlLogin = `${process.env.REACT_APP_API_URL}authen/jwt/create/`;


export const useLoginRestaurant = () => {
  const navigate = useNavigate();
  const showErrorToast = useErrorToast();

  const onSubmit = async (data:any) => {
    try {
      const authRes = await axios.post(apiUrlLogin, data, { headers: { 'Content-Type': 'application/json' } });
      const token = authRes.data.access;
      localStorage.clear();
      localStorage.setItem('localJWT', token);
      const restaurantRes = await customAuthAxios.get(`accounts/restaurants/me/`);
      navigate(`/restaurants/${restaurantRes.data.id}/dashboard`)
    } catch (error:any) {
      if (error.response?.status === 401 || error.response?.status === 404) {
        showErrorToast("ユーザーネームまたはパスワードが誤っています。");
      } else {
        showErrorToast("予期せぬエラーが発生しました。");
      }
    }
  };
  return onSubmit;
};