import { CardFooter } from "@chakra-ui/react";
import { useFetchUser } from "../../../hooks/user/useFetchUser";
import { EditIconBtn } from "../../atoms/button/EditIconBtn";


type RestaurantSettingCardFooterProps = {
  path: string
}
export const RestaurantSettingCardFooter = ({path}: RestaurantSettingCardFooterProps) => {
  const { user, isLoading } = useFetchUser()
  if (isLoading || !user || !user.is_superuser) return null
  return (
    <CardFooter pt={0}>
      <EditIconBtn path={path} />
    </CardFooter>
  );
};