import useSWR from "swr";

type IfreaAnalyticsSetting = {
  id: string;
  ifrea_analytics_restaurant_id: string;
}

export const useFetchIfreaAnalyticsSettingByRestaurantId = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `/ifrea_analytics/restaurants/${restaurantId}/setting/` : null;
  const { data: setting, error, isLoading } = useSWR<IfreaAnalyticsSetting>(fetcherKey)
  return { setting, isLoading, error };
};