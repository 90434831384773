import { useParams } from "react-router-dom";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useFetchRestaurantStaff } from "../../../hooks/restaurantStaff/useFetchRestaurantStaff";
import { useEditStaffForm } from "../../../hooks/restaurantStaff/useEditStaffForm";
import { useEditStaff } from "../../../hooks/restaurantStaff/useEditStaff";
import { RestaurantStaffForm } from "../../organisms/restaurantStaff/RestaurantStaffForm";


export const EditStaff = () => {
  const { staffId } = useParams();
  const { staff, isLoading } = useFetchRestaurantStaff(staffId);
  const { handleSubmit, register, isSubmitting, errors } = useEditStaffForm(staff);
  const onSubmit = useEditStaff(staffId || '')
  if (isLoading) return <CircularLoading />;
  return <RestaurantStaffForm onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} register={register} errors={errors} cardHeading='スタッフ編集' btnLabel='変更'/>;
};