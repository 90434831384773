import { customAuthAxios } from "../../../utils/customAuthAxios";
import { apiUrlLineSegmentCoupon } from "../../../apiUrls/lineApiUrls";
import { useSuccessToast } from "../../toast/useSuccessToast";

export const useLineSegmentCouponSubmit = (restaurantId: string, accessToken: string, reset: () => void) => {
  const showToast = useSuccessToast();
  const onSubmit = async (data:any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("restaurantId", restaurantId);
      uploadData.append("accessToken", accessToken!);
      uploadData.append("visitCounts", JSON.stringify(data.visitCounts));
      uploadData.append("textMsg", data.textMsg);
      uploadData.append("altText", data.altText);
      uploadData.append("title", data.title);
      uploadData.append("text", data.text);
      uploadData.append("label", data.label);
      uploadData.append("notes", data.notes);
      uploadData.append("expirationDate", data.expirationDate);
      data.img[0] && uploadData.append("img", data.img[0], data.img[0].name);

      await customAuthAxios.post(
        apiUrlLineSegmentCoupon,
        uploadData
      );
      showToast('配信が完了しました。');
      reset()
    } catch (error:any) {
    } finally {
    }
  };

  return {
    onSubmit,
  };
};