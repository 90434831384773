import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const editStaffSettingByAdminSchema = yup.object().shape({
  staff_branch_score: yup.number().required('必須項目です'),
  restaurant_branch_score: yup.number().required('必須項目です'),
  g_url: yup.string().required('必須項目です'),
  is_navigate_g_url: yup.boolean(),
});
type StaffSetting = {
  id: string;
  restaurant: string;
  restaurant_branch_score: number;
  staff_branch_score: number;
  g_url: string;
  is_navigate_g_url: boolean;
}
export const useEditStaffSettingFormForAdmin = (staffSetting?: StaffSetting) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(editStaffSettingByAdminSchema),});
  useEffect(() => {
    if (staffSetting) {
      setValue('restaurant_branch_score', staffSetting.restaurant_branch_score);
      setValue('g_url', staffSetting.g_url);
      setValue('is_navigate_g_url', staffSetting.is_navigate_g_url);
      setValue('staff_branch_score', staffSetting.staff_branch_score);
    }
  }, [staffSetting]);
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};