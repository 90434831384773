import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';


export const useRequestMonthlyDispatchByRestaurant = () => {
  const { restaurantId } = useParams();
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = useCallback(async (data: any) => {
    try {
      data.dispatch.restaurants = [restaurantId];
      const res = await customAuthAxios.post(
        'kuitan/restaurant/dispatch/request/monthly/',
        data
      );
      showToast(res.data.message)
      navigate('../dispatch/requests');
    } catch (error:any) {
    }
  }, [navigate, showToast]);
  return onSubmit;
};