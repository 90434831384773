import { Route } from 'react-router-dom';
import { DashboardWrapper } from '../components/template/DashboardWrapper';
import { CorporateRestaurantBase } from '../components/template/base/CorporateRestaurantBase';
import { UsedCouponUsers } from '../components/pages/UsedCouponUsers';
import { VisitQrCode } from '../components/pages/externalApi/line/VisitQrCode';
import { RestaurantBaseInfo } from '../components/pages/restaurants/RestaurantBaseInfo';
import { RestaurantBaseInfoEdit } from '../components/pages/restaurants/RestaurantBaseInfoEdit';
import { AddOnsRoutes } from './AddOnsRoutes';


export const CorporateRestaurantsRoutes = (
  <Route path="restaurants/:restaurantId" element={<CorporateRestaurantBase />}>
    <Route path="" element={<RestaurantBaseInfo />} />
    <Route path="edit" element={<RestaurantBaseInfoEdit />} />
    <Route path="dashboard" element={<DashboardWrapper/>} />
    <Route path="used-coupon-users" element={<UsedCouponUsers />} />
    <Route path="visit/qr-code" element={<VisitQrCode />} />
    {AddOnsRoutes}
  </Route>
);