import { useSwrNoAuth } from "../swr/useSwrNoAuth";

type Benefits = {
  id: string;
  title: string;
  img: string;
}[]

export const useFetchBenefitsByLineId = (restaurantId: string, lineId: string) => {
  const { data: benefits, error, isLoading } = useSwrNoAuth<Benefits>(`membership/restaurants/${restaurantId}/line/accounts/${lineId}/benefits/`)
  return { benefits, isLoading, error };
};