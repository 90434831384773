import useSWR from "swr";


type Ratings = {
  id: string;
  agent_name: string;
  overall: number;
  food: number;
  drink: number;
  customer_service: number;
  hygiene: number;
  comment: string;
  posted_at: string;
}[]
type PaginatedRatings = {
  count: number;
  ratings: Ratings;
};
export const useFetchPaginatedRatingsByRestaurantId = (pageSize: number, currentPage: number, restaurantId?: string,) => {
  const fetcherKey = restaurantId ? `kuitan/restaurants/${restaurantId}/ratings/paginated/?pageSize=${pageSize}&currentPage=${currentPage}` : null
  const { data: paginatedRatings, error, isLoading } = useSWR<PaginatedRatings>(fetcherKey)
  return { paginatedRatings, isLoading, error };
};