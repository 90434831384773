import { Box, Center, FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { memo } from "react"
import { Controller } from "react-hook-form";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Rating } from "@mui/material";


type SurveyScoreRatingProps = {
  questionContent: string;
  index: number;
  control: any;
  errors: any;
}
export const SurveyScoreRating: React.FC<SurveyScoreRatingProps> = memo(({questionContent, index, control, errors}) => {
  const theme = createTheme();
  return (
    <FormControl isInvalid={!!errors.survey?.[index]?.answer_score?.message}>
      <FormLabel>{questionContent}</FormLabel>
      <Center>
        <Controller
          name={`survey.${index}.answer_score`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ThemeProvider theme={theme}>
              <Box
                as={Rating}
                fontSize='3rem'
                name="score"
                size="large"
                onChange={(event: any, newValue: any) => {
                  field.onChange(newValue);
                }}
              />
            </ThemeProvider>
          )}
        />
      </Center>
      <FormErrorMessage m={0} justifyContent='center'>{errors.survey?.[index]?.answer_score?.message}</FormErrorMessage>
    </FormControl>
  );
});