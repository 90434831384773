import { memo } from "react"
import { Tabs, TabList, TabPanels, Tab, TabPanel, VStack } from '@chakra-ui/react'
import { HotPepperAnalytics } from "../../organisms/ifreaAnalytics/HotPepperAnalytics";
import { TabelogAnalytics } from "../../organisms/ifreaAnalytics/TabelogAnalytics";
import { GurunaviAnalytics } from "../../organisms/ifreaAnalytics/GurunaviAnalytics";
import { TotalAnalytics } from "../../organisms/ifreaAnalytics/TotalAnalytics";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { ifreaMediaAnalyticsDateRangeState } from "../../../store/ifreaMediaAnalyticsDateRangeState";
import { DateFilterSection } from "../../organisms/DateFilterSection";


export const MediaAnalytics: React.FC = memo(() => {
  const setDateRange = useSetRecoilState(ifreaMediaAnalyticsDateRangeState);
  const { restaurantId } = useParams()
  if (!restaurantId) return null
  return (
    <VStack spacing={5} >
      <DateFilterSection setDateRange={setDateRange} />
      <Tabs isLazy w='100%'>
        <TabList>
          <Tab fontSize={{base: 'sm', sm: 'md'}} px={2} _selected={{ color: 'red.500', borderBottom: '2px solid #E53E3E', fontWeight: 'bold' }}>ホットペッパー</Tab>
          <Tab fontSize={{base: 'sm', sm: 'md'}} px={2} _selected={{ color: 'blue.500', borderBottom: '2px solid #3182CE', fontWeight: 'bold' }}>食べログ</Tab>
          <Tab fontSize={{base: 'sm', sm: 'md'}} px={2} _selected={{ color: 'yellow.500', borderBottom: '2px solid #D69D2E', fontWeight: 'bold' }}>ぐるなび</Tab>
          <Tab fontSize={{base: 'sm', sm: 'md'}} px={2} _selected={{ color: 'green.500', borderBottom: '2px solid #36A168', fontWeight: 'bold' }}>総合</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={2}>
            <HotPepperAnalytics restaurantId={restaurantId} />
          </TabPanel>
          <TabPanel px={2}>
            <TabelogAnalytics restaurantId={restaurantId} />
          </TabPanel>
          <TabPanel px={2}>
            <GurunaviAnalytics restaurantId={restaurantId} />
          </TabPanel>
          <TabPanel px={2}>
            <TotalAnalytics restaurantId={restaurantId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
});