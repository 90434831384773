import React, { memo } from "react";
import { Skeleton, Text } from "@chakra-ui/react";
import { useFetchBusinessTitleByRestaurantId } from "../../../../hooks/externalApi/google/useFetchBusinessTitleByRestaurantId";


type BusinessTitleByRestaurantIdProps = {
  restaurantId?: string;
}
export const BusinessTitleByRestaurantId: React.FC<BusinessTitleByRestaurantIdProps> = memo(({restaurantId}) => {
  const { businessTitle, isLoading } = useFetchBusinessTitleByRestaurantId(restaurantId);
  if (isLoading || !businessTitle) return <Skeleton w='140px' h='24px' />;
  return <CommonText text={businessTitle} />
});


const CommonText: React.FC<{text: string}> = ({text}) => {
  return <Text m='0' fontSize='sm' color='gray.500' borderBottom='1px' borderColor='gray.200' pb='2px'>{text}</Text>
}