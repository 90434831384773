import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";
import { BenchmarkRestaurant } from "./useFetchBenchmarkRestaurant";


const  benchmarkRestaurantSchema = yup.object().shape({
  name: yup.string().required('入力必須項目です'),
  url: yup.string().required('入力必須項目です'),
});
export const useEditBenchmarkRestaurantForm = (benchmarkRestaurant?: BenchmarkRestaurant) => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(benchmarkRestaurantSchema),});
  useEffect(() => {
    if (benchmarkRestaurant) {
      setValue('name', benchmarkRestaurant.name);
      setValue('url', benchmarkRestaurant.url);
    }
  }, [benchmarkRestaurant]);
  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};