import useSWR from "swr";


type Teams = {
    id: string;
    name: string;
    email: string;
    dispatch_count: number;
}[]
type PaginatedTeams = {
  count: number;
  teams: Teams;
};
export const useFetchPaginatedKuitanTeams = (queryParams: URLSearchParams) => {
  const queryString = queryParams.toString();
  const { data: paginatedTeams, error, isLoading } = useSWR<PaginatedTeams>(`kuitan/teams/paginated/?${queryString}`)
  return { paginatedTeams, isLoading, error };
};