import React, { memo } from "react";
import { Box, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { HoverPopover } from "../organisms/HoverPopover";
import { useRecoilState } from "recoil";
import { ordState } from "../../store/ordState";

export const SortVisitCountHoverPopover: React.FC = memo(() => {
  const [ord, setOrd] = useRecoilState(ordState)
  return (
    <HoverPopover w='150px'>
      <Box>
        来店回数 <ChevronDownIcon />
      </Box>
      <>
        <Button
          onClick={() => {setOrd('freq')}}
          isDisabled={ord === 'freq'}
          w='100%'
          borderRadius='none'
          justifyContent='start'
          size='sm'
        >
          多い順
        </Button>
        <Button
          onClick={() => {setOrd('few')}}
          isDisabled={ord === 'few'}
          w='100%'
          borderRadius='none'
          justifyContent='start'
          size='sm'
        >
          少ない順
        </Button>
      </>
    </HoverPopover>
  );
});
