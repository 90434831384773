import { Box, CardBody, CardFooter, CardHeader, FormControl, FormErrorMessage, FormLabel, Input, VStack } from "@chakra-ui/react";
import { memo } from "react"
import { useParams } from "react-router-dom";
import { useFetchTable } from "../../../hooks/tableStock/useFetchTable";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { useTableEditForm } from "../../../hooks/tableStock/useTableEditForm";
import { useSubmitTableEdit } from "../../../hooks/tableStock/useSubmitTableEdit";
import { SubmitBtn } from "../../atoms/button/SubmitBtn";
import { DarkShadowCard } from "../../molecules/card/DarkShadowCard";
import { MdOutlineTableRestaurant } from "react-icons/md";
import { CardHeading } from "../../molecules/card/CardHeading";


export const TableEdit: React.FC = memo(() => {
  const { tableId } = useParams();
  const { table, isLoading } = useFetchTable(tableId)
  const { handleSubmit, register, isSubmitting, errors } = useTableEditForm(table)
  const onSubmit = useSubmitTableEdit(tableId)
  if (isLoading) return <CircularLoading />
  if (!table) return null
  return (
    <Box w={{base: '100%', sm: '450px'}} m='auto'>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DarkShadowCard>
          <CardHeader>
            <CardHeading icon={<MdOutlineTableRestaurant fontSize='18px' />} heading='テーブル編集'/>
          </CardHeader>
          <CardBody py={2}>
            <VStack spacing={4}>
              <TableNameInput register={register} errors={errors} />
              <ImgInput register={register} errors={errors} />
            </VStack>
          </CardBody>
          <CardFooter>
            <SubmitBtn isLoading={isSubmitting}>登録</SubmitBtn>
          </CardFooter>
        </DarkShadowCard>
      </form>
    </Box>
  );
});


const TableNameInput: React.FC<{register: any, errors: any}> = memo(({register, errors}) => {
  return (
    <FormControl isRequired>
      <FormLabel>テーブル名</FormLabel>
      <Input {...register('table_name')} />
      <FormErrorMessage>{errors.table_name?.message}</FormErrorMessage>
    </FormControl>
  )
})
const ImgInput: React.FC<{register: any, errors: any}> = memo(({register, errors}) => {
  return (
    <FormControl>
      <FormLabel>QR画像</FormLabel>
      <input type='file' accept="image/*" {...register('qr_code')} />
      <FormErrorMessage>{errors.qr_code?.message}</FormErrorMessage>
    </FormControl>
  )
})