import React, { memo } from "react";
import { ListItem, Text } from "@chakra-ui/react"
import { Link } from 'react-router-dom';


export const AccordionItemLink: React.FC<{to: string, text: string}> = memo(({to, text}) => {
  return(
    <ListItem
      listStyleType="none"
      p="0 10px"
    >
      <Link to={to}>
        <Text m='0'>{text}</Text>
      </Link>
    </ListItem>
  )
})