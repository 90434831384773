import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const googleSettingSchema = yup.object().shape({
  notification_recipients: yup.string()
});
type GoogleSetting = {
  notification_recipients: string[];
}
export const useEditGoogleSettingFormForRestaurant = (googleSetting?: GoogleSetting) => {
  const { handleSubmit, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(googleSettingSchema),});
  useEffect(() => {
    if (googleSetting) {
      setValue('notification_recipients', googleSetting.notification_recipients[0]);
    }
  }, [googleSetting]);
  return {
    handleSubmit,
    control,
    isSubmitting,
    errors,
  };
};