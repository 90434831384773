import { useNavigate } from "react-router-dom";
import { customAuthAxios } from "../../utils/customAuthAxios";
import { useSuccessToast } from "../toast/useSuccessToast";

export const useSubmitTableEdit = (tableId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();

  const onSubmit = async (data: any) => {
    try {
      const uploadData = new FormData();
      uploadData.append("table_name", data.table_name);
      data.qr_code[0] && uploadData.append("qr_code", data.qr_code[0], data.qr_code[0].name);
      await customAuthAxios.put(`table_stock/tables/${tableId}/`, uploadData)
      showToast('編集が完了しました');
      navigate(-1);
    } catch (error: any) {
    }
  };

  return onSubmit;
};