import React, { useEffect } from 'react';
import { CircularLoading } from '../molecules/loading/CircularLoading';
import { LiffLoginProcess } from '../organisms/LiffLoginProcess';
import { useExtractRestaurantIdFromUrl } from '../../hooks/externalApi/line/useExtractRestaurantIdFromUrl';
import { useFetchLiffUrlCustomerByRestaurantId } from '../../hooks/externalApi/line/useFetchLiffUrlCustomerByRestaurantId';
import liff from '@line/liff';


export const VisitLiffApp = () => {
  const restaurantId = useExtractRestaurantIdFromUrl()
  if (restaurantId === null) return <CircularLoading />
  return (
    <LiffLoginProcess restaurantId={restaurantId} liffType="visit">
      <NavigateToMembership restaurantId={restaurantId}/>
    </LiffLoginProcess>
  );
};


const NavigateToMembership: React.FC<{restaurantId: string}> = ({restaurantId}) => {
  const { liffUrl, isLoading } = useFetchLiffUrlCustomerByRestaurantId(restaurantId, 'membership');
  useEffect(() => {
    if (!isLoading && liffUrl) liff.openWindow({ url: liffUrl +'/'+ restaurantId });
  }, [isLoading, liffUrl]);
  return <CircularLoading/>;
};