import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const overallScoreSchema = yup.object().shape({
  score: yup.number().required('Please select without fail'),
});

export const usePostKuchikomyuOverallScoreFormEn = () => {
  const { handleSubmit, formState: { isSubmitting, errors }, control } = useForm({
    resolver: yupResolver(overallScoreSchema),
  });

  return {
    handleSubmit,
    control,
    isSubmitting,
    errors,
  };
};