import { memo } from "react"
import { FormControl, FormErrorMessage, FormLabel, Skeleton, Textarea } from "@chakra-ui/react";

type GUrlTextAreaProps = {
  register: any;
  errors: any;
  isLoading?: boolean;
}
export const GUrlTextArea: React.FC<GUrlTextAreaProps> = memo(({ register, errors, isLoading }) => {
  const gUrlError = errors.g_url?.message;
  return (
    <FormControl isInvalid={!!errors.g_url} isRequired w='100%'>
      <FormLabel>
        GoogleUrl
      </FormLabel>
      <Skeleton isLoaded={!isLoading} w='100%' borderRadius={5}>
        <Textarea {...register('g_url')} />
      </Skeleton>
      <FormErrorMessage>{gUrlError}</FormErrorMessage>
    </FormControl>
  );
});