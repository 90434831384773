import React, { memo } from 'react';
import { Grid } from '@chakra-ui/react';
import { TodaysKuchikomyuOverallScoreAnswerCountCard } from '../dashboard/TodaysKuchikomyuOverallScoreAnswerCountCard';
import { KuchikomyuOverallScoreAnswerCountCard } from './KuchikomyuOverallScoreAnswerCountCard';
import { useRecoilValue } from 'recoil';
import { kuchikomyuDateRangeState } from '../../../store/kuchikomyuDateRangeState';


type KuchikomyuOverallScoreAnswerCountSectionProps = {
  restaurantId: string;
}
export const KuchikomyuOverallScoreAnswerCountSection: React.FC<KuchikomyuOverallScoreAnswerCountSectionProps> = memo(({restaurantId}) => {
  const dateRange = useRecoilValue(kuchikomyuDateRangeState)
  return (
    <Grid templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)'}} gap={3} w='100%'>
      <KuchikomyuOverallScoreAnswerCountCard restaurantId={restaurantId} startDate={dateRange.startDate} endDate={dateRange.endDate} />
      <TodaysKuchikomyuOverallScoreAnswerCountCard restaurantId={restaurantId} />
    </Grid>
  );
});