import React, { memo } from "react";
import {
  Pagination,
  PaginationPage,
  PaginationNext,
  PaginationPrevious,
  PaginationPageGroup,
  PaginationContainer,
  PaginationSeparator,
} from "@ajna/pagination";

type PaginatorProps = {
  pagesCount: number;
  currentPage: number;
  setCurrentPage: (value: React.SetStateAction<number>) => void
  pages: number[];
}

export const Paginator: React.FC<PaginatorProps> = memo(({pagesCount, currentPage, setCurrentPage, pages}) => {
  const handlePageChange = (nextPage: number): void => setCurrentPage(nextPage);
  return (
    <Pagination
      pagesCount={pagesCount}
      currentPage={currentPage}
      onPageChange={handlePageChange}
    >
      <PaginationContainer
        align="center"
        justify="space-between"
        alignItems='center'
        w="full"
      >
        <PaginationPrevious
          size='sm'
          _hover={{
            color: "blue.300",
          }}
          color="blue.500"
          bg="none"
        >
          <div>＜</div>
        </PaginationPrevious>
        <PaginationPageGroup
          align="center"
          m='0'
          separator={
            <PaginationSeparator
              isDisabled
              bg="blue.300"
              fontSize="sm"
              w={7}
              jumpSize={1}
            />
          }
        >
          {pages.map((page: number) => (
            <PaginationPage
              w={7}
              h={8}
              bg="gray.300"
              key={`pagination_page_${page}`}
              page={page}
              fontSize="sm"
              _hover={{
                bg: "messenger.500",
                color: "white"
              }}
              _current={{
                bg: "messenger.500",
                fontSize: "sm",
                color: "white",
                w: 7,
                h: 8
              }}
            />
          ))}
        </PaginationPageGroup>
        <PaginationNext
          size='sm'
          _hover={{
            color: "messenger.300",
          }}
          color="messenger.500"
          bg="none"
        >
          <div>＞</div>
        </PaginationNext>
      </PaginationContainer>
    </Pagination>
  );
});

