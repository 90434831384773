import { useRecoilValue } from "recoil";
import { totalState } from "../../store/totalState";
import { useCustomPagination } from "../useCustomPagenation";
import { useFetchSoCouponOverallScoreWithLineAccountsPerPage } from "./useFetchSoCouponOverallScoreWithLineAccountsPerPage";
import { useUpdateTotal } from "../useUpdateTotal";


export const useFetchPaginateSoCouponOverallScoreWithLineAccounts = (restaurantId: string) => {
  const total = useRecoilValue(totalState);
  const { pages, pagesCount, currentPage, setCurrentPage, pageSize } = useCustomPagination(total, 24);
  const { soCouponOverallScoresWithLineAccountsPerPage, isLoading } = useFetchSoCouponOverallScoreWithLineAccountsPerPage(restaurantId, pageSize, currentPage)
  useUpdateTotal(soCouponOverallScoresWithLineAccountsPerPage);

  return { soCouponOverallScoresWithLineAccountsPerPage, isLoading, pages, pagesCount, currentPage, setCurrentPage };
};