import { useParams } from "react-router-dom";
import { useFetchKuitanSetting } from "../../../hooks/kuitan/useFetchKuitanSetting";
import { useEditKuitanSettingForm } from "../../../hooks/kuitan/useEditKuitanSettingForm";
import { useEditKuitanSetting } from "../../../hooks/kuitan/useEditKuitanSetting";
import { KuitanSettingForm } from "../../organisms/kuitan/KuitanSettingForm";


export const EditKuitanSetting = () => {
  const { settingId } = useParams();
  const { setting } = useFetchKuitanSetting(settingId);
  const { handleSubmit, register, isSubmitting, errors } = useEditKuitanSettingForm(setting)
  const onSubmit = useEditKuitanSetting(settingId)
  return <KuitanSettingForm onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} errors={errors} register={register} />
};