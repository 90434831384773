import React, { useEffect } from 'react';
import { Button, Center, FormControl, Input, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack, useDisclosure, } from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom';
import { useSignUpKuitanAgentForm } from '../../../hooks/auth/useSignUpKuitanAgentForm';
import { UserUsernameInput } from '../../molecules/form/UserUsernameInput';
import { UserPasswordInput } from '../../organisms/restaurant/UserPasswordInput';
import { UserEmailInput } from '../../molecules/form/UserEmailInput';
import { UserPhoneNumberInput } from '../../molecules/form/UserPhoneNumberInput';
import { UserFullNameInput } from '../../molecules/form/UserFullNameInput';
import { useSignUpKuitanAgent } from '../../../hooks/auth/useSignUpKuitanAgent';
import { useAuthKuitanAgentForm } from '../../../hooks/auth/useAuthKuitanAgentForm';
import { useAuthKuitanAgent } from '../../../hooks/auth/useAuthKuitanAgent';
import { InstagramUrlInput, TabelogUrlInput, TiktokUrlInput, XUrlInput } from '../../organisms/kuitan/SnsUrlInputs';


type InputField = {
  component: React.ComponentType<any>;
  props: Record<string, any>;
}
export const SignUpKuitanAgent = () => {
  const { handleSubmit, register, isSubmitting, errors } = useSignUpKuitanAgentForm();
  const onSubmit = useSignUpKuitanAgent();
  const inputFields: InputField[] = [
    { component: UserUsernameInput, props: { register, errors } },
    { component: UserPasswordInput, props: { register, errors } },
    { component: UserPhoneNumberInput, props: { register, errors } },
    { component: UserFullNameInput, props: { register, errors } },
    { component: UserEmailInput, props: { register, errors, isRequired: false } },
    { component: TabelogUrlInput, props: { register, errors } },
    { component: InstagramUrlInput, props: { register, errors } },
    { component: TiktokUrlInput, props: { register, errors } },
    { component: XUrlInput, props: { register, errors } },
  ];
  return (
    <Center>
      <AuthModal/>
      <VStack as='form' align='start' spacing={6} bg="white" p={{ base: 6, sm: 10 }} rounded={6} w={{ base: '100%', sm: '560px' }} onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize='2xl' fontWeight='bold' mb={0}>エージェントサインアップ</Text>
        <VStack align='start' w='100%' spacing={4}>
          {inputFields.map(({ component: InputComponent, props }, index) => <InputComponent key={index} {...props} />)}
        </VStack>
        <VStack align='start' w='100%'>
          <Button colorScheme="red" w='100%' isLoading={isSubmitting} type='submit'>サインアップ</Button>
          <Link as={RouterLink} to='/login/kuitan-agent' color='messenger.500' fontSize='sm'>ログイン</Link>
        </VStack>
      </VStack>
    </Center>
  );
};


const AuthModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { handleSubmit, register, isSubmitting } = useAuthKuitanAgentForm();
  const onSubmit = useAuthKuitanAgent(onClose);
  useEffect(() => onOpen(), [])
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size='xs'>
      <ModalOverlay backdropFilter='blur(20px)' />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader fontSize='md'>認証コードを入力してください</ModalHeader>
          <ModalBody>
            <FormControl isRequired>
              <Input type="text" {...register('auth_code')} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button type='submit' colorScheme='messenger' size='sm' mr={3} isLoading={isSubmitting}>
              送信
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
};