import { Icon, Text, Box, PopoverBody, Popover, PopoverTrigger, PopoverContent } from '@chakra-ui/react'
import { MdOutlineInsertComment } from "react-icons/md";


type CommentPopoverProps = {
  comment: string
}
export const CommentPopover = ({comment}: CommentPopoverProps) => {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
      <Box>
        <Icon as={MdOutlineInsertComment} />
      </Box>
      </PopoverTrigger>
      <PopoverContent w='300px'  alignItems='start'>
        <PopoverBody >
          <Box maxH='200px' overflowY='scroll'>
            <Text m={0} whiteSpace='pre-wrap'>{comment}</Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}