import { Route } from 'react-router-dom';
import { RestaurantsRoutesForSalesman } from './RestaurantsRoutesForSalesman';
import { SalesmanBaseInfoEdit } from '../components/pages/salesmen/SalesmanBaseInfoEdit';
import { NoticeRoutes } from './NoticeRoutes';
import SalesmanBase from '../components/template/base/SalesmanBase';
import { SalesmanBaseInfo } from '../components/pages/salesmen/SalesmanBaseInfo';
import { KuitanRoutesForSalesman } from './KuitanRoutesForSalesman';
import { AssignedCorporations } from '../components/pages/corporations/AssignedCorporations';
import { Corporation } from '../components/pages/corporations/Corporation';
import { TabelogScoreRoutes } from './TabelogScoreRoutes';


export const SalesmanRoutes = (
  <Route path="salesmen/:salesmanId" element={<SalesmanBase />}>
    <Route path="" element={<SalesmanBaseInfo />} />
    <Route path="edit" element={<SalesmanBaseInfoEdit />} />
    <Route path="corporations" element={<AssignedCorporations />} />
    <Route path="corporations/:corporationId" element={<Corporation />} />
    {RestaurantsRoutesForSalesman}
    {NoticeRoutes}
    {KuitanRoutesForSalesman}
    {TabelogScoreRoutes}
  </Route>
);