import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const liffSchema = yup.object().shape({
  liff_type: yup.string().required('入力必須項目です'),
  liff_id: yup.string().required('入力必須項目です'),
  liff_url: yup.string().required('入力必須項目です'),
});
export const useRegisterLiffForm = () => {
  const { handleSubmit, register, formState: { errors, isSubmitting }, control } = useForm({resolver: yupResolver(liffSchema),});
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};