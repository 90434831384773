import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const schema = yup.object().shape({
  overall: yup.string().required('必須項目です'),
  food: yup.string().required('必須項目です'),
  drink: yup.string().required('必須項目です'),
  customer_service: yup.string().required('必須項目です'),
  hygiene: yup.string().required('必須項目です'),
  comment: yup.string(),
});
export const useRateForm = () => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting } } = useForm({ resolver: yupResolver(schema) });
  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};