import { useNavigate } from "react-router-dom";
import { useSuccessToast } from "../../toast/useSuccessToast";
import { customAuthAxios } from "../../../utils/customAuthAxios";


export const useEditRouletteSetting = (settingsId?: string) => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = async (data: any) => {
    try {
      await customAuthAxios.put(`games/roulette/settings/${settingsId}/`, data)
      showToast('編集が完了しました')
      navigate(-1);
    } catch (error: any) {
    }
  };
  return onSubmit;
};