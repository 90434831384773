import { Route } from 'react-router-dom';
import { ManagerRegister } from '../components/pages/managers/ManagerRegister';
import { Managers } from '../components/pages/managers/Managers';
import { Manager } from '../components/pages/managers/Manager';
import { ManagerEdit } from '../components/pages/managers/ManagerEdit';
import { ManagerPasswordEdit } from '../components/pages/managers/ManagerPasswordEdit';


export const ManagersRoutes = (
  <Route path='managers'>
    <Route path="" element={<Managers />} />
    <Route path="register" element={<ManagerRegister />} />
    <Route path=":managerId" element={<Manager />} />
    <Route path=":managerId/edit" element={<ManagerEdit />} />
    <Route path=":managerId/pass/edit" element={<ManagerPasswordEdit />} />
  </Route>
);