import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const editMyRestaurantSchema = yup.object().shape({
  user: yup.object().shape({
    username: yup.string().required('ユーザーネームを入力してください'),
    email: yup.string().required('メールアドレスを入力してください').email("メールアドレスを正しい形式で入力してください"),
    phone_number: yup.string().required('電話番号を入力してください'),
  }),
  restaurant: yup.object().shape({
    name: yup.string().required('店舗名を入力してください'),
    address: yup.string().required('住所を入力してください'),
    manager_last_name: yup.string().required('代表者名を入力してください'),
    manager_first_name: yup.string().required('代表者名を入力してください'),
    manager_phone_number: yup.string().required('電話番号を入力してください'),
    logo: yup.mixed()
  }),
});


export const useEditMyRestaurantForm = (restaurant: any) => {

  const { handleSubmit, register, formState: { errors, isSubmitting }, setValue } = useForm({
    resolver: yupResolver(editMyRestaurantSchema),
  });
  useEffect(() => {
    if (restaurant) {
      setValue('user.username', restaurant.user_data.username);
      setValue('user.email', restaurant.user_data.email);
      setValue('user.phone_number', restaurant.user_data.phone_number);
      setValue('restaurant.name', restaurant.name);
      setValue('restaurant.address', restaurant.address);
      setValue('restaurant.manager_last_name', restaurant.manager_last_name);
      setValue('restaurant.manager_first_name', restaurant.manager_first_name);
      setValue('restaurant.manager_phone_number', restaurant.manager_phone_number);
    }
  }, [restaurant]);

  return {
    handleSubmit,
    register,
    isSubmitting,
    errors,
  };
};