import { useToast } from "@chakra-ui/react";

export const useErrorToast = () => {
  const toast = useToast();
  const showErrorToast = (title: string) => {
    toast({
      position: 'top',
      title: `${title}`,
      status: 'error',
      duration: 10000,
      isClosable: true,
      containerStyle: {
        whiteSpace: 'pre-wrap',
      },
    });
  };

  return showErrorToast;
};