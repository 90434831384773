import React from 'react';
import {
  List,
  ListItem,
  Divider,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom';

type DrawerNavProps = {
  onClose: () => void;
}

type LinkItem = {
  path: string;
  label: string;
}
const links: LinkItem[] = [
  { path: '/membership/top', label: 'トップ' },
  { path: '/membership/menu', label: 'メニュー' },
  { path: '/membership/messages', label: 'メッセージ' },
  { path: '/membership/coupons', label: 'クーポン' },
];

export const DrawerNav: React.FC<DrawerNavProps> = ({onClose}) => {
  return (
    <List spacing={4} onClick={onClose}>
      {links.map((link, index) => (
        <React.Fragment key={index}>
          <Divider my={1} />
          <ListItem>
            <Link to={link.path}>{link.label}</Link>
          </ListItem>
        </React.Fragment>
      ))}
      <Divider my={1} />
    </List>
  );
}