import { memo } from "react";
import { VStack, Box } from '@chakra-ui/react'
import { useParams, useSearchParams } from 'react-router-dom';
import { TableLayout } from "../../template/table/TableLayout";
import { TableRowWithNavigationLayout } from "../../template/table/TableRowWithNavigationLayout";
import { Pagination } from "../../molecules/pagenation/Pagination";
import { DispatchRequestHistoriesTab } from "../../organisms/kuitan/DispatchRequestHistoriesTab";
import { useFetchPaginatedMonthlyDispatchRequestsBySalesmanId } from "../../../hooks/kuitan/useFetchPaginatedMonthlyDispatchRequestsBySalesmanId";
import { PartialCircularLoading } from "../../molecules/loading/PartialCircularLoading";
import { formatDate, formatMonth } from "../../../utils/formatDate";
import { useFetchPaginatedMultiMonthDispatchRequestsBySalesmanId } from "../../../hooks/kuitan/useFetchPaginatedMultiMonthDispatchRequestsBySalesmanId";


export const DispatchRequestsForSalesman = () => {
  const { salesmanId = '' } = useParams();
  return (
    <DispatchRequestHistoriesTab
      monthly={<MonthlyRequests salesmanId={salesmanId} />}
      multiMonth={<MultiMonthRequests salesmanId={salesmanId} />}
    />
  );
};


const MonthlyRequests = memo(({salesmanId}: {salesmanId: string}) => {
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const pageSize = 10;
  const { paginatedMonthlyDispatchRequests, isLoading } = useFetchPaginatedMonthlyDispatchRequestsBySalesmanId(pageSize, page, salesmanId)
  if (isLoading || !paginatedMonthlyDispatchRequests) return <PartialCircularLoading />
  const headers = ['法人', 'チーム', '派遣月', '件数', '依頼日時'];
  const rows = paginatedMonthlyDispatchRequests.monthly_dispatch_requests.map((monthly_dispatch_request) => (
    <TableRowWithNavigationLayout
      key={monthly_dispatch_request.id}
      path={`monthly/${monthly_dispatch_request.id}`}
      size='sm'
      childrenList={[
        monthly_dispatch_request.corporation_name,
        monthly_dispatch_request.team_name,
        formatMonth(monthly_dispatch_request.month),
        monthly_dispatch_request.number + '件',
        formatDate(monthly_dispatch_request.requested_at),
      ]}
    />
  ));
  return (
    <VStack>
      <Box w='100%'>
        <TableLayout headers={headers} rows={rows || []} size='sm' />
      </Box>
      <Pagination totalItems={paginatedMonthlyDispatchRequests.count} pageSize={pageSize}/>
    </VStack>
  );
});



const MultiMonthRequests = memo(({salesmanId}: {salesmanId: string}) => {
  const [searchParams] = useSearchParams({ p: "1" });
  const page = Number(searchParams.get('p') || "1");
  const pageSize = 10;
  const { paginatedMultiMonthDispatchRequests, isLoading } = useFetchPaginatedMultiMonthDispatchRequestsBySalesmanId(pageSize, page, salesmanId)
  if (isLoading || !paginatedMultiMonthDispatchRequests) return <PartialCircularLoading />
  const headers = ['法人', 'チーム', '期間', '件数', '依頼日時'];
  const rows = paginatedMultiMonthDispatchRequests.multi_month_dispatch_requests.map((multi_month_dispatch_request) => (
    <TableRowWithNavigationLayout
      key={multi_month_dispatch_request.id}
      path={`multi-month/${multi_month_dispatch_request.id}`}
      size='sm'
      childrenList={[
        multi_month_dispatch_request.corporation_name,
        multi_month_dispatch_request.team_name,
        multi_month_dispatch_request.period + 'ヶ月',
        multi_month_dispatch_request.number + '件',
        formatDate(multi_month_dispatch_request.requested_at),
      ]}
    />
  ));
  return (
    <VStack>
      <Box w='100%'>
        <TableLayout headers={headers} rows={rows || []} size='sm' />
      </Box>
      <Pagination totalItems={paginatedMultiMonthDispatchRequests.count} pageSize={pageSize}/>
    </VStack>
  );
});