import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";

type RestaurantNameInputFieldProps = {
  register: any;
  errors: any;
}
export const RestaurantNameInputField: React.FC<RestaurantNameInputFieldProps> = memo(({register, errors}) => {
  const restaurantNameError = errors.restaurant?.name?.message;
  return (
    <FormControl isRequired isInvalid={errors.restaurant?.name} w='100%'>
      <FormLabel>店名</FormLabel>
      <Input type="text" {...register('restaurant.name')} />
      <FormErrorMessage>{restaurantNameError}</FormErrorMessage>
    </FormControl>
  );
});