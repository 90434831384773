type LineAccountCounts = {
  visit_count: number;
  count: number;
}[]
export const calcTotalTargetAccountCount = (selectedVisitCounts: number[], lineAccountCounts: LineAccountCounts) => {
  const targetCounts = selectedVisitCounts.map((selectedVisitCount: number) => {
    const targetObject = lineAccountCounts?.find(lineAccountCount => lineAccountCount.visit_count === selectedVisitCount);
    return targetObject ? targetObject.count : 0;
  })
  const totalTargetCount = targetCounts.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
  return totalTargetCount;
}