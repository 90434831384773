import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Skeleton } from "@chakra-ui/react";
import { useFetchCorporateRestaurants } from "../../../hooks/restaurants/useFetchCorporateRestaurants";
import { Controller } from "react-hook-form";
import { ReactSelectWithChakraStyle } from "../../atoms/ReactSelectWithChakraStyle";


export const ManagerRestaurantsSelect: React.FC<{corporationId?: string, errors: any, control: any}> = memo(({ corporationId, errors, control }) => {
  const { restaurants, isLoading } = useFetchCorporateRestaurants(corporationId);
  const restaurantOptions = restaurants?.map(restaurant => ({
    label: restaurant.name,
    value: restaurant.id
  }));
  return (
    <FormControl isRequired isInvalid={!!errors.restaurant_media_pages}>
      <FormLabel>担当店舗</FormLabel>
        <Skeleton isLoaded={!isLoading} rounded={5}>
          <Controller
            name='manager.restaurants'
            control={control}
            render={({ field }) => (
              <ReactSelectWithChakraStyle
                placeholder="選択"
                options={restaurantOptions}
                value={Array.isArray(field.value)
                  ? restaurantOptions?.filter(option => field.value.includes(option.value))
                  : restaurantOptions?.find(option => option.value === field.value)}
                onChange={(newValue:any) => {field.onChange(newValue.map((item:any) => item.value));}}
                isMulti
              />
            )}
          />
        </Skeleton>
      <FormErrorMessage>{errors.restaurants?.message}</FormErrorMessage>
    </FormControl>
  )
});