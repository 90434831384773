import { memo, useEffect } from "react"
import liff from '@line/liff';
import { useFetchTableQrCodeImageUrl } from "../../../hooks/menu/useFetchTableQrCodeImageUrl";
import { CircularLoading } from "../../molecules/loading/CircularLoading";
import { getQrCodeUrl } from "../../../utils/getQrCodeUrl";

type MobileOrderQrProps = {
  tableId: string;
}
export const MobileOrderQr: React.FC<MobileOrderQrProps> = memo(({tableId}) => {
  const { qrCodeImageUrl } = useFetchTableQrCodeImageUrl(tableId);
  useEffect(() => {
    if (!qrCodeImageUrl) return;
    const navigateToQrCodeUrl = async () => {
      const qrCodeUrl = await getQrCodeUrl(qrCodeImageUrl);
      liff.openWindow({
        url: qrCodeUrl,
        external: true,
      });
    };
    navigateToQrCodeUrl();
  }, [qrCodeImageUrl]);
  return (
    <CircularLoading/>
  );
});