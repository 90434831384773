import { useState } from "react";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";


export const useChangeSoCouponStateByLineAccountId = (couponId: string, lineAccountId: string, onClose: () => void) => {
  const [isUsed, setIsUsed] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleOnClick = async () => {
    setIsSubmitting(true)
    try {
      const scoreData = {line_account_id:lineAccountId}
      const res = await customNoAuthAxios.post(
        `socoupon/socoupons/${couponId}/mark-as-used/line-account-id/`,
        scoreData,
      );
      setIsSubmitting(false)
      setIsUsed(true)
      onClose()
    } catch (error) {
    }
  };
  return { isUsed, isSubmitting, handleOnClick }
}