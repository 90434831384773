import { useNavigate } from "react-router-dom";
import { customNoAuthAxios } from "../../utils/customNoAuthAxios";
import liff from '@line/liff';


export const useSubmitSoCouponCustomerOverallScore = (lineAccountId: string, restaurantId: string, branchScore: number, gUrl: string) => {
  const navigate = useNavigate();
  const onSubmit = async (data:any) => {
    try {
      await customNoAuthAxios.post(
        `socoupon/customers/${lineAccountId}/overall-score/`,
        {restaurant_id: restaurantId, score: data.score},
      );
      if (data.score && data.score > branchScore) {
        liff.openWindow({url: gUrl, external: true});
      } else {
        navigate(`/socoupon/survey/${restaurantId}`);
      }
    } catch (error) {
    }
  }

  return onSubmit;
};