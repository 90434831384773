import React from "react"
import { useParams } from "react-router-dom";
import { Badge, Box, CardBody, CardHeader, Divider, Flex, VStack } from "@chakra-ui/react";
import { useFetchRouletteSettingByRestaurantId } from "../../../../hooks/games/roulette/useFetchRouletteSettingByRestaurantId";
import { CircularLoading } from "../../../molecules/loading/CircularLoading";
import { DarkShadowCard } from "../../../molecules/card/DarkShadowCard";
import { CardHeading } from "../../../molecules/card/CardHeading";
import { SettingsIcon } from "@chakra-ui/icons";
import { EditIconBtn } from "../../../atoms/button/EditIconBtn";
import { SettingItem } from "../../../organisms/SettingItem";
import { AlertMsg } from "../../../molecules/AlertMsg";


export const RouletteSettingForRestaurant = () => {
  const { restaurantId } = useParams();
  const {rouletteSetting, isLoading, error} = useFetchRouletteSettingByRestaurantId(restaurantId);
  if (isLoading) return <CircularLoading />;
  if (error && error.response.status === 404) return <AlertMsg msg={error.response.data.message}/>
  const settingItems = [
    {label: 'ブランチスコア', content: rouletteSetting?.branch_score ?? '未登録'},
    {label: 'セグメント数', content: rouletteSetting?.segment_count ?? '未登録'},
    {
      label: 'アンケート',
      content: (
        <Badge colorScheme={rouletteSetting?.is_survey ? 'green' : 'gray'}>
          {rouletteSetting?.is_survey ? 'ON' : 'OFF'}
        </Badge>
      ),
    },
  ];
  return (
    <DarkShadowCard w='100%'>
      <CardHeader>
        <Flex align='center' justify='space-between'>
          <CardHeading icon={<SettingsIcon />} heading='ルーレット設定'/>
          <EditIconBtn path='edit' />
        </Flex>
      </CardHeader>
      <CardBody pt={0}>
        <VStack align='start' fontSize='sm' fontWeight='bold' spacing={4} w='100%'>
          <Divider my={0} />
          {settingItems.map((detail) => (
            <React.Fragment key={detail.label}>
              <Box w='100%'>
                <SettingItem label={detail.label} content={detail.content}/>
              </Box>
              <Divider my={0} />
            </React.Fragment>
          ))}
        </VStack>
      </CardBody>
    </DarkShadowCard>
  );
};