import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from "react";


const rouletteSettingEditBySalesmanSchema = yup.object().shape({
  branch_score: yup.number().required('ブランチスコアを入力してください'),
  g_url: yup.string().required('Urlを入力してください'),
  is_survey: yup.boolean(),
  segment_count: yup.number().required('セグメント数を入力してください')
});
type RouletteSetting = {
  id: string;
  restaurant: string;
  branch_score: number;
  g_url: string;
  is_survey: boolean;
  segment_count: number;
}
export const useEditRouletteSettingFormForAdmin = (rouletteSetting?: RouletteSetting) => {
  const { handleSubmit, register, control, formState: { errors, isSubmitting }, setValue } = useForm({resolver: yupResolver(rouletteSettingEditBySalesmanSchema),});

  useEffect(() => {
    if (rouletteSetting) {
      setValue('branch_score', rouletteSetting.branch_score);
      setValue('g_url', rouletteSetting.g_url);
      setValue('is_survey', rouletteSetting.is_survey);
      setValue('segment_count', rouletteSetting.segment_count);
    }
  }, [rouletteSetting]);

  return {
    handleSubmit,
    register,
    control,
    isSubmitting,
    errors,
  };
};