import { useSwrWithQueryParams } from "../swr/useSwrWithQueryParams";


type TabelogScores = {
  score: number;
  date: number;
}[]
export const useFetchBenchmarkRestaurantTabelogScores = (benchmarkRestaurantId?: string, queryParams?: object) => {
  const fetchKey = benchmarkRestaurantId ? `ifrea_analytics/benchmark-restaurants/${benchmarkRestaurantId}/scores/` : null;
  const { data: tabelogScores, error, isLoading } = useSwrWithQueryParams<TabelogScores>(fetchKey, queryParams)
  return { tabelogScores, isLoading, error };
};