import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { ifreaMediaAnalyticsDateRangeState } from "../../store/ifreaMediaAnalyticsDateRangeState";

export const useFetchTotalMediaTopPv = (restaurantId?: string) => {
  const dateRange = useRecoilValue(ifreaMediaAnalyticsDateRangeState)
  const fetcherKey = restaurantId ? `ifrea_analytics/restaurants/${restaurantId}/total-media-top-pv/?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}` : null;
  const { data: totalMediaTopPv, isLoading } = useSWR<{[key: string]: number}>(fetcherKey)
  return { totalMediaTopPv, isLoading };
};

