import useSWR from "swr";


type CouponSegment = {
  coupon: {
    id: string;
    title: string;
    explanation: string;
    notes: string;
    expiration_date: string;
    img: string;
  }
  segment_count: number;
}
export const useFetchRouletteCouponSegment = (segmentId?: string) => {
  const fetcherKey = segmentId ? `games/roulette/coupon/segments/${segmentId}/` : null;
  const { data: couponSegment, error, isLoading } = useSWR<CouponSegment>(fetcherKey)
  return { couponSegment, isLoading, error };
};