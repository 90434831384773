import React from 'react';
import { useLoginForm } from '../../../hooks/auth/useLoginForm';
import { Button, Center, Text, VStack, } from "@chakra-ui/react";
import { useLoginRestaurant } from '../../../hooks/auth/useLoginRestaurant';
import { AuthPasswordInput } from '../../molecules/form/AuthPasswordInput';
import { LoginUsernameInput } from '../../molecules/form/LoginUsernameInput';


export const Login: React.FC = () => {
  const { handleSubmit, register, isSubmitting, errors } = useLoginForm();
  const onSubmit = useLoginRestaurant();
  return (
    <Center mt={{base: 0, sm: '60px', lg: '60px'}}>
      <VStack as='form' align='start' spacing={6} background="white" p={{ base: 6, sm: 10, lg: 10 }} rounded={6} w={{ base: '100%', sm: '420px', lg: '420px' }} onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize='2xl' fontWeight='bold' mb={0}>ログイン</Text>
        <VStack align='start' w='100%' spacing={4}>
          <LoginUsernameInput register={register} errors={errors} />
          <AuthPasswordInput register={register} errors={errors} />
        </VStack>
        <Button colorScheme="messenger" w='100%' isLoading={isSubmitting} type='submit'>ログイン</Button>
      </VStack>
    </Center>
  );
};