import useSWR from "swr";
import { UUID } from "crypto";


type User = {
  id: UUID;
  username: string;
  email: string;
  phone_number: string;
  last_name: string;
  first_name: string;
  is_superuser: boolean;
  groups: number[];
}
export const useFetchUser = () => {
  const { data: user, error, isLoading } = useSWR<User>('accounts/users/me/')
  return { user, isLoading, error };
};