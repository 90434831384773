import React, { memo } from "react";
import { Grid } from "@chakra-ui/react";
import { LatestValidCoupon } from "./LatestValidCoupon";
import { useFetchLatestValidCoupons } from "../../../hooks/membership/useFetchLatestValidCoupons";
import { NoValidCouponSection } from "./NoValidCouponSection";

type LatestValidCouponsProps = {
  restaurantId: string;
  lineId: string;
}

export const LatestValidCoupons: React.FC<LatestValidCouponsProps> = memo(({restaurantId, lineId}) => {
  const { latestValidCoupons, error, isLoading } = useFetchLatestValidCoupons(restaurantId, lineId)
  if (isLoading || !latestValidCoupons) return null
  if (latestValidCoupons.length === 0) return <NoValidCouponSection/>

  return (
    <Grid templateColumns='repeat(3, 1fr)' gap={2} w='100%'>
      {latestValidCoupons.map((coupon) => (
        <LatestValidCoupon key={coupon.id} {...coupon} />
      ))}
    </Grid>
    );
});
