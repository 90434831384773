import React, { memo } from "react";
import { Accordion, List, ListItem } from '@chakra-ui/react'
import { SegmentDeliveryAccordionMenu } from "./SegmentDeliveryAccordionMenu";
import { MenuAccordionMenu } from "./MenuAccordionMenu";
import { TableAccordionMenu } from "./TableAccordionMenu";
import { MembershipAccordionMenu } from "./MembershipAccordionMenu";
import { GoogleAccordionMenu } from "./GoogleAccordionMenu";
import { SidebarItemLink } from "../../molecules/SidebarItemLink";
import { IfreaAnalyticsAccordionMenu } from "./IfreaAnalyticsAccordionMenu";
import { useFetchRestaurant } from "../../../hooks/restaurants/useFetchRestaurant";
import { GameAccordionMenu } from "./GameAccordionMenu";
import { SurveyAccordionMenu } from "./SurveyAccordionMenu";
import { KuitanAccordionMenu } from "./KuitanAccordionMenu";
import { StaffAccordionMenu } from "./StaffAccordionMenu";
import { InstagramAccordionMenu } from "./InstagramAccordionMenu";


type RestaurantSidebarMenuProps = {
  restaurantId: string
}
export const RestaurantSidebarMenu: React.FC<RestaurantSidebarMenuProps> = memo(({restaurantId}) => {
  return (
    <Accordion allowMultiple>
      <List spacing={1}>
        <ListItem >
          <SidebarItemLink to='dashboard' text="ダッシュボード" />
        </ListItem>
        <AddOns restaurantId={restaurantId}/>
        <ListItem >
          <SidebarItemLink to='' text="基本情報" />
        </ListItem>
      </List>
    </Accordion>
  );
});


const AddOns: React.FC<RestaurantSidebarMenuProps> = memo(({restaurantId}) => {
  const {restaurant} = useFetchRestaurant(restaurantId);
  if (!restaurant) return null;
  const addOns = restaurant.add_ons_names;
  // console.log(addOns);
  const menuItems = [
    { conditions: ['即ーポン'], component: <MenuAccordionMenu /> },
    { conditions: ['即ーポン'], component: <TableAccordionMenu /> },
    { conditions: ['セグメント配信'], component: <SegmentDeliveryAccordionMenu /> },
    { conditions: ['セグメント配信'], component: <MembershipAccordionMenu /> },
    { conditions: ['即ーポン', '口コミュ', '満足度ラボ'], component: <SurveyAccordionMenu addOns={addOns} /> },
    { conditions: ['GBPマネジャー'], component: <GoogleAccordionMenu /> },
    { conditions: ['Instagramマネージャー'], component: <InstagramAccordionMenu /> },
    { conditions: ['ifreaアナリティクス'], component: <IfreaAnalyticsAccordionMenu /> },
    { conditions: ['ルーレット'], component: <GameAccordionMenu /> },
    { conditions: ['食イタン'], component: <KuitanAccordionMenu /> },
    { conditions: ['従業員'], component: <StaffAccordionMenu /> },
  ];
  return (
    <>
      {menuItems.map((item, i) =>
        item.conditions.some(condition => addOns.includes(condition)) && (
          <ListItem key={i}>
            {item.component}
          </ListItem>
        )
      )}
    </>
  );
});