import { customAuthAxios } from "../../../utils/customAuthAxios";
import { useErrorToast } from "../../toast/useErrorToast";
import { useSuccessToast } from "../../toast/useSuccessToast";


export const useReplyToGoogleReview = (restaurantId: string, reviewId: string, onClose: () => void) => {
  const showToast = useSuccessToast();
  const showErrorToast = useErrorToast();

  const onSubmit = async (data: any) => {
    try {
      await customAuthAxios.post(`external_api/restaurants/${restaurantId}/google/reviews/${reviewId}/reply/`, data)
      onClose()
      showToast('口コミに返信しました', '反映まで10秒程度かかります。\n10秒経過後にページを再読み込みしてください。');
    } catch (error: any) {
      showErrorToast(error.response.data.detail)
    }
  };

  return onSubmit;
};