import { useLoginForm } from '../../../hooks/auth/useLoginForm';
import { useLoginKuitanAgentForRate } from '../../../hooks/auth/useLoginKuitanAgentForRate';
import { useSearchParams } from 'react-router-dom';
import { LoginForm } from '../../organisms/authentication/LoginForm';


export const LoginKuitanAgentForRate = () => {
  const [searchParams] = useSearchParams({ r: "" });
  const restaurantId = searchParams.get('r') || '';
  const { handleSubmit, register, isSubmitting, errors } = useLoginForm();
  const onSubmit = useLoginKuitanAgentForRate(restaurantId);
  return <LoginForm onSubmit={handleSubmit(onSubmit)} register={register} errors={errors} isSubmitting={isSubmitting} title="エージェントログイン" btnColor="red" />
};