import { Button, FormControl, FormLabel, Radio, RadioGroup, Select, Stack, VStack } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { CardWithFooter } from "../../../molecules/card/CardWithFooter";


export const CustomerForm: React.FC<{onSubmit: (data: any) => Promise<void>}> = ({onSubmit}) => {
  const { handleSubmit, formState: { isSubmitting }, control, register } = useForm({});
  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <CardWithFooter cardTitle="入力してください">
        <VStack spacing={4}>
          <SelectBirthYear {...{register}} />
          <SelectBirthMonth {...{register}} />
          <SelectSex {...{control}} />
        </VStack>
        <Button colorScheme='messenger' size='sm' type='submit' isLoading={isSubmitting}>送信</Button>
      </CardWithFooter>
    </form>
  );
}

const SelectBirthYear: React.FC<{register: any}> = ({register}) => {
  return (
    <FormControl>
      <FormLabel>誕生年</FormLabel>
      <Select {...register("birthYear")} defaultValue='2023' >
        {Array.from({ length: 100 }, (_, i) => (new Date().getFullYear() - i).toString()).map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
const SelectBirthMonth: React.FC<{register: any}> = ({register}) => {
  return (
    <FormControl>
      <FormLabel>誕生月</FormLabel>
      <Select {...register("birthMonth")} defaultValue='1'>
        {[ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map((month, index) => (
          <option key={index} value={month}>
            {month}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
const SelectSex: React.FC<{control: any}> = ({control}) => {
  return (
    <FormControl>
      <FormLabel>性別</FormLabel>
      <Controller
        control={control}
        name="sex"
        defaultValue="男性"
        render={({ field }) => (
          <RadioGroup {...field}>
            <Stack direction='row'>
              <Radio value='男性'>男性</Radio>
              <Radio value='女性'>女性</Radio>
              <Radio value='その他'>その他</Radio>
            </Stack>
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}

