import useSWR from "swr";


export type Corporations = {
  id: string;
  name: string;
}[]
export const useFetchCorporations = () => {
  const fetchKey = `accounts/corporations/`
  const { data: corporations, error, isLoading } = useSWR<Corporations>(fetchKey)
  return { corporations, isLoading, error };
};