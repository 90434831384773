import { Flex } from '@chakra-ui/react';
import React from 'react';


export const AuthHeader: React.FC = () => {
  return (
    <Flex
      as="nav"
      align='center'
      justify='space-between'
      bg="#0E0F0E"
      p={4}
      w='100%'
      h='56px'
    >
    </Flex>
  );
};