import React, { memo } from "react";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from "@chakra-ui/react";


type UserUsernameInputProps = {
  register: any;
  errors: any;
}
export const UserUsernameInput: React.FC<UserUsernameInputProps> = memo(({register, errors}) => {
  const usernameError = errors.user?.username?.message;
  return (
    <FormControl isRequired isInvalid={errors.user?.username} w='100%'>
      <FormLabel>ユーザーネーム</FormLabel>
      <Input type="text" {...register('user.username')} />
      <FormHelperText>半角アルファベット、半角数字、@/./+/-/_ で150文字以下。</FormHelperText>
      <FormErrorMessage>{usernameError}</FormErrorMessage>
    </FormControl>
  );
});