import { useCallback, useRef } from 'react';
import html2canvas from 'html2canvas';


export const useDownloadNameTag = (onClose: () => void) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const handleDownload = useCallback(() => {
    if (modalRef.current) {
      const pTags = modalRef.current.getElementsByTagName('p');
      Array.from(pTags).forEach((p) => {
        if (p.classList.contains('nickname')) p.style.paddingBottom = '10px';
        p.style.marginBottom = '20px';
      });
      html2canvas(modalRef.current, { scale: 2, width: 450, height: 250, useCORS: true }).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'name-tag.png';
        link.click();
      });
      onClose();
    }
  }, []);
  return { modalRef, handleDownload };
};