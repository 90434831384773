import { Route } from 'react-router-dom';
import MembershipBase from '../components/template/base/MembershipBase';
import { Top } from '../components/pages/membership/Top';
import { CustomerValidCoupons } from '../components/pages/membership/CustomerValidCoupons';
import { MembershipMenu } from '../components/pages/membership/MembershipMenu';
import { CustomerValidCoupon } from '../components/pages/membership/CustomerValidCoupon';
import { CustomerBenefits } from '../components/pages/membership/CustomerBenefits';
import { CustomerBenefit } from '../components/pages/membership/CustomerBenefit';
import { SegmentMessage } from '../components/pages/membership/SegmentMessage';
import { SegmentMessages } from '../components/pages/membership/SegmentMessages';


export const CustomerMembershipRoutes = (
  <Route path="membership" element={<MembershipBase />} >
    <Route path="line-login/*" element={<Top />} />
    <Route path="coupons/line-login/*" element={<CustomerValidCoupons />} />
    <Route path="top" element={<Top />} />
    <Route path="menu" element={<MembershipMenu />} />
    <Route path="coupons" element={<CustomerValidCoupons />} />
    <Route path="coupons/:couponId" element={<CustomerValidCoupon />} />
    <Route path="customer-benefits" element={<CustomerBenefits />} />
    <Route path="customer-benefits/:benefitId" element={<CustomerBenefit />} />
    <Route path="messages" element={<SegmentMessages />} />
    <Route path="messages/:msgId" element={<SegmentMessage />} />
  </Route>
);