import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAuthAxios } from '../../utils/customAuthAxios';
import { useSuccessToast } from '../toast/useSuccessToast';


export const useCreateTeam = () => {
  const navigate = useNavigate();
  const showToast = useSuccessToast();
  const onSubmit = useCallback(async (data: any) => {
    try {
      const cc_emails = data.cc_emails.filter((email: string) => email !== '');
      const newData = { ...data, cc_emails };
      await customAuthAxios.post(
        'kuitan/teams/',
        newData
      );
      showToast('チームを作成しました')
      navigate(-1)
    } catch (error:any) {
    }
  }, [navigate, showToast]);
  return onSubmit;
};